import { useState, useEffect, useRef } from 'react';

const useGoogleMap = (options: google.maps.MapOptions) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (!google || !ref || !ref?.current) {
      return;
    }
    // @ts-ignore
    setMap(new google.maps.Map(ref?.current, options));
  }, [google, ref]);

  return { ref, map };
};

export default useGoogleMap;
