import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { BREAKPOINTS } from '../../variables/constant';
import {
  Breakpoint,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { ChildOfType } from '../../generated/graphql';
import DateOfBirthPicker from '../../components/DateOfBirthPicker';
import AddIcon from '@mui/icons-material/Add';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import isValidDateOfBirth from '../../utils/isValidDateOfBirth';
import Box from '@mui/material/Box';
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
} from 'react-hook-form/dist/types/fieldArray';
import { DataForm } from './children-form-hooks';
import { DeleteButton } from '../../components/DeleteButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export interface ChildrenFormInfoProps {
  control: Control<DataForm>;
  fields: FieldArrayWithId<DataForm, 'fieldArray', 'id'>[];
  append: UseFieldArrayAppend<any>;
  onRemoveChild: Function;
  errors: any;
}

const ChildrenForm = ({
  control,
  fields,
  append,
  errors,
  onRemoveChild,
}: ChildrenFormInfoProps) => {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={3}>
      {fields.map((child, index) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={child.id}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  flexDirection: 'column',
                },
              }}
            >
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name={`fieldArray.${index}.full_name`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Full Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Please add your child’s full name',
                  }}
                  control={control}
                />
                {errors?.fieldArray?.[index]?.full_name && (
                  <FormHelperErrorText>
                    {errors?.fieldArray[index]?.full_name?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                  [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                    my: 3,
                  },
                  [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                    mx: 2,
                  },
                }}
              >
                <Controller
                  name={`fieldArray.${index}.date_of_birth`}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateOfBirthPicker onChange={onChange} value={value} />
                    </LocalizationProvider>
                  )}
                  control={control}
                  rules={{
                    required: 'Please select the date of birth',
                    validate: {
                      validDateOfBirth: (value: Date | null) =>
                        isValidDateOfBirth(value) ||
                        'Please enter valid date of birth.',
                    },
                  }}
                />
                {errors?.fieldArray?.[index]?.date_of_birth && (
                  <FormHelperErrorText>
                    {errors?.fieldArray[index]?.date_of_birth?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name={`fieldArray.${index}.child_of`}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>Child Of</InputLabel>
                      <Select
                        value={value ? value : ''}
                        label="CHILD OF"
                        onChange={onChange}
                      >
                        <MenuItem value={ChildOfType.SpouseAndMe}>
                          Spouse & Me
                        </MenuItem>
                        <MenuItem value={ChildOfType.MeAndSomeoneElse}>
                          Me and someone else
                        </MenuItem>
                        <MenuItem value={ChildOfType.SpouseAndSomeoneElse}>
                          Spouse and someone else
                        </MenuItem>
                      </Select>
                    </>
                  )}
                  rules={{
                    required: 'Please select the child of option',
                  }}
                  control={control}
                />
                {errors?.fieldArray?.[index]?.child_of && (
                  <FormHelperErrorText>
                    {errors?.fieldArray[index]?.child_of?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <Box>
                {index !== 0 ? <DeleteButton
                  sx={{
                    [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                      mt: 2,
                    },
                    [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                      ml: 2,
                    },
                  }}
                  onClick={() => onRemoveChild(child.child_id, index)}
                ><DeleteOutlineIcon
                  sx={{
                    color: theme.palette.error.main,
                    fontSize: '1.5rem',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }} /></DeleteButton> : <Grid sx={{ ml: 10 }}></Grid>}
              </Box>
            </Grid>
          </Grid>
        );
      })}

      <FormHelperErrorText>
        {errors?.fieldArray?.root?.message}
      </FormHelperErrorText>

      <Button
        onClick={() =>
          append({
            id: '',
            full_name: '',
            date_of_birth: null,
            child_of: ChildOfType.SpouseAndMe,
          })
        }
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        sx={{
          mt: 1,
          width: '8rem',
          color: theme.palette.text.primary,
        }}
      >
        Add Child
      </Button>
    </Stack>
  );
};

export default React.memo(ChildrenForm);
