import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  RealEstatePropertyFragmentFragment,
  RealEstatePropertyType,
} from '../../../generated/graphql';
import useEstateForm, { DataForm } from './estate-form-hooks';
import TabLayout from '../../../components/TabLayout';
import { apiUrls } from '../../../variables/urls';
import Loader from '../../../components/Loader';
import HtmlTooltip from '../../../components/HtmlTooltip';
import { Control, Controller, useWatch } from 'react-hook-form';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import StatesDropdown from '../../../components/StatesDropdown';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddressField from './AddressField';
import useAssetsCommon from '../assets-common-hooks';

interface Props {
  realEstateList: RealEstatePropertyFragmentFragment[];
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

interface FieldProps {
  control: Control<DataForm>;
  errors: any;
  index: number;
}

const DescriptionField = ({ index, control, errors }: FieldProps) => {
  const propertyType = useWatch({
    control,
    name: `fieldArray.${index}.property_type`,
  });

  return propertyType === RealEstatePropertyType.Other ? (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <Controller
        name={`fieldArray.${index}.description`}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            label="DESCRIPTION"
            variant="outlined"
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          required: 'Please enter the description',
        }}
        control={control}
      />
      {errors?.fieldArray?.[index]?.description && (
        <FormHelperErrorText>
          {errors?.fieldArray[index]?.description?.message}
        </FormHelperErrorText>
      )}
    </FormControl>
  ) : null;
};

const EstateBox = ({ realEstateList, selectedForms, uiFlag, setEditState }: Props) => {
  const theme = useTheme();
  const history = useHistory();
  const {
    loading,
    handleSubmit,
    create,
    onRemoveRealEstateProperty,
    isSubmitting,
    control,
    fields,
    append,
    remove,
    errors,
    defaultValues,
    onUpdate,
    setValue,
  } = useEstateForm({
    realEstateList,
    selectedForms,
    uiFlag,
    setEditState,
  });

  const previousAction = () => {
    history.push(apiUrls.ASSETS);
  };

  const { editItems, setEditItems, cancelEditMode } = useAssetsCommon({
    remove,
  });

  const resetAndRemove = (estate: any, index: number) => {
    cancelEditMode(estate.estate_id, index);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout
        title="Assets"
        previous={previousAction}
        isSubmitting={isSubmitting}
        uiFlag={uiFlag}
        next={setEditState && !uiFlag ? () => setEditState('') : undefined}
        setEditState={setEditState}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography
                variant="sTextInfo"
                component="div"
                sx={{ color: theme.palette.primary.main, mb: 2 }}
              >
                REAL ESTATE DETAILS
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="m" component="div">
                  List home/residence to be held in trust
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Why Should I Include My Home or Other Real Estate in My
                        Trust or Will?
                        <br />
                        For most people, real estate is often the most valuable
                        thing you own. Including your real estate assets in your
                        trust will ensure that these assets pass to your
                        beneficiaries without going through the probate process.
                        This can save your family both time and court expenses
                        while administering your estate.
                        <br /> <br />
                        How Do I Place My Home or other Real Estate in My Trust
                        or Will?
                        <br />
                        This is done by signing and recording a deed conveying
                        the property to the trust. You will receive detailed
                        instructions regarding how this is done when you receive
                        your final documents from TruWill.
                        <br /> <br />
                        What Happens If I Need to Sell My House or Other
                        Property?
                        <br />
                        Placing real estate in your trust will not effect your
                        ability to sell, refinance, take a line of credit, or
                        any other financial transaction related to the property
                        as you will maintain control of these assets as trustee
                        of your trust.
                      </React.Fragment>
                    }
                  />
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={6}>
            <Stack direction="column" spacing={3}>
              {fields.map((estate, index) => {
                return (
                  <Stack
                    key={estate.id}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      width: '100%',
                      mt: 2,
                      border: '1px solid',
                      borderColor: theme.palette.common.lightGreen,
                      borderRadius: '0.5rem',
                      p: 2,
                    }}
                  >
                    {estate.estate_id && !editItems.includes(index) ? (
                      <Box sx={{ alignSelf: 'flex-start', width: '100%' }}>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'baseline'}
                        >
                          <Typography
                            variant="sTextInfo"
                            component="div"
                            sx={{
                              color: theme.palette.primary.main,
                              mb: 2,
                              fontWeight: 800,
                            }}
                          >
                            {estate.property_type}
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() =>
                                setEditItems((prevArray: number[]) => [
                                  ...prevArray,
                                  index,
                                ])
                              }
                              size="small"
                              aria-label="delete"
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: '2rem',
                              }}
                            >
                              <EditOutlinedIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                onRemoveRealEstateProperty(
                                  estate.estate_id,
                                  index,
                                )
                              }
                              size="small"
                              aria-label="delete"
                              sx={{ color: theme.palette.error.main }}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{ fontSize: 20 }}
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                        <Typography variant="smText" component="div">
                          {estate.address}
                        </Typography>
                        <Typography
                          variant="smText"
                          component="div"
                          sx={{ color: theme.palette.grey[700] }}
                        >
                          {estate.city}
                        </Typography>
                        <Typography
                          variant="smText"
                          component="div"
                          sx={{ color: theme.palette.grey[700] }}
                        >
                          {estate.state}
                        </Typography>
                        <Typography
                          variant="smText"
                          component="div"
                          sx={{ color: theme.palette.grey[700] }}
                        >
                          {estate.zip_code}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.property_type`}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <InputLabel>Type</InputLabel>
                                <Select
                                  value={value ? value : ''}
                                  label="Type"
                                  onChange={onChange}
                                >
                                  <MenuItem value={''}>
                                    <em>Select Property Type</em>
                                  </MenuItem>
                                  <MenuItem
                                    value={RealEstatePropertyType.Residence}
                                  >
                                    Residence
                                  </MenuItem>
                                  <MenuItem
                                    value={RealEstatePropertyType.Vacation}
                                  >
                                    Vacation
                                  </MenuItem>
                                  <MenuItem
                                    value={RealEstatePropertyType.Rental}
                                  >
                                    Rental
                                  </MenuItem>
                                  <MenuItem value={RealEstatePropertyType.Land}>
                                    Land
                                  </MenuItem>
                                  <MenuItem
                                    value={RealEstatePropertyType.Other}
                                  >
                                    Other
                                  </MenuItem>
                                </Select>
                              </>
                            )}
                            rules={{
                              required: 'Please select the property type',
                            }}
                            control={control}
                          />

                          {errors?.fieldArray?.[index]?.property_type && (
                            <FormHelperErrorText>
                              {
                                errors?.fieldArray[index]?.property_type
                                  ?.message
                              }
                            </FormHelperErrorText>
                          )}
                        </FormControl>
                        <DescriptionField
                          control={control}
                          index={index}
                          errors={errors}
                        />
                        <AddressField
                          index={index}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                        />
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.city`}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                label="City"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            rules={{
                              required: 'Please enter the city',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.city && (
                            <FormHelperErrorText>
                              {errors?.fieldArray[index]?.city?.message}
                            </FormHelperErrorText>
                          )}
                        </FormControl>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.state`}
                            render={({ field: { onChange, value } }) => (
                              <StatesDropdown
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            rules={{
                              required: 'Please enter the state',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.state && (
                            <FormHelperErrorText>
                              {errors?.fieldArray[index]?.state?.message}
                            </FormHelperErrorText>
                          )}
                        </FormControl>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.zip_code`}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                label="Zip Code"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            rules={{
                              required: 'Please enter the zip code',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.zip_code && (
                            <FormHelperErrorText>
                              {errors?.fieldArray[index]?.zip_code?.message}
                            </FormHelperErrorText>
                          )}
                        </FormControl>
                        <Stack
                          direction={'row'}
                          spacing={2}
                          alignSelf={'flex-start'}
                        >
                          {estate.estate_id && (
                            <Button
                              disabled={isSubmitting}
                              onClick={handleSubmit(onUpdate)}
                              variant="contained"
                              color="primary"
                              sx={{ width: '6rem' }}
                            >
                              Save
                            </Button>
                          )}
                          <Button
                            onClick={() =>
                              resetAndRemove(estate, index)
                            }
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: '6rem',
                              color: theme.palette.common.naturalBlack,
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Stack>
                );
              })}

              <FormHelperErrorText>
                {errors?.fieldArray?.root?.message}
              </FormHelperErrorText>
              <Button
                onClick={() => append(defaultValues)}
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                sx={{
                  mt: 1,
                  width: '12rem',
                  color: theme.palette.text.primary,
                }}
              >
                Add Real Estate
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </TabLayout>
      <Loader open={loading} />
    </form>
  );
};

export default React.memo(EstateBox);
