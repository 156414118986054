/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import { useTheme } from '@mui/material/styles';

const ChevronIcon = (props: any) => {
  const theme = useTheme();

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.24652 11.64L2.45052 6.158C1.88452 5.513 2.34452 4.5 3.20352 4.5H12.7955C12.9878 4.49984 13.176 4.55509 13.3376 4.65914C13.4993 4.76319 13.6275 4.91164 13.707 5.08669C13.7864 5.26175 13.8137 5.456 13.7856 5.64618C13.7575 5.83636 13.6752 6.01441 13.5485 6.159L8.75252 11.639C8.65866 11.7464 8.54291 11.8325 8.41303 11.8915C8.28316 11.9505 8.14216 11.981 7.99952 11.981C7.85688 11.981 7.71589 11.9505 7.58601 11.8915C7.45614 11.8325 7.34038 11.7464 7.24652 11.639V11.64Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default ChevronIcon;
