import React from 'react';
import { apiUrls } from '../../variables/urls';
import { Route, Switch } from 'react-router-dom';
import AssetsBanner from './AssetsBanner';
import RealEstates from './RealEstateProperties';
import BusinessInterests from './BusinessInterests';
import Accounts from './Accounts';
import PersonalProperties from './PersonalProperties';
import useAssets from './assets-hooks';
import Loader from '../../components/Loader';
import CompletionScreen from './CompletionScreen';

const Assets = () => {
  const { loading, handleChange, forms, selectedForms } = useAssets();

  return (
    <Switch>
      <Route
        path={apiUrls.ASSETS}
        component={() => (
          <AssetsBanner
            handleChange={handleChange}
            forms={forms}
            selectedForms={selectedForms}
          />
        )}
        exact
      />
      <Route
        path={[apiUrls.ASSETS_REAL_ESTATE]}
        component={() => <RealEstates selectedForms={selectedForms} />}
        exact
      />
      <Route
        path={[apiUrls.ASSETS_ACCOUNTS]}
        component={() => <Accounts selectedForms={selectedForms} />}
        exact
      />
      <Route
        path={[apiUrls.ASSETS_BUSINESS]}
        component={() => <BusinessInterests selectedForms={selectedForms} />}
        exact
      />
      <Route
        path={[apiUrls.ASSETS_PERSONAL_PROPERTIES]}
        component={() => <PersonalProperties selectedForms={selectedForms} />}
        exact
      />
      <Route
        path={apiUrls.ASSETS_COMPLETION}
        component={CompletionScreen}
        exact
      />
      <Loader open={loading} />
    </Switch>
  );
};

export default React.memo(Assets);
