import gql from 'graphql-tag';

const query = gql`
  query Me {
    me {
      id
      email
      user_info {
        on_boarding_status
        plan_id
        company_id
      }
    }
  }
`;

export default query;
