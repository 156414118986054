import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SearchPlaceFragment } from '../../generated/graphql';

interface Props {
  place: SearchPlaceFragment
}

const MapRow = ({ place }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        pb: 3,
        mb: 3,
        borderBottom: '1px solid',
        borderColor: theme.palette.common.green,
      }}
    >
      <Grid item xs={10}>
        <Grid item>
          <Typography variant="mLonger">
            { place.name }
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="s">
            { place.vicinity }
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="mLink"
            color={theme.palette.primary.main}
          >
            { place.formatted_phone_number }
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="s">0.5 miles</Typography>
      </Grid>
    </Box>
  );
};

export default MapRow;
