import React, { useContext } from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import ChildrenIcon from '../../components/Icons/CompletionScreen/ChildrenIcon';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import Loader from '../../components/Loader';

interface Props {
  setShowCompletionScreen: Function;
}

const CompletionScreen = ({ setShowCompletionScreen }: Props) => {
  const history = useHistory();
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const onNextClick = () => {
    const hasUnderAgeChildren = planDocuments?.children?.find(
      (child) => Number(child?.age) < 18,
    );
    if (hasUnderAgeChildren) {
      history.push(apiUrls.GUARDIANSHIP);
    } else {
      history.push(apiUrls.PETS);
    }
  };

  const onPreviousClick = () => {
    setShowCompletionScreen(false);
    history.push(apiUrls.CHILDREN);
  };

  return (
    <>
      <CompletionBox
        header={'Children'}
        image={ChildrenIcon}
        title="Perfect! We will make sure your kids will get what they deserve."
        quote={
          'It only took me around 15 min to fully protect my family with a trust & will. ' +
          'The process was intuitive and easy. I highly recommend TruWill!'
        }
        quoteBy="Sam"
        previous={() => onPreviousClick()}
        continue={() => onNextClick()}
      />
      <Loader open={loadingPlanDocuments} />
    </>
  );
};

export default React.memo(CompletionScreen);
