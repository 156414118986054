import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Stack,
  Link, Breakpoint,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TopBar from '../../components/TopBar';
import { BREAKPOINTS, VALIDATOR_REGEX } from '../../variables/constant';
import useResetPassword from './reset-password-hooks';

const ResetPassword = () => {
  const {
    onResetPassword,
    register,
    handleSubmit,
    errors,
    showPassword,
    handleClickShowPassword,
  } = useResetPassword();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
    >
      <TopBar />
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onResetPassword)}
        noValidate
      >
        <Stack spacing={2} sx={{ mt: 2, p: 4 }}>
          <Typography
            variant="xlDisplay"
            sx={{
              textAlign: 'center',
            }}
          >
              Reset Password
          </Typography>
          <TextField
            label="Email"
            variant="outlined"
            {...register('email', {
              required: {
                value: true,
                message: 'Email is required',
              },
              pattern: {
                value: VALIDATOR_REGEX.email,
                message: 'Please enter correct email address.',
              },
            })}
          />
          {errors.email &&
          <Typography
            variant="helper"
            color="error.main"
          >
            { errors.email.message }
          </Typography>}
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            {...register('password', {
              required: {
                value: true,
                message: 'Password is required',
              },
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters',
              },
            })}
          />
          {
            errors.password &&
              <Typography
                variant="helper"
                color="error.main"
              >
                { errors.password.message }
              </Typography>
          }
          <Box display="flex" flexDirection="row-reverse" alignItems="center">
            <Typography
              variant="s"
              sx={{ cursor: 'pointer' }}
              color="primary"
              onClick={handleClickShowPassword}
            >
              {showPassword ? 'Hide password' : 'Show password'}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
                Reset Password
          </Button>
          <Link
            to="/login"
            component={RouterLink}
            sx={{
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                textAlign: 'center',
              },
            }}
          >
              Go back to Log In
          </Link>
        </Stack>
      </form>
    </Box>
  );
};

export default React.memo(ResetPassword);
