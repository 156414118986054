import React from 'react';
import {
  Box,
  Breakpoint,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Loader from '../../components/Loader';
import useLogin from './login-hook';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import TopBar from '../../components/TopBar';
import {
  BREAKPOINTS,
  // CognitoHostedUIIdentityProvider,
  VALIDATOR_REGEX,
} from '../../variables/constant';
import SignInIcon from '../../components/Icons/SignInIcon';
// import GoogleIcon from '../../components/Icons/GoogleIcon';
// import { Auth } from 'aws-amplify';
import { useAuthProvider } from '../../core/authContext';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import DisclaimerFooter from '../../components/DisclaimerFooter';

const Login = () => {
  const {
    register,
    errors,
    loading,
    handleSubmit,
    onUserLogin,
    showPassword,
    rememberMe,
    handleClickShowPassword,
    handleRememberMe,
  } = useLogin();
  const theme = useTheme();
  // const history = useHistory();

  // const googleSignIn = useCallback(async () => {
  //   await Auth.federatedSignIn({
  //     provider: CognitoHostedUIIdentityProvider.Google,
  //   });
  // }, []);

  const { getUser } = useAuthProvider();
  if (getUser()) {
    return <Redirect to={apiUrls.ROOT} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
      sx={{
        [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
          backgroundColor: theme.palette.common.white,
          mt: 6,
        },
      }}
    >
      <TopBar />
      <Grid
        container
        sx={{
          width: '80%',
          mt: 15,
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            mt: 5,
          },
        }}
      >
        <Grid
          item
          lg={6}
          sx={{
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
          }}
        >
          <Box
            component={SignInIcon}
            sx={{
              width: '554px',
              height: '523px',
            }}
          />
        </Grid>
        <Grid item lg={4} xs={12} sx={{ justifyContent: 'center' }}>
          <Box
            sx={{
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                textAlign: 'center',
              },
            }}
          >
            <Typography variant="xlDisplay">Log In</Typography>
          </Box>
          {/* <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: 50,
              backgroundColor: 'background.paper',
              p: 1,
              border: 1,
              borderColor: theme.palette.grey[500],
              borderRadius: '12px',
              marginTop: 3,
              cursor: 'pointer',
            }}
            onClick={googleSignIn}
          >
            <Box
              component={GoogleIcon}
              sx={{ height: '45px', width: '45px' }}
            />
            <Typography variant="mLonger">Log in with Google</Typography>
          </Box> */}
          <Divider sx={{ my: 6 }}>
            {/* <Typography variant="sInfo">OR</Typography> */}
          </Divider>
          <Typography variant="sCaps">log in with your email</Typography>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onUserLogin)}
            noValidate
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Email"
                variant="outlined"
                {...register('username', {
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value: VALIDATOR_REGEX.email,
                    message: 'Please enter correct email address.',
                  },
                })}
              />
              {errors.email && (
                <Typography variant="helper" color="error.main">
                  {errors.email.message}
                </Typography>
              )}
              <TextField
                variant="outlined"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                {...register('password', { required: true })}
              />
              {errors.password && (
                <FormHelperErrorText>Password is required.</FormHelperErrorText>
              )}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ justifyContent: 'space-between' }}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember me"
                  onChange={handleRememberMe}
                  checked={rememberMe}
                />
                <Typography
                  variant="s"
                  sx={{ cursor: 'pointer' }}
                  color="primary"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? 'Hide password' : 'Show password'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button variant="contained" color="primary" type="submit">
                  Login
                </Button>
                <Link
                  to="/login/recover"
                  component={RouterLink}
                  sx={{ textAlign: 'center', my: 2 }}
                >
                  Forgot Password?
                </Link>
              </Box>
              <Divider />
            </Stack>
            {/* <Box
              display="flex"
              sx={{
                mt: 4,
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  flexDirection: 'column',
                  mt: 3,
                },
              }}
            >
              <Typography variant="mLonger">Don’t have an account?</Typography>
              <Typography
                variant="mLonger"
                color="primary"
                onClick={() => history.push(apiUrls.CREATE_ACCOUNT + '/' + 'normal')}
                sx={{
                  cursor: 'pointer',
                  [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                    ml: 0.5,
                  },
                }}
              >
                Sign Up
              </Typography>
            </Box> */}
            <Box
              sx={{
                mt: 5,
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  maxWidth: '70%',
                },
              }}
            >
              <DisclaimerFooter />
            </Box>
          </form>
        </Grid>
      </Grid>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(Login);
