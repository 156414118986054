import React, { memo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import VisaIcon from '../../../components/Icons/VisaIcon';

interface CardInfoProps {
  cardType?: String;
  cardNumber?: String;
}

const CardInfoLine = ({ cardType, cardNumber }: CardInfoProps) => {
  let cardIcon = <Box component="p">{cardType}</Box>;
  switch (cardType) {
    case 'visa':
      cardIcon = <Box component={VisaIcon} />;
      break;
    default: // do nothing
  }
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0.5}
    >
      {cardIcon}
      <Typography variant="s" component="p">
        {`Card ending in ${cardNumber || ''}`}
      </Typography>
    </Stack>
  );
};

export default memo(CardInfoLine);
