import { Breakpoint, styled } from '@mui/system';
import { Button } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';

export const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.error.main,
  border: '2px solid',
  borderColor: theme.palette.error.main,
  textAlign: 'center',
  height: '100%',
  [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
    width: '100%',
  },
  [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
    width: 'auto',
  },
}));