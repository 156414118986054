import React from 'react';
import { BREAKPOINTS } from '../../variables/constant';
import { Box, Breakpoint, FormControl, Grid, TextField, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DeleteButton } from '../../components/DeleteButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { Control, Controller, FieldArrayWithId, UseFieldArrayAppend } from 'react-hook-form';
import { DataForm } from './pets-form-hooks';
import FormHelperErrorText from '../../components/FormHelperErrorText';

interface Props {
  control: Control<DataForm>;
  fields: FieldArrayWithId<DataForm, 'fieldArray', 'id'>[];
  append: UseFieldArrayAppend<any>;
  deleteAPet: Function;
  errors: any;
}

const PetsForm = ({ 
  control, 
  fields, 
  append, 
  deleteAPet, 
  errors,
}: Props) => {

  const theme = useTheme();

  return (
    <Stack direction="column" spacing={3}>
      {fields.map((pet, index) => {
        return (
          <Grid
            key={pet.id}
            item
            xs={12}
            md={12}
            sx={{
              mt: 4,
              display: 'flex',
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                flexDirection: 'column',
              },
            }}
          >
            <FormControl
              sx={{
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  width: '100%',
                },
              }}
            >
              <Controller
                name={`fieldArray.${index}.name`}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: 'Please add your Pet\'s name',
                }}
                control={control}
              />
              {errors?.fieldArray?.[index]?.name && (
                <FormHelperErrorText>
                  {errors?.fieldArray[index]?.name?.message}
                </FormHelperErrorText>
              )}
            </FormControl>
            <FormControl
              sx={{
                mt: 2,
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  width: '100%',
                  ml: 2,
                  mt: 0,
                },
              }}
            >
              <Controller
                name={`fieldArray.${index}.type`}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Type of Animal"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: 'Please add what animal your Pet is',
                }}
                control={control}
              />
              {errors?.fieldArray?.[index]?.type && (
                <FormHelperErrorText>
                  {errors?.fieldArray[index]?.type?.message}
                </FormHelperErrorText>
              )}
            </FormControl>
            <Box>
              {index !== 0 ? <DeleteButton
                sx={{
                  [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                    mt: 2,
                  },
                  [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                    ml: 2,
                  },
                }}
                onClick={() => deleteAPet(pet.pet_id, index)}
              ><DeleteOutlineIcon
                sx={{
                  color: theme.palette.error.main,
                  fontSize: '1.5rem',
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                }} /></DeleteButton> : <Grid sx={{ ml: 10 }}></Grid>}
            </Box>
          </Grid>
        );
      })}

      <FormHelperErrorText>
        {errors?.fieldArray?.root?.message}
      </FormHelperErrorText>

      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        sx={{
          mt: 1,
          width: '10rem',
          color: theme.palette.text.primary,
        }}
        onClick={() => append({
          id: '',
          name: '',
          type: '',
          primary_guardian_name: '',
          secondary_guardian_name: '',
          same_guardian_as_above: false,
        })}>Add Another
      </Button>
    </Stack>
  );
};

export default React.memo(PetsForm);
