import { useContext, useEffect } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';

const usePersonalRepresentative = () => {
  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } =
    useContext(DocumentContext);

  useEffect(() => {
    refetchPlanDocuments?.();
  }, [planDocuments, refetchPlanDocuments]);

  return {
    loading: loadingPlanDocuments,
    personalRepresentatives: planDocuments?.personal_representatives,
  };
};

export default usePersonalRepresentative;
