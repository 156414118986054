import React, { useState } from 'react';
import { Box, Breakpoint, Grid, Stack, Typography } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import HtmlTooltip from '../../components/HtmlTooltip';
import TabLayout from '../../components/TabLayout';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import StyledBannerButton from '../../components/StyledBannerButton';

interface Props {
  hasPets: boolean;
  onPetsQuestion: Function;
  UserInfoSetOption: Function;
}

const PetsBanner = ({ hasPets, onPetsQuestion, UserInfoSetOption }: Props) => {
  const theme = useTheme();
  const history = useHistory();
  const [buttonState, setButtonState] = useState(hasPets);

  const buttonStateSetter = (value: boolean) => {
    setButtonState(value);
    UserInfoSetOption(value);
  };

  return (
    <TabLayout
      title="Pets"
      previous={() => history.push(apiUrls.GUARDIANSHIP)}
      next={onPetsQuestion(buttonState)}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          py: 8,
          border: '0.063rem solid',
          borderColor: 'common.lightGreen',
          borderRadius: '0.5rem',
        }}
      >
        <Box
          sx={{
            ml: 10,
            mr: 10,
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              p: 4,
              ml: 3,
              mr: 3,
            },
          }}
        >
          <Typography variant="m" component="div" align="center">
            Do you have any pets?
            <HtmlTooltip
              title={
                <React.Fragment>
                  Why do you need to know if I have any pets?
                  <br />
                  Like many people, you probably consider your pets to be a part of
                  your family and want to make sure they’re taken care of after
                  you’re gone. But in the eyes of the law, pets are considered
                  property, and you can’t use your trust or will to leave them an
                  inheritance. However, you can still provide for your pets’ care
                  when you make a plan by naming a “pet guardian,” a.k.a. a
                  beneficiary for your pet.
                </React.Fragment>
              }
            />
          </Typography>
        </Box>
        <Grid item xs={11} sm={8} md={6} lg={5}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              width: '100%',
            }}
          >
            <StyledBannerButton
              onClick={() => buttonStateSetter(true)}
              value={buttonState}
              buttonText="Yes"
            />
            <StyledBannerButton
              onClick={() => buttonStateSetter(false)}
              value={!buttonState}
              buttonText="No"
            />
          </Stack>
        </Grid>
      </Grid>
    </TabLayout>
  );
};

export default React.memo(PetsBanner);
