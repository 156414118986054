import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { renderToString } from 'react-dom/server';
import InfoWindow from './InfoWindow';
import { SearchPlaceFragment } from '../../generated/graphql';

interface Props {
  places: Array<SearchPlaceFragment> | null
  map: google.maps.Map;
}


const Map = forwardRef(({ places, map }: Props, ref: ForwardedRef<HTMLElement>) => {
  const [markers, setMarkers] = useState<Array<google.maps.Marker>>([]);

  const removeAllMarkers = () => {
    if (markers?.length) {
      markers.forEach(marker => {
        marker.setMap(null);
      });
    }
  };

  const svgMarker = {
    url: 'images/marker.svg',
  };

  useEffect(() => {
    removeAllMarkers();
    if (places?.length) {
      const bounds = new google.maps.LatLngBounds();
      const newMarkers = places?.map((place, index) => {
        const position = new google.maps.LatLng({
          lat: place.location.lat,
          lng: place.location.lng,
        });
        const marker = new google.maps.Marker({
          position,
          label: {
            text: `${index + 1}`,
            color: '#ffffff',
            fontSize: '18px',
          },
          title: `${index + 1}. ${place.name}`,
          map,
          icon: svgMarker,
        });

        // Create an info window to share between markers.
        const infoWindow = new google.maps.InfoWindow({
          minWidth: 300,
        });
        const contentString = renderToString(<InfoWindow place={place} />);

        // Add a click listener for each marker, and set up the info window.
        marker.addListener('click', () => {
          infoWindow.close();
          infoWindow.setContent(contentString);
          infoWindow.open(marker.getMap(), marker);
        });

        bounds.extend(position);

        return marker;
      });
      map.fitBounds(bounds);
      setMarkers(newMarkers);
    }
  }, [places]);

  return <Box ref={ref} id="map" sx={{ height: '100%', width: '100%' }}/>;
});

export default Map;
