import { useContext, useEffect, useState } from 'react';
import { FeatureFlagContext } from '../../core/featureContext';
import { STORAGE_EVENT } from '../../variables/constant';

const DEV_TOOL = 'devtool';
const TRUE = 'true';

const useDevtool = () => {
  const [showDevtools, setShowDevtools] = useState(
    localStorage.getItem(DEV_TOOL) === TRUE,
  );
  const [showPanel, setShowPanel] = useState(false);
  const { featureFlags, featureFlagDispatch } = useContext(FeatureFlagContext);

  useEffect(() => {
    const handleChange = () => {
      const devToolStatus = localStorage.getItem(DEV_TOOL);
      if (showDevtools.toString() !== devToolStatus) {
        setShowDevtools(devToolStatus === TRUE);
      }
    };
    window.addEventListener(STORAGE_EVENT, handleChange, false);

    return () => window.removeEventListener(STORAGE_EVENT, handleChange);
  }, [showDevtools]);

  return {
    showDevtools,
    showPanel,
    setShowPanel,
    featureFlags,
    featureFlagDispatch,
  };
};

export default useDevtool;
