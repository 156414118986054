import { useAuthProvider } from '../../core/authContext';
import { usePlanDocumentQuery } from '../../generated/graphql';

const usePlanDocument = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const userID = user?.id;
  const {
    data: planDocuments,
    loading,
    refetch,
  } = usePlanDocumentQuery({
    fetchPolicy: 'network-only',
    skip: !userID,
  });

  return {
    loading,
    planDocuments,
    refetch,
  };
};

export default usePlanDocument;
