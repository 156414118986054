import { Button } from '@mui/material';
import React from 'react';

interface Props {
  onClick: Function;
}

const ApplePayButton = ({ onClick }: Props) => {
  return (
    <Button
      sx={{
        backgroundColor: 'black',
        '&:hover': {
          backgroundColor: 'black',
        },
      }}
      onClick={() => onClick()}
      fullWidth
    >
      <img src="/images/applePay.png" alt="Apple Pay" />
    </Button>
  );
};

export default ApplePayButton;
