import { useEffect, useState } from 'react';
import { useAllCountiesForStateLazyQuery } from '../../generated/graphql';

interface Props {
  state: string;
}

const useCounties = ({ state }: Props) => {
  const [counties, setCounties] = useState([] as string[]);

  const [getCounties, { data, loading }] = useAllCountiesForStateLazyQuery({
    variables: {
      state: state,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (state) {
      getCounties();
    }
  }, [state]);

  useEffect(() => {
    if (data?.counties) {
      setCounties(data.counties.map((county) => county.name));
    }
  }, [data]);

  return {
    counties,
    loading,
  };
};

export default useCounties;
