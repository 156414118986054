import { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  PersonalPropertiesFragmentFragment,
  useDeletePersonalPropertyMutation,
  usePersonalPropertyInputMutation,
} from '../../../generated/graphql';
import { NotifierType } from '../../../variables/types';
import { openSnackbar } from '../../../components/Notifier';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';
import getErrorMessage from '../../../utils/getErrorMessage';
import useUrlPush from '../url-push-hook';
import { Page } from '../constants';
import { SUMMARY } from '../../Summary/constants';

export interface DataForm {
  fieldArray: {
    property_id: string;
    description: string;
    beneficiary_owner_name: string;
  }[];
}

interface Props {
  propertyList: PersonalPropertiesFragmentFragment[];
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const usePersonalPropertiesForm = ({ propertyList, selectedForms, uiFlag, setEditState }: Props) => {
  const { nextForm } = useUrlPush();
  const previousAction = () => {
    nextForm(selectedForms[selectedForms.indexOf(Page.OtherItems) - 1]);
  };

  const defaultValues = {
    property_id: '',
    description: '',
    beneficiary_owner_name: '',
  };
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<DataForm>({
    defaultValues: {
      fieldArray: [defaultValues],
    },
  });

  const { fields, remove, append, replace } = useFieldArray({
    name: 'fieldArray',
    control,
    rules: {
      required: 'Please enter information of at least one personal property',
    },
  });

  useEffect(() => {
    let financialAccounts = [] as any;
    if (propertyList.length > 0) {
      propertyList.map((property) => {
        return financialAccounts.push({
          property_id: property?.id,
          description: property.description,
          beneficiary_owner_name: property.beneficiary_owner_name,
        });
      });
      replace(financialAccounts);
    }
  }, [propertyList]);

  const { refetchPlanDocuments } = useContext(DocumentContext);

  const [UpsertPersonalProperty, { loading }] =
    usePersonalPropertyInputMutation();

  const upsert = async (data: DataForm) => {
    await UpsertPersonalProperty({
      variables: {
        input: data.fieldArray.map((property: any) => {
          return {
            ID: property.property_id || null,
            description: property.description,
            beneficiary_owner_name: property.beneficiary_owner_name,
          };
        }),
      },
    });
  };

  const onCreate = async (data: DataForm) => {
    try {
      await upsert(data);
      openSnackbar(
        {
          message: 'Personal property added successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        nextForm(selectedForms[selectedForms.indexOf(Page.OtherItems) + 1]);
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const onUpdate = async (data: DataForm) => {
    let arr = data.fieldArray.filter(obj => obj.property_id !== '');
    try {
      await upsert({
        fieldArray: arr,
      });
      openSnackbar(
        {
          message: 'Personal property updated successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const [DeletePersonalProperty, { loading: deletingPersonalProperty }] =
    useDeletePersonalPropertyMutation();
  const onRemoveAccount = async (id: string, index: number) => {
    if (id) {
      try {
        await DeletePersonalProperty({
          variables: {
            input: id,
          },
        });
        refetchPlanDocuments?.();
        openSnackbar(
          {
            message: 'Personal property deleted successfully',
          },
          NotifierType.Success,
        );
      } catch (err) {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
    remove(index);
  };

  return {
    loading: loading || deletingPersonalProperty,
    handleSubmit,
    create: onCreate,
    onRemoveAccount,
    isSubmitting,
    errors,
    control,
    fields,
    remove,
    append,
    reset,
    defaultValues,
    previousAction,
    onUpdate,
  };
};
export default usePersonalPropertiesForm;
