import { useContext } from 'react';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';

const useBusinessInterest = () => {
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  return {
    loading: loadingPlanDocuments,
    businessInterests: planDocuments?.assets?.business_interests,
  };
};

export default useBusinessInterest;
