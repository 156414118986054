import { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  ChildOfType,
  ChildrenFragmentFragment,
  useChildrenInputMutation,
  useDeleteChildMutation,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import dateFormat from 'dateformat';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import getErrorMessage from '../../utils/getErrorMessage';
import { SUMMARY } from '../Summary/constants';

export interface DataForm {
  fieldArray: {
    child_id: string;
    full_name: string;
    date_of_birth: Date | null;
    child_of: ChildOfType;
  }[];
}

export interface ChildrenInfoProps {
  childrenList: ChildrenFragmentFragment[];
  setShowCompletionScreen: Function;
  uiFlag: string;
  setEditState: Function;
}

const useChildrenForm = ({
  childrenList,
  setShowCompletionScreen,
  uiFlag,
  setEditState,
}: ChildrenInfoProps) => {
  const [DeleteChild, { loading: deletingChild }] = useDeleteChildMutation();

  const defaultValues = {
    fieldArray: [
      {
        child_id: '',
        full_name: '',
        date_of_birth: null,
        child_of: ChildOfType.SpouseAndMe,
      },
    ],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<DataForm>({
    defaultValues,
  });

  const { fields, remove, append, replace } = useFieldArray({
    name: 'fieldArray',
    control,
    rules: {
      required: 'Please enter information of at least one child',
    },
  });

  useEffect(() => {
    let childrenData = [] as any;
    if (childrenList.length > 0) {
      childrenList.map((children) => {
        return childrenData.push({
          child_id: children.id,
          full_name: children.full_name,
          date_of_birth: new Date(children.date_of_birth),
          child_of: children.child_of as ChildOfType,
        });
      });
      replace(childrenData);
    }
  }, [childrenList]);

  const { refetchPlanDocuments } = useContext(DocumentContext);

  const [UpsertChildren, { loading: loadingChildren }] =
    useChildrenInputMutation();

  const onCreate = async (data: DataForm) => {
    try {
      await UpsertChildren({
        variables: {
          input: data.fieldArray.map((child: any) => {
            return {
              id: child?.child_id || null,
              full_name: child.full_name,
              date_of_birth: dateFormat(
                new Date(child.date_of_birth || new Date()),
                'isoUtcDateTime',
              ),
              child_of: child.child_of,
            };
          }),
        },
      });
      openSnackbar(
        {
          message:
            data.fieldArray.length > 1
              ? 'Added children successfully'
              : 'Added child successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        setEditState('');
        openSnackbar(
          {
            message:
              data.fieldArray.length > 1
                ? 'Please make sure to add Guardians for your children through the Guardianship form in Documents'
                : 'Please make sure to add a Guardian for your child through the Guardianship form in Documents',
          },
          NotifierType.Info,
        );
      } else {
        setShowCompletionScreen(true);
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const onRemoveChild = async (id: string, index: number) => {
    if (id) {
      try {
        await DeleteChild({
          variables: {
            input: id,
          },
        });
        refetchPlanDocuments?.();
        openSnackbar(
          {
            message: 'Child deleted successfully',
          },
          NotifierType.Success,
        );
      } catch (err) {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
    remove(index);
  };

  return {
    loading: loadingChildren || deletingChild,
    handleSubmit,
    create: onCreate,
    errors,
    control,
    fields,
    remove,
    append,
    onRemoveChild,
  };
};
export default useChildrenForm;
