import react from 'react';
import React from 'react';
import { Box, Breakpoint, Button, Stack, Typography } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import PersonPostBoxIcon from '../../components/Icons/PersonPostBoxIcon';
import useUserPlan from './Payment/user-plan-hooks';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';

const YouDidItPage = () => {
  const theme = useTheme();
  const history = useHistory();
  const { userPlan, loadingPlan } = useUserPlan();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
    >
      <TopBar />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{
          p: 2,
        }}
      >
        <Box
          component={PersonPostBoxIcon}
          sx={{
            width: '26.908rem',
            height: '24.25rem',
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
          }}
        />
        <Typography
          variant="lDisplay"
          component="p"
          sx={{ textAlign: 'center' }}
        >
          {`Your personalized ${
            userPlan?.userPlan?.name || 'Trust'
          } is on its way!`}
        </Typography>
        <Box
          component={PersonPostBoxIcon}
          sx={{
            width: '15.188rem',
            height: '13.688rem',
            [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
          }}
        />
        <Typography
          variant="s"
          component="p"
          sx={{
            maxWidth: '24rem',
            textAlign: 'center',
          }}
        >
          When it arrives, just follow the included instructions to have your
          documents notarized and legally finalized.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            maxWidth: '24rem',
          }}
          onClick={() => history.push(apiUrls.NEAR_BY_NOTARY)}
        >
          Next
        </Button>
        <Loader open={loadingPlan} />
      </Stack>
    </Box>
  );
};

export default react.memo(YouDidItPage);
