import React from 'react';
import { Box, FormControl, FormGroup, Typography } from '@mui/material';
import SelectorRow from './CheckboxRow';
import RadioButtonRow from './RadioButtonRow';

const SelectorList = () => (
  <Box>
    <Typography variant="xlDisplay">Selectors</Typography>
    <Box mt={2} width="100%">
      <Box display="grid" gridTemplateColumns="800px">
        <Typography variant="h6">Checkbox</Typography>
        <FormControl component="fieldset">
          <FormGroup>
            <SelectorRow mockupName="Selected" color="primary" checked />
            <SelectorRow mockupName="Default" color="primary" />
            <SelectorRow mockupName="Disabled" color="primary" disabled />
          </FormGroup>
        </FormControl>

        <Typography variant="h6">Radio Button</Typography>
        <FormControl component="fieldset">
          <FormGroup>
            <RadioButtonRow mockupName="Selected" color="primary" checked />
            <RadioButtonRow mockupName="Default" color="primary" />
            <RadioButtonRow mockupName="Disabled" color="primary" disabled />
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  </Box>
);

export default React.memo(SelectorList);
