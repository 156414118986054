import { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

const PersonPostBoxIcon = (props: any) => {
  const theme = useTheme();
  return (
    <SvgIcon
      width="431"
      height="389"
      viewBox="0 0 431 389"
      fill="none"
      {...props}
    >
      <path
        d="M300.299 388.553C300.928 386.697 329.481 257.858 352.399 216.223L361.063 219.934C361.063 219.934 332.961 296.511 310.043 384.124"
        fill={theme.palette.common.dustOrange}
      />
      <path
        d="M339.304 211.6L408.303 236.952C408.303 236.952 423.081 214.716 425.422 189.189C425.911 183.761 422.627 178.719 417.492 176.933L355.724 157.989C349.051 155.678 341.645 158.234 337.802 164.152L324.876 184.111C322.046 188.489 322.081 194.161 325.015 198.468L327.531 202.215C330.395 206.487 334.518 209.744 339.304 211.6Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M344.51 213.665C346.152 211.073 347.549 208.237 348.457 205.331C352.126 193.705 354.956 180.819 353.628 168.563C353.523 167.513 353.349 166.392 353.139 165.306C351.916 159.424 344.859 157.113 340.422 161.139C339.444 162.015 338.571 163.03 337.802 164.186L324.876 184.146C322.046 188.523 322.081 194.195 325.015 198.502L327.531 202.249C330.395 206.486 334.518 209.778 339.304 211.599L344.51 213.665Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M344.959 213.42L309.813 215.486C302.721 215.906 296.572 210.583 295.943 203.51C295.524 198.993 298.354 194.791 302.686 193.46L322.67 187.367L344.959 213.42Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M65.9247 223.189L60.0554 256.701L0 251.098L5.38018 216.816L65.9247 223.189Z"
        fill={theme.palette.common.lightBrown}
      />
      <path
        d="M28.8242 238.247L29.0687 238.212L62.2931 224.766C62.5377 224.731 62.7124 224.486 62.6774 224.206C62.6425 223.961 62.3979 223.785 62.1184 223.82L29.1386 237.232L7.12878 218.358C6.9541 218.183 6.63968 218.183 6.465 218.358C6.29031 218.533 6.29031 218.848 6.465 219.023L28.8242 238.247Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M59.0792 255.127C59.1491 255.127 59.2189 255.092 59.2888 255.057C59.4984 254.917 59.5683 254.637 59.4285 254.392L39.2005 234.047C39.0607 233.837 38.7812 233.767 38.5367 233.907C38.3271 234.047 38.2572 234.327 38.3969 234.572L58.625 254.917C58.7298 255.057 58.9045 255.127 59.0792 255.127Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M2.41434 250.364C2.48422 250.364 2.51915 250.329 2.55409 250.329L23.2014 234.291C23.446 234.186 23.5508 233.906 23.446 233.661C23.3412 233.416 23.0617 233.311 22.8171 233.416L2.16979 249.488C1.92524 249.593 1.82043 249.873 1.92524 250.119C2.03004 250.294 2.23966 250.399 2.41434 250.364Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M28.4372 199.027C28.4372 199.027 28.7865 206.521 23.5112 209.672C18.2358 212.824 14.9867 227.391 15.8601 233.379C15.8601 233.379 16.8383 236.075 18.4803 234.639C18.4803 234.639 17.4323 241.468 20.332 241.048C20.332 241.048 19.7031 247.666 22.8124 247.456C22.8124 247.456 24.6641 247.876 25.9218 244.759C25.9218 244.759 30.8827 245.6 31.9308 242.273C31.9308 242.273 38.953 234.604 40.8046 220.528L47.6172 221.368C47.6172 221.368 50.0977 217.656 45.5559 213.069C41.0142 208.517 44.3332 204.98 44.3332 204.98"
        fill={theme.palette.common.darkApricot}
      />
      <path
        d="M266.67 38.7568L260.626 28.672C260.626 28.672 268.416 15.8208 268.242 14.14C268.067 12.4592 265.971 11.3036 265.971 11.3036C265.971 11.3036 265.586 7.7669 264.294 6.78643C263.001 5.80596 261.289 5.49081 261.289 5.49081C261.289 5.49081 260.556 0.623471 257.726 0.553437C254.896 0.483404 253.254 5.6659 252.416 6.9265C251.577 8.18711 249.83 4.16017 246.441 6.85647C243.053 9.55276 244.345 24.8201 246.022 28.4268C247.664 32.0336 250.599 34.0996 250.599 34.0996C250.599 34.0996 253.918 48.4915 257.621 48.2464C261.324 47.9662 266.67 38.7568 266.67 38.7568Z"
        fill={theme.palette.common.darkApricot}
      />
      <path
        d="M251.999 22.684C251.719 22.649 251.545 22.4389 251.545 22.1938C251.649 20.8631 251.125 17.6066 250.147 13.3345C250.112 13.2294 250.112 13.1594 250.112 13.1594C250.077 12.9843 250.182 12.7742 250.322 12.6692C250.636 12.4941 250.811 12.6692 251.125 12.9843C251.894 13.6847 254.2 15.8207 256.436 16.0658C257.973 16.2059 259.021 15.6806 259.231 15.1204C259.405 14.5951 258.916 13.8948 257.938 13.2645C254.898 11.3035 251.859 7.69679 251.719 7.5217C251.545 7.34662 251.58 7.03147 251.789 6.8914C251.964 6.71632 252.278 6.75133 252.418 6.96143C252.453 6.99645 255.492 10.6382 258.427 12.4941C260.418 13.7547 260.244 14.9453 260.104 15.4005C259.72 16.486 258.183 17.1513 256.366 16.9412C254.374 16.7311 252.418 15.2954 251.3 14.315C251.894 16.9412 252.593 20.583 252.453 22.2638C252.453 22.5089 252.243 22.684 251.999 22.684Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M256.189 11.7922C256.154 11.7922 256.084 11.7922 256.049 11.7572C255.804 11.6872 255.699 11.4071 255.769 11.1619L256.922 8.01042C256.992 7.7653 257.272 7.66025 257.516 7.73028C257.761 7.80032 257.866 8.08045 257.796 8.32557L256.643 11.4771C256.538 11.6522 256.363 11.7922 256.189 11.7922Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M259.23 13.7195C259.195 13.7195 259.16 13.7195 259.09 13.6845C258.846 13.6144 258.706 13.3693 258.776 13.1242L259.37 11.1633C259.44 10.9181 259.684 10.7781 259.929 10.8481C260.173 10.9181 260.313 11.1633 260.243 11.4084L259.649 13.3693C259.614 13.6144 259.44 13.7195 259.23 13.7195Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M259.826 21.7737C259.337 21.7737 259.023 21.5286 258.883 21.3185C257.939 20.0929 259.302 16.5212 259.581 15.8208C259.686 15.5757 259.931 15.4707 260.175 15.5757C260.42 15.6808 260.525 15.9259 260.42 16.171C259.826 17.6767 259.162 20.1979 259.616 20.7932C259.651 20.8282 259.686 20.8983 259.931 20.8983C261.503 20.7232 263.32 17.3616 263.879 16.066C263.983 15.8559 264.228 15.7508 264.473 15.8208C264.682 15.9259 264.787 16.171 264.717 16.4161C264.473 16.9414 262.411 21.5286 260.036 21.7737C259.966 21.7737 259.896 21.7737 259.826 21.7737Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M66.0297 326.243C66.0297 326.243 51.0071 347.148 48.911 346.973C43.8103 346.588 43.2513 338.394 45.3475 326.979C47.4436 315.563 53.4177 306.949 53.4177 306.949L56.2476 304.673L53.278 304.428L56.9463 295.463C56.9463 295.463 64.248 293.958 70.3269 295.568C71.6894 295.919 74.659 298.265 75.1481 300.751C76.266 306.529 72.8772 315.878 66.0297 326.243Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M70.8893 316.757C70.8893 316.757 72.7759 312.835 71.3784 311.189C69.981 309.544 66.7319 303.311 68.4088 302.61C73.8239 300.264 73.6143 298.268 73.8938 298.303C74.1733 298.338 89.2658 302.26 89.6501 306.287C90.0344 310.314 87.9382 320.224 86.2613 320.924C84.5494 321.589 71.4483 315.952 70.8893 316.757Z"
        fill={theme.palette.common.darkApricot}
      />
      <path
        d="M205.215 270.148C205.215 270.148 239.837 315.985 243.086 339.936C246.335 363.888 215.905 375.863 198.507 371.836C181.109 367.809 74.9375 331.007 74.9375 331.007C74.9375 331.007 74.9375 320.362 80.143 308.876C83.6366 301.137 90.9383 295.57 90.9383 295.57L170.593 314.059C170.593 314.059 105.437 267.171 117.175 257.857C128.844 248.542 205.215 270.148 205.215 270.148Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M188.131 104.87L254.125 101.543L247.068 32.5948C247.068 32.5948 254.09 28.3578 256.746 27.9025C259.506 27.4123 267.96 29.1281 267.96 29.1281C267.96 29.1281 292.45 102.628 281.271 122.343C269.462 143.178 199.101 140.167 195.083 139.081C191.066 138.03 188.131 104.87 188.131 104.87Z"
        fill={theme.palette.common.chestnutRose}
      />
      <path
        d="M198.195 117.648L194.422 138.659C194.631 138.904 194.841 139.079 195.086 139.114C196.134 139.394 201.549 139.779 209.235 139.884C206.65 132.285 198.195 117.648 198.195 117.648Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M357.609 322.428C360.194 322.393 360.124 322.988 362.5 323.654C364.875 324.319 374.518 320.992 374.518 320.992C374.518 320.992 371.513 318.786 370.081 314.829C368.998 311.923 370.605 310.172 371.548 309.472C370.605 309.262 369.836 309.017 369.277 308.736C365.784 306.916 362.08 301.418 362.08 301.418C362.08 301.418 344.927 307.861 351.145 318.156C351.145 318.156 352.822 320.887 354.429 323.514C355.268 322.918 356.351 322.428 357.609 322.428Z"
        fill={theme.palette.common.darkApricot}
      />
      <path
        d="M404.878 310.136C397.82 309.716 386.256 310.696 386.256 310.696C386.256 310.696 376.614 310.556 371.513 309.436C370.605 310.136 368.963 311.887 370.046 314.793C371.513 318.75 374.483 320.956 374.483 320.956C374.483 320.956 364.806 324.318 362.465 323.617C360.124 322.917 360.159 322.357 357.574 322.392C356.316 322.427 355.233 322.917 354.395 323.442C355.757 325.683 357.085 327.889 357.329 328.345C357.818 329.325 363.059 337.799 363.059 337.799L373.016 336.959L371.723 331.811L373.435 331.076L376.789 335.138L411.9 314.863C411.935 314.898 411.935 310.556 404.878 310.136Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M173.355 60.0123C168.568 63.3739 167.101 78.5362 175.695 82.8783C184.29 87.2204 186.002 66.2803 184.814 64.1093C183.661 61.9032 175.416 58.5766 173.355 60.0123Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M168.044 96.6398C149.493 97.5853 132.339 110.051 125.247 127.245C116.513 148.36 111.971 165.903 109.631 197.173C106.207 242.17 109.875 255.476 109.875 255.476C109.875 255.476 118.994 268.748 169.721 267.662C185.233 267.312 214.335 254.04 224.012 242.73L201.897 184.112C201.897 184.112 209.199 122.027 192.884 106.515C187.853 101.717 178.42 96.1145 168.044 96.6398Z"
        fill={theme.palette.common.chestnutRose}
      />
      <path
        d="M171.851 96.6393C171.851 96.6393 164.794 96.1141 164.06 97.4447C162.244 100.771 175.135 124.163 181.703 119.505C188.271 114.883 189.075 105.674 187.363 102.872C185.651 100.071 180.341 97.5148 171.851 96.6393Z"
        fill={theme.palette.common.white}
      />
      <path
        d="M178.348 80.0052C178.348 80.0052 173.003 91.3156 168.741 95.4826C166.26 97.9338 173.772 107.598 178.837 109.664C183.868 111.73 183.03 101.015 183.379 98.459C183.729 95.8678 185.999 88.8644 186.034 87.7089C186.069 86.5533 180.305 79.1297 178.348 80.0052Z"
        fill={theme.palette.common.darkApricot}
      />
      <path
        d="M183.276 99.6866C183.311 99.1963 183.311 98.7761 183.346 98.461C183.521 97.0253 184.324 94.259 184.988 91.8428L178.77 88.166C178.804 88.201 180.097 96.5701 183.276 99.6866Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M205.814 66.1738C205.814 66.1738 205.709 75.3132 205.709 78.0796C205.674 80.8459 206.198 83.6472 205.604 87.7442C205.045 91.8412 202.18 98.8446 194.529 98.6345C186.878 98.4244 179.681 92.0163 178.528 87.6392C177.375 83.2621 177.934 81.4062 177.934 81.4062C177.934 81.4062 175.559 81.3711 174.65 78.1846C173.742 74.9981 172.869 70.7961 175.629 70.691C178.389 70.586 178.773 72.1267 179.437 71.9866C180.101 71.8466 183.594 64.7381 187.158 64.1078C190.791 63.5126 204.137 62.0769 205.814 66.1738Z"
        fill={theme.palette.common.darkApricot}
      />
      <path
        d="M197.704 73.7371C197.53 73.7371 197.39 73.6321 197.285 73.492C197.25 73.422 196.726 72.3714 195.154 72.0563C193.407 71.7061 190.577 72.0213 190.542 72.0213C190.298 72.0563 190.088 71.8812 190.053 71.6361C190.018 71.391 190.193 71.1809 190.438 71.1459C190.542 71.1459 193.442 70.8307 195.329 71.1809C197.39 71.6011 198.054 73.0368 198.089 73.1068C198.193 73.3169 198.089 73.597 197.879 73.7021C197.844 73.7021 197.774 73.7371 197.704 73.7371Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M188.309 81.513C188.309 82.4584 189.706 83.2288 191.488 83.2288C193.235 83.2288 194.702 82.4935 194.702 81.583C194.702 80.6376 193.304 79.8672 191.523 79.8672C189.741 79.8672 188.309 80.5675 188.309 81.513Z"
        fill={theme.palette.common.froly}
      />
      <path
        d="M221.843 294.344C217.057 286.816 212.515 280.268 209.371 275.855L161.299 288.111C161.299 288.111 202.593 297.006 221.843 294.344Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M203.082 82.3507C203.082 83.2962 203.921 84.0316 204.969 84.0666C206.017 84.0666 206.89 83.3312 206.89 82.3858C206.89 81.4403 206.052 80.7049 205.004 80.6699C203.955 80.6699 203.117 81.4053 203.082 82.3507Z"
        fill={theme.palette.common.froly}
      />
      <path
        d="M190.997 77.0289C190.858 77.0289 190.753 76.9939 190.648 76.8889C190.473 76.7138 190.508 76.4337 190.683 76.2586C191.731 75.3131 194.281 73.9825 196.517 75.6983C196.727 75.8384 196.762 76.1185 196.587 76.3286C196.447 76.5387 196.168 76.5737 195.958 76.3987C193.757 74.7178 191.347 76.8189 191.242 76.9239C191.207 76.9939 191.102 77.0289 190.997 77.0289Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M195.222 75.7326C195.222 75.7326 195.536 75.6976 195.956 76.1178C196.375 76.538 196.235 78.1488 195.117 78.8141C193.999 79.4444 193.091 78.7091 192.951 77.6936C192.811 76.6431 193.335 75.2774 195.222 75.7326Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M205.704 77.6257C205.564 77.6257 205.425 77.5557 205.32 77.4156C204.97 76.8903 204.551 76.6102 204.062 76.5402C202.979 76.4001 201.896 77.2755 201.861 77.2755C201.651 77.4156 201.372 77.4156 201.232 77.2055C201.092 77.0304 201.092 76.7153 201.302 76.5752C201.372 76.5402 202.699 75.4196 204.202 75.6297C204.935 75.7348 205.599 76.155 206.088 76.8903C206.228 77.1004 206.193 77.3806 205.983 77.5207C205.879 77.5907 205.809 77.6257 205.704 77.6257Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M205.108 76.8915C205.108 76.8915 205.388 76.9616 205.597 77.4518C205.807 77.9421 205.073 79.3777 203.816 79.6229C202.593 79.868 202.104 78.9575 202.348 78.0121C202.663 77.0316 203.676 75.9811 205.108 76.8915Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M177.65 70.0624C177.65 70.0624 179.118 75.595 181.423 76.3304C183.694 77.0658 184.603 68.7668 185.092 67.226C185.581 65.6853 187.223 64.1445 187.223 64.1445C187.223 64.1445 175.729 62.7789 177.65 70.0624Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M200.5 87.7814C199.312 87.7814 198.019 87.2562 196.691 86.1706C196.482 86.0306 196.447 85.7154 196.622 85.5403C196.761 85.3302 197.076 85.2952 197.25 85.4703C197.844 85.9605 199.661 87.3962 201.548 86.6959C202.107 86.4858 202.456 86.1706 202.596 85.7854C202.84 85.1551 202.596 84.2097 201.862 82.9491C200.884 81.1632 199.626 75.5255 201.233 73.8447C202.77 72.1989 205.81 73.7396 205.95 73.8097C206.159 73.9147 206.264 74.1949 206.159 74.405C206.054 74.6151 205.775 74.7201 205.565 74.6151C204.867 74.2649 202.805 73.5295 201.897 74.475C200.814 75.5955 201.617 80.638 202.666 82.5289C203.539 84.0696 203.784 85.2252 203.469 86.1356C203.225 86.8009 202.701 87.2912 201.862 87.5713C201.408 87.6764 200.954 87.7814 200.5 87.7814Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M201.2 67.1211C201.2 67.1211 206.301 68.3117 207.209 62.4639C208.118 56.6161 208.083 50.4881 196.519 47.5117C192.955 46.6013 189.113 48.4922 186.457 50.278C184.466 51.6087 181.986 51.9588 179.645 51.2585C177.304 50.5582 172.378 52.344 172.378 52.344C172.378 52.344 169.828 59.5925 176.466 64.0396C180.623 66.806 192.082 65.3002 193.689 65.7905C195.296 66.2807 200.537 68.4167 201.2 67.1211Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M193.76 87.1855C193.76 87.1855 199.245 89.0765 202.529 90.092C202.529 90.092 202.179 92.9633 198.546 92.298C194.913 91.6327 193.76 87.1855 193.76 87.1855Z"
        fill={theme.palette.common.white}
      />
      <path
        d="M148.271 253.163C148.271 253.163 256.609 201.689 286.724 216.081C316.839 230.507 371.689 303.027 371.689 303.027C371.689 303.027 361.802 317.734 345.906 322.567L270.688 259.186C270.688 259.186 213.916 287.725 178.177 288.916C142.437 290.106 122.942 273.088 118.75 268.081C114.558 263.073 115.047 259.396 115.047 259.396L148.271 253.163Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M179.604 106.41C179.604 106.41 170.87 94.4696 170.136 93.9093C169.403 93.349 168.984 94.7147 168.599 94.9248C168.215 95.1349 166.992 98.1113 166.992 98.4265C166.992 98.7416 175.097 112.048 176.006 111.768C176.949 111.453 179.15 109.947 179.15 109.947C179.15 109.947 182.155 112.048 182.818 111.803C183.482 111.558 184.845 105.57 184.495 103.364C184.39 102.558 183.238 99.9672 183.238 99.9672L179.604 106.41Z"
        fill={theme.palette.common.white}
      />
      <path
        d="M176.045 112.362C175.8 112.362 175.591 112.292 175.416 112.187C174.158 111.382 168.429 101.787 167.311 99.8614C167.171 99.6513 167.241 99.3711 167.451 99.2311C167.66 99.091 167.94 99.161 168.08 99.3711C170.665 103.748 175.067 110.857 175.905 111.382C176.429 111.732 178.071 110.296 179.259 108.826C179.364 108.721 179.504 108.651 179.643 108.651C179.783 108.651 179.923 108.756 179.993 108.896C180.657 110.086 181.949 111.662 182.683 111.347C183.382 111.032 184.115 106.865 184.325 103.188C184.325 102.943 184.569 102.733 184.814 102.768C185.059 102.768 185.268 103.013 185.233 103.258C185.128 105.184 184.639 111.452 183.067 112.187C181.67 112.818 180.202 110.927 179.539 109.911C178.735 110.822 177.233 112.362 176.045 112.362Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M264.259 121.047C260.486 121.047 254.792 104.939 253.709 101.683C253.639 101.438 253.744 101.193 253.988 101.123C254.233 101.053 254.477 101.158 254.547 101.403C257.028 108.651 261.814 120.137 264.259 120.137C264.434 120.137 264.434 120.102 264.469 120.067C265.273 118.561 260.067 109.247 256.119 103.084C256.015 102.908 256.015 102.663 256.189 102.523C256.364 102.383 256.608 102.348 256.748 102.488C256.853 102.593 268.731 111.453 272.539 111.278C272.574 111.278 272.609 111.278 272.644 111.278C272.085 109.947 264.818 105.08 257.901 100.983C257.691 100.842 257.622 100.562 257.726 100.352C257.866 100.142 258.111 100.072 258.355 100.177C258.39 100.212 262.268 102.488 266.041 104.974C273.902 110.157 273.692 111.067 273.587 111.558C273.518 111.838 273.308 112.153 272.609 112.188C272.574 112.188 272.504 112.188 272.469 112.188C269.185 112.188 261.674 107.111 258.146 104.589C260.941 109.071 266.391 118.386 265.273 120.487C265.063 120.837 264.714 121.047 264.259 121.047Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M125.246 127.244C123.045 132.567 121.124 137.679 119.412 142.827C122.941 142.582 127.867 141.391 133.596 137.574C145.44 129.696 147.92 121.432 147.92 121.432L126.539 124.478C126.05 125.354 125.631 126.299 125.246 127.244Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M139.885 128.471C116.303 136.175 76.2662 136.875 76.2662 136.875L50.1689 206.979C50.1689 206.979 41.7842 207.399 33.1898 205.053C29.7661 204.108 19.9141 199.871 19.9141 199.871C19.9141 199.871 30.919 124.409 53.9769 105.92C76.0916 88.1669 152.602 97.8666 156.165 99.3023C159.729 100.738 154.104 123.814 139.885 128.471Z"
        fill={theme.palette.common.chestnutRose}
      />
      <path
        d="M76.2663 137.328C76.1615 137.328 76.0217 137.293 75.9519 137.188C75.9169 137.153 71.5499 132.425 67.3925 127.593C58.7283 117.543 59.2174 116.668 59.4969 116.213C59.6017 116.038 59.8463 115.757 60.4052 115.863C63.3748 116.423 69.7682 124.407 72.7028 128.258C72.1788 126.473 71.48 123.881 70.8512 121.29C68.4406 111.485 69.314 110.96 69.7682 110.68C70.3621 110.33 70.9909 110.96 71.2005 111.17C75.1833 115.162 78.8166 131.34 78.9564 132.04C79.0263 132.285 78.8516 132.531 78.607 132.566C78.3625 132.636 78.1179 132.461 78.083 132.215C78.048 132.04 74.3448 115.582 70.5717 111.766C70.397 111.59 70.2573 111.485 70.1874 111.45C69.5585 112.676 71.7246 121.92 74.24 130.324C74.3099 130.535 74.2051 130.745 73.9954 130.85C73.7858 130.955 73.5762 130.885 73.4365 130.71C70.7813 127.033 63.27 117.403 60.3354 116.703C60.7895 118.594 68.8249 128.048 76.6506 136.522C76.8253 136.698 76.8253 136.978 76.6157 137.153C76.4759 137.293 76.3711 137.328 76.2663 137.328Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M201.86 184.566C201.79 184.566 201.756 184.566 201.686 184.531C201.441 184.426 175.798 173.466 142.294 174.972C142.049 174.972 141.84 174.797 141.84 174.552C141.84 174.306 142.015 174.096 142.259 174.096C175.973 172.626 201.79 183.621 202.07 183.761C202.315 183.866 202.419 184.111 202.314 184.356C202.21 184.461 202.035 184.566 201.86 184.566Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M270.684 259.644C270.579 259.644 270.439 259.609 270.369 259.504C270.194 259.329 270.194 259.049 270.404 258.874C270.474 258.839 275.819 253.761 281.584 249.104C293.497 239.474 295.593 240.805 296.292 241.26C296.816 241.575 296.921 242.031 296.921 242.346C296.921 244.517 291.121 248.299 282.981 253.341C280.046 255.162 276.728 257.193 275.819 258.103C279.068 258.033 297.864 252.431 309.568 248.754C309.812 248.684 310.057 248.824 310.127 249.034C310.196 249.279 310.057 249.524 309.847 249.594C304.432 251.31 277.217 259.819 275.086 258.874C274.841 258.769 274.701 258.594 274.701 258.348C274.631 257.543 276.238 256.458 282.527 252.536C287.907 249.209 296.047 244.167 296.047 242.311C296.047 242.206 296.012 242.101 295.838 241.996C293.113 240.28 279.208 251.835 271.068 259.504C270.893 259.609 270.788 259.644 270.684 259.644Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M215.314 364.132C215.279 364.132 215.244 364.132 215.209 364.132C214.44 364.062 213.288 363.046 211.052 355.938C204.868 336.364 170.665 314.723 170.316 314.513C170.106 314.373 170.036 314.093 170.176 313.883C170.316 313.673 170.595 313.603 170.805 313.743C171.154 313.953 205.636 335.768 211.925 355.658C214.196 362.906 215.244 363.222 215.314 363.257C215.314 363.257 215.454 363.117 215.593 362.486C216.292 359.37 215.174 350.195 213.078 347.289C209.41 342.211 186.876 323.162 186.631 322.952C186.457 322.777 186.422 322.497 186.561 322.322C186.736 322.147 187.015 322.112 187.19 322.252C188.133 323.022 210.038 341.581 213.777 346.729C216.222 350.09 217.48 361.226 216.117 363.572C215.873 364.027 215.523 364.132 215.314 364.132Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M226.178 349.321C226.038 349.321 225.898 349.251 225.793 349.111C225.758 349.041 221.007 341.863 207.487 332.128C193.897 322.358 177.337 316.16 177.162 316.125C176.918 316.055 176.813 315.775 176.883 315.53C176.952 315.285 177.232 315.18 177.476 315.25C177.651 315.32 194.316 321.553 208.011 331.393C221.706 341.232 226.352 348.341 226.562 348.621C226.702 348.831 226.632 349.111 226.422 349.251C226.352 349.286 226.248 349.321 226.178 349.321Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M27.32 244.935L26.6562 244.725C28.6476 238.632 29.5909 234.325 30.5691 229.773C30.7787 228.757 31.0233 227.742 31.2329 226.691L31.9316 226.831C31.6871 227.882 31.4775 228.897 31.2678 229.913C30.2547 234.5 29.3114 238.807 27.32 244.935Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M20.8552 240.768L20.1914 240.558L20.2962 240.243C22.2876 234.43 23.72 230.228 24.803 224.275L25.5017 224.415C24.3838 230.438 22.8815 234.886 20.96 240.488L20.8552 240.768Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M20.2563 224.506L18.1602 235.084L18.8455 235.22L20.9416 224.642L20.2563 224.506Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M394.254 202.25L404.49 147.239L430.518 144.438L428.666 162.156L405.399 163.101L398.9 204.106L394.254 202.25Z"
        fill={theme.palette.common.chestnutRose}
      />
      <path
        d="M402.533 215.697C390.48 213.351 378.567 209.919 367.107 205.472L367.352 204.807C378.776 209.254 390.654 212.65 402.673 214.997L402.533 215.697Z"
        fill={theme.palette.common.metalBlue}
      />
      <path
        d="M400.719 223.473C392.718 222.038 384.893 219.656 377.451 216.4L377.731 215.77C385.137 218.991 392.893 221.372 400.858 222.808L400.719 223.473Z"
        fill={theme.palette.common.metalBlue}
      />
    </SvgIcon>
  );
};

export default memo(PersonPostBoxIcon);
