import * as React from 'react';
import { Breakpoint, Stack } from '@mui/material';
import SwipeableViews, { OnChangeIndexCallback } from 'react-swipeable-views';
import { PlanInfoFragmentFragment } from '../../generated/graphql';
import { BREAKPOINTS } from '../../variables/constant';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PlanDetails from './PlanDetails';

interface Props {
  planInfo: PlanInfoFragmentFragment[];
  recommendedPlan: string;
  activeStep: number;
  handleStepChange?: OnChangeIndexCallback | undefined;
  onPlanSelection: Function;
}

const PlanView = ({
  planInfo,
  activeStep,
  handleStepChange,
  recommendedPlan,
  onPlanSelection,
}: Props) => {
  const theme = useTheme();
  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint),
  );

  const styles = {
    root: {
      padding: activeStep ? '0 0 0 1.563rem' : '0 1.563rem 0 0',
      width: 'clamp(21.875rem, 100vw, 37.5rem)',
    },
    slideContainer: {
      padding: '0 0.5rem',
    },
  };

  if (!desktopView) {
    return (
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        style={styles.root}
        slideStyle={styles.slideContainer}
      >
        {planInfo?.map((plan, index) => {
          return (
            <PlanDetails
              key={index}
              plan={plan}
              onPlanSelection={onPlanSelection}
              recommendedPlan={recommendedPlan}
            />
          );
        })}
      </SwipeableViews>
    );
  } else {
    return (
      <Stack spacing={2} direction="row" sx={{ display: 'flex' }}>
        {planInfo?.map((plan, index) => {
          return (
            <PlanDetails
              key={index}
              plan={plan}
              onPlanSelection={onPlanSelection}
              recommendedPlan={recommendedPlan}
            />
          );
        })}
      </Stack>
    );
  }
};

export default React.memo(PlanView);
