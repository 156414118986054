import React, { useCallback } from 'react';
import { withGoogle } from 'aws-amplify-react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import GoogleIcon from '../../components/Icons/GoogleIcon';

const SocialMediaSignUp = ({ provider }: any) => {
  const theme = useTheme();
  const signUp = useCallback(async () => {
    await Auth.federatedSignIn({
      provider: provider,
    });
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 50,
        backgroundColor: 'background.paper',
        p: 1,
        border: 1,
        borderColor: theme.palette.grey[500],
        borderRadius: '12px',
        marginTop: 3,
        cursor: 'pointer',
      }}
      onClick={signUp}
    >
      <Box component={GoogleIcon} sx={{ height: '45px', width: '45px' }} />
      <Typography variant="mLonger">Sign up with Google</Typography>
    </Box>
  );
};

export default withGoogle(SocialMediaSignUp);
