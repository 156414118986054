import React from 'react';
import { Container, Typography, Box, Radio, Grid, FormControl, TextField, FormControlLabel, Stack } from '@mui/material';
import { Breakpoint, useTheme } from '@mui/material/styles';
import HtmlTooltip from '../../components/HtmlTooltip';
import TabLayout from '../../components/TabLayout';
import { BREAKPOINTS } from '../../variables/constant';
import { StyledSwitch } from '../../components/StyledSwitch/StyledSwitch.styled';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import { PetsFragmentFragment, ChildrenFragmentFragment } from '../../generated/graphql';
import useGuardiansForm from './guardian-form-hooks';
import { Controller } from 'react-hook-form';
import Loader from '../../components/Loader';

interface Props {
  guardians: ChildrenFragmentFragment[];
  petsList: PetsFragmentFragment[];
  setShowGuardianBanner: Function;
  uiFlag?: string;
  setEditState?: Function;
}

const GuardianBanner = ({ 
  guardians, 
  petsList, 
  setShowGuardianBanner,
  uiFlag, 
  setEditState, 
}: Props) => {

  const {
    loading,
    errors,
    control,
    fields,
    watched,
    radioKey,
    setRadioKey,
    create,
    handleSubmit,
  } = useGuardiansForm({
    guardians,
    petsList,
    uiFlag, 
    setEditState, 
  });
  const theme = useTheme();

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout
        title="Pets"
        previous={() => setShowGuardianBanner(false)}
        uiFlag={uiFlag}
      >
        <Container
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Typography variant="m" component="div" textAlign="center">
            {guardians.length ? 'Do you want to appoint the same guardians for your pets as your children?' : 'Please appoint guardians for your pets.'}
            <HtmlTooltip
              title={
                <React.Fragment>
                  What Should I Consider When Appointing a Guardian for my Pets?
                  <br />
                  When considering who you should appoint to serve as
                  guardians over your Pets, there are a few
                  things to consider:
                  <br />
                  (1) Familiarity with your Pets and their care;
                  <br />
                  (2) Domestication style and similarity to you and your
                  spouse;
                </React.Fragment>
              }
            />
          </Typography>
          <Container sx={{ mt: 4 }}>
            {guardians.map((data, key) => (
              <Box key={key} sx={{ 
                border: '1px solid',
                borderColor: theme.palette.common.lightGreen,
                borderRadius: 1,
                py: 2.5,
                px: 2,
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  my: 1,
                  mx: 5,
                },
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  my: 1,
                },
              }}>
                <Grid sx={{
                  display: 'flex',
                }}>
                  <Radio
                    checked={radioKey === key}
                    onChange={() => setRadioKey(key)}
                    value={data.full_name}
                    name="guardian-buttons"
                  />
                  <Typography fontWeight="600" variant='body1' component="div" sx={{ mt: 1 }}>
                    {data.full_name}
                    <Typography fontWeight="400" variant='body1' component="span">
                      ({data.age} Years Old)
                    </Typography>
                  </Typography>
                </Grid>
                <Typography fontWeight="400" variant='body1' component="div" sx={{ mt: 0.5, ml: 5 }}>
                  Primary Legal Guardian:
                  <Typography fontWeight="600" variant='body1' component="span">
                    {' ' + data.primary_guardian_name}
                  </Typography>
                </Typography>
                <Typography fontWeight="400" variant='body1' component="div" sx={{ mt: 0.5, ml: 5 }}>
                  Secondary Legal Guardian:
                  <Typography fontWeight="600" variant='body1' component="span">
                    {' ' + data.secondary_guardian_name}
                  </Typography>
                </Typography>
              </Box>
            ))}
            <Box sx={{ 
              border: '1px solid',
              borderColor: theme.palette.common.lightGreen,
              borderRadius: 1,
              py: 2.5,
              px: 2,
              [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                my: 1,
                mx: 5,
              },
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                my: 1,
              },
            }}>
              <Grid sx={{
                display: 'flex',
              }}>
                <Radio
                  checked={radioKey === guardians.length + 1}
                  onChange={() => setRadioKey(guardians.length + 1)}
                  value="new_guardian"
                  name="guardian-buttons"
                />
                <Typography fontWeight="600" variant='body1' component="div" sx={{ mt: 1 }}>
                  {guardians.length ? 'Appoint Different Guardians' : 'Appoint Guardians'}
                </Typography>
              </Grid>
              {radioKey === guardians.length + 1 ? fields.map((pet, index) => {
                return (
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      width: '100%',
                      mt: 1,
                    }}
                    key={pet.id}
                  >
                    {pet.name && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignSelf: 'baseline',
                          my: 2,
                        }}
                      >
                        <Typography
                          variant="smText"
                          sx={{
                            alignSelf: 'flex-start',
                            fontWeight: 600,
                          }}
                        >
                          {pet.name}
                          <Typography
                            variant="smText"
                            sx={{
                              alignSelf: 'flex-start',
                              color: theme.palette.grey[700],
                            }}
                          >
                            ({pet.type})
                          </Typography>
                        </Typography>
                        {index !== 0 && (
                          <Controller
                            name={`fieldArray.${index}.same_guardian_as_above`}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel
                                control={
                                  <StyledSwitch
                                    sx={{ mx: 1.3, my: 1 }}
                                    checked={value}
                                    onChange={onChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                label="Same Legal Guardians as Above"
                              />
                            )}
                            control={control}
                          />
                        )}
                      </Box>
                    )}
                    {(!watched[index].same_guardian_as_above || index === 0) && (
                      <>
                        <Typography
                          variant="smText"
                          sx={{
                            alignSelf: 'flex-start',
                          }}
                        >
                          Primary Legal Guardian
                        </Typography>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.primary_guardian_name`}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                label="Full Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            rules={{
                              required: 'Please name the primary guardian',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.primary_guardian_name && (
                            <FormHelperErrorText>
                              {
                                errors?.fieldArray[index]?.primary_guardian_name
                                  ?.message
                              }
                            </FormHelperErrorText>
                          )}
                        </FormControl>
                        <Typography
                          variant="smText"
                          sx={{
                            alignSelf: 'flex-start',
                          }}
                        >
                          Secondary Legal Guardian
                          <Typography
                            variant="smText"
                            sx={{
                              color: theme.palette.grey[700],
                            }}
                          >
                            {' (Optional)'}
                          </Typography>
                        </Typography>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.secondary_guardian_name`}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                label="Full Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            control={control}
                          />
                        </FormControl>
                      </>
                    )}
                  </Stack>
                );
              }) : null}
            </Box>
          </Container>
      </Container>
    </TabLayout>
    <Loader open={loading} />
    </form>
  );
};

export default React.memo(GuardianBanner);
