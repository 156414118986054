import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { apiUrls } from '../../variables/urls';

interface State {
  stateLoading: boolean;
  showPassword: boolean;
}

const useResetPassword = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const search = useLocation().search;
  const confirmationCode = new URLSearchParams(search).get('confirmation_code');

  if (!confirmationCode) {
    openSnackbar({ message: 'Invalid reset code' }, NotifierType.Error);
    history.push(apiUrls.LOGIN);
  }

  const [values, setValues] = useState<State>({
    stateLoading: false,
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const setLoading = useCallback(
    (loading: boolean) => {
      setValues({
        ...values,
        stateLoading: loading,
      });
    },
    [values],
  );

  const onResetPassword = useCallback(
    async (data: Record<string, string>) => {
      setLoading(true);
      try {
        const resetCode = confirmationCode?.trim();

        await Auth.forgotPasswordSubmit(
          data.email.trim(),
          resetCode || '',
          data.password.trim(),
        );
        setLoading(false);
        openSnackbar(
          { message: 'The Password is updated successfully.' },
          NotifierType.Success,
        );
        history.push(apiUrls.LOGIN);
      } catch (err: any) {
        setLoading(false);
        openSnackbar({ message: err?.message }, NotifierType.Error);
      }
    },
    [history, setLoading, confirmationCode],
  );

  return {
    loading: values.stateLoading,
    register,
    errors,
    handleSubmit,
    onResetPassword,
    setLoading,
    showPassword: values.showPassword,
    handleClickShowPassword,
  };
};

export default useResetPassword;
