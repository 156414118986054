/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';

const PersonalRepresentativeIcon = (props: any) => {
  return (
    <svg
      width="209"
      height="220"
      viewBox="0 0 209 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.6748 211.822C81.6748 215.943 63.4546 219.283 40.9741 219.283C18.4937 219.283 0.273438 215.943 0.273438 211.822C0.273438 207.701 18.4937 204.361 40.9741 204.361C63.4546 204.361 81.6748 207.701 81.6748 211.822Z"
        fill="#CADCE3"
      />
      <path
        d="M202.808 211.822C202.808 215.943 184.588 219.283 162.107 219.283C139.63 219.283 121.41 215.943 121.41 211.822C121.41 207.701 139.63 204.361 162.107 204.361C184.588 204.361 202.808 207.701 202.808 211.822Z"
        fill="#CADCE3"
      />
      <path
        d="M146.723 48.1738C141.798 57.761 141.253 87.3267 133.947 112.546C136.088 114.221 139.172 115.724 141.99 116.644C141.99 116.644 156.927 89.9038 158.794 55.1613C159.128 49.0303 150.596 40.6264 146.723 48.1738Z"
        fill="#549E7B"
      />
      <path
        d="M161.119 122.735L118.604 115.368L124.367 85.9824L166.187 93.511L161.119 122.735Z"
        fill="#8C8C8C"
      />
      <path
        d="M119.979 115.603L125.933 86.916L166.187 93.5091L161.119 122.733L119.979 115.603Z"
        fill="#E2E2E2"
      />
      <path
        d="M146.291 104.56C146.291 106.333 144.856 107.768 143.083 107.768C141.31 107.768 139.875 106.333 139.875 104.56C139.875 102.787 141.314 101.352 143.083 101.352C144.856 101.348 146.291 102.787 146.291 104.56Z"
        fill="#8C8C8C"
      />
      <path
        d="M137.267 119.546C137.049 121.206 134.799 120.849 135.092 119.136C135.227 118.347 135.517 116.747 135.859 116.029C136.358 114.989 137.429 115.195 137.617 116.405C137.741 117.187 137.369 118.764 137.267 119.546Z"
        fill="#F4B998"
      />
      <path
        d="M139.262 120.048C139.045 121.637 136.798 121.295 137.091 119.654C137.226 118.899 137.516 117.362 137.857 116.675C138.357 115.675 139.424 115.874 139.616 117.035C139.736 117.783 139.364 119.297 139.262 120.048Z"
        fill="#F4B998"
      />
      <path
        d="M141.272 120.484C141.054 121.754 138.804 121.48 139.097 120.172C139.236 119.568 139.522 118.343 139.863 117.794C140.363 116.998 141.434 117.156 141.622 118.08C141.746 118.681 141.377 119.887 141.272 120.484Z"
        fill="#F4B998"
      />
      <path
        d="M135.254 118.908C135.036 120.178 132.786 119.904 133.079 118.592C133.214 117.988 133.503 116.763 133.845 116.218C134.345 115.422 135.412 115.58 135.603 116.504C135.727 117.101 135.355 118.307 135.254 118.908Z"
        fill="#F4B998"
      />
      <path
        d="M152.655 194.631L151.971 204.47C151.971 204.47 152.283 206.555 152.268 207.253C152.253 207.941 151.851 209.985 151.851 209.985C150.968 210.601 148.214 210.74 146.832 210.311L146.569 210.079C146.569 210.079 146.298 210.229 144.334 210.642C142.327 211.063 136.414 211.052 135.55 210.311C135.55 210.311 134.874 207.347 137.338 206.795C143.507 205.413 144.446 203.793 144.923 201.923C145.524 199.563 146.291 193.646 146.291 193.646L152.655 194.631Z"
        fill="#207089"
      />
      <path
        d="M143.312 204.214C143.312 204.214 144.954 204.068 145.848 204.999C146.693 205.878 147.354 206.622 148.673 206.397C152.05 205.815 151.934 203.2 153.342 204.41L153.203 211.224L134.585 211.664L134.638 206.498L143.312 204.214Z"
        fill="#1C3742"
      />
      <path
        d="M135.137 209.572C135.137 209.572 135.366 210.462 141.219 210.3C147.072 210.135 147.617 208.82 152.178 209.023L151.915 210.616L134.769 211.796L135.137 209.572Z"
        fill="#207089"
      />
      <path
        d="M168.797 99.1188C166.521 89.4188 152.854 88.4308 151.509 98.2735C149.582 112.395 144.059 203.677 144.059 203.677C146.711 205.829 150.532 204.714 152.929 203.944C152.929 203.944 174.752 124.484 168.797 99.1188Z"
        fill="#19323A"
      />
      <path
        d="M176.77 194.861V204.726C176.77 204.726 176.315 206.785 176.285 207.484C176.255 208.171 176.514 210.234 176.514 210.234C177.352 210.91 180.09 211.241 181.496 210.906L181.774 210.692C181.774 210.692 182.033 210.861 183.964 211.41C185.936 211.969 191.838 212.364 192.747 211.684C192.747 211.684 193.626 208.772 191.207 208.051C185.151 206.248 184.324 204.569 183.975 202.664C183.535 200.267 183.182 194.316 183.182 194.316L176.77 194.861Z"
        fill="#207089"
      />
      <path
        d="M185.425 205.063C185.425 205.063 183.798 204.804 182.844 205.672C181.939 206.491 181.229 207.19 179.929 206.87C176.6 206.055 176.897 203.455 175.409 204.564L175.079 211.371L193.622 213.092L193.927 207.933L185.425 205.063Z"
        fill="#1C3742"
      />
      <path
        d="M193.209 210.975C193.209 210.975 192.92 211.85 187.093 211.283C181.267 210.715 180.816 209.367 176.251 209.254L176.402 210.862L193.427 213.225L193.209 210.975Z"
        fill="#207089"
      />
      <path
        d="M186.038 95.9539C181.391 87.1518 167.517 89.9055 167.175 99.8572C166.104 131.388 176.3 204.374 176.3 204.374C179.148 205.978 182.653 205.407 184.723 204.073C184.723 204.073 194.757 112.476 186.038 95.9539Z"
        fill="#1C3742"
      />
      <path
        d="M153.669 102.741C153.797 107.936 163.602 113.913 172.07 113.707C180.538 113.5 186.928 109.131 186.804 103.935C186.394 87.1764 179.448 78.209 170.98 78.4156C162.513 78.6222 153.252 85.6586 153.669 102.741Z"
        fill="#1C3742"
      />
      <path
        d="M151.253 100.406C151.328 103.449 160.367 104.775 170.229 104.531C180.086 104.29 187.465 102.679 187.393 99.6357C187.153 89.8193 178.588 84.3081 168.73 84.5486C158.868 84.789 151.009 90.3978 151.253 100.406Z"
        fill="#3C8460"
      />
      <path
        d="M193.739 46.988C190.004 40.7367 170.435 35.0603 169.534 35.034C163.305 34.8536 149.27 43.6745 147.846 46.8753C146.606 49.6628 152.042 73.0035 151.513 83.5976C151.235 89.12 150.528 93.2487 150.664 98.8087C150.791 104.004 187.78 104.079 187.653 98.8838C187.547 94.5786 186.755 91.329 186.804 86.8359C186.905 77.8497 195.343 49.6741 193.739 46.988Z"
        fill="#71BF9D"
      />
      <path
        opacity="0.3"
        d="M187.653 98.8837C187.547 94.5784 186.755 91.3288 186.804 86.8357C186.905 77.8496 195.343 49.6739 193.739 46.9878C192.123 44.2867 186.233 46.1613 185.447 49.3057C178.768 64.8888 178.817 78.7475 184.272 101.258C186.383 100.653 187.675 99.8604 187.653 98.8837Z"
        fill="#549E7B"
      />
      <path
        d="M167.258 52.3108C167.269 52.7879 167.667 53.1636 168.141 53.1523C168.618 53.1411 168.993 52.7466 168.982 52.2695C168.971 51.7924 168.576 51.4167 168.099 51.428C167.626 51.4393 167.25 51.8337 167.258 52.3108Z"
        fill="white"
      />
      <path
        d="M166.104 61.5179C166.116 61.995 166.514 62.3706 166.991 62.3594C167.468 62.3481 167.844 61.9499 167.832 61.4765C167.821 60.9994 167.427 60.6237 166.946 60.635C166.472 60.6463 166.093 61.0407 166.104 61.5179Z"
        fill="white"
      />
      <path
        d="M165.436 70.4864C165.447 70.9636 165.841 71.3392 166.319 71.328C166.796 71.3167 167.171 70.9222 167.16 70.4414C167.149 69.9643 166.751 69.5886 166.277 69.5999C165.8 69.6149 165.421 70.0093 165.436 70.4864Z"
        fill="white"
      />
      <path
        d="M164.771 78.7639C164.782 79.2411 165.18 79.6167 165.657 79.6092C166.131 79.5979 166.51 79.2035 166.499 78.7226C166.487 78.2455 166.089 77.8698 165.612 77.8811C165.139 77.8924 164.763 78.2906 164.771 78.7639Z"
        fill="white"
      />
      <path
        d="M164.786 86.9358C164.797 87.4129 165.191 87.7886 165.668 87.7773C166.146 87.7661 166.521 87.3716 166.51 86.8945C166.499 86.4174 166.101 86.0417 165.623 86.053C165.15 86.0643 164.774 86.4587 164.786 86.9358Z"
        fill="white"
      />
      <path
        d="M164.553 95.1076C164.564 95.5847 164.958 95.9641 165.436 95.9491C165.913 95.9378 166.292 95.5396 166.277 95.0625C166.266 94.5854 165.871 94.2097 165.394 94.221C164.917 94.236 164.541 94.6304 164.553 95.1076Z"
        fill="white"
      />
      <path
        d="M174.452 70.794H159.305C158.982 70.794 158.715 70.531 158.715 70.2042V60.955C158.715 60.632 158.982 60.3652 159.305 60.3652H174.449C174.775 60.3652 175.038 60.6282 175.038 60.955V70.2042C175.042 70.531 174.775 70.794 174.452 70.794Z"
        fill="white"
      />
      <path
        d="M164.05 69.0397H160.184C159.921 69.0397 159.711 68.8293 159.711 68.5663V63.3933C159.711 63.1341 159.921 62.9199 160.184 62.9199H164.05C164.309 62.9199 164.523 63.1341 164.523 63.3933V68.5663C164.523 68.8293 164.309 69.0397 164.05 69.0397Z"
        fill="#71BF9D"
      />
      <path
        d="M168.971 60.7793H164.786V61.4179H168.971V60.7793Z"
        fill="#3E2344"
      />
      <path
        d="M167.671 61.0978H165.947C165.812 61.0978 165.703 60.9888 165.703 60.8536V58.5995C165.703 58.4643 165.812 58.3516 165.947 58.3516H167.671C167.806 58.3516 167.919 58.4605 167.919 58.5995V60.8536C167.919 60.9851 167.806 61.0978 167.671 61.0978Z"
        fill="#7C4284"
      />
      <path
        d="M173.749 62.9238H166.3V63.6263H173.749V62.9238Z"
        fill="#A1ABAD"
      />
      <path d="M173.749 64.3965H166.3V65.099H173.749V64.3965Z" fill="#A1ABAD" />
      <path
        d="M173.749 65.8652H166.3V66.5677H173.749V65.8652Z"
        fill="#A1ABAD"
      />
      <path
        d="M173.749 68.3398H166.3V69.0424H173.749V68.3398Z"
        fill="#A1ABAD"
      />
      <path
        d="M167.81 58.9942H165.86C165.646 58.9942 165.458 58.8665 165.376 58.6711C162.896 52.7392 159.267 40.0188 161.153 37.4191C161.247 37.2877 161.615 36.9909 161.551 37.1336C160.436 39.677 163.107 51.7437 165.909 58.4457L167.814 58.4157L167.934 58.2504C171.116 54.5913 178.682 44.9552 179.141 37.8023C179.152 37.6408 179.644 38.1404 179.633 38.2982C179.163 45.6277 171.589 54.9256 168.366 58.6298L168.208 58.8101C168.107 58.9266 167.96 58.9942 167.81 58.9942Z"
        fill="#764582"
      />
      <path
        d="M176.841 32.9194C176.135 31.3266 163.64 31.8413 163.136 33.4041L163.741 40.061L176.897 39.2533L176.841 32.9194Z"
        fill="#3C8460"
      />
      <path
        d="M175.966 20.9941C175.966 20.9941 174.775 25.7051 175.722 29.12C176.665 32.5349 177.878 39.7441 177.878 39.7441L171.961 40.518L168.126 46.51L166.706 39.7591L162.111 38.3353C162.111 38.3353 165.883 33.9211 165.473 29.2327C165.12 25.1491 165.308 24.4053 165.308 24.4053L175.966 20.9941Z"
        fill="#FFA982"
      />
      <path
        d="M176.842 32.9219C176.842 32.9219 177.232 34.2217 174.79 36.611C174.028 37.3586 171.383 39.2332 171.383 39.2332L167.777 46.9947L168.69 47.0924L172.116 39.8606L176.169 45.3041C178.111 42.8623 179.374 40.5669 179.468 38.5194L176.842 32.9219Z"
        fill="#84D1AA"
      />
      <path
        d="M176.173 45.3011L172.12 39.8613L168.69 47.0893L173.003 43.2199L176.173 45.3011Z"
        fill="#3C8460"
      />
      <path
        d="M163.137 33.4062C163.167 35.202 164.373 38.1623 166.604 39.3494L168.689 47.0921L167.776 46.9944L166.048 40.0106L163.621 45.4767C162.205 43.3391 161.127 41.1001 160.932 38.5793L163.137 33.4062Z"
        fill="#84D1AA"
      />
      <path
        d="M163.625 45.4701L166.052 40.0078L167.78 46.9916L166.146 43.2311L163.625 45.4701Z"
        fill="#3C8460"
      />
      <path
        d="M158.922 18.8627L164.099 16.3945L164.215 16.6387L159.009 19.1182C158.982 19.0318 158.952 18.9454 158.922 18.8627Z"
        fill="#581550"
      />
      <path
        d="M176.969 10.2251C176.969 10.2251 184.092 10.4204 182.236 16.9572C180.384 23.494 175.797 14.3726 175.797 14.3726L173.746 8.56836L176.969 10.2251Z"
        fill="#581550"
      />
      <path
        d="M165.5 28.1361L165.474 29.233C168.896 29.2781 169.982 29.3946 173.393 29.2142L175.97 20.9945C175.97 20.9945 179.483 20.2807 179.483 16.7305C179.479 13.7514 176.703 14.1121 176.207 16.9409L172.653 10.6634C172.653 10.6634 171.59 8.3943 170.977 8.42811C170.365 8.46568 167.055 9.93457 166.965 9.99092C166.875 10.0473 162.776 11.8355 162.776 11.8355L161.912 14.7132C161.909 14.7094 159.324 26.3704 165.5 28.1361Z"
        fill="#FFA982"
      />
      <path
        d="M176.954 16.0992C177.938 15.9489 178.889 16.8092 178.776 17.816C178.757 17.9738 178.509 17.9738 178.524 17.816C178.615 16.9895 177.859 16.2119 177.018 16.3396C176.864 16.3659 176.796 16.1255 176.954 16.0992Z"
        fill="#1C232B"
      />
      <path
        d="M178.318 16.8369C177.905 17.0172 177.597 17.3516 177.465 17.7799C177.416 17.9339 177.176 17.8663 177.225 17.7122C177.375 17.2239 177.724 16.8256 178.194 16.619C178.337 16.5552 178.464 16.773 178.318 16.8369Z"
        fill="#1C232B"
      />
      <path
        d="M176.203 16.9429L172.649 10.6653C172.649 10.6653 171.586 8.39625 170.973 8.43006C170.361 8.46763 167.051 9.93652 166.961 9.99287C166.871 10.0492 162.772 11.8374 162.772 11.8374L161.908 14.7151C161.908 14.7151 161.769 15.35 161.63 16.3418C164.794 15.4514 166.097 12.0328 166.097 12.0328C167.292 15.3387 171.548 12.9457 171.548 12.9457C171.086 17.0405 176.203 16.9429 176.203 16.9429Z"
        fill="#F2836D"
      />
      <path
        d="M166.097 11.8043C166.097 11.8043 164.309 16.5078 159.831 16.3199C155.353 16.1283 155.011 11.5977 158.227 10.1476C158.227 10.1476 155.695 6.25182 158.779 4.38471C161.864 2.51761 164.136 4.9595 164.136 4.9595C164.136 4.9595 166.27 -0.348797 169.907 0.823311C173.54 1.99166 173.201 5.65826 173.201 5.65826C173.201 5.65826 177.627 3.69723 178.848 6.20674C180.069 8.7125 178.348 10.3054 178.348 10.3054L179.261 15.4596C179.261 15.4596 178.089 12.89 176.207 16.9436C176.207 16.9436 171.086 16.8121 171.552 12.721C171.549 12.721 167.296 15.1103 166.097 11.8043Z"
        fill="#581550"
      />
      <path
        d="M165.08 18.9131L164.177 21.748L164.392 21.8165L165.295 18.9815L165.08 18.9131Z"
        fill="#160539"
      />
      <path
        d="M167.242 17.6661L166.928 18.2695L168.912 19.2998L169.225 18.6964L167.242 17.6661Z"
        fill="#160539"
      />
      <path
        d="M161.484 17.616L163.449 17.3268L163.348 16.6543L161.383 16.9436L161.484 17.616Z"
        fill="#160539"
      />
      <path
        d="M167.127 19.6427C167.055 19.8756 167.157 20.1085 167.348 20.1686C167.544 20.2287 167.758 20.0897 167.825 19.8568C167.897 19.6277 167.795 19.391 167.604 19.3309C167.412 19.2745 167.198 19.4135 167.127 19.6427Z"
        fill="#160539"
      />
      <path
        d="M162.277 18.1583C162.205 18.3875 162.307 18.6242 162.498 18.6843C162.69 18.7444 162.904 18.6054 162.975 18.3725C163.047 18.1433 162.945 17.9066 162.754 17.8465C162.558 17.7902 162.344 17.9292 162.277 18.1583Z"
        fill="#160539"
      />
      <path
        d="M163.254 23.108C163.145 23.4612 163.265 23.8105 163.517 23.8857C163.772 23.9608 164.065 23.7392 164.174 23.386C164.283 23.0329 164.163 22.6835 163.908 22.6084C163.656 22.5332 163.363 22.7549 163.254 23.108Z"
        fill="#160539"
      />
      <path
        d="M165.504 21.6497C165.35 22.1494 165.872 22.7504 166.665 22.9946C167.457 23.2388 168.227 23.0284 168.381 22.5288C168.535 22.0291 168.013 21.4281 167.221 21.1839C166.428 20.9397 165.658 21.1501 165.504 21.6497Z"
        fill="#F2836D"
      />
      <path
        d="M162.273 19.6695C163.065 19.9137 163.587 20.5148 163.433 21.0144C163.279 21.5141 162.513 21.7244 161.717 21.4803C161.611 21.4502 161.514 21.4089 161.424 21.3676C161.378 20.7665 161.367 20.1654 161.375 19.5831C161.645 19.5493 161.957 19.5718 162.273 19.6695Z"
        fill="#F2836D"
      />
      <path
        d="M167.465 22.3665C166.71 22.1374 166.09 21.6265 165.718 20.9277C165.346 20.2289 165.271 19.4288 165.5 18.6737C165.974 17.1221 167.623 16.2318 169.193 16.7126C169.948 16.9418 170.568 17.4527 170.94 18.1515C171.312 18.8502 171.387 19.6504 171.158 20.4055C170.7 21.9082 169.144 22.7873 167.623 22.4116C167.574 22.3966 167.518 22.3816 167.465 22.3665ZM165.722 18.7413C165.508 19.44 165.579 20.1801 165.921 20.8225C166.263 21.4649 166.834 21.9383 167.533 22.1524C168.983 22.5957 170.504 21.7767 170.94 20.3416C171.154 19.6429 171.083 18.9066 170.741 18.2604C170.399 17.618 169.828 17.1446 169.129 16.9305C169.08 16.9155 169.032 16.9005 168.983 16.8892C167.578 16.5398 166.143 17.355 165.722 18.7413Z"
        fill="#581550"
      />
      <path
        d="M160.824 21.2006C159.265 20.7235 158.382 19.0668 158.863 17.5077C159.336 15.9562 160.985 15.0658 162.556 15.5429C163.311 15.7759 163.931 16.2868 164.303 16.9818C164.675 17.6805 164.75 18.4807 164.517 19.2358C164.059 20.7385 162.507 21.6176 160.985 21.2419C160.929 21.2307 160.877 21.2156 160.824 21.2006ZM159.077 17.5753C158.634 19.0142 159.449 20.5432 160.888 20.9865C162.334 21.426 163.856 20.607 164.295 19.172C164.509 18.4732 164.438 17.7331 164.096 17.0907C163.754 16.4483 163.179 15.975 162.484 15.7608C162.436 15.7458 162.387 15.7308 162.338 15.7195C160.933 15.3739 159.502 16.1891 159.077 17.5753Z"
        fill="#581550"
      />
      <path
        d="M164.584 18.6482L164.517 18.8672L165.511 19.1741L165.578 18.9551L164.584 18.6482Z"
        fill="#581550"
      />
      <path
        d="M171.09 19.0347L176.267 16.5703L176.383 16.8145L171.177 19.294C171.146 19.2038 171.12 19.1174 171.09 19.0347Z"
        fill="#581550"
      />
      <path
        d="M158.276 11.1673C157.378 12.2868 156.243 14.3643 158.065 15.1907C158.122 15.217 158.167 15.1344 158.114 15.1081C156.345 14.3042 157.483 12.3018 158.343 11.2311C158.377 11.1898 158.313 11.1222 158.276 11.1673Z"
        fill="white"
      />
      <path
        d="M158.666 15.3344C158.756 15.4396 158.854 15.4847 158.993 15.4922C159.053 15.496 159.053 15.4021 158.993 15.3983C158.88 15.3908 158.805 15.3532 158.734 15.2668C158.693 15.2217 158.625 15.2894 158.666 15.3344Z"
        fill="white"
      />
      <path
        d="M160.65 15.5889C160.864 15.6001 161.048 15.5212 161.247 15.4649C161.307 15.4499 161.281 15.3597 161.221 15.3747C161.029 15.4273 160.852 15.5062 160.646 15.4949C160.589 15.4912 160.589 15.5851 160.65 15.5889Z"
        fill="white"
      />
      <path
        d="M165.455 12.0802C165.432 11.5543 165.785 11.0433 166.157 10.7015C166.356 10.5174 166.785 10.2695 166.999 10.555C167.134 10.7353 167.115 11.0321 167.119 11.2462C167.119 11.3063 167.213 11.3063 167.213 11.2462C167.209 10.7804 167.096 10.1042 166.462 10.4009C165.868 10.6827 165.334 11.4002 165.361 12.0765C165.361 12.1403 165.455 12.1403 165.455 12.0802Z"
        fill="white"
      />
      <path
        d="M172.468 11.95C172.292 12.9118 172.232 13.8021 172.904 14.5835C173.753 15.5678 175.275 15.9209 176.511 15.9472C176.571 15.9472 176.571 15.8533 176.511 15.8533C175.399 15.8308 174.422 15.5415 173.479 14.9479C172.371 14.2529 172.344 13.1259 172.559 11.9763C172.57 11.9162 172.48 11.8899 172.468 11.95Z"
        fill="white"
      />
      <path
        d="M165.206 4.90883C165.142 3.06426 167.175 1.97105 168.745 1.78321C171.007 1.51648 172.171 3.71042 171.724 5.69775C171.709 5.75786 171.803 5.7804 171.814 5.72405C172.19 4.05605 171.439 1.79823 169.463 1.6968C167.633 1.60288 165.037 2.73742 165.112 4.90883C165.116 4.96894 165.21 4.96894 165.206 4.90883Z"
        fill="white"
      />
      <path
        d="M171.853 6.1186C171.992 6.3703 172.037 6.71592 172.353 6.80608C172.413 6.82111 172.435 6.73095 172.379 6.71592C172.097 6.63703 172.056 6.29516 171.936 6.06976C171.905 6.02092 171.823 6.066 171.853 6.1186Z"
        fill="white"
      />
      <path
        d="M178.405 6.55775C178.769 7.36546 178.987 8.09427 178.514 8.93578C178.225 9.44294 177.823 9.88624 177.466 10.3446C177.428 10.3934 177.511 10.4385 177.545 10.3934C177.958 9.85994 178.66 9.18748 178.826 8.51127C179.002 7.78997 178.784 7.17011 178.484 6.50892C178.461 6.45632 178.382 6.50516 178.405 6.55775Z"
        fill="white"
      />
      <path
        d="M158.855 10.0978C159.095 9.82736 159.031 9.33898 158.791 9.09104C158.75 9.04596 158.682 9.11358 158.723 9.15866C158.93 9.36904 158.998 9.80107 158.787 10.034C158.75 10.0753 158.814 10.1429 158.855 10.0978Z"
        fill="white"
      />
      <path
        d="M158.34 8.28231C158.295 8.18463 158.25 8.08696 158.216 7.98552C158.197 7.92917 158.107 7.95171 158.126 8.01182C158.163 8.12077 158.208 8.22596 158.261 8.33114C158.287 8.3875 158.366 8.33866 158.34 8.28231Z"
        fill="white"
      />
      <path
        d="M172.115 7.40609C172.277 6.47442 173.288 5.63291 173.9 4.95293C174.602 4.17904 175.406 3.29996 176.323 2.78528C176.349 2.77026 176.327 2.72893 176.3 2.7402C175.271 3.31874 174.422 4.30301 173.641 5.16707C173.077 5.79069 172.221 6.52326 172.067 7.39107C172.063 7.42112 172.112 7.43615 172.115 7.40609Z"
        fill="#581550"
      />
      <path
        d="M171.597 7.51354C171.947 6.66827 172.769 6.11978 173.457 5.56003C174.34 4.84249 175.324 4.26019 176.458 4.06859C176.488 4.06108 176.477 4.016 176.443 4.01975C175.418 4.19256 174.497 4.68094 173.682 5.31959C172.901 5.93194 171.939 6.55557 171.548 7.50227C171.537 7.53232 171.586 7.54359 171.597 7.51354Z"
        fill="#581550"
      />
      <path
        d="M165.379 28.0986C165.379 28.0986 167.93 28.4217 169.993 27.0918C169.993 27.0918 169.418 30.9613 165.034 32.9185C165.034 32.9223 165.684 30.841 165.379 28.0986Z"
        fill="#160539"
      />
      <path
        d="M193.942 47.2537C188.961 40.3901 178.799 49.0757 182.766 57.4645C186.771 65.9322 192.77 73.5284 193.939 81.3237C194.754 86.7597 181.868 107.846 181.868 107.846C181.774 108.842 187.616 114.763 188.657 114.781C188.657 114.781 210.806 91.249 208.488 78.2995C206.603 67.7693 200.55 57.1339 193.942 47.2537Z"
        fill="#71BF9D"
      />
      <path
        d="M194.671 81.32C196.391 79.8023 202.65 77.2177 203.18 77.8826C203.6 78.4123 197.451 80.5724 197.451 80.5724C197.451 80.5724 205.344 78.1005 205.783 79.7723C206.027 80.6889 200.922 80.0277 195.264 81.8422C195.264 81.846 193.656 82.2179 194.671 81.32Z"
        fill="#549E7B"
      />
      <path
        d="M180.301 105.314C180.301 105.314 182.796 110.754 189.336 115.191C189.336 115.191 189.441 115.83 188.852 116.014C188.262 116.198 186.891 115.044 184.295 112.494C182.559 110.784 179.651 106.494 179.572 105.904C179.501 105.314 180.301 105.314 180.301 105.314Z"
        fill="#2B545E"
      />
      <path
        d="M49.089 32.8343L45.2233 32.6465C45.2233 32.6465 34.7608 33.225 31.128 36.8578C27.4952 40.4906 25.876 93.0814 33.7502 95.2115C41.6244 97.3416 66.3776 99.3515 69.9841 93.1002C73.5906 86.849 67.1553 40.3854 65.2281 38.4619C63.3046 36.5422 63.3046 31.6509 49.089 32.8343Z"
        fill="#000E45"
      />
      <path
        d="M114.426 58.1515L120.272 52.9221C120.272 52.9221 123.367 49.7401 122.778 49.5109C122.188 49.2818 119.359 51.8965 119.359 51.8965C119.359 51.8965 122.522 47.9669 122.045 47.8692C121.564 47.7715 118.416 50.653 118.416 50.653C118.416 50.653 122.109 45.8255 121.493 46.1298C120.88 46.4341 117.695 49.2592 117.695 49.2592C117.695 49.2592 120.23 45.525 119.731 45.6189C119.231 45.7166 114.201 50.0106 114.201 50.0106C114.201 50.0106 113.686 50.2548 113.957 49.5861C114.227 48.9211 115.771 46.9526 114.704 46.1824C114.704 46.1824 112.841 48.4177 112.522 49.2292C112.202 50.0406 111.763 50.5478 111.763 50.5478L92.4605 67.04C92.4605 67.04 88.9479 70.8756 91.7542 74.1515C94.5605 77.4274 98.9522 75.008 98.9522 75.008L114.426 58.1515Z"
        fill="#F6BA99"
      />
      <path
        d="M119.337 52.4422L119.506 52.3784C119.468 52.2807 118.608 49.9928 116.891 48.9785L116.797 49.1363C118.461 50.1206 119.329 52.4197 119.337 52.4422Z"
        fill="#231F20"
      />
      <path
        d="M114.513 52.5088C115.388 50.7581 114.118 49.988 114.107 49.9805L114.017 50.1383C114.062 50.1646 115.151 50.8333 114.355 52.4299L114.513 52.5088Z"
        fill="#231F20"
      />
      <path
        d="M116.886 53.1448C117.116 52.8781 117.356 52.7429 117.604 52.7429C117.972 52.7429 118.239 53.0396 118.243 53.0434L118.378 52.9232C118.363 52.9081 118.055 52.5588 117.604 52.5625C117.3 52.5625 117.014 52.7203 116.751 53.0284L116.886 53.1448Z"
        fill="#231F20"
      />
      <path
        d="M120.427 47.7945L118.343 50.6074L118.487 50.7148L120.572 47.9018L120.427 47.7945Z"
        fill="#231F20"
      />
      <path
        d="M121.194 49.3436L119.15 51.8887L119.29 52.0016L121.335 49.4565L121.194 49.3436Z"
        fill="#231F20"
      />
      <path
        d="M117.664 49.5279L119.396 46.8944L119.246 46.793L117.514 49.4302L117.664 49.5279Z"
        fill="#231F20"
      />
      <path
        d="M114.246 58.3453L109.114 52.8379L107.104 54.566L112.127 60.5918L114.246 58.3453Z"
        fill="#F5F5F5"
      />
      <path
        d="M111.984 58.2665C111.92 58.3867 111.962 58.5332 112.078 58.6008C112.194 58.6685 112.345 58.6234 112.412 58.5069C112.476 58.3867 112.435 58.2402 112.318 58.1726C112.198 58.1087 112.048 58.15 111.984 58.2665Z"
        fill="#2B23CC"
      />
      <path
        d="M110.538 56.6749C110.47 56.7914 110.511 56.9416 110.631 57.0093C110.748 57.0769 110.898 57.0356 110.966 56.9153C111.033 56.7989 110.988 56.6486 110.872 56.581C110.752 56.5096 110.605 56.5547 110.538 56.6749Z"
        fill="#2B23CC"
      />
      <path
        d="M108.99 54.9849C108.923 55.1052 108.964 55.2517 109.084 55.3193C109.205 55.3869 109.351 55.3456 109.419 55.2254C109.486 55.1089 109.445 54.9549 109.325 54.891C109.205 54.8234 109.054 54.8685 108.99 54.9849Z"
        fill="#2B23CC"
      />
      <path
        d="M114.246 58.3437C114.246 58.3437 112.803 56.8222 112.018 57.1604C111.447 57.4045 110.658 58.1634 111.154 59.159C111.477 59.8089 112.131 60.5903 112.131 60.5903L114.246 58.3437Z"
        fill="#FCC4CC"
      />
      <path
        d="M56.2156 32.6504C56.2156 32.6504 69.0224 32.3047 74.0188 40.8739C79.0153 49.4431 93.3699 64.0493 108.45 51.4454L115.629 58.2677C115.629 58.2677 106.053 75.5187 95.1694 77.8892C84.2861 80.2597 71.0623 73.6967 71.0623 73.6967L64.2663 46.9185C64.2663 46.9185 60.2053 38.5109 60.1602 38.3156"
        fill="#FFC97D"
      />
      <path
        d="M63.402 203.121L57.0306 202.734L56.6511 212.385L59.5476 212.318L59.3898 211.228L63.1879 212.479L78.5417 212.656C78.5417 212.656 75.1569 209.699 70.3483 208.554C67.3428 207.84 64.8296 205.822 63.402 203.121Z"
        fill="#0B1C30"
      />
      <path
        d="M26.0182 203.102L20.7813 202.922L19.5002 212.862L22.4643 212.674L22.9076 211.54L27.4909 212.637L41.9093 212.227C41.9093 212.227 38.2915 209.32 33.2913 208.362C30.2258 207.776 27.6036 205.826 26.0558 203.162L26.0182 203.102Z"
        fill="#0B1C30"
      />
      <path
        d="M64.8896 92.6169C64.8896 92.6169 47.3756 98.9433 35.0046 89.5664C35.0046 89.5664 28.0734 101.227 30.2336 118.978C32.3975 136.729 26.9351 171.272 13.4333 201.135C13.4333 201.135 25.3873 207.232 32.003 205.887C32.003 205.887 52.3796 169.458 54.4571 147.807C54.4571 147.807 63.1953 168.875 53.7283 202.532C53.7283 202.532 65.626 206.172 72.437 204.647C72.437 204.647 79.7288 182.309 80.8671 165.325C82.0993 146.921 79.8941 133.149 75.4649 119.966C71.0319 106.784 65.8852 96.5239 64.8896 92.6169Z"
        fill="#168AC4"
      />
      <path
        d="M37.5739 92.2557C33.2423 104.217 35.1695 117.313 36.2139 129.666C37.0404 139.426 37.093 149.295 36.0411 159.043C35.0118 168.593 32.4684 177.857 29.0911 186.832C27.5433 190.942 25.834 194.988 24.0345 198.993C23.9782 199.117 24.1623 199.226 24.2186 199.102C28.738 189.052 32.7277 178.676 34.9066 167.842C36.537 159.731 36.939 151.492 37.0479 143.239C37.217 130.428 34.437 117.712 35.2785 104.893C35.5603 100.611 36.3154 96.3544 37.7805 92.3121C37.8256 92.1844 37.619 92.128 37.5739 92.2557Z"
        fill="#EEEFF2"
      />
      <path
        d="M57.7403 183.664C57.7403 183.664 66.5912 144.593 53.4351 116.068C53.4351 116.068 56.726 128.353 54.6372 148.437C54.6372 148.437 55.4487 151.581 56.309 154.816C59.1942 165.624 57.7403 183.664 57.7403 183.664Z"
        fill="#0B1C30"
      />
      <path
        d="M53.15 115.723C54.1568 120.07 54.7128 124.521 55.0547 128.966C55.4304 133.861 55.5092 138.801 55.0622 143.692C54.9119 145.345 54.6865 146.994 54.3559 148.621C54.3296 148.756 54.5325 148.812 54.5588 148.677C55.4792 144.128 55.6407 139.432 55.5468 134.8C55.4529 130.025 55.0321 125.246 54.2845 120.528C54.0253 118.897 53.721 117.275 53.3491 115.667C53.3228 115.535 53.1199 115.591 53.15 115.723Z"
        fill="#EEEFF2"
      />
      <path
        d="M45.9858 94.0982C43.6078 96.7504 39.9412 98.0615 36.4173 97.5694C36.2821 97.5506 36.2257 97.7572 36.361 97.7723C39.96 98.2757 43.7017 96.9608 46.1361 94.2484C46.2262 94.147 46.0797 93.9967 45.9858 94.0982Z"
        fill="#EEEFF2"
      />
      <path
        d="M48.6793 94.9657C45.7566 99.0456 40.7413 100.623 35.8876 99.9923C35.7523 99.9735 35.7523 100.188 35.8876 100.206C40.8202 100.845 45.8956 99.2146 48.8634 95.0747C48.9423 94.962 48.7582 94.8568 48.6793 94.9657Z"
        fill="#EEEFF2"
      />
      <path
        d="M33.2048 93.8229C33.6481 94.2586 34.1853 94.5667 34.7864 94.7282C34.9179 94.7658 34.9742 94.5592 34.8427 94.5254C34.2755 94.3713 33.7758 94.0896 33.3551 93.6763C33.2574 93.5749 33.1071 93.7252 33.2048 93.8229Z"
        fill="#EEEFF2"
      />
      <path
        d="M34.051 96.3938C33.4349 96.2886 32.8826 96.0331 32.4055 95.6274C32.3003 95.5372 32.15 95.6875 32.2552 95.7777C32.7586 96.2059 33.3409 96.484 33.9908 96.5967C34.1298 96.623 34.1862 96.4201 34.051 96.3938Z"
        fill="#EEEFF2"
      />
      <path
        d="M55.9714 94.739C56.0879 99.1494 55.468 103.549 54.1343 107.752C54.093 107.884 54.2996 107.94 54.3372 107.809C55.6784 103.586 56.302 99.1682 56.1818 94.739C56.1818 94.6 55.9676 94.6 55.9714 94.739Z"
        fill="#EEEFF2"
      />
      <path
        d="M65.6827 96.0337C64.2288 95.9285 62.9515 95.0833 62.2039 93.8435C62.1325 93.7271 61.9484 93.8323 62.0198 93.9525C62.8087 95.2598 64.1537 96.1352 65.6827 96.2479C65.8217 96.2554 65.8179 96.0412 65.6827 96.0337Z"
        fill="#EEEFF2"
      />
      <path
        d="M66.5052 98.2572C63.7177 98.2047 61.0804 96.5705 59.8332 94.0722C59.7731 93.9482 59.589 94.0572 59.6491 94.1812C60.9302 96.747 63.6463 98.4188 66.5052 98.4714C66.6404 98.4714 66.6404 98.2572 66.5052 98.2572Z"
        fill="#EEEFF2"
      />
      <path
        d="M23.5126 49.5516C23.5126 49.5516 25.4549 83.9559 23.892 90.3387C22.333 96.7252 19.6657 113.146 20.2893 114.836C29.4971 121.974 44.9261 121.554 50.8618 120.254C53.8522 69.3947 41.6465 32.834 41.6465 32.834C41.6465 32.834 28.1146 34.254 21.4201 42.6541"
        fill="#FFC97D"
      />
      <path
        d="M71.8358 45.5565C71.4639 48.081 73.2671 73.4128 74.9502 82.7634C76.652 92.2116 78.0082 111.559 77.6024 115.019C74.691 117.652 68.6689 118.809 66.5501 119.144C66.1481 119.208 65.7874 118.903 65.7762 118.498C65.6447 112.19 64.4763 63.0817 56.9365 32.8398"
        fill="#FFC97D"
      />
      <path
        d="M73.5337 71.7696C73.5337 71.7696 71.1031 55.0032 71.7756 49.7324"
        stroke="#3C2415"
        stroke-width="0.375676"
        stroke-miterlimit="10"
      />
      <path
        d="M41.6464 32.8398L38.8401 57.1385L43.4684 60.0012L40.5832 65.6739L50.9444 89.518C50.9481 89.518 51.0683 62.1501 41.6464 32.8398Z"
        fill="#FBD870"
      />
      <path
        d="M67.9587 54.7099L64.1343 57.9407L68.7439 61.8891L64.2771 86.6236C64.2771 86.6236 61.4407 51.2762 56.2151 32.6465C56.2151 32.654 65.0622 39.8181 67.9587 54.7099Z"
        fill="#FBD870"
      />
      <path
        d="M11.4965 101.357L27.5181 117.686L48.5067 97.0909L32.4852 80.7628L11.4965 101.357Z"
        fill="#FFA982"
      />
      <path
        d="M36.1772 85.6011L43.7358 93.3025L44.6262 92.4309C44.9305 92.1304 44.9342 91.642 44.6375 91.3415L38.1571 84.7408C37.8603 84.4365 37.3719 84.4328 37.0676 84.7296L36.1772 85.6011Z"
        fill="#F18E60"
      />
      <path
        d="M40.9484 86.1967C40.306 86.8279 40.2985 87.8572 40.9259 88.4959C41.5533 89.1383 42.5864 89.1458 43.225 88.5184C43.8674 87.8873 43.875 86.8579 43.2476 86.2155C42.6202 85.5731 41.5908 85.5656 40.9484 86.1967ZM41.5495 86.8053C41.8538 86.5086 42.3422 86.5123 42.639 86.8166C42.9358 87.1172 42.932 87.6055 42.6277 87.9061C42.3234 88.2029 41.835 88.1991 41.5383 87.8948C41.2377 87.5905 41.2452 87.1059 41.5495 86.8053Z"
        fill="#F18E60"
      />
      <path
        d="M48.6045 24.8262C48.6045 24.8262 50.6407 26.4416 53.6911 26.4979"
        stroke="#F7A794"
        stroke-width="0.281757"
        stroke-miterlimit="10"
      />
      <path
        d="M40.3056 14.1719C39.2725 17.042 38.2394 19.9122 37.2063 22.7824C37.0673 23.1768 36.9246 23.575 36.9095 23.992C36.8644 25.228 37.9764 26.2611 39.1786 26.5541C40.377 26.8509 41.6355 26.588 42.8452 26.3287C43.0969 27.6023 44.5395 28.2146 45.8018 28.5264C51.4069 29.9089 57.4402 29.4957 62.8048 27.3656C60.7349 24.0221 59.0932 20.4156 57.9399 16.6551C53.4881 16.6063 49.0363 16.5537 44.5883 16.5011C43.7994 16.4936 42.9917 16.4785 42.2667 16.1742C41.5416 15.8699 40.9105 15.1937 40.903 14.4086"
        fill="#000E45"
      />
      <path
        d="M54.7541 32.1301C54.6414 31.1421 55.265 27.051 55.265 27.051C55.265 27.051 59.0405 26.8632 59.4763 23.1552C59.9121 19.4473 60.1638 10.8519 60.1638 10.8519L53.5143 7.12891L45.6777 12.4072L45.8205 32.1564L54.7541 32.1301Z"
        fill="#F6BA99"
      />
      <path
        d="M46.0344 15.3045C46.0344 15.3045 52.2029 15.0678 53.5967 9.55666L60.5242 16.0108C60.5242 16.0108 62.1659 12.0699 58.6909 8.02766C55.2159 3.98539 52.6387 4.64282 51.1886 4.56017C49.8399 4.48504 42.935 5.55196 40.4744 13.2007C40.2978 13.753 40.0987 15.1392 39.9709 15.7666L46.0344 15.3045Z"
        fill="#000E45"
      />
      <path
        d="M59.0106 17.0628C58.9956 17.3258 58.7702 17.5286 58.5072 17.5136C58.2442 17.4986 58.0451 17.2732 58.0564 17.0102C58.0714 16.7472 58.2968 16.5444 58.5598 16.5594C58.8227 16.5744 59.0256 16.7998 59.0106 17.0628Z"
        fill="#00143A"
      />
      <path
        d="M59.5036 16.5693C59.436 16.6256 59.1016 16.3101 58.5907 16.2537C58.0798 16.1899 57.6816 16.4228 57.6327 16.3514C57.6064 16.3213 57.6778 16.2086 57.8544 16.1072C58.0272 16.002 58.3127 15.9081 58.632 15.9494C58.9514 15.987 59.2068 16.141 59.3496 16.2838C59.4923 16.419 59.5337 16.543 59.5036 16.5693Z"
        fill="#00143A"
      />
      <path
        d="M53.4918 16.754C53.4768 17.017 53.2513 17.2199 52.9884 17.2011C52.7254 17.1861 52.5263 16.9607 52.5376 16.6977C52.5526 16.4347 52.778 16.2319 53.041 16.2469C53.3039 16.2657 53.5068 16.4911 53.4918 16.754Z"
        fill="#00143A"
      />
      <path
        d="M53.8151 16.2495C53.7513 16.3096 53.4132 15.9903 52.9022 15.9339C52.3913 15.8701 51.9931 16.103 51.9443 16.0316C51.918 16.0015 51.9893 15.8888 52.1622 15.7837C52.335 15.6785 52.6205 15.5883 52.9398 15.6259C53.2591 15.6634 53.5146 15.8175 53.6573 15.9602C53.8039 16.103 53.8489 16.227 53.8151 16.2495Z"
        fill="#00143A"
      />
      <path
        d="M55.4263 20.6873C55.4263 20.6573 55.7494 20.6385 56.2716 20.6272C56.4031 20.631 56.5308 20.6197 56.5646 20.522C56.6059 20.4243 56.5646 20.2703 56.5158 20.105C56.4218 19.7632 56.3242 19.3988 56.2227 19.0231C56.0199 18.2529 55.8508 17.5542 55.7344 17.047C55.6217 16.5361 55.5615 16.2205 55.5916 16.213C55.6179 16.2055 55.7268 16.5098 55.8771 17.0095C56.0274 17.5091 56.219 18.2041 56.4218 18.9742C56.5195 19.3537 56.6097 19.7181 56.6998 20.0637C56.7337 20.2252 56.805 20.4093 56.7224 20.6009C56.6773 20.6986 56.5759 20.7587 56.4932 20.7737C56.4106 20.7925 56.3354 20.785 56.2716 20.7812C55.7456 20.7474 55.4225 20.7136 55.4263 20.6873Z"
        fill="#00143A"
      />
      <g opacity="0.33">
        <g opacity="0.33">
          <path
            opacity="0.33"
            d="M52.947 20.8026C52.9094 21.2271 52.2407 21.4938 51.448 21.4675C50.6553 21.4412 50.0129 21.1256 50.0129 20.7011C50.0129 20.2804 50.6854 19.9047 51.5156 19.931C52.3459 19.961 52.9845 20.378 52.947 20.8026Z"
            fill="#F2957E"
          />
        </g>
      </g>
      <path
        d="M53.7057 21.0782C53.2061 20.7063 55.0919 22.0136 56.1363 22.07C56.1363 22.07 55.4413 22.9641 54.4082 22.4569C53.3375 21.9347 53.7057 21.0782 53.7057 21.0782Z"
        fill="#F3F0EB"
      />
      <path
        d="M55.2535 27.044C55.2535 27.044 52.744 26.9313 50.4148 25.1055C50.4148 25.1055 51.5456 27.2318 55.0168 28.8134L55.2535 27.044Z"
        fill="#000E45"
      />
      <path
        d="M53.4397 9.10123C53.4397 9.10123 54.6569 11.8136 53.9619 13.7634L60.5249 16.0099C60.5249 16.0099 61.6257 8.15077 53.4397 9.10123Z"
        fill="#000E45"
      />
      <path
        d="M40.0056 15.1973C40.0056 15.1973 38.6118 23.7364 45.776 25.6899L46.0352 15.3062L40.0056 15.1973Z"
        fill="#000E45"
      />
      <path
        d="M54.1044 6.69814C54.1081 6.65306 54.8294 6.65306 55.9302 7.01747C57.0196 7.37436 58.4697 8.18206 59.6118 9.55328C60.7538 10.9245 61.2798 12.5023 61.4338 13.6369C61.5954 14.7827 61.4601 15.4927 61.4188 15.489C61.3474 15.4852 61.3775 14.7789 61.1483 13.6895C60.9267 12.6038 60.3819 11.1236 59.2962 9.82001C58.2105 8.52017 56.8543 7.71247 55.8287 7.29547C54.7919 6.87096 54.0931 6.76952 54.1044 6.69814Z"
        fill="#000E45"
      />
      <path
        d="M55.3333 16.6234C55.3108 16.6234 55.3371 16.3604 55.2432 15.9134C55.1493 15.4738 54.8901 14.8202 54.2627 14.298C53.9509 14.0425 53.5564 13.8171 53.0943 13.7119C52.6322 13.6105 52.1101 13.5955 51.6029 13.7608C51.0957 13.911 50.6036 14.2228 50.2429 14.6736C49.871 15.1132 49.6419 15.6955 49.6043 16.3078C49.5742 16.9164 49.7358 17.5213 50.0551 18.0021C50.3669 18.4868 50.8215 18.8549 51.3061 19.0615C51.7945 19.2794 52.3129 19.3245 52.7825 19.2757C53.2559 19.2231 53.6729 19.0427 54.011 18.8211C54.6909 18.3703 55.0215 17.7504 55.1643 17.3222C55.3071 16.8826 55.3108 16.6234 55.3333 16.6234C55.3409 16.6234 55.3409 16.6873 55.3333 16.8112C55.3296 16.9352 55.3033 17.1155 55.2394 17.3409C55.1155 17.788 54.7961 18.4454 54.0936 18.9376C53.7442 19.1742 53.3047 19.3733 52.8051 19.441C52.3092 19.5048 51.7532 19.4598 51.231 19.2343C50.7088 19.0165 50.2204 18.6295 49.8861 18.1111C49.5404 17.5964 49.3639 16.9465 49.3939 16.2928C49.4352 15.6391 49.6832 15.0155 50.0852 14.5422C50.4759 14.0613 51.0056 13.7345 51.5465 13.5767C52.0913 13.4076 52.6473 13.4264 53.1319 13.5466C53.6203 13.6668 54.0335 13.911 54.3528 14.189C54.999 14.7563 55.2432 15.4475 55.3183 15.9059C55.3559 16.1388 55.3634 16.3191 55.3521 16.4431C55.3484 16.5595 55.3371 16.6234 55.3333 16.6234Z"
        fill="#00143A"
      />
      <path
        d="M62.0539 16.9986C62.0313 16.9986 62.0576 16.7393 61.9637 16.2885C61.8698 15.849 61.6106 15.1991 60.987 14.6731C60.6752 14.4177 60.2807 14.1923 59.8149 14.0871C59.3565 13.9856 58.8306 13.9744 58.3272 14.1359C57.82 14.2862 57.3279 14.6017 56.9672 15.0488C56.5953 15.4921 56.3699 16.0706 56.3286 16.683C56.2985 17.2916 56.4601 17.8964 56.7794 18.3773C57.0912 18.8619 57.5458 19.2301 58.0304 19.4367C58.515 19.6583 59.0372 19.6997 59.5068 19.6508C59.9764 19.5982 60.3972 19.4179 60.7353 19.1963C61.4152 18.7454 61.7458 18.1256 61.8886 17.6973C62.0276 17.2578 62.0313 16.9948 62.0539 16.9986C62.0614 16.9986 62.0614 17.0624 62.0539 17.1864C62.0501 17.3104 62.0238 17.4907 61.96 17.7198C61.8397 18.1669 61.5167 18.8243 60.8104 19.3165C60.461 19.5569 60.0252 19.756 59.5256 19.8199C59.0297 19.88 58.4737 19.8387 57.9515 19.6133C57.4293 19.3954 56.9409 19.0084 56.6028 18.49C56.2572 17.9753 56.0806 17.3291 56.1144 16.6717C56.1558 16.0143 56.4037 15.3944 56.8019 14.9211C57.1926 14.4402 57.7261 14.1134 58.2671 13.9556C58.8118 13.7865 59.3678 13.8053 59.8524 13.9255C60.3408 14.0457 60.754 14.2899 61.0771 14.5679C61.7233 15.1352 61.9675 15.8264 62.0426 16.281C62.0802 16.5139 62.0839 16.6943 62.0764 16.8182C62.0689 16.9347 62.0614 16.9986 62.0539 16.9986Z"
        fill="#00143A"
      />
      <path
        d="M56.2155 16.7656C56.2117 16.822 55.9675 16.8595 55.6745 16.8407C55.3777 16.8257 55.1448 16.7656 55.1448 16.7055C55.1485 16.6491 55.389 16.6153 55.6857 16.6341C55.9825 16.6492 56.2192 16.7093 56.2155 16.7656Z"
        fill="#00143A"
      />
      <path
        d="M49.5552 16.2368C49.5627 16.2932 48.4019 16.5036 46.9593 16.7064C45.5167 16.9093 44.3408 17.0258 44.3333 16.9694C44.3258 16.9131 45.4866 16.7027 46.933 16.4998C48.3718 16.2969 49.5477 16.1805 49.5552 16.2368Z"
        fill="#00143A"
      />
      <path
        d="M46.4557 19.6323C46.512 19.0124 46.5496 18.3738 46.358 17.784C46.1664 17.1904 45.6968 16.6532 45.0844 16.5518C44.3782 16.4391 43.6569 16.9913 43.5028 17.6901C43.3488 18.3888 43.7207 19.1477 44.3218 19.5309C44.9267 19.9178 45.7156 19.9516 46.388 19.7037"
        fill="#F6BA99"
      />
      <path
        d="M46.035 19.8418L45.0732 33.7906C45.0732 33.7906 46.9591 38.1222 50.4154 37.8216C53.8716 37.5211 54.7507 33.6667 54.7507 33.6667L54.7544 32.1302L52.1622 27.7235C52.1622 27.7235 47.8269 23.7563 47.7781 23.4671C47.7255 23.1778 46.035 19.8418 46.035 19.8418Z"
        fill="#F6BA99"
      />
      <path
        d="M51.9929 46.0955C51.9328 46.0955 51.8802 46.073 51.8351 46.0317C46.354 40.6332 43.3223 36.4444 42.8189 33.8861C42.7964 33.7658 43.266 33.6268 43.2923 33.7508C43.7731 36.1739 46.786 40.4153 52.0831 45.641L53.5745 45.5358C56.377 39.7767 56.4935 34.9154 54.9946 32.9882C54.9194 32.8905 55.4867 32.9243 55.5618 33.022C57.1885 35.1108 56.8541 39.8555 53.9201 45.8476C53.8863 45.9189 53.8149 45.9678 53.736 45.9715L52.0154 46.0918C52.0004 46.0955 51.9967 46.0955 51.9929 46.0955Z"
        fill="#7C4284"
      />
      <path
        d="M59.3935 54.513L47.7137 55.3395C47.4395 55.3583 47.2028 55.1517 47.184 54.8774L46.6806 47.7734C46.6618 47.4991 46.8685 47.2625 47.1427 47.2437L58.8225 46.4172C59.0967 46.3984 59.3334 46.605 59.3559 46.8793L59.8556 53.9833C59.8706 54.2576 59.664 54.4942 59.3935 54.513Z"
        fill="white"
      />
      <path
        d="M51.2303 53.7202L48.2888 53.9268C48.0709 53.9418 47.8793 53.7765 47.8642 53.5586L47.5825 49.6028C47.5675 49.3849 47.7328 49.1933 47.9507 49.1783L50.8922 48.9717C51.1101 48.9566 51.3017 49.1219 51.3167 49.3398L51.5985 53.2957C51.6135 53.5136 51.4482 53.7052 51.2303 53.7202Z"
        fill="#DD4026"
      />
      <path
        d="M54.6601 47.5271L51.4105 47.7563L51.3767 47.2604L54.6263 47.0312L54.6601 47.5271Z"
        fill="#3E2344"
      />
      <path
        d="M53.6194 47.3551L52.312 47.4491C52.1993 47.4566 52.0979 47.3702 52.0904 47.2575L51.9702 45.5406C51.9627 45.4242 52.0491 45.3265 52.1618 45.319L53.4691 45.2251C53.5856 45.2176 53.6833 45.304 53.6908 45.4167L53.811 47.1335C53.8185 47.2462 53.7321 47.3476 53.6194 47.3551Z"
        fill="#7C4284"
      />
      <path
        d="M58.4502 48.4351L52.668 48.8438L52.7064 49.3871L58.4886 48.9785L58.4502 48.4351Z"
        fill="#A1ABAD"
      />
      <path
        d="M58.5341 49.5785L52.752 49.9883L52.7905 50.5317L58.5727 50.1218L58.5341 49.5785Z"
        fill="#A1ABAD"
      />
      <path
        d="M58.6081 50.7249L52.8259 51.1348L52.8644 51.6781L58.6466 51.2683L58.6081 50.7249Z"
        fill="#A1ABAD"
      />
      <path
        d="M58.7883 53.1873L53.0067 53.593L52.9653 53.0483L58.7507 52.6426L58.7883 53.1873Z"
        fill="#A1ABAD"
      />
      <path
        d="M34.1223 108.067C38.1232 107.376 38.1833 106.673 38.0255 105.708C38.0255 105.708 35.6175 106.508 34.8022 106.053C33.987 105.599 33.4235 105.599 33.4235 105.599L29.5052 99.5391C29.1558 99.7945 28.7989 100.035 28.427 100.264C26.3383 101.541 24.1556 102.638 21.9241 103.63L24.9708 107.323L29.678 114.288C29.678 114.288 32.6083 118.037 32.9351 117.444C33.262 116.85 30.8952 113.465 30.8952 113.465C30.8952 113.465 34.6106 117.395 34.7834 116.899C34.9563 116.403 32.3528 112.646 32.3528 112.646C32.3528 112.646 36.9473 117.267 36.7107 116.572C36.4777 115.873 33.9382 112.083 33.9382 112.083C33.9382 112.083 37.5372 115.314 37.5071 114.773C37.477 114.232 33.6452 108.266 33.6452 108.266C33.6452 108.266 33.3596 108.202 34.1223 108.067Z"
        fill="#F6BA99"
      />
      <path
        d="M28.7768 108.229L28.9647 108.18C28.7468 107.331 28.8257 106.617 29.2126 106.065C29.9001 105.07 31.3314 104.901 31.3465 104.901L31.3239 104.709C31.2638 104.716 29.7874 104.889 29.0511 105.956C28.6341 106.557 28.5402 107.324 28.7768 108.229Z"
        fill="#F6BA99"
      />
      <path
        d="M39.5693 33.166C39.5693 33.166 15.804 36.1639 8.38442 60.7068C0.964825 85.2459 18.0844 106.13 26.8639 111.017L32.9724 103.936C32.9724 103.936 22.3333 82.0114 30.9888 69.8244C39.6444 57.6375 34.7681 48.6213 34.7681 48.6213L36.2445 38.9815L39.5693 33.166Z"
        fill="#FFC97D"
      />
      <path
        d="M27.6039 82.4365C27.6039 82.4365 26.8638 73.4015 32.8371 65.6738"
        stroke="#3C2415"
        stroke-width="0.375676"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default PersonalRepresentativeIcon;
