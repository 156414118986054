export const AUTH_KEY = 'auth';
export const STORAGE_EVENT = 'storage';
export const DEVELOPMENT = 'development';
export const PROD = 'prod';
export const BREAKPOINTS = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};
export const VALIDATOR_REGEX = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
};

export enum CognitoHostedUIIdentityProvider {
  Google = 'Google',
}

export enum BillingPlan {
  TrustPlan = 'Trust Plan',
  BasicWill = 'Basic Will',
}

export const PUBLIC = 'public';

// google maps related configurations
export const MAP_NOTARY_RADIUS = 2000;
export const MAP_NOTARY_SEARCH_KEYWORD = 'notary';
export const MAP_NOTARY_DEFAULT_LAT = 39.82533726040308;
export const MAP_NOTARY_DEFAULT_LNG = -104.96027398118902;
export const MAP_NOTARY_ZOOM = 5;
export const MAP_SEARCH_DEBOUNCE = 300;
