import React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import { useClipboard } from 'use-clipboard-copy';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';

interface Props {
  variant: TypographyProps['variant'];
  name: string;
  subText: string;
}

const TypographyRow = ({ variant, name, subText }: Props) => {
  const clipboard = useClipboard();

  const textToCopy = `variant="${variant}"`;
  const copyToClipboard = () => {
    clipboard.copy(textToCopy);
    openSnackbar({ message: 'Copied!' }, NotifierType.Success);
  };
  return (
    <Box
      onClick={copyToClipboard}
      sx={{
        my: 1,
        cursor: 'pointer',
      }}
    >
      <Typography
        variant={variant}
        component="p"
        sx={{
          my: 1,
        }}
      >
        {name}
      </Typography>
      <Typography
        variant="s"
        component="p"
        sx={{
          my: 1,
        }}
      >
        {subText}
      </Typography>
      <Typography
        variant="s"
        component="p"
        sx={{
          my: 1,
        }}
      >
        {textToCopy}
      </Typography>
    </Box>
  );
};

export default React.memo(TypographyRow);
