import React from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import PetsIcon from '../../components/Icons/CompletionScreen/PetsIcon';

const CompletionScreen = () => {
  const history = useHistory();

  return (
    <CompletionBox
      header={'Pets'}
      image={PetsIcon}
      title="Great! Rest assured your pets will be taken care of."
      quote={
        'A process that most people think will be laborious, ' +
        'intimidating, expensive and an all around pain, was amazingly quick and easy.' +
        'So glad I got TruWill!'
      }
      quoteBy="Craig"
      previous={() => history.push(apiUrls.PETS)}
      continue={() => history.push(apiUrls.PERSONAL_REPRESENTATIVE)}
    />
  );
};

export default React.memo(CompletionScreen);
