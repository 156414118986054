import { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  BusinessInterestsFragmentFragment,
  BusinessRelationship,
  BusinessType,
  useBusinessInterestInputMutation,
  useDeleteBusinessInterestMutation,
} from '../../../generated/graphql';
import { NotifierType } from '../../../variables/types';
import { openSnackbar } from '../../../components/Notifier';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';
import getErrorMessage from '../../../utils/getErrorMessage';
import useUrlPush from '../url-push-hook';
import { Page } from '../constants';
import { SUMMARY } from '../../Summary/constants';

export interface DataForm {
  fieldArray: {
    business_id: string;
    name: string;
    business_type: BusinessType | undefined;
    state_formed_in: string;
    relationship: BusinessRelationship | undefined;
  }[];
}

interface Props {
  businessInterests: BusinessInterestsFragmentFragment[];
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const useBusinessInterestForm = ({
  businessInterests,
  selectedForms,
  uiFlag, 
  setEditState,
}: Props) => {
  const { nextForm } = useUrlPush();
  const previousAction = () => {
    nextForm(selectedForms[selectedForms.indexOf(Page.BusinessInterest) - 1]);
  };

  const defaultValues = {
    business_id: '',
    name: '',
    state_formed_in: '',
    business_type: undefined,
    relationship: undefined,
  };
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<DataForm>({
    defaultValues: {
      fieldArray: [defaultValues],
    },
  });

  const { fields, remove, append, replace } = useFieldArray({
    name: 'fieldArray',
    control,
    rules: {
      required: 'Please enter information of at least one business interest',
    },
  });

  useEffect(() => {
    let realEstateData = [] as any;
    if (businessInterests.length > 0) {
      businessInterests.map((business) => {
        return realEstateData.push({
          business_id: business?.id,
          business_type: business.type,
          name: business.name,
          state_formed_in: business.state_formed_in,
          relationship: business.relationship,
        });
      });
      replace(realEstateData);
    }
  }, [businessInterests]);

  const { refetchPlanDocuments } = useContext(DocumentContext);

  const [UpsertBusinessInterest, { loading }] =
    useBusinessInterestInputMutation();

  const upsert = async (data: DataForm) => {
    await UpsertBusinessInterest({
      variables: {
        input: data.fieldArray.map((business_interest: any) => {
          return {
            ID: business_interest.business_id || null,
            name: business_interest.name,
            type: business_interest.business_type,
            state_formed_in: business_interest.state_formed_in,
            relationship: business_interest.relationship,
          };
        }),
      },
    });
  };

  const onCreate = async (data: DataForm) => {
    try {
      await upsert(data);
      openSnackbar(
        {
          message: 'Business interest added successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        nextForm(selectedForms[selectedForms.indexOf(Page.BusinessInterest) + 1]);
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const onUpdate = async (data: DataForm) => {
    let arr = data.fieldArray.filter(obj => obj.business_id !== '');
    try {
      await upsert({
        fieldArray: arr,
      });
      openSnackbar(
        {
          message: 'Business interest updated successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const [DeleteBusinessInterest, { loading: deletingBusinessInterest }] =
    useDeleteBusinessInterestMutation();
  const onRemoveBusinessInterest = async (id: string, index: number) => {
    if (id) {
      try {
        await DeleteBusinessInterest({
          variables: {
            input: id,
          },
        });
        refetchPlanDocuments?.();
        openSnackbar(
          {
            message: 'Business interest deleted successfully',
          },
          NotifierType.Success,
        );
      } catch (err) {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
    remove(index);
  };

  return {
    loading: loading || deletingBusinessInterest,
    handleSubmit,
    create: onCreate,
    onRemoveBusinessInterest,
    isSubmitting,
    errors,
    control,
    fields,
    remove,
    append,
    reset,
    defaultValues,
    previousAction,
    onUpdate,
  };
};
export default useBusinessInterestForm;
