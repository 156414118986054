import React, { useReducer, createContext, useEffect } from 'react';
import devToolReducer, {
  initialState,
} from '../components/DevTool/dev-tool-reducer';
import { DevtoolTypes } from '../variables/types';

interface Props {
  children: React.ReactNode;
}

interface ContextVal {
  featureFlags?: Record<string | number, unknown>;
  featureFlagDispatch?: Function;
}

const FeatureFlagContext = createContext<ContextVal>({});

/**
 * To use feature toggle, We can add environment variable
 * prefixed with "REACT_APP_FEATURE_FLAG_".
 * e.g. "REACT_APP_FEATURE_FLAG_PASSWORD=false"
 *
 * The same can be updated using devtool.
 *
 * To enable dev tool. Add "devtool=true" in the local storage
 */

const FeatureFlagContextProvider = ({ children }: Props) => {
  const [state, featureFlagDispatch] = useReducer(devToolReducer, initialState);

  const loadFlags = () => {
    const flags = Object.keys(process.env)?.filter((flag) =>
      /REACT_APP_FEATURE_FLAG_/gi.test(flag),
    );
    const flagsLocal = JSON.parse(localStorage.getItem('featureFlags') || '{}');
    return (
      flags?.reduce((envFlags: Record<string, boolean>, flag: string) => {
        const flagAttribute = flag.replace(/REACT_APP_FEATURE_FLAG_/gim, '');
        return {
          ...envFlags,
          [flagAttribute]: ['true', '1'].includes(process.env[flag] || ''),
          ...flagsLocal,
        };
      }, {}) || {}
    );
  };

  useEffect(() => {
    const flags = loadFlags();
    featureFlagDispatch({
      type: DevtoolTypes.UpdateFlag,
      payload: flags,
    });
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ ...state, featureFlagDispatch }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagContext, FeatureFlagContextProvider, devToolReducer };
