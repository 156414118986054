import { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  RealEstatePropertyFragmentFragment,
  RealEstatePropertyType,
  useDeleteRealEstatePropertyMutation,
  useRealEstatePropertyInputMutation,
} from '../../../generated/graphql';
import { NotifierType } from '../../../variables/types';
import { openSnackbar } from '../../../components/Notifier';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';
import getErrorMessage from '../../../utils/getErrorMessage';
import useUrlPush from '../url-push-hook';
import { Page } from '../constants';
import { SUMMARY } from '../../Summary/constants';

export interface DataForm {
  fieldArray: {
    estate_id: string;
    property_type: RealEstatePropertyType | undefined;
    description: string;
    address: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    edit_form: boolean;
  }[];
}

interface Props {
  realEstateList: RealEstatePropertyFragmentFragment[];
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const useEstateForm = ({ realEstateList, selectedForms, uiFlag, setEditState }: Props) => {
  const { nextForm } = useUrlPush();

  const defaultValues = {
    estate_id: '',
    description: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    country: 'US',
    property_type: undefined,
    edit_form: false,
  };
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
    setValue,
  } = useForm<DataForm>({
    defaultValues: {
      fieldArray: [defaultValues],
    },
  });

  const { fields, remove, append, replace } = useFieldArray({
    name: 'fieldArray',
    control,
    rules: {
      required: 'Please enter information of at least one real estate',
    },
  });

  useEffect(() => {
    let realEstateData = [] as any;
    if (realEstateList.length > 0) {
      realEstateList.map((property) => {
        return realEstateData.push({
          estate_id: property.ID,
          description: property.description,
          address: property.address,
          city: property.city,
          state: property.state,
          zip_code: property.zip_code,
          country: 'US',
          property_type: property.type,
          edit_form: false,
        });
      });
      replace(realEstateData);
    }
  }, [realEstateList]);

  const { refetchPlanDocuments } = useContext(DocumentContext);
  const [UpsertRealEstateProperty, { loading }] =
    useRealEstatePropertyInputMutation();

  const upsert = async (data: DataForm) => {
    await UpsertRealEstateProperty({
      variables: {
        input: data.fieldArray.map((property: any) => {
          return {
            ID: property.estate_id || null,
            type: property.property_type,
            address: property.address,
            city: property.city,
            state: property.state,
            zip_code: property.zip_code,
            country: property.country,
            description: property.description,
          };
        }),
      },
    });
  };

  const onCreate = async (data: DataForm) => {
    try {
      await upsert(data);
      openSnackbar(
        {
          message: 'Real estate property added successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        nextForm(selectedForms[selectedForms.indexOf(Page.RealEstate) + 1]);
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const onUpdate = async (data: DataForm) => {
    let arr = data.fieldArray.filter(obj => obj.estate_id !== '');
    try {
      await upsert({
        fieldArray: arr,
      });
      openSnackbar(
        {
          message: 'Real estate property updated successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const [DeleteRealEstateProperty, { loading: deletingRealEstateProperty }] =
    useDeleteRealEstatePropertyMutation();
  const onRemoveRealEstateProperty = async (id: string, index: number) => {
    if (id) {
      try {
        await DeleteRealEstateProperty({
          variables: {
            input: id,
          },
        });
        refetchPlanDocuments?.();
        openSnackbar(
          {
            message: 'Real estate property deleted successfully',
          },
          NotifierType.Success,
        );
      } catch (err) {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
    remove(index);
  };

  return {
    loading: loading || deletingRealEstateProperty,
    handleSubmit,
    create: onCreate,
    onRemoveRealEstateProperty,
    isSubmitting,
    errors,
    control,
    fields,
    remove,
    append,
    reset,
    defaultValues,
    onUpdate,
    setValue,
  };
};
export default useEstateForm;
