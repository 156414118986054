import { Controller, useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NotifierType } from '../../variables/types';
import { apiUrls } from '../../variables/urls';
import { openSnackbar } from '../../components/Notifier';
import {
  TrusteesFragmentFragment,
  useTrusteesInputMutation,
} from '../../generated/graphql';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { SUMMARY } from '../Summary/constants';

export interface DataForm {
  primary_trustee_name: string;
  secondary_trustee_name: string;
}

export interface TrusteesInfoProps {
  info?: TrusteesFragmentFragment | null;
  uiFlag?: string,
  setEditState?: Function;
}

const useTrusteesForm = ({ info, uiFlag, setEditState }: TrusteesInfoProps) => {
  const [stateLoading, setLoadingState] = useState<boolean>(false);
  const [upsertTrusteesInfo, { loading }] = useTrusteesInputMutation();
  const { refetchPlanDocuments } = useContext(DocumentContext);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<DataForm>({
    defaultValues: {
      primary_trustee_name: '',
      secondary_trustee_name: '',
    },
  });

  useEffect(() => {
    if (info) {
      setValue('primary_trustee_name', info?.primary_trustee_name || '');
      setValue('secondary_trustee_name', info?.secondary_trustee_name || '');
    }
  }, [info, setValue]);

  const onCreate = async (data: DataForm) => {
    setLoadingState(true);
    try {
      await upsertTrusteesInfo({
        variables: {
          input: {
            id: info?.id,
            primary_trustee_name: data.primary_trustee_name || '',
            secondary_trustee_name: data.secondary_trustee_name || '',
          },
        },
      });

      openSnackbar(
        {
          message: info?.id
            ? 'Updated trustees successfully'
            : 'Added trustees successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        history.push(apiUrls.TRUSTEES_COMPLETION);
      }
    } catch (err: any) {
      setLoadingState(false);
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  return {
    loading: stateLoading || loading,
    register,
    errors,
    handleSubmit,
    create: onCreate,
    setValue,
    control,
    Controller,
  };
};
export default useTrusteesForm;
