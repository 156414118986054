export const apiUrls = {
  BASIC_INFO: '/basic-info',
  BENEFICIARIES: '/plan-documents/beneficiaries',
  BENEFICIARIES_EDIT: '/plan-documents/beneficiaries/:id',
  BENEFICIARIES_COMPLETION: '/plan-documents/beneficiaries/complete',
  CHILDREN: '/plan-documents/children',
  CHILDREN_COMPLETION: '/plan-documents/children/complete',
  CREATE_ACCOUNT: '/create-account',
  CREATE_ACCOUNT_SALES: '/sales/create-account',
  DASHBOARD: '/dashboard',
  ASSETS: '/plan-documents/assets',
  ASSETS_REAL_ESTATE: '/plan-documents/assets/real-estates',
  ASSETS_ACCOUNTS: '/plan-documents/assets/accounts',
  ASSETS_BUSINESS: '/plan-documents/assets/business-interests',
  ASSETS_PERSONAL_PROPERTIES: '/plan-documents/assets/personal-properties',
  ASSETS_COMPLETION: '/plan-documents/assets/complete',
  GUARDIANSHIP: '/plan-documents/guardianship',
  GUARDIANSHIP_COMPLETION: '/plan-documents/guardianship/complete',
  LOGIN: '/login',
  HOME: '/home',
  PERSONAL_INFORMATION: '/plan-documents/personal-information',
  PERSONAL_INFORMATION_COMPLETION:
    '/plan-documents/personal-information/complete',
  PETS: '/plan-documents/pets',
  PETS_EDIT: '/plan-documents/pets/:id',
  PETS_COMPLETION: '/plan-documents/pets/complete',
  PERSONAL_REPRESENTATIVE: '/plan-documents/personal-representative',
  PERSONAL_REPRESENTATIVE_SPOUSE:
    '/plan-documents/personal-representative/spouse',
  PERSONAL_REPRESENTATIVE_COMPLETION:
    '/plan-documents/personal-representative/complete',
  PLAN: '/plan',
  PLAN_DOCUMENTS: '/plan-documents',
  POWER_OF_ATTORNEY_GENERAL: '/plan-documents/power-of-attorney/general',
  POWER_OF_ATTORNEY_GENERAL_SPOUSE:
    '/plan-documents/power-of-attorney/general/spouse',
  POWER_OF_ATTORNEY_MEDICAL: '/plan-documents/power-of-attorney/medical',
  POWER_OF_ATTORNEY_MEDICAL_SPOUSE:
    '/plan-documents/power-of-attorney/medical/spouse',
  POWER_OF_ATTORNEY_COMPLETION: '/plan-documents/power-of-attorney/complete',
  NEAR_BY_NOTARY: '/near-by-notary',
  RECOVER: '/login/recover',
  RESET_PASSWORD: '/reset-password',
  ROOT: '/',
  STYLEGUIDE: '/styleguide',
  TRUSTEES: '/plan-documents/trustees',
  TRUSTEES_COMPLETION: '/plan-documents/trustees/complete',
  SUMMARY: '/summary',
  YOU_DID_IT: '/summary/completion',
  CONFIRMATION: '/confirmation-page',
  CHECKOUT: '/checkout',
  CHECKOUT_PAYMENT: '/checkout/payment',
  CHECKOUT_SUCCESS: '/checkout/success',
  PRIVACY_POLICY: '/privacy-policy',
  CHANGE_PASSWORD: '/change-password',
  PROFILE: '/profile',
};
