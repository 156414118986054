import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useHistory } from 'react-router-dom';

interface State {
  stateLoading: boolean;
  showPassword: boolean;
}

const useChangePasswordForm = () => {
  const history = useHistory();
  const [values, setValues] = useState<State>({
    stateLoading: false,
    showPassword: false,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const setLoading = useCallback(
    (loading: boolean) => {
      setValues({
        ...values,
        stateLoading: loading,
      });
    },
    [values],
  );

  const onPasswordChange = useCallback(
    async (data: Record<string, string>) => {
      setLoading(true);
      if (data.new_password.trim() === data.repeat_new_password.trim()) {
        try {
          const user = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(user, data.current_password.trim(), data.new_password.trim());
          openSnackbar({ message: 'Password changed successfully.' }, NotifierType.Success);
          reset();
          setLoading(false);
          history.goBack();
        } catch (err: any) {
          setLoading(false);
          if (err?.code === 'NotAuthorizedException') {
            openSnackbar({ message: 'Incorrect Password' }, NotifierType.Error);
          } else {
            openSnackbar({ message: err?.message }, NotifierType.Error);
          }
        }
      } else {
        setLoading(false);
        openSnackbar({ message: 'New password should match the repeat new password field' }, NotifierType.Error);
      }
    },
    [setLoading],
  );

  return {
    loading: values.stateLoading,
    register,
    errors,
    handleSubmit,
    onPasswordChange,
    showPassword: values.showPassword,
    handleClickShowPassword,
  };

};

export default useChangePasswordForm;