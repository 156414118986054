import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { PersonalPropertiesFragmentFragment } from '../../../generated/graphql';
import TabLayout from '../../../components/TabLayout';
import Loader from '../../../components/Loader';
import HtmlTooltip from '../../../components/HtmlTooltip';
import { Controller } from 'react-hook-form';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import usePersonalForm from './personal-properties-form-hooks';
import useAssetsCommon from '../assets-common-hooks';

interface Props {
  propertyList: PersonalPropertiesFragmentFragment[];
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const PersonalPropertiesBox = ({ propertyList, selectedForms, uiFlag, setEditState }: Props) => {
  const theme = useTheme();
  const {
    loading,
    handleSubmit,
    create,
    onRemoveAccount,
    isSubmitting,
    control,
    fields,
    append,
    remove,
    errors,
    defaultValues,
    previousAction,
    onUpdate,
  } = usePersonalForm({
    propertyList,
    selectedForms,
    uiFlag,
    setEditState,
  });

  const { editItems, setEditItems, cancelEditMode } = useAssetsCommon({
    remove,
  });

  const resetAndRemove = (property: any, index: number) => {
    cancelEditMode(property.property_id, index);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout
        title="Assets"
        previous={previousAction}
        isSubmitting={isSubmitting}
        uiFlag={uiFlag}
        next={setEditState && !uiFlag ? () => setEditState('') : undefined}
        setEditState={setEditState}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography
                variant="sTextInfo"
                component="div"
                sx={{ color: theme.palette.primary.main, mb: 2 }}
              >
                PERSONAL PROPERTY DETAILS
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="m" component="div">
                  List any personal item that you would like to specifically
                  gift to an individual.
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        What Personal Belongings Should I Include in my Trust or
                        Will?
                        <br />
                        Specific personal belongings included in your estate
                        plan are typically limited to family heirlooms, jewelry,
                        guns, collector’s items, and other specialized
                        belongings that you wish to be passed to a specific
                        person. If your intent is that these items be divided
                        equally rather than going to a specific person, there is
                        no need to list them individually.
                      </React.Fragment>
                    }
                  />
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={6}>
            <Stack direction="column" spacing={3}>
              {fields.map((property, index) => {
                return (
                  <Stack
                    key={property.id}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      width: '100%',
                      mt: 2,
                      border: '1px solid',
                      borderColor: theme.palette.common.lightGreen,
                      borderRadius: '0.5rem',
                      p: 2,
                    }}
                  >
                    {property.property_id && !editItems.includes(index) ? (
                      <Box sx={{ alignSelf: 'flex-start', width: '100%' }}>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'baseline'}
                        >
                          <Typography
                            variant="sTextInfo"
                            component="div"
                            sx={{
                              color: theme.palette.primary.main,
                              mb: 2,
                              fontWeight: 800,
                            }}
                          >
                            {property.description}
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() =>
                                setEditItems((prevArray: number[]) => [
                                  ...prevArray,
                                  index,
                                ])
                              }
                              size="small"
                              aria-label="delete"
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: '2rem',
                              }}
                            >
                              <EditOutlinedIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                onRemoveAccount(property.property_id, index)
                              }
                              size="small"
                              aria-label="delete"
                              sx={{ color: theme.palette.error.main }}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{ fontSize: 20 }}
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                        <Typography
                          variant="smText"
                          component="div"
                          sx={{ color: theme.palette.grey[700] }}
                        >
                          {property.beneficiary_owner_name}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.description`}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                label="ITEM DESCRIPTION"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                placeholder={'ex: wedding ring, car, etc'}
                              />
                            )}
                            rules={{
                              required: 'Please enter the item description',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.description && (
                            <FormHelperErrorText>
                              {errors?.fieldArray[index]?.description?.message}
                            </FormHelperErrorText>
                          )}
                        </FormControl>

                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.beneficiary_owner_name`}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                label="RECIPIENT"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            rules={{
                              required: 'Please enter the recipient',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]
                            ?.beneficiary_owner_name && (
                            <FormHelperErrorText>
                              {
                                errors?.fieldArray[index]
                                  ?.beneficiary_owner_name?.message
                              }
                            </FormHelperErrorText>
                          )}
                        </FormControl>

                        <Stack
                          direction={'row'}
                          spacing={2}
                          alignSelf={'flex-start'}
                        >
                          {property.property_id && (
                            <Button
                              disabled={isSubmitting}
                              onClick={handleSubmit(onUpdate)}
                              variant="contained"
                              color="primary"
                              sx={{ width: '6rem' }}
                            >
                              Save
                            </Button>
                          )}
                          <Button
                            onClick={() =>
                              resetAndRemove(property, index)
                            }
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: '6rem',
                              color: theme.palette.common.naturalBlack,
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Stack>
                );
              })}

              <FormHelperErrorText>
                {errors?.fieldArray?.root?.message}
              </FormHelperErrorText>
              <Button
                onClick={() => append(defaultValues)}
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                sx={{
                  mt: 1,
                  width: '12rem',
                  color: theme.palette.text.primary,
                }}
              >
                Add Property
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </TabLayout>
      <Loader open={loading} />
    </form>
  );
};

export default React.memo(PersonalPropertiesBox);
