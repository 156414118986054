import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import { Page } from './constants';

const useUrlPush = () => {
  const history = useHistory();
  const nextForm = (form: string) => {
    switch (form) {
      case Page.RealEstate:
        return history.push(apiUrls.ASSETS_REAL_ESTATE);
      case Page.FinancialAccounts:
        return history.push(apiUrls.ASSETS_ACCOUNTS);
      case Page.BusinessInterest:
        return history.push(apiUrls.ASSETS_BUSINESS);
      case Page.OtherItems:
        return history.push(apiUrls.ASSETS_PERSONAL_PROPERTIES);
      case Page.CompletionScreen:
        return history.push(apiUrls.ASSETS_COMPLETION);
      default:
        return history.push(apiUrls.ASSETS);
    }
  };
  return {
    nextForm,
  };
};

export default useUrlPush;
