import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../core/privateRoute';
import Dashboard from './Dashboard';
import useUserInfo from '../core/useUserInfo';
import { refreshAuthentication } from '../core/auth';
import Login from './Login';
import Loader from '../components/Loader';
import StyleGuide from './StyleGuide';
import { apiUrls } from '../variables/urls';
import CreateAccount from './CreateAccount';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ChangePassword from './ChangePassword';
import Profile from './Profile';
import Plan from './Plan';
import BasicInfo from './BasicInfo';
import PlanDocuments from './PlanDocuments';
import { DEVELOPMENT, PROD } from '../variables/constant';
import Payment from './Checkout/Payment';
import PaymentSuccessPage from './Checkout/PaymentSuccessPage';
import NotaryMap from './NotaryMap';
import PrivacyPolicy from './PivacyPolicy';
import Home from './Home';
import RouteMiddleware from '../core/RouteMiddleware';
import Summary from './Summary';
import YouDidItPage from './YouDidItPage';
import Confirmation from './Confirmation';
import SalesCreateAccount from './SalesCreateAccount';

const AppRouter = () => {
  const [isLoadingUserInfo] = useUserInfo();
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthentication = useCallback(async () => {
    await refreshAuthentication();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  if (isLoading || isLoadingUserInfo) {
    return <Loader open />;
  }

  const loadProdHome = () => {
    const prodURL =
      process.env.REACT_APP_HOME_URL || process.env.REACT_APP_URL || '';
    window.location.href = prodURL;
    return null;
  };

  return (
    <Router>
      <RouteMiddleware />
      <Switch>
        <Route path={apiUrls.LOGIN} exact component={Login} />
        <Route
          path={apiUrls.HOME}
          exact
          component={() =>
            process.env.REACT_APP_ENV !== PROD ? <Home /> : loadProdHome()
          }
        />
        <Route path={apiUrls.CREATE_ACCOUNT} exact component={CreateAccount} />
        <Route path={apiUrls.CREATE_ACCOUNT_SALES} exact component={SalesCreateAccount} />
        <Route path={apiUrls.RECOVER} exact component={ForgotPassword} />
        <Route path={apiUrls.RESET_PASSWORD} exact component={ResetPassword} />
        <Route path={apiUrls.BASIC_INFO} exact component={BasicInfo} />
        <Route path={apiUrls.PLAN} exact component={Plan} />
        <Route path={apiUrls.PRIVACY_POLICY} exact component={PrivacyPolicy} />
        <Route path={apiUrls.STYLEGUIDE} exact component={StyleGuide} />
        {process.env.REACT_APP_ENV === DEVELOPMENT && (
          <Route path={apiUrls.STYLEGUIDE} exact component={StyleGuide} />
        )}
        <PrivateRoute path={apiUrls.ROOT}>
          <Route path={apiUrls.ROOT} exact component={Dashboard} />
          <Route path={apiUrls.DASHBOARD} exact component={Dashboard} />
          <Route path={apiUrls.CHANGE_PASSWORD} exact component={ChangePassword} />
          <Route path={apiUrls.PROFILE} exact component={Profile} />
          <Route path={apiUrls.NEAR_BY_NOTARY} exact component={NotaryMap} />
          <Route
            path={[
              apiUrls.PERSONAL_INFORMATION,
              apiUrls.PERSONAL_INFORMATION_COMPLETION,
              apiUrls.CHILDREN,
              apiUrls.CHILDREN_COMPLETION,
              apiUrls.GUARDIANSHIP,
              apiUrls.GUARDIANSHIP_COMPLETION,
              apiUrls.PETS,
              apiUrls.PETS_EDIT,
              apiUrls.PERSONAL_REPRESENTATIVE,
              apiUrls.PERSONAL_REPRESENTATIVE_SPOUSE,
              apiUrls.PERSONAL_REPRESENTATIVE_COMPLETION,
              apiUrls.POWER_OF_ATTORNEY_GENERAL,
              apiUrls.POWER_OF_ATTORNEY_MEDICAL,
              apiUrls.POWER_OF_ATTORNEY_GENERAL_SPOUSE,
              apiUrls.POWER_OF_ATTORNEY_MEDICAL_SPOUSE,
              apiUrls.TRUSTEES,
              apiUrls.TRUSTEES_COMPLETION,
              apiUrls.ASSETS,
              apiUrls.ASSETS_REAL_ESTATE,
              apiUrls.ASSETS_BUSINESS,
              apiUrls.ASSETS_ACCOUNTS,
              apiUrls.ASSETS_PERSONAL_PROPERTIES,
              apiUrls.ASSETS_COMPLETION,
              apiUrls.BENEFICIARIES,
              apiUrls.BENEFICIARIES_EDIT,
              apiUrls.POWER_OF_ATTORNEY_GENERAL,
              apiUrls.POWER_OF_ATTORNEY_MEDICAL,
              apiUrls.POWER_OF_ATTORNEY_GENERAL_SPOUSE,
              apiUrls.POWER_OF_ATTORNEY_MEDICAL_SPOUSE,
              apiUrls.POWER_OF_ATTORNEY_COMPLETION,
              apiUrls.CHECKOUT,
            ]}
            exact
            component={PlanDocuments}
          />
          <Route path={apiUrls.SUMMARY} exact component={Summary} />
          <Route path={apiUrls.YOU_DID_IT} exact component={YouDidItPage} />
          <Route path={apiUrls.CONFIRMATION} exact component={Confirmation} />
          <Route path={apiUrls.CHECKOUT_PAYMENT} exact component={Payment} />
          <Route
            path={apiUrls.CHECKOUT_SUCCESS}
            exact
            component={PaymentSuccessPage}
          />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default AppRouter;
