import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';

import AppRouter from './pages/AppRouter';
import './core/sentry';
import Notifier from './components/Notifier';
import apolloClient from './core/apolloClient';
import AuthContext from './core/authContext';
import initAWSAmplify from './core/awsAmplify';
import { FeatureFlagContextProvider } from './core/featureContext';
import DevTools from './components/DevTool';
import ErrorPage from './components/ErrorBoundary';
import ThemeConfig from './theme';

initAWSAmplify();

const CCSThemeProvider = () => (
  <ThemeConfig>
    <>
      <Notifier />
      <AppRouter />
      <DevTools />
    </>
  </ThemeConfig>
);
const ApolloContainer = () => (
  <ApolloProvider client={apolloClient}>
    <AuthContext>
      <CCSThemeProvider />
    </AuthContext>
  </ApolloProvider>
);
const App = () => (
  <ErrorBoundary fallback={<ErrorPage />}>
    <FeatureFlagContextProvider>
      <ApolloContainer />
    </FeatureFlagContextProvider>
  </ErrorBoundary>
);

export default App;
