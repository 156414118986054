import React from 'react';
import { FormHelperText } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const FormHelperErrorText = ({ children }: Props) => {
  return (
    <FormHelperText
      sx={{
        color: 'error.main',
        fontSize: 14,
        fontWeight: 500,
      }}
    >
      {children}
    </FormHelperText>
  );
};

export default FormHelperErrorText;
