import React from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import AssetsIcon from '../../components/Icons/CompletionScreen/AssetsIcon';

const CompletionScreen = () => {
  const history = useHistory();

  return (
    <CompletionBox
      header={'Assets'}
      image={AssetsIcon}
      title="Great! Your Assets Are Accounted For!"
      quote={
        'So grateful to have been connected to ' +
        'TruWill and to have taken care of ' +
        'some of the most important directives ' +
        'for my property and my family.'
      }
      quoteBy="Shaun"
      previous={() => history.push(apiUrls.ASSETS)}
      continue={() => history.push(apiUrls.BENEFICIARIES)}
    />
  );
};

export default React.memo(CompletionScreen);
