import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import {
  PersonType,
  PowerOfAttorneyFragmentFragment,
  PowerOfAttorneyType,
  usePowerOfAttorneyInputMutation,
} from '../../generated/graphql';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { SUMMARY } from '../Summary/constants';

export interface DataForm {
  first_choice: string;
  second_choice: string;
}

export interface PowerOfAttorneyInfoProps {
  powerOfAttorney?: PowerOfAttorneyFragmentFragment | null;
  attorneyType: PowerOfAttorneyType;
  attorneyFor: PersonType;
  uiFlag?: string;
  setEditState?: Function;
}

const usePowerOfAttorneyForm = ({
  powerOfAttorney,
  attorneyType,
  attorneyFor,
  uiFlag, 
  setEditState,
}: PowerOfAttorneyInfoProps) => {
  const defaultValues = {
    first_choice: '',
    second_choice: '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<DataForm>({
    defaultValues: {
      ...defaultValues,
    },
  });

  const history = useHistory();
  const { planDocuments, refetchPlanDocuments } = useContext(DocumentContext);

  useEffect(() => {
    if (powerOfAttorney?.id) {
      reset({
        first_choice: powerOfAttorney.first_choice_owner_name,
        second_choice: powerOfAttorney.second_choice_owner_name || '',
      });
    }
  }, [powerOfAttorney, reset]);

  const [UpsertPowerOfAttorney, { loading }] =
    usePowerOfAttorneyInputMutation();

  const nextURL = () => {
    const isMarried = planDocuments?.user_info?.is_married;
    switch (attorneyFor) {
      case PersonType.Self:
        if (attorneyType === PowerOfAttorneyType.General) {
          return apiUrls.POWER_OF_ATTORNEY_MEDICAL;
        } else if (attorneyType === PowerOfAttorneyType.Medical) {
          if (isMarried) {
            return apiUrls.POWER_OF_ATTORNEY_GENERAL_SPOUSE;
          }
        }
        break;
      case PersonType.Spouse:
        return attorneyType === PowerOfAttorneyType.General
          ? apiUrls.POWER_OF_ATTORNEY_MEDICAL_SPOUSE
          : apiUrls.POWER_OF_ATTORNEY_COMPLETION;
      default:
        return apiUrls.POWER_OF_ATTORNEY_COMPLETION;
    }
    return apiUrls.POWER_OF_ATTORNEY_COMPLETION;
  };

  const onCreate = async (data: DataForm) => {
    try {
      await UpsertPowerOfAttorney({
        variables: {
          input: {
            id: powerOfAttorney?.id || null,
            first_choice_owner_name: data.first_choice,
            second_choice_owner_name: data.second_choice,
            power_of_attorney_type: attorneyType,
            power_of_attorney_for: attorneyFor,
          },
        },
      });
      openSnackbar(
        {
          message: `
            ${powerOfAttorney?.id ? 'Updated' : 'Created'}
            ${attorneyType}
            power of attorney successfully
            `,
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        history.push(nextURL());
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  return {
    loading,
    register,
    handleSubmit,
    create: onCreate,
    errors,
    control,
  };
};
export default usePowerOfAttorneyForm;
