import React from 'react';
import { useHistory } from 'react-router-dom';
import usePowerOfAttorneys from './power-of-attorneys-hooks';
import { apiUrls } from '../../variables/urls';
import { PersonType, PowerOfAttorneyType } from '../../generated/graphql';
import PowerOfAttorneyForm from './PowerOfAttorneyForm';
import Loader from '../../components/Loader';

interface Props {
  uiFlag?: string;
  setEditState?: Function;
}

const MedicalPowerOfAttorney = ({ uiFlag, setEditState }: Props) => {
  const { loading, powerOfAttorneys } = usePowerOfAttorneys();

  const history = useHistory();

  return (
    <>
      <PowerOfAttorneyForm
        powerOfAttorney={powerOfAttorneys?.medical}
        attorneyType={PowerOfAttorneyType.Medical}
        attorneyFor={PersonType.Self}
        header={
          'Who would you like to make medical' +
          ' decisions on your behalf if you ' +
          'are incapacitated but still alive?'
        }
        previous={() => history.push(apiUrls.POWER_OF_ATTORNEY_GENERAL)}
        uiFlag={uiFlag}
        setEditState={setEditState}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(MedicalPowerOfAttorney);
