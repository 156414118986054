/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const YouDidItIcon = (props: any) => {
  const theme = useTheme();
  return (
    <SvgIcon
      width="225"
      height="352"
      viewBox="0 0 226 352"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_3289_16699)">
        <path
          d="M209.517 55.5962C209.463 55.5962 209.383 55.5693 209.356
             55.5155C209.302 55.4348 209.329 55.3002 209.41 55.2464L219.036
             48.8151C219.116 48.7613 219.251 48.7882 219.305 48.8689C219.358
             48.9497 219.332 49.0842 219.251 49.138L209.625 55.5693C209.571
             55.5962 209.544 55.5962 209.517 55.5962Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M215.997 65.8485C215.889 65.8485 215.809 65.7678 215.809
             65.6601C215.809 65.5525 215.889 65.4449 215.997 65.4449L225.811
             64.6914C225.919 64.6914 226.026 64.7721 226.026 64.8798C226.026
             64.9874 225.946 65.095 225.838 65.095L215.997 65.8485Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M222.961 83.0968C222.934 83.0968 222.907 83.0968 222.881
             83.0699L213.254 79.0873C213.147 79.0335 213.093 78.9258 213.147
             78.8182C213.201 78.7106 213.308 78.6567 213.416 78.7106L223.042
             82.6931C223.149 82.7469 223.203 82.8546 223.149 82.9622C223.123
             83.0698 223.042 83.0968 222.961 83.0968Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M139.687 107.529L179.482 127.523C179.482 127.523 163.484 108.525
             166.038 102.497C168.592 96.4696 172.921 96.5773 177.493
             95.4471C182.064 94.3169 184.349 102.659 184.349 102.659C184.349
             102.659 188.759 107.637 192.147 110.92C195.535 114.203 208.28
             128.007 209.033 134.062C209.786 140.116 206.64 151.284 191.878
             155.428C177.143 159.572 148.614 136.241 142.887 126.581C138.88
             119.854 139.687 107.529 139.687 107.529Z"
          fill={theme.palette.common.calypso}
        />
        <path
          d="M158.052 141.866C157.488 140.412 156.869 138.771 156.331
             137.156C154.799 132.582 146.893 117.701 146.893
             117.701V131.721C149.744 134.869 153.643 138.421 158.052 141.866Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M75.1269 195.793L68.0283 204C68.0283 204 65.6352 209.489 68.593
             213.445C71.5507 217.401 141.515 236.425 154.099 219.553C154.099
             219.553 145.468 212.692 132.373 206.045C119.305 199.372 113.013
             194.447 102.392 193.586C91.798 192.725 75.1269 195.793 75.1269
             195.793Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M85.318 351.999C132.438 351.999 170.636 346.927 170.636
             340.671C170.636 334.414 132.438 329.342 85.318 329.342C38.1982
             329.342 0 334.414 0 340.671C0 346.927 38.1982 351.999 85.318
             351.999Z"
          fill={theme.palette.common.gainsboro}
        />
        <path
          d="M34.6597 339.352C34.337 342.5 36.2999 342.958 43.4523
             342.662C50.6047 342.366 56.2783 338.895 56.2783 338.895L57.542
             336.607L57.4076 338.464L60.9569 337.495C60.9569 337.495 62.5433
             333.163 62.0862 329.288C61.9787 328.427 60.8225 326.409 59.3436
             325.871C56.7892 324.956 52.7827 325.44 48.0234 327.108C46.437
             327.673 34.9824 336.204 34.6597 339.352Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M133.61 330.661C133.61 330.661 147.996 337.415 148.13
             340.537C148.265 343.658 142.242 343.954 135.17 343.255C128.098
             342.582 122.532 339.407 122.532 339.407L121 337.792L121.026
             339.622L115.38 337.846C115.38 337.846 114.062 333.46 114.734
             329.665C114.869 328.831 116.16 326.867 117.638 326.436C121.053
             325.441 126.915 327.028 133.61 330.661Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M128.663 195.011C122.559 194.311 115.675 193.289 111.777
             192.67C94.3526 190.733 80.6125 194.204 80.6125 194.204C67.3025
             242.317 53.9657 300.683 48.3997 328.481L57.4344 328.777C58.8326
             328.83 60.1232 328.104 60.8492 326.893C64.5061 320.704 75.7994
             301.437 79.6983 294.01C88.7598 276.707 113.148 223.373 113.148
             223.373L117.047 326.247C117.074 327.297 117.827 328.185 118.875
             328.427L128.206 330.418C128.206 330.418 138.262 274.07 144.473
             219.418C148.318 192.186 136.138 196.007 128.663 195.011Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M114.653 262.795L114.788 266.347C117.557 266.777 125.221 267.961
             129.523 268.069C132.481 268.15 135.761 268.231 138.127
             268.284C138.289 267.154 138.45 266.051 138.611 264.894C133.126
             264.463 118.606 263.306 117.88 263.118C117.369 263.01 115.917
             262.903 114.653 262.795Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M66.6569 254.076C66.6569 254.076 72.7876 259.942 80.5046
             263.171C85.4522 265.243 90.1846 266.374 92.9272 266.939C93.7339
             265.217 94.5406 263.494 95.3741 261.772C89.0821 259.404 72.3842
             253.457 66.6569 254.076Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M128.663 195.012C122.559 194.312 115.676 193.29 111.777
             192.671C94.3528 190.734 79.1069 193.801 79.1069
             193.801C73.8636 212.772 68.8354 233.411 64.3449 253.163C69.2387
             254.428 86.8239 267.075 102.366 263.334L112.395 227.787L113.874
             263.953C122.398 265.406 141.65 266.59 149.098 267.102C147.835
             251.629 145.979 236.102 147.835 219.769C151.653 192.536 136.138
             196.008 128.663 195.012Z"
          fill={theme.palette.common.chestnutRose}
        />
        <path
          d="M150.308 126.232C150.308 126.232 144.957 104.516 129.792
             103.009C129.281 102.848 128.743 102.686 128.152 102.579C127.076
             102.39 126.377 102.552 125.92 103.036C123.635 103.305 121.161
             104.032 118.499 105.269C113.955 107.395 113.363 109.198 101.156
             123.783C83.7047 144.611 77.7623 157.043 68.0286 203.999C68.0286
             203.999 80.8545 197.38 96.1542 206.394C118.633 219.633 146.705
             222.297 154.073 219.553C151.545 202.062 154.288 148.62 150.308
             126.232Z"
          fill={theme.palette.common.calypso}
        />
        <path
          d="M90.373 137.937C86.8506 143.238 83.9197 148.701 81.2577
             155.186C84.6457 153.921 91.7981 150.907 98.4127 145.929C107.474
             139.067 112.234 127.146 112.234 127.146L90.373 137.937Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M120.005 105.27L77.3861 125.29C77.3861 125.29 96.2083 106.292
             93.6807 100.265C91.1263 94.2371 88.2761 92.1651 83.705
             91.0349C79.1339 89.9047 76.3912 98.0851 76.3912 98.0851C76.3912
             98.0851 70.9866 105.404 67.5986 108.687C64.2106 111.97 53.6971
             123.138 52.9711 129.165C52.2182 135.22 49.0454 150.45 66.792
             152.899C88.7332 155.913 113.767 126.205 116.106 123.245C118.391
             120.258 120.005 105.27 120.005 105.27Z"
          fill={theme.palette.common.calypso}
        />
        <path
          d="M168.431 104.758C168.431 104.758 171.228 105.296 173.11
             104.247C174.992 103.197 169.803 99.9144 168.996 100.506C168.189
             101.098 167.678 104.139 168.431 104.758Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M164.371 84.9536C164.371 84.9536 167.92 104.113 170.743
             104.786C173.567 105.458 178.864 104.194 179.401 101.987C179.966
             99.7806 177.304 99.1347 176.417 98.5966C175.529 98.0584 174.992
             96.3093 175.045 95.4751C175.099 94.6409 175.395 93.5915 174.266
             93.134C173.136 92.6765 172.894 92.5151 173.056 91.4387C173.217
             90.3624 173.835 89.9587 172.921 89.0707C172.007 88.1827 171.2
             88.0751 171.362 87.1064C171.523 86.1376 171.469 83.1776 171.2
             82.9354C170.932 82.6932 164.236 84.254 164.371 84.9536Z"
          fill={theme.palette.common.chestnutRose}
        />
        <path
          d="M169.668 70.127C167.544 70.6651 165.312 71.1495 162.919
             71.607C160.364 72.0913 158.375 74.1902 158.052 76.7735C157.837
             78.6033 157.971 80.8099 159.154 83.0433C160.364 85.3306 162.865
             86.5953 165.42 86.2186C167.033 85.9764 169.157 85.6804 171.55
             85.4113C168.055 79.2492 168.969 72.9524 169.668 70.127Z"
          fill={theme.palette.common.chestnutRose}
        />
        <path
          d="M199.219 56.0527C199.219 56.0527 191.663 64.6368 169.641
             70.1262C168.942 72.9517 168.028 79.2484 171.55 85.4106C178.353
             84.6302 187.442 83.9575 193.626 85.0339L205.215 86.6484L199.219
             56.0527Z"
          fill={theme.palette.grey[200]}
        />
        <path
          d="M205.403 70.0455C206.801 78.4949 207.554 86.2448 205.215
             86.6484C202.876 87.0251 199.246 80.1902 197.847 71.7407C196.449
             63.2913 197.31 56.268 199.219 56.0527C201.047 55.8374 204.005
             61.596 205.403 70.0455Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M198.546 65.928C198.546 65.928 198.358 74.6465 201.585
             78.1447C201.585 78.1447 212.475 73.5433 212.313 69.0494C212.206
             65.6051 198.546 65.928 198.546 65.928Z"
          fill={theme.palette.grey[200]}
        />
        <path
          d="M167.329 103.629C167.329 103.629 164.909 95.3136 164.748
             94.4256C164.613 93.5376 166.926 89.582 167.087 87.9405C167.248
             86.2991 168.109 87.1871 168.351 88.4249C168.593 89.6627 168.109
             92.9456 168.109 92.9456C168.109 92.9456 169.211 96.1478 169.453
             98.0584C169.695 99.9689 168.889 101.933 169.453 103.602C170.045
             105.324 167.49 105.028 167.329 103.629Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M176.068 94.3984C176.068 94.3984 176.875 93.322 176.794
             92.7569C176.686 92.2188 175.799 91.7882 175.799 91.7882C175.799
             91.7882 176.848 90.9809 176.525 90.1468C176.202 89.3395 175.127
             88.8013 175.127 88.8013C175.127 88.8013 176.014 87.402 174.992
             86.2449C173.971 85.0878 171.389 86.8907 171.389 86.8907C171.389
             86.8907 167.625 90.1198 168.566 90.658C169.507 91.1962 172.572
             89.0704 172.572 89.0704L170.878 90.7926C170.878 90.7926 169.131
             92.6762 169.319 93.4835C169.48 94.2908 170.852 93.7526 170.852
             93.7526C170.852 93.7526 169.776 94.8559 170.367 95.5555C170.959
             96.2551 171.954 95.7977 171.954 95.7977C171.954 95.7977 170.26
             97.493 171.524 97.9773C172.787 98.4617 176.283 96.0129 176.417
             95.3402C176.552 94.6675 176.068 94.3984 176.068 94.3984Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M66.8454 148.216C66.7916 148.216 66.7378 148.189 66.7109
             148.162C66.6303 148.081 66.6303 147.947 66.7109 147.866C73.1373
             141.919 76.122 130.186 76.9555 126.446C75.88 128.249 73.6213
             131.936 71.7391 134.411C70.6367 135.837 66.0925 139.336 65.9043
             139.497C65.8236 139.578 65.6892 139.551 65.6354 139.47C65.5547
             139.389 65.5816 139.255 65.6623 139.201C65.716 139.174 70.3678
             135.595 71.4165 134.196C73.944 130.886 77.1706 125.289 77.1975
             125.235C77.2513 125.154 77.3589 125.101 77.4395 125.154C77.5202
             125.181 77.574 125.289 77.574 125.37C77.5471 125.531 74.7507
             141.031 66.9798 148.189C66.9529 148.189 66.8991 148.216 66.8454
             148.216Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M194.594 141.974C194.325 141.974 194.029 141.92 193.68
             141.84C189.915 140.79 179.751 128.224 179.321 127.685C179.267
             127.605 179.267 127.497 179.321 127.443C179.375 127.362 179.482
             127.336 179.563 127.389C179.698 127.443 191.851 132.96 197.175
             133.094C197.283 133.094 197.363 133.202 197.363 133.309C197.363
             133.417 197.256 133.498 197.148 133.498C194.89 133.444 191.206
             132.448 186.205 130.565C183.596 129.569 181.311 128.6 180.155
             128.089C182.333 130.726 190.614 140.575 193.787 141.463C194.594
             141.678 195.078 141.624 195.239 141.274C195.669 140.279 193.949
             137.642 193.626 137.453C193.545 137.4 193.491 137.265 193.545
             137.184C193.599 137.077 193.707 137.05 193.814 137.104C194.298
             137.346 196.073 140.198 195.616 141.382C195.481 141.759 195.132
             141.974 194.594 141.974Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M127.91 95.0988C127.91 95.0988 127.184 100.534 125.49
             103.683C124.522 105.513 130.545 109.657 133.771 109.926C136.998
             110.195 134.605 104.248 134.336 102.741C134.067 101.207 134.121
             96.821 133.906 96.1483C133.744 95.4756 128.878 94.2377 127.91
             95.0988Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M133.153 107.018L135.143 103.17L137.079 105.807L136.03
             111.162L133.287 109.843C133.287 109.843 130.518 111.566
             130.464 111.512C130.41 111.485 125.947 107.502 124.737
             105.403C123.527 103.304 125.92 101.475 125.92 101.475L133.153
             107.018Z"
          fill={theme.palette.common.calypso}
        />
        <path
          d="M123.688 85.2221C123.688 85.2221 123.715 95.636 127.264
             96.1204C132.965 96.9007 130.276 84.8723 129.2 83.8229C128.152
             82.7734 124.441 83.9036 123.688 85.2221Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M141.542 81.3474C141.542 81.3474 143.075 86.5677 143.559
             88.1554C144.016 89.743 144.796 91.2499 145.172 93.6717C145.549
             96.1205 145.145 100.587 140.735 101.798C136.326 103.009 131.109
             100.614 129.684 98.3001C128.259 96.0128 128.259 94.8557 128.259
             94.8557C128.259 94.8557 126.888 95.2594 125.839 93.591C124.764
             91.9226 123.527 89.6892 125.113 89.151C126.673 88.6128 127.157
             89.4201 127.533 89.2317C127.883 89.0434 128.662 84.3881 130.572
             83.3925C132.508 82.4506 139.875 79.3023 141.542 81.3474Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M133.879 87.8867C133.825 87.8867 133.771 87.8598 133.718
             87.806C133.637 87.7253 133.664 87.5907 133.744 87.51C133.798
             87.4831 134.847 86.5413 135.707 86.2453C136.648 85.9223 137.374
             86.4336 137.428 86.4605C137.509 86.5143 137.536 86.6489 137.482
             86.7296C137.428 86.8103 137.294 86.8373 137.213 86.7834C137.186
             86.7565 136.622 86.3529 135.869 86.622C135.062 86.918 134.04
             87.806 134.013 87.806C133.96 87.8598 133.933 87.8867 133.879
             87.8867Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M129.63 90.0659C129.63 90.0659 131.647 93.1874 132.561
             94.0216C133.502 94.8558 134.551 95.5554 135.304 94.9903C137.805
             93.0259 139.445 92.3801 140.547 92.3801C141.65 92.3801 145.091
             93.7256 145.253 94.2368C145.414 94.7212 145.979 99.6725 145.28
             101.502C144.581 103.332 140.816 104.678 138.665 104.92C136.514
             105.162 133.341 104.22 131.997 103.359C129.684 101.879 129.335
             99.8609 129.093 98.5423C128.77 96.7394 128.044 94.6943 128.474
             93.1874C128.877 91.7074 127.883 90.2274 128.259 89.8776C128.635
             89.5277 129.227 89.3663 129.63 90.0659Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M134.202 93.133C134.363 93.6712 135.304 93.8596 136.326
             93.5636C137.348 93.2676 138.02 92.5948 137.859 92.0566C137.697
             91.5185 136.756 91.3301 135.735 91.6261C134.74 91.949 134.041
             92.6217 134.202 93.133Z"
          fill={theme.palette.common.chestnutRose}
        />
        <path
          d="M142.806 91.0354C142.967 91.5736 143.586 91.8427 144.177
             91.6813C144.769 91.4929 145.145 90.9278 144.984 90.3896C144.823
             89.8514 144.204 89.5823 143.613 89.7438C143.021 89.9322 142.645
             90.5242 142.806 91.0354Z"
          fill={theme.palette.common.chestnutRose}
        />
        <path
          d="M99.6227 176.686L101.881 180.346C102.097 180.723 102.5 180.911
             102.903 180.911C103.118 180.911 103.333 180.857 103.549
             180.723C104.113 180.373 104.274 179.619 103.925 179.054L100.94
             174.237C100.752 173.941 100.456 173.753 100.134 173.672C99.7841
             173.619 99.4345 173.699 99.1656 173.915L94.7827 177.413C94.2719
             177.816 94.1912 178.597 94.5945 179.108C94.9979 179.619 95.7776
             179.7 96.2885 179.296L99.6227 176.686Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M69.6417 197.192L71.9004 200.852C72.1155 201.228 72.5188 201.417
             72.9222 201.417C73.1373 201.417 73.3524 201.363 73.5675
             201.228C74.1322 200.879 74.2935 200.125 73.9439 199.56L70.9862
             194.77C70.798 194.474 70.5022 194.286 70.1795 194.205C70.1526
             194.205 70.1526 194.205 70.1257 194.205C69.9106 195.228 69.6686
             196.304 69.4266 197.353L69.6417 197.192Z"
          fill={theme.palette.common.white}/>
        <path
          d="M112.153 157.769C112.556 158.281 113.336 158.361 113.847
             157.958L117.181 155.294L119.44 158.953C119.655 159.33 120.058
             159.518 120.462 159.518C120.677 159.518 120.892 159.465 121.107
             159.33C121.672 158.98 121.833 158.227 121.483 157.662L118.499
             152.845C118.31 152.549 118.015 152.361 117.692 152.28C117.342
             152.226 116.993 152.307 116.724 152.522L112.341 156.02C111.803
             156.478 111.723 157.231 112.153 157.769Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M146.221 192.375L143.236 187.558C143.048 187.262 142.752 187.073
             142.43 186.993C142.08 186.939 141.731 187.02 141.462
             187.235L137.079 190.733C136.568 191.137 136.487 191.917 136.891
             192.428C137.294 192.94 138.074 193.02 138.585 192.617L141.919
             189.953L144.178 193.612C144.393 193.989 144.796 194.177 145.199
             194.177C145.414 194.177 145.63 194.124 145.845 193.989C146.382
             193.693 146.571 192.94 146.221 192.375Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M146.141 140.817C145.791 140.763 145.441 140.844 145.173
             141.059L140.79 144.557C140.279 144.961 140.198 145.741 140.602
             146.253C141.005 146.764 141.785 146.845 142.296 146.441L145.63
             143.777L147.888 147.437C148.103 147.813 148.507 148.002 148.91
             148.002C149.125 148.002 149.34 147.948 149.555 147.813C150.12
             147.463 150.281 146.71 149.932 146.145L146.947 141.328C146.759
             141.059 146.463 140.871 146.141 140.817Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M139.472 112.508C138.961 112.912 138.88 113.692 139.283
             114.203C139.687 114.715 140.467 114.795 140.977 114.392L143.801
             112.131C143.344 111.485 142.86 110.84 142.322 110.221L139.472
             112.508Z"
          fill={theme.palette.common.white}/>
        <path
          d="M117.019 121.441C117.423 121.952 118.203 122.033 118.713
             121.63L122.048 118.966L124.306 122.625C124.521 123.002 124.925
             123.19 125.328 123.19C125.543 123.19 125.758 123.136 125.973
             123.002C126.538 122.652 126.699 121.899 126.35 121.334L123.365
             116.517C123.177 116.221 122.881 116.032 122.559 115.952C122.209
             115.898 121.859 115.979 121.591 116.194L117.208 119.692C116.697
             120.15 116.616 120.903 117.019 121.441Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M80.1017 160.218L82.3604 163.878C82.5755 164.255 82.9788 164.443
             83.3822 164.443C83.5973 164.443 83.8124 164.389 84.0275
             164.255C84.5922 163.905 84.7535 163.152 84.4039 162.586L81.4193
             157.77C81.2311 157.474 80.9353 157.285 80.6126 157.205C80.5588
             157.205 80.5051 157.178 80.4244 157.178C79.9404 158.416 79.4564
             159.707 78.9993 161.026L80.1017 160.218Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M116.401 200.69C116.052 200.636 115.702 200.717 115.433
             200.932L111.05 204.43C110.54 204.834 110.459 205.614 110.862
             206.126C111.266 206.637 112.045 206.718 112.556 206.314L115.89
             203.677L118.149 207.336C118.364 207.713 118.768 207.902 119.171
             207.902C119.386 207.902 119.601 207.848 119.816 207.713C120.381
             207.363 120.542 206.61 120.193 206.045L117.235 201.255C117.047
             200.959 116.751 200.744 116.401 200.69Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M134.175 89.7152C134.148 89.7152 134.094 89.7152 134.067
             89.6883C133.987 89.6345 133.96 89.4999 134.014 89.4192C134.041
             89.3923 135.412 87.5087 137.267 88.3698C137.375 88.4236 137.402
             88.5312 137.375 88.6388C137.321 88.7465 137.213 88.7734 137.106
             88.7465C135.546 88.0199 134.39 89.6076 134.336 89.6614C134.309
             89.6883 134.256 89.7152 134.175 89.7152Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M141.032 88.6122C140.978 88.6122 140.951 88.5853 140.897
             88.5584C140.817 88.4777 140.79 88.3701 140.87 88.2893C140.897
             88.2624 141.543 87.4551 142.376 87.4013C142.806 87.3744 143.21
             87.5359 143.586 87.8588C143.667 87.9395 143.667 88.0741 143.586
             88.1548C143.505 88.2355 143.371 88.2355 143.29 88.1548C143.021
             87.8857 142.699 87.7781 142.403 87.805C141.731 87.8588 141.166
             88.5315 141.166 88.5584C141.166 88.5853 141.112 88.6122 141.032
             88.6122Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M126.135 88.4773C126.135 88.4773 127.937 91.3835 129.389
             91.4104C130.814 91.4373 129.873 86.5399 129.899 85.5711C129.926
             84.6024 130.572 83.4453 130.572 83.4453C130.572 83.4453 123.769
             84.6562 126.135 88.4773Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M140.924 89.9583C140.924 89.9583 142.053 91.0347 141.784
             91.8689C141.515 92.6762 140.413 93.1067 139.552 92.9452C138.692
             92.7838 138.369 92.6492 138.154 92.2725C137.939 91.8958 138.342
             88.9896 140.924 89.9583Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M139.848 93.2406C139.069 93.2406 138.396 92.81 138.02
             92.4602C137.939 92.3795 137.939 92.245 138.02 92.1642C138.101
             92.0835 138.235 92.0835 138.316 92.1642C138.827 92.6755 139.983
             93.2675 141.166 92.3257C141.462 92.0835 141.623 91.8413 141.65
             91.5453C141.677 91.1417 141.354 90.6304 140.736 90.0115C139.66
             88.9889 139.875 87.482 140.359 86.5133C140.924 85.3562 142.887
             85.6791 142.967 85.706C143.075 85.7329 143.156 85.8406 143.129
             85.9482C143.102 86.0558 142.994 86.1366 142.887 86.1097C142.86
             86.1097 141.139 85.8137 140.709 86.7017C140.279 87.5628 140.117
             88.8544 141.005 89.7424C141.757 90.442 142.08 91.034 142.053
             91.5722C142.026 91.9759 141.838 92.3257 141.435 92.6486C140.87
             93.0791 140.332 93.2406 139.848 93.2406Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M124.818 88.048L123.904 87.1062C123.904 87.1062 122.452 82.3433
             127.13 79.1142C131.809 75.8582 136.676 76.2349 139.365
             78.0647C142.053 79.8945 142.779 83.5273 141.865 83.8771C140.951
             84.2269 140.682 84.3076 139.607 84.6036C138.531 84.8996 136.272
             83.9309 134.094 84.6574C131.916 85.384 130.518 85.2494 129.873
             87.16C129.227 89.0436 125.302 88.5324 124.818 88.048Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M137.993 94.8554C137.993 94.8554 140.897 92.9449 143.102
             94.6132C143.102 94.6132 142.699 96.147 141.031 96.6852C139.364
             97.2234 137.993 94.8554 137.993 94.8554Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M142.241 81.9128C142.241 81.9128 133.933 82.9084 130.598
             85.3571C127.237 87.8328 123.876 90.1739 123.58 89.851C123.285
             89.5281 122.478 83.1506 122.72 82.3971C122.774 82.2088 122.881
             82.0204 122.989 81.8859C123.446 81.267 123.688 80.5135 123.634
             79.7331C123.553 77.7149 123.742 75.024 124.118 74.6473C125.516
             73.3018 127.936 73.4095 128.528 73.1942C129.119 72.9789 130.356
             71.2298 131.755 71.6873C135.089 72.7906 137.025 76.3964 136.998
             75.8851C136.998 75.7775 141.757 76.9077 142.241 81.9128Z"
          fill={theme.palette.common.lightBrown}
        />
        <path
          d="M135.116 88.6669C135.116 88.6669 134.928 90.1469 136.272
             90.0123C137.616 89.8778 137.159 88.5323 136.729 88.3709C136.326
             88.1825 135.277 88.4785 135.116 88.6669Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M141.838 87.9403C141.838 87.9403 141.703 89.4203 142.645
             89.2858C143.586 89.1512 143.263 87.8058 142.967 87.6443C142.671
             87.4559 141.945 87.7519 141.838 87.9403Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M98.4397 78.064L104.463 81.616C105.404 82.1542 105.7 83.3651 105.162
             84.3069L97.2297 97.7884C96.692 98.7302 95.482 99.0262 94.5409
             98.488L88.5178 94.936C87.5767 94.3978 87.2809 93.1869 87.8187
             92.2451L95.7509 78.7636C96.2886 77.8218 97.4986 77.5258 98.4397
             78.064Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M87.2268 91.1692C88.3024 89.2048 88.8402 87.4019 89.9426
             86.8637C91.045 86.3255 94.433 86.0295 95.2128 86.1372C95.9926
             86.2448 101.559 86.0833 102.096 86.2179C102.634 86.3255 104.328
             86.9175 104.973 87.4557C105.619 87.9939 104.57 88.9357 103.817
             88.6935C103.064 88.4513 99.5956 89.4739 99.5956 89.4739C99.5956
             89.4739 102.123 89.6892 102.5 90.6848C102.876 91.6804 101.075
             92.4608 101.075 92.4608C101.075 92.4608 101.021 93.6448 100.295
             93.8063C99.5957 93.9677 99.5956 93.9677 99.5956 93.9677C99.5956
             93.9677 99.9721 94.8019 98.9503 95.1248C97.9285 95.4208 95.6968
             95.3401 94.7288 95.9052C93.7608 96.4703 89.6468 102.04 89.6468
             102.04C89.6468 102.04 85.3446 97.5466 83.4624 94.0754C85.4791
             92.703 86.8235 91.8957 87.2268 91.1692Z"
          fill={theme.palette.common.antiqueBrass}
        />
        <path
          d="M129.873 111.808C128.313 111.808 125.463 107.825 124.764
             106.614C123.742 104.865 124.172 103.332 124.199 103.251C124.226
             103.143 124.333 103.089 124.441 103.116C124.549 103.143 124.602
             103.251 124.575 103.358C124.575 103.385 124.172 104.812 125.113
             106.399C125.597 107.206 126.565 108.606 127.587 109.736C128.716
             110.974 129.604 111.566 130.034 111.377C131.19 110.866 133.287
             108.633 133.287 108.606C133.314 108.552 133.368 108.552 133.422
             108.552C133.476 108.552 133.529 108.579 133.556 108.606C134.175
             109.278 135.949 110.92 136.783 110.489C137.536 110.086 137.213
             107.449 136.864 105.861C136.837 105.753 136.917 105.646 137.025
             105.619C137.133 105.592 137.24 105.673 137.267 105.78C137.375
             106.238 138.235 110.193 136.971 110.866C135.842 111.458 133.987
             109.655 133.395 109.063C132.884 109.601 131.217 111.297 130.141
             111.754C130.088 111.781 129.98 111.808 129.873 111.808Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M47.5928 327.97C47.5928 327.97 48.1575 325.495 49.6364
             325.414C51.1153 325.333 57.1652 326.114 57.1383 327.082C57.0846
             328.051 55.4175 330.419 55.4175 330.419C55.4175 330.419 46.1677
             330.338 47.5928 327.97Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M122.183 329.503C122.183 329.503 121.107 326.381 122.183
             326.004C123.258 325.628 129.308 325.708 129.443 326.139C129.577
             326.596 130.545 330.606 130.626 330.848C130.679 331.09 125.382
             331.252 124.71 330.983C123.984 330.714 122.183 329.503 122.183
             329.503Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M127.264 215.785C127.13 215.785 127.049 215.678 127.076
             215.57C127.076 215.543 127.264 212.099 127.641 209.246C128.259
             204.483 131.325 179.216 131.163 173.888C130.975 168.129 130.894
             163.77 131.54 155.859C131.647 154.486 131.782 152.925 131.943
             151.257C132.642 143.131 133.61 131.99 133.287 125.613C132.884
             117.97 133.287 108.794 133.287 108.714C133.287 108.606 133.395
             108.525 133.503 108.525C133.61 108.525 133.691 108.633 133.691
             108.741C133.691 108.821 133.287 117.97 133.691 125.613C134.013
             132.044 133.045 143.184 132.346 151.338C132.212 153.006 132.077
             154.567 131.943 155.939C131.298 163.824 131.378 168.183 131.567
             173.915C131.755 179.781 128.098 209.031 128.044 209.327C127.668
             212.153 127.479 215.57 127.479 215.597C127.453 215.705 127.372
             215.785 127.264 215.785Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M134.659 203.919C134.659 204.43 134.39 204.834 134.067
             204.834C133.744 204.834 133.475 204.43 133.475 203.919C133.475
             203.408 133.744 203.004 134.067 203.004C134.39 203.004 134.659
             203.408 134.659 203.919Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M135.842 168.345C135.842 168.856 135.573 169.26 135.251
             169.26C134.928 169.26 134.659 168.856 134.659 168.345C134.659
             167.833 134.928 167.43 135.251 167.43C135.573 167.43 135.842
             167.833 135.842 168.345Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M135.251 133.713C135.577 133.713 135.842 133.303 135.842
             132.798C135.842 132.292 135.577 131.883 135.251 131.883C134.924
             131.883 134.659 132.292 134.659 132.798C134.659 133.303 134.924
             133.713 135.251 133.713Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M198.95 126.984C198.762 126.688 198.466 126.499 198.143
             126.418C197.794 126.365 197.444 126.445 197.175 126.661L192.792
             130.159C192.281 130.562 192.201 131.343 192.604 131.854C193.007
             132.365 193.787 132.446 194.298 132.042L197.632 129.378L199.891
             133.038C200.106 133.415 200.509 133.603 200.913 133.603C201.128
             133.603 201.343 133.549 201.558 133.415C202.123 133.065 202.284
             132.312 201.934 131.746L198.95 126.984Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M190.05 152.87C189.862 152.574 189.566 152.386 189.243
             152.305C188.894 152.251 188.544 152.332 188.275 152.547L184.484
             155.561C185.667 155.776 186.823 155.884 187.926 155.884L188.706
             155.265L189.055 155.857C189.942 155.803 190.803 155.669 191.636
             155.454L190.05 152.87Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M166.065 140.304L169.399 137.64L171.658 141.299C171.873 141.676
             172.276 141.865 172.68 141.865C172.895 141.865 173.11 141.811
             173.325 141.676C173.89 141.326 174.051 140.573 173.702
             140.008L170.744 135.218C170.556 134.922 170.26 134.734 169.937
             134.653C169.588 134.599 169.238 134.68 168.969 134.895L164.586
             138.393C164.075 138.797 163.995 139.577 164.398 140.089C164.801
             140.627 165.554 140.707 166.065 140.304Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M174.75 115.575L178.084 112.911L180.343 116.57C180.558 116.947
             180.961 117.136 181.365 117.136C181.58 117.136 181.795 117.082
             182.01 116.947C182.575 116.597 182.736 115.844 182.386
             115.279L179.402 110.462C179.214 110.166 178.918 109.978 178.595
             109.897C178.246 109.843 177.896 109.924 177.627 110.139L173.244
             113.637C172.733 114.041 172.653 114.821 173.056 115.333C173.486
             115.898 174.239 116.005 174.75 115.575Z"
          fill={theme.palette.common.white}/>
        <path
          d="M75.1003 117.594C74.5894 117.998 74.5088 118.778 74.9121
             119.29C75.3154 119.801 76.0952 119.882 76.6061 119.478L79.9403
             116.814L82.0645 120.258C82.6023 119.666 83.14 119.021 83.7316
             118.375L81.2847 114.419C81.0965 114.123 80.8007 113.935 80.4781
             113.854C80.1285 113.8 79.779 113.881 79.5101 114.096L75.1003
             117.594Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M73.3257 146.36C73.5408 146.736 73.9441 146.925 74.3474
             146.925C74.5625 146.925 74.7776 146.871 74.9928 146.736C75.5574
             146.386 75.7187 145.633 75.3692 145.068L72.3846 140.251C72.1963
             139.955 71.9006 139.767 71.5779 139.686C71.2283 139.632 70.8788
             139.713 70.6099 139.928L66.227 143.426C65.7162 143.83 65.6355
             144.61 66.0388 145.122C66.4422 145.633 67.2219 145.714 67.7328
             145.31L71.067 142.646L73.3257 146.36Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M56.0629 127.416L54.4765 124.859C53.9656 125.801 53.5891 126.689
             53.2934 127.523L53.9925 128.68C54.2076 129.057 54.6109 129.246
             55.0143 129.246C55.2294 129.246 55.4445 129.192 55.6596
             129.057C56.2242 128.734 56.4125 127.981 56.0629 127.416Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M102.446 126.232C102.258 125.936 101.962 125.747 101.64
             125.667C101.29 125.613 100.941 125.693 100.672 125.909L96.2888
             129.407C95.7779 129.811 95.6973 130.591 96.1006 131.102C96.5039
             131.613 97.2837 131.694 97.7946 131.291L101.129 128.627L103.387
             132.286C103.603 132.663 104.006 132.851 104.409 132.851C104.624
             132.851 104.839 132.797 105.055 132.663C105.619 132.313 105.781
             131.56 105.431 130.995L102.446 126.232Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M145.442 216.324C136.219 216.324 123.662 213.66 114.923
             210.673C110.029 209.004 106.103 206.852 102.285 204.753C98.1711
             202.492 94.2722 200.367 89.5129 198.994C80.4245 196.384 70.8252
             198.967 70.7177 198.994C70.6101 199.021 70.5026 198.967 70.4757
             198.86C70.4488 198.752 70.5026 198.644 70.6101 198.617C70.7177
             198.591 80.4245 195.98 89.6205 198.617C94.4335 199.99 98.3593
             202.143 102.473 204.403C106.265 206.475 110.19 208.628 115.03
             210.296C124.952 213.687 139.822 216.647 149.072 215.759C149.179
             215.759 149.287 215.839 149.287 215.947C149.287 216.055 149.206
             216.162 149.098 216.162C147.969 216.27 146.732 216.324 145.442
             216.324Z"
          fill={theme.palette.common.white}
        />
        <path
          d="M130.384 233.599C129.604 233.599 128.663 233.572 127.534
             233.491C124.899 233.33 122.263 233.034 122.237 233.034C122.129
             233.034 122.048 232.926 122.048 232.819C122.048 232.711 122.156
             232.63 122.263 232.63C122.29 232.63 124.925 232.926 127.561
             233.088C132.669 233.411 132.884 232.872 132.884 232.846C132.884
             232.846 132.884 232.63 131.89 232.146C126.808 229.59 101.613
             226.145 101.344 226.118C101.236 226.091 101.156 226.011 101.183
             225.903C101.21 225.795 101.29 225.715 101.398 225.742C102.446
             225.876 126.915 229.24 132.051 231.823C132.992 232.28 133.342
             232.657 133.234 233.007C133.153 233.411 132.266 233.599 130.384
             233.599Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M94.8637 262.122C90.7498 262.122 79.6985 258.624 76.0416
             255.664C72.331 252.677 66.6306 250.821 66.5768 250.794C66.4692
             250.767 66.4154 250.659 66.4423 250.552C66.4692 250.444 66.5768
             250.39 66.6843 250.417C66.7381 250.444 72.4923 252.328 76.2836
             255.368C79.9943 258.355 91.9867 262.096 95.3746 261.719C98.7895
             261.342 101.371 261.073 101.371 261.073C101.478 261.073 101.586
             261.127 101.586 261.261C101.586 261.369 101.532 261.477 101.398
             261.477C101.398 261.477 98.8433 261.773 95.4015 262.122C95.2671
             262.122 95.0789 262.122 94.8637 262.122Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M146.786 264.302C143.478 264.302 136.729 264.248 131.029
             263.844C123.15 263.306 114.627 261.449 114.546 261.423C114.438
             261.396 114.358 261.288 114.385 261.18C114.411 261.073 114.519
             260.992 114.627 261.019C114.707 261.046 123.204 262.876 131.056
             263.441C138.907 263.979 148.748 263.898 148.829 263.898C148.937
             263.898 149.017 263.979 149.017 264.087C149.017 264.194 148.937
             264.302 148.829 264.302C148.829 264.302 148.049 264.302 146.786
             264.302Z"
          fill={theme.palette.common.metalBlue}
        />
        <path
          d="M139.042 71.8496L142.241 59.5252C142.241 59.5252 115.299 55.139
             122.962 38.4823C130.652 21.8255 186.016 20.8299 189.566
             34.1768C196.637 60.9514 153.723 59.7674 153.723 59.7674L139.042
             71.8496Z"
          fill={theme.palette.common.dustGreen}
        />
      </g>
      <path
        d="M158.578 65.9288C153.047 49.2146 144.75 44.5717 132.766
           40.8574C141.062 40.8574 151.203 40.8574 155.812 49.2146C156.73
           30.7279 165.233 19.4496 185.312 8.35742C163.999 25.0717 162.266
           42.7146 158.578 65.9288Z"
        fill={theme.palette.primary.main}
      />
      <defs>
        <clipPath id="clip0_3289_16699">
          <rect
            width="226"
            height="327"
            fill={theme.palette.common.white}
            transform="translate(0 25)"
          />
        </clipPath>
      </defs>
     </SvgIcon>
  );
};

export default YouDidItIcon;
