import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import MultiStepForm from './MultiStepForm';
import CompletionScreen from './CompletionScreen';

const PersonalInformation = () => {
  return (
    <Switch>
      <Route
        path={apiUrls.PERSONAL_INFORMATION}
        component={MultiStepForm}
        exact
      />
      <Route
        path={apiUrls.PERSONAL_INFORMATION_COMPLETION}
        component={CompletionScreen}
        exact
      />
    </Switch>
  );
};

export default React.memo(PersonalInformation);
