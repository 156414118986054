import { useContext, useState } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { PageNumber } from './constants';

const usePersonalInformation = () => {
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const [currentPage, setCurrentPage] = useState<PageNumber>(
    PageNumber.FirstPage,
  );
  const next = () => setCurrentPage((previous) => previous + 1);
  const prev = () => setCurrentPage((previous) => previous - 1);
  const resetPage = () => setCurrentPage(PageNumber.FirstPage);
  const setPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return {
    loading: loadingPlanDocuments,
    self: planDocuments?.personal_information?.self,
    spouse: planDocuments?.personal_information?.spouse,
    hasPartner: planDocuments?.user_info?.is_married,
    userInfo: planDocuments?.user_info,
    prev,
    next,
    currentPage,
    resetPage,
    setPage,
  };
};

export default usePersonalInformation;
