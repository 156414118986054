import React from 'react';
import {
  Box,
  Breakpoint,
  Button,
  Divider,
  FormControl,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  BREAKPOINTS,
  CognitoHostedUIIdentityProvider,
  VALIDATOR_REGEX,
} from '../../variables/constant';
import TopBar from '../../components/TopBar';
import SignInIcon from '../../components/Icons/SignInIcon';
import { useHistory } from 'react-router-dom';
import useSalesSignup from './sales-signup-hooks';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import SocialMediaSignUp from './SocialMediaSignUp';
import { apiUrls } from '../../variables/urls';
import DisclaimerFooter from '../../components/DisclaimerFooter';

const SalesCreateAccount = () => {
  const theme = useTheme();
  const history = useHistory();
  const {
    onUserSignup,
    register,
    handleSubmit,
    errors,
  } = useSalesSignup();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
      sx={{
        [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
          backgroundColor: theme.palette.common.white,
          mt: 6,
        },
      }}
    >
      <TopBar />
      <Grid
        container
        sx={{
          width: '80%',
          mt: 5,
          [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
            mt: 15,
          },
        }}
      >
        <Grid
          item
          lg={6}
          sx={{
            [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
              display: 'none',
            },
          }}
        >
          <Box
            component={SignInIcon}
            sx={{
              width: '554px',
              height: '523px',
            }}
          />
        </Grid>
        <Grid item lg={4} xs={12} sx={{ justifyContent: 'center' }}>
          <Box
            sx={{
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                textAlign: 'center',
              },
            }}
          >
            <Box>
              <Typography variant="xlDisplay">Create Your Account</Typography>
            </Box>
            <Box
              sx={{
                my: 2,
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            >
              <Typography variant="s">
                Creating an account will allow you to save your progress
              </Typography>
            </Box>
          </Box>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <SocialMediaSignUp
                provider={CognitoHostedUIIdentityProvider.Google}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }}>
            <Typography variant="sInfo">or</Typography>
          </Divider>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onUserSignup)}
            noValidate
          >
            <Stack
              spacing={2}
              sx={{
                marginBottom: 3,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <FormControl
                sx={{
                  mt: 5,
                }}
              >
                <TextField
                  label="Sales Person Name"
                  variant="outlined"
                  type="text"
                  {...register('salesPersonName', {
                    required: {
                      value: true,
                      message: 'Sales Person name is required',
                    },
                  })}
                  error={!!errors.salesPersonName}
                />
                {errors.salesPersonName && (
                  <FormHelperErrorText>
                    {errors?.salesPersonName?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <Typography variant="sCaps">use your email</Typography>
              <FormControl
                sx={{
                  mt: 5,
                }}
              >
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  {...register('email', {
                    required: { value: true, message: 'Email is required' },
                    pattern: {
                      value: VALIDATOR_REGEX.email,
                      message: 'Please enter correct email address.',
                    },
                  })}
                />
                {errors.email && (
                  <FormHelperErrorText>
                    {errors?.email?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  mt: 5,
                }}
              >
                <TextField
                  label="Create Password"
                  variant="outlined"
                  type="password"
                  {...register('password', {
                    required: { value: true, message: 'Password is required' },
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters',
                    },
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                      message:
                        'Password must contain at least one number and both lower and uppercase letters and special characters',
                    },
                  })}
                />
                {errors.password && (
                  <FormHelperErrorText>
                    {errors?.password?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <Button type="submit" variant="contained" color="primary">
                Create Account
              </Button>
            </Stack>
          </form>
          <Box
            display="flex"
            sx={{
              [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                flexDirection: 'column',
              },
            }}
          >
            <Typography variant="mLonger">Already have an account?</Typography>
            <Typography
              variant="mLonger"
              color="primary"
              onClick={() => history.push(apiUrls.LOGIN)}
              sx={{
                cursor: 'pointer',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  ml: 0.5,
                },
              }}
            >
              Sign In
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: 5,
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                maxWidth: '70%',
              },
            }}
          >
            <DisclaimerFooter />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(SalesCreateAccount);
