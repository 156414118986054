import React from 'react';
import { FormControl, Grid, Stack, Typography } from '@mui/material';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import { useTheme } from '@mui/material/styles';
import { PersonType } from '../../../generated/graphql';
import { Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormProps } from './personal-information-form-hooks';
import isValidDateOfBirth from '../../../utils/isValidDateOfBirth';
import HtmlTooltip from '../../../components/HtmlTooltip';
import DateOfBirthPicker from '../../../components/DateOfBirthPicker';
import TabLayout from '../../../components/TabLayout';
import { SUMMARY } from '../../Summary/constants';

const AboutYouForm = ({
  type,
  errors,
  control,
  previousPage,
  nextPage,
  uiFlag,
  setEditState,
  handleSubmit,
}: FormProps) => {
  const theme = useTheme();
  const submitFormData = (e: any) => {
    e.preventDefault();
    nextPage(['date_of_birth']);
  };

  return (
    <form autoComplete="off" onSubmit={uiFlag === SUMMARY ? handleSubmit : submitFormData} noValidate>
      <TabLayout 
        title="Personal Information" 
        previous={previousPage}
        uiFlag={uiFlag}
        setEditState={setEditState}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="m" component="p">
                {type === PersonType.Self
                  ? 'What is your date of birth?'
                  : 'What is your spouse/partner’s date of birth?'}
                <HtmlTooltip
                  title={
                    type === PersonType.Self ? (
                      <React.Fragment>
                        Why Do You Need My Date of Birth? <br />
                        Most states have minimum age requirements for who can
                        create estate planning documents. <br /> <br /> Why Do
                        You Need My Phone Number? <br />A phone number is
                        necessary in the event we need to contact you regarding
                        your estate plan and to verify your account for security
                        purposes.
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        Why Do You Need Date of Birth? <br />
                        Most states have minimum age requirements for who can
                        create estate planning documents. <br /> <br /> Why Do
                        You Need My Phone Number? <br />A phone number is
                        necessary in the event we need to contact you regarding
                        your estate plan and to verify your account for security
                        purposes.
                      </React.Fragment>
                    )
                  }
                />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="date_of_birth"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateOfBirthPicker onChange={onChange} value={value} />
                    </LocalizationProvider>
                  )}
                  rules={{
                    required: 'Please select the date of birth',
                    validate: {
                      validDateOfBirth: (value: Date | null) =>
                        isValidDateOfBirth(value) ||
                        'Please enter valid date of birth.',
                    },
                  }}
                  control={control}
                />
                {errors.date_of_birth && (
                  <FormHelperErrorText>
                    {errors?.date_of_birth?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </TabLayout>
    </form>
  );
};

export default React.memo(AboutYouForm);
