import React from 'react';
import useTrustees from './trustees-hooks';
import TrusteesForm from './TrusteesForm';
import Loader from '../../components/Loader';
import { Route, Switch } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import CompletionScreen from './CompletionScreen';

const Trustees = () => {
  const { loading, trusteesInfo } = useTrustees();
  
  return (
    <>
      <Switch>
        <Route
          path={apiUrls.TRUSTEES}
          component={() => <TrusteesForm info={trusteesInfo || null} />}
          exact
        />
        <Route
          path={apiUrls.TRUSTEES_COMPLETION}
          component={CompletionScreen}
          exact
        />
      </Switch>
      <Loader open={loading} />
    </>
  );
};

export default React.memo(Trustees);
