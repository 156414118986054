import React from 'react';
import { ChildrenFragmentFragment } from '../../generated/graphql';
import { useTheme } from '@mui/material/styles';
import TabLayout from '../../components/TabLayout';
import { Grid, Stack, Typography } from '@mui/material';
import ChildrenForm from './ChildrenForm';
import Loader from '../../components/Loader';
import useChildrenForm from './children-form-hooks';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';

interface Props {
  childrenList: ChildrenFragmentFragment[];
  setShowChildrenForm: Function;
  setShowCompletionScreen: Function;
  uiFlag: string;
  setEditState: Function;
}

const ChildrenBox = ({
  childrenList,
  setShowChildrenForm,
  setShowCompletionScreen,
  uiFlag,
  setEditState,
}: Props) => {
  const theme = useTheme();
  const history = useHistory();
  const {
    loading,
    handleSubmit,
    create,
    control,
    fields,
    append,
    onRemoveChild,
    errors,
  } = useChildrenForm({
    childrenList,
    setShowCompletionScreen,
    uiFlag,
    setEditState,
  });

  const previousAction = () => {
    if (childrenList.length > 0) {
      history.push(apiUrls.PERSONAL_INFORMATION);
    } else {
      setShowChildrenForm(false);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout title="Children" previous={previousAction} uiFlag={uiFlag} setEditState={setEditState}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="m" component="p">
                Children Details
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={11}>
            <ChildrenForm
              control={control}
              fields={fields}
              append={append}
              errors={errors}
              onRemoveChild={onRemoveChild}
            />
          </Grid>
        </Grid>
      </TabLayout>
      <Loader open={loading} />
    </form>
  );
};
export default React.memo(ChildrenBox);
