import React from 'react';
import { FormControlLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledRadioButton from '../StyledRadioButton';
import { makeStyles } from '@mui/styles';

interface Props {
  name: string;
  value: string;
  label: string;
}

const StyledFormControlLabel = ({ name, value, label }: Props) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    labelPlacementStart: {
      justifyContent: 'space-between',
    },
  });
  const classes = useStyles();
  return (
    <FormControlLabel
      name={name}
      value={value}
      control={<StyledRadioButton name={name} />}
      label={label}
      labelPlacement="start"
      sx={{
        backgroundColor: theme.palette.background.default,
        border: '0.063rem solid',
        borderColor: 'common.lightGreen',
        borderRadius: '0.25rem',
        width: '10rem',
        pl: 2,
        mb: 2,
      }}
      className={classes.labelPlacementStart}
    />
  );
};

export default React.memo(StyledFormControlLabel);
