import React from 'react';
import Loader from '../../components/Loader';
import useBeneficiary, { BeneficiaryProps } from './beneficiaries-hooks';
import BeneficiariesAgeForm from './BeneficiariesAgeForm';
import BeneficiariesBox from './BeneficiariesBox';

const BeneficiariesMain = ({ uiFlag, setEditState }: BeneficiaryProps) => {
  const {
    loading,
    onBeneficiaryNameChange,
    onBeneficiaryPercentChange,
    onBeneficiaryRemove,
    onBeneficiaryAppend,
    totalPercentage,
    beneficiaries,
    divideEqually,
    setDivideEqually,
    onEquallyDivided,
    trusteeName,
    onNextClickPercentForm,
    onNextClickAgeForm,
    handleSubmit,
    showPercentForm,
    setShowPercentForm,
    errors,
    control,
  } = useBeneficiary({
    uiFlag,
    setEditState,
  });
  return (
    <div>
      {showPercentForm ? (
        <BeneficiariesBox
          onBeneficiaryNameChange={onBeneficiaryNameChange}
          onBeneficiaryPercentChange={onBeneficiaryPercentChange}
          onBeneficiaryRemove={onBeneficiaryRemove}
          onBeneficiaryAppend={onBeneficiaryAppend}
          totalPercentage={totalPercentage}
          beneficiaries={beneficiaries}
          divideEqually={divideEqually}
          setDivideEqually={setDivideEqually}
          onEquallyDivided={onEquallyDivided}
          onNextClickPercentForm={onNextClickPercentForm}
          handleSubmit={handleSubmit}
          uiFlag={uiFlag}
          setEditState={setEditState}
        />
      ) : (
        <BeneficiariesAgeForm
          trusteeName={trusteeName}
          handleSubmit={handleSubmit}
          onNextClickAgeForm={onNextClickAgeForm}
          setShowPercentForm={setShowPercentForm}
          uiFlag={uiFlag}
          setEditState={setEditState}
          control={control}
          errors={errors}
        />
      )}
      <Loader open={loading} />
    </div>
  );
};

export default React.memo(BeneficiariesMain);
