import React, { useEffect, useState } from 'react';
import { Box, Breakpoint, Link, MobileStepper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TopBar from '../../components/TopBar';
import { BREAKPOINTS } from '../../variables/constant';
import Typography from '@mui/material/Typography';
import palette from '../../theme/palette';
import usePlanInfo from './plan-hook';
import PlanView from './PlanView';
import Loader from '../../components/Loader';
import { Link as RouterLink } from 'react-router-dom';

const Plan = () => {
  const theme = useTheme();
  const { planInfo, loading, recommendedPlan, onPlanSelection } = usePlanInfo();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 2;
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  // Need different background color for plan page
  useEffect(() => {
    document.body.classList.add('background-default');
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent={'center'}
      width="100%"
      bgcolor="background.default"
      sx={{
        mt: 18,
        [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
          mx: 0,
          mt: 12,
        },
      }}
    >
      <TopBar />
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            mx: 0,
          },
        }}
      >
        <Typography variant="lgText" component="p" textAlign="center">
          Select a plan to get started
        </Typography>
        <Typography
          variant="smText"
          component="p"
          sx={{
            color: palette.text.primary,
            width: '80%',
            textAlign: 'center',
          }}
        >
          We recommend the following plans based on the information you provided
          earlier.
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              flexDirection: 'column',
            },
          }}
        >
          <PlanView
            planInfo={planInfo || []}
            recommendedPlan={recommendedPlan}
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            onPlanSelection={onPlanSelection}
          />
        </Stack>
        <Box>
          <Typography
            variant="mLink"
            sx={{ textDecoration: 'none', fontWeight: 500 }}
          >
            To know more visit&nbsp;
          </Typography>
          <Link
            to={{
              pathname: process.env.REACT_APP_HOME_URL + '/will-vs-trust',
            }}
            target="_blank"
            component={RouterLink}
            variant="mLink"
            color={theme.palette.primary.main}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              mt: 7.5,
              fontWeight: 400,
            }}
          >
            Will vs Trust.
          </Link>
        </Box>

        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={null}
          backButton={null}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiMobileStepper-dot': {
              backgroundColor: theme.palette.common.lightGreen,
            },
            '& .MuiMobileStepper-dotActive': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        />
      </Stack>

      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(Plan);
