import { useContext, useEffect, useState } from 'react';
import { useAuthProvider } from '../../core/authContext';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useHistory, useLocation } from 'react-router-dom';
import getErrorMessage from '../../utils/getErrorMessage';
import {
  OnBoardingStatus,
  PaymentStatus,
  useCreateCheckoutSessionLazyQuery,
  useDocumentPdfLazyQuery,
  usePaymentDetailsQuery,
  useUpdateOnBoardingStatusMutation,
} from '../../generated/graphql';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { apiUrls } from '../../variables/urls';

const useStripeCheckout = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const history = useHistory();
  const companyID = user?.user_info?.company_id;

  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } =
    useContext(DocumentContext);

  useEffect(() => {
    if (!planDocuments) {
      refetchPlanDocuments?.();
    }
  }, [planDocuments, refetchPlanDocuments]);

  const [planID, setPlanID] = useState();
  useEffect(() => {
    if (user?.user_info.plan_id) {
      setPlanID(user?.user_info.plan_id);
    }
  }, [user]);

  const [
    createCheckoutSession,
    {
      data: checkoutSession,
      loading: loadingCheckoutSession,
      error: checkoutSessionError,
    },
  ] = useCreateCheckoutSessionLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        plan_id: planID!,
      },
    },
  });

  const [updateOnBoardingStatus, { loading: loadingOnBoardingStatus }] =
    useUpdateOnBoardingStatusMutation();

  const { data: paymentDetails, loading: loadingPaymentDetails } =
    usePaymentDetailsQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (checkoutSessionError) {
      openSnackbar(
        { message: getErrorMessage(checkoutSessionError) },
        NotifierType.Error,
      );
    }
    if (!loadingCheckoutSession && checkoutSession?.createCheckoutSession) {
      window.location.replace(checkoutSession?.createCheckoutSession);
    }
  }, [loadingCheckoutSession, checkoutSession, checkoutSessionError]);

  const redirectToStripeCheckout = async () => {
    await createCheckoutSession();
  };

  const [
    planDocumentPDF,
    { loading: loadingPDFURL, data: documentPDF, error: documentPDFError },
  ] = useDocumentPdfLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      history.push(apiUrls.YOU_DID_IT);
    },
    onError: () => {
      openSnackbar(
        { message: getErrorMessage(documentPDFError) },
        NotifierType.Error,
      );
    },
  });

  const onContinueClick = async () => {
    if (!planDocuments?.is_document_complete) {
      openSnackbar(
        {
          message:
            'Please make sure you have completed all the steps before proceeding to notarization.',
        },
        NotifierType.Error,
      );
      return;
    }

    if (
      planDocuments?.user_info?.on_boarding_status ===
      OnBoardingStatus.Notarization ||
      planDocuments?.user_info?.on_boarding_status ===
      OnBoardingStatus.DocumentReceived
    ) {
      await planDocumentPDF();
    } else if (
      paymentDetails?.paymentDetails?.status === PaymentStatus.Success ||
      !!companyID || planDocuments?.manual_payment
    ) {
      await updateOnBoardingStatus({
        variables: {
          input: {
            on_boarding_status: OnBoardingStatus.Notarization,
          },
        },
      });
      await planDocumentPDF();
    } else {
      redirectToStripeCheckout();
    }
  };

  const search = useLocation().search;
  useEffect(() => {
    const canceled = new URLSearchParams(search).get('canceled');
    if (canceled) {
      openSnackbar(
        { message: 'Payment failed. Please try again.' },
        NotifierType.Error,
      );
    }
  }, [search]);

  return {
    loading:
      loadingCheckoutSession ||
      loadingPlanDocuments ||
      loadingPaymentDetails ||
      loadingOnBoardingStatus ||
      loadingPDFURL,
    planDocuments: planDocuments,
    redirectToStripeCheckout,
    onContinueClick,
    paymentDetails,
    documentPDF,
  };
};

export default useStripeCheckout;
