import { BeneficiariesFragmentFragment, BeneficiaryType } from '../../generated/graphql';
import {
  Box,
  Breakpoint,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BREAKPOINTS } from '../../variables/constant';
import { StyledInputAdornment } from '../../components/StyledInputAdornment/PercentageInput.styled';
import AddIcon from '@mui/icons-material/Add';
import { DeleteButton } from '../../components/DeleteButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface PercentProps {
  onBeneficiaryNameChange: Function;
  onBeneficiaryPercentChange: Function;
  onBeneficiaryRemove: Function;
  onBeneficiaryAppend: Function;
  totalPercentage: Number;
  beneficiaries: BeneficiariesFragmentFragment[];
  divideEqually: boolean;
  setDivideEqually: Function;
  onEquallyDivided: Function;
}

const BeneficiariesPercentForm = ({ 
  onBeneficiaryNameChange,
  onBeneficiaryPercentChange,
  onBeneficiaryRemove,
  onBeneficiaryAppend,
  totalPercentage,
  beneficiaries,
  divideEqually,
  setDivideEqually,
  onEquallyDivided,
}: PercentProps) => {
  const theme = useTheme();

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'equally') {
      onEquallyDivided();
    } else {
      setDivideEqually(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        px: 2,
        [theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint)]: {
          px: 11,
        },
        py: 2,
      }}
    >
      <Grid item xs={12}>
        <Stack
          spacing={2}
          sx={{
            mb: 2,
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
              width: '100%',
            },
          }}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={divideEqually ? 'equally' : 'customize'}
              onChange={onRadioChange}
            >
              <FormControlLabel value="equally" control={<Radio />} label="Divide Equally" 
                sx={{
                  [theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint)]: {
                    mx: 4,
                  },
                }} />
              <FormControlLabel value="customize" control={<Radio />} label="Customize"  
                sx={{
                  [theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint)]: {
                    mx: 4,
                  },
                }} />
            </RadioGroup>
          </FormControl>
          <Grid item xs={12} md={12} sx={{ width: '100%', display: 'flex', mt: 2 }}>
            <Typography variant="disclaimer" component="p" sx={{ 
              mb: 1,
              width: '65%',
              px: 2,
            }}>
              Beneficiary
            </Typography>
            <Typography variant="disclaimer" component="p" sx={{ 
              mb: 1,
              width: '35%',
              px: 2,
            }}>
              Percentage
            </Typography>
          </Grid>
          {beneficiaries.map((beneficiary, index) => {
            return (
              <Grid item xs={12} md={12} key={index} sx={{ width: '100%' }}>
                <FormControl
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingRight: 0,
                    },
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                    }}
                  >
                    {beneficiary.full_name &&
                    beneficiary.owner_type === BeneficiaryType.Child ? (
                      <Typography variant="mLonger" component="div" sx={{ 
                        mb: 1,
                        width: '65%',
                        borderRadius: '6px 0px 0px 6px',
                        backgroundColor: theme.palette.background.default,
                        border: '1px solid',
                        borderColor: theme.palette.grey[500],
                        padding: '17px 16px',
                      }}>
                        {beneficiary.full_name}
                      </Typography>
                      ) : (
                        <TextField
                          variant="outlined"
                          value={beneficiary.full_name}
                          onChange={onBeneficiaryNameChange(index)}
                          sx={{ 
                            mb: 2,
                            width: '65%',
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '6px 0px 0px 6px', 
                            },
                          }}
                        />
                      )}
                    <TextField
                      type="number"
                      variant="outlined"
                      disabled={divideEqually}
                      sx={{ 
                        width: '35%',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '0px 6px 6px 0px',
                          backgroundColor: divideEqually ? '#EFEFF4' : theme.palette.common.white,
                        },
                      }}
                      value={Number(beneficiary.inheritance_percentage) === 0 ? 0 : Number(Math.round((Number(beneficiary.inheritance_percentage) + Number.EPSILON) * 100) / 100).toString()}
                      onChange={onBeneficiaryPercentChange(index)}
                      InputProps={{
                        inputProps: {
                          inputMode: 'numeric',
                          max: 100,
                          min: 0,
                          pattern: '[0-9]*',
                        },
                        endAdornment: (
                          <StyledInputAdornment position="end">
                            <Typography variant="s" component="p">
                              %
                            </Typography>
                          </StyledInputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {beneficiary.owner_type === BeneficiaryType.Other && (
                    <DeleteButton
                      sx={{
                        height: 'auto',
                        [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                          ml: 2,
                          position: 'absolute',
                          height: '77%',
                          right: '-70px',
                        },
                      }}
                      onClick={onBeneficiaryRemove(index)}
                    ><DeleteOutlineIcon
                      sx={{
                        color: theme.palette.error.main,
                        fontSize: '1.5rem',
                        verticalAlign: 'middle',
                        cursor: 'pointer',
                      }} />
                    </DeleteButton>
                  )}
                </FormControl>
              </Grid>
            );
          })}
          <Grid
            item
            xs={12}
            md={12}
            sx={{ 
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button 
              sx={{ 
                width: 'auto',
                color: theme.palette.common.black,
                border: '1px solid',
                borderColor: theme.palette.primary.main,
              }}
              onClick={onBeneficiaryAppend({
                full_name: '',
                inheritance_percentage: '0',
                owner_id: '',
                owner_type: BeneficiaryType.Other,
              })}>
              <AddIcon sx={{
                color: theme.palette.common.black,
                fontSize: '1.5rem',
                verticalAlign: 'middle',
                mr: 1,
              }}></AddIcon>Add Another
          </Button>
          <Box>
            <Typography variant="sTextInfo" color={totalPercentage !== 100 ? 'error' : 'primary'} component="p" align='right'>
              {`Total Equals to: ${totalPercentage}%`}
            </Typography>
            <Box>
              {totalPercentage !== 100 ? (
                <Typography variant="sLabel" color="error" align='right' sx={{
                  fontStyle: 'italic',
                }}>
                  Total need to equal 100%
                </Typography>
              ) : (
                null
              )}
            </Box>
          </Box>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BeneficiariesPercentForm;
