/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';

const PetsIcon = (props: any) => {
  return (
    <svg
      width="304"
      height="267"
      viewBox="0 0 304 267"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M176.09 29.9813C184.377 29.9813 191.094 23.2697 191.094 14.9906C191.094 6.71154 184.377 0 176.09 0C167.804 0 161.086 6.71154 161.086 14.9906C161.086 23.2697 167.804 29.9813 176.09 29.9813Z"
        fill="#FFEA8F"
      />
      <path
        d="M121.7 236.852C121.7 236.852 137.58 205.663 148.124 202.707C158.669 199.75 170.589 223.61 170.589 223.61C170.589 223.61 178.841 217.697 189.302 220.737C199.805 223.777 214.392 234.645 214.392 234.645L121.7 236.852Z"
        fill="#726F38"
      />
      <path
        d="M2.29297 252.842L4.62694 249.261C11.0037 239.559 21.84 233.688 33.4682 233.688H234.315C247.694 233.688 260.322 239.975 268.408 250.635L270.992 254.05L2.29297 252.842Z"
        fill="#84CECA"
      />
      <path
        d="M0.834233 257.798C0.834233 257.798 -3.54197 252.551 8.00287 249.428C19.5477 246.305 222.52 248.054 236.691 248.345C250.861 248.595 274.534 249.345 273.576 255.383C272.617 261.421 105.863 260.754 92.7344 260.588C79.6475 260.421 13.1709 258.089 0.834233 257.798Z"
        fill="#FFC97D"
      />
      <path
        d="M262.614 257.506C262.614 257.506 264.198 216.198 259.488 182.719C252.361 132.126 239.858 110.556 235.524 97.3974C231.189 84.2389 222.812 69.5814 227.396 69.4565C231.981 69.3732 236.941 80.9493 244.026 96.5229C251.653 113.263 265.198 139.288 273.576 175.016C278.994 198.085 282.578 257.381 282.578 257.381L267.782 257.048L262.614 257.506Z"
        fill="#9C6F43"
      />
      <path
        d="M256.821 61.2536C260.614 61.6283 266.032 62.8359 271.492 64.7514C271.909 64.8763 271.951 65.6258 272.367 65.7924C273.159 66.0839 274.326 65.7924 275.118 66.1255C280.995 68.499 286.496 71.5388 289.539 75.0783C292.748 78.7843 297.124 96.0652 297.124 96.0652L289.789 93.6084L284.913 87.3623L285.163 92.0677L273.993 88.32C273.993 88.32 271.284 82.6152 269.908 81.9906C269.325 81.6991 270.575 86.7376 269.783 86.3629C266.866 84.9887 262.656 83.0316 258.03 80.8247C257.28 80.4499 256.321 75.2865 255.529 74.9117C254.863 74.5786 254.321 79.0758 253.654 78.7426C241.276 72.788 227.563 70.0397 228.314 65.0845C229.772 55.5904 252.112 60.7955 256.821 61.2536Z"
        fill="#515032"
      />
      <path
        d="M231.938 64.7509C231.938 64.7509 256.612 77.6595 265.531 93.2747C274.45 108.89 272.825 120.383 272.825 120.383C272.825 120.383 249.36 103.935 239.44 92.6085C229.521 81.2822 224.395 62.7521 231.938 64.7509Z"
        fill="#515032"
      />
      <path
        d="M212.559 41.1823C213.059 40.7242 224.187 42.7646 228.397 47.0953C232.606 51.4259 233.19 62.6272 233.19 62.6272C233.19 62.6272 233.773 67.7074 226.188 66.7913C218.602 65.8752 211.392 56.5893 210.475 54.1742C209.475 51.759 212.059 41.682 212.559 41.1823Z"
        fill="#726F38"
      />
      <path
        d="M231.94 65.8755C231.148 63.5852 228.939 62.1694 226.522 62.3776C224.855 62.5026 222.854 63.0855 220.854 64.5013C216.061 67.9575 193.721 94.0662 191.096 103.019C188.47 112.013 189.554 130.835 189.554 130.835C189.554 130.835 196.18 127.004 203.099 121.424C203.933 120.758 203.766 114.678 204.599 113.97C205.141 113.512 206.642 118.468 207.183 117.968C209.392 115.969 211.518 113.804 213.352 111.555C213.894 110.889 214.477 109.931 215.144 108.724C215.477 108.099 214.227 101.562 214.602 100.812C214.852 100.312 216.728 105.684 216.978 105.143C218.02 102.977 219.145 100.437 220.312 97.6889C220.562 97.106 219.854 91.0264 220.104 90.4018C220.562 89.2775 221.979 93.6082 222.438 92.4839C227.397 80.1166 232.107 66.4168 231.94 65.8755Z"
        fill="#515032"
      />
      <path
        d="M228.564 63.5438C228.564 63.5438 248.486 39.3505 267.033 34.6868C285.579 30.023 294.915 32.6464 294.915 32.6464C294.915 32.6464 290.414 38.9341 282.912 45.7632C282.078 46.5544 279.286 44.7638 278.369 45.5134C277.911 45.8881 279.328 48.8446 278.869 49.261C276.327 51.3014 273.576 53.3002 270.575 55.1324C269.867 55.5488 268.45 53.2585 267.741 53.6749C267.074 54.0497 267.116 57.0895 266.449 57.4642C265.074 58.1721 263.657 58.8384 262.198 59.4213C242.984 67.2914 225.063 70.8725 228.564 63.5438Z"
        fill="#726F38"
      />
      <path
        d="M231.814 61.8781C231.814 61.8781 201.306 54.591 183.718 62.0863C166.13 69.54 160.253 77.2851 160.253 77.2851C160.253 77.2851 167.63 79.5754 177.758 80.4915C178.883 80.6164 180.05 77.4933 181.259 77.5766C181.884 77.6183 182.509 80.8246 183.134 80.8246C186.385 80.9495 189.803 80.8662 193.262 80.4915C194.054 80.4082 193.804 77.7432 194.637 77.6183C195.388 77.535 197.221 79.9918 197.972 79.8669C199.514 79.617 201.014 79.2839 202.514 78.8675C222.603 73.5791 239.066 65.6258 231.814 61.8781Z"
        fill="#726F38"
      />
      <path
        d="M236.774 101.478C236.524 101.478 236.274 101.27 236.232 101.02C236.19 100.729 236.399 100.437 236.69 100.396L245.401 99.1049C245.693 99.0633 245.985 99.2715 246.026 99.563C246.068 99.8545 245.86 100.146 245.568 100.188L236.857 101.478C236.815 101.478 236.815 101.478 236.774 101.478Z"
        fill="#2E2E47"
      />
      <path
        d="M243.693 118.884C243.443 118.884 243.192 118.676 243.151 118.426C243.109 118.135 243.318 117.843 243.609 117.801L253.237 116.094C253.529 116.053 253.82 116.261 253.862 116.552C253.904 116.844 253.695 117.135 253.404 117.177L243.776 118.884C243.734 118.884 243.693 118.884 243.693 118.884Z"
        fill="#2E2E47"
      />
      <path
        d="M250.654 139.663C250.404 139.663 250.153 139.497 250.112 139.205C250.07 138.914 250.237 138.622 250.529 138.539L261.698 136.291C261.99 136.249 262.282 136.415 262.365 136.707C262.407 136.998 262.24 137.29 261.948 137.373L250.779 139.622C250.695 139.663 250.695 139.663 250.654 139.663Z"
        fill="#2E2E47"
      />
      <path
        d="M261.781 166.312C261.573 166.312 261.364 166.188 261.281 165.979L259.864 162.94H256.196C255.904 162.94 255.654 162.69 255.654 162.398C255.654 162.107 255.904 161.857 256.196 161.857H260.197C260.406 161.857 260.614 161.982 260.697 162.19L261.656 164.189L262.198 162.273C262.239 162.065 262.406 161.94 262.573 161.899L269.658 160.066C269.95 159.983 270.242 160.15 270.325 160.441C270.408 160.733 270.242 161.024 269.95 161.107L263.198 162.856L262.364 165.896C262.323 166.104 262.114 166.271 261.864 166.312C261.823 166.312 261.823 166.312 261.781 166.312Z"
        fill="#2E2E47"
      />
      <path
        d="M264.198 188.174C264.073 188.174 263.989 188.133 263.864 188.091L261.697 186.55L260.155 187.05C259.863 187.133 259.571 186.967 259.446 186.675C259.363 186.384 259.53 186.092 259.821 185.967L261.614 185.384C261.78 185.343 261.947 185.343 262.114 185.468L264.156 186.925L264.864 186.342C264.948 186.3 265.031 186.259 265.114 186.217L269.699 185.384C269.741 185.384 269.782 185.384 269.782 185.384H275.659C275.951 185.384 276.201 185.634 276.201 185.926C276.201 186.217 275.951 186.467 275.659 186.467H269.824L265.448 187.258L264.531 188.008C264.448 188.133 264.323 188.174 264.198 188.174Z"
        fill="#2E2E47"
      />
      <path
        d="M275.034 216.906C274.742 216.906 274.576 216.906 274.492 216.864C273.992 216.823 271.783 216.864 270.45 216.864C269.741 216.864 269.116 216.864 268.741 216.864C267.532 216.864 262.656 216.282 262.447 216.282C262.156 216.24 261.947 215.99 261.947 215.657C261.989 215.365 262.239 215.157 262.572 215.157C262.614 215.157 267.615 215.74 268.741 215.74C269.074 215.74 269.699 215.74 270.408 215.74C273.534 215.699 274.492 215.657 274.867 215.782C275.368 215.782 277.41 215.615 279.244 215.449C279.535 215.407 279.827 215.657 279.827 215.948C279.869 216.24 279.619 216.531 279.327 216.531C276.91 216.823 275.659 216.906 275.034 216.906Z"
        fill="#2E2E47"
      />
      <path
        d="M164.379 267C241.49 267 304.001 266.161 304.001 265.126C304.001 264.091 241.49 263.252 164.379 263.252C87.2685 263.252 24.7578 264.091 24.7578 265.126C24.7578 266.161 87.2685 267 164.379 267Z"
        fill="#EAE6E4"
      />
      <path
        d="M154.585 111.93C151.875 108.641 121.325 53.0502 119.242 49.719C109.822 34.6867 101.778 35.228 101.778 35.228C101.778 35.228 101.695 42.3069 106.03 52.1758C109.28 59.5878 114.574 72.1633 121.325 83.0315C128.702 94.8991 135.246 102.519 144.957 111.264C152.501 118.051 158.544 116.802 154.585 111.93Z"
        fill="#FFA982"
      />
      <path
        d="M89.7341 7.20355C89.7341 7.20355 87.7335 7.07863 86.6499 9.24394C84.8994 12.7001 85.4412 19.1544 88.9005 22.0276C92.3598 24.9008 93.6102 26.0251 94.3187 25.109C95.0272 24.1929 94.402 6.07925 89.7341 7.20355Z"
        fill="#774237"
      />
      <path
        d="M149.501 107.308C149.501 107.308 155.002 108.099 155.836 108.474C156.711 108.848 163.296 116.302 162.379 117.593C161.462 118.884 160.379 118.384 160.045 118.509C159.753 118.592 158.128 119.134 157.961 119.134C157.794 119.175 157.128 119.175 156.711 119.217C156.252 119.258 155.169 119.925 154.669 119.841C154.169 119.758 152.418 116.302 151.626 115.636C150.834 114.97 146.625 112.554 146.125 111.888C145.625 111.263 148.084 107.641 149.501 107.308Z"
        fill="#FFA982"
      />
      <path
        d="M175.924 120.549C174.757 114.928 165.463 112.554 160.878 111.68C156.293 110.805 154.793 113.887 154.793 113.887C154.793 113.887 153.626 116.135 154.001 118.675C154.418 121.215 158.961 123.964 158.961 123.964C158.961 123.964 160.128 129.085 166.171 130.46C172.214 131.75 177.132 126.129 175.924 120.549ZM160.128 121.632C160.128 121.632 156.752 120.591 155.918 118.925C155.085 117.218 155.335 113.97 157.46 113.429C159.586 112.887 164.254 114.428 164.254 114.428L160.128 121.632Z"
        fill="#2E2E47"
      />
      <path
        d="M243.026 217.364C243.026 217.364 243.401 215.99 245.943 215.782C248.485 215.574 252.737 216.157 254.07 216.573C255.404 216.989 256.696 218.03 256.863 218.364C257.029 218.738 257.863 220.404 258.738 220.779C259.572 221.195 262.448 222.153 263.365 222.861C264.281 223.569 265.115 224.027 265.198 224.568C265.282 225.109 263.323 227.025 262.698 227.316C262.031 227.608 256.404 228.191 255.654 228.191C254.904 228.191 251.778 228.232 251.695 228.482C251.57 228.732 251.278 231.231 251.403 233.063C251.528 234.895 250.694 237.56 250.736 240.433C250.778 243.306 251.153 245.805 250.819 247.304C250.486 248.803 242.859 255.507 238.649 256.423C234.44 257.339 220.019 253.8 217.227 252.967C214.434 252.134 207.641 249.011 203.181 249.969C198.722 250.885 194.679 253.883 192.387 254.549C190.053 255.257 185.426 260.421 185.593 261.503C185.76 262.544 187.052 262.919 187.177 264.501C187.302 266.084 184.093 266.375 182.842 265.043C181.592 263.71 182.884 259.713 182.842 258.755C182.801 257.797 184.718 252.592 185.01 252.259C185.301 251.926 187.344 251.01 187.927 250.052C188.511 249.094 189.428 241.433 192.303 238.976C195.179 236.519 197.597 236.061 203.473 236.394C209.35 236.727 218.185 237.019 223.27 236.686C228.355 236.352 232.231 235.561 235.065 234.52C237.858 233.521 239.733 229.523 239.941 228.899C240.108 228.274 242.275 218.322 243.026 217.364Z"
        fill="#2E2E47"
      />
      <path
        d="M243.61 250.677C243.61 250.677 239.067 254.591 238.4 256.215C237.733 257.88 236.942 259.463 236.9 260.67C236.858 261.878 237.317 263.585 239.192 264.251C241.068 264.918 241.443 265.084 240.901 265.917C240.359 266.75 238.025 267 236.066 266.958C234.107 266.916 232.482 265.792 232.19 264.918C231.898 264.085 232.982 256.09 233.149 254.091C233.316 252.092 233.982 250.677 235.524 249.511C237.067 248.386 243.818 249.011 243.61 250.677Z"
        fill="#2E2E47"
      />
      <path
        d="M246.86 249.844C246.86 249.844 248.985 253.342 251.236 255.174C253.445 257.048 257.571 259.713 259.363 260.421C261.155 261.087 261.947 261.42 260.238 262.378C258.53 263.335 255.779 263.46 253.278 262.836C250.736 262.253 250.402 260.462 248.86 259.255C247.318 258.047 241.441 253.758 241.191 252.384C240.941 251.01 246.109 249.261 246.86 249.844Z"
        fill="#2E2E47"
      />
      <path
        d="M205.682 247.928C205.682 247.928 204.89 250.885 203.432 252.009C201.973 253.175 199.806 254.299 200.556 256.215C201.306 258.172 202.431 260.545 203.515 261.42C204.599 262.253 205.766 263.377 206.016 264.46C206.266 265.542 199.972 265.917 199.18 264.876C198.388 263.835 196.971 257.256 196.013 256.131C195.054 254.965 192.303 252.925 194.012 250.843C195.679 248.761 203.848 245.222 205.682 247.928Z"
        fill="#2E2E47"
      />
      <path
        d="M195.888 236.935C195.888 236.935 191.47 238.767 187.469 240.849C183.468 242.931 171.381 239.767 172.923 242.099C174.465 244.43 183.885 244.472 187.802 243.556C191.72 242.64 193.762 242.098 195.596 240.516C197.388 238.934 198.722 236.435 195.888 236.935Z"
        fill="#2E2E47"
      />
      <path
        d="M265.199 224.61C265.115 224.069 264.324 223.611 263.365 222.903C262.906 222.569 262.031 222.153 261.114 221.778C258.947 222.653 255.446 224.443 254.362 224.568C251.445 224.943 243.943 222.195 244.068 223.652C244.193 224.901 251.653 228.566 251.695 228.524L251.737 228.482C251.778 228.482 251.82 228.441 251.903 228.441H251.945C252.028 228.441 252.112 228.399 252.195 228.399C252.237 228.399 252.32 228.399 252.362 228.399C252.487 228.399 252.612 228.358 252.737 228.358C252.779 228.358 252.862 228.358 252.904 228.358C252.987 228.358 253.112 228.358 253.195 228.358C254.112 228.316 255.196 228.316 255.571 228.316C256.321 228.316 261.948 227.691 262.615 227.441C263.323 227.067 265.282 225.11 265.199 224.61Z"
        fill="#82664B"
      />
      <path
        d="M259.363 260.421C258.863 260.212 258.196 259.879 257.404 259.463C256.404 259.546 255.945 260.379 254.737 260.504C253.32 260.67 251.152 260.212 249.985 260.421C250.777 261.42 251.486 262.461 253.278 262.877C255.82 263.46 258.571 263.377 260.238 262.419C261.905 261.462 261.155 261.129 259.363 260.421Z"
        fill="#82664B"
      />
      <path
        d="M239.191 264.293C238.941 264.21 238.691 264.085 238.524 263.96C237.482 264.252 237.607 265.001 237.441 265.001C237.232 265.001 234.898 264.793 233.773 264.169C233.189 263.835 232.648 263.086 232.272 262.503C232.147 263.752 232.106 264.71 232.231 265.001C232.523 265.834 234.148 267 236.107 267.042C238.066 267.083 240.4 266.834 240.941 266.001C241.442 265.085 241.067 264.918 239.191 264.293Z"
        fill="#82664B"
      />
      <path
        d="M265.198 224.61C265.115 224.069 264.323 223.611 263.364 222.903C263.156 222.736 262.823 222.57 262.447 222.361C261.906 222.694 261.155 223.277 261.364 223.652C261.614 224.152 263.114 225.818 264.198 226.067C264.781 225.484 265.24 224.86 265.198 224.61Z"
        fill="#2E2E47"
      />
      <path
        d="M205.974 264.46C205.724 263.377 204.557 262.253 203.474 261.42C202.39 260.587 201.265 258.172 200.515 256.215C199.764 254.258 201.932 253.133 203.39 252.009C204.015 251.509 204.516 250.718 204.891 249.969C204.391 249.76 203.682 249.802 202.64 250.302C199.723 251.759 197.389 252.509 198.514 255.465C199.598 258.463 199.556 260.837 198.431 263.085C198.722 263.96 198.972 264.626 199.181 264.918C199.973 265.959 206.224 265.584 205.974 264.46Z"
        fill="#82664B"
      />
      <path
        d="M192.345 254.633C194.22 254.091 197.221 252.051 200.639 250.76C199.93 250.177 198.597 249.636 196.054 250.76C191.928 252.592 187.218 254.133 186.052 255.757C184.926 257.381 184.759 260.962 183.259 262.211C182.842 262.544 182.509 262.836 182.259 263.002C182.259 263.835 182.384 264.626 182.801 265.084C184.051 266.417 187.26 266.125 187.135 264.543C187.01 262.961 185.718 262.586 185.551 261.545C185.426 260.504 190.053 255.299 192.345 254.633Z"
        fill="#82664B"
      />
      <path
        d="M239.733 229.399C239.733 229.399 251.194 232.438 251.528 232.522C251.82 232.605 251.611 235.478 251.528 235.645C251.445 235.811 238.691 231.273 238.566 231.064C238.483 230.898 238.816 229.315 239.733 229.399Z"
        fill="#FA634B"
      />
      <path
        d="M252.361 220.695C252.319 220.695 252.236 220.653 252.194 220.612C252.153 220.529 252.153 220.404 252.236 220.362C252.278 220.32 253.361 219.612 254.945 219.737C255.028 219.737 255.112 219.821 255.112 219.946C255.112 220.029 255.028 220.112 254.903 220.112C253.486 219.987 252.444 220.653 252.444 220.653C252.444 220.653 252.403 220.695 252.361 220.695Z"
        fill="white"
      />
      <path
        d="M253.402 220.029C253.402 220.029 253.152 220.737 253.652 221.154C254.153 221.57 255.028 220.196 254.569 219.904C254.111 219.696 253.402 220.029 253.402 220.029Z"
        fill="white"
      />
      <path
        d="M245.735 217.989C245.735 217.989 243.776 216.531 241.776 218.155C239.775 219.779 237.65 221.528 237.275 223.402C236.9 225.276 240.734 230.023 242.568 231.272C244.402 232.521 245.36 231.397 246.194 229.44C246.986 227.483 244.235 222.944 245.11 221.445C245.902 219.946 246.277 219.113 245.735 217.989Z"
        fill="#2E2E47"
      />
      <path
        d="M243.943 232.063C243.192 232.063 242.484 231.522 242.025 231.106C241.359 230.481 239.9 228.899 239.816 228.857C239.733 228.774 239.733 228.649 239.816 228.607C239.9 228.524 240.025 228.524 240.066 228.607C240.066 228.607 241.567 230.273 242.234 230.856C242.692 231.272 243.317 231.772 243.984 231.73C244.318 231.689 244.651 231.522 244.943 231.147C245.901 229.94 246.276 228.899 246.11 227.858C246.026 227.275 245.693 226.192 245.401 225.109C245.11 224.11 244.859 223.194 244.734 222.652C244.484 221.445 245.693 219.779 245.735 219.696C245.776 219.613 245.901 219.613 245.985 219.654C246.068 219.696 246.068 219.821 246.026 219.904C246.026 219.904 244.901 221.487 245.11 222.569C245.193 223.11 245.485 224.068 245.776 224.984C246.11 226.067 246.443 227.191 246.527 227.816C246.693 228.982 246.318 230.106 245.276 231.397C244.943 231.813 244.526 232.063 244.068 232.105C244.026 232.063 243.984 232.063 243.943 232.063Z"
        fill="white"
      />
      <path
        d="M155.835 108.516C155.835 108.516 158.461 109.057 159.836 110.14C161.211 111.264 162.253 112.68 163.087 113.346C163.921 114.012 160.878 114.678 159.336 113.096C157.794 111.514 153.793 108.516 155.835 108.516Z"
        fill="#FFA982"
      />
      <path
        d="M241.775 221.694C241.608 221.694 241.441 221.611 241.316 221.444L172.798 120.174C172.631 119.924 172.714 119.591 172.964 119.425C173.214 119.258 173.548 119.3 173.715 119.591L242.275 220.903C242.442 221.153 242.4 221.486 242.108 221.652C241.983 221.652 241.9 221.694 241.775 221.694Z"
        fill="#2E2E47"
      />
      <path
        d="M122.701 97.4395C122.701 97.4395 129.161 130.752 127.953 144.827C126.744 158.943 115.908 175.682 105.28 190.173C98.2363 199.792 69.6451 227.275 57.7669 230.939C55.5996 231.606 54.4326 225.401 54.4326 225.401C54.4326 225.401 77.8974 183.011 83.3989 176.099C89.6923 168.229 95.5272 108.807 104.613 103.686C113.699 98.5221 122.701 97.4395 122.701 97.4395Z"
        fill="#FFA982"
      />
      <path
        d="M128.412 93.4828C127.953 89.0689 127.078 82.2814 126.203 80.5325C124.869 77.8675 114.991 53.3828 114.991 53.3828L117.075 75.7439C118.409 78.2423 119.826 80.6991 121.326 83.0726C123.744 86.9036 126.036 90.3181 128.412 93.4828Z"
        fill="#2E2E47"
      />
      <path
        d="M76.7304 129.169C79.9813 146.158 81.7734 190.922 81.7734 190.922L83.4822 245.679C83.4822 245.679 91.9846 248.136 92.1513 247.054C94.9854 231.022 111.865 122.506 113.49 118.55C115.116 114.511 73.5212 112.179 76.7304 129.169Z"
        fill="#FFA982"
      />
      <path
        d="M131.495 131.959C128.12 107.641 124.577 99.6873 120.451 78.6171C117.45 63.335 117.2 53.6327 115.741 48.4693C114.032 42.3897 108.864 35.9354 105.78 34.1865C102.029 32.0628 97.1944 30.4805 93.2766 32.4376C87.7751 35.1859 85.7329 36.8515 84.6493 41.0572C82.9404 47.7614 86.6081 54.7154 84.4409 73.9534C84.0658 77.1597 80.5231 86.1541 79.7312 90.0683C78.6476 95.5232 75.2717 107.224 75.2717 116.51C75.2717 126.712 75.1466 139.204 74.9799 142.327C74.7299 147.241 65.0605 192.296 65.0605 192.296C65.0605 192.296 69.5201 193.337 77.1055 191.38C81.1066 190.339 83.9407 160.025 84.6909 159.983C86.1497 159.858 84.1491 190.88 85.6912 190.755C95.4439 190.048 105.863 189.548 109.781 189.048C128.995 186.591 131.912 184.218 131.912 184.218C131.912 184.218 131.579 162.898 131.745 159.858C132.162 152.696 132.537 139.662 131.495 131.959Z"
        fill="#EAE6E4"
      />
      <path
        d="M92.8184 24.6515C92.8184 24.6515 93.4019 27.4831 93.1935 29.2736C92.8184 32.0219 91.6514 33.521 91.6514 33.521C91.6514 33.521 94.5688 37.7683 99.1951 35.6446C100.07 35.2282 100.487 34.6869 100.779 34.2289C101.196 33.6042 101.154 32.7714 100.654 32.1885C99.987 31.4389 98.82 29.69 98.1948 25.984C97.9448 24.6099 92.8184 24.6515 92.8184 24.6515Z"
        fill="#FFA982"
      />
      <path
        d="M98.1946 26.0253L94.8604 25.9004C94.8604 25.9004 95.944 28.7736 99.1532 29.5648C98.7781 28.6487 98.4447 27.4827 98.1946 26.0253Z"
        fill="#2E2E47"
      />
      <path
        d="M90.4427 21.0286C90.4427 21.0286 89.9425 21.4034 89.1506 20.6955C88.5255 20.1125 88.5672 17.6974 89.1506 17.156C89.7758 16.6147 90.1509 16.9062 90.3176 16.8646C90.4844 16.8229 90.0259 13.6166 91.0678 11.7011C92.1098 9.78565 94.1103 8.03674 97.2362 8.28659C100.362 8.57807 101.904 10.327 102.904 11.6595C103.905 12.992 103.946 15.7403 104.03 17.0311C104.113 18.322 104.405 18.7384 104.655 19.4463C105.197 21.0703 105.363 22.2362 104.822 24.0268C104.28 25.8589 102.321 27.6079 99.2784 27.5246C96.2776 27.3997 91.4013 25.8589 90.4427 21.0286Z"
        fill="#FFA982"
      />
      <path
        d="M100.403 20.7788C100.278 20.7788 100.153 20.7788 100.028 20.7372C99.6528 20.6955 99.4444 20.5289 99.4027 20.3207C99.361 20.2375 99.4444 20.1125 99.5277 20.1125C99.6111 20.0709 99.7361 20.1542 99.7361 20.2375C99.7361 20.2791 99.9028 20.3624 100.278 20.404C100.736 20.404 101.32 20.2791 101.528 19.9043C101.737 19.5712 101.487 19.0715 101.237 18.7384C100.403 17.5308 99.9862 16.9478 99.9862 16.5731C99.9445 16.4482 99.8195 15.4904 100.32 14.9491C100.57 14.6576 100.903 14.5327 101.362 14.5327C101.653 14.5327 103.279 14.4494 103.654 14.7825C103.737 14.8658 103.737 14.9491 103.696 15.0324C103.612 15.1157 103.529 15.1157 103.445 15.074C103.279 14.9075 102.237 14.8658 101.362 14.9075C100.986 14.9075 100.736 14.9907 100.57 15.1989C100.195 15.657 100.32 16.5314 100.32 16.5314V16.5731C100.32 16.8646 101.028 17.8639 101.528 18.5302C102.07 19.3214 101.987 19.8211 101.82 20.1125C101.57 20.6122 100.986 20.7788 100.403 20.7788Z"
        fill="#2E2E47"
      />
      <path
        d="M95.6938 15.1987C95.6105 15.1987 95.5688 15.157 95.5271 15.1154C95.4854 15.0321 95.5271 14.9072 95.6104 14.8655C95.6521 14.8239 96.7774 14.3242 98.2362 14.3242C98.3195 14.3242 98.4029 14.4075 98.4029 14.5324C98.4029 14.6157 98.3195 14.699 98.1945 14.699C96.8608 14.6573 95.7772 15.1987 95.7355 15.1987H95.6938Z"
        fill="#2E2E47"
      />
      <path
        d="M97.3608 17.0723C97.7359 17.0723 98.0276 17.2805 98.0693 17.5303C98.111 17.7802 98.1527 18.4048 97.7776 18.4881C96.3605 18.9045 96.7773 17.3221 96.8606 17.2388C96.944 17.1139 97.1107 17.0723 97.3608 17.0723Z"
        fill="#2E2E47"
      />
      <path
        d="M95.8184 17.9884H95.7767C95.6934 17.9467 95.61 17.8635 95.6517 17.7802C95.6517 17.7385 95.8184 17.1972 96.6937 16.9057C97.6106 16.6142 98.3191 17.3221 98.4025 17.4471C98.4442 17.5303 98.4441 17.6553 98.3608 17.6969C98.2774 17.7385 98.1524 17.7385 98.1107 17.6553C98.0274 17.572 97.4856 17.0723 96.8187 17.2805C96.1102 17.4887 96.0268 17.9051 95.9851 17.9051C95.9851 17.9467 95.9018 17.9884 95.8184 17.9884Z"
        fill="#2E2E47"
      />
      <path
        d="M102.821 16.7812C102.529 16.7812 102.279 16.9895 102.237 17.2393C102.196 17.4891 102.154 18.1138 102.446 18.197C103.571 18.6134 103.238 17.0727 103.196 16.9478C103.154 16.8645 103.071 16.7812 102.821 16.7812Z"
        fill="#2E2E47"
      />
      <path
        d="M101.321 17.5301C101.321 17.5301 101.279 17.5301 101.321 17.5301C101.196 17.4884 101.113 17.4052 101.154 17.3219C101.196 17.1137 101.738 16.6973 102.488 16.6973C103.238 16.6973 103.53 16.9888 103.572 17.0304C103.655 17.1137 103.655 17.197 103.572 17.2802C103.488 17.3635 103.405 17.3635 103.322 17.2802C103.322 17.2802 103.072 17.072 102.488 17.072C101.946 17.072 101.613 17.3219 101.53 17.4052C101.488 17.4884 101.404 17.5301 101.321 17.5301Z"
        fill="#2E2E47"
      />
      <path
        d="M97.3611 19.3207C97.3611 19.3207 95.2772 18.7794 94.6937 19.4457C94.1102 20.1119 96.4442 20.6949 97.6111 20.5283C98.7364 20.3617 97.9029 19.404 97.3611 19.3207Z"
        fill="#F2836D"
      />
      <path
        d="M104.071 18.9465C104.071 18.9465 102.528 18.7383 102.237 19.4878C101.987 20.2373 103.695 20.4872 104.487 20.1124C105.237 19.7793 104.446 18.9881 104.071 18.9465Z"
        fill="#F2836D"
      />
      <path
        d="M102.904 21.0703C102.904 21.0703 100.612 22.1113 98.0693 21.6116C98.0693 21.6116 99.2363 23.5688 101.487 23.4022C103.738 23.2356 102.904 21.0703 102.904 21.0703Z"
        fill="white"
      />
      <path
        d="M115.158 94.6498C118.326 93.0258 116.158 89.528 117.575 87.6125C118.951 85.697 121.076 87.6125 121.076 87.6125C121.577 87.7791 122.035 87.9456 122.452 88.0705C122.285 87.2377 122.077 86.4049 121.91 85.5721C121.827 85.5721 121.702 85.5721 121.577 85.5721C119.993 85.4888 118.242 84.2812 119.326 84.0314C119.576 83.9898 119.826 83.9898 120.034 84.073C120.368 84.1563 120.951 84.2812 121.577 84.073C121.243 82.4907 120.91 80.7834 120.535 79.0345C119.659 79.9506 118.909 80.4919 118.534 80.1172C117.992 79.5758 118.909 78.2017 120.118 76.7443C119.784 74.8704 119.451 73.0799 119.201 71.3726C118.117 73.7045 117.242 77.7437 116.075 77.0774C114.366 76.1613 118.784 70.7897 118.617 69.0408C118.451 67.2502 116.534 67.7083 114.908 67.5833C113.283 67.4168 113.116 76.4944 111.824 75.9531C110.49 75.4118 113.366 68.3745 112.282 67.2918C111.199 66.2092 107.698 65.6679 105.947 65.2098C104.155 64.7518 103.53 68.4578 103.322 69.9985C103.071 71.5392 111.615 77.2023 109.365 78.0351C107.114 78.8679 102.863 72.6219 101.529 72.372C100.196 72.1222 100.529 74.7872 100.529 76.4112C100.529 78.0351 113.158 82.6573 111.532 83.1153C109.907 83.5734 101.946 78.9512 100.571 79.9506C99.2371 80.95 99.9039 82.8238 100.571 83.7399C101.238 84.656 114.075 85.5305 112.282 86.738C110.49 87.9873 102.53 85.9052 101.196 86.738C99.8623 87.5709 100.821 89.528 101.654 90.1526C102.488 90.7772 103.53 89.8611 106.614 89.6945C109.698 89.528 111.866 90.8605 110.24 91.7766C108.615 92.6927 104.113 92.2346 103.363 92.4845C102.571 92.7343 102.446 93.817 103.363 95.0245C104.238 96.2738 111.991 96.2738 115.158 94.6498ZM111.699 80.5336C111.49 80.8667 108.531 80.367 108.073 79.2427C107.614 78.1184 110.99 79.7424 110.99 79.7424C110.99 79.7424 111.866 80.2005 111.699 80.5336ZM113.158 77.244C113.741 76.6194 114.158 77.7853 114.158 77.7853C114.158 77.7853 115.575 79.4093 114.7 79.909C113.824 80.4503 112.574 77.8686 113.158 77.244Z"
        fill="#FA634B"
      />
      <path
        d="M81.8156 119.092C81.1904 116.386 90.318 116.636 91.1098 115.303C91.9017 113.971 91.4849 113.263 89.5677 110.473C87.6505 107.683 83.1493 115.678 83.4411 112.138C83.7745 108.599 88.8592 109.182 88.7759 107.558C88.6508 105.934 87.1087 100.396 86.4419 99.8127C85.9001 99.3547 79.2316 103.227 76.5225 104.976C76.1891 106.767 75.8973 108.599 75.6889 110.431C75.9807 112.513 76.1891 114.47 75.3555 114.512C75.3138 115.178 75.3138 115.844 75.3138 116.511C75.3138 121.591 75.2721 127.254 75.2305 132.043C76.2307 132.584 76.6058 133.542 76.7309 134.374C77.0226 136.082 77.6895 137.706 78.8148 139.038C78.8565 139.08 78.8981 139.163 78.9815 139.205C81.0654 141.62 86.2752 141.995 88.234 137.581C90.1929 133.167 91.8184 129.877 91.6516 127.879C91.4849 125.838 83.066 126.838 83.3577 124.922C83.6495 123.007 91.0265 126.713 92.0684 125.672C93.1104 124.631 92.7353 120.883 91.6516 118.343C90.568 115.844 82.4825 121.799 81.8156 119.092ZM84.8998 130.627C84.8998 130.627 86.2335 131.668 85.65 132.584C85.3583 133.042 84.608 133.084 83.9412 133C83.316 132.917 82.7742 133.417 82.7325 134.083C82.6909 134.749 82.5658 135.582 82.1907 136.123C81.3572 137.289 79.565 131.668 79.1482 129.336C78.7314 127.004 84.8998 130.627 84.8998 130.627Z"
        fill="#FA634B"
      />
      <path
        d="M93.6104 42.5986C93.0269 41.4326 84.1078 42.7651 84.9831 40.7664C85.8583 38.7676 89.526 40.7664 90.4846 39.1007C90.943 38.2679 89.8594 36.6023 88.5257 35.1865C87.7755 35.7279 87.1503 36.3108 86.6502 36.9354C86.6085 37.1436 86.4418 37.3935 86.1917 37.56C85.5249 38.5178 85.0664 39.6421 84.733 41.0162C83.691 45.097 84.6496 49.2611 85.0664 56.2151C85.4832 56.0485 85.9417 55.8403 86.4835 55.5905C89.5677 54.2163 82.4407 48.345 85.9 49.3027C89.3176 50.2188 87.8588 55.174 89.4843 55.3406C91.1097 55.5072 96.8196 54.9242 97.4865 54.3829C98.1117 53.8415 94.1939 43.7645 93.6104 42.5986Z"
        fill="#FA634B"
      />
      <path
        d="M131.58 132.542C131.371 132.334 131.33 132.043 131.455 131.626C131.121 129.253 130.788 127.046 130.454 124.964C129.829 125.713 129.162 126.546 129.037 126.296C128.787 125.922 129.913 122.674 129.913 121.716C129.913 121.633 129.871 121.549 129.871 121.466C128.704 120.009 127.454 118.968 126.328 119.426C125.453 119.8 123.869 122.382 122.286 125.88C121.952 126.588 125.953 129.086 125.203 130.544C124.369 132.209 121.119 128.67 120.994 128.961C120.327 130.71 119.702 132.542 119.16 134.375C119.035 134.874 123.244 136.248 122.869 137.831C122.327 139.996 118.618 136.582 118.493 136.956C117.409 141.787 117.368 146.034 119.66 147.408C125.578 150.948 129.663 142.994 129.663 142.994C129.663 142.994 130.663 143.619 132.163 144.327C132.163 140.079 131.997 135.874 131.58 132.542Z"
        fill="#FA634B"
      />
      <path
        d="M84.7743 244.139C86.2747 244.805 88.442 257.297 89.3589 258.338C90.3175 259.379 90.4425 260.046 93.4433 258.338C94.527 257.714 95.819 257.672 97.0277 257.88C96.0691 257.214 95.1521 256.548 94.5686 255.548C93.1099 253.133 92.7348 243.556 92.7348 243.556C91.7762 241.474 84.1074 241.349 83.7323 242.14C83.6073 242.432 83.2739 243.056 82.8154 243.889C83.3156 243.806 83.9824 243.764 84.7743 244.139Z"
        fill="#FFA982"
      />
      <path
        d="M97.0692 264.168C96.0689 264.668 88.7752 264.376 85.7327 262.877C82.6485 261.336 83.3154 250.926 81.3149 249.719C80.8147 249.427 80.3979 249.344 80.1062 249.427C79.9812 249.677 79.8978 249.885 79.8144 250.135C78.4807 253.3 78.8558 264.626 79.6894 265.292C80.4813 265.917 97.3609 266.833 99.2364 266.875C101.862 266.916 102.071 265.084 101.362 262.919C99.5699 262.086 98.0278 263.71 97.0692 264.168Z"
        fill="#FFD3B5"
      />
      <path
        d="M81.3151 249.719C83.3157 250.926 82.6488 261.337 85.733 262.877C88.8172 264.418 96.0692 264.668 97.0694 264.168C98.028 263.71 99.5701 262.086 101.279 262.877C101.112 262.378 100.904 261.836 100.654 261.295C99.9035 259.629 98.4448 258.796 97.0694 257.839C95.8608 257.63 94.5687 257.672 93.4851 258.297C90.4426 260.004 90.3176 259.338 89.4007 258.297C88.4421 257.256 86.2748 244.763 84.8161 244.097C84.0242 243.722 83.3573 243.764 82.8989 243.847C82.107 245.346 80.9817 247.47 80.1064 249.386C80.3982 249.344 80.815 249.427 81.3151 249.719Z"
        fill="#9C6F43"
      />
      <path
        d="M56.3494 223.818C57.1413 225.234 51.5147 236.602 51.6397 237.976C51.8065 239.392 51.5147 240.017 54.9323 240.391C56.1827 240.516 57.2246 241.224 58.0998 242.14C57.7247 241.058 57.3913 239.975 57.5164 238.809C57.8081 235.977 63.1429 228.066 63.1429 228.066C63.6014 225.817 57.5164 221.195 56.7245 221.611C56.4744 221.778 55.8076 222.069 54.9323 222.486C55.3908 222.652 55.9326 223.027 56.3494 223.818Z"
        fill="#FFA982"
      />
      <path
        d="M54.3909 247.221C53.2656 247.013 47.5974 242.516 46.0136 239.476C44.4299 236.436 51.14 228.399 50.2648 226.234C50.0564 225.693 49.7647 225.401 49.4312 225.276C49.2228 225.401 48.9728 225.526 48.7644 225.651C45.8052 227.4 39.3868 236.769 39.6786 237.769C39.9703 238.768 52.9739 249.47 54.4743 250.594C56.5582 252.176 57.8085 250.844 58.5171 248.678C57.6418 247.013 55.4329 247.429 54.3909 247.221Z"
        fill="#FFD3B5"
      />
      <path
        d="M50.2649 226.234C51.1401 228.399 44.4716 236.394 46.0137 239.475C47.5975 242.515 53.2657 247.054 54.391 247.221C55.4329 247.429 57.6419 247.012 58.5588 248.678C58.7255 248.178 58.8505 247.595 58.9756 247.012C59.3507 245.222 58.6838 243.681 58.1003 242.099C57.2251 241.224 56.1831 240.516 54.9328 240.35C51.4735 239.933 51.7653 239.309 51.6402 237.935C51.4735 236.519 57.1417 225.193 56.3499 223.777C55.9331 223.027 55.3913 222.653 54.9328 222.444C53.4324 223.152 51.2651 224.235 49.3896 225.234C49.723 225.401 50.0148 225.692 50.2649 226.234Z"
        fill="#9C6F43"
      />
      <path
        d="M76.3135 106.308C82.4818 105.725 94.4851 104.018 96.9858 99.7705C100.362 94.0241 99.7366 88.7773 99.7366 88.7773C99.7366 88.7773 95.402 93.108 89.6088 95.8563C86.4829 97.3553 81.815 97.397 78.189 97.1471C77.5221 100.02 76.8553 103.143 76.3135 106.308Z"
        fill="#2E2E47"
      />
      <path
        d="M92.7763 15.4902L89.6922 16.6978C89.5671 16.7395 89.5254 16.8644 89.6088 16.9893C89.6505 17.0309 89.6922 17.0726 89.7755 17.0726L93.4432 16.8227L92.7763 15.4902Z"
        fill="#2E2E47"
      />
      <path
        d="M98.5691 11.2008C98.5691 11.2008 96.1101 13.616 94.6931 14.7403C93.276 15.8646 90.4002 16.6974 89.4416 16.7807C88.483 16.8223 88.1496 16.6974 87.8995 15.7813C87.6495 14.8652 88.0246 11.3257 88.1496 10.6595C88.2747 9.9516 88.6081 6.5787 90.9421 6.07902C93.276 5.57933 95.0265 6.24558 95.9017 6.82855C96.8187 7.36988 98.5691 11.2008 98.5691 11.2008Z"
        fill="#774237"
      />
      <path
        d="M98.1111 10.951C98.1111 10.951 103.529 13.4911 103.904 13.8242C104.279 14.199 103.904 11.7005 103.404 10.2847C102.862 8.86895 102.321 6.57871 99.8199 5.7459C97.3192 4.91308 92.7346 5.07964 93.6932 6.74527C94.6518 8.45254 98.1111 10.951 98.1111 10.951Z"
        fill="#774237"
      />
      <path
        d="M106.114 15.1988C105.739 15.0323 104.905 15.1988 103.613 15.407C102.321 15.6153 101.654 16.3648 101.321 16.4897C100.988 16.6563 100.738 16.6563 100.154 16.4897C99.5289 16.2815 97.8618 15.7402 96.9865 15.5736C96.1113 15.3654 93.7356 15.2821 93.0688 15.407C92.6937 15.4903 92.652 15.5736 92.6937 15.6569C92.7354 15.7818 92.777 15.9067 92.777 16.0317C92.777 16.9894 93.6523 18.655 94.7359 19.4462C95.9029 20.279 97.6951 20.1124 98.4869 19.7793C99.2788 19.4046 100.112 18.3219 100.279 18.0304C100.446 17.7389 100.613 17.614 100.988 17.8222C101.363 18.0721 101.238 18.9465 102.53 19.4878C103.822 20.0292 104.28 19.8626 105.28 19.1547C106.281 18.4468 106.281 16.6979 106.364 16.2815C106.447 15.8651 106.489 15.3654 106.114 15.1988Z"
        fill="#2E2E47"
      />
      <path
        d="M94.9024 18.655H94.8607C93.777 17.7389 93.7354 16.2398 93.7354 16.1565C93.7354 16.1149 93.777 16.0732 93.8187 16.0732C93.8604 16.0732 93.9021 16.1149 93.9021 16.1565C93.9021 16.1565 93.9437 17.6556 94.9857 18.53C95.0274 18.5717 95.0274 18.6133 94.9857 18.655C94.944 18.655 94.944 18.655 94.9024 18.655Z"
        fill="white"
      />
      <path
        d="M88.901 7.95325C88.901 7.95325 85.5251 5.62137 87.609 2.74816C89.6929 -0.166686 92.1935 1.04089 93.2355 2.08191C94.2775 3.12293 93.4439 7.57848 91.6934 8.07817C89.8596 8.6195 88.901 7.95325 88.901 7.95325Z"
        fill="#774237"
      />
      <path
        d="M101.78 17.4057C101.738 17.4057 101.738 17.4057 101.78 17.4057C101.696 17.4057 101.696 17.3224 101.696 17.2807C101.696 17.2391 101.988 16.3646 103.03 15.9482C103.072 15.9482 103.113 15.9482 103.155 15.9899C103.155 16.0315 103.155 16.0732 103.113 16.1148C102.155 16.5312 101.863 17.3224 101.863 17.364C101.821 17.4057 101.821 17.4057 101.78 17.4057Z"
        fill="white"
      />
      <path
        d="M90.109 26.025C89.9423 26.025 89.7756 25.9417 89.6922 25.7752C89.5672 25.5669 89.6505 25.2755 89.9006 25.1505C91.5677 24.2761 91.1926 22.0275 90.7758 20.4035C90.4841 19.2376 90.1923 15.9063 90.1507 15.7814C90.109 15.5315 90.3174 15.3233 90.5675 15.2817C90.8175 15.2401 91.0259 15.4483 91.0676 15.6981C91.0676 15.7397 91.3593 19.071 91.6511 20.1953C91.9428 21.278 92.8181 24.6509 90.3174 25.9834C90.2757 25.9834 90.1923 26.025 90.109 26.025Z"
        fill="#774237"
      />
      <path
        d="M57.976 64.5426C57.976 64.5426 72.8134 59.9621 86.2754 60.5034C99.7374 61.0448 110.782 64.9174 111.657 66.3748C112.533 67.8322 100.113 91.7756 97.1534 97.2722C94.1525 102.769 70.0209 100.937 68.2704 98.1883C66.52 95.44 52.5995 67.166 53.8498 66.1666C55.0585 65.1672 57.976 64.5426 57.976 64.5426Z"
        fill="#FFC97D"
      />
      <path
        d="M65.2276 92.4421C77.8144 89.4024 91.6516 91.651 99.1536 93.3582C100.904 90.027 103.196 85.5298 105.405 81.1159C99.8205 78.9922 88.234 75.3695 77.356 76.4938C67.4783 77.5348 61.7267 78.7007 58.8926 79.4086C61.0182 83.9474 63.3938 88.7778 65.2276 92.4421Z"
        fill="#2E2E47"
      />
      <path
        d="M66.7701 127.129C68.0621 123.048 75.7309 60.0877 76.3977 56.2568C79.5236 38.8093 87.1924 36.3525 87.1924 36.3525C87.1924 36.3525 89.9014 42.8901 89.568 53.6751C89.318 61.7533 88.0259 73.4127 85.7753 85.9882C83.3163 99.7296 79.0651 113.388 74.4806 123.59C70.3544 132.834 64.8529 133.125 66.7701 127.129Z"
        fill="#FFA982"
      />
      <path
        d="M67.8951 122.59C67.8951 122.59 67.2283 125.88 66.5198 126.296C65.8112 126.713 63.1022 128.253 62.1436 129.419C61.185 130.585 59.1844 135.749 59.4762 135.707C59.7679 135.665 60.0597 135.249 60.0597 135.249C60.0597 135.249 59.1427 137.165 59.3511 137.581L59.8513 137.747C59.8513 137.747 59.8513 138.372 60.3514 138.539C60.8515 138.705 63.3105 134.916 63.4356 134.958C63.5606 134.999 61.185 138.206 61.8101 138.58C62.477 138.955 66.5198 132.584 66.9782 133.125C67.4367 133.667 65.7279 136.873 66.6448 137.914C67.5617 138.955 67.7701 136.79 68.1035 135.957C68.3953 135.124 70.9376 130.835 71.3961 129.461C71.8546 128.087 73.0215 124.881 72.438 123.715C71.9796 122.632 68.3953 121.882 67.8951 122.59Z"
        fill="#FFA982"
      />
      <path
        d="M95.8184 65.0839C95.7767 65.0839 95.7767 65.0839 95.735 65.0839C95.0682 65.0422 94.568 64.5009 94.6097 63.8346C94.9015 58.2964 94.6514 42.9727 90.1918 38.6004C89.3166 37.7676 88.3997 37.3928 87.2744 37.4761C79.7723 38.1007 75.5628 56.4642 74.5626 63.5015C74.4792 64.1678 73.854 64.6258 73.2289 64.5009C72.562 64.4176 72.1036 63.793 72.2286 63.1684C72.2703 62.8769 73.1872 56.4226 75.3544 49.7601C78.3969 40.4326 82.3564 35.519 87.066 35.1026C88.8581 34.936 90.4836 35.5606 91.859 36.8931C98.0273 42.8894 97.0687 63.0851 96.9854 63.9596C96.9854 64.5842 96.4436 65.0839 95.8184 65.0839Z"
        fill="#2E2E47"
      />
      <path
        d="M119.2 187.632C120.867 187.341 122.326 187.049 123.618 186.8C124.076 185.134 124.16 182.844 123.826 180.97C123.285 178.055 113.323 182.344 113.323 179.346C113.323 176.389 122.784 178.805 123.951 177.597C125.077 176.389 124.827 175.515 123.535 172.142C122.243 168.769 115.532 176.056 116.741 172.434C117.908 168.811 123.118 170.643 123.41 168.936C123.701 167.187 123.368 161.024 122.826 160.274C122.284 159.525 111.24 163.148 109.948 163.731C108.655 164.314 109.572 173.933 107.489 172.892C105.405 171.851 107.739 168.02 106.03 166.895C104.321 165.813 98.6111 171.101 99.0695 172.35C99.528 173.6 98.8611 179.554 98.0276 178.013C97.1523 176.473 96.1938 173.891 96.1938 173.891C96.1938 173.891 94.735 173.683 93.1929 175.515C91.6508 177.389 90.1921 183.718 92.6928 187.258C94.3599 189.631 96.1521 189.714 98.361 189.923C102.237 189.673 105.655 189.465 108.03 189.256C108.155 188.549 108.822 188.632 109.697 189.09C109.739 189.09 109.822 189.09 109.906 189.048C112.49 188.715 114.741 188.382 116.783 188.091C114.907 187.424 113.24 186.716 113.615 185.884C114.032 184.634 116.824 186.383 119.2 187.632Z"
        fill="#FA634B"
      />
      <path
        d="M82.4818 213.7C82.3984 213.7 82.3151 213.617 82.3151 213.533L81.2314 182.761C81.2314 182.678 81.3148 182.594 81.3982 182.553C81.4815 182.553 81.5649 182.636 81.6065 182.719L82.6902 213.492C82.6902 213.617 82.6068 213.7 82.4818 213.7Z"
        fill="#2E2E47"
      />
      <path
        d="M84.7751 160.15C84.6501 160.15 84.6084 160.067 84.6084 159.942C84.6084 159.775 85.2336 141.204 84.6084 127.129C84.6084 127.046 84.6918 126.963 84.7751 126.921C84.8585 126.921 84.9418 127.004 84.9835 127.087C85.6086 141.162 84.9835 159.734 84.9835 159.942C84.9418 160.109 84.8585 160.15 84.7751 160.15Z"
        fill="#2E2E47"
      />
      <path
        d="M100.445 196.086C100.32 196.086 100.237 195.961 100.278 195.878L101.279 189.673C101.279 189.59 101.404 189.507 101.487 189.507C101.57 189.507 101.654 189.632 101.654 189.715L100.654 195.92C100.612 196.044 100.528 196.086 100.445 196.086Z"
        fill="#2E2E47"
      />
      <path
        d="M43.9706 181.802C43.9706 181.802 43.9289 181.802 43.9706 181.802C43.8872 181.802 43.8038 181.761 43.8038 181.677C43.7622 181.594 41.2615 173.516 37.1354 172.183C33.0092 170.892 30.6336 172.391 30.5919 172.391C30.5085 172.433 30.3835 172.433 30.3418 172.35C30.3002 172.267 30.3002 172.142 30.3835 172.1C30.4669 172.017 32.9259 170.476 37.2187 171.85C39.0525 172.433 40.7613 174.224 42.3451 177.263C43.2204 178.929 43.8038 180.553 44.0539 181.219C44.5541 180.595 46.0128 179.345 49.3054 179.554C49.3887 179.554 49.4721 179.637 49.4721 179.762C49.4721 179.845 49.3887 179.928 49.2637 179.928C45.3043 179.679 44.1373 181.719 44.1373 181.719C44.0956 181.761 44.0122 181.802 43.9706 181.802Z"
        fill="#2E2E47"
      />
      <path
        d="M54.0151 186.966H35.9685C35.8851 186.966 35.8018 186.883 35.8018 186.8C35.8018 186.717 35.8851 186.633 35.9685 186.633H53.8067C53.8484 185.759 53.8067 181.636 51.1393 180.346C51.0559 180.304 51.0143 180.179 51.0559 180.096C51.0976 180.012 51.2226 179.971 51.306 180.012C52.6397 180.679 53.5566 182.053 53.9734 184.01C54.2651 185.467 54.1818 186.758 54.1818 186.8C54.1818 186.883 54.0984 186.966 54.0151 186.966Z"
        fill="#2E2E47"
      />
      <path
        d="M12.1287 186.966C12.0037 186.966 11.9203 186.883 11.962 186.758C11.962 186.716 12.3371 182.26 16.1298 180.303C19.2974 178.679 22.8817 179.804 23.8819 180.178C23.8403 179.679 23.8403 178.596 24.4238 177.18C25.3407 174.848 28.8833 172.85 29.0084 172.766C29.0917 172.725 29.2167 172.766 29.2584 172.85C29.3001 172.933 29.2584 173.058 29.1751 173.099C29.1334 173.099 25.6324 175.14 24.7572 177.347C24.0487 179.179 24.2987 180.428 24.2987 180.47C24.2987 180.553 24.2987 180.595 24.2154 180.636C24.1737 180.678 24.0903 180.678 24.007 180.678C23.9653 180.678 19.8392 178.804 16.2549 180.678C12.6705 182.51 12.2954 186.799 12.2954 186.841C12.2954 186.924 12.2121 186.966 12.1287 186.966Z"
        fill="#2E2E47"
      />
      <path
        d="M67.9365 176.722C67.8115 176.722 67.7281 176.597 67.7698 176.514C67.7698 176.472 68.1866 173.807 65.9776 172.475C63.8104 171.142 62.3516 172.475 62.2683 172.517C62.2266 172.558 62.1432 172.558 62.1016 172.558C62.0182 172.558 61.9765 172.475 61.9765 172.433C61.9765 172.433 61.5597 170.435 60.101 169.643C59.2675 169.227 58.2672 169.227 57.1419 169.685C55.058 170.56 54.2244 173.308 54.2244 173.308C54.2244 173.349 54.1827 173.433 54.0994 173.433C54.0577 173.433 53.9743 173.433 53.9327 173.391C53.9327 173.391 52.2239 172.1 50.4734 172.725C48.7229 173.349 48.2644 175.223 48.2644 175.223C48.2228 175.307 48.1394 175.39 48.056 175.348C47.9727 175.307 47.8893 175.223 47.931 175.14C47.931 175.057 48.4311 173.1 50.3483 172.392C51.9321 171.809 53.3908 172.6 53.9327 172.933C54.1827 172.225 55.0996 170.06 56.9752 169.31C58.2255 168.811 59.3508 168.769 60.2677 169.269C61.5598 169.935 62.1016 171.392 62.2683 172.017C62.8101 171.642 64.2688 170.934 66.186 172.1C68.6034 173.599 68.1449 176.514 68.1449 176.514C68.1032 176.639 68.0199 176.722 67.9365 176.722Z"
        fill="#2E2E47"
      />
      <path
        d="M248.068 19.071C247.943 19.071 247.86 18.9461 247.902 18.8628C247.902 18.8212 248.61 14.2407 244.818 11.9088C241.066 9.61857 238.482 11.9921 238.482 12.0337C238.441 12.0754 238.357 12.0754 238.316 12.0754C238.232 12.0754 238.191 11.9921 238.191 11.9504C238.191 11.9088 237.441 8.53591 234.94 7.20341C233.523 6.45388 231.814 6.49552 229.855 7.28669C227.98 8.03622 226.729 9.70185 226.021 10.9094C225.229 12.2419 224.895 13.4495 224.895 13.4495C224.895 13.4912 224.854 13.5744 224.77 13.5744C224.729 13.5744 224.645 13.5744 224.604 13.5328C224.562 13.4912 221.645 11.3258 218.685 12.4085C215.726 13.4912 214.934 16.6558 214.934 16.6975C214.893 16.7808 214.809 16.864 214.726 16.8224C214.643 16.7808 214.559 16.6975 214.601 16.6142C214.643 16.4893 215.434 13.2413 218.602 12.0754C221.311 11.076 223.895 12.5751 224.645 13.0747C224.979 12.0754 226.437 8.24443 229.772 6.87029C231.856 6.03747 233.689 5.99583 235.19 6.787C237.44 7.99458 238.316 10.6179 238.566 11.4924C239.316 10.9511 241.817 9.49365 245.109 11.534C249.069 13.9908 248.36 18.7795 248.36 18.8212C248.235 18.9877 248.193 19.071 248.068 19.071Z"
        fill="#2E2E47"
      />
      <path
        d="M199.554 188.174C199.513 188.174 199.513 188.174 199.554 188.174C199.429 188.174 199.346 188.049 199.388 187.966C199.388 187.925 200.013 183.76 197.929 180.929C196.887 179.555 195.345 178.68 193.344 178.43C193.261 178.43 193.178 178.306 193.178 178.222C193.178 178.139 193.303 178.056 193.386 178.056C195.47 178.347 197.095 179.222 198.221 180.721C200.43 183.677 199.805 187.841 199.763 188.008C199.721 188.091 199.638 188.174 199.554 188.174Z"
        fill="#2E2E47"
      />
      <path
        d="M160.336 187.508C160.252 187.508 160.211 187.466 160.169 187.424C160.127 187.383 157.752 182.427 161.211 179.221C164.295 176.431 168.505 178.513 169.505 179.055C169.588 178.721 169.797 178.18 170.13 177.556C170.839 176.39 171.797 175.807 172.923 175.807C175.257 175.807 176.632 179.846 177.007 181.178C177.465 180.47 178.674 179.138 180.841 179.762C182.925 180.387 183.926 181.803 184.301 182.552C184.676 181.595 185.759 179.346 188.594 178.388C188.677 178.347 188.802 178.388 188.844 178.513C188.885 178.597 188.844 178.721 188.719 178.763C185.301 179.929 184.551 183.135 184.509 183.135C184.509 183.219 184.426 183.26 184.342 183.26C184.259 183.26 184.176 183.219 184.176 183.135C184.176 183.094 183.342 180.887 180.758 180.137C178.216 179.388 177.174 181.636 177.132 181.761C177.09 181.845 177.049 181.886 176.965 181.886C176.882 181.886 176.84 181.845 176.799 181.761C176.799 181.72 175.382 176.223 172.923 176.223C171.922 176.223 171.089 176.764 170.422 177.805C169.922 178.597 169.755 179.429 169.755 179.429C169.755 179.471 169.713 179.554 169.63 179.554C169.588 179.596 169.505 179.554 169.463 179.554C169.422 179.513 164.67 176.556 161.378 179.554C158.085 182.552 160.377 187.258 160.377 187.299C160.419 187.383 160.377 187.508 160.294 187.549C160.419 187.508 160.377 187.508 160.336 187.508Z"
        fill="#2E2E47"
      />
      <path
        d="M35.0522 44.0972H15.8803C15.7969 44.0972 15.7135 44.0139 15.7135 43.9307C15.6719 42.5982 16.1303 41.5571 17.0056 40.8492C18.0058 40.0581 19.5063 39.7249 21.4651 39.8499C22.5071 39.8915 23.424 40.0997 23.8408 40.183C23.8408 40.0164 23.8825 39.8082 23.9658 39.5584C24.2992 38.8505 25.1328 38.1426 26.4248 37.3931C27.5918 36.7268 28.5921 36.5603 29.4256 36.8934C30.0925 37.1432 30.5093 37.6846 30.7177 38.101C31.2595 32.5211 32.8432 28.7318 35.3856 26.858C37.0527 25.6088 39.095 25.234 41.5123 25.7337C44.0963 26.2334 46.0969 27.3993 47.4306 29.1066C47.4723 29.1899 47.4723 29.3148 47.3889 29.3564C47.3055 29.3981 47.1805 29.3981 47.1388 29.3148C45.8885 27.6492 43.9713 26.5665 41.4706 26.0668C39.1783 25.6088 37.2194 25.9835 35.6357 27.1495C33.1767 28.9817 31.6346 32.7293 31.0928 38.2675C31.0511 38.8921 31.0094 38.9338 30.8427 38.9754C30.7593 38.9754 30.676 38.9338 30.6343 38.8505C30.5926 38.7256 30.2592 37.6846 29.2589 37.2682C28.5087 36.9767 27.6335 37.1432 26.5499 37.7262C23.7157 39.3085 24.1742 40.3496 24.1742 40.3496C24.2159 40.4328 24.2159 40.4745 24.1325 40.5578C24.0908 40.5994 24.0075 40.641 23.9658 40.641C23.9241 40.641 19.3395 39.4335 17.1306 41.1824C16.3804 41.7653 16.0053 42.6398 15.9636 43.8057H34.9271C35.0105 43.8057 35.0939 43.889 35.0939 43.9723C35.2189 44.0139 35.1355 44.0972 35.0522 44.0972Z"
        fill="#2E2E47"
      />
      <path
        d="M55.5164 44.097H49.5147C49.4314 44.097 49.348 44.0138 49.348 43.9305C49.348 43.8472 49.4314 43.7639 49.5147 43.7639H55.433C55.5997 43.3891 56.1832 41.9734 55.7247 40.7241C55.433 39.933 54.8078 39.35 53.8492 38.9752C50.4316 37.6011 48.8062 39.7248 48.7228 39.808C48.6811 39.8913 48.5561 39.8913 48.4728 39.8497C48.3894 39.808 48.3477 39.7248 48.3894 39.6415C48.3894 39.6415 48.7228 38.5588 48.8479 37.0181C48.8479 36.9348 48.9312 36.8516 49.0562 36.8516C49.1396 36.8516 49.223 36.9348 49.223 37.0598C49.1396 37.9342 49.0146 38.6421 48.8895 39.1002C49.6814 38.4755 51.3485 37.5595 53.9743 38.6005C55.0579 39.0169 55.7247 39.6831 56.0582 40.5576C56.6417 42.1816 55.7247 43.9305 55.6831 43.9721C55.6414 44.0554 55.5997 44.097 55.5164 44.097Z"
        fill="#2E2E47"
      />
    </svg>
  );
};

export default PetsIcon;
