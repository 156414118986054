import React, { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';

const useAssets = () => {
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const [forms, setForms] = useState<Object>({
    realEstate: false,
    financialAccounts: false,
    businessInterest: false,
    otherItems: false,
    completionScreen: true,
  });

  useEffect(() => {
    setForms((values) => ({
      ...values,
      realEstate: !!planDocuments?.assets?.real_estate_properties?.length,
      financialAccounts:
        !!planDocuments?.assets?.financial_account_designations?.length,
      businessInterest: !!planDocuments?.assets?.business_interests?.length,
      otherItems: !!planDocuments?.assets?.personal_properties?.length,
    }));
  }, [planDocuments]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForms((values) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }));
  };

  const getSelectedForms = (obj: any) => {
    const filtered = Object.keys(obj).reduce((o: any, key) => {
      obj[key] === true && (o[key] = obj[key]);
      return o;
    }, {});

    return Object.keys(filtered).map((key) => key);
  };

  const [selectedForms, setSelectedForm] = useState<string[]>(
    getSelectedForms(forms),
  );

  useEffect(() => {
    setSelectedForm(getSelectedForms(forms));
  }, [forms, setForms]);

  return {
    loading: loadingPlanDocuments,
    realEstateProperties: planDocuments?.assets?.real_estate_properties,
    forms,
    setForms,
    handleChange,
    selectedForms,
  };
};

export default useAssets;
