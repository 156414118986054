import { useContext, useEffect, useState } from 'react';
import { useAuthProvider } from '../../../core/authContext';
import {
  AddressType,
  // OnBoardingStatus,
  PaymentStatus,
  useAddressQuery,
  useCreatePaymentMutation,
  usePaymentDetailsQuery,
  UserAddressFragmentFragment,
  useUpdatePaymentStatusMutation,
} from '../../../generated/graphql';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import useUserPlan from './user-plan-hooks';
import { apiUrls } from '../../../variables/urls';
import { useHistory } from 'react-router-dom';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';
import { StripePaymentRequestButtonElementOptions } from '@stripe/stripe-js';

interface CardValue {
  brand: string;
  empty: boolean;
  error?:
  | undefined
  | {
    type: 'validation_error';
    code: string;
    message: string;
  };
  complete: boolean;
}

export interface CardProps {
  name?: string;
  card_number?: CardValue;
  expiration_date?: CardValue;
  security_code?: CardValue;
}

interface PaymentMethod {
  id: string;
  create_payment_code: string;
  payment_method_id: string;
  is_wallet_payment: boolean;
}

const useCheckout = () => {
  const history = useHistory();
  const { getUser } = useAuthProvider();
  const user = getUser();
  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } =
    useContext(DocumentContext);
  useEffect(() => {
    if (!loadingPlanDocuments) {
      // if (
      //   planDocuments?.user_info?.on_boarding_status !==
      //   OnBoardingStatus.Payment
      // ) {
      history.push(apiUrls.DASHBOARD);
      // }
    }
  }, [planDocuments, loadingPlanDocuments]);

  const stripe = useStripe();
  const elements = useElements();
  const [showCheckout, setShowCheckout] = useState(false);
  const [showPaymentSummary, setShowPaymentSummary] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(
    {} as UserAddressFragmentFragment,
  );
  const [addShippingAddress, setAddShippingAddress] = useState(false);
  const [orderReview, setOrderReview] = useState(false);
  const [cardInfo, setCardInfo] = useState({} as CardProps);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    {} as PaymentMethod,
  );
  const [applePayRequest, setApplePayRequest] =
    useState<StripePaymentRequestButtonElementOptions>(
      {} as StripePaymentRequestButtonElementOptions,
    );
  const [showApplePay, setShowApplePay] = useState(false);
  const [payingWithApplePay, setPayingWithApplePay] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const [updatePaymentStatus, { loading: loadingUpdatePaymentStatus }] =
    useUpdatePaymentStatusMutation();

  const onProceedToPay = async () => {
    if (payingWithApplePay) {
      applePayRequest?.paymentRequest?.show();
    } else {
      if (
        !(
          cardInfo.card_number?.complete &&
          cardInfo.expiration_date?.complete &&
          cardInfo.security_code?.complete
        )
      ) {
        openSnackbar({ message: 'Invalid card details' }, NotifierType.Error);
        return;
      }
      if (!stripe || !elements) {
        openSnackbar(
          { message: 'Something went wrong, try again' },
          NotifierType.Error,
        );
        return;
      }
      setPaymentInProgress(true);
      try {
        const cardPaymentResult = await stripe.confirmCardPayment(
          paymentMethod.create_payment_code,
          {
            payment_method: paymentMethod.payment_method_id,
          },
        );
        if (cardPaymentResult?.paymentIntent?.status !== 'succeeded') {
          openSnackbar(
            {
              message:
                cardPaymentResult?.error?.message ||
                'Something went wrong, try again!',
            },
            NotifierType.Error,
          );
          await updatePaymentStatus({
            variables: {
              input: {
                id: paymentMethod.id,
                status: PaymentStatus.Failed,
              },
            },
          });
          setPaymentInProgress(false);
          return;
        } else if (cardPaymentResult?.paymentIntent?.status === 'succeeded') {
          openSnackbar(
            {
              message: 'Paid successfully',
            },
            NotifierType.Success,
          );
          await updatePaymentStatus({
            variables: {
              input: {
                id: paymentMethod.id,
                status: PaymentStatus.Success,
              },
            },
          });
          setPaymentInProgress(false);
          refetchPlanDocuments?.();
          history.push(apiUrls.CHECKOUT_SUCCESS);
          return;
        }
      } catch (err: any) {
        openSnackbar(
          {
            message: err.message,
          },
          NotifierType.Error,
        );
        setPaymentInProgress(false);
      }
    }
  };

  const onProceedToPayment = () => {
    setShowCheckout(false);
    setShowPaymentSummary(true);
  };

  const userID = user?.id;

  const { userPlan, loadingPlan } = useUserPlan();

  const {
    data: userAddresses,
    loading: loadingAddress,
    refetch,
  } = useAddressQuery({
    fetchPolicy: 'network-only',
    skip: !userID,
  });

  useEffect(() => {
    if (userPlan?.userPlan) {
      setTotalPrice(
        parseInt(
          userPlan?.userPlan?.offer_price ||
            userPlan?.userPlan?.original_price ||
            '0',
        ),
      );
    }
  }, [userPlan, setTotalPrice]);

  useEffect(() => {
    if (userAddresses?.userAddresses?.length == 1) {
      setShippingAddress(
        userAddresses.userAddresses[0] || ({} as UserAddressFragmentFragment),
      );
    } else {
      for (const [, address] of userAddresses?.userAddresses?.entries() || []) {
        if (address?.type === AddressType.Shipping) {
          setShippingAddress(address);
          break;
        }
      }
    }
  }, [userAddresses, setShippingAddress]);

  const handleShippingAddressChange =
    (shippingAddressId: string | undefined) => () => {
      if (shippingAddressId) {
        for (const [, address] of userAddresses?.userAddresses?.entries() ||
          []) {
          if (address?.id === shippingAddressId) {
            setShippingAddress(address || ({} as UserAddressFragmentFragment));
            break;
          }
        }
      }
    };

  const { data: paymentDetails, loading: loadingPaymentDetails } =
    usePaymentDetailsQuery({
      fetchPolicy: 'network-only',
      skip: !paymentMethod.payment_method_id,
    });

  const currentDate = new Date();
  const deliveryDate = currentDate.setDate(currentDate.getDate() + 8);

  const [paymentCreate, { loading: loadingCreatePayment }] =
    useCreatePaymentMutation();

  useEffect(() => {
    if (!stripe || !elements || !totalPrice) {
      return;
    }
    const apr = stripe?.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Willy plan payment',
        amount: totalPrice * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    apr?.canMakePayment().then((result) => {
      if (result) {
        setApplePayRequest({
          paymentRequest: apr,
        } as StripePaymentRequestButtonElementOptions);
        setShowApplePay(true);
      }
    });
    apr.on('paymentmethod', async (e) => {
      try {
        const createPayment = await paymentCreate({
          variables: {
            input: {
              plan_id: user.user_info.plan_id,
              payment_method_id: e.paymentMethod?.id || '',
            },
          },
        });
        setPaymentMethod({
          payment_method_id: e.paymentMethod?.id,
          create_payment_code:
            createPayment?.data?.paymentCreate?.create_payment_code || '',
          id: createPayment?.data?.paymentCreate?.id || '',
          is_wallet_payment: true,
        });
        const paymentConfirm = await stripe.confirmCardPayment(
          createPayment?.data?.paymentCreate?.create_payment_code || '',
          {
            payment_method: e.paymentMethod?.id,
          },
          {
            handleActions: false,
          },
        );
        if (paymentConfirm.error) {
          e.complete('fail');
          return;
        }
        e.complete('success');
        if (paymentConfirm.paymentIntent.status === 'requires_action') {
          await stripe.confirmCardPayment(
            createPayment?.data?.paymentCreate?.create_payment_code || '',
          );
        }
        if (paymentConfirm?.paymentIntent?.status === 'succeeded') {
          await updatePaymentStatus({
            variables: {
              input: {
                id: createPayment?.data?.paymentCreate?.id || '',
                status: PaymentStatus.Success,
              },
            },
          });
          refetchPlanDocuments?.();
          history.push(apiUrls.CHECKOUT_SUCCESS);
        }
      } catch (err: any) {
        openSnackbar(
          {
            message: err.message,
          },
          NotifierType.Error,
        );
      }
    });
  }, [stripe, elements, setApplePayRequest, totalPrice]);

  return {
    loading:
      loadingPlan ||
      loadingAddress ||
      paymentInProgress ||
      loadingUpdatePaymentStatus ||
      loadingPaymentDetails ||
      loadingCreatePayment ||
      loadingPlanDocuments,
    showCheckout: showCheckout && !showPaymentSummary,
    showPaymentSummary: showPaymentSummary && !showCheckout,
    userPlanPrice: totalPrice,
    userPlanName: userPlan?.userPlan?.name,
    shippingAddress,
    addresses: userAddresses?.userAddresses,
    handleShippingAddressChange,
    onProceedToPayment,
    addShippingAddress,
    setAddShippingAddress,
    refetchAddress: refetch,
    cardInfo,
    setCardInfo,
    deliveryDate,
    orderReview,
    setOrderReview,
    onProceedToPay,
    setPaymentMethod,
    setPaymentInProgress,
    paymentDetails: paymentDetails?.paymentDetails,
    applePayRequest,
    showApplePay,
    payingWithApplePay,
    setPayingWithApplePay,
  };
};

export default useCheckout;
