import React, { useContext } from 'react';
import {
  Breakpoint,
  Divider,
  Grid,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { BREAKPOINTS } from '../../variables/constant';
import ProgressBoxList from '../ProgressBoxList';
import { PlanDocumentFragmentFragment } from '../../generated/graphql';
import Typography from '@mui/material/Typography';
import palette from '../../theme/palette';
import { DocumentContext } from '../../pages/PlanDocuments/DocumentContext';

type Props = {
  children?: React.ReactNode;
  planDocuments?: PlanDocumentFragmentFragment | null;
};

const DocumentContentLayout = ({ children }: Props) => {
  const { planDocuments } = useContext(DocumentContext);
  const progressPercentage = planDocuments?.progress_percentage
    ? planDocuments?.progress_percentage
    : 0;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.info.main,
    },
  }));

  const theme = useTheme();

  return (
    <Grid
      container
      display="flex"
      direction="row"
      justifyContent="center"
      sx={{
        maxWidth: { xs: '100%' },
        pb: 5,
      }}
    >
      <Grid
        item
        lg={3}
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            display: 'none',
          },
          mt: 2,
        }}
      >
        <ProgressBoxList />
      </Grid>
      <Grid
        item
        lg={7}
        sx={{
          width: '100%',
          [theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint)]: {
            px: 10,
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          sx={{
            mt: 4,
            [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
              ml: 4,
            },
            [theme.breakpoints.down(BREAKPOINTS.MD as Breakpoint)]: {
              justifyContent: 'center',
            },
          }}
        >
          <Grid
            container
            spacing={1}
            flexDirection={'row'}
            justifyContent={'end'}
            alignItems={'center'}
            sx={{
              [theme.breakpoints.down(BREAKPOINTS.MD as Breakpoint)]: {
                justifyContent: 'flex-start',
                ml: 2,
              },
            }}
          >
            <Grid
              item
              xs="auto"
              sx={{
                color: palette.grey[700],
              }}
            >
              <Typography variant="sHelper" component="p" noWrap={true}>
                OVERALL PROGRESS
              </Typography>
            </Grid>
            <Grid item xs={10} sm={11} md={5}>
              <BorderLinearProgress
                variant="determinate"
                value={progressPercentage}
                sx={{
                  my: 2,
                }}
              />
            </Grid>
            <Grid item xs={2} sm="auto">
              <Typography variant="sHelper" component="p" noWrap={true}>
                {progressPercentage}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          variant={'fullWidth'}
          sx={{
            my: 3,
            [theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint)]: {
              display: 'none',
            },
          }}
        />
        {children}
      </Grid>
    </Grid>
  );
};

export default DocumentContentLayout;
