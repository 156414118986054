import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useUserInfo from '../../core/useUserInfo';
import { Auth } from 'aws-amplify';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import useLocalStorage from '../../hooks/useLocalStorage';
import { apiUrls } from '../../variables/urls';
import { useAuthProvider } from '../../core/authContext';

const useSalesSignup = () => {
  const [stateLoading, setLoading] = useState(false);
  const [isLoadingUserInfo] = useUserInfo();
  const [isCorporateUser, setIsCorporateUser] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const { getUser } = useAuthProvider();
  const user = getUser();
  const userID = user?.id;

  useEffect(() => {
    if (userID) {
      history.push(apiUrls.ROOT);
    }
  }, [userID]);

  const [basicInfo, setBasicInfo] = useLocalStorage('basic-info', {});
  useEffect(() => {
    if (!userID) {
      if (!basicInfo || basicInfo.is_married === undefined) {
        history.push(apiUrls.BASIC_INFO);
      }
      if (!basicInfo?.plan_id) {
        history.push(apiUrls.PLAN);
      }
    }
  }, [basicInfo, userID]);

  const checkUserPreSignup = async (data: Record<string, string>) => {
    if (!data.salesPersonName) {
      setError('salesPersonName', {
        type: 'custom',
        message: 'Please enter your name as a Sales Person',
      });
    } else {
      onUserSignup(data);
    }
  };

  const onUserSignup = useCallback(
    async (data: Record<string, string>) => {
      setLoading(true);
      try {
        const cognitoUsername = data.email.trim().toLowerCase();
        await Auth.signUp({
          username: cognitoUsername,
          password: data.password,
          clientMetadata: {
            is_married: basicInfo.is_married.toString(),
            has_real_estate: basicInfo.has_real_estate.toString(),
            has_children: basicInfo.has_children.toString(),
            has_asset_above_threshold:
              basicInfo.has_asset_above_threshold.toString(),
            plan_id: basicInfo.plan_id.toString(),
            sales_person: data.salesPersonName,
            ...(data?.company_id ? { company_id: data?.company_id } : {}),
          },
        });

        await Auth.signIn({
          username: cognitoUsername,
          password: data.password,
        });

        setLoading(false);
        setBasicInfo({});
        history.push(apiUrls.DASHBOARD);

        openSnackbar(
          { message: 'You have signed up successfully' },
          NotifierType.Success,
        );
      } catch (err: any) {
        setLoading(false);
        openSnackbar({ message: err?.message }, NotifierType.Error);
      }
    },
    [history, setLoading],
  );

  return {
    loading: isLoadingUserInfo || stateLoading,
    register,
    errors,
    handleSubmit,
    onUserSignup: checkUserPreSignup,
    setLoading,
    isCorporateUser,
    setIsCorporateUser,
  };
};

export default useSalesSignup;
