import React from 'react';
import { apiUrls } from '../../variables/urls';
import { Route, Switch } from 'react-router-dom';
import CompletionScreen from './CompletionScreen';
import BeneficiariesMain from './BeneficiariesMain';

const Guardianship = () => {
  return (
    <Switch>
      <Route path={apiUrls.BENEFICIARIES} component={BeneficiariesMain} exact />
      <Route
        path={apiUrls.BENEFICIARIES_COMPLETION}
        component={CompletionScreen}
        exact
      />
    </Switch>
  );
};

export default React.memo(Guardianship);
