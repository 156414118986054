import React from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioProps,
  Typography,
} from '@mui/material';

interface Props {
  mockupName: string;
  color?: RadioProps['color'];
  checked?: boolean;
  disabled?: boolean;
}

const RadioButtonRow = ({ mockupName, color, checked, disabled }: Props) => (
  <Box display="grid" gridTemplateColumns="2fr 3fr 1fr" width="100%" my={2}>
    <Typography>{`Mockup Name: ${mockupName}`}</Typography>
    <Typography>
      {!disabled && (
        <Typography component="span">
          color: <code>{color}</code>
        </Typography>
      )}
      {disabled && (
        <Typography component="span">
          disabled: <code>true</code>
        </Typography>
      )}
      {checked && (
        <Typography component="span">
          {', '}
          checked: <code>true</code>
        </Typography>
      )}
    </Typography>
    <FormControlLabel
      checked={checked}
      value={mockupName}
      disabled={disabled}
      control={<Radio />}
      label={mockupName}
    />
  </Box>
);

export default React.memo(RadioButtonRow);
