import React from 'react';
import { apiUrls } from '../../variables/urls';
import { Route, Switch } from 'react-router-dom';
import PersonalRepresentativeSpouse from './PersonalRepresentativeSpouse';
import PersonalRepresentativeSelf from './PersonalRepresentativeSelf';
import CompletionScreen from './CompletionScreen';

const PersonalRepresentative = () => {
  return (
    <Switch>
      <Route
        path={apiUrls.PERSONAL_REPRESENTATIVE}
        component={PersonalRepresentativeSelf}
        exact
      />
      <Route
        path={apiUrls.PERSONAL_REPRESENTATIVE_SPOUSE}
        component={PersonalRepresentativeSpouse}
        exact
      />
      <Route
        path={apiUrls.PERSONAL_REPRESENTATIVE_COMPLETION}
        component={CompletionScreen}
        exact
      />
    </Switch>
  );
};

export default React.memo(PersonalRepresentative);
