export enum NotifierType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export type User = {
  id: String;
};

export enum DevtoolTypes {
  UpdateFlag = 'UPDATE_FLAG',
  ResetFlag = 'RESET_FLAGS',
}
