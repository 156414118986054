import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { ChildrenFragmentFragment, PetsFragmentFragment } from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  usePetsInputMutation,
} from '../../generated/graphql';
import { SUMMARY } from '../Summary/constants';

export interface DataForm {
  fieldArray: {
    id: string;
    name: string;
    type: string;
    primary_guardian_name: string;
    secondary_guardian_name: string;
    same_guardian_as_above: boolean;
  }[];
}

export interface GuardiansInfoProps {
  petsList: PetsFragmentFragment[];
  guardians: ChildrenFragmentFragment[];
  uiFlag?: string;
  setEditState?: Function;
}

const useGuardiansForm = ({
  petsList,
  guardians,
  uiFlag, 
  setEditState, 
}: GuardiansInfoProps) => {

  const defaultValues = {
    fieldArray: [
      {
        id: '',
        name: '',
        type: '',
        primary_guardian_name: '',
        secondary_guardian_name: '',
        same_guardian_as_above: false,
      },
    ],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<DataForm>({
    defaultValues,
  });

  const { fields, replace } = useFieldArray({
    name: 'fieldArray',
    control,
    rules: {
      required: 'Please select or enter details of the Guardians for your Pets',
    },
  });

  useEffect(() => {
    let petData = [] as any;
    if (petsList.length > 0) {
      petsList.map((pet: any) => {
        return petData.push({
          id: pet.pet_id,
          name: pet.name,
          type: pet.type,
          primary_guardian_name: pet.primary_guardian_name,
          secondary_guardian_name: pet.secondary_guardian_name,
          same_guardian_as_above: pet.same_guardian_as_above,
        });
      });
      replace(petData);
    }
    if (!guardians.length) {
      setRadioKey(1);
    } else {
      let index = guardians.findIndex((obj) => petsList[0].primary_guardian_name === obj.primary_guardian_name);
      setRadioKey(index !== -1 ? index : guardians.length + 1);
    }
  }, [petsList]);

  const { refetchPlanDocuments } = useContext(DocumentContext);

  const [UpsertPets, { loading: loadingPets }] = usePetsInputMutation();
  const [radioKey, setRadioKey] = useState(0);

  const history = useHistory();

  const createPetsWithGuardians = async (data: DataForm) => {
    try {
      await UpsertPets({
        variables: {
          input: data.fieldArray.map((pet: any) => {
            return {
              id: pet?.id || null,
              name: pet.name,
              type: pet.type,
              primary_guardian_name: radioKey === guardians.length + 1 ? pet.primary_guardian_name : guardians[radioKey].primary_guardian_name,
              secondary_guardian_name: radioKey === guardians.length + 1 ? pet.secondary_guardian_name : guardians[radioKey].secondary_guardian_name,
              same_guardian_as_above: pet.same_guardian_as_above,
            };
          }),
        },
      });
      openSnackbar(
        {
          message: 'Added pets successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        history.push(apiUrls.PETS_COMPLETION);
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };
  const watched = watch('fieldArray');

  return {
    loading: loadingPets,
    errors,
    control,
    fields,
    watched,
    radioKey,
    setRadioKey,
    create: createPetsWithGuardians,
    handleSubmit,
  };
};

export default useGuardiansForm;
