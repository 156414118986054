import React, { useContext, useState } from 'react';
import {
  Box,
  Breakpoint,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import Layout from '../../components/Layout';
import MultiStepForm from '../PersonalInformation/MultiStepForm';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { EDIT_CASES, SUMMARY } from '../Summary/constants';
import Loader from '../../components/Loader';

const Profile = () => {
  const theme = useTheme();

  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const [editState, setEditState] = useState('');

  const summaryContent = (
    title: string,
    subtitle: string,
    editCase: string,
  ) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          py: 1.5,
        }}
      >
        <Typography variant="s" component="p" sx={{
          width: '20%',
          overflowWrap: 'anywhere',
        }}>
          {title}
        </Typography>
        <Typography variant="sideBar" component="p" sx={{
          width: '65%',
          overflowWrap: 'anywhere',
        }}>
          {subtitle}
        </Typography>
        <Stack
          direction="row"
          sx={{
            color: theme.palette.primary.main,
            cursor: 'pointer',
          }}
          onClick={() => setEditState(editCase)}
        ><EditIcon sx={{
          width: '16px',
        }} /> <Typography sx={{ 
          ml: 1,
          fontSize: '14px',
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            display: 'none',
          },
        }}>Edit</Typography></Stack>
      </Stack>
    );
  };

  const personalInformation = () => {
    return (
      <>
        <Typography component="div" variant="sTextInfoLight">
          Information about your profile.
        </Typography>
        <Box sx={{
          backgroundColor: theme.palette.common.white,
          px: 5,
          py: 2.5,
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            px: 2,
            py: 2,
          },
        }}>
          <Typography variant="mLonger" component="p" sx={{
            py: 2,
          }}>
            Basic Info
          </Typography>
          {editState === EDIT_CASES.PERSONAL_INFORMATION.PERSONAL_INFO_FORM ? <Box sx={{
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              py: 2,
            },
          }}><MultiStepForm uiFlag={SUMMARY} setEditState={setEditState} page={1} /></Box> : summaryContent(
            'Full Legal Name',
            `${planDocuments?.personal_information?.self?.first_name || ''} ${
              planDocuments?.personal_information?.self?.middle_name || ''
            } ${
              planDocuments?.personal_information?.self?.last_name || ''
            }`,
            EDIT_CASES.PERSONAL_INFORMATION.PERSONAL_INFO_FORM,
          )}
          <Divider />
          {editState === EDIT_CASES.PERSONAL_INFORMATION.ABOUT_YOU_FORM ? <Box sx={{
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              py: 2,
            },
          }}><MultiStepForm uiFlag={SUMMARY} setEditState={setEditState} page={2} /></Box> :
          /*eslint-disable */
          summaryContent(
            'Date Of Birth',
            `${
              planDocuments?.personal_information?.self?.date_of_birth
                ? format(
                    new Date(
                      planDocuments?.personal_information?.self?.date_of_birth,
                    ) as Date,
                    'MM/dd/yyyy',
                  )
                : ''
            }`,
            EDIT_CASES.PERSONAL_INFORMATION.ABOUT_YOU_FORM,
          )
          /*eslint-enable */
          }
          <Divider />
          <Typography variant="mLonger" component="p" sx={{
            py: 2,
          }}>
            Contact Info
          </Typography>
          {editState === EDIT_CASES.PERSONAL_INFORMATION.ADDRESS_FORM ? <Box sx={{
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              py: 2,
            },
          }}><MultiStepForm uiFlag={SUMMARY} setEditState={setEditState} page={3} /></Box> : summaryContent(
            'Address',
            `${planDocuments?.personal_information?.self?.address?.address || ''}
              ${
                planDocuments?.personal_information?.self?.address
                  ?.apt_or_suit || ''
              }
              ${planDocuments?.personal_information?.self?.address?.city || ''},
              ${
                planDocuments?.personal_information?.self?.address?.county || ''
              },
              ${planDocuments?.personal_information?.self?.address?.state || ''}
              ${
                planDocuments?.personal_information?.self?.address?.zip_code ||
                ''
              }`,
            EDIT_CASES.PERSONAL_INFORMATION.ADDRESS_FORM,
          )}
          <Divider />
          {editState === EDIT_CASES.PERSONAL_INFORMATION.CONTACT_INFO_FORM ? <Box sx={{
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              py: 2,
            },
          }}><MultiStepForm uiFlag={SUMMARY} setEditState={setEditState} page={4} /></Box> :
          <>
            {summaryContent(
              'Email',
              `${planDocuments?.personal_information?.self?.email || ''}`,
              EDIT_CASES.PERSONAL_INFORMATION.CONTACT_INFO_FORM,
            )}
            <Divider />
            {summaryContent(
              'Phone Number',
              `${planDocuments?.personal_information?.self?.phone || ''}`,
              EDIT_CASES.PERSONAL_INFORMATION.CONTACT_INFO_FORM,
            )}
            <Divider />
          </>
          }
        </Box>
      </>
    );
  };

  return (
    <Layout isDocumentPage={false}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            width: '100vw',
          },
          [theme.breakpoints.up(BREAKPOINTS.SM as Breakpoint)]: {
            width: '90%',
            padding: '0% 5%',
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Typography
            variant="lDisplay"
            component="p"
            sx={{
              mb: 3,
            }}
          >
            My Profile
          </Typography>
          {personalInformation()}
        </Stack>
      </Box>
      <Loader open={loadingPlanDocuments} />
    </Layout>
  );
};

export default React.memo(Profile);
