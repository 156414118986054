import { useContext, useEffect } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { PetsFragmentFragment, useDeletePetMutation } from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useFieldArray, useForm } from 'react-hook-form';

export interface DataForm {
  fieldArray: {
    pet_id: string;
    name: string;
    type: string;
    primary_guardian_name: string;
    secondary_guardian_name: string;
    same_guardian_as_above: boolean;
  }[];
}

export interface PetsInfoProps {
  petsList: PetsFragmentFragment[];
  setShowGuardianBanner: Function;
  setFinalPets: Function;
}

const usePetsForm = ({
  petsList,
  setShowGuardianBanner,
  setFinalPets,
}: PetsInfoProps) => {
  const [DeletePet, { loading: loadingDeletePets }] = useDeletePetMutation();
  
  const defaultValues = {
    fieldArray: [
      {
        pet_id: '',
        name: '',
        type: '',
        primary_guardian_name: '',
        secondary_guardian_name: '',
        same_guardian_as_above: false,
      },
    ],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<DataForm>({
    defaultValues,
  });

  const { fields, remove, append, replace } = useFieldArray({
    name: 'fieldArray',
    control,
    rules: {
      required: 'Please enter information of at least one Pet',
    },
  });

  useEffect(() => {
    let petData = [] as any;
    if (petsList.length > 0) {
      petsList.map((pet) => {
        return petData.push({
          pet_id: pet.id,
          name: pet.name,
          type: pet.type,
          primary_guardian_name: pet.primary_guardian_name,
          secondary_guardian_name: pet.secondary_guardian_name,
          same_guardian_as_above: false,
        });
      });
      replace(petData);
    }
  }, [petsList]);

  const { refetchPlanDocuments } = useContext(DocumentContext);

  const onCreate = async (data: DataForm) => {
    setFinalPets(data.fieldArray);
    setShowGuardianBanner(true);
  };

  const deleteAPet = async (id: string, index: number) => {
    console.log(fields);
    if (id) {
      try {
        await DeletePet({
          variables: {
            input: id,
          },
        });
        openSnackbar(
          {
            message: 'Pet details deleted successfully',
          },
          NotifierType.Success,
        );
        refetchPlanDocuments?.();
      } catch (err: any) {
        openSnackbar({ message: err?.message }, NotifierType.Error);
      }
    }
    remove(index);
  };

  return {
    loading: loadingDeletePets,
    errors,
    control,
    fields,
    append,
    create: onCreate,
    handleSubmit,
    deleteAPet,
  };
};

export default usePetsForm;
