import React from 'react';
import { Link, Typography } from '@mui/material';
import { apiUrls } from '../../variables/urls';

const DisclaimerFooter = () => {
  return (
    <Typography variant="disclaimer">
      By continuing I agree to TruWill’s Terms of Service and{' '}
      <Typography
        component={Link}
        variant="disclaimer"
        underline="hover"
        sx={{ cursor: 'pointer' }}
        href={apiUrls.PRIVACY_POLICY}
      >
        Privacy Policy
      </Typography>
    </Typography>
  );
};

export default React.memo(DisclaimerFooter);
