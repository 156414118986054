import { DevtoolTypes } from '../../variables/types';

interface State {
  featureFlags: Record<string, boolean>;
}

interface Action {
  type: string;
  payload: any;
}

export const initialState = {
  featureFlags: {},
};

const devToolReducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case DevtoolTypes.UpdateFlag: {
      const featureFlags = {
        ...state.featureFlags,
        ...payload,
      };
      localStorage.setItem('featureFlags', JSON.stringify(featureFlags));
      return {
        ...state,
        featureFlags,
      };
    }
    case DevtoolTypes.ResetFlag:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

export default devToolReducer;
