import { useForm } from 'react-hook-form';
import { CardProps } from './payment-hooks';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import {
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  useCreatePaymentMutation,
  UserAddressFragmentFragment,
} from '../../../generated/graphql';
import { useAuthProvider } from '../../../core/authContext';
import { CreatePaymentMethodData } from '@stripe/stripe-js';

export interface CardFormProps {
  setCard: Function;
  shippingAddress: UserAddressFragmentFragment;
  setPaymentMethod: Function;
  setPaymentInProgress: Function;
}

const useCreditCardForm = ({
  setCard,
  shippingAddress,
  setPaymentMethod,
  setPaymentInProgress,
}: CardFormProps) => {
  const defaultValues = {
    name: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CardProps>({
    defaultValues: {
      ...defaultValues,
    },
  });

  const stripe = useStripe();
  const elements = useElements();
  const { getUser } = useAuthProvider();
  const user = getUser();

  const [paymentCreate, { loading: loadingCreatePayment }] =
    useCreatePaymentMutation();

  const onSubmit = async (data: CardProps) => {
    setPaymentInProgress(true);
    try {
      if (!stripe || !elements) {
        openSnackbar(
          { message: 'Something went wrong, try again' },
          NotifierType.Error,
        );
        setPaymentInProgress(false);
        return;
      }
      const cardElement = elements.getElement(CardCvcElement);
      const paymentMethod = {
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            city: shippingAddress.city!,
            country: 'US',
            line1: shippingAddress.address!,
            line2: '',
            postal_code: shippingAddress.zip_code!,
            state: shippingAddress.state || '',
          },
          email: user.email!,
          name: data.name!,
          phone: '',
        },
      };
      const result = await stripe.createPaymentMethod(
        paymentMethod as CreatePaymentMethodData,
      );
      if (result.error) {
        openSnackbar(
          { message: result?.error?.message || 'Failed payment, try again' },
          NotifierType.Error,
        );
        setPaymentInProgress(false);
      }
      const createPayment = await paymentCreate({
        variables: {
          input: {
            plan_id: user.user_info.plan_id,
            payment_method_id: result.paymentMethod?.id || '',
          },
        },
      });
      setPaymentMethod({
        payment_method_id: result.paymentMethod?.id,
        create_payment_code:
          createPayment?.data?.paymentCreate?.create_payment_code,
        id: createPayment?.data?.paymentCreate?.id,
      });
      setCard(data);
      setPaymentInProgress(false);
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
      setPaymentInProgress(false);
    }
  };

  return {
    handleSubmit,
    errors,
    onSubmit,
    control,
    loading: loadingCreatePayment,
  };
};

export default useCreditCardForm;
