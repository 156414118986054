import React from 'react';
import CompletionBox from '../../components/CompletionBox';
import PersonalInformationIcon from '../../components/Icons/CompletionScreen/PersonalInformationIcon';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';

const CompletionScreen = () => {
  const history = useHistory();

  return (
    <CompletionBox
      header={'Personal Information'}
      image={PersonalInformationIcon}
      title="Awesome! We’ve got your back!"
      quote={
        'Thanks TruWill! I feel much better knowing that if ' +
        'something were to happen to me and my spouse our minor ' +
        'children would be properly cared for and loved!'
      }
      quoteBy="Kara"
      previous={() => history.push(apiUrls.PERSONAL_INFORMATION)}
      continue={() => history.push(apiUrls.CHILDREN)}
    />
  );
};

export default React.memo(CompletionScreen);
