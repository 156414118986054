import { DataForm } from './basicinfo-hooks';

interface Question {
  name: keyof DataForm;
  question: string;
  subText?: string;
  options?: Array<string>;
}

export const questions: Question[] = [
  {
    name: 'is_married',
    question: 'Are you married?',
  },
  {
    name: 'has_children',
    question: 'Do you have children?',
  },
  {
    name: 'has_real_estate',
    question: 'Do you own a home or other real estate?',
  },
  {
    name: 'has_asset_above_threshold',
    question: 'Do you have assets worth more than $10k?',
    subText:
      '(This may include savings, investments, equity in your home, etc..)',
  },
];
