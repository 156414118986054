import React, { MouseEventHandler } from 'react';
import { BREAKPOINTS } from '../../variables/constant';
import { Breakpoint, Grid, Paper, useMediaQuery } from '@mui/material';
import PreviousButton from '../PreviousButton';
import { useTheme } from '@mui/material/styles';

type Props = {
  children?: React.ReactNode;
  onPreviousClick: MouseEventHandler;
  hidePaper?: boolean;
  previousButtonText?: string;
};

const LayoutContent = ({
  children,
  onPreviousClick,
  hidePaper,
  previousButtonText,
}: Props) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(
    theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint),
  );
  return (
    <Grid
      container
      direction="column"
      sx={{
        width: '100%',
        [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
          ml: 4,
        },
        [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
          p: 3,
        },
      }}
    >
      <Grid
        item
        sx={{
          mb: 2,
          [theme.breakpoints.down(BREAKPOINTS.MD as Breakpoint)]: {
            mt: -3,
          },
        }}
      >
        <PreviousButton
          onPreviousClick={onPreviousClick}
          buttonText={previousButtonText}
        />
      </Grid>
      <Grid item sx={{ mt: 4 }}>
        {hidePaper ? (
          children
        ) : (
          <Paper
            elevation={mobileView ? 0 : 1}
            sx={{
              height: '100%',
            }}
          >
            {children}
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(LayoutContent);
