import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const RouteMiddleware = () => {
  const location = useLocation();
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID || '';
  const validUUID = new RegExp(
    '/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
  );

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    if (validUUID.test(location.pathname)) {
      const newLocationPath = location.pathname.replace(validUUID, '');
      ReactGA.pageview(newLocationPath + location.search);
    } else {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
  return <></>;
};

export default RouteMiddleware;
