import React from 'react';
import { apiUrls } from '../../variables/urls';
import { Route, Switch } from 'react-router-dom';
import GuardianshipForm from './GuardianshipForm';
import CompletionScreen from './CompletionScreen';

const Guardianship = () => {
  return (
    <Switch>
      <Route path={apiUrls.GUARDIANSHIP} component={GuardianshipForm} exact />
      <Route
        path={apiUrls.GUARDIANSHIP_COMPLETION}
        component={CompletionScreen}
        exact
      />
    </Switch>
  );
};

export default React.memo(Guardianship);
