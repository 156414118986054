import { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';

import {
  AUTH_KEY,
  CognitoHostedUIIdentityProvider,
} from '../variables/constant';
import client from './apolloClient';
import meQuery from '../utils/meQuery';
import { useAuthProvider } from './authContext';
import { openSnackbar } from '../components/Notifier';
import getErrorMessage from '../utils/getErrorMessage';
import { NotifierType } from '../variables/types';
import useLocalStorage from '../hooks/useLocalStorage';
import { useBasicInfoMutation } from '../generated/graphql';
import { apiUrls } from '../variables/urls';

const useUserInfo = () => {
  const { getUser, setUser } = useAuthProvider();
  const [user, setNewUser] = useState(getUser());
  const [isLoadingUserInfo, setLoadingUserInfo] = useState(false);
  const [isSavingBasicInfo, setSavingBasicInfo] = useState(false);
  const [basicInfo, setBasicInfo] = useLocalStorage('basic-info', {});
  const [updateBasicInfo, { loading }] = useBasicInfoMutation();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setLoadingUserInfo(true);
        const { data: userInfo } = await client.query({
          query: meQuery,
          fetchPolicy: 'network-only',
        });
        const userDetails = userInfo?.me;
        setUser(userDetails);
        setNewUser(userDetails);
      } catch (error) {
        openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
      }
      setLoadingUserInfo(false);
    };

    const saveBasicInfo = async (email: string) => {
      if (!email) {
        return;
      }
      try {
        setSavingBasicInfo(true);
        if (basicInfo?.plan_id) {
          await updateBasicInfo({
            variables: {
              input: {
                is_married: basicInfo.is_married || false,
                has_children: basicInfo.has_children || false,
                has_real_estate: basicInfo.has_real_estate || false,
                has_asset_above_threshold:
                  basicInfo.has_asset_above_threshold || false,
                plan_id: basicInfo.plan_id,
                email: email,
              },
            },
          });
          window.location.replace(apiUrls.DASHBOARD);
        }
        setBasicInfo({});
      } catch (error) {
        openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
      }
      setSavingBasicInfo(false);
    };

    const onAuthChange = async ({ payload: { event, data } }: any) => {
      switch (event) {
        case 'signIn':
          const userToken = data.getSignInUserSession().getIdToken();
          const providerName = userToken.payload.identities
            ? userToken.payload.identities[0].providerName
            : null;
          if (
            [
              CognitoHostedUIIdentityProvider.Google,
            ].includes(providerName)
          ) {
            await saveBasicInfo(userToken.payload.email);
          }
          await getUserInfo();
          break;
        case 'signOut':
          setUser(null);
          setNewUser(null);
          break;
      }
    };

    Hub.listen(AUTH_KEY, onAuthChange);
    return () => {
      Hub.remove(AUTH_KEY, onAuthChange);
    };
  }, [setUser]);
  return [isLoadingUserInfo || isSavingBasicInfo || loading, user];
};

export default useUserInfo;
