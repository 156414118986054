import React from 'react';
import { Box, Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/system';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import CurveLineIcon from '../../components/Icons/CurveLineIcon';
import DocumentIcon from '../../components/Icons/DocumentIcon';
import DocumentActiveIcon from '../../components/Icons/DocumentActiveIcon';
import NotorizationIcon from '../../components/Icons/NotorizationIcon';
import NotorizationActiveIcon from '../../components/Icons/NotorizationActiveIcon';
import PaymentIcon from '../../components/Icons/PaymentIcon';
import PaymentActiveIcon from '../../components/Icons/PaymentActiveIcon';
import TickIcon from '../../components/Icons/TickIcon';

interface Props {
  activeStep: number;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)',
    top: '50%',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[500],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled(Box)(() => ({
  display: 'flex',
  height: 130,
  alignItems: 'center',
  flexDirection: 'column',
}));

const steps = ['Payment', 'Documents', 'Notorization'];

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props;

  const renderIcon = () => {
    switch (icon) {
      case 1:
        return active || completed ? (
          <Box
            component={PaymentActiveIcon}
            sx={{
              height: { xs: '2rem', lg: '3rem' },
              width: { xs: '2rem', lg: '3rem' },
            }}
          />
        ) : (
          <Box
            component={PaymentIcon}
            sx={{
              height: { xs: '2rem', lg: '3rem' },
              width: { xs: '2rem', lg: '3rem' },
            }}
          />
        );
      case 2:
        return active || completed ? (
          <Box
            component={DocumentActiveIcon}
            sx={{
              height: { xs: '2rem', lg: '3rem' },
              width: { xs: '2rem', lg: '3rem' },
            }}
          />
        ) : (
          <Box
            component={DocumentIcon}
            sx={{
              height: { xs: '2rem', lg: '3rem' },
              width: { xs: '2rem', lg: '3rem' },
            }}
          />
        );
      case 3:
        return active || completed ? (
          <Box
            component={NotorizationActiveIcon}
            sx={{
              height: { xs: '2rem', lg: '3rem' },
              width: { xs: '2rem', lg: '3rem' },
            }}
          />
        ) : (
          <Box
            component={NotorizationIcon}
            sx={{
              height: { xs: '2rem', lg: '3rem' },
              width: { xs: '2rem', lg: '3rem' },
            }}
          />
        );
      default:
        return (
          <Box
            component={DocumentIcon}
            sx={{
              height: { xs: '2rem', lg: '3rem' },
              width: { xs: '2rem', lg: '3rem' },
            }}
          />
        );
    }
  };

  return (
    <QontoStepIconRoot className={className}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: {
            xs: '20px 30px 20px',
            lg: '30px 40px 30px',
          },
          gap: 2,
          height: {
            xs: 80,
            lg: 110,
          },
        }}
      >
        <Box>
          {completed ? (
            <Box
              component={TickIcon}
              sx={{
                height: { xs: '2rem', lg: '3rem' },
                width: { xs: '2rem', lg: '3rem' },
              }}
            />
          ) : null}
        </Box>
        <Box>{renderIcon()}</Box>
        <Box>
          {active ? (
            <Box
              component={CurveLineIcon}
              sx={{
                height: { xs: '2rem', lg: '3rem' },
                width: { xs: '2rem', lg: '3rem' },
              }}
            />
          ) : null}
        </Box>
      </Box>
    </QontoStepIconRoot>
  );
};

const StepperContainer = ({ activeStep }: Props) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<QontoConnector />}
      sx={{
        my: 8,
      }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            sx={{
              '& .MuiStepLabel-label': {
                fontSize: '1rem',
                fontWeight: 600,
                lineHeight: 1.2,
                color: 'common.naturalBlack',
                display: { xs: 'none', lg: 'block' },
                mb: {
                  xs: 0,
                  mt: 2,
                },
              },
            }}
            StepIconComponent={QontoStepIcon}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
export default React.memo(StepperContainer);
