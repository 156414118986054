import React from 'react';
import { Box, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Control, Controller } from 'react-hook-form';
import { DataForm } from './basicinfo-hooks';
import ButtonSelectionGroup from './ButtonSelectionGroup';

interface Props {
  control: Control;
  question: string;
  subText?: string;
  options?: Array<string>;
  name: keyof DataForm;
  displayQuestion: boolean;
}

const QuestionRow = ({
  control,
  question,
  subText,
  name,
  options,
  displayQuestion,
}: Props) => {
  return (
    <Box
      textAlign={'center'}
      sx={{ mb: 1.5, display: displayQuestion ? 'block' : 'none' }}
    >
      <Typography variant="lgText" component="p" sx={{ mt: 6 }}>
        {question}
      </Typography>
      {subText && (
        <Typography variant="s" component="p">
          (This may include savings, investments, equity in your home, etc..)
        </Typography>
      )}
      {!options ? (
        <FormControl>
          <Controller
            control={control}
            name={name}
            rules={{
              validate: (formValue?: boolean) =>
                typeof formValue === 'boolean'
                  ? true
                  : 'Please answer the question',
            }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <ButtonSelectionGroup
                value={value}
                name={name}
                onClick={onChange}
                errors={errors}
              />
            )}
            defaultValue={undefined}
          />
        </FormControl>
      ) : (
        <FormControl>
          <Controller
            control={control}
            name={name}
            rules={{
              validate: (formValue?: string) =>
                typeof formValue === 'string'
                  ? true
                  : 'Please answer the question',
            }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <ButtonSelectionGroup
                value={value}
                name={name}
                options={options}
                onClick={onChange}
                errors={errors}
              />
            )}
            defaultValue={undefined}
          />
        </FormControl>
      )}
    </Box>
  );
};

export default React.memo(QuestionRow);
