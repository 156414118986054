import React from 'react';
import {
  Box,
  Breakpoint,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BREAKPOINTS } from '../../variables/constant';
import HtmlTooltip from '../../components/HtmlTooltip';
import TabLayout from '../../components/TabLayout';
import { Control, Controller } from 'react-hook-form';
import FormHelperErrorText from '../../components/FormHelperErrorText';

interface Props {
  trusteeName: string | undefined;
  handleSubmit: Function;
  onNextClickAgeForm: Function;
  setShowPercentForm: Function;
  uiFlag?: string;
  setEditState?: Function;
  errors: any;
  control: Control<any>;
}

const BeneficiariesAgeForm = ({
  trusteeName,
  handleSubmit,
  onNextClickAgeForm,
  setShowPercentForm,
  uiFlag,
  control,
  errors,
}: Props) => {
  const theme = useTheme();

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onNextClickAgeForm)}
      noValidate
    >
      <TabLayout
        title="Beneficiaries"
        previous={() => setShowPercentForm(true)}
        uiFlag={uiFlag}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                p: 2,
              },
            }}
          >
            <Typography
              variant="m"
              component="div"
              align="center"
              sx={{
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  mx: 1,
                },
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  mx: 3,
                },
              }}
            >
              At what age would you like your beneficiaries to receive outright
              access to their inheritance?
              <HtmlTooltip
                title={
                  <React.Fragment>
                    Why should I provide an age numerical of my beneficiaries,
                    for access to their inheritance?
                    <br />
                    This age numerical is necessary to let us know when the
                    inheritance of your assets should be handed over to your
                    beneficiaries, making the management and ownership of your
                    valuable assets much easier to handle.
                  </React.Fragment>
                }
              />
            </Typography>
            {trusteeName && (
              <Typography
                variant="smText"
                component="div"
                align="center"
                sx={{
                  [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                    width: '80%',
                    marginLeft: '10%',
                  },
                  [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                    width: '70%',
                    marginLeft: '15%',
                  },
                  color: theme.palette.grey[700],
                  mt: 2,
                }}
              >
                Prior to this age, your child will have access to their
                inheritance through {trusteeName}, your named successor trustee.
              </Typography>
            )}
            <Grid
              container
              direction="column"
              sx={{
                px: 2,
                [theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint)]: {
                  px: 20,
                },
                py: 2,
              }}
            >
              <Grid item xs={12}>
                <Stack
                  spacing={2}
                  sx={{
                    mb: 2,
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="mTextInfo" component="div">
                    Age
                  </Typography>

                  <Controller
                    name="age"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        type="number"
                        label="Age"
                        value={value}
                        onChange={onChange}
                        sx={{
                          mt: 2,
                        }}
                      />
                    )}
                    rules={{
                      required: 'Please enter the inheritance age',
                      min: {
                        value: 18,
                        message:
                          'Inheritance age should be more than or equal to 18',
                      },
                    }}
                    control={control}
                  />
                  {errors.age && console.log(errors?.age?.message)}
                  {errors.age && (
                    <FormHelperErrorText>
                      {errors?.age?.message}
                    </FormHelperErrorText>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Typography
              variant="smText"
              component="div"
              align="center"
              sx={{
                fontStyle: 'italic',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  width: '80%',
                  marginLeft: '10%',
                },
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  width: '70%',
                  marginLeft: '15%',
                },
                color: theme.palette.grey[700],
              }}
            >
              The default age for inheritance is 18
            </Typography>
          </Box>
        </Grid>
      </TabLayout>
    </form>
  );
};

export default React.memo(BeneficiariesAgeForm);
