import React from 'react';
import { apiUrls } from '../../variables/urls';
import LayoutContent from '../../components/LayoutContent';
import { useHistory } from 'react-router-dom';
import YouDidItPage from './YouDidItPage';

const Checkout = () => {
  const history = useHistory();
  return (
    <LayoutContent
      onPreviousClick={() => history.push(apiUrls.SUMMARY)}
      hidePaper={true}
    >
      <YouDidItPage />
    </LayoutContent>
  );
};

export default React.memo(Checkout);
