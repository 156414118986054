import { useState } from 'react';

interface Props {
  remove: Function;
}

const useAssetsCommon = ({ remove }: Props) => {
  const [editItems, setEditItems] = useState<number[]>([]);

  const cancelEditMode = (id: string | null, index: number) => {
    if (id) {
      setEditItems((items: number[]) => items.filter((item) => item !== index));
    } else {
      remove(index);
    }
  };

  return {
    editItems,
    setEditItems,
    cancelEditMode,
  };
};

export default useAssetsCommon;
