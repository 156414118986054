import React from 'react';
import Loader from '../../../components/Loader';
import useBusinessInterest from './business-interests-hooks';
import InterestBox from './BusinessInterestBox';

interface Props {
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const BusinessInterests = ({ selectedForms, uiFlag, setEditState }: Props) => {
  const { loading, businessInterests } = useBusinessInterest();
  return (
    <>
      <InterestBox
        businessInterests={businessInterests || []}
        selectedForms={selectedForms}
        uiFlag={uiFlag}
        setEditState={setEditState}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(BusinessInterests);
