import React from 'react';
import { Box, Button, Typography, ButtonProps } from '@mui/material';

interface Props {
  mockupName: string;
  color?: ButtonProps['color'];
  variant: ButtonProps['variant'];
  disabled?: boolean;
}

const ButtonRow = ({ mockupName, color, variant, disabled }: Props) => (
  <Box display="grid" gridTemplateColumns="2fr 3fr 1fr" width="100%" my={2}>
    <Typography variant="s">{`Mockup Name: ${mockupName}`}</Typography>
    <Typography variant="s">
      {!disabled && (
        <Typography component="span" variant="s">
          color: <code>{color}</code>
        </Typography>
      )}
      {disabled && (
        <Typography component="span" variant="s">
          disabled: <code>true</code>
        </Typography>
      )}
      <Typography component="span" variant="s">
        {', '}
        variant: <code>{variant}</code>
      </Typography>
    </Typography>
    <Button color={color} variant={variant} disabled={disabled}>
      Button Text
    </Button>
  </Box>
);

export default React.memo(ButtonRow);
