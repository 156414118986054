import React from 'react';
import PersonalInformationForm from './PersonalInformationForm';
import Loader from '../../components/Loader';
import usePersonalInformation from './personal-information-hooks';
import { PersonType } from '../../generated/graphql';
import { SUMMARY } from '../Summary/constants';

interface Props {
  uiFlag?: string;
  setEditState?: Function;
  page?: number;
  personType?: PersonType;
}

const MultiStepForm = ({ uiFlag, setEditState, page, personType }: Props) => {
  const {
    loading,
    self,
    spouse,
    prev,
    next,
    currentPage,
    resetPage,
    hasPartner,
  } = usePersonalInformation();
  return (
    <>
      <PersonalInformationForm
        self={self || null}
        spouse={spouse || null}
        hasPartner={hasPartner || false}
        prev={prev}
        next={next}
        personType={personType}
        currentPage={uiFlag === SUMMARY ? page : currentPage}
        resetPage={resetPage}
        uiFlag={uiFlag}
        setEditState={setEditState}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(MultiStepForm);
