import React from 'react';
import { FormControl, Stack, TextField } from '@mui/material';
import useCreditCardForm, { CardFormProps } from './credit-card-form-hooks';
import { Controller } from 'react-hook-form';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import StripeInput from '../../../components/StripeInput';

const CreditCardForm = ({
  setCard,
  shippingAddress,
  setPaymentMethod,
  setPaymentInProgress,
}: CardFormProps) => {
  const { handleSubmit, onSubmit, control, errors } = useCreditCardForm({
    setCard,
    shippingAddress,
    setPaymentMethod,
    setPaymentInProgress,
  });

  return (
    <form
      id="card_form_id"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
      noValidate
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <FormControl>
          <Controller
            name="name"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="NAME ON CARD"
                variant="outlined"
                value={value}
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
              />
            )}
            rules={{
              required: 'Please add name on the card',
            }}
            control={control}
          />
          {errors.name && (
            <FormHelperErrorText>{errors?.name?.message}</FormHelperErrorText>
          )}
        </FormControl>
        <FormControl>
          <Controller
            name="card_number"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="CARD NUMBER"
                variant="outlined"
                value={value}
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput as any,
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
              />
            )}
            rules={{
              required: 'Please add card number',
            }}
            control={control}
          />
          {errors.card_number && (
            <FormHelperErrorText>
              {errors?.card_number?.message}
            </FormHelperErrorText>
          )}
        </FormControl>
        <FormControl>
          <Controller
            name="expiration_date"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="EXPIRATION DATE"
                variant="outlined"
                value={value}
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput as any,
                  inputProps: {
                    component: CardExpiryElement,
                  },
                }}
              />
            )}
            rules={{
              required: 'Please add month/year',
            }}
            control={control}
          />
          {errors.expiration_date && (
            <FormHelperErrorText>
              {errors?.expiration_date?.message}
            </FormHelperErrorText>
          )}
        </FormControl>
        <FormControl>
          <Controller
            name="security_code"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="SECURITY CODE"
                variant="outlined"
                value={value}
                onChange={onChange}
                type="password"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput as any,
                  inputProps: {
                    component: CardCvcElement,
                  },
                }}
              />
            )}
            rules={{
              required: 'Please add security code',
            }}
            control={control}
          />
          {errors.security_code && (
            <FormHelperErrorText>
              {errors?.security_code?.message}
            </FormHelperErrorText>
          )}
        </FormControl>
      </Stack>
    </form>
  );
};

export default React.memo(CreditCardForm);
