import React from 'react';
import { FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import useTrusteesForm, { TrusteesInfoProps } from './trustees-form-hooks';
import HtmlTooltip from '../../components/HtmlTooltip';
import TabLayout from '../../components/TabLayout';
import Loader from '../../components/Loader';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';

const TrusteesForm = ({ info, uiFlag, setEditState }: TrusteesInfoProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { errors, handleSubmit, create, control, loading } = useTrusteesForm({
    info,
    uiFlag,
    setEditState,
  });

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout
        title="Trustees"
        previous={() => history.push(apiUrls.PERSONAL_REPRESENTATIVE)}
        uiFlag={uiFlag}
        setEditState={setEditState}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="m" component="div">
                  Who will manage your finances and settle your estate in the
                  event that both spouses pass away?
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Who Should I Choose as My Successor Trustee?
                        <br />
                        When choosing a successor trustee you should select
                        someone you trust to manage your assets for the benefit
                        of your beneficiaries. This can be an adult child or
                        other family member or a trusted friend. This person
                        does not need to have legal or business expertise but
                        should be able to exercise good judgment.
                      </React.Fragment>
                    }
                  />
                </Typography>
              </Stack>
              <Typography
                variant="smText"
                component="div"
                sx={{ width: '80%' }}
              >
                You should select someone you trust to manage your assets for
                the benefit of your beneficiaries. This can be an adult child,
                other family member, or a trusted friend.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <Typography
                variant="smText"
                sx={{
                  alignSelf: 'flex-start',
                }}
              >
                Primary Trustee
              </Typography>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="primary_trustee_name"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Full Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Please enter the primary trustee name',
                  }}
                  control={control}
                />
                {errors.primary_trustee_name && (
                  <FormHelperErrorText>
                    {errors?.primary_trustee_name?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <Typography
                variant="smText"
                sx={{
                  alignSelf: 'flex-start',
                }}
              >
                Secondary Trustee
              </Typography>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="secondary_trustee_name"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Full Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Please enter the secondary trustee name',
                  }}
                  control={control}
                />
                {errors.secondary_trustee_name && (
                  <FormHelperErrorText>
                    {errors?.secondary_trustee_name?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <Loader open={loading} />
      </TabLayout>
    </form>
  );
};
export default React.memo(TrusteesForm);
