/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';

const ChildrenIcon = (props: any) => {
  return (
    <svg
      width="340"
      height="266"
      viewBox="0 0 340 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_7081_32199)">
        <path
          d="M26.0747 253.734C22.9364 253.06 22.0271 248.991 24.5789 247.059C27.5119 244.805 32.0874 242.726 38.8333 242.99C52.9704 243.517 94.3259 242.755 106.029 242.755C117.702 242.755 145.448 247.41 157.298 247.205C169.176 247 210.297 248.874 225.197 248.523C240.097 248.201 290.574 252.036 289.841 253.617C289.107 255.198 329.642 254.993 338.118 258.037C346.594 261.082 293.419 266.732 277.727 265.942C262.006 265.122 171.552 262.517 136.063 261.404C103.33 260.379 52.0025 259.384 26.0747 253.734Z"
          fill="#DEDEDE"
        />
        <path
          d="M249.102 132.21C249.102 132.21 245.788 133.966 245.113 132.678C244.439 131.39 245.553 128.755 246.521 128.433C247.46 128.141 250.07 128.667 249.102 132.21Z"
          fill="#FFA982"
        />
        <path
          d="M153.398 123.983C153.398 123.983 149.115 125.681 143.044 124.569C136.943 123.485 122.982 116.079 129.963 115.639C136.914 115.2 149.702 118.831 153.398 123.983Z"
          fill="#2E2E47"
        />
        <path
          d="M179.356 215.675C178.769 217.109 173.167 218.895 172.023 220.681C172.023 220.652 171.994 220.623 171.994 220.623C171.29 220.037 163.664 211.254 163.664 211.254L171.231 203.643L176.775 211.869C176.775 211.869 179.942 214.24 179.356 215.675Z"
          fill="#FFD3B5"
        />
        <path
          d="M174.311 231.836C175.191 230.958 177.655 229.084 179.092 225.073C180.236 221.823 181.351 214.622 181.732 211.958C183.345 212.426 184.929 213.041 185.633 213.421C187.07 214.182 183.902 231.016 181.615 235.32C180.236 237.896 176.159 241.468 171.73 240.999C170.176 240.823 168.709 238.891 168.445 236.52C169.795 233.856 173.549 232.597 174.311 231.836Z"
          fill="#EAE6E4"
        />
        <path
          d="M177.685 235.612C177.069 236.227 175.661 237.954 172.229 238.891C170.528 239.359 168.299 237.603 168.328 235.876C168.328 235.349 168.416 234.822 168.475 234.412C168.974 231.162 172.523 221.677 172.024 220.681C170.235 219.042 173.96 216.436 175.397 215.47C177.773 213.86 178.77 217.08 179.357 215.646C179.943 214.211 176.776 211.84 176.776 211.84C176.776 211.84 176.775 211.84 177.685 211.284C178.154 210.991 181.468 211.869 183.257 212.396C183.932 214.856 184.284 214.592 182.26 223.814C180.852 230.079 178.565 234.763 177.685 235.612Z"
          fill="#2E2E47"
        />
        <path
          d="M171.205 228.322C171.146 228.322 171.088 228.293 171.058 228.263C171 228.176 171 228.059 171.088 228L175.927 224.194C176.015 224.136 176.133 224.136 176.191 224.223C176.25 224.311 176.25 224.428 176.162 224.487L171.322 228.293C171.293 228.322 171.264 228.322 171.205 228.322Z"
          fill="white"
        />
        <path
          d="M175.928 226.741H175.899L172.496 225.951C172.379 225.921 172.32 225.833 172.35 225.716C172.379 225.599 172.467 225.541 172.584 225.57L175.987 226.36C176.104 226.39 176.163 226.477 176.133 226.595C176.104 226.682 176.016 226.741 175.928 226.741Z"
          fill="white"
        />
        <path
          d="M173.227 224.955C173.168 224.955 173.109 224.926 173.08 224.896C173.021 224.809 173.021 224.692 173.109 224.633C174.605 223.316 176.482 221.149 176.512 221.12C176.57 221.032 176.717 221.032 176.776 221.091C176.864 221.149 176.864 221.296 176.805 221.354C176.776 221.383 174.869 223.55 173.373 224.896C173.315 224.926 173.285 224.955 173.227 224.955Z"
          fill="white"
        />
        <path
          d="M176.63 223.725H176.6L173.257 222.935C173.139 222.906 173.081 222.818 173.11 222.701C173.139 222.584 173.227 222.525 173.345 222.554L176.688 223.345C176.806 223.374 176.864 223.462 176.835 223.579C176.776 223.667 176.718 223.725 176.63 223.725Z"
          fill="white"
        />
        <path
          d="M97.8174 165.174C97.8174 168.424 98.2573 174.221 97.9347 177.177C97.7001 179.432 97.7587 181.715 98.0814 183.969C101.953 210.786 104.915 242.053 104.915 242.053C104.915 242.053 114.77 246.005 114.858 240.355C115.064 226.185 121.457 194.099 116.471 177.265C116.735 173.401 117.263 169.888 117.498 165.935C107.203 166.228 102.54 165.321 97.8174 165.174Z"
          fill="#FFD3B5"
        />
        <path
          d="M147.619 170.825C147.678 170.796 147.355 166.229 147.385 163.506C139.906 165.145 130.667 156.187 125.915 166.024C126.854 172.025 126.707 175.743 129.845 180.544C140.023 193.748 164.924 214.621 169.382 217.813C171.171 219.071 176.744 211.87 176.744 211.87C176.744 211.87 158.383 178.876 147.619 170.825Z"
          fill="#FFD3B5"
        />
        <path
          d="M149.584 129.37C148.352 121.758 142.956 99.6841 142.956 99.6841L94.5022 103.022C94.5022 103.022 92.5664 108.847 95.4701 143.1C95.6754 145.647 95.6168 158.646 95.5874 168.98C100.486 171.585 108.815 171.205 119.843 169.068C120.665 155.484 119.99 140.495 119.99 140.495C121.163 151.005 121.75 158.265 122.747 169.068C132.279 172.23 144.187 168.951 151.637 166.345C151.725 156.626 150.288 133.644 149.584 129.37Z"
          fill="#9C6F43"
        />
        <path
          d="M112.98 247.264C111.455 247.527 114.095 245.712 111.455 244.57C109.871 243.897 106.909 243.78 104.797 243.78C104.797 243.751 104.827 243.721 104.827 243.721C104.944 242.814 105.061 234.88 105.061 234.88L115.063 232.567L114.77 243.019C114.77 243.019 114.476 247 112.98 247.264Z"
          fill="#FFD3B5"
        />
        <path
          d="M96.6142 251.714C97.8167 251.977 100.75 253.06 104.885 252.094C108.229 251.304 114.916 248.376 117.38 247.264C117.849 248.874 118.201 250.543 118.26 251.304C118.377 252.943 106.498 256.017 103.624 256.486C100.75 256.954 87.6685 256.31 87.5805 253.734C87.5219 252.153 87.7858 250.952 88.6951 249.928C91.6574 249.664 95.5583 251.479 96.6142 251.714Z"
          fill="white"
        />
        <path
          d="M96.614 251.713C95.5581 251.479 91.6572 249.664 88.6948 249.928C89.2814 249.284 90.1027 248.727 91.3052 248.2C94.2969 246.854 104.24 244.746 104.797 243.78C106.938 243.78 109.901 243.897 111.455 244.57C114.095 245.712 111.455 247.527 112.98 247.264C114.505 247 114.799 243.019 114.799 243.019C114.799 243.019 114.799 243.019 115.767 243.487C116.265 243.721 116.91 245.448 117.438 247.234C114.945 248.347 108.287 251.304 104.944 252.065C100.75 253.06 97.8165 252.006 96.614 251.713Z"
          fill="#2E2E47"
        />
        <path
          d="M101.19 247.674L95.5583 247.615C95.441 247.615 95.353 247.528 95.353 247.41C95.353 247.293 95.441 247.206 95.5583 247.206L101.19 247.264C101.307 247.264 101.395 247.352 101.395 247.469C101.395 247.586 101.307 247.674 101.19 247.674Z"
          fill="white"
        />
        <path
          d="M99.6029 249.255C99.5442 249.255 99.4855 249.226 99.4269 249.167L97.8137 246.591C97.7551 246.503 97.7844 246.386 97.8724 246.327C97.9604 246.269 98.0777 246.298 98.1364 246.386L99.7495 248.962C99.8082 249.05 99.7788 249.167 99.6908 249.226C99.6615 249.226 99.6322 249.255 99.6029 249.255Z"
          fill="white"
        />
        <path
          d="M99.1365 246.327C99.0485 246.327 98.9605 246.239 98.9312 246.151C98.9312 246.034 99.0191 245.946 99.1071 245.946C100.955 245.858 103.36 245.858 103.389 245.858C103.507 245.858 103.595 245.946 103.595 246.063C103.595 246.18 103.507 246.268 103.389 246.268C103.36 246.239 100.984 246.239 99.1365 246.327Z"
          fill="white"
        />
        <path
          d="M101.454 247.117C101.366 247.117 101.278 247.059 101.278 246.942L101.014 245.097C100.985 244.98 101.073 244.892 101.19 244.892C101.307 244.863 101.395 244.951 101.395 245.068L101.659 246.912C101.659 247 101.571 247.117 101.454 247.117Z"
          fill="white"
        />
        <path
          d="M102.922 168.834C102.922 168.834 104.858 160.636 105.562 159.963C106.295 159.29 115.681 153.552 115.681 153.552C115.681 153.552 106.676 152.966 102.775 154.723C98.8746 156.479 96.8215 161.515 96.7628 163.037C96.7042 164.032 96.1762 167.194 95.7949 169.156C99.9598 171.264 106.53 171.293 115.065 169.975C117.353 166.111 119.435 159.173 119.435 159.173L111.311 163.154L102.922 168.834Z"
          fill="#7A5534"
        />
        <path
          d="M100.78 125.33L115.299 123.046C115.299 123.046 104.212 117.513 103.068 117.338C101.924 117.133 96.674 117.425 94.5915 118.479C94.3862 118.596 94.1809 118.831 93.9756 119.211C94.1516 124.627 94.5329 131.741 95.2954 141.08C96.586 137.187 98.0231 132.854 98.3458 132.21C99.0204 130.98 100.78 125.33 100.78 125.33Z"
          fill="#7A5534"
        />
        <path
          d="M150.787 143.656C148.382 145.266 145.948 146.906 145.361 147.374C144.129 148.34 141.49 153.873 141.49 153.873C141.49 153.873 146.359 152.029 151.169 150.302C151.051 148.048 150.905 145.823 150.787 143.656Z"
          fill="#7A5534"
        />
        <path
          d="M137.061 134.669C134.861 136.045 131.517 138.387 131.517 138.387H141.46C141.46 138.387 147.766 139.938 150.523 140.173C150.171 135.079 149.819 130.951 149.555 129.37C149.438 128.579 149.262 127.642 149.057 126.559C144.217 123.193 130.227 116.02 125.827 115.347C121.398 114.644 121.398 114.644 121.398 114.644C121.398 114.644 127.616 124.715 134.392 128.433C141.167 132.151 145.449 134.23 145.449 134.23C145.449 134.23 139.26 133.293 137.061 134.669Z"
          fill="#7A5534"
        />
        <path
          d="M146.944 122.256C147.354 122.402 147.824 122.549 148.264 122.695C147.912 120.997 147.501 119.153 147.061 117.279C141.312 117.25 119.022 117.133 112.305 117.279C104.562 117.455 95.7041 120.295 95.7041 120.295C95.7041 120.295 101.863 124.071 117.526 123.134C133.247 122.168 145.595 121.788 146.944 122.256Z"
          fill="#2E2E47"
        />
        <path
          d="M81.8306 63.8507C81.8306 63.2944 91.7735 48.4516 94.4132 45.524C96.7889 42.8599 106.732 35.9215 110.046 33.2574C112.393 35.8337 116.264 39.9323 117.379 40.1372C118.963 40.4593 118.523 40.957 119.637 39.581C120.488 38.5564 124.066 32.2913 125.738 29.3345C128.319 31.3252 138.379 37.7074 143.043 43.5918C153.631 56.9708 157.502 71.1696 157.502 71.1696C157.502 71.1696 154.775 73.5995 151.46 73.8923C146.68 74.3021 144.216 79.1033 144.45 80.1866C146.416 89.4377 145.741 89.7012 145.741 89.7012C145.741 89.7012 153.249 110.399 153.425 118.538V123.925C153.425 123.925 149.437 118.948 138.584 118.479C127.732 117.982 119.168 120.909 109.606 121.261C100.045 121.583 91.2455 119.709 89.6031 118.069C87.9606 116.43 98.5781 73.1018 98.5781 73.1018C98.5781 73.1018 91.7442 72.1064 89.1631 71.0525C83.2677 68.6519 81.8306 64.4362 81.8306 63.8507Z"
          fill="#679D84"
        />
        <path
          d="M117.35 40.1665C116.264 39.9323 112.393 35.863 110.017 33.2867C110.369 33.0232 112.393 32.1742 112.539 31.9693C116.616 26.8753 114.563 25.1773 115.854 23.7135C117.144 22.2498 121.632 20.5518 121.632 20.5518C121.632 20.5518 121.515 26.1434 125.005 28.8368C125.122 28.9246 125.386 29.1296 125.709 29.3931C124.037 32.3499 120.459 38.6149 119.608 39.6396C118.493 40.957 118.933 40.4886 117.35 40.1665Z"
          fill="#FFD3B5"
        />
        <path
          d="M80.6298 37.5903C80.6298 37.5903 73.5906 61.7429 73.004 65.578C72.4174 69.4131 72.9453 80.4794 77.9901 85.3977C81.1578 88.4717 88.5196 83.1142 90.6021 78.1081C93.8577 70.2329 91.1007 56.9417 90.6314 54.3361C89.5462 48.5688 87.1411 40.9571 86.4665 39.5225C85.7626 38.088 80.6298 37.5903 80.6298 37.5903Z"
          fill="#FFD3B5"
        />
        <path
          d="M124.125 27.3145C124.125 27.3145 118.64 33.6673 118.2 33.4038C117.76 33.1403 111.894 30.5641 111.689 30.5641C111.484 30.5641 107.788 34.4577 107.231 35.1311C106.703 35.8044 117.35 41.0448 119.022 40.9863C120.723 40.9277 125.122 39.2004 125.21 38.9662C125.298 38.732 129.17 35.541 130.108 34.6334C131.047 33.7259 130.108 31.9108 129.903 31.8229C129.698 31.7058 124.125 27.3145 124.125 27.3145Z"
          fill="#366861"
        />
        <path
          d="M100.603 41.7767L105.53 41.0156C106.527 40.8692 107.437 41.5425 107.583 42.5379L109.49 55.068C109.636 56.0633 108.962 56.9709 107.965 57.1173L103.037 57.8784C102.04 58.0248 101.131 57.3515 100.984 56.3561L99.0775 43.8261C98.9308 42.8307 99.6347 41.9231 100.603 41.7767Z"
          fill="#2E2E47"
        />
        <path
          d="M119.523 114.849C119.435 114.849 119.347 114.79 119.318 114.673C117.998 103.695 115.27 72.1062 115.446 67.305C115.505 65.8998 115.798 62.8258 116.297 58.1417C116.385 57.3805 116.678 56.5022 116.971 55.6532C117.265 54.8042 117.558 53.9552 117.646 53.1941C117.734 52.3743 117.617 51.6717 117.499 50.9691C117.382 50.2958 117.265 49.5639 117.353 48.8027C117.881 44.4699 118.056 36.097 118.056 36.0384C118.056 35.9213 118.144 35.8628 118.262 35.8628C118.379 35.8628 118.467 35.9506 118.438 36.0677C118.438 36.1556 118.262 44.5284 117.734 48.8905C117.646 49.5931 117.763 50.2665 117.881 50.9398C117.998 51.6717 118.144 52.4329 118.027 53.2819C117.939 54.0723 117.617 54.9506 117.323 55.8289C117.03 56.6486 116.737 57.5269 116.649 58.2588C116.121 62.9429 115.857 66.0169 115.798 67.3928C115.622 72.1941 118.35 103.753 119.67 114.703C119.728 114.761 119.64 114.849 119.523 114.849Z"
          fill="#2E2E47"
        />
        <path
          d="M99.7263 67.7443C99.609 67.7443 99.521 67.6565 99.521 67.5394C99.521 67.4516 99.9609 59.957 100.694 57.0001C101.398 54.014 106.766 39.8152 107 39.2004C107.029 39.1126 107.147 39.054 107.235 39.0833C107.323 39.1126 107.381 39.2297 107.352 39.3175C107.293 39.4639 101.779 54.1311 101.075 57.0586C100.372 60.0155 99.9316 67.4516 99.9316 67.5394C99.9023 67.6858 99.8436 67.7443 99.7263 67.7443Z"
          fill="#2E2E47"
        />
        <path
          d="M129.112 92.2481C122.366 88.3837 115.502 73.3652 115.502 73.3652C115.502 73.3652 125.328 81.7674 125.709 82.1187C126.003 82.4115 137.647 84.3437 142.691 85.1927C144.099 88.2959 145.742 89.7597 145.742 89.7597C145.742 89.7597 146.24 91.1064 146.974 93.2435C143.425 93.6534 134.684 95.4099 129.112 92.2481Z"
          fill="#2E2E47"
        />
        <path
          d="M113.156 55.5366C113.244 55.6537 113.39 55.7708 113.537 55.8879C114.475 56.532 128.965 67.6861 131.692 69.8525L131.927 67.042C131.927 67.042 144.803 67.042 150.669 69.4134C150.815 71.1406 150.845 72.8386 150.757 74.4781C150.258 85.3979 147.296 94.3563 134.185 91.985C127.117 90.6969 118.816 78.8694 114.241 71.4627C109.695 64.0559 108.58 58.8448 108.58 58.8448L113.156 55.5366Z"
          fill="#FFD3B5"
        />
        <path
          d="M99.311 47.1047C99.487 46.3142 103.564 47.9244 104.238 48.5099C104.913 49.0662 107.523 49.9737 108.169 49.9737C108.814 49.9737 108.345 47.3389 108.345 47.3389C108.345 47.3389 109.547 48.2757 110.046 48.8612C110.75 49.7102 110.867 50.4421 111.63 51.6717C112.686 53.399 114.064 56.5022 114.621 57.2048L110.046 60.2495C110.046 60.2495 101.276 55.8874 100.426 55.0384C99.575 54.1894 99.575 52.1401 99.8389 51.9059C100.103 51.6717 99.487 51.1448 99.487 51.1448C99.487 51.1448 99.1643 48.7734 99.9856 48.8905C100.777 49.0369 103.007 50.53 103.329 50.2665C103.623 50.003 99.1057 47.9244 99.311 47.1047Z"
          fill="#FFD3B5"
        />
        <path
          d="M129.729 68.4765C129.612 68.4765 129.524 68.3887 129.524 68.2716C129.524 68.0959 129.495 49.8864 131.078 42.8016C131.87 39.23 133.249 35.5998 133.249 35.5412C133.278 35.4534 133.396 35.3948 133.484 35.4241C133.572 35.4534 133.63 35.5705 133.601 35.6583C133.601 35.6876 132.252 39.2885 131.46 42.8602C129.905 49.8864 129.935 68.0667 129.935 68.2423C129.905 68.3887 129.817 68.4765 129.729 68.4765Z"
          fill="#2E2E47"
        />
        <path
          d="M141.87 79.9815C141.812 79.9815 141.724 79.9522 141.694 79.8936C141.665 79.8351 137.881 73.0724 131.605 69.9984C131.517 69.9399 131.458 69.8228 131.517 69.7349C131.575 69.6471 131.693 69.5886 131.781 69.6471C138.175 72.7503 141.988 79.6302 142.046 79.6887C142.105 79.7765 142.076 79.8936 141.958 79.9522C141.929 79.9815 141.9 79.9815 141.87 79.9815Z"
          fill="#2E2E47"
        />
        <path
          d="M74.7629 77.6101C75.4668 81.5331 76.5813 88.5007 87.1988 88.5885C96.7898 88.6764 99.7228 69.8228 99.7228 69.8228C99.7228 69.8228 94.4434 59.4591 87.6975 60.2496C80.9515 61.04 73.8243 72.2819 74.7629 77.6101Z"
          fill="#FFD3B5"
        />
        <path
          d="M88.7532 81.182C88.6945 81.182 88.6652 81.1527 88.6065 81.1234C88.5185 81.0356 88.5185 80.9185 88.6065 80.8599C88.6359 80.8307 90.2197 79.1327 91.4809 74.2729C92.7421 69.3838 91.4809 61.0988 91.4809 61.011C91.4515 60.8939 91.5395 60.806 91.6275 60.806C91.7448 60.7768 91.8328 60.8646 91.8328 60.9524C91.8328 61.0402 93.094 69.3838 91.8328 74.3607C90.5423 79.3376 88.9292 81.0356 88.8705 81.1234C88.8412 81.182 88.8118 81.182 88.7532 81.182Z"
          fill="#2E2E47"
        />
        <path
          d="M163.721 43.2111C163.721 43.2111 163.868 45.9923 165.1 46.4607C166.302 46.9291 168.238 44.9969 167.886 43.8552C167.505 42.7427 164.689 42.1572 163.721 43.2111Z"
          fill="#FFA982"
        />
        <path
          d="M167.973 46.4606C167.973 46.4606 169.117 46.4606 169.733 44.3821C170.437 42.0985 170.466 40.2249 170.349 39.4052C170.232 38.6147 171.199 36.2434 171.141 35.6871C171.082 35.1016 169.88 35.2773 169.293 36.1848C168.706 37.0924 168.413 38.966 168.09 39.9907C167.768 41.0153 166.037 46.1386 167.973 46.4606Z"
          fill="#FFA982"
        />
        <path
          d="M188.799 43.7673C188.799 43.7673 183.783 48.5392 182.287 50.2958C173.254 60.8058 167.241 70.9938 167.241 70.9938C167.241 70.9938 170.937 42.0693 168.268 41.1617C165.599 40.2249 145.478 78.9568 156.096 87.5932C166.713 96.2295 185.69 69.1494 188.916 59.5762C192.084 49.9737 188.799 43.7673 188.799 43.7673Z"
          fill="#FFA982"
        />
        <path
          d="M238.368 129.136C238.368 129.136 238.72 131.273 239.57 132.444C240.421 133.615 241.565 134.493 242.093 135.196C242.621 135.898 243.266 133.439 242.005 132.122C240.773 130.804 238.456 127.467 238.368 129.136Z"
          fill="#FFA982"
        />
        <path
          d="M237.633 123.924C237.633 123.924 238.103 128.433 238.367 129.136C238.66 129.838 244.497 135.488 245.582 134.757C246.667 134.054 246.315 133.146 246.403 132.883C246.491 132.619 246.96 131.331 246.99 131.185C247.019 131.038 247.048 130.512 247.107 130.16C247.166 129.809 247.723 128.931 247.694 128.521C247.635 128.082 242.003 121.729 241.505 121.29C241.006 120.851 237.956 122.753 237.633 123.924Z"
          fill="#FFA982"
        />
        <path
          d="M199.271 243.575C199.271 243.575 198.245 236.929 197.453 230.518C196.895 226.185 191.587 224.311 190.12 226.565C188.654 228.82 191 241.467 189.152 243.165C187.304 244.892 196.191 247.615 199.271 243.575Z"
          fill="white"
        />
        <path
          d="M171.087 248.201C171.85 246.737 175.076 246.503 178.185 245.654C181.324 244.805 183.084 242.697 184.785 241.116C186.486 239.535 188.686 239.857 188.686 239.857C188.686 239.857 189.302 240.501 190.944 241.321C192.557 242.17 191.883 243.224 194.434 242.697C196.986 242.17 198.365 242.697 198.365 242.697C198.365 242.697 198.365 242.697 199.45 243.019C199.978 243.165 199.86 244.57 199.567 246.122C197.661 246.942 193.584 248.581 190.915 248.845C187.366 249.167 175.487 249.899 173.698 249.372C172.877 249.138 171.879 248.728 171 248.376C171.029 248.318 171.058 248.259 171.087 248.201Z"
          fill="#366861"
        />
        <path
          d="M173.696 249.372C175.456 249.899 187.364 249.167 190.913 248.845C193.582 248.61 197.688 246.942 199.565 246.122C199.214 247.82 198.627 249.664 198.334 250.104C197.776 250.953 194.11 251.362 192.204 251.684C190.297 252.006 172.552 252.006 171.76 251.772C170.998 251.567 170.382 249.811 171.027 248.376C171.878 248.728 172.875 249.108 173.696 249.372Z"
          fill="white"
        />
        <path
          d="M179.884 74.9167C179.62 69.325 179.297 62.9722 179.297 62.9722C179.297 62.9722 173.138 77.5222 173.372 82.3235C175.66 80.0985 177.889 77.5515 179.884 74.9167Z"
          fill="#2E2E47"
        />
        <path
          d="M257.517 227.649C257.517 227.649 253.675 222.115 250.126 216.729C247.72 213.069 240.095 215.938 239.772 218.632C239.449 221.296 249.158 229.405 248.248 231.747C247.339 234.06 256.52 232.625 257.517 227.649Z"
          fill="white"
        />
        <path
          d="M244.232 249.928C243.469 248.464 245.17 245.712 246.314 242.697C247.458 239.681 246.754 236.988 246.461 234.704C246.167 232.421 247.722 230.781 247.722 230.781C247.722 230.781 248.602 230.635 250.215 229.815C251.858 228.996 252.297 230.167 253.383 227.766C254.439 225.395 255.67 224.575 255.67 224.575C255.67 224.575 255.67 224.575 256.55 223.872C256.961 223.55 258.076 224.458 259.131 225.6C258.691 227.62 257.665 231.894 256.286 234.207C254.468 237.281 248.162 247.352 246.695 248.493C246.021 249.02 245.112 249.606 244.32 250.103C244.29 250.045 244.261 249.986 244.232 249.928Z"
          fill="#366861"
        />
        <path
          d="M246.695 248.493C248.161 247.352 254.467 237.281 256.286 234.207C257.635 231.894 258.691 227.62 259.131 225.6C260.304 226.858 261.477 228.41 261.653 228.908C262.034 229.845 260.216 233.065 259.365 234.792C258.515 236.519 248.161 250.923 247.545 251.45C246.929 251.948 245.169 251.421 244.348 250.074C245.111 249.606 246.02 249.02 246.695 248.493Z"
          fill="#EAE6E4"
        />
        <path
          d="M229.538 173.02C229.538 173.02 225.725 132.561 222.645 119.592C220.387 110.048 215.254 104.544 215.254 104.544L177.624 103.666C177.624 103.666 173.253 123.017 173.253 136.689C173.253 147.257 177.712 199.924 178.914 223.462C179.266 230.371 177.741 235.085 177.741 235.085C177.741 235.085 187.097 238.803 193.814 238.803C200.765 238.803 207.042 234.031 207.042 234.031L198.096 153.025C199.416 161.895 201.909 173.927 206.426 183.764C217.454 207.741 232.207 225.629 235.932 232.83C238.777 238.364 257.167 223.784 257.402 222.847C257.519 222.379 242.707 196.294 229.538 173.02Z"
          fill="#82A7AF"
        />
        <path
          d="M218.921 109.901C217.015 106.447 215.255 104.544 215.255 104.544L209.007 104.397C199.064 105.656 184.341 107.471 178.827 107.998C178.827 107.998 186.394 112.038 197.129 111.98C204.549 111.951 213.876 110.662 218.921 109.901Z"
          fill="#2E2E47"
        />
        <path
          d="M163.254 32.7015C163.254 32.7015 162.902 32.4087 162.99 31.8818C163.078 31.3841 163.811 30.8278 164.251 30.213C164.691 29.5982 164.808 28.8956 165.6 28.6321C168.005 27.8124 169.882 28.8956 170.088 29.6568C170.322 30.418 170.234 31.4133 170.704 31.8232C171.173 32.2331 171.114 33.1699 170.733 33.4919C170.352 33.814 164.925 34.3702 163.254 32.7015Z"
          fill="#FFC0DD"
        />
        <path
          d="M163.987 30.0668C163.987 30.0668 163.664 29.774 163.752 29.247C163.84 28.7494 164.397 28.1638 164.72 27.5198C166.216 24.4165 168.621 25.4412 169.853 26.4658C170.645 27.1392 170.234 28.4566 170.557 28.9543C170.997 29.6569 171.055 30.5352 170.703 30.8572C170.322 31.15 165.483 31.7355 163.987 30.0668Z"
          fill="#84CECA"
        />
        <path
          d="M163.253 32.7015L165.805 49.1838L170.997 33.5798C171.085 33.3163 170.909 33.0528 170.645 33.0235C169.677 32.9064 167.594 32.7601 166.48 33.4334C166.48 33.4334 165.072 31.9696 163.253 32.7015Z"
          fill="#E5A251"
        />
        <path
          d="M82.1838 21.0493C82.1838 21.0493 81.7145 20.6687 81.8318 19.9661C81.9491 19.2928 82.917 18.5902 83.4743 17.7997C84.0316 16.98 84.2076 16.0724 85.2341 15.7211C88.3724 14.6672 90.8362 16.0432 91.1295 17.0385C91.4228 18.0339 91.3348 19.3513 91.9507 19.8783C92.5666 20.376 92.4786 21.6641 91.98 22.074C91.4521 22.4838 84.3835 23.245 82.1838 21.0493Z"
          fill="#FFEA8F"
        />
        <path
          d="M82.1836 21.0491L85.5272 42.6546L92.3318 22.1908C92.4491 21.8688 92.2145 21.5175 91.8625 21.4589C90.6013 21.3126 87.8443 21.1076 86.3778 21.9859C86.4071 22.0152 84.5593 20.1123 82.1836 21.0491Z"
          fill="#E5A251"
        />
        <path
          d="M191.967 21.0201C191.967 21.0201 190.53 22.3961 190.032 24.8845C189.533 27.3729 185.192 41.2497 189.093 45.2019C192.994 49.1249 216.781 53.6626 213.466 45.4361C213.466 45.4361 210.885 44.7335 209.536 43.1526C208.187 41.5717 205.958 32.0278 204.139 27.6657C202.35 23.3036 194.578 16.7458 191.967 21.0201Z"
          fill="#9C6F43"
        />
        <path
          d="M221.384 105.685C220.416 100.796 219.038 93.1552 218.598 89.6128C216.691 74.4772 218.92 44.9087 198.125 41.2199C195.456 40.7515 191.731 42.0689 189.502 43.2985C178.65 49.2415 175.834 82.8794 175.16 91.3694C174.955 94.0042 171.787 101.06 171.582 104.69C180.938 111.57 203.522 111.072 221.384 105.685Z"
          fill="#FFC97D"
        />
        <path
          d="M197.482 88.1795C197.687 86.9792 195.223 87.6818 195.223 87.6818C195.223 87.6818 198.831 84.6664 196.954 83.8174C195.076 82.9684 190.677 87.9453 189.122 89.6433C187.568 91.3705 188.36 92.6587 188.36 92.6587C190.325 94.708 197.276 89.3798 197.482 88.1795Z"
          fill="#2E2E47"
        />
        <path
          d="M188.156 69.6472C188.214 69.0324 186.455 69.5593 186.455 69.5593C186.455 69.5593 189.71 67.6857 190.062 66.4561C190.385 65.2558 189.3 64.0555 186.308 65.7827C183.287 67.51 183.727 69.9985 183.727 69.9985C183.375 71.9014 188.097 70.2327 188.156 69.6472Z"
          fill="#2E2E47"
        />
        <path
          d="M205.197 54.3653C205.197 53.2528 203.056 52.5502 201.678 52.9894C201.678 52.9894 198.745 53.4285 198.305 55.7413C197.894 58.0541 205.197 55.4778 205.197 54.3653Z"
          fill="#2E2E47"
        />
        <path
          d="M203.143 109.287C203.319 109.141 203.407 108.995 203.407 108.848C203.407 107.736 201.266 107.033 199.887 107.472C199.887 107.472 197.394 107.853 196.661 109.727C198.773 109.668 200.943 109.522 203.143 109.287Z"
          fill="#2E2E47"
        />
        <path
          d="M207.778 69.1206C204.259 73.9218 206.136 77.4349 210.447 76.0589C212.354 75.4441 214.876 74.1853 217.223 72.6629C216.9 69.4133 216.489 66.1051 215.844 62.9434C212.852 64.6121 209.479 66.8078 207.778 69.1206Z"
          fill="#2E2E47"
        />
        <path
          d="M181.087 56.649C184.578 55.8879 191.294 49.4472 191.06 48.2469C190.766 46.7538 188.273 48.2469 188.273 48.2469C188.273 48.2469 192.819 44.4117 190.297 44.5874C188.889 44.7045 186.895 46.0805 185.399 47.31C183.698 49.7985 182.29 53.0481 181.087 56.649Z"
          fill="#2E2E47"
        />
        <path
          d="M201.734 42.2156C201.089 39.7272 202.027 34.1648 202.027 34.1648C202.027 34.1648 199.476 33.5792 197.481 35.3943C196.425 36.3604 195.545 39.1124 195.281 40.5762C195.281 40.5469 190.53 52.6963 201.734 42.2156Z"
          fill="#FFA982"
        />
        <path
          d="M196.688 36.4196C196.307 37.093 195.984 37.9127 195.75 38.7031C196.307 38.6446 197.04 38.4397 197.832 38.0884C199.885 37.2101 200.794 35.5999 200.882 35.3364L196.688 36.4196Z"
          fill="#2E2E47"
        />
        <path
          d="M204.196 31.2957C204.196 31.2957 205.076 31.4421 205.897 30.7395C206.543 30.1832 206.631 28.3096 206.044 27.6948C205.457 27.08 204.666 27.08 204.49 27.0215C204.314 26.9629 204.959 23.7133 203.991 21.7225C203.023 19.7318 201.117 17.7996 197.92 17.9167C194.723 18.0338 193.051 19.7025 191.936 21.0199C190.851 22.3373 190.646 25.1185 190.499 26.4359C190.352 27.7534 190.03 28.1339 189.737 28.8366C189.121 30.476 188.857 31.6178 189.297 33.4914C189.737 35.3651 191.643 37.2973 194.752 37.3558C197.861 37.4144 202.935 36.1555 204.196 31.2957Z"
          fill="#FFA982"
        />
        <path
          d="M215.255 90.5796C216.78 96.2298 219.332 99.9478 220.593 101.558C219.772 97.1959 218.922 92.2483 218.599 89.6135C218.423 88.3253 218.306 86.9494 218.188 85.5149L211.472 70.877C211.472 70.877 213.144 82.7922 215.255 90.5796Z"
          fill="#2E2E47"
        />
        <path
          d="M194.164 30.5053C193.548 30.5053 192.845 30.2711 192.581 29.7735C192.405 29.4807 192.346 28.9537 192.962 28.1633C193.519 27.4607 193.842 25.9676 193.607 25.1186C193.519 24.8551 193.402 24.6795 193.255 24.6209C192.903 24.4745 192.346 24.3867 191.261 24.9429C191.173 25.0015 191.055 24.9429 190.997 24.8551C190.938 24.7673 190.997 24.6502 191.085 24.5916C192.053 24.0939 192.757 23.9768 193.402 24.2403C193.695 24.3574 193.9 24.6209 193.988 25.0015C194.252 25.9383 193.9 27.5778 193.255 28.3682C192.991 28.6902 192.727 29.1587 192.903 29.51C193.079 29.8613 193.636 30.0662 194.106 30.0662C194.516 30.0955 194.692 29.9784 194.722 29.9491C194.751 29.8613 194.868 29.8027 194.956 29.832C195.044 29.8613 195.103 29.9784 195.074 30.0662C195.015 30.2711 194.78 30.4175 194.399 30.4468C194.34 30.5053 194.252 30.5053 194.164 30.5053Z"
          fill="#2E2E47"
        />
        <path
          d="M199.151 26.0847C199.121 26.0847 199.063 26.0847 199.033 26.0555C199.004 26.0555 197.977 25.3236 196.159 25.4407C196.042 25.4407 195.954 25.3528 195.954 25.265C195.954 25.1479 196.042 25.0601 196.13 25.0601C198.124 24.943 199.209 25.7041 199.268 25.7627C199.356 25.8213 199.385 25.9384 199.297 26.0262C199.268 26.0555 199.209 26.0847 199.151 26.0847Z"
          fill="#2E2E47"
        />
        <path
          d="M197.246 27.0214C196.894 26.9336 196.542 27.08 196.396 27.3727C196.279 27.6362 196.073 28.3681 196.396 28.573C197.657 29.422 197.716 27.4898 197.686 27.3727C197.628 27.1971 197.51 27.08 197.246 27.0214Z"
          fill="#2E2E47"
        />
        <path
          d="M198.858 27.9289C198.828 27.9289 198.799 27.9289 198.77 27.8996C198.066 27.4897 196.893 27.0213 196.541 27.2262C196.453 27.2848 196.335 27.2555 196.277 27.1677C196.218 27.0799 196.247 26.9628 196.335 26.9042C197.01 26.4943 198.623 27.3726 198.946 27.5483C199.034 27.6068 199.063 27.7239 199.004 27.8118C199.004 27.8996 198.946 27.9289 198.858 27.9289Z"
          fill="#2E2E47"
        />
        <path
          d="M192.2 26.7873C192.141 27.0215 192.171 27.6655 191.877 27.7534C190.675 28.0461 191.115 26.6116 191.203 26.5238C191.584 25.8797 192.317 26.3774 192.2 26.7873Z"
          fill="#2E2E47"
        />
        <path
          d="M193.288 27.1096C193.258 27.1096 193.2 27.1096 193.17 27.0803C191.88 26.1728 190.971 26.3777 190.941 26.3777C190.853 26.407 190.736 26.3484 190.707 26.2313C190.677 26.1142 190.736 26.0264 190.853 25.9971C190.883 25.9971 191.939 25.7629 193.376 26.7583C193.464 26.8168 193.493 26.934 193.434 27.0218C193.405 27.0803 193.346 27.1096 193.288 27.1096Z"
          fill="#2E2E47"
        />
        <path
          d="M196.805 28.9248C196.805 28.9248 199.005 28.7784 199.474 29.5688C199.943 30.3593 197.479 30.5349 196.365 30.1251C195.221 29.7152 196.248 28.9248 196.805 28.9248Z"
          fill="#F2836D"
        />
        <path
          d="M208.157 45.2021C208.157 45.2021 212.205 49.1837 216.282 57.1467C227.955 80.0404 243.442 120.734 242.65 123.661C241.623 127.584 237.957 126.12 237.957 126.12C237.957 126.12 223.174 108.584 215.607 85.8077C210.474 70.4965 207.923 53.3408 208.157 45.2021Z"
          fill="#FFA982"
        />
        <path
          d="M190.236 28.1341C190.734 28.0463 192.113 27.8706 192.113 28.8074C192.142 29.6271 190.5 29.715 189.737 29.3051C188.945 28.8952 189.414 28.2805 190.236 28.1341Z"
          fill="#F2836D"
        />
        <path
          d="M194.633 31.9693C192.99 31.9693 191.641 31.4424 191.553 31.4131C191.465 31.3838 191.407 31.2667 191.436 31.1496C191.465 31.0618 191.582 31.0032 191.7 31.0325C191.729 31.0325 193.753 31.8522 195.806 31.4717C195.923 31.4424 196.011 31.5302 196.041 31.618C196.07 31.7351 195.982 31.823 195.894 31.8522C195.454 31.9401 195.043 31.9693 194.633 31.9693Z"
          fill="#2E2E47"
        />
        <path
          d="M195.222 21.0787C195.222 21.0787 198.947 22.7474 200.003 25.675C201.059 28.6319 201.206 38.8491 203.2 42.7135C205.195 46.5779 211.882 48.2467 211.882 48.2467L213.436 45.4069C213.436 45.4069 209.037 40.5764 208.832 38.4685C208.597 36.3607 208.45 28.7783 208.45 26.3776C208.45 23.977 205.869 18.239 204.373 17.1558C202.907 16.0433 194.9 16.1604 195.222 21.0787Z"
          fill="#9C6F43"
        />
        <path
          d="M195.223 21.079C195.223 21.079 191.381 23.0697 190.53 24.797C189.68 26.5243 189.973 22.9819 190.853 20.9033C191.703 18.7955 193.493 16.7169 195.839 16.0435C198.185 15.3702 200.121 15.0774 200.913 16.3363C201.764 17.5951 195.223 21.079 195.223 21.079Z"
          fill="#9C6F43"
        />
        <path
          d="M167.064 40.8688C166.947 40.4882 167.24 40.1369 166.829 37.9412C166.741 37.385 165.568 37.2093 165.568 37.2093C165.568 37.2093 166.712 36.3603 166.624 35.9212C166.448 34.7501 163.046 36.1554 162.635 36.6238C162.195 37.1215 162.488 37.6192 162.547 38.2047C162.606 38.8195 162.576 40.0783 162.723 40.9859C162.84 41.8934 163.016 43.0937 163.427 44.1477C163.838 45.1723 166.683 42.7717 166.917 42.4789C167.152 42.1569 167.211 41.2786 167.064 40.8688Z"
          fill="#FFA982"
        />
        <path
          d="M198.098 153.201C198.01 153.201 197.922 153.113 197.892 153.025C197.892 152.879 197.042 137.421 195.34 132.064C193.639 126.677 191.234 111.19 191.205 111.044C191.176 110.927 191.264 110.839 191.352 110.839C191.469 110.809 191.557 110.897 191.557 110.985C191.586 111.132 193.991 126.589 195.663 131.947C197.364 137.363 198.215 152.85 198.215 152.996C198.273 153.084 198.215 153.172 198.098 153.201Z"
          fill="#2E2E47"
        />
        <path
          d="M227.014 188.829H226.985C226.897 188.829 226.809 188.77 226.78 188.653C226.574 188.068 227.483 182.945 227.982 180.251C227.542 181.13 226.897 182.359 226.252 183.618C223.759 188.302 223.289 188.39 223.084 188.448C222.996 188.478 222.908 188.448 222.82 188.39C220.503 186.575 228.451 174.308 229.361 172.932C229.419 172.844 229.537 172.815 229.625 172.874C229.713 172.932 229.742 173.049 229.683 173.137C229.654 173.166 227.454 176.533 225.548 180.076C222.351 186.019 222.556 187.629 222.996 188.068C223.612 187.746 226.105 183.208 228.217 178.875C228.246 178.787 228.363 178.758 228.451 178.787C228.539 178.817 228.598 178.905 228.569 178.992C227.982 182.096 227.132 187.043 227.102 188.273C227.894 187.307 230.446 182.71 232.03 179.783C232.088 179.695 232.206 179.666 232.294 179.695C232.382 179.754 232.411 179.871 232.382 179.958C232.382 179.988 231.179 182.183 229.947 184.35C227.571 188.595 227.22 188.829 227.014 188.829Z"
          fill="#2E2E47"
        />
        <path
          d="M78.8987 36.2436C78.8987 36.2436 79.3093 32.7306 79.8373 31.2375C80.3652 29.7444 86.9352 25.7922 87.1698 26.9047C87.4044 28.0171 83.2396 29.6273 83.7675 30.1543C84.2954 30.6812 87.2578 27.4609 87.8737 27.8415C88.4897 28.2221 88.9589 29.8615 88.9589 29.8615C88.9589 29.8615 89.4575 30.652 89.6922 31.2375C89.9268 31.823 88.871 33.843 88.343 35.219C87.8444 36.595 85.7326 40.7521 83.5035 41.162C81.2744 41.6011 78.1948 38.4393 78.8987 36.2436Z"
          fill="#FFD3B5"
        />
        <path
          d="M126.942 8.08009C126.942 8.08009 127.588 13.2619 125.241 16.4822C122.924 19.7026 121.634 18.473 121.927 16.0724C122.22 13.701 122.953 5.09395 126.942 8.08009Z"
          fill="#9C6F43"
        />
        <path
          d="M109.461 11.1248C109.461 11.1248 108.757 10.5393 108.258 11.0369C107.759 11.5346 107.437 14.5208 108.082 14.6964C108.727 14.8428 110.047 11.9738 109.461 11.1248Z"
          fill="#FFD3B5"
        />
        <path
          d="M126.179 14.5795C125.505 14.2282 124.947 14.1403 124.683 13.8476C124.595 13.7598 124.537 13.6427 124.507 13.467C124.478 13.2621 124.478 13.0279 124.566 12.6766C124.566 12.6766 125.886 8.87069 123.598 6.14804C121.34 3.42539 114.037 2.72277 111.661 5.29905C109.256 7.84604 109.314 11.3299 108.698 13.145C108.405 13.9647 106.968 18.0633 106.675 19.5564C106.117 22.279 106.323 26.1434 111.778 27.4608C117.321 28.8075 121.31 25.6165 122.337 23.977C123.363 22.3376 123.979 18.8245 123.979 18.8245C123.979 18.8245 125.505 19.2343 126.209 18.1511C126.883 17.0094 127.382 15.1943 126.179 14.5795Z"
          fill="#FFD3B5"
        />
        <path
          d="M122.308 23.9479C123.335 22.3085 123.951 18.7954 123.951 18.7954C123.951 18.7954 123.628 16.0142 123.687 15.6043C123.745 15.1652 123.1 15.3408 123.1 15.3408C123.1 15.3408 121.135 19.4687 119.815 20.2885C118.466 21.1082 116.823 18.6197 112.131 17.9171C110.195 17.6244 107.262 17.8878 107.056 18.0049C106.88 18.6197 106.704 19.176 106.646 19.5566C106.089 22.2792 106.294 26.1436 111.749 27.461C117.322 28.7784 121.282 25.5874 122.308 23.9479Z"
          fill="#BFA08A"
        />
        <path
          d="M113.3 18.2394C112.186 18.2102 111.453 17.9467 111.218 17.449C111.071 17.1562 111.13 16.7756 111.394 16.4536C111.541 16.2779 111.687 16.1316 111.834 15.9559C112.274 15.5168 112.743 14.9898 113.154 13.9359C113.476 13.1162 113.535 12.5306 113.359 12.1793C113.212 11.9159 112.89 11.7987 112.45 11.6816C111.599 11.506 109.869 11.3889 109.869 11.3889C109.752 11.3889 109.693 11.3011 109.693 11.184C109.693 11.0669 109.781 11.0083 109.898 11.0083C109.957 11.0083 111.658 11.1254 112.538 11.3011C112.978 11.3889 113.476 11.5353 113.711 11.9744C113.946 12.4135 113.887 13.0869 113.535 14.053C113.124 15.1655 112.567 15.7217 112.127 16.1901C111.981 16.3365 111.834 16.5122 111.717 16.6585C111.629 16.7756 111.482 17.0098 111.599 17.244C111.775 17.5954 112.421 17.8296 113.359 17.8296C113.476 17.8296 113.564 17.9174 113.535 18.0345C113.506 18.1809 113.418 18.2394 113.3 18.2394Z"
          fill="#2E2E47"
        />
        <path
          d="M120.517 13.584C120.458 13.584 120.4 13.5547 120.37 13.5254C120.341 13.4669 119.315 12.2666 117.232 12.6471C117.115 12.6764 117.027 12.5886 116.997 12.5008C116.968 12.3837 117.056 12.2958 117.144 12.2666C119.461 11.8567 120.605 13.2327 120.664 13.2912C120.722 13.379 120.722 13.4961 120.634 13.5547C120.576 13.5547 120.546 13.584 120.517 13.584Z"
          fill="#2E2E47"
        />
        <path
          d="M112.395 6.58689C112.395 6.58689 111.368 6.704 110.43 8.07996C109.491 9.45592 108.963 12.0029 108.963 12.0029C108.963 12.0029 108.993 8.87041 109.638 7.46517C110.283 6.05993 111.28 3.36655 112.366 3.60076C113.451 3.86424 113.011 6.58689 112.395 6.58689Z"
          fill="#9C6F43"
        />
        <path
          d="M119.756 8.57814C119.756 8.57814 121.78 9.837 122.161 11.3301C122.513 12.8231 122.572 15.8385 123.364 16.1606C124.156 16.4533 124.215 15.0481 124.713 14.2284C125.212 13.4379 125.769 8.22683 125.27 7.87552C124.772 7.52421 120.138 7.81697 119.756 8.57814Z"
          fill="#9C6F43"
        />
        <path
          d="M111.837 4.33287C111.837 4.33287 111.544 5.56245 112.072 6.52856C112.6 7.49466 114.682 9.07555 116.031 9.36831C117.381 9.66107 123.247 8.168 124.449 8.2851C125.652 8.40221 127.118 8.37293 127.059 7.81669C127.001 7.23118 126.18 2.40067 122.953 0.790496C119.727 -0.819673 116.119 0.585566 116.119 0.585566C116.119 0.585566 111.338 1.7566 111.837 4.33287Z"
          fill="#9C6F43"
        />
        <path
          d="M119.288 15.6042C119.259 15.6042 119.23 15.6042 119.2 15.5749C117.91 14.9308 116.238 14.843 116.238 14.843C116.121 14.843 116.062 14.7552 116.062 14.6381C116.062 14.521 116.15 14.4624 116.267 14.4624C116.326 14.4624 118.027 14.5795 119.406 15.2528C119.494 15.3114 119.552 15.4285 119.494 15.5163C119.435 15.5749 119.347 15.6042 119.288 15.6042Z"
          fill="#2E2E47"
        />
        <path
          d="M116.88 14.8433C116.88 14.8433 115.912 15.5166 117.056 16.2778C118.2 17.0389 118.786 15.3409 118.2 15.1067C117.613 14.8725 116.88 14.8433 116.88 14.8433Z"
          fill="#2E2E47"
        />
        <path
          d="M112.13 14.3165C112.1 14.3165 112.071 14.3165 112.042 14.2872C111.074 13.7017 109.783 13.6724 109.783 13.6724C109.666 13.6724 109.578 13.5846 109.607 13.4675C109.607 13.3504 109.695 13.2625 109.813 13.2918C109.871 13.2918 111.22 13.3211 112.276 13.9651C112.364 14.0237 112.394 14.1408 112.335 14.2286C112.276 14.2872 112.218 14.3165 112.13 14.3165Z"
          fill="#2E2E47"
        />
        <path
          d="M110.283 13.6426C110.283 13.6426 109.52 14.3452 110.371 15.0478C111.221 15.7504 111.778 14.0232 111.309 13.8182C110.869 13.6426 110.283 13.6426 110.283 13.6426Z"
          fill="#2E2E47"
        />
        <path
          d="M110.017 18.1509C110.017 18.1509 113.038 19.0877 116.03 19.3219C116.03 19.3219 114.769 21.2541 112.51 20.9028C110.222 20.5515 110.017 18.1509 110.017 18.1509Z"
          fill="white"
        />
        <path
          d="M282.012 259.618C281.044 259.764 282.98 258.681 281.572 257.949C280.751 257.51 278.932 257.422 277.642 257.422L277.671 257.393C277.906 256.837 278.316 252.533 278.316 252.533H283.83L283.889 257.042C283.918 257.012 282.98 259.442 282.012 259.618Z"
          fill="#FFA982"
        />
        <path
          d="M271.161 262.194C271.865 262.37 273.449 263.043 276.176 262.487C278.376 262.048 283.01 260.291 284.741 259.618C284.711 260.613 284.623 261.638 284.506 262.106C284.271 263.101 276.411 264.887 274.563 265.151C272.715 265.414 264.826 264.887 265.236 263.336C265.5 262.37 265.881 261.638 266.644 261.023C268.521 260.906 270.545 262.048 271.161 262.194Z"
          fill="#EAE6E4"
        />
        <path
          d="M271.162 262.194C270.575 262.048 268.522 260.877 266.645 261.023C267.114 260.642 267.73 260.291 268.551 259.969C270.634 259.179 277.116 257.949 277.644 257.363C278.964 257.363 280.753 257.481 281.574 257.89C282.982 258.622 281.017 259.706 282.014 259.559C282.982 259.413 283.92 256.983 283.92 256.983C283.92 256.983 283.92 256.983 284.419 257.276C284.683 257.422 284.771 258.476 284.742 259.588C283.011 260.262 278.377 261.989 276.177 262.458C273.42 263.072 271.836 262.399 271.162 262.194Z"
          fill="#2E2E47"
        />
        <path
          d="M274.765 259.823L271.334 259.735C271.216 259.735 271.158 259.647 271.158 259.53C271.158 259.413 271.246 259.354 271.363 259.354L274.795 259.442C274.912 259.442 274.971 259.53 274.971 259.647C274.941 259.735 274.853 259.823 274.765 259.823Z"
          fill="white"
        />
        <path
          d="M273.477 260.789C273.389 260.789 273.33 260.73 273.301 260.643L272.802 259.032C272.773 258.945 272.831 258.827 272.919 258.798C273.007 258.769 273.125 258.827 273.154 258.915L273.653 260.525C273.682 260.613 273.623 260.73 273.535 260.76C273.535 260.789 273.506 260.789 273.477 260.789Z"
          fill="white"
        />
        <path
          d="M273.739 259.003C273.622 259.003 273.564 258.915 273.534 258.827C273.534 258.71 273.622 258.623 273.71 258.623C274.854 258.564 276.321 258.593 276.35 258.593C276.467 258.593 276.555 258.681 276.526 258.798C276.526 258.915 276.438 258.974 276.321 258.974C276.35 258.974 274.883 258.945 273.739 259.003Z"
          fill="white"
        />
        <path
          d="M275.002 259.501H274.973C274.856 259.472 274.797 259.384 274.826 259.267L275.002 258.125C275.032 258.008 275.12 257.949 275.237 257.979C275.354 258.008 275.413 258.096 275.384 258.213L275.208 259.355C275.178 259.442 275.09 259.501 275.002 259.501Z"
          fill="white"
        />
        <path
          d="M331.899 235.758C331.4 236.607 331.752 234.411 330.345 235.055C329.494 235.436 328.291 236.783 327.47 237.807C327.47 237.778 327.47 237.778 327.47 237.749C327.177 237.193 324.068 234.236 324.068 234.236L327.5 229.932L331.078 232.684C331.048 232.655 332.368 234.88 331.899 235.758Z"
          fill="#FFA982"
        />
        <path
          d="M327.178 245.829C327.735 245.39 329.26 244.6 330.521 242.111C331.518 240.12 333.044 235.407 333.601 233.65C334.363 234.294 335.126 234.997 335.419 235.378C336.065 236.168 332.574 243.429 331.636 245.039C330.697 246.649 325.388 252.504 324.421 251.187C323.834 250.367 323.482 249.635 323.453 248.64C324.509 247.088 326.679 246.21 327.178 245.829Z"
          fill="#EAE6E4"
        />
        <path
          d="M327.178 245.829C326.679 246.21 324.509 247.088 323.453 248.639C323.453 248.025 323.57 247.351 323.834 246.502C324.508 244.365 327.588 238.569 327.442 237.778C328.263 236.753 329.465 235.436 330.316 235.026C331.753 234.382 331.401 236.578 331.87 235.729C332.369 234.88 331.02 232.626 331.02 232.626C331.02 232.626 331.02 232.626 331.548 232.421C331.841 232.304 332.721 232.918 333.571 233.621C333.014 235.378 331.518 240.091 330.492 242.082C329.26 244.599 327.735 245.419 327.178 245.829Z"
          fill="#2E2E47"
        />
        <path
          d="M325.212 244.248C325.183 244.248 325.124 244.219 325.095 244.19C325.007 244.131 325.007 244.014 325.066 243.926L327.265 241.291C327.324 241.203 327.441 241.203 327.529 241.262C327.617 241.321 327.617 241.438 327.559 241.525L325.359 244.16C325.3 244.248 325.271 244.248 325.212 244.248Z"
          fill="white"
        />
        <path
          d="M327.354 243.194C327.325 243.194 327.295 243.194 327.295 243.194L325.741 242.579C325.653 242.55 325.594 242.433 325.623 242.345C325.653 242.257 325.77 242.199 325.858 242.228L327.413 242.843C327.501 242.872 327.559 242.989 327.53 243.077C327.501 243.165 327.442 243.194 327.354 243.194Z"
          fill="white"
        />
        <path
          d="M326.12 241.876C326.091 241.876 326.032 241.876 326.003 241.847C325.915 241.788 325.886 241.671 325.974 241.583C326.648 240.646 327.587 239.534 327.587 239.505C327.645 239.417 327.763 239.417 327.851 239.475C327.939 239.534 327.939 239.651 327.88 239.739C327.88 239.739 326.941 240.881 326.267 241.788C326.238 241.847 326.179 241.876 326.12 241.876Z"
          fill="white"
        />
        <path
          d="M327.293 241.204C327.234 241.204 327.176 241.174 327.146 241.145L326.355 240.296C326.296 240.208 326.296 240.091 326.355 240.033C326.443 239.974 326.56 239.974 326.619 240.033L327.41 240.882C327.469 240.969 327.469 241.087 327.41 241.145C327.381 241.204 327.352 241.204 327.293 241.204Z"
          fill="white"
        />
        <path
          d="M332.487 228.937C332.487 228.937 322.603 217.109 312.455 205.106C311.428 195.533 310.46 186.926 309.903 182.915C308.349 171.937 302.483 158.207 302.483 158.207C302.483 158.207 299.52 163.593 288.786 163.476C280.632 163.388 277.816 160.607 277.816 160.607C277.816 160.607 273.27 189.297 273.299 212.718C273.329 232.977 272.947 253.119 272.947 253.119C272.947 253.119 275.118 255.841 279.869 255.666C287.319 255.402 291.455 252.153 291.455 252.153L292.51 194.655C292.833 197.29 293.156 199.895 293.566 202.53C294.622 209.586 297.585 216.202 302.072 221.735C312.074 234.031 315.828 239.827 315.828 239.827C315.828 239.827 322.193 240.032 326.915 236.022C333.25 230.664 332.487 228.937 332.487 228.937Z"
          fill="#9C6F43"
        />
        <path
          d="M302.952 159.407C302.336 159.582 301.603 159.787 300.811 159.992C298.963 161.544 295.297 163.535 288.756 163.447C286.586 163.418 284.797 163.213 283.33 162.92C280.779 163.125 278.784 163.271 278.784 163.271C278.784 163.271 279.341 165.145 288.58 165.613C295.356 165.935 301.251 164.471 304.507 163.418C303.891 161.72 303.334 160.344 302.952 159.407Z"
          fill="#2E2E47"
        />
        <path
          d="M272.359 134.757L291.395 125.506C291.395 125.506 291.512 132.327 286.291 138.416C284.238 140.817 277.58 145.15 272.946 146.116C269.221 140.641 272.183 136.689 272.359 134.757Z"
          fill="#FFE2BD"
        />
        <path
          d="M283.916 133V140.524C282.303 141.753 280.191 143.1 278.05 144.183C279.106 140.67 283.916 133 283.916 133Z"
          fill="#2E2E47"
        />
        <path
          d="M243.353 128.023C246.374 127.789 272.272 134.786 272.272 134.786L272.389 134.728C272.213 136.66 272.008 140.934 273.299 144.359C269.779 145.091 239.657 128.316 243.353 128.023Z"
          fill="#FFA982"
        />
        <path
          d="M293.449 128.843C291.748 128.17 292.012 126.033 292.422 124.569C293.625 123.895 294.446 123.544 294.563 123.456C294.974 123.134 295.385 122.695 295.854 122.08C297.819 119.475 300.928 119.533 300.928 119.533C300.928 119.533 299.52 122.871 301.456 126.852C300.195 127.526 295.707 129.751 293.449 128.843Z"
          fill="#FFA982"
        />
        <path
          d="M300.107 121.583C300.107 121.583 298.347 121.261 296.91 120.997C296.558 121.29 296.177 121.641 295.854 122.08C295.561 122.461 295.326 122.754 295.062 123.017C297.321 123.31 300.107 121.583 300.107 121.583Z"
          fill="#2E2E47"
        />
        <path
          d="M276.79 156.801C277.582 154.342 283.184 135.166 283.712 133.556C285.765 127.584 289.9 126.003 292.423 124.568C292.012 126.032 291.778 128.169 293.449 128.843C295.678 129.75 300.166 127.525 301.456 126.823C301.72 127.379 302.043 127.935 302.454 128.491C306.355 133.907 305.123 140.758 302.894 150.097C302.248 152.82 303.422 157.913 303.92 159.875C304.038 160.343 303.803 160.87 303.363 161.075C301.603 161.983 296.94 164.061 291.308 164.296C277.171 164.91 276.614 161.749 276.614 161.749C276.614 161.749 276.203 158.558 276.79 156.801Z"
          fill="#FFE2BD"
        />
        <path
          d="M292.511 194.86C292.423 194.86 292.335 194.801 292.335 194.714C292.306 194.567 290.077 181.335 288.991 178.583C287.906 175.86 284.885 173.284 284.856 173.254C284.768 173.196 284.768 173.079 284.827 172.991C284.885 172.903 285.003 172.903 285.091 172.962C285.208 173.079 288.229 175.597 289.343 178.436C290.458 181.247 292.628 194.099 292.716 194.655C292.746 194.772 292.658 194.86 292.57 194.889C292.511 194.86 292.511 194.86 292.511 194.86Z"
          fill="#2E2E47"
        />
        <path
          d="M302.746 153.083L295.882 150.712C295.882 150.712 296.498 156.509 299.813 158.177C301.132 158.851 302.54 159.026 303.743 159.026C303.362 157.562 302.892 155.22 302.746 153.083Z"
          fill="#2E2E47"
        />
        <path
          d="M287.907 178.817C287.819 178.817 287.76 178.758 287.731 178.7C287.614 178.495 286.323 177.734 285.179 177.06C282.305 175.362 280.076 174.016 279.871 173.108C279.783 172.757 279.929 172.552 280.047 172.405C280.78 171.703 283.39 172.318 283.683 172.405C283.801 172.435 283.859 172.523 283.83 172.64C283.801 172.757 283.713 172.815 283.596 172.786C282.569 172.523 280.75 172.288 280.311 172.698C280.252 172.757 280.193 172.845 280.223 173.02C280.399 173.84 283.361 175.567 285.326 176.709C287.203 177.822 287.936 178.261 288.024 178.524C288.054 178.612 288.024 178.729 287.907 178.758C287.966 178.817 287.936 178.817 287.907 178.817Z"
          fill="#2E2E47"
        />
        <path
          d="M307.292 178.67C307.263 178.67 307.263 178.67 307.234 178.67C303.421 177.294 301.632 174.337 301.573 174.191C301.514 174.103 301.544 173.986 301.632 173.927C301.72 173.869 301.837 173.898 301.896 173.986C301.925 174.015 303.685 176.972 307.351 178.29C307.439 178.319 307.498 178.436 307.468 178.524C307.439 178.612 307.351 178.67 307.292 178.67Z"
          fill="#2E2E47"
        />
        <path
          d="M293.77 133.469C293.77 133.469 292.685 132.151 291.629 131.712C290.573 131.273 289.459 131.127 288.843 130.863C288.198 130.6 288.755 132.503 290.104 132.913C291.453 133.322 294.386 134.611 293.77 133.469Z"
          fill="#FFA982"
        />
        <path
          d="M309.607 111.161C309.607 111.161 310.282 119.416 302.597 120.09C294.883 120.734 307.319 106.799 309.607 111.161Z"
          fill="#9C6F43"
        />
        <path
          d="M304.122 118.07C304.122 118.07 304.914 118.275 305.736 117.689C306.381 117.221 306.615 115.523 306.117 114.908C305.618 114.293 304.914 114.235 304.738 114.176C304.562 114.118 305.442 111.19 304.738 109.258C304.005 107.355 302.421 105.423 299.488 105.276C296.555 105.101 294.883 106.535 293.769 107.648C292.654 108.76 292.244 111.307 292.009 112.508C291.745 113.708 291.452 114.03 291.129 114.645C289.633 117.514 290.836 120.705 295.118 121.876C297.846 122.666 302.568 122.432 304.122 118.07Z"
          fill="#FFA982"
        />
        <path
          d="M295.468 116.518C295.057 116.518 294.471 116.108 294.295 115.61C294.207 115.376 294.148 114.908 294.735 114.439C295.263 114 295.703 112.595 295.439 111.658C295.321 111.248 295.087 111.043 294.764 110.985C293.972 110.838 293.004 110.75 292.828 110.838C292.74 110.897 292.623 110.867 292.564 110.78C292.506 110.692 292.535 110.575 292.623 110.516C293.004 110.282 294.412 110.516 294.823 110.604C295.292 110.663 295.644 110.985 295.82 111.541C296.113 112.565 295.703 114.176 294.999 114.732C294.617 115.025 294.559 115.347 294.823 115.698C295.087 116.049 295.497 116.166 295.585 116.137C295.615 116.049 295.732 115.991 295.82 116.02C295.908 116.049 295.967 116.166 295.937 116.283C295.82 116.43 295.673 116.518 295.468 116.518Z"
          fill="#2E2E47"
        />
        <path
          d="M299.987 112.214C299.958 112.214 299.899 112.215 299.87 112.185C299.87 112.185 298.931 111.57 297.67 111.424C297.553 111.424 297.494 111.307 297.494 111.219C297.494 111.102 297.611 111.043 297.699 111.043C299.049 111.19 300.017 111.863 300.075 111.892C300.163 111.951 300.193 112.068 300.134 112.156C300.134 112.185 300.075 112.214 299.987 112.214Z"
          fill="#2E2E47"
        />
        <path
          d="M299.516 114.586C299.457 114.586 299.428 114.557 299.398 114.527C299.398 114.527 298.166 113.415 297.433 113.942C297.345 114 297.228 113.971 297.169 113.883C297.111 113.795 297.14 113.678 297.228 113.62C298.225 112.946 299.633 114.205 299.692 114.235C299.78 114.293 299.78 114.439 299.692 114.498C299.633 114.557 299.574 114.586 299.516 114.586Z"
          fill="#2E2E47"
        />
        <path
          d="M293.241 112.449C293.505 112.478 293.71 112.712 293.71 112.947C293.71 113.181 293.652 113.766 293.388 113.825C292.273 114.088 292.801 112.654 292.86 112.566C292.948 112.478 293.036 112.42 293.241 112.449Z"
          fill="#2E2E47"
        />
        <path
          d="M298.138 113.561C298.402 113.591 298.608 113.825 298.608 114.059C298.608 114.293 298.549 114.879 298.285 114.937C297.17 115.201 297.698 113.766 297.757 113.678C297.845 113.591 297.962 113.532 298.138 113.561Z"
          fill="#2E2E47"
        />
        <path
          d="M294.475 113.386C294.416 113.386 294.358 113.356 294.328 113.327C293.624 112.566 292.715 112.625 292.686 112.625C292.568 112.625 292.48 112.566 292.48 112.449C292.48 112.332 292.539 112.244 292.656 112.244C292.715 112.244 293.8 112.156 294.622 113.064C294.68 113.152 294.68 113.269 294.622 113.327C294.563 113.356 294.534 113.386 294.475 113.386Z"
          fill="#2E2E47"
        />
        <path
          d="M297.905 115.552C297.905 115.552 299.928 115.347 300.368 116.05C300.808 116.753 298.579 116.987 297.523 116.665C296.467 116.343 297.406 115.582 297.905 115.552Z"
          fill="#F2836D"
        />
        <path
          d="M291.715 114.352C291.715 114.352 293.181 114.352 293.328 115.083C293.474 115.815 291.832 115.786 291.157 115.376C290.512 114.937 291.363 114.322 291.715 114.352Z"
          fill="#F2836D"
        />
        <path
          d="M293.21 109.668C290.218 107.999 294.823 102.29 298.02 102.319C300.953 102.349 302.097 102.7 302.097 102.7C302.097 102.7 304.267 102.817 307.229 103.9C310.192 104.984 310.896 108.292 309.957 111.336C309.019 114.381 307.083 115.435 306.056 115.406C305.03 115.376 304.091 114.469 304.091 114.469C304.091 114.469 303.358 116.87 302.771 116.518C302.185 116.167 301.334 112.654 301.334 112.654C301.334 112.654 299.926 112.947 298.988 111.629C298.577 111.044 298.46 109.668 298.254 109.58C297.99 109.492 297.433 111.366 297.14 111.249C296.172 110.897 294.999 110.722 294.412 110.019C293.943 109.463 294.177 107.882 293.972 107.794C293.825 107.735 294.119 110.165 293.21 109.668Z"
          fill="#9C6F43"
        />
        <path
          d="M293.152 116.84C293.152 116.84 294.912 117.689 296.847 117.484C296.847 117.484 296.173 118.509 294.677 118.333C293.211 118.187 293.152 116.84 293.152 116.84Z"
          fill="white"
        />
        <path
          d="M308.434 105.188C308.434 105.188 310.546 103.637 313.215 104.866C315.854 106.096 314.622 109.668 314.916 111.483C315.209 113.298 319.579 113.064 319.521 115.933C319.433 118.802 316.705 120.5 313.772 119.241C310.868 117.982 308.727 116.255 307.876 113.034C306.997 109.785 308.434 105.188 308.434 105.188Z"
          fill="#9C6F43"
        />
        <path
          d="M313.77 120.47C316.586 121.495 318.522 120.412 319.197 119.68C319.314 119.534 319.314 119.329 319.167 119.182C319.021 119.065 318.815 119.065 318.669 119.212C318.111 119.856 316.351 120.763 313.741 119.709C311.483 118.772 309.606 115.142 309.43 114.644C309.371 114.498 309.224 114.381 309.048 114.41C308.843 114.44 308.726 114.615 308.755 114.791C308.784 114.967 310.808 119.27 313.506 120.383C313.565 120.412 313.653 120.441 313.77 120.47Z"
          fill="#9C6F43"
        />
        <path
          d="M286.734 123.807C286.734 123.807 286.382 123.514 286.47 122.987C286.558 122.49 287.291 121.933 287.731 121.319C288.171 120.704 288.288 120.001 289.08 119.738C291.485 118.918 293.362 120.001 293.568 120.762C293.802 121.523 293.714 122.519 294.184 122.929C294.653 123.309 294.594 124.275 294.213 124.597C293.802 124.919 288.405 125.476 286.734 123.807Z"
          fill="#84CECA"
        />
        <path
          d="M287.467 121.172C287.467 121.172 287.144 120.879 287.232 120.353C287.32 119.855 287.877 119.269 288.2 118.625C289.696 115.522 292.101 116.547 293.333 117.571C294.125 118.245 293.714 119.562 294.037 120.06C294.477 120.762 294.535 121.641 294.183 121.963C293.802 122.255 288.962 122.841 287.467 121.172Z"
          fill="#84CECA"
        />
        <path
          d="M286.733 123.807L289.285 140.289L294.477 124.714C294.565 124.451 294.389 124.188 294.125 124.158C293.157 124.041 291.074 123.895 289.96 124.568C289.96 124.568 288.552 123.075 286.733 123.807Z"
          fill="#E5A251"
        />
        <path
          d="M308.32 147.755C307.85 140.085 303.656 130.511 303.656 130.511C300.313 129.897 298.347 139.206 298.406 147.169C298.406 147.228 298.406 147.316 298.406 147.374C300.606 147.989 304.301 148.633 308.32 147.755Z"
          fill="#FFE2BD"
        />
        <path
          d="M298.434 147.374C298.522 150.594 298.317 154.752 300.429 156.303C301.778 157.299 305.063 157.445 306.852 154.986C308.142 153.258 308.494 150.624 308.318 147.755C304.3 148.633 300.605 147.989 298.434 147.374Z"
          fill="#FFA982"
        />
        <path
          d="M295.004 136.689C295.004 136.689 294.241 133.849 293.772 133.468C293.303 133.088 287.114 131.624 286.674 132.532C286.234 133.439 286.821 133.908 286.85 134.113C286.88 134.317 287.085 135.401 287.114 135.488C287.144 135.576 287.349 135.957 287.437 136.22C287.525 136.484 287.496 137.304 287.701 137.567C287.906 137.831 290.399 137.772 290.986 138.006C291.602 138.24 292.687 138.943 293.186 139.031C293.743 139.089 295.239 137.596 295.004 136.689Z"
          fill="#FFA982"
        />
        <path
          d="M294.68 135.606C294.68 135.606 308.671 148.575 307.585 152.527C306.5 156.48 301.866 157.973 299.168 156.04C296.469 154.108 290.662 138.416 291.601 137.158C292.539 135.928 294.68 135.606 294.68 135.606Z"
          fill="#FFA982"
        />
        <path
          d="M306.647 149.424C306.589 149.424 306.501 149.394 306.471 149.306C304.653 145.706 297.438 138.562 297.379 138.504C297.291 138.416 297.291 138.299 297.379 138.24C297.467 138.152 297.584 138.152 297.643 138.24C297.936 138.533 304.946 145.501 306.823 149.16C306.882 149.248 306.823 149.365 306.735 149.424C306.706 149.394 306.677 149.424 306.647 149.424Z"
          fill="#2E2E47"
        />
        <path
          d="M310.018 212.747C309.93 212.747 309.842 212.718 309.784 212.659C309.256 212.279 309.93 210.698 310.781 209.175C310.165 209.878 309.49 210.551 308.874 210.991C307.818 211.752 306.469 211.927 306.029 211.693C305.912 211.635 305.824 211.518 305.853 211.4C305.853 211.166 306.147 210.932 306.733 210.669C308.669 209.849 312.277 205.048 312.306 204.989C312.365 204.901 312.482 204.872 312.57 204.96C312.658 205.018 312.658 205.135 312.599 205.223C312.453 205.428 308.874 210.142 306.851 211.02C306.44 211.196 306.293 211.313 306.235 211.371C306.528 211.459 307.672 211.371 308.61 210.669C310.165 209.556 312.247 206.628 312.277 206.599C312.335 206.511 312.453 206.482 312.541 206.541C312.629 206.599 312.658 206.716 312.599 206.804C310.869 209.498 309.637 212.074 309.989 212.308C309.989 212.308 310.018 212.337 310.048 212.308C310.605 212.132 311.719 209.527 312.277 207.946C312.306 207.858 312.423 207.8 312.511 207.829C312.599 207.858 312.658 207.975 312.629 208.063C312.365 208.795 311.074 212.396 310.165 212.689C310.106 212.747 310.077 212.747 310.018 212.747Z"
          fill="#2E2E47"
        />
        <path
          d="M119.989 140.729C119.901 140.729 119.813 140.67 119.813 140.582C118.61 133.615 118.2 122.314 118.2 122.197C118.2 122.08 118.288 121.992 118.376 121.992C118.493 121.992 118.581 122.08 118.581 122.168C118.581 122.285 119.021 133.556 120.194 140.495C120.223 140.612 120.135 140.699 120.047 140.729C120.018 140.699 119.989 140.729 119.989 140.729Z"
          fill="#2E2E47"
        />
        <path
          d="M118.346 131.302C117.994 131.302 117.466 131.214 116.703 130.951C114.914 130.307 114.768 123.281 114.738 122.49C114.738 122.373 114.826 122.285 114.914 122.285C115.032 122.285 115.12 122.373 115.12 122.461C115.12 122.49 115.149 124.393 115.384 126.354C115.794 129.721 116.469 130.453 116.821 130.57C118.346 131.126 118.727 130.834 118.727 130.834C118.786 130.746 118.903 130.746 118.991 130.834C119.079 130.922 119.079 131.039 118.991 131.097C118.991 131.156 118.815 131.302 118.346 131.302Z"
          fill="#2E2E47"
        />
        <path
          d="M162.43 84.6947C162.4 84.6947 162.342 84.6947 162.312 84.6655C162.224 84.6069 162.195 84.4898 162.254 84.402C165.275 79.8642 167.034 71.0522 167.034 70.9644C167.064 70.8473 167.152 70.7887 167.269 70.818C167.386 70.8473 167.445 70.9351 167.416 71.0522C167.386 71.14 165.627 80.0399 162.576 84.6362C162.547 84.6655 162.488 84.6947 162.43 84.6947Z"
          fill="#2E2E47"
        />
        <path
          d="M218.363 120.997C218.333 120.997 218.275 120.997 218.245 120.968C215.518 119.153 213.934 117.045 213.113 115.611C212.203 114.03 211.969 112.917 211.969 112.859C211.939 112.741 212.027 112.654 212.115 112.624C212.233 112.595 212.321 112.683 212.35 112.771C212.35 112.771 212.585 113.883 213.464 115.406C214.256 116.811 215.782 118.86 218.451 120.617C218.539 120.675 218.568 120.792 218.509 120.88C218.48 120.968 218.421 120.997 218.363 120.997Z"
          fill="#2E2E47"
        />
        <path
          d="M226.517 178.173C226.459 178.173 226.371 178.143 226.341 178.085C226.312 178.026 225.461 176.416 225.198 172.318C225.051 169.888 223.584 162.334 222.177 155.045C221.209 149.951 220.27 145.12 219.771 142.046C218.569 134.581 216.721 119.943 216.721 119.797C216.721 119.68 216.78 119.592 216.897 119.592C217.014 119.592 217.102 119.65 217.102 119.767C217.132 119.914 218.98 134.552 220.153 142.017C220.651 145.091 221.561 149.892 222.558 155.016C223.966 162.334 225.432 169.888 225.579 172.347C225.843 176.358 226.664 177.968 226.664 177.968C226.723 178.056 226.664 178.173 226.576 178.231C226.576 178.173 226.547 178.173 226.517 178.173Z"
          fill="#2E2E47"
        />
        <path
          d="M253.94 223.345C253.882 223.345 253.794 223.316 253.764 223.228C253.735 223.199 252.034 219.656 250.773 218.076C249.629 216.612 247.898 213.977 245.933 210.669C245.053 209.176 244.115 208.239 243.029 207.156C242.032 206.16 240.888 205.019 239.598 203.233C237.281 200.012 236.078 196.089 235.052 192.635C234.905 192.108 234.729 191.581 234.582 191.083C233.409 187.307 230.153 183.647 230.124 183.618C230.065 183.53 230.065 183.413 230.124 183.354C230.212 183.296 230.329 183.296 230.388 183.354C230.417 183.384 233.732 187.102 234.934 190.966C235.081 191.464 235.257 191.991 235.404 192.518C236.43 195.943 237.603 199.837 239.891 202.999C241.152 204.755 242.296 205.897 243.293 206.892C244.408 208.005 245.346 208.942 246.256 210.493C248.191 213.801 249.922 216.407 251.066 217.871C252.356 219.481 254.028 222.965 254.087 223.111C254.145 223.199 254.087 223.316 253.999 223.374C253.999 223.345 253.97 223.345 253.94 223.345Z"
          fill="#2E2E47"
        />
        <path
          d="M202.142 234.353C202.054 234.353 201.966 234.294 201.966 234.206C201.937 234.089 199.033 222.613 199.092 216.465C199.121 214.24 199.062 211.254 199.004 208.092C198.886 202.383 198.769 196.47 199.092 193.571C199.62 188.916 197.625 174.191 196.305 169.741C194.956 165.145 194.575 137.362 194.545 136.191C194.545 136.074 194.633 135.986 194.721 135.986C194.839 135.986 194.927 136.074 194.927 136.161C194.927 136.454 195.308 165.057 196.657 169.594C197.977 174.074 200.001 188.858 199.444 193.571C199.121 196.44 199.238 202.354 199.356 208.034C199.414 211.195 199.473 214.182 199.444 216.436C199.385 222.525 202.289 233.972 202.318 234.089C202.347 234.206 202.289 234.294 202.171 234.323C202.171 234.353 202.171 234.353 202.142 234.353Z"
          fill="#2E2E47"
        />
        <path
          d="M11.8532 127.086C11.8532 127.086 15.7247 110.019 13.5836 108.731C11.4425 107.472 -4.13176 131.39 1.05967 137.919C5.51785 143.539 12.7331 141.637 18.6871 137.655C15.5194 134.464 14.3462 128.902 13.9063 125.915C11.9412 127.116 11.8532 127.086 11.8532 127.086Z"
          fill="#FFA982"
        />
        <path
          d="M30.3609 114.146C30.3609 114.146 25.5214 117.601 23.9376 118.83C19.0981 122.548 15.9012 124.685 13.936 125.915C14.376 128.901 15.5492 134.463 18.7168 137.654C22.7351 134.961 26.2254 131.272 28.0145 128.198C32.414 120.557 30.3609 114.146 30.3609 114.146Z"
          fill="#C96B59"
        />
        <path
          d="M9.32832 109.726C9.32832 109.726 8.97636 112.127 9.91492 112.742C10.8535 113.356 12.8479 112.039 12.7599 111.014C12.672 109.99 10.3255 108.994 9.32832 109.726Z"
          fill="#FFA982"
        />
        <path
          d="M12.3801 113.268C12.3801 113.268 13.348 113.473 14.2572 111.805C15.2545 109.96 15.6358 108.379 15.6651 107.647C15.6944 106.945 16.9556 105.1 17.0143 104.603C17.0729 104.105 15.9877 104.017 15.3424 104.691C14.6972 105.364 14.0813 106.916 13.6413 107.735C13.2013 108.555 10.7963 112.654 12.3801 113.268Z"
          fill="#FFA982"
        />
        <path
          d="M10.7964 100.651C10.7964 100.651 10.5325 100.358 10.7084 99.8897C10.8844 99.4798 11.6177 99.1285 12.087 98.6894C12.5856 98.221 12.7909 97.6647 13.5241 97.5476C15.7239 97.2842 17.1317 98.5137 17.1904 99.2163C17.2491 99.919 17.0144 100.768 17.3371 101.178C17.6597 101.588 17.4544 102.407 17.0438 102.612C16.6625 102.847 11.9403 102.378 10.7964 100.651Z"
          fill="#FFC0DD"
        />
        <path
          d="M11.9112 98.4841C11.9112 98.4841 11.6766 98.1913 11.8526 97.7229C11.9992 97.2838 12.6151 96.9032 12.9964 96.4055C14.8442 94.0342 16.692 95.3223 17.5719 96.4348C18.1292 97.1374 17.5426 98.2206 17.7186 98.689C17.9826 99.3624 17.8652 100.124 17.5133 100.358C17.1613 100.592 12.8791 100.182 11.9112 98.4841Z"
          fill="#84CECA"
        />
        <path
          d="M10.7958 100.651L10.0332 115.23L17.2777 102.759C17.3951 102.554 17.3071 102.29 17.0724 102.232C16.2805 101.968 14.4914 101.47 13.4355 101.822C13.4355 101.851 12.4969 100.329 10.7958 100.651Z"
          fill="#9C6F43"
        />
        <path
          d="M12.6132 108.321C12.5838 107.969 12.8771 107.735 12.9358 105.774C12.9358 105.276 11.9972 104.925 11.9972 104.925C11.9972 104.925 13.1118 104.398 13.1411 103.988C13.1998 102.934 10.0321 103.549 9.59217 103.9C9.12289 104.251 9.29887 104.72 9.24021 105.247C9.18155 105.774 8.94691 106.857 8.88825 107.647C8.82959 108.438 8.77093 109.521 8.94691 110.487C9.12289 111.453 11.9972 109.872 12.2319 109.667C12.4372 109.433 12.6425 108.672 12.6132 108.321Z"
          fill="#FFA982"
        />
        <path
          d="M43.2065 240.94C43.0892 238.803 43.3238 236.285 43.1478 232.655L36.4312 231.191C36.3726 234.705 37.1645 237.749 37.3698 240.238C37.7511 244.307 34.8474 246.415 36.1966 246.415C44.3504 246.503 43.3238 242.902 43.2065 240.94Z"
          fill="#FFA982"
        />
        <path
          d="M25.4069 243.985C25.5242 245.332 25.7882 246.444 26.4335 247.381C27.0787 248.318 29.6598 248.494 30.8623 248.259C32.0649 248.025 41.2452 247.001 41.9785 246.766C42.4771 246.62 43.8262 246.152 44.7355 245.829C45.2634 244.19 43.7676 241.438 43.3863 241.204C43.3276 241.175 43.269 241.087 43.2103 240.94C42.8877 241.497 42.9757 242.697 42.7997 243.517C42.6237 244.424 37.139 243.839 38.2242 242.668C39.3094 241.497 37.3736 240.267 37.3736 240.267C37.3736 240.267 30.0997 241.292 29.3078 241.321C28.5159 241.35 27.2841 240.794 25.3483 241.672C24.967 241.848 24.6443 242.024 24.3804 242.229C24.7323 242.434 25.3189 242.99 25.4069 243.985Z"
          fill="#2E2E47"
        />
        <path
          d="M44.7053 245.947C44.7346 245.918 44.7346 245.859 44.7346 245.83C43.8254 246.152 42.4469 246.62 41.9776 246.767C41.215 247.001 32.064 248.026 30.8615 248.26C29.6589 248.494 27.0779 248.318 26.4326 247.382C25.7874 246.445 25.5234 245.332 25.4061 243.986C25.3181 242.99 24.7315 242.405 24.4382 242.2C23.2943 243.049 23.001 244.22 23.2356 245.976C23.5583 248.172 26.2566 250.397 28.779 250.338C31.3308 250.28 38.37 248.494 39.6019 248.26C40.8337 248.084 44.0307 247.557 44.7053 245.947Z"
          fill="white"
        />
        <path
          d="M34.9665 242.433C34.8785 241.672 33.7639 240.53 33.7639 240.53C33.06 240.326 33.1187 241.292 33.1187 241.292C33.1187 241.292 32.6787 240.794 32.6787 240.706C32.6494 240.618 31.6815 241.379 31.6815 241.379L31.5055 240.882C31.5055 240.882 31.1242 240.794 30.391 240.94C29.6577 241.087 30.039 241.76 30.039 241.76C30.039 241.76 29.599 241.057 29.3057 240.94C29.0124 240.853 28.1619 240.882 28.0152 241.057C27.8686 241.233 28.8951 243.575 29.2471 243.634C29.599 243.722 30.0977 243.312 30.0977 243.312C30.0977 243.312 30.215 243.751 30.4496 243.78C30.6843 243.809 31.3588 243.634 31.6521 243.546C31.9454 243.458 32.0921 242.814 32.0921 242.814C32.0921 242.814 32.2387 243.253 32.3267 243.487C32.4147 243.722 33.148 243.546 33.324 243.4C33.4999 243.253 33.8812 242.668 33.8812 242.668C33.8812 242.668 34.0572 243.312 34.0865 243.37C34.1159 243.458 34.9665 243.4 35.0544 243.37C35.0544 243.341 35.0544 243.195 34.9665 242.433Z"
          fill="white"
        />
        <path
          d="M87.0851 216.377C85.4133 215.06 83.6829 213.216 80.8378 210.932L75.2944 215.001C77.9048 217.373 80.6912 218.807 82.715 220.271C85.9999 222.701 85.5893 226.156 86.5572 225.248C93.3031 219.129 88.6396 217.607 87.0851 216.377Z"
          fill="#FFA982"
        />
        <path
          d="M81.1557 232.362C82.4755 233.211 83.6781 233.767 84.8806 233.943C86.0831 234.089 87.5496 232.216 87.9016 231.103C88.2829 229.991 91.8318 222.145 91.9785 221.384C92.0665 220.886 92.3011 219.51 92.4478 218.573C91.1572 217.022 87.8136 216.26 87.403 216.407C87.3443 216.436 87.227 216.407 87.0803 216.377C87.4323 217.022 88.6348 217.812 89.3094 218.485C90.1013 219.276 86.7284 222.935 86.1711 221.296C85.6138 219.656 82.8568 220.388 82.8568 220.388C82.8568 220.388 83.0621 221.589 82.5048 222.935C81.7129 224.78 80.745 227.063 80.5397 227.444C80.1878 228.088 79.0439 228.673 78.8972 230.752C78.8679 231.162 78.8973 231.542 78.9559 231.894C79.3079 231.806 80.1584 231.747 81.1557 232.362Z"
          fill="#2E2E47"
        />
        <path
          d="M92.5657 218.72C92.5363 218.69 92.507 218.632 92.4777 218.603C92.331 219.539 92.0964 220.945 92.0084 221.413C91.8618 222.174 88.2835 230.02 87.9315 231.133C87.5502 232.245 86.113 234.119 84.9105 233.972C83.708 233.826 82.5054 233.24 81.1856 232.391C80.1883 231.747 79.3671 231.835 79.0151 231.894C79.2498 233.358 80.2177 234.441 82.0361 235.465C84.2652 236.754 87.7262 236.227 88.9287 234.207C90.1313 232.187 91.9204 225.424 92.331 224.341C92.683 223.228 93.7682 220.33 92.5657 218.72Z"
          fill="white"
        />
        <path
          d="M84.3836 223.843C83.5917 223.375 81.9785 223.433 81.9785 223.433C81.4506 223.843 82.3891 224.458 82.3891 224.458C82.3891 224.458 81.6852 224.429 81.6266 224.4C81.5386 224.37 81.7732 225.658 81.7732 225.658L81.2159 225.454C81.2159 225.454 80.952 225.688 80.688 226.361C80.4534 227.034 81.2746 227.21 81.2746 227.21C81.2746 227.21 80.3654 227.064 80.1307 227.21C79.8961 227.386 79.5148 228.059 79.6028 228.293C79.6908 228.527 82.4185 229.377 82.6531 229.142C82.8878 228.908 82.7704 228.264 82.7704 228.264C82.7704 228.264 83.2397 228.469 83.3864 228.293C83.533 228.118 83.709 227.474 83.7677 227.21C83.8263 226.917 83.2984 226.332 83.2984 226.332C83.2984 226.332 83.797 226.507 84.061 226.625C84.3249 226.742 84.5302 226.039 84.4716 225.805C84.4129 225.571 84.0316 224.868 84.0316 224.868C84.0316 224.868 84.7356 225.19 84.7942 225.219C84.8822 225.249 85.2635 224.575 85.2635 224.487C85.3222 224.4 85.1755 224.282 84.3836 223.843Z"
          fill="white"
        />
        <path
          d="M58.5754 154.899C57.9888 152.879 27.8374 152.937 27.8374 155.982C27.8667 168.57 32.0903 237.251 32.0903 237.251C32.0903 237.251 34.3194 238.891 38.1909 238.891C43.3237 238.862 46.0807 237.251 46.0807 237.251L43.9396 174.894C43.9396 174.894 52.5334 199.398 56.8742 205.516C61.743 212.396 77.024 221.56 77.024 221.56C77.024 221.56 80.309 222.789 84.5619 219.305C87.7882 216.641 86.791 213.333 86.7616 213.362L67.1398 192.255C67.0811 192.225 60.8631 162.715 58.5754 154.899Z"
          fill="#366861"
        />
        <path
          d="M58.8383 155.777C57.3131 156.479 54.7027 157.387 50.5085 157.855C43.0294 158.704 29.6255 158.382 29.6255 158.382C29.6255 158.382 37.6326 162.481 44.3785 162.627C49.8046 162.774 56.8438 161.574 60.3341 161.105C59.7475 158.997 59.2196 157.153 58.8383 155.777Z"
          fill="#2E2E47"
        />
        <path
          d="M60.2161 158.499C57.3124 148.897 53.1475 128.726 52.5609 123.72C51.9743 118.772 43.8499 110.429 35.1975 112.185C30.8273 113.063 26.5745 116.079 26.0465 120.675C23.8467 140.846 25.5772 145.677 26.8091 157.592C28.2463 157.943 30.0647 158.763 37.5732 160.315C51.4464 163.154 57.6351 158.763 60.2161 158.499Z"
          fill="#C96B59"
        />
        <path
          d="M56.873 145.472C55.7584 140.671 54.7026 135.665 53.9106 131.595L46.1675 125.447C46.1675 125.447 50.7723 143.364 56.873 145.472Z"
          fill="#2E2E47"
        />
        <path
          d="M45.2866 100.592C45.2866 100.592 44.9053 104.134 42.5882 105.51C40.2711 106.857 39.3912 106.271 38.2767 105.217C37.1621 104.164 38.6873 96.2883 41.063 96.8738C43.4095 97.4008 44.876 99.8892 45.2866 100.592Z"
          fill="#9C6F43"
        />
        <path
          d="M39.0704 104.544C39.0704 104.544 39.3638 108.702 40.9182 112.42C41.4462 113.708 33.7617 115.435 34.319 112.917C34.9936 109.96 34.7589 105.218 34.9349 104.808C35.1402 104.369 38.4838 103.9 39.0704 104.544Z"
          fill="#FFA982"
        />
        <path
          d="M34.6118 111.073C34.6998 110.195 34.7585 109.258 34.7878 108.409L38.8647 106.682C38.8647 106.682 38.1608 109.463 35.9903 110.487C35.4917 110.751 35.0224 110.956 34.6118 111.073Z"
          fill="#2E2E47"
        />
        <path
          d="M30.5351 94.2682C30.5351 94.2682 29.8605 96.4639 29.0099 98.3961C28.482 99.5964 28.3353 99.4207 27.6607 101.265C26.8395 103.49 26.3116 105.598 29.4205 107.472C32.5295 109.375 36.7237 109.17 38.3956 107.559C39.9501 106.066 40.39 105.247 40.39 105.247C40.39 105.247 40.8593 106.301 42.0325 105.247C43.2057 104.222 44.6722 102.407 43.587 101.646C42.5018 100.885 41.9445 101.851 41.7099 101.616C41.4752 101.382 42.0912 99.3915 42.2671 97.6935C42.4725 96.054 35.7852 90.8722 30.5351 94.2682Z"
          fill="#FFA982"
        />
        <path
          d="M37.0745 99.5094C37.0452 99.5094 37.0158 99.5094 36.9865 99.4802C36.9865 99.4802 35.8133 98.7776 34.9627 98.5726C34.8454 98.5433 34.7867 98.4555 34.8161 98.3384C34.8454 98.2213 34.9334 98.1628 35.0507 98.192C35.9306 98.397 37.1331 99.0996 37.1625 99.1289C37.2505 99.1874 37.2798 99.3045 37.2211 99.3923C37.1918 99.4802 37.1331 99.5094 37.0745 99.5094Z"
          fill="#2E2E47"
        />
        <path
          d="M36.0193 102.487C36.1475 102.019 35.5293 101.442 34.6387 101.199C33.7481 100.956 32.9222 101.139 32.794 101.606C32.6659 102.074 33.284 102.651 34.1746 102.894C35.0653 103.137 35.8912 102.954 36.0193 102.487Z"
          fill="#F2836D"
        />
        <path
          d="M30.0063 100.241C29.889 100.709 29.2144 100.943 28.5398 100.738C27.8652 100.563 27.3959 100.036 27.5426 99.5674C27.6599 99.099 28.3345 98.8648 29.0091 99.0697C29.6837 99.2453 30.1529 99.7723 30.0063 100.241Z"
          fill="#F2836D"
        />
        <path
          d="M40.155 98.865C40.155 98.865 39.3338 102.378 39.803 102.847C40.2723 103.315 40.7416 103.286 41.3282 102.817C41.9148 102.349 43.2347 99.4505 42.8827 99.1285C42.5601 98.8065 40.155 98.865 40.155 98.865Z"
          fill="#9C6F43"
        />
        <path
          d="M30.8858 102.525C30.8565 102.525 30.8565 102.525 30.8272 102.525C30.1233 102.29 29.742 101.939 29.6833 101.5C29.5953 100.973 30.0353 100.446 30.3286 100.27C32.0004 99.0993 31.8244 98.0454 31.7364 97.6063C31.7071 97.4599 31.6484 97.372 31.5311 97.3135C31.2085 97.1086 30.5632 97.2549 30.3579 97.3135C30.2699 97.3428 30.1526 97.2842 30.1233 97.1964C30.0939 97.1086 30.1526 96.9915 30.2406 96.9622C30.2699 96.9622 31.1791 96.6694 31.7364 96.9915C31.9417 97.1086 32.059 97.3135 32.1177 97.5477C32.2057 98.0161 32.4403 99.3042 30.5632 100.592C30.3872 100.709 30.0353 101.09 30.0939 101.471C30.1526 101.822 30.5925 102.056 30.9738 102.173C31.0618 102.203 31.1205 102.32 31.0911 102.407C31.0325 102.466 30.9738 102.525 30.8858 102.525Z"
          fill="#2E2E47"
        />
        <path
          d="M30.0947 93.3904C30.0947 93.3904 31.9719 87.5352 34.9929 87.3596C38.0139 87.2132 41.9148 88.1207 43 88.5013C45.3464 89.3796 46.8129 92.4243 47.0182 93.5075C47.3408 95.0591 46.3729 97.0791 45.8743 98.865L45.2584 100.563C45.2584 100.563 42.2667 99.8896 41.4162 99.5676C34.0543 96.7571 30.7986 94.3858 30.0947 93.3904Z"
          fill="#FA634B"
        />
        <path
          d="M36.1678 100.212C36.1384 100.212 36.1091 100.212 36.0798 100.182C34.9652 99.5383 33.9387 99.4797 33.9387 99.4797C33.8214 99.4797 33.7627 99.3919 33.7627 99.2748C33.7627 99.1577 33.8507 99.0991 33.968 99.0991C34.0267 99.0991 35.0825 99.1577 36.2851 99.831C36.3731 99.8896 36.4024 100.007 36.3437 100.094C36.2851 100.153 36.2264 100.212 36.1678 100.212Z"
          fill="#2E2E47"
        />
        <path
          d="M34.7896 99.4501C34.6136 99.3916 34.291 99.2745 34.027 99.5965C33.763 99.9185 33.7923 100.563 34.027 100.914C34.2616 101.265 34.8482 101.119 35.0242 100.826C35.2002 100.563 35.3468 99.8893 35.2295 99.7429C35.1709 99.5965 34.9362 99.5087 34.7896 99.4501Z"
          fill="#2E2E47"
        />
        <path
          d="M31.2124 98.7187C31.1537 98.7187 31.0951 98.6894 31.0364 98.6309C31.0071 98.6016 30.5671 97.8697 29.5699 98.1917C29.4819 98.221 29.3646 98.1625 29.3353 98.0746C29.3059 97.9868 29.3646 97.8697 29.4526 97.8405C30.5085 97.4891 31.1537 98.0746 31.3591 98.4552C31.4177 98.5431 31.3884 98.6602 31.3004 98.7187C31.2711 98.7187 31.2417 98.7187 31.2124 98.7187Z"
          fill="#2E2E47"
        />
        <path
          d="M30.535 98.1333C30.447 97.9577 30.0363 97.7235 29.7724 97.9869C29.5377 98.2504 29.4204 98.8359 29.5377 99.158C29.655 99.48 30.095 99.3629 30.271 99.1287C30.4763 98.8945 30.6523 98.3675 30.535 98.1333Z"
          fill="#2E2E47"
        />
        <path
          d="M30.1527 93.1558C30.1527 93.1558 21.9109 92.8045 22.1455 93.712C22.3802 94.6195 26.3104 96.5517 28.9208 97.0787C31.5312 97.6057 40.9462 99.7135 41.3274 99.5379C41.5621 99.45 36.4293 97.2544 33.1443 95.4393C30.9446 94.239 30.1527 93.1558 30.1527 93.1558Z"
          fill="#2E2E47"
        />
        <path
          d="M29.1553 102.495C29.1553 102.495 31.2377 103.461 32.5869 103.578C32.5869 103.578 31.7657 104.574 30.5045 104.574C29.2726 104.574 29.1553 102.495 29.1553 102.495Z"
          fill="white"
        />
        <path
          d="M80.5718 174.513C80.5718 174.513 80.8651 177.06 80.2199 177.236C79.5746 177.382 79.6626 176.329 79.6626 176.329C79.6626 176.329 79.34 176.709 79.0467 176.68C78.7827 176.68 78.7534 175.919 78.7534 175.919C78.7534 175.919 78.5187 176.241 78.2547 175.919C77.9908 175.597 78.2547 172.464 78.4307 172.084C78.636 171.703 80.2492 172.991 80.5718 174.513Z"
          fill="#FFA982"
        />
        <path
          d="M75.438 165.702C75.438 165.702 80.2188 170.971 80.6001 171.82C80.9814 172.669 82.3012 175.802 81.6266 176.036C80.952 176.27 79.4855 172.903 78.8989 172.611C78.283 172.318 77.5791 172.289 77.3151 172.962C77.0511 173.635 77.6377 174.806 77.1391 175.392C76.6405 175.977 76.0832 175.597 76.1126 174.836C76.1419 174.074 75.4673 172.464 74.2061 171.176C72.9449 169.888 72.8276 168.863 72.3876 167.429C71.9184 166.024 75.086 165.321 75.438 165.702Z"
          fill="#FFA982"
        />
        <path
          d="M67.3736 141.081C66.7577 139.968 65.7311 138.212 64.7046 136.602C62.4755 139.617 59.7478 140.027 57.4014 140.173C57.9586 141.256 58.3106 142.34 58.5159 143.189C58.8092 144.389 59.2492 145.56 59.8358 146.672C63.1207 152.879 73.2396 171.323 77.1112 170.825C78.6657 170.62 70.9226 147.375 67.3736 141.081Z"
          fill="#FFA982"
        />
        <path
          d="M65.5254 137.216C60.8032 129.663 50.8603 116.431 47.106 115.611C37.8964 113.561 51.0949 135.811 56.4917 142.662C58.8381 142.515 63.2669 140.232 65.5254 137.216Z"
          fill="#C96B59"
        />
        <path
          d="M43.9357 175.07C43.8477 175.07 43.789 175.011 43.7597 174.923C43.7597 174.865 42.3225 170.356 41.4719 168.863C40.8267 167.692 36.5738 167.868 34.99 168.014C34.8727 168.014 34.7847 167.956 34.7847 167.839C34.7847 167.722 34.8433 167.634 34.9606 167.634C35.5766 167.575 40.9147 167.077 41.8239 168.688C42.6745 170.239 44.0823 174.631 44.141 174.836C44.1703 174.923 44.1116 175.04 44.0237 175.07C43.965 175.04 43.9357 175.07 43.9357 175.07Z"
          fill="white"
        />
        <path
          d="M32.7364 169.771C32.6777 169.771 32.619 169.741 32.5897 169.683C32.5311 169.595 32.5311 169.478 32.619 169.419C33.6456 168.629 39.101 168.804 39.7169 168.834C39.8343 168.834 39.8929 168.922 39.8929 169.039C39.8929 169.156 39.8049 169.244 39.6876 169.214C38.0451 169.156 33.6163 169.127 32.8537 169.741C32.8244 169.771 32.7657 169.771 32.7364 169.771Z"
          fill="white"
        />
        <path
          d="M44.9933 167.897C44.9053 167.897 44.8173 167.839 44.8173 167.751C44.7879 167.634 44.8759 167.546 44.9639 167.517L54.7015 165.877C54.8188 165.848 54.9068 165.936 54.9362 166.024C54.9655 166.141 54.8775 166.229 54.7895 166.258L45.0519 167.897C44.9933 167.897 44.9933 167.897 44.9933 167.897Z"
          fill="white"
        />
        <path
          d="M64.1161 202.999C64.0868 202.999 64.0282 202.999 63.9988 202.97C63.7935 202.853 63.5002 202.706 65.3187 196.968C66.0813 194.539 66.9025 192.196 66.9025 192.167C66.9318 192.079 67.0492 192.021 67.1371 192.05C67.2251 192.079 67.2838 192.196 67.2545 192.284C67.2545 192.314 66.4626 194.626 65.6706 197.056C64.5268 200.628 64.2628 201.945 64.2041 202.414C65.8173 200.335 66.9025 198.374 66.8732 198.081C66.8732 197.964 66.9318 197.876 67.0198 197.847C67.1078 197.817 67.2251 197.905 67.2251 197.993C67.3425 198.579 65.6413 201.155 64.4388 202.706C64.4388 202.824 64.2921 202.999 64.1161 202.999Z"
          fill="white"
        />
        <path
          d="M68.722 203.848C68.6047 203.848 68.5167 203.76 68.5167 203.643C68.5167 203.057 68.0181 195.504 67.9888 195.416C67.9888 195.299 68.0474 195.211 68.1647 195.211C68.2821 195.211 68.3701 195.27 68.3701 195.387C68.3994 195.709 68.898 203.028 68.898 203.643C68.9273 203.76 68.8393 203.848 68.722 203.848Z"
          fill="white"
        />
        <path
          d="M29.509 126.736C29.509 126.736 30.6529 124.891 34.9644 125.067C39.3053 125.243 41.4757 127.116 41.2997 129.019C41.1237 130.893 35.991 130.893 32.3247 130.395C28.6291 129.897 27.8958 127.877 29.509 126.736Z"
          fill="white"
        />
        <path
          d="M10.0306 136.483H10.0013C9.88397 136.454 9.82531 136.366 9.85464 136.249C9.85464 136.191 10.9985 130.16 11.6438 127.057C11.6731 126.94 11.7611 126.881 11.8784 126.91C11.9957 126.94 12.0544 127.027 12.0251 127.144C11.3798 130.248 10.2359 136.279 10.2359 136.337C10.2066 136.425 10.1186 136.483 10.0306 136.483Z"
          fill="#2E2E47"
        />
      </g>
      <defs>
        <clipPath id="clip0_7081_32199">
          <rect width="339.056" height="266" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChildrenIcon;
