import { useMemo } from 'react';

const useGeocoder = (): { geocode: (postalCode: string) => Promise<google.maps.GeocoderResponse> | undefined } => {
  // Creates a Geocoder instance
  const geocoder = useMemo<google.maps.Geocoder | null>(() => {
    return new google.maps.Geocoder();
  }, []);

  const geocode = (postalCode: string) => {
    return geocoder?.geocode({
      componentRestrictions: {
        country: 'US',
        postalCode,
      },
    });
  };

  return {
    geocode,
  };
};

export default useGeocoder;
