import React from 'react';
import { apiUrls } from '../../variables/urls';
import { useHistory, useLocation } from 'react-router-dom';
import { Breakpoint, Button, Grid, Typography } from '@mui/material';
import ProgressBox from '../ProgressBox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BREAKPOINTS } from '../../variables/constant';
import useProgressBarStatus from '../../pages/Dashboard/progress-bar-status-hook';

const ProgressBoxList = () => {
  const history = useHistory();
  const theme = useTheme();
  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint),
  );

  const location = useLocation();
  const selected =
    location.pathname === '/'
      ? apiUrls.PERSONAL_INFORMATION
      : location.pathname;

  const onTabClick = (tabURL: string) => () => {
    history.push(tabURL);
  };

  const {
    personalInformationProgress,
    childrenInfoProgress,
    guardianshipProgress,
    petsProgress,
    personalRepresentativeProgress,
    trusteeProgress,
    financialAssetsProgress,
    beneficiaryProgress,
    powerOfAttorneyProgress,
  } = useProgressBarStatus();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      alignItems="center"
      sx={{
        width: '100%',
        mt: 1,
      }}
    >
      <Grid item container sx={{ alignItems: 'center' }}>
        <Grid item container xs={12} direction="column">
          <Grid item>
            {desktopView ? (
              <Typography variant="sideLabel" component="p" align="left">
                Estate Plan Documents
              </Typography>
            ) : (
              <Typography
                variant="lDisplay"
                component="p"
                sx={{
                  textAlign: 'center',
                }}
              >
                My Information
              </Typography>
            )}
          </Grid>
          <Grid item sx={{ mt: 0.5, mb: 7 }}>
            {desktopView ? (
              <Typography
                variant="sTextInfo"
                component="p"
                sx={{
                  fontWeight: 400,
                }}
              >
                As you progress we’ll save your information.
              </Typography>
            ) : (
              <Typography
                variant="s"
                component="p"
                sx={{
                  textAlign: 'center',
                }}
              >
                Here’s where you left off. There’s only a few more steps to
                complete your estate plan.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title={desktopView ? 'Personal Information' : 'Personal Info'}
          timeToComplete={personalInformationProgress.time_to_complete}
          completed={personalInformationProgress.completed}
          total={personalInformationProgress.total}
          filled={personalInformationProgress.filled}
          selected={selected.startsWith(apiUrls.PERSONAL_INFORMATION)}
          onClick={onTabClick(apiUrls.PERSONAL_INFORMATION)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Children"
          timeToComplete={childrenInfoProgress.time_to_complete}
          completed={childrenInfoProgress.completed}
          total={childrenInfoProgress.total}
          filled={childrenInfoProgress.filled}
          selected={selected.startsWith(apiUrls.CHILDREN)}
          onClick={onTabClick(apiUrls.CHILDREN)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Guardianship"
          timeToComplete={guardianshipProgress.time_to_complete}
          completed={guardianshipProgress.completed}
          total={guardianshipProgress.total}
          filled={guardianshipProgress.filled}
          selected={selected.startsWith(apiUrls.GUARDIANSHIP)}
          onClick={onTabClick(apiUrls.GUARDIANSHIP)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Pets"
          timeToComplete={petsProgress.time_to_complete}
          completed={petsProgress.completed}
          total={petsProgress.total}
          filled={petsProgress.filled}
          selected={selected.startsWith(apiUrls.PETS)}
          onClick={onTabClick(apiUrls.PETS)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Personal Representative"
          timeToComplete={personalRepresentativeProgress.time_to_complete}
          completed={personalRepresentativeProgress.completed}
          total={personalRepresentativeProgress.total}
          filled={personalRepresentativeProgress.filled}
          selected={selected.startsWith(apiUrls.PERSONAL_REPRESENTATIVE)}
          onClick={onTabClick(apiUrls.PERSONAL_REPRESENTATIVE)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Trustees"
          timeToComplete={trusteeProgress.time_to_complete}
          completed={trusteeProgress.completed}
          total={trusteeProgress.total}
          filled={trusteeProgress.filled}
          selected={selected.startsWith(apiUrls.TRUSTEES)}
          onClick={onTabClick(apiUrls.TRUSTEES)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Assets"
          timeToComplete={financialAssetsProgress.time_to_complete}
          completed={financialAssetsProgress.completed}
          total={financialAssetsProgress.total}
          filled={financialAssetsProgress.filled}
          selected={selected.startsWith(apiUrls.ASSETS)}
          onClick={onTabClick(apiUrls.ASSETS)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Beneficiaries"
          timeToComplete={beneficiaryProgress.time_to_complete}
          completed={beneficiaryProgress.completed}
          total={beneficiaryProgress.total}
          filled={beneficiaryProgress.filled}
          selected={selected.startsWith(apiUrls.BENEFICIARIES)}
          onClick={onTabClick(apiUrls.BENEFICIARIES)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <ProgressBox
          title="Power of Attorney"
          timeToComplete={powerOfAttorneyProgress.time_to_complete}
          completed={powerOfAttorneyProgress.completed}
          total={powerOfAttorneyProgress.total}
          filled={powerOfAttorneyProgress.filled}
          selected={selected.startsWith(apiUrls.POWER_OF_ATTORNEY_GENERAL)}
          onClick={onTabClick(apiUrls.POWER_OF_ATTORNEY_GENERAL)}
        />
      </Grid>
      <Grid item container sx={{ alignItems: 'center' }}>
        <Grid item xs={12}>
          {!desktopView && (
            <Button
              variant="contained"
              color="primary"
              onClick={onTabClick(selected)}
              sx={{
                width: '320px',
              }}
            >
              Resume
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ProgressBoxList);
