import React from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { DevtoolTypes } from '../../variables/types';

interface Props {
  flag: string;
  checked: boolean;
  featureFlagDispatch: Function;
}

const Flag = ({ flag, checked, featureFlagDispatch }: Props) => {
  const handleChange = () => {
    featureFlagDispatch({
      type: DevtoolTypes.UpdateFlag,
      payload: {
        [flag]: !checked,
      },
    });
  };

  return (
    <TableRow>
      <TableCell>{flag}</TableCell>
      <TableCell>
        <Checkbox checked={checked} onChange={handleChange} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Flag);
