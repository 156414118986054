import React from 'react';
import { useHistory } from 'react-router-dom';
import usePowerOfAttorneys from './power-of-attorneys-hooks';
import { apiUrls } from '../../variables/urls';
import { PersonType, PowerOfAttorneyType } from '../../generated/graphql';
import PowerOfAttorneyForm from './PowerOfAttorneyForm';
import Loader from '../../components/Loader';

interface Props {
  uiFlag?: string;
  setEditState?: Function;
}

const GeneralPowerOfAttorney = ({ uiFlag, setEditState }: Props) => {
  const { loading, powerOfAttorneys } = usePowerOfAttorneys();

  const history = useHistory();

  return (
    <>
      <PowerOfAttorneyForm
        powerOfAttorney={powerOfAttorneys?.general}
        attorneyType={PowerOfAttorneyType.General}
        attorneyFor={PersonType.Self}
        header={
          'Who would you like to manage your finances ' +
          'and make legal decisions on your behalf if ' +
          'you are incapacitated but still alive?'
        }
        previous={() => history.push(apiUrls.BENEFICIARIES)}
        uiFlag={uiFlag}
        setEditState={setEditState}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(GeneralPowerOfAttorney);
