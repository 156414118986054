import { useContext, useEffect } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';

const usePowerOfAttorneys = () => {
  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } =
    useContext(DocumentContext);

  useEffect(() => {
    refetchPlanDocuments?.();
  }, [planDocuments, refetchPlanDocuments]);

  return {
    loading: loadingPlanDocuments,
    powerOfAttorneys: planDocuments?.power_of_attorneys,
  };
};

export default usePowerOfAttorneys;
