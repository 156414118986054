import React from 'react';
import { FormControl, TextField } from '@mui/material';
import useGoogleAutocomplete, {
  AddressProps,
} from '../../../hooks/maps/google-address-autocomplete';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import { DataForm } from './estate-form-hooks';

interface Props {
  index: number;
  control: Control<DataForm>;
  errors: FieldErrors<DataForm>;
  setValue: Function;
}

const AddressField = ({ index, control, errors, setValue }: Props) => {
  const onAutocompleteAddress = (place: AddressProps) => {
    setValue(`fieldArray.${index}.address`, place.address);
    setValue(`fieldArray.${index}.city`, place.city);
    setValue(`fieldArray.${index}.state`, place.state);
    setValue(`fieldArray.${index}.zip_code`, place.postalCode);
  };

  const { googleAutoCompleteRef } = useGoogleAutocomplete({
    onPlaceSelect: onAutocompleteAddress,
  });

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <Controller
        name={`fieldArray.${index}.address`}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            label="Address"
            variant="outlined"
            value={value}
            onChange={onChange}
            inputRef={googleAutoCompleteRef}
          />
        )}
        rules={{
          required: 'Please enter the address',
        }}
        control={control}
      />
      {errors?.fieldArray?.[index]?.address && (
        <FormHelperErrorText>
          {errors?.fieldArray[index]?.address?.message}
        </FormHelperErrorText>
      )}
    </FormControl>
  );
};

export default AddressField;
