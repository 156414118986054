import React from 'react';
import { apiUrls } from '../../variables/urls';
import { Route, Switch } from 'react-router-dom';
import PetsMain from './PetsMain';
import CompletionScreen from './CompletionScreen';

const Pets = () => {
  return (
    <Switch>
      <Route path={apiUrls.PETS} component={PetsMain} exact />
      <Route
        path={apiUrls.PETS_COMPLETION}
        component={CompletionScreen}
        exact
      />
    </Switch>
  );
};

export default React.memo(Pets);
