import React from 'react';
import { apiUrls } from '../../variables/urls';
import { Route, Switch } from 'react-router-dom';
import GeneralPowerOfAttorney from './GeneralPowerOfAttorney';
import MedicalPowerOfAttorney from './MedicalPowerOfAttorney';
import GeneralPowerOfAttorneySpouse from './GeneralPowerOfAttorneySpouse';
import MedicalPowerOfAttorneySpouse from './MedicalPowerOfAttorneySpouse';
import CompletionScreen from './CompletionScreen';

const PowerOfAttorney = () => {
  return (
    <Switch>
      <Route
        path={apiUrls.POWER_OF_ATTORNEY_GENERAL}
        component={GeneralPowerOfAttorney}
        exact
      />
      <Route
        path={apiUrls.POWER_OF_ATTORNEY_MEDICAL}
        component={MedicalPowerOfAttorney}
        exact
      />
      <Route
        path={apiUrls.POWER_OF_ATTORNEY_GENERAL_SPOUSE}
        component={GeneralPowerOfAttorneySpouse}
        exact
      />
      <Route
        path={apiUrls.POWER_OF_ATTORNEY_MEDICAL_SPOUSE}
        component={MedicalPowerOfAttorneySpouse}
        exact
      />
      <Route
        path={apiUrls.POWER_OF_ATTORNEY_COMPLETION}
        component={CompletionScreen}
        exact
      />
    </Switch>
  );
};

export default React.memo(PowerOfAttorney);
