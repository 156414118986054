import React, { useContext } from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import PowerOfAttorneyIcon from '../../components/Icons/CompletionScreen/PowerOfAttorneyIcon';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import Loader from '../../components/Loader';

const CompletionScreen = () => {
  const history = useHistory();

  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const onPreviousClick = () => {
    const isMarried = planDocuments?.user_info?.is_married;
    if (isMarried) {
      return history.push(apiUrls.POWER_OF_ATTORNEY_MEDICAL_SPOUSE);
    }
    return history.push(apiUrls.POWER_OF_ATTORNEY_MEDICAL);
  };

  return (
    <>
      <CompletionBox
        header={'Power of Attorney'}
        image={PowerOfAttorneyIcon}
        title="Fantastic! You're almost done!"
        quote={
          'For a younger guy like me, ' +
          'it’s peace of mind for my small ' +
          'family and it couldn’t be more simple.'
        }
        quoteBy="Matt"
        previous={() => onPreviousClick()}
        continue={() => history.push(apiUrls.SUMMARY)}
      />
      <Loader open={loadingPlanDocuments} />
    </>
  );
};

export default React.memo(CompletionScreen);
