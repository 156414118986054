import {
  Box,
  Button,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import TopBar from '../../../components/TopBar';
import useMobileView from './mobile-view-hooks';
import useCheckout, { CardProps } from './payment-hooks';
import AddressForm from './AddressForm';
import CreditCardForm from './CreditCardForm';
import Loader from '../../../components/Loader';
import dateFormat from 'dateformat';
import CardInfoLine from './CardInfoLine';
import { BillingPlan } from '../../../variables/constant';
import ApplePayButton from './ApplePayButton';

const CheckoutMobileView = () => {
  const {
    showPaymentType,
    setShowPaymentType,
    showCardForm,
    setShowCardForm,
    showShippingAddress,
    setShowShippingAddress,
  } = useMobileView();

  const {
    loading,
    userPlanPrice,
    shippingAddress,
    addShippingAddress,
    setAddShippingAddress,
    refetchAddress,
    setCardInfo,
    deliveryDate,
    orderReview,
    setOrderReview,
    onProceedToPay,
    setPaymentMethod,
    setPaymentInProgress,
    paymentDetails,
    showApplePay,
    userPlanName,
    setPayingWithApplePay,
    payingWithApplePay,
  } = useCheckout();

  const checkoutRow = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
    >
      <Typography variant="sCaps">checkout</Typography>
      <Typography variant="lDisplay">${userPlanPrice}</Typography>
    </Stack>
  );

  const onBackClick = () => {
    if (showCardForm) {
      setShowCardForm(false);
      setAddShippingAddress(false);
      setOrderReview(false);
      setShowShippingAddress(false);
      setShowPaymentType(true);
    } else if (showShippingAddress) {
      setShowPaymentType(false);
      setShowShippingAddress(false);
      setAddShippingAddress(false);
      setOrderReview(false);
      setShowCardForm(true);
    } else if (addShippingAddress) {
      setShowPaymentType(false);
      setAddShippingAddress(false);
      setShowCardForm(false);
      setOrderReview(false);
      setShowShippingAddress(true);
    } else if (orderReview) {
      setShowPaymentType(false);
      setAddShippingAddress(false);
      setShowCardForm(false);
      setShowShippingAddress(false);
      setOrderReview(false);
      setShowShippingAddress(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
    >
      <TopBar />
      {!showPaymentType && (
        <Box sx={{ textAlign: 'left', width: '100%', p: 2 }}>
          <Typography variant="sCaps" component="p" onClick={onBackClick}>
            {'< Back'}
          </Typography>
        </Box>
      )}
      {addShippingAddress ? (
        <Box sx={{ mt: 8, width: '100%', p: 2 }}>
          {checkoutRow}
          <Divider />
          <Stack
            spacing={1}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Typography variant="m" component="p" sx={{ textAlign: 'center' }}>
              Add new address
            </Typography>
            <AddressForm
              onShipToAddress={() => {
                setAddShippingAddress(false);
                setShowPaymentType(false);
                setShowCardForm(false);
                setOrderReview(false);
                setShowShippingAddress(true);
                refetchAddress();
              }}
            />
          </Stack>
        </Box>
      ) : (
        <Box sx={{ mt: 8, width: '100%', p: 2 }}>
          {(showPaymentType || showCardForm) && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ mb: 8 }}
            >
              <Typography variant="sCaps">Due now</Typography>
              <Typography variant="xlDisplay">${userPlanPrice}</Typography>
            </Stack>
          )}
          {(showPaymentType || showCardForm || showShippingAddress) && (
            <Typography
              variant="mDisplay"
              component="p"
              sx={{ textAlign: 'center' }}
            >
              Your Personalized {userPlanName || 'Trust'}
            </Typography>
          )}
          {showPaymentType && (
            <Box sx={{ textAlign: 'center' }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ mt: 5 }}
              >
                {userPlanName === BillingPlan.TrustPlan && (
                  <Box
                    sx={{
                      mb: 8,
                    }}
                  >
                    <ul
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <li>
                        <Typography variant="s" component="p">
                          Revokable living trust
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="s" component="p">
                          Guaranteed guardianship assurance
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowPaymentType(false);
                    setAddShippingAddress(false);
                    setShowShippingAddress(false);
                    setOrderReview(false);
                    setPayingWithApplePay(false);
                    setShowCardForm(true);
                  }}
                  fullWidth
                  sx={{ mt: 8 }}
                >
                  Pay with Card
                </Button>
                {showApplePay && (
                  <ApplePayButton
                    onClick={() => {
                      setShowPaymentType(false);
                      setAddShippingAddress(false);
                      setShowShippingAddress(false);
                      setOrderReview(false);
                      setShowCardForm(false);
                      setPayingWithApplePay(true);
                      setShowShippingAddress(true);
                    }}
                  />
                )}
              </Stack>
            </Box>
          )}
          {(showCardForm || showShippingAddress || orderReview) && (
            <Paper variant="outlined" sx={{ p: 2, width: '100%', mt: 5 }}>
              {checkoutRow}
              <Divider sx={{ mb: 2 }} />
              {showCardForm && (
                <>
                  <Typography
                    variant="lDisplay"
                    component="p"
                    sx={{ textAlign: 'center' }}
                  >
                    Pay with card
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <CreditCardForm
                      setCard={(data: CardProps) => {
                        setCardInfo(data);
                        setShowPaymentType(false);
                        setShowCardForm(false);
                        setAddShippingAddress(false);
                        setOrderReview(false);
                        setShowShippingAddress(true);
                      }}
                      shippingAddress={shippingAddress}
                      setPaymentMethod={setPaymentMethod}
                      setPaymentInProgress={setPaymentInProgress}
                    />
                  </Box>
                  <Button
                    fullWidth
                    type="submit"
                    form="card_form_id"
                    variant="contained"
                    color="primary"
                  >
                    Next
                  </Button>
                </>
              )}
              {showShippingAddress && (
                <>
                  <Stack direction="column" alignItems="flex-start" spacing={2}>
                    <Typography
                      variant="m"
                      component="p"
                      sx={{ textAlign: 'center' }}
                    >
                      Shipping Address
                    </Typography>
                    <Divider />
                    <Typography
                      variant="mLight"
                      component="p"
                      sx={{
                        wordWrap: 'break-word',
                      }}
                    >
                      {shippingAddress?.name}
                      <Box component="br" />
                      {shippingAddress?.address}
                      <Box component="br" />
                      {`${shippingAddress?.city}, ${shippingAddress?.state}`}
                      <Box component="br" />
                      {`USA, ${shippingAddress?.zip_code}`}
                    </Typography>
                    <Link
                      onClick={() => {
                        setShowPaymentType(false);
                        setShowCardForm(false);
                        setShowShippingAddress(false);
                        setOrderReview(false);
                        setAddShippingAddress(true);
                      }}
                    >
                      Add New Address
                    </Link>
                  </Stack>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 10 }}
                    onClick={() => {
                      setShowPaymentType(false);
                      setShowCardForm(false);
                      setShowShippingAddress(false);
                      setAddShippingAddress(false);
                      setOrderReview(true);
                    }}
                  >
                    Ship to this address
                  </Button>
                </>
              )}
              {orderReview && (
                <>
                  <Typography
                    variant="lDisplay"
                    component="p"
                    sx={{ textAlign: 'center' }}
                  >
                    Order Review
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Stack
                      direction="column"
                      divider={<Divider orientation="horizontal" flexItem />}
                      spacing={1}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Typography variant="mLonger" component="p">
                          Payment Method
                        </Typography>
                        <Link
                          onClick={() => {
                            setOrderReview(false);
                            setShowCardForm(false);
                            setShowShippingAddress(false);
                            setAddShippingAddress(false);
                            setPayingWithApplePay(false);
                            setShowPaymentType(true);
                          }}
                        >
                          Edit
                        </Link>
                      </Stack>
                      {payingWithApplePay ? (
                        <Typography variant="s" component="p">
                          Apple Pay
                        </Typography>
                      ) : (
                        <CardInfoLine
                          cardType={paymentDetails?.brand || ''}
                          cardNumber={paymentDetails?.last4 || ''}
                        />
                      )}
                    </Stack>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Stack
                      direction="column"
                      divider={<Divider orientation="horizontal" flexItem />}
                      spacing={1}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Typography variant="mLonger" component="p">
                          Shipping Address
                        </Typography>
                        <Link
                          onClick={() => {
                            setOrderReview(false);
                            setShowPaymentType(false);
                            setShowCardForm(false);
                            setAddShippingAddress(false);
                            setShowShippingAddress(true);
                          }}
                        >
                          Edit
                        </Link>
                      </Stack>
                      <Typography
                        variant="mLight"
                        component="p"
                        sx={{
                          wordWrap: 'break-word',
                        }}
                      >
                        {shippingAddress?.name}
                        <Box component="br" />
                        {shippingAddress?.address}
                        <Box component="br" />
                        {`${shippingAddress?.city}, ${shippingAddress?.state}`}
                        <Box component="br" />
                        {`USA, ${shippingAddress?.zip_code}`}
                      </Typography>
                      <Typography variant="m" component="p" sx={{ my: 2 }}>
                        {`Estimated delivery ${dateFormat(
                          deliveryDate,
                          'mmm dS, yyyy',
                        )}`}
                      </Typography>
                    </Stack>
                  </Box>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onProceedToPay}
                  >
                    Place My Order
                  </Button>
                </>
              )}
            </Paper>
          )}
        </Box>
      )}
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(CheckoutMobileView);
