import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint, TextField } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';
import { DesktopDatePicker } from '@mui/lab';

interface Props {
  value: Date | null;
  onChange: (...event: any[]) => void;
}

const DateOfBirthPicker = ({ value, onChange }: Props) => {
  const theme = useTheme();
  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint),
  );
  return (
    <DesktopDatePicker
      label="Date of Birth"
      inputFormat="MM/dd/yyyy"
      openTo="year"
      views={['year', 'month', 'day']}
      maxDate={new Date()}
      value={value}
      onChange={onChange}
      disableOpenPicker={!desktopView}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default React.memo(DateOfBirthPicker);
