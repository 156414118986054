import React from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import GuardianshipIcon from '../../components/Icons/CompletionScreen/GuardianshipIcon';

const CompletionScreen = () => {
  const history = useHistory();

  return (
    <CompletionBox
      header={'Guardianship'}
      image={GuardianshipIcon}
      title="Great! Your children will be taken care of."
      quote={
        'Thanks TruWill! I feel much better knowing ' +
        'that if something were to happen to me and my ' +
        'spouse our minor children would be properly cared ' +
        'for and loved!'
      }
      quoteBy="Kara"
      previous={() => history.push(apiUrls.GUARDIANSHIP)}
      continue={() => history.push(apiUrls.PETS)}
    />
  );
};

export default React.memo(CompletionScreen);
