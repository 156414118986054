import React from 'react';
import { Button } from '@mui/material';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import { FieldErrors, UnpackNestedValue } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonCheckMarkIcon from '../../components/Icons/ButtonCheckMarkIcon';

interface Props {
  value: UnpackNestedValue<any>;
  name: string;
  options?: Array<string>;
  errors: FieldErrors;
  onClick: (value: boolean | string) => void;
}

const ButtonSelectionGroup = ({ value, name, onClick, errors, options }: Props) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    normalButton: {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.lightGreen,
      },
    },
    selectedButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  });

  const classes = useStyles();

  return (
    !options ?
      <>
        <Button
          variant="outlined"
          className={value ? classes.selectedButton : classes.normalButton}
          sx={{
            mb: 1,
            mt: 4,
            width: 256,
            height: 43,
          }}
          onClick={() => onClick(true)}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 256,
              alignItems: 'center',
              mx: 2,
            }}
          >
            <Box>Yes</Box>
            <Box
              component={ButtonCheckMarkIcon}
              sx={{
                width: '1.203rem',
                height: '1.329rem',
                display: value ? 'block' : 'none',
              }}
            />
          </Box>
        </Button>
        <Button
          variant="outlined"
          className={
            value !== undefined && value === false
              ? classes.selectedButton
              : classes.normalButton
          }
          sx={{
            width: 256,
            height: 43,
          }}
          onClick={() => onClick(false)}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 256,
              alignItems: 'center',
              mx: 2,
            }}
          >
            <Box>No</Box>
            <Box
              component={ButtonCheckMarkIcon}
              sx={{
                display:
                  value !== undefined && value === false ? 'block' : 'none',
                width: '1.203rem',
                height: '1.329rem',
                color: 'white',
              }}
            />
          </Box>
        </Button>
        {errors?.[name] && (
          <FormHelperErrorText>{errors?.[name]?.message}</FormHelperErrorText>
        )}
      </>
      : 
      <>
        {options.map((option, key) => (
          <Button
            key={key}
            variant="outlined"
            className={value === option ? classes.selectedButton : classes.normalButton}
            sx={{
              mb: 1,
              mt: key === 0 ? 4 : 0,
              width: 256,
              height: 43,
            }}
            onClick={() => onClick(option)}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 256,
                alignItems: 'center',
                mx: 2,
              }}
            >
              <Box sx={{ textTransform: 'capitalize' }}>{option}</Box>
              <Box
                component={ButtonCheckMarkIcon}
                sx={{
                  width: '1.203rem',
                  height: '1.329rem',
                  display: value ? 'block' : 'none',
                }}
              />
            </Box>
          </Button>
        ))}
        {errors?.[name] && (
          <FormHelperErrorText>{errors?.[name]?.message}</FormHelperErrorText>
        )}
      </>
  );
};

export default React.memo(ButtonSelectionGroup);
