import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import {
  PersonalRepresentativeFragmentFragment,
  PersonType,
  usePersonalRepresentativeInputMutation,
} from '../../generated/graphql';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { SUMMARY } from '../Summary/constants';

export interface DataForm {
  first_choice: string;
  second_choice: string;
}

export interface PowerOfAttorneyInfoProps {
  personalRepresentative?: PersonalRepresentativeFragmentFragment | null;
  personalRepresentativeFor: PersonType;
  uiFlag?: string;
  setEditState?: Function;
}

const usePersonalRepresentativeForm = ({
  personalRepresentative,
  personalRepresentativeFor,
  uiFlag, 
  setEditState,
}: PowerOfAttorneyInfoProps) => {
  const defaultValues = {
    first_choice: '',
    second_choice: '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<DataForm>({
    defaultValues: {
      ...defaultValues,
    },
  });

  const history = useHistory();
  const { planDocuments, refetchPlanDocuments } = useContext(DocumentContext);

  useEffect(() => {
    if (personalRepresentative?.id) {
      reset({
        first_choice: personalRepresentative.first_choice_owner_name,
        second_choice: personalRepresentative.second_choice_owner_name || '',
      });
    }
  }, [personalRepresentative, reset]);

  const [UpsertPersonalRepresentative, { loading }] =
    usePersonalRepresentativeInputMutation();

  const nextURL = () => {
    const isMarried = planDocuments?.user_info?.is_married;
    switch (personalRepresentativeFor) {
      case PersonType.Self:
        if (isMarried) {
          return apiUrls.PERSONAL_REPRESENTATIVE_SPOUSE;
        }
        break;
      default:
        return apiUrls.PERSONAL_REPRESENTATIVE_COMPLETION;
    }

    return apiUrls.PERSONAL_REPRESENTATIVE_COMPLETION;
  };

  const onCreate = async (data: DataForm) => {
    try {
      await UpsertPersonalRepresentative({
        variables: {
          input: {
            id: personalRepresentative?.id || null,
            first_choice_owner_name: data.first_choice,
            second_choice_owner_name: data.second_choice,
            personal_representative_for: personalRepresentativeFor,
          },
        },
      });
      openSnackbar(
        {
          message: `
            ${personalRepresentative?.id ? 'Updated' : 'Created'}
            ${personalRepresentativeFor}
            personal representative successfully
            `,
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        history.push(nextURL());
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  return {
    loading,
    register,
    handleSubmit,
    create: onCreate,
    errors,
    control,
  };
};
export default usePersonalRepresentativeForm;
