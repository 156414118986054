/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const VisaIcon = (props: any) => {
  const theme = useTheme();
  return (
    <SvgIcon width="42" height="14" viewBox="0 0 42 14" fill="none" {...props}>
      <path
        d="M17.9614 13.7445H14.6035L16.7038 0.787109H20.0614L17.9614 13.7445Z"
        fill={theme.palette.common.visaBlue}
      />
      <path
        d="M30.1352 1.10438C29.4728 0.842208 28.4224 0.552734 27.1234
           0.552734C23.8074 0.552734 21.4723 2.31706 21.458 4.8395C21.4305
           6.70056 23.1299 7.73423 24.4009 8.35477C25.7 8.98886 26.1416 9.40274
           26.1416 9.96794C26.1284 10.836 25.0919 11.2361 24.1251
           11.2361C22.7845 11.2361 22.0662 11.0298 20.9745 10.5469L20.5324
           10.3399L20.0625 13.2485C20.85 13.6065 22.301 13.9242 23.8074
           13.9381C27.3307 13.9381 29.6245 12.2011 29.6517 9.51303C29.6651
           8.038 28.7677 6.90777 26.8331 5.9842C25.6587 5.39133 24.9394 4.99157
           24.9394 4.38496C24.9532 3.83349 25.5478 3.26865 26.8735
           3.26865C27.9651 3.24099 28.7672 3.5028 29.3748 3.76479L29.6785
           3.90238L30.1352 1.10438Z"
        fill={theme.palette.common.visaBlue}
      />
      <path
        d="M34.5975 9.15417C34.874 8.40978 35.9381 5.52879 35.9381
           5.52879C35.9242 5.55645 36.2141 4.77066 36.3799 4.28827L36.6146
           5.40476C36.6146 5.40476 37.2505 8.50633 37.3885 9.15417C36.8637
           9.15417 35.2607 9.15417 34.5975 9.15417ZM38.7424
           0.787109H36.1451C35.3441 0.787109 34.7356 1.02125 34.39
           1.86219L29.4023 13.7443H32.9256C32.9256 13.7443 33.5057
           12.1451 33.6304 11.8006C34.0169 11.8006 37.4444 11.8006 37.9416
           11.8006C38.038 12.2556 38.3423 13.7443 38.3423
           13.7443H41.4513L38.7424 0.787109Z"
        fill={theme.palette.common.visaBlue}
      />
      <path
        d="M11.7981 0.787109L8.50963 9.62282L8.15028 7.83083C7.54232 5.76312
           5.6356 3.51658 3.50781 2.39955L6.51992 13.7308H10.0708L15.3487
           0.787109H11.7981Z"
        fill={theme.palette.common.visaBlue}
      />
      <path
        d="M5.45774 0.787109H0.0552694L0 1.04892C4.21427 2.12418 7.00529
           4.71607 8.152 7.83138L6.97757 1.8763C6.78422 1.04873 6.19002
           0.814408 5.45774 0.787109Z"
        fill={theme.palette.common.visaYellow}
      />
    </SvgIcon>
  );
};

export default VisaIcon;
