import React from 'react';
import { Box, Typography } from '@mui/material';
import { useClipboard } from 'use-clipboard-copy';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';

interface Props {
  themeText: string;
  name: string;
  color: string;
}

const ColorBox = ({ themeText, name, color }: Props) => {
  const clipboard = useClipboard();

  const copyToClipboard = (textToCopy: string) => {
    clipboard.copy(textToCopy);
    openSnackbar({ message: 'Copied!' }, NotifierType.Success);
  };

  return (
    <Box my={2}>
      <Box onClick={() => copyToClipboard(themeText)}>
        <Box
          sx={{
            height: 100,
            width: 120,
            bgcolor: color,
          }}
        />
        <Typography
          sx={{
            my: 0.5,
          }}
          variant="s"
          component="p"
        >
          {name}
        </Typography>
        <Typography
          sx={{
            my: 0.5,
          }}
          variant="s"
          component="p"
        >
          {color}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(ColorBox);
