import { createTheme } from '@mui/material/styles';
import palette from './palette';

export const SYSTEM_FONTS = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const NOTO_SANS_DISPLAY = 'Noto Sans Display';
const PLAYFAIR_DISPLAY = 'Playfair Display';
const SHADOWS_INTO_LIGHT = 'Shadows Into Light';
const ROBOTO = 'Roboto';
const PLAYFAIR_DEFAULT = {
  fontFamily: [PLAYFAIR_DISPLAY, ...SYSTEM_FONTS].join(','),
  color: palette.common.naturalBlack,
};

const NOTO_SANS_DEFAULT = {
  fontFamily: [NOTO_SANS_DISPLAY, ...SYSTEM_FONTS].join(','),
  color: palette.common.naturalBlack,
};

const defaultTheme = createTheme();

const typography = {
  fontFamily: [
    NOTO_SANS_DISPLAY,
    PLAYFAIR_DISPLAY,
    SHADOWS_INTO_LIGHT,
    ...SYSTEM_FONTS,
  ].join(','),
  fontFamilySystem: SYSTEM_FONTS.join(','),
  xlDisplay: {
    ...PLAYFAIR_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(42),
    fontWeight: 900,
    lineHeight: 1.09,
  },
  lDisplay: {
    ...PLAYFAIR_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(32),
    fontWeight: 900,
    lineHeight: 1.05,
  },
  mDisplay: {
    ...PLAYFAIR_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(22),
    fontWeight: 700,
    lineHeight: 1.05,
  },
  lLight: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(26),
    fontWeight: 300,
    lineHeight: 1.1,
  },
  m: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(22),
    fontWeight: 600,
  },
  mLonger: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(17),
    fontWeight: 600,
    lineHeight: 1.2,
  },
  mLight: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(21),
    fontWeight: 300,
    lineHeight: 1.22,
  },
  mLink: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.1,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  sLink: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(12),
    fontWeight: 500,
    lineHeight: 1.8,
    cursor: 'pointer',
    letterSpacing: '0.05em',
    color: palette.primary.main,
  },
  s: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 300,
    lineHeight: 1.37,
  },
  sCaps: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(13),
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: '0.05rem',
    textTransform: 'uppercase' as const,
  },
  sInfo: {
    ...PLAYFAIR_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(13),
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: '0.05rem',
    color: palette.grey[500],
  },
  sLabel: {
    fontFamily: [ROBOTO, ...SYSTEM_FONTS].join(','),
    fontSize: defaultTheme.typography.pxToRem(12),
    fontWeight: 600,
    lineHeight: 0.9,
    color: palette.primary.main,
  },
  sLabelItalic: {
    fontFamily: [ROBOTO, ...SYSTEM_FONTS].join(','),
    fontSize: defaultTheme.typography.pxToRem(12),
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: 0.9,
    color: palette.primary.main,
  },
  disclaimer: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: 1.1,
    color: palette.grey[700],
  },
  helper: {
    fontFamily: [SHADOWS_INTO_LIGHT, ...SYSTEM_FONTS].join(','),
    color: palette.common.naturalBlack,
    fontSize: defaultTheme.typography.pxToRem(18),
    fontWeight: 400,
    lineHeight: 1.22,
  },
  feedback: {
    ...PLAYFAIR_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(32),
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '41px',
    letterSpacing: '0.02em',
  },
  // New design
  sHelper: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(12),
    fontWeight: 600,
    lineHeight: 1.36,
    color: palette.primary.main,
    textTransform: 'uppercase' as const,
  },
  xlText: {
    ...PLAYFAIR_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(48),
    fontWeight: 700,
    lineHeight: 1.1,
  },
  lgText: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(28),
    fontWeight: 600,
    lineHeight: 1.3,
  },
  mText: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(24),
    fontWeight: 700,
    lineHeight: 1.1,
  },
  smText: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  mTextInfo: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: 1.36,
  },
  sTextInfo: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: 1.36,
  },
  sTextInfoLight: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 1.36,
  },
  mLabel: {
    ...PLAYFAIR_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(24),
    fontWeight: 400,
    lineHeight: 1.1,
  },
  sideLabel: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(20),
    fontWeight: 600,
    lineHeight: 1.3,
  },
  sideBar: {
    ...NOTO_SANS_DEFAULT,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.1,
    cursor: 'pointer',
  },
};

export default typography;
