import React from 'react';
import MapContainer from './MapContainer';
import { Wrapper } from '@googlemaps/react-wrapper';
import Layout from '../../components/Layout';

const NotaryMap = () => {
  return (
    <Layout isDocumentPage={false}>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY!}
        libraries={['places']}
      >
        <MapContainer />
      </Wrapper>
    </Layout>
  );
};

export default React.memo(NotaryMap);
