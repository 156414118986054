import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import {
  useInfoInputMutation,
  BasicInfoType,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType } from '../../variables/types';

const useChildren = () => {
  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } = useContext(DocumentContext);
  const [hasChildren, setHasChildren] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showCompletionScreen, setShowCompletionScreen] = useState(false);
  const [UpdateUserInfo] = useInfoInputMutation();

  const setShowChildrenForm = (value: boolean) => {
    setShowForm(value);
    setHasChildren(value);
  };

  useEffect(() => {
    if (planDocuments?.user_info?.has_children) {
      setHasChildren(true);
    }
    if (planDocuments?.children?.length) {
      setShowForm(true);
    }
  }, [planDocuments, setHasChildren]);

  const UserInfoSetOption = async (value: boolean) => {
    try {
      await UpdateUserInfo({
        variables: {
          input:{
            section: BasicInfoType.Children,
            answer: value,
          },
        },
      });
      refetchPlanDocuments?.();
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    loading: loadingPlanDocuments,
    userChildren: planDocuments?.children,
    hasChildren,
    hasChildrenFlag: planDocuments?.user_info?.has_children ? true : false,
    setHasChildren,
    setShowChildrenForm,
    showForm,
    showCompletionScreen,
    setShowCompletionScreen,
    UserInfoSetOption,
  };
};

export default useChildren;
