import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useUserInfo from '../../core/useUserInfo';
import { Auth } from 'aws-amplify';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { apiUrls } from '../../variables/urls';

interface State {
  stateLoading: boolean;
  showPassword: boolean;
}

const useLogin = () => {
  const [values, setValues] = useState<State>({
    stateLoading: false,
    showPassword: false,
  });

  const [rememberMe, setRememberMe] = useState(false);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const setLoading = useCallback(
    (loading: boolean) => {
      setValues({
        ...values,
        stateLoading: loading,
      });
    },
    [values],
  );

  const [isLoadingUserInfo] = useUserInfo();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onUserLogin = useCallback(
    async (data: Record<string, string>) => {
      setLoading(true);
      try {
        const cognitoUsername = data.username.trim().toLowerCase();
        await Auth.signIn({
          username: cognitoUsername,
          password: data.password,
        });

        if (rememberMe) {
          await Auth.rememberDevice();
        }

        setLoading(false);
        history.push(apiUrls.ROOT);
      } catch (err: any) {
        setLoading(false);
        if (err?.code === 'NotAuthorizedException') {
          openSnackbar(
            { message: 'Incorrect Username or Password' },
            NotifierType.Error,
          );
          history.push(apiUrls.LOGIN);
        } else {
          openSnackbar({ message: err?.message }, NotifierType.Error);
        }
      }
    },
    [history, setLoading, rememberMe],
  );

  return {
    loading: isLoadingUserInfo || values.stateLoading,
    register,
    errors,
    handleSubmit,
    onUserLogin,
    showPassword: values.showPassword,
    rememberMe,
    handleClickShowPassword,
    handleRememberMe,
  };
};

export default useLogin;
