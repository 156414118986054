import React from 'react';
import { Portal, Backdrop, CircularProgress, Box } from '@mui/material';

type Props = {
  open: boolean;
};

const Loader = ({ open }: Props) => {
  return (
    <Portal>
      <Box
        component={Backdrop}
        sx={{
          zIndex: 'loader',
        }}
        open={open}
      >
        <CircularProgress color="primary" />
      </Box>
    </Portal>
  );
};

export default React.memo(Loader);
