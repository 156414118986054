import { useContext, useEffect, useState } from 'react';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { useFieldArray, useForm } from 'react-hook-form';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useGuardianshipInputMutation } from '../../generated/graphql';
import { SUMMARY } from '../Summary/constants';
export interface Props {
  uiFlag?: string;
  setEditState?: Function;
}

export interface DataForm {
  fieldArray: {
    id: string | null;
    full_name: string | null;
    primary_guardian_name: string | null;
    secondary_guardian_name: string | null;
    age: number | null;
    same_guardian_as_above: boolean;
  }[];
}

const useGuardianship = ({ uiFlag, setEditState }: Props) => {
  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } =
    useContext(DocumentContext);

  const history = useHistory();

  const [showGuardianBanner, setShowGuardianBanner] = useState(true);
  const [underAgeChildren, setUnderAgeChildren] = useState([]);

  const onBannerNextClick = () => {
    setShowGuardianBanner(false);
    if (underAgeChildren.length <= 0) {
      history.push(apiUrls.PETS);
    }
  };

  useEffect(() => {
    const children = planDocuments?.children;
    let childrenData = [] as any;

    if (children && children.length > 0) {
      const filteredList = children.filter((child) => Number(child?.age) < 18);
      if (filteredList.length > 0) {
        filteredList.map((child) => {
          return childrenData.push({
            id: child.id,
            full_name: child.full_name,
            primary_guardian_name: child.primary_guardian_name,
            secondary_guardian_name: child.secondary_guardian_name,
            age: child.age,
            same_guardian_as_above: false,
          });
        });
        setUnderAgeChildren(childrenData);
        replace(childrenData);
      }
    }
  }, [planDocuments]);

  const defaultValues = {
    fieldArray: [
      {
        id: '',
        full_name: '',
        primary_guardian_name: '',
        secondary_guardian_name: '',
        age: null,
        same_guardian_as_above: false,
      },
    ],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<DataForm>({ defaultValues });

  const { fields, replace } = useFieldArray({
    name: 'fieldArray',
    control,
    rules: {
      required: 'Please enter information of at least one child',
    },
  });

  const [UpdateGuardianship, { loading: loadingGuardianShip }] =
    useGuardianshipInputMutation();

  const onCreate = async (data: DataForm) => {
    try {
      await UpdateGuardianship({
        variables: {
          input: data.fieldArray.map((child: any) => {
            return {
              id: child?.id || null,
              full_name: child.full_name,
              primary_guardian_name: child.primary_guardian_name,
              secondary_guardian_name: child.secondary_guardian_name,
              age: child.age,
              same_guardian_as_above: child.same_guardian_as_above,
            };
          }),
        },
      });
      openSnackbar(
        {
          message: 'Added guardian successfully',
        },
        NotifierType.Success,
      );
      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        history.push(apiUrls.GUARDIANSHIP_COMPLETION);
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const watched = watch('fieldArray');

  return {
    loading: loadingPlanDocuments || loadingGuardianShip,
    showGuardianBanner,
    underAgeChildren,
    onBannerNextClick,
    onCreate,
    handleSubmit,
    control,
    errors,
    fields,
    watched,
  };
};

export default useGuardianship;
