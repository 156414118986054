import { forwardRef, useImperativeHandle, useRef } from 'react';

const StripeInput = forwardRef<any, any>((props, ref) => {
  const { component: Component, ...other } = props;
  const elementRef = useRef<any>();
  useImperativeHandle(ref, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <Component
      onReady={(element: any) => (elementRef.current = element)}
      {...other}
    />
  );
});
export default StripeInput;
