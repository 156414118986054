import { Controller } from 'react-hook-form';
import {
  PaymentStatus,
  usePaymentDetailsQuery,
  usePlanInfoQuery,
  usePlanMutation,
  useUserInfoQuery,
} from '../../generated/graphql';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useAuthProvider } from '../../core/authContext';
import { BillingPlan, PUBLIC } from '../../variables/constant';
import React, { useEffect, useState } from 'react';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';

const usePlanInfo = () => {
  const history = useHistory();
  const [stateLoading, setLoadingState] = useState<boolean>(false);

  const setLoading = (loading: boolean) => {
    setLoadingState(loading);
  };

  const { data: planInfo, loading: planInfoLoading } = usePlanInfoQuery({
    fetchPolicy: 'network-only',
    context: { clientName: PUBLIC },
  });

  const [recommendedPlan, setRecommendedPlan] = React.useState('');

  const [basicInfo, setBasicInfo] = useLocalStorage('basic-info', {});

  const { getUser } = useAuthProvider();
  const user = getUser();
  const userID = user?.id;

  const { data: userInfo, loading: userInfoLoading } = useUserInfoQuery({
    fetchPolicy: 'network-only',
    skip: !userID,
  });

  const { data: paymentData, loading: loadingPayment } = usePaymentDetailsQuery(
    {
      fetchPolicy: 'network-only',
      skip: !userID,
    },
  );

  useEffect(() => {
    if (paymentData?.paymentDetails.status === PaymentStatus.Success) {
      history.push(apiUrls.DASHBOARD);
    }
  }, [paymentData]);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo.userInfo.has_asset_above_threshold ||
        userInfo.userInfo.has_real_estate
      ) {
        setRecommendedPlan(BillingPlan.TrustPlan);
      } else {
        setRecommendedPlan(BillingPlan.BasicWill);
      }
    } else if (basicInfo) {
      if (basicInfo.has_asset_above_threshold || basicInfo.has_real_estate) {
        setRecommendedPlan(BillingPlan.TrustPlan);
      } else {
        setRecommendedPlan(BillingPlan.BasicWill);
      }
    }
  }, [userInfo, basicInfo, setRecommendedPlan]);

  const [updatePlan, { loading }] = usePlanMutation();

  const onPlanSelection = async (selectedPlan: string) => {
    setLoading(true);
    try {
      if (userID) {
        await updatePlan({
          variables: {
            input: {
              plan_id: selectedPlan,
              id: userID,
            },
          },
        });

        //TODO: Redirect to the next question page once created
        history.push('/');
      } else {
        basicInfo.plan_id = selectedPlan;
        setBasicInfo(basicInfo);
        history.push(apiUrls.CREATE_ACCOUNT);
      }
    } catch (err: any) {
      setLoading(false);
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  return {
    loading:
      planInfoLoading ||
      userInfoLoading ||
      stateLoading ||
      loading ||
      loadingPayment,
    Controller,
    planInfo: planInfo?.planInfo,
    recommendedPlan,
    onPlanSelection,
  };
};

export default usePlanInfo;
