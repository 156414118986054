import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import { apiUrls } from '../../variables/urls';
import PersonalInformation from '../PersonalInformation';
import Pets from '../Pets';
import Trustees from '../Trustees';
import FinancialAssets from '../Assets';
import Beneficiaries from '../Beneficiaries';
import PowerOfAttorney from '../PowerOfAttorney';
import Children from '../Children';
import Checkout from '../Checkout';
import PersonalRepresentative from '../PersonalRepresentative';
import Guardianship from '../Guardianship';

const PlanDocuments = () => {
  return (
    <Layout>
      <Switch>
        <Route
          path={[
            apiUrls.PERSONAL_INFORMATION,
            apiUrls.PERSONAL_INFORMATION_COMPLETION,
          ]}
          exact
          component={PersonalInformation}
        />
        <Route
          path={[apiUrls.CHILDREN, apiUrls.CHILDREN_COMPLETION]}
          exact
          component={Children}
        />
        <Route
          path={[apiUrls.GUARDIANSHIP, apiUrls.GUARDIANSHIP_COMPLETION]}
          exact
          component={Guardianship}
        />
        <Route
          path={[apiUrls.PETS, apiUrls.PETS_EDIT]}
          exact
          component={Pets}
        />
        <Route
          path={[
            apiUrls.PERSONAL_REPRESENTATIVE,
            apiUrls.PERSONAL_REPRESENTATIVE_SPOUSE,
            apiUrls.PERSONAL_REPRESENTATIVE_COMPLETION,
          ]}
          exact
          component={PersonalRepresentative}
        />
        <Route
          path={[apiUrls.TRUSTEES, apiUrls.TRUSTEES_COMPLETION]}
          exact
          component={Trustees}
        />
        <Route
          path={[
            apiUrls.ASSETS,
            apiUrls.ASSETS_REAL_ESTATE,
            apiUrls.ASSETS_BUSINESS,
            apiUrls.ASSETS_ACCOUNTS,
            apiUrls.ASSETS_PERSONAL_PROPERTIES,
            apiUrls.ASSETS_COMPLETION,
          ]}
          exact
          component={FinancialAssets}
        />
        <Route
          path={[apiUrls.BENEFICIARIES, apiUrls.BENEFICIARIES_EDIT]}
          exact
          component={Beneficiaries}
        />
        <Route
          path={[
            apiUrls.POWER_OF_ATTORNEY_GENERAL,
            apiUrls.POWER_OF_ATTORNEY_MEDICAL,
            apiUrls.POWER_OF_ATTORNEY_GENERAL_SPOUSE,
            apiUrls.POWER_OF_ATTORNEY_MEDICAL_SPOUSE,
            apiUrls.POWER_OF_ATTORNEY_COMPLETION,
          ]}
          exact
          component={PowerOfAttorney}
        />
        <Route path={apiUrls.CHECKOUT} exact component={Checkout} />
        <Redirect
          from={apiUrls.PLAN_DOCUMENTS}
          to={apiUrls.PERSONAL_INFORMATION}
        />
      </Switch>
    </Layout>
  );
};

export default React.memo(PlanDocuments);
