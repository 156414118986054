import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import TopBar from '../../../components/TopBar';
import React from 'react';
import TrustIcon from '../../../components/Icons/TrustIcon';
import CreditCardForm from './CreditCardForm';
import dateFormat from 'dateformat';
import useCheckout, { CardProps } from './payment-hooks';
import AddressForm from './AddressForm';
import Loader from '../../../components/Loader';
import CardInfoLine from './CardInfoLine';
import ApplePayButton from './ApplePayButton';

const CheckoutPaymentPage = () => {
  const {
    addresses,
    shippingAddress,
    handleShippingAddressChange,
    userPlanPrice,
    addShippingAddress,
    setAddShippingAddress,
    loading,
    refetchAddress,
    setCardInfo,
    deliveryDate,
    orderReview,
    setOrderReview,
    onProceedToPay,
    setPaymentMethod,
    setPaymentInProgress,
    paymentDetails,
    setPayingWithApplePay,
    payingWithApplePay,
    showApplePay,
  } = useCheckout();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
    >
      <TopBar />
      <Grid
        container
        display="flex"
        direction="column"
        sx={{ pt: 6, pl: 20, pr: 20 }}
      >
        <Grid item sx={{ mb: 1.5 }}>
          <Typography variant="xlDisplay">Checkout</Typography>
        </Grid>
        <Grid item container display="flex" direction="row" spacing={3}>
          <Grid item lg={8}>
            <Paper sx={{ p: 2 }} elevation={4}>
              {addShippingAddress ? (
                <Stack
                  spacing={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                  }}
                >
                  <Typography variant="m">
                    Add new address
                    <Divider
                      sx={{
                        mb: 3,
                      }}
                    />
                  </Typography>
                  <AddressForm
                    onShipToAddress={() => {
                      setAddShippingAddress(false);
                      refetchAddress();
                    }}
                  />
                </Stack>
              ) : (
                <Stack
                  spacing={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                  }}
                >
                  <Grid container display="flex" direction="row" sx={{ mb: 6 }}>
                    <Grid item lg={1}>
                      <Box
                        component={TrustIcon}
                        sx={{ width: '3.063rem', height: '2.875rem' }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={11}
                      container
                      display="flex"
                      direction="column"
                    >
                      <Grid item>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Typography variant="lDisplay">
                            Estate Plan
                          </Typography>
                          <Typography variant="lDisplay">
                            ${userPlanPrice}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  {orderReview ? (
                    <Box>
                      <Box sx={{ mt: 2 }}>
                        <Stack
                          direction="column"
                          divider={
                            <Divider orientation="horizontal" flexItem />
                          }
                          spacing={1}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="mLonger" component="p">
                              Payment Method
                            </Typography>
                            <Link
                              onClick={() => {
                                setOrderReview(false);
                                setPayingWithApplePay(false);
                              }}
                            >
                              Edit
                            </Link>
                          </Stack>
                          {payingWithApplePay ? (
                            <Typography variant="s" component="p">
                              Apple Pay
                            </Typography>
                          ) : (
                            <CardInfoLine
                              cardType={paymentDetails?.brand || ''}
                              cardNumber={paymentDetails?.last4 || ''}
                            />
                          )}
                        </Stack>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Stack
                          direction="column"
                          divider={
                            <Divider orientation="horizontal" flexItem />
                          }
                          spacing={1}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="mLonger" component="p">
                              Shipping Address
                            </Typography>
                            <Link
                              onClick={() => {
                                setOrderReview(false);
                              }}
                            >
                              Edit
                            </Link>
                          </Stack>
                          <Typography
                            variant="mLight"
                            component="p"
                            sx={{
                              wordWrap: 'break-word',
                            }}
                          >
                            {shippingAddress?.name}
                            <Box component="br" />
                            {shippingAddress?.address}
                            <Box component="br" />
                            {`${shippingAddress?.city}, ${shippingAddress?.state}`}
                            <Box component="br" />
                            {`USA, ${shippingAddress?.zip_code}`}
                          </Typography>
                          <Typography variant="m" component="p">
                            {`Estimated delivery ${dateFormat(
                              deliveryDate,
                              'mmm dS, yyyy',
                            )}`}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography variant="m">
                        Payment Details
                        <Divider
                          sx={{
                            mt: 1,
                            mb: 3,
                          }}
                        />
                      </Typography>
                      <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        alignItems="stretch"
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="flex-start"
                          spacing={2}
                          sx={{
                            width: '70%',
                          }}
                        >
                          <Typography variant="mLonger">
                            Credit/Debit Card
                          </Typography>
                          <CreditCardForm
                            setCard={(data: CardProps) => {
                              setCardInfo(data);
                              setOrderReview(true);
                            }}
                            shippingAddress={shippingAddress}
                            setPaymentMethod={setPaymentMethod}
                            setPaymentInProgress={setPaymentInProgress}
                          />
                        </Stack>
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          spacing={2}
                          sx={{
                            width: '30%',
                          }}
                        >
                          {showApplePay && (
                            <Typography variant="mLonger">Or Use</Typography>
                          )}
                          {showApplePay && (
                            <ApplePayButton
                              onClick={() => {
                                setPayingWithApplePay(true);
                                setOrderReview(true);
                              }}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={5}
                        mt={30}
                      >
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Typography variant="m">Shipping Address</Typography>
                          {addresses?.map((address, index) => {
                            return (
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                key={index}
                              >
                                <Grid item xs={3}>
                                  <Radio
                                    checked={address?.id === shippingAddress.id}
                                    onChange={handleShippingAddressChange(
                                      address?.id,
                                    )}
                                    value={address?.id}
                                    name={address?.id}
                                  />
                                </Grid>
                                <Grid item xs={9}>
                                  <Typography
                                    variant="mLight"
                                    component="p"
                                    sx={{
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    {address?.name}
                                    <Box component="br" />
                                    {address?.address}
                                    <Box component="br" />
                                    {`${address?.city}, ${address?.state}`}
                                    <Box component="br" />
                                    {`USA, ${address?.zip_code}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                          <Link
                            onClick={() => setAddShippingAddress(true)}
                            sx={{ cursor: 'pointer' }}
                          >
                            Add New Address
                          </Link>
                        </Stack>
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Typography variant="m">
                            Estimated Delivery
                          </Typography>
                          <Typography variant="mLight" component="p">
                            {'5–8 Business Days'}
                            <Box component="br" />
                            {`Around ${dateFormat(
                              deliveryDate,
                              'mmm dS, yyyy',
                            )}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>
              )}
            </Paper>
          </Grid>
          <Grid item lg={4}>
            <Paper sx={{ p: 2 }} elevation={4}>
              <Stack direction="column" spacing={2}>
                <Typography variant="lDisplay">Total</Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant="s">Subtotal</Typography>
                  <Typography variant="s">${userPlanPrice}</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant="s">Tax</Typography>
                  <Typography variant="s">$0</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant="mDisplay">Total</Typography>
                  <Typography variant="mDisplay">${userPlanPrice}</Typography>
                </Stack>
                {!addShippingAddress && !orderReview && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    form="card_form_id"
                  >
                    Proceed to Summary
                  </Button>
                )}
                {orderReview && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onProceedToPay}
                  >
                    Place Order
                  </Button>
                )}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Loader open={loading} />
    </Box>
  );
};

export default CheckoutPaymentPage;
