import React from 'react';
import { useTheme } from '@mui/material/styles';

const ButtonCheckMarkIcon = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 31.5C11.5 22.5 7 20 0.5 18C5 18 10.5 18 13 22.5C13.4977
        12.5456 18.1092 6.47271 29 0.5C17.4401 9.5 16.5 19 14.5 31.5Z"
        fill={theme.palette.common.white}
      />
    </svg>
  );
};

export default React.memo(ButtonCheckMarkIcon);
