import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';
import { StyledTooltip } from './StyledTooltip.styled';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  title: NonNullable<React.ReactNode>;
}

const HtmlTooltip = ({ title }: Props) => {
  const theme = useTheme();
  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint),
  );
  return (
    <StyledTooltip title={title} placement={desktopView ? 'right' : 'bottom'}>
      <InfoIcon
        sx={{
          color: theme.palette.primary.main,
          fontSize: '1rem',
          verticalAlign: 'middle',
          ml: 1,
          mb: 0.5,
        }}
      />
    </StyledTooltip>
  );
};

export default React.memo(HtmlTooltip);
