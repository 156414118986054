import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';

const useAccounts = () => {
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const [hasAccounts, setHasAccounts] = useState(false);

  useEffect(() => {
    if (
      (planDocuments?.assets?.financial_account_designations?.length || 0) > 0
    ) {
      setHasAccounts(true);
    }
  }, [planDocuments]);

  return {
    loading: loadingPlanDocuments,
    accounts: planDocuments?.assets?.financial_account_designations,
    hasAccounts,
    setHasAccounts,
  };
};

export default useAccounts;
