import React, { useContext } from 'react';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import { FormControl, FormGroup, Grid, Stack, Typography } from '@mui/material';
import TabLayout from '../../components/TabLayout';
import HtmlTooltip from '../../components/HtmlTooltip';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import { NOTO_SANS_DISPLAY } from '../../theme/typography';
import useUrlPush from './url-push-hook';
import { useDeleteAssetsMutation } from '../../generated/graphql';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import Loader from '../../components/Loader';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import getErrorMessage from '../../utils/getErrorMessage';

interface Props {
  forms: any;
  handleChange: any;
  selectedForms: string[];
}

const AssetsBanner = ({ forms, handleChange, selectedForms }: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const { realEstate, businessInterest, financialAccounts, otherItems } = forms;
  const error =
    [realEstate, businessInterest, financialAccounts, otherItems].filter(
      (v) => v,
    ).length === 0;

  const [DeleteAssets, { loading: deletingAssets }] = useDeleteAssetsMutation();
  const { refetchPlanDocuments } = useContext(DocumentContext);

  const { nextForm } = useUrlPush();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (error) {
      return;
    }
    try {
      await DeleteAssets({
        variables: {
          input: {
            real_estate: !realEstate,
            business_interest: !businessInterest,
            financial_accounts: !financialAccounts,
            other_items: !otherItems,
          },
        },
      });
      refetchPlanDocuments?.();
      openSnackbar(
        {
          message: 'Asset selection updated successfully',
        },
        NotifierType.Success,
      );
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
    nextForm(selectedForms[0]);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TabLayout
        title="Financial Assets"
        previous={() => history.push(apiUrls.TRUSTEES)}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="m" component={'div'}>
                Which of the following assets would you like to include in your
                estate?
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      What Are Financial Assets?
                      <br />
                      Financial assets are anything you own that adds financial
                      value. Examples of personal assets include: A home, other
                      property, checking and savings accounts, investment or
                      financial accounts, ownership interest in a business,
                      jewelry, collectors items, and life insurance policies.
                      <br /> <br />
                      Why Do I Need to List My Financial Assets?
                      <br />
                      Providing information regarding your financial assets
                      helps us determine which estate plan recommendation is
                      best for you and what instructions need to be provided to
                      you to best assist you in the proper funding / titling of
                      your assets.
                      <br /> <br />
                      Which Financial Assets Should I Include in My Trust or
                      Will?
                      <br />
                      Typically, real estate and assets such as checking,
                      savings, and investment accounts should be titled in the
                      name of your trust. Personal property items such as
                      furniture, jewelry, firearms, etc. can be specifically
                      gifted to individuals in your will. If you prefer that
                      your personal property items be divided equally among your
                      beneficiaries then there is no need to list these items
                      specifically as your will simply provides a general
                      provision regarding their equal distribution.
                      <br /> <br />
                      Am I Safe to Include Assets in My Trust or Will? And Do I
                      Still Own Everything?
                      <br />
                      Yes, you are safe to include assets in your trust as this
                      does not change who owns the assets, but rather how the
                      assets are owned (as trustees instead of as individuals).
                    </React.Fragment>
                  }
                />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{
                width: '100%',
              }}
            >
              <FormControl
                required
                error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={realEstate}
                        onChange={handleChange}
                        name="realEstate"
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      />
                    }
                    label="Real Estate"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        font: NOTO_SANS_DISPLAY,
                        fontSize: theme.typography.pxToRem(20),
                        fontWeight: 500,
                      },
                      backgroundColor: theme.palette.background.default,
                      border: '0.063rem solid',
                      borderColor: 'common.lightGreen',
                      borderRadius: '0.25rem',
                      width: '20rem',
                      mb: 2,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={financialAccounts}
                        onChange={handleChange}
                        name="financialAccounts"
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      />
                    }
                    label="Financial Accounts"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        font: NOTO_SANS_DISPLAY,
                        fontSize: theme.typography.pxToRem(20),
                        fontWeight: 500,
                      },
                      backgroundColor: theme.palette.background.default,
                      border: '0.063rem solid',
                      borderColor: 'common.lightGreen',
                      borderRadius: '0.25rem',
                      width: '20rem',
                      mb: 2,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={businessInterest}
                        onChange={handleChange}
                        name="businessInterest"
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      />
                    }
                    label="Business Interest"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        font: NOTO_SANS_DISPLAY,
                        fontSize: theme.typography.pxToRem(20),
                        fontWeight: 500,
                      },
                      backgroundColor: theme.palette.background.default,
                      border: '0.063rem solid',
                      borderColor: 'common.lightGreen',
                      borderRadius: '0.25rem',
                      width: '20rem',
                      mb: 2,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={otherItems}
                        onChange={handleChange}
                        name="otherItems"
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      />
                    }
                    label="Other Items"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        font: NOTO_SANS_DISPLAY,
                        fontSize: theme.typography.pxToRem(20),
                        fontWeight: 500,
                      },
                      backgroundColor: theme.palette.background.default,
                      border: '0.063rem solid',
                      borderColor: 'common.lightGreen',
                      borderRadius: '0.25rem',
                      width: '20rem',
                      mb: 2,
                    }}
                  />
                </FormGroup>
                {error && (
                  <FormHelperErrorText>
                    Please select at least one
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <Loader open={deletingAssets} />
      </TabLayout>
    </form>
  );
};

export default React.memo(AssetsBanner);
