import { useEffect, useState } from 'react';
import { useAuthProvider } from '../../core/authContext';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useLocation } from 'react-router-dom';
import getErrorMessage from '../../utils/getErrorMessage';
import { useCreateCheckoutSessionLazyQuery } from '../../generated/graphql';

const useStripeCheckout = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();

  const [planID, setPlanID] = useState();
  useEffect(() => {
    if (user?.user_info.plan_id) {
      setPlanID(user?.user_info.plan_id);
    }
  }, [user]);

  const [
    createCheckoutSession,
    {
      data: checkoutSession,
      loading: loadingCheckoutSession,
      error: checkoutSessionError,
    },
  ] = useCreateCheckoutSessionLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        plan_id: planID!,
      },
    },
  });

  useEffect(() => {
    if (checkoutSessionError) {
      openSnackbar(
        { message: getErrorMessage(checkoutSessionError) },
        NotifierType.Error,
      );
    }
    if (!loadingCheckoutSession && checkoutSession?.createCheckoutSession) {
      window.location.replace(checkoutSession?.createCheckoutSession);
    }
  }, [loadingCheckoutSession, checkoutSession, checkoutSessionError]);

  const redirectToStripeCheckout = async () => {
    await createCheckoutSession();
  };

  const search = useLocation().search;
  useEffect(() => {
    const canceled = new URLSearchParams(search).get('canceled');
    if (canceled) {
      openSnackbar(
        { message: 'Payment failed. Please try again.' },
        NotifierType.Error,
      );
    }
  }, [search]);

  return {
    loading: loadingCheckoutSession,
    redirectToStripeCheckout,
  };
};

export default useStripeCheckout;
