import React, { MouseEventHandler } from 'react';
import { Breakpoint, Button, Grid, Stack } from '@mui/material';
import { BREAKPOINTS } from '../../variables/constant';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SUMMARY } from '../../pages/Summary/constants';

type Props = {
  title: string;
  children: React.ReactNode;
  previous?: MouseEventHandler;
  next?: MouseEventHandler;
  isSubmitting?: boolean;
  uiFlag?: string;
  setEditState?: Function;
  saveNextFlag?: boolean;
};

const TabLayout = ({
  title,
  children,
  previous,
  next,
  isSubmitting,
  uiFlag,
  setEditState,
  saveNextFlag,
}: Props) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(
    theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint),
  );
  return (
    <Grid
      item
      flexDirection={'row'}
      justifyContent={'center'}
      sx={{
        width: '100%',
        mb: 1.5,
        mt: uiFlag === SUMMARY ? 0 : 11,
        [theme.breakpoints.down(BREAKPOINTS.MD as Breakpoint)]: {
          px: 2,
        },
        [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
          mt: 4,
        },
      }}
    >
      <Stack
        direction={'column'}
        spacing={2}
        justifyContent={'center'}
        sx={{ mt: 2 }}
      >
        {uiFlag !== SUMMARY && <Typography
          variant={mobileView ? 'mTextInfo' : 'mText'}
          component="p"
          sx={{
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              alignSelf: 'center',
              color: theme.palette.primary.main,
            },
          }}
        >
          {title}
        </Typography>}
        {children}
        {uiFlag !== SUMMARY ? <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'center'}
          sx={{ mt: 2 }}
        >
          {previous && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: '8.75rem' }}
              onClick={previous}
            >
              Previous
            </Button>
          )}
          {next ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '8.75rem' }}
              onClick={next}
            >
              Next
            </Button>
          ) : (
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: '8.75rem' }}
            >
              Next
            </Button>
          )}
        </Stack> : <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'center'}
          sx={{ mt: 2 }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ width: '8.75rem' }}
            onClick={(event) => setEditState ? setEditState('') : previous ? previous(event) : null}
          >
            {setEditState ? 'Cancel' : previous ? 'Previous' : null}
          </Button>
          {next ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '8.75rem' }}
              onClick={next}
            >
              {saveNextFlag ? 'Save & Next' : 'Save'}
            </Button>
          ) : (
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: '8.75rem' }}
            >
              {saveNextFlag ? 'Save & Next' : 'Save'}
            </Button>
          )}
        </Stack>}
      </Stack>
    </Grid>
  );
};

export default TabLayout;