import { Breakpoint } from '@mui/system';
import react from 'react';
import Layout from '../../components/Layout';
import { useTheme } from '@mui/material/styles';
import { BREAKPOINTS } from '../../variables/constant';
import { Typography, Box, Button, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import PersonalRepresentativeIcon from '../../components/Icons/CompletionScreen/PersonalRepresentativeIcon';

const Confirmation = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Layout isDocumentPage={false}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            width: '100vw',
          },
          [theme.breakpoints.up(BREAKPOINTS.SM as Breakpoint)]: {
            width: '90%',
            padding: '0% 5%',
          },
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{
            py: 5,
          }}
        >
          <Box component={PersonalRepresentativeIcon} />
        </Stack>
        <Typography
          variant="lDisplay"
          component="h2"
          sx={{
            mt: 4,
            textAlign: 'center',
          }}
        >
          Thank you for your purchase
        </Typography>
        <Typography variant="s" component="p" mt={4} align="center">
          Please click "Continue" to proceed.
        </Typography>
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(apiUrls.DASHBOARD)}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default react.memo(Confirmation);