interface PlanDetails {
  key: string;
  name: string;
  description: string;
}

export const BasicPlanDetails: PlanDetails[] = [
  {
    key: 'will',
    name: 'Will(s)',
    description:
      'Last Will & Testament (Pour Over Will): A document that outlines your final wishes as it pertains to your possessions, dependents, and final arrangements.',
  },
  {
    key: 'guardianship',
    name: 'Guardianship Nomination for Children and/or Pets',
    description:
      'A document that appoints who will look after your minor children and/or pets in the event you are unable to do so.',
  },
  {
    key: 'medical_directive',
    name: 'Medical Directive',
    description:
      'A document that allows you to provide instructions to your physicians regarding end-of-life care.\n',
  },
  {
    key: 'power_of_attorney',
    name: 'Power(s) of Attorney',
    description:
      'General Power of Attorney: A document that appoints someone to act on your behalf in the event that you cannot act for yourself in order to pay bills, banking transactions, insurance, real estate transactions, etc.',
  },
];

export const TrustPlanDetails: PlanDetails[] = [
  {
    key: 'trust_documents',
    name: 'Trust Documents',
    description:
      'A specific and detailed outline for the management, control and distribution of your assets during life and after death.',
  },
  {
    key: 'will',
    name: 'Will',
    description:
      'Last Will & Testament (Pour Over Will): A document that ensures that all personal property such as furniture, household & personal items, etc. funnel into your Trust when you pass away.',
  },
  {
    key: 'guardianship',
    name: 'Guardianship Nomination for Children and/or Pets',
    description:
      'A document that appoints who will look after your minor children and/or pets in the event you are unable to do so.',
  },
  {
    key: 'medical_directive',
    name: 'Medical Directive',
    description:
      'A document that allows you to provide instructions to your physicians regarding end-of-life care.\n',
  },
  {
    key: 'power_of_attorney',
    name: 'Power(s) of Attorney',
    description:
      'General Power of Attorney: A document that appoints someone to act on your behalf in the event that you cannot act for yourself in order to pay bills, banking transactions, insurance, real estate transactions, etc.',
  },
  {
    key: 'trust_certificate',
    name: 'Trust Certificate',
    description:
      'A document to show third parties in place of a copy of the Trust. This contains a summary of key provisions from the Trust but does not reveal other personal details.',
  },
];
