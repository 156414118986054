import React from 'react';
import Loader from '../../components/Loader';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import TabLayout from '../../components/TabLayout';
import { FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import HtmlTooltip from '../../components/HtmlTooltip';
import { Controller } from 'react-hook-form';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import { useTheme } from '@mui/material/styles';
import useGuardianship from './guardianship-hooks';
import { StyledSwitch } from '../../components/StyledSwitch/StyledSwitch.styled';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
  uiFlag?: string;
  setEditState?: Function;
}

const GuardianshipForm = ({ uiFlag, setEditState }: Props) => {
  const theme = useTheme();

  const history = useHistory();

  const { loading, control, errors, handleSubmit, onCreate, fields, watched } =
    useGuardianship({ uiFlag, setEditState });

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onCreate)} noValidate>
      <TabLayout
        title="Guardianship"
        previous={() => history.push(apiUrls.CHILDREN)}
        uiFlag={uiFlag}
        setEditState={setEditState}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="m" component="div">
                  Who do you want to nominate as guardians for your children?
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        What Should I Consider When Appointing a Guardian?
                        <br />
                        When considering who you should appoint to serve as
                        guardians over your minor children, there are a few
                        things to consider:
                        <br />
                        (1) Familiarity with your children and their interests;
                        <br />
                        (2) Parenting style and similarity to you and your
                        spouse;
                        <br />
                        (3) Proximity to your children and their established
                        social circle; and
                        <br />
                        (4) Financial and emotional stability within the
                        guardian’s own family.
                      </React.Fragment>
                    }
                  />
                </Typography>
              </Stack>
              <Typography
                variant="smText"
                component="div"
                sx={{ width: '80%', color: theme.palette.grey[700] }}
              >
                If something were to happen to you and your spouse, a legal
                guardian should be appointed if you have children under 18 or
                with special needs.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            {fields.map((child, index) => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                  key={child.id}
                >
                  {child.full_name && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'baseline',
                        my: 2,
                      }}
                    >
                      <Typography
                        variant="smText"
                        sx={{
                          alignSelf: 'flex-start',
                          fontWeight: 600,
                        }}
                      >
                        {child.full_name}
                        <Typography
                          variant="smText"
                          sx={{
                            alignSelf: 'flex-start',
                            color: theme.palette.grey[700],
                          }}
                        >
                          {` (${child.age} Years Old)`}
                        </Typography>
                      </Typography>
                      {index !== 0 && (
                        <Controller
                          name={`fieldArray.${index}.same_guardian_as_above`}
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              control={
                                <StyledSwitch
                                  sx={{ mx: 1.3, my: 1 }}
                                  checked={value}
                                  onChange={onChange}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Same Legal Guardians as Above"
                            />
                          )}
                          control={control}
                        />
                      )}
                    </Box>
                  )}
                  {(!watched[index].same_guardian_as_above || index === 0) && (
                    <>
                      <Typography
                        variant="smText"
                        sx={{
                          alignSelf: 'flex-start',
                        }}
                      >
                        Primary Legal Guardian
                      </Typography>
                      <FormControl
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Controller
                          name={`fieldArray.${index}.primary_guardian_name`}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Full Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                            />
                          )}
                          rules={{
                            required: 'Please name the primary guardian',
                          }}
                          control={control}
                        />
                        {errors?.fieldArray?.[index]?.primary_guardian_name && (
                          <FormHelperErrorText>
                            {
                              errors?.fieldArray[index]?.primary_guardian_name
                                ?.message
                            }
                          </FormHelperErrorText>
                        )}
                      </FormControl>
                      <Typography
                        variant="smText"
                        sx={{
                          alignSelf: 'flex-start',
                        }}
                      >
                        Secondary Legal Guardian
                        <Typography
                          variant="smText"
                          sx={{
                            color: theme.palette.grey[700],
                          }}
                        >
                          {' (Optional)'}
                        </Typography>
                      </Typography>
                      <FormControl
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Controller
                          name={`fieldArray.${index}.secondary_guardian_name`}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Full Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                            />
                          )}
                          control={control}
                        />
                      </FormControl>
                    </>
                  )}
                </Stack>
              );
            })}
          </Grid>
        </Grid>
        <Loader open={loading} />
      </TabLayout>
    </form>
  );
};

export default React.memo(GuardianshipForm);
