import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: string;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  apt_or_suit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  person_id: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  zip_code?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address?: Maybe<Scalars['String']>;
  apt_or_suit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export enum AddressType {
  Billing = 'BILLING',
  Main = 'MAIN',
  Shipping = 'SHIPPING',
}

export type AssetInput = {
  business_interest: Scalars['Boolean'];
  financial_accounts: Scalars['Boolean'];
  other_items: Scalars['Boolean'];
  real_estate: Scalars['Boolean'];
};

export type Assets = {
  __typename?: 'Assets';
  business_interests: Array<BusinessInterest>;
  financial_account_designations: Array<FinancialAccountDesignation>;
  personal_properties: Array<PersonalProperty>;
  real_estate_properties: Array<RealEstateProperty>;
};

export type BasicInfoInput = {
  email?: Maybe<Scalars['String']>;
  has_asset_above_threshold?: Maybe<Scalars['Boolean']>;
  has_children?: Maybe<Scalars['Boolean']>;
  has_real_estate?: Maybe<Scalars['Boolean']>;
  is_married?: Maybe<Scalars['Boolean']>;
  plan_id?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
};

export enum BasicInfoType {
  Children = 'CHILDREN',
  PersonalInformation = 'PERSONAL_INFORMATION',
  Pets = 'PETS',
}

export type Beneficiaries = {
  __typename?: 'Beneficiaries';
  full_name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  inheritance_age: Scalars['String'];
  inheritance_percentage: Scalars['String'];
  owner_id: Scalars['String'];
  owner_type: BeneficiaryType;
  user_id: Scalars['String'];
};

export type BeneficiariesInput = {
  age: Scalars['String'];
  full_name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  owner_id: Scalars['String'];
  owner_type: BeneficiaryType;
  percentage: Scalars['String'];
};

export enum BeneficiaryType {
  Child = 'CHILD',
  Other = 'OTHER',
}

export type BusinessInterest = {
  __typename?: 'BusinessInterest';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  relationship?: Maybe<BusinessRelationship>;
  state_formed_in?: Maybe<Scalars['String']>;
  type?: Maybe<BusinessType>;
  user_id: Scalars['String'];
};

export type BusinessInterestInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  relationship?: Maybe<BusinessRelationship>;
  state_formed_in: Scalars['String'];
  type: BusinessType;
};

export enum BusinessRelationship {
  Employee = 'EMPLOYEE',
  Founder = 'FOUNDER',
  Investor = 'INVESTOR',
  Member = 'MEMBER',
  Other = 'OTHER',
  Owner = 'OWNER',
  Partner = 'PARTNER',
}

export enum BusinessType {
  CCorporation = 'C_CORPORATION',
  Llc = 'LLC',
  NonProfit = 'NON_PROFIT',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  SCorporation = 'S_CORPORATION',
}

export type CheckoutSessionInput = {
  plan_id: Scalars['String'];
};

export enum ChildOfType {
  MeAndSomeoneElse = 'ME_AND_SOMEONE_ELSE',
  SpouseAndMe = 'SPOUSE_AND_ME',
  SpouseAndSomeoneElse = 'SPOUSE_AND_SOMEONE_ELSE',
}

export type Children = {
  __typename?: 'Children';
  age?: Maybe<Scalars['Int']>;
  child_of?: Maybe<ChildOfType>;
  date_of_birth: Scalars['Time'];
  full_name: Scalars['String'];
  id: Scalars['ID'];
  primary_guardian_name?: Maybe<Scalars['String']>;
  secondary_guardian_name?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type ChildrenInput = {
  child_of: ChildOfType;
  date_of_birth: Scalars['Time'];
  full_name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type Company = {
  __typename?: 'Company';
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  promo_code: Scalars['String'];
};

export type Counties = {
  __typename?: 'Counties';
  name: Scalars['String'];
};

export type Document = {
  __typename?: 'Document';
  family_trust: Scalars['String'];
  medical_directive: Scalars['String'];
  medical_power_of_attorney: Scalars['String'];
  power_of_attorney: Scalars['String'];
  self_will: Scalars['String'];
  spouse_medical_directive: Scalars['String'];
  spouse_medical_power_of_attorney: Scalars['String'];
  spouse_power_of_attorney: Scalars['String'];
  spouse_will: Scalars['String'];
};

export enum DocumentType {
  MedicalDirective = 'MEDICAL_DIRECTIVE',
  MedicalPowerOfAttorney = 'MEDICAL_POWER_OF_ATTORNEY',
  Plan = 'PLAN',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  SpouseMedicalDirective = 'SPOUSE_MEDICAL_DIRECTIVE',
  SpouseMedicalPowerOfAttorney = 'SPOUSE_MEDICAL_POWER_OF_ATTORNEY',
  SpousePlan = 'SPOUSE_PLAN',
  SpousePowerOfAttorney = 'SPOUSE_POWER_OF_ATTORNEY',
  TrustPlan = 'TRUST_PLAN',
}

export type FinancialAccountDesignation = {
  __typename?: 'FinancialAccountDesignation';
  account_type: FinancialAccountType;
  id: Scalars['String'];
  provider: Scalars['String'];
  user_id: Scalars['String'];
};

export type FinancialAccountDesignationInput = {
  ID?: Maybe<Scalars['String']>;
  account_type: FinancialAccountType;
  provider: Scalars['String'];
};

export enum FinancialAccountType {
  BusinessChecking = 'BUSINESS_CHECKING',
  BusinessSavings = 'BUSINESS_SAVINGS',
  Checking = 'CHECKING',
  Investment = 'INVESTMENT',
  Retirement = 'RETIREMENT',
  Savings = 'SAVINGS',
}

export type GuardianshipInput = {
  age: Scalars['Int'];
  full_name: Scalars['String'];
  id: Scalars['ID'];
  primary_guardian_name?: Maybe<Scalars['String']>;
  same_guardian_as_above?: Maybe<Scalars['Boolean']>;
  secondary_guardian_name?: Maybe<Scalars['String']>;
};

export type InfoInput = {
  answer: Scalars['Boolean'];
  section: BasicInfoType;
};

export type IsValidPromoCode = {
  __typename?: 'IsValidPromoCode';
  id?: Maybe<Scalars['ID']>;
  ok: Scalars['Boolean'];
};

export type Location = {
  __typename?: 'Location';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  DeleteAssets?: Maybe<Scalars['String']>;
  DeleteBusinessInterest?: Maybe<Scalars['String']>;
  DeleteChild?: Maybe<Scalars['String']>;
  DeleteFinancialAccountDesignation?: Maybe<Scalars['String']>;
  DeletePersonalProperty?: Maybe<Scalars['String']>;
  DeletePet?: Maybe<Scalars['String']>;
  DeleteRealEstateProperty?: Maybe<Scalars['String']>;
  UpdateGuardianship?: Maybe<Scalars['ID']>;
  UpdateUserInfo?: Maybe<Scalars['String']>;
  UpsertBeneficiaries?: Maybe<Scalars['String']>;
  UpsertBusinessInterests?: Maybe<Scalars['String']>;
  UpsertChildren?: Maybe<Scalars['ID']>;
  UpsertFinancialAccountDesignations?: Maybe<Scalars['String']>;
  UpsertPersonalProperties?: Maybe<Scalars['String']>;
  UpsertPersonalRepresentative?: Maybe<Scalars['String']>;
  UpsertPets?: Maybe<Scalars['ID']>;
  UpsertPowerOfAttorney?: Maybe<Scalars['String']>;
  UpsertRealEstateProperties?: Maybe<Scalars['String']>;
  UpsertTrustees?: Maybe<Scalars['ID']>;
  health: Scalars['Boolean'];
  paymentCreate?: Maybe<StripePayment>;
  personUpsert?: Maybe<Scalars['ID']>;
  updateBasicInfo?: Maybe<Scalars['ID']>;
  updateHasPets?: Maybe<Scalars['ID']>;
  updateOnBoardingStatus?: Maybe<Scalars['ID']>;
  updatePaymentStatus?: Maybe<Scalars['ID']>;
  updatePlan: UserInfo;
  updateRecommendationQuestionnaire: UserInfo;
  upsertShippingAddress?: Maybe<Scalars['ID']>;
};

export type MutationDeleteAssetsArgs = {
  input: AssetInput;
};

export type MutationDeleteBusinessInterestArgs = {
  id: Scalars['String'];
};

export type MutationDeleteChildArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFinancialAccountDesignationArgs = {
  id: Scalars['String'];
};

export type MutationDeletePersonalPropertyArgs = {
  id: Scalars['String'];
};

export type MutationDeletePetArgs = {
  id: Scalars['String'];
};

export type MutationDeleteRealEstatePropertyArgs = {
  id: Scalars['String'];
};

export type MutationUpdateGuardianshipArgs = {
  input: Array<GuardianshipInput>;
};

export type MutationUpdateUserInfoArgs = {
  input: InfoInput;
};

export type MutationUpsertBeneficiariesArgs = {
  input: Array<BeneficiariesInput>;
};

export type MutationUpsertBusinessInterestsArgs = {
  input: Array<BusinessInterestInput>;
};

export type MutationUpsertChildrenArgs = {
  input: Array<ChildrenInput>;
};

export type MutationUpsertFinancialAccountDesignationsArgs = {
  input: Array<FinancialAccountDesignationInput>;
};

export type MutationUpsertPersonalPropertiesArgs = {
  input: Array<PersonalPropertyInput>;
};

export type MutationUpsertPersonalRepresentativeArgs = {
  input: PersonalRepresentativeInput;
};

export type MutationUpsertPetsArgs = {
  input: Array<PetsInput>;
};

export type MutationUpsertPowerOfAttorneyArgs = {
  input: PowerOfAttorneyInput;
};

export type MutationUpsertRealEstatePropertiesArgs = {
  input: Array<RealEstatePropertyInput>;
};

export type MutationUpsertTrusteesArgs = {
  input: TrusteesInput;
};

export type MutationPaymentCreateArgs = {
  input: PaymentInput;
};

export type MutationPersonUpsertArgs = {
  input: PersonInput;
};

export type MutationUpdateBasicInfoArgs = {
  input: BasicInfoInput;
};

export type MutationUpdateHasPetsArgs = {
  input: Scalars['Boolean'];
};

export type MutationUpdateOnBoardingStatusArgs = {
  input: OnBoardingStatusInput;
};

export type MutationUpdatePaymentStatusArgs = {
  input: PaymentStatusInput;
};

export type MutationUpdatePlanArgs = {
  input: PlanInput;
};

export type MutationUpdateRecommendationQuestionnaireArgs = {
  input: RecommendationQuestionnaireInput;
};

export type MutationUpsertShippingAddressArgs = {
  input: AddressInput;
};

export enum OnBoardingStatus {
  AppointeesInformation = 'APPOINTEES_INFORMATION',
  Assets = 'ASSETS',
  AssetDisbursementInformation = 'ASSET_DISBURSEMENT_INFORMATION',
  BasicInfo = 'BASIC_INFO',
  Beneficiaries = 'BENEFICIARIES',
  Children = 'CHILDREN',
  DocumentReceived = 'DOCUMENT_RECEIVED',
  Guardianship = 'GUARDIANSHIP',
  Notarization = 'NOTARIZATION',
  Payment = 'PAYMENT',
  PersonalInformation = 'PERSONAL_INFORMATION',
  PersonalInformationSpouse = 'PERSONAL_INFORMATION_SPOUSE',
  Pets = 'PETS',
  Plan = 'PLAN',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  Trustees = 'TRUSTEES',
}

export type OnBoardingStatusInput = {
  on_boarding_status: OnBoardingStatus;
};

export type Payment = {
  __typename?: 'Payment';
  brand?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last4?: Maybe<Scalars['String']>;
  payment_method_id: Scalars['String'];
  plan_id: Scalars['String'];
  status: PaymentStatus;
  type?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type PaymentInput = {
  payment_method_id: Scalars['String'];
  plan_id: Scalars['String'];
};

export enum PaymentStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
}

export type PaymentStatusInput = {
  id: Scalars['ID'];
  status: PaymentStatus;
};

export type Person = {
  __typename?: 'Person';
  address?: Maybe<Address>;
  date_of_birth?: Maybe<Scalars['Time']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relation_type?: Maybe<RelationType>;
  type: PersonType;
  user_id: Scalars['String'];
};

export type PersonInput = {
  address: Scalars['String'];
  apt_or_suit?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  county: Scalars['String'];
  date_of_birth: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  last_name: Scalars['String'];
  middle_name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  relation_type?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  type: Scalars['String'];
  zip_code: Scalars['String'];
};

export enum PersonType {
  Self = 'SELF',
  Spouse = 'SPOUSE',
}

export type PersonalInformation = {
  __typename?: 'PersonalInformation';
  self?: Maybe<Person>;
  spouse?: Maybe<Person>;
};

export type PersonalProperty = {
  __typename?: 'PersonalProperty';
  beneficiary_owner_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  user_id?: Maybe<Scalars['String']>;
};

export type PersonalPropertyInput = {
  ID?: Maybe<Scalars['String']>;
  beneficiary_owner_name: Scalars['String'];
  description: Scalars['String'];
};

export type PersonalRepresentative = {
  __typename?: 'PersonalRepresentative';
  first_choice_owner_name: Scalars['String'];
  id: Scalars['ID'];
  personal_representative_for: PersonType;
  second_choice_owner_name: Scalars['String'];
  user_id: Scalars['String'];
};

export type PersonalRepresentativeInput = {
  first_choice_owner_name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  personal_representative_for: PersonType;
  second_choice_owner_name: Scalars['String'];
};

export type PersonalRepresentatives = {
  __typename?: 'PersonalRepresentatives';
  self?: Maybe<PersonalRepresentative>;
  spouse?: Maybe<PersonalRepresentative>;
};

export type Pets = {
  __typename?: 'Pets';
  id: Scalars['ID'];
  name: Scalars['String'];
  primary_guardian_name?: Maybe<Scalars['String']>;
  secondary_guardian_name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  user_id: Scalars['String'];
};

export type PetsInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  primary_guardian_name?: Maybe<Scalars['String']>;
  same_guardian_as_above?: Maybe<Scalars['Boolean']>;
  secondary_guardian_name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Place = {
  __typename?: 'Place';
  formatted_phone_number: Scalars['String'];
  id: Scalars['String'];
  location: Location;
  name: Scalars['String'];
  vicinity: Scalars['String'];
};

export type PlanDocument = {
  __typename?: 'PlanDocument';
  assets: Assets;
  beneficiaries?: Maybe<Array<Beneficiaries>>;
  children?: Maybe<Array<Children>>;
  is_document_complete?: Maybe<Scalars['Boolean']>;
  is_document_editable?: Maybe<Scalars['Boolean']>;
  manual_payment?: Maybe<Scalars['Boolean']>;
  personal_information?: Maybe<PersonalInformation>;
  personal_representatives?: Maybe<PersonalRepresentatives>;
  pets?: Maybe<Array<Pets>>;
  power_of_attorneys?: Maybe<PowerOfAttorneys>;
  progress?: Maybe<ProgressList>;
  progress_percentage?: Maybe<Scalars['Int']>;
  trustees?: Maybe<Trustees>;
  user_info: UserInfo;
};

export type PlanInfo = {
  __typename?: 'PlanInfo';
  additional_info?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  offer_price?: Maybe<Scalars['String']>;
  original_price?: Maybe<Scalars['String']>;
};

export type PlanInput = {
  id: Scalars['ID'];
  plan_id: Scalars['String'];
};

export type PowerOfAttorney = {
  __typename?: 'PowerOfAttorney';
  first_choice_owner_name: Scalars['String'];
  id: Scalars['ID'];
  power_of_attorney_for?: Maybe<PersonType>;
  power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
  second_choice_owner_name?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type PowerOfAttorneyInput = {
  first_choice_owner_name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  power_of_attorney_for: PersonType;
  power_of_attorney_type: PowerOfAttorneyType;
  second_choice_owner_name?: Maybe<Scalars['String']>;
};

export enum PowerOfAttorneyType {
  General = 'GENERAL',
  Medical = 'MEDICAL',
}

export type PowerOfAttorneys = {
  __typename?: 'PowerOfAttorneys';
  general?: Maybe<PowerOfAttorney>;
  general_for_spouse?: Maybe<PowerOfAttorney>;
  medical?: Maybe<PowerOfAttorney>;
  medical_for_spouse?: Maybe<PowerOfAttorney>;
};

export type Progress = {
  __typename?: 'Progress';
  completed: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  filled: Scalars['Int'];
  time_to_complete: Scalars['String'];
  total: Scalars['Int'];
};

export type ProgressList = {
  __typename?: 'ProgressList';
  beneficiaries?: Maybe<Progress>;
  children?: Maybe<Progress>;
  financial_assets?: Maybe<Progress>;
  guardianship?: Maybe<Progress>;
  personal_information?: Maybe<Progress>;
  personal_representatives?: Maybe<Progress>;
  pets?: Maybe<Progress>;
  power_of_attorneys?: Maybe<Progress>;
  trustees?: Maybe<Progress>;
};

export type Query = {
  __typename?: 'Query';
  counties?: Maybe<Array<Counties>>;
  createCheckoutSession?: Maybe<Scalars['String']>;
  documentPDF?: Maybe<Document>;
  generateDocumentPDF?: Maybe<Document>;
  generateDocumentPDFForUser?: Maybe<Document>;
  health: Scalars['Boolean'];
  isValidPromoCode: IsValidPromoCode;
  me?: Maybe<User>;
  paymentDetails: Payment;
  places?: Maybe<Array<Maybe<Place>>>;
  planDocuments: PlanDocument;
  planInfo: Array<PlanInfo>;
  states?: Maybe<Array<States>>;
  userAddresses?: Maybe<Array<Maybe<Address>>>;
  userInfo: UserInfo;
  userPlan: UserPlanInfo;
};

export type QueryCountiesArgs = {
  state: Scalars['String'];
};

export type QueryCreateCheckoutSessionArgs = {
  input: CheckoutSessionInput;
};

export type QueryDocumentPdfArgs = {
  regenerate: Scalars['Boolean'];
};

export type QueryGenerateDocumentPdfArgs = {
  documentTypes: Array<DocumentType>;
};

export type QueryGenerateDocumentPdfForUserArgs = {
  documentTypes: Array<DocumentType>;
  id: Scalars['ID'];
};

export type QueryIsValidPromoCodeArgs = {
  promoCode: Scalars['String'];
};

export type QueryPlacesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryPlanDocumentsArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryUserPlanArgs = {
  id: Scalars['ID'];
};

export type RealEstateProperty = {
  __typename?: 'RealEstateProperty';
  ID: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<RealEstatePropertyType>;
  user_id?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type RealEstatePropertyInput = {
  ID?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  type: RealEstatePropertyType;
  zip_code: Scalars['String'];
};

export enum RealEstatePropertyType {
  Land = 'LAND',
  Other = 'OTHER',
  Rental = 'RENTAL',
  Residence = 'RESIDENCE',
  Vacation = 'VACATION',
}

export type RecommendationQuestionnaireInput = {
  has_asset_above_threshold: Scalars['Boolean'];
  has_children: Scalars['Boolean'];
  has_real_estate: Scalars['Boolean'];
  id: Scalars['ID'];
  is_married: Scalars['Boolean'];
};

export enum RelationType {
  Husband = 'HUSBAND',
  Partner = 'PARTNER',
  Wife = 'WIFE',
}

export type States = {
  __typename?: 'States';
  name: Scalars['String'];
};

export type StripePayment = {
  __typename?: 'StripePayment';
  create_payment_code: Scalars['String'];
  id: Scalars['ID'];
};

export type Trustees = {
  __typename?: 'Trustees';
  id?: Maybe<Scalars['ID']>;
  primary_trustee_name: Scalars['String'];
  secondary_trustee_name: Scalars['String'];
  user_id: Scalars['String'];
};

export type TrusteesInput = {
  id?: Maybe<Scalars['ID']>;
  primary_trustee_name: Scalars['String'];
  secondary_trustee_name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  user_info?: Maybe<UserInfo>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  company_id?: Maybe<Scalars['String']>;
  equally_divide_non_specified_assets?: Maybe<Scalars['String']>;
  has_asset_above_threshold?: Maybe<Scalars['Boolean']>;
  has_children?: Maybe<Scalars['Boolean']>;
  has_deceased_children?: Maybe<Scalars['Boolean']>;
  has_pets?: Maybe<Scalars['Boolean']>;
  has_real_estate?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  is_expecting_children?: Maybe<Scalars['Boolean']>;
  is_married?: Maybe<Scalars['Boolean']>;
  on_boarding_status?: Maybe<OnBoardingStatus>;
  plan_id?: Maybe<Scalars['String']>;
  sales_person?: Maybe<Scalars['String']>;
};

export type UserPlanInfo = {
  __typename?: 'UserPlanInfo';
  additional_info?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  offer_price?: Maybe<Scalars['String']>;
  original_price?: Maybe<Scalars['String']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_shipping_price_id?: Maybe<Scalars['String']>;
};

export type AllCountiesForStateQueryVariables = Exact<{
  state: Scalars['String'];
}>;

export type AllCountiesForStateQuery = {
  __typename?: 'Query';
  counties?: Maybe<Array<{ __typename?: 'Counties'; name: string }>>;
};

export type StatesFragmentFragment = { __typename?: 'States'; name: string };

export type AllStatesQueryVariables = Exact<{ [key: string]: never }>;

export type AllStatesQuery = {
  __typename?: 'Query';
  states?: Maybe<Array<{ __typename?: 'States'; name: string }>>;
};

export type AccountInputMutationVariables = Exact<{
  input:
    | Array<FinancialAccountDesignationInput>
    | FinancialAccountDesignationInput;
}>;

export type AccountInputMutation = {
  __typename?: 'Mutation';
  UpsertFinancialAccountDesignations?: Maybe<string>;
};

export type DeleteFinancialAccountDesignationMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type DeleteFinancialAccountDesignationMutation = {
  __typename?: 'Mutation';
  DeleteFinancialAccountDesignation?: Maybe<string>;
};

export type BusinessInterestInputMutationVariables = Exact<{
  input: Array<BusinessInterestInput> | BusinessInterestInput;
}>;

export type BusinessInterestInputMutation = {
  __typename?: 'Mutation';
  UpsertBusinessInterests?: Maybe<string>;
};

export type DeleteBusinessInterestMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type DeleteBusinessInterestMutation = {
  __typename?: 'Mutation';
  DeleteBusinessInterest?: Maybe<string>;
};

export type PersonalPropertyInputMutationVariables = Exact<{
  input: Array<PersonalPropertyInput> | PersonalPropertyInput;
}>;

export type PersonalPropertyInputMutation = {
  __typename?: 'Mutation';
  UpsertPersonalProperties?: Maybe<string>;
};

export type DeletePersonalPropertyMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type DeletePersonalPropertyMutation = {
  __typename?: 'Mutation';
  DeletePersonalProperty?: Maybe<string>;
};

export type RealEstatePropertyInputMutationVariables = Exact<{
  input: Array<RealEstatePropertyInput> | RealEstatePropertyInput;
}>;

export type RealEstatePropertyInputMutation = {
  __typename?: 'Mutation';
  UpsertRealEstateProperties?: Maybe<string>;
};

export type DeleteRealEstatePropertyMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type DeleteRealEstatePropertyMutation = {
  __typename?: 'Mutation';
  DeleteRealEstateProperty?: Maybe<string>;
};

export type DeleteAssetsMutationVariables = Exact<{
  input: AssetInput;
}>;

export type DeleteAssetsMutation = {
  __typename?: 'Mutation';
  DeleteAssets?: Maybe<string>;
};

export type RecommendationQuestionnaireMutationVariables = Exact<{
  input: RecommendationQuestionnaireInput;
}>;

export type RecommendationQuestionnaireMutation = {
  __typename?: 'Mutation';
  updateRecommendationQuestionnaire: {
    __typename?: 'UserInfo';
    is_married?: Maybe<boolean>;
    has_children?: Maybe<boolean>;
    has_real_estate?: Maybe<boolean>;
    has_asset_above_threshold?: Maybe<boolean>;
  };
};

export type UserInfoFragmentFragment = {
  __typename?: 'UserInfo';
  is_married?: Maybe<boolean>;
  has_children?: Maybe<boolean>;
  has_real_estate?: Maybe<boolean>;
  has_asset_above_threshold?: Maybe<boolean>;
};

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoQuery = {
  __typename?: 'Query';
  userInfo: {
    __typename?: 'UserInfo';
    is_married?: Maybe<boolean>;
    has_children?: Maybe<boolean>;
    has_real_estate?: Maybe<boolean>;
    has_asset_above_threshold?: Maybe<boolean>;
  };
};

export type BeneficiariesInputMutationVariables = Exact<{
  input: Array<BeneficiariesInput> | BeneficiariesInput;
}>;

export type BeneficiariesInputMutation = {
  __typename?: 'Mutation';
  UpsertBeneficiaries?: Maybe<string>;
};

export type PaymentFragmentFragment = {
  __typename?: 'Payment';
  id: string;
  type?: Maybe<string>;
  brand?: Maybe<string>;
  last4?: Maybe<string>;
  payment_method_id: string;
  status: PaymentStatus;
};

export type PaymentDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentDetailsQuery = {
  __typename?: 'Query';
  paymentDetails: {
    __typename?: 'Payment';
    id: string;
    type?: Maybe<string>;
    brand?: Maybe<string>;
    last4?: Maybe<string>;
    payment_method_id: string;
    status: PaymentStatus;
  };
};

export type ShippingAddressInputMutationVariables = Exact<{
  input: AddressInput;
}>;

export type ShippingAddressInputMutation = {
  __typename?: 'Mutation';
  upsertShippingAddress?: Maybe<string>;
};

export type CreatePaymentMutationVariables = Exact<{
  input: PaymentInput;
}>;

export type CreatePaymentMutation = {
  __typename?: 'Mutation';
  paymentCreate?: Maybe<{
    __typename?: 'StripePayment';
    id: string;
    create_payment_code: string;
  }>;
};

export type UpdatePaymentStatusMutationVariables = Exact<{
  input: PaymentStatusInput;
}>;

export type UpdatePaymentStatusMutation = {
  __typename?: 'Mutation';
  updatePaymentStatus?: Maybe<string>;
};

export type UserAddressFragmentFragment = {
  __typename?: 'Address';
  id: string;
  name?: Maybe<string>;
  address?: Maybe<string>;
  apt_or_suit?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  country?: Maybe<string>;
  zip_code?: Maybe<string>;
  type?: Maybe<AddressType>;
};

export type AddressQueryVariables = Exact<{ [key: string]: never }>;

export type AddressQuery = {
  __typename?: 'Query';
  userAddresses?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Address';
        id: string;
        name?: Maybe<string>;
        address?: Maybe<string>;
        apt_or_suit?: Maybe<string>;
        city?: Maybe<string>;
        state?: Maybe<string>;
        country?: Maybe<string>;
        zip_code?: Maybe<string>;
        type?: Maybe<AddressType>;
      }>
    >
  >;
};

export type UserPlanFragmentFragment = {
  __typename?: 'UserPlanInfo';
  id: string;
  offer_price?: Maybe<string>;
  original_price?: Maybe<string>;
  name?: Maybe<string>;
};

export type UserPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserPlanQuery = {
  __typename?: 'Query';
  userPlan: {
    __typename?: 'UserPlanInfo';
    id: string;
    offer_price?: Maybe<string>;
    original_price?: Maybe<string>;
    name?: Maybe<string>;
  };
};

export type CreateCheckoutSessionQueryVariables = Exact<{
  input: CheckoutSessionInput;
}>;

export type CreateCheckoutSessionQuery = {
  __typename?: 'Query';
  createCheckoutSession?: Maybe<string>;
};

export type ChildrenInputMutationVariables = Exact<{
  input: Array<ChildrenInput> | ChildrenInput;
}>;

export type ChildrenInputMutation = {
  __typename?: 'Mutation';
  UpsertChildren?: Maybe<string>;
};

export type DeleteChildMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type DeleteChildMutation = {
  __typename?: 'Mutation';
  DeleteChild?: Maybe<string>;
};

export type InfoInputMutationVariables = Exact<{
  input: InfoInput;
}>;

export type InfoInputMutation = {
  __typename?: 'Mutation';
  UpdateUserInfo?: Maybe<string>;
};

export type IsValidPromoCodeQueryVariables = Exact<{
  promoCode: Scalars['String'];
}>;

export type IsValidPromoCodeQuery = {
  __typename?: 'Query';
  isValidPromoCode: {
    __typename?: 'IsValidPromoCode';
    ok: boolean;
    id?: Maybe<string>;
  };
};

export type DocumentFragmentFragment = {
  __typename?: 'Document';
  family_trust: string;
  self_will: string;
  power_of_attorney: string;
  medical_power_of_attorney: string;
  medical_directive: string;
  spouse_will: string;
  spouse_power_of_attorney: string;
  spouse_medical_power_of_attorney: string;
  spouse_medical_directive: string;
};

export type DocumentPdfQueryVariables = Exact<{
  regenerate?: Maybe<Scalars['Boolean']>;
}>;

export type DocumentPdfQuery = {
  __typename?: 'Query';
  documentPDF?: Maybe<{
    __typename?: 'Document';
    family_trust: string;
    self_will: string;
    power_of_attorney: string;
    medical_power_of_attorney: string;
    medical_directive: string;
    spouse_will: string;
    spouse_power_of_attorney: string;
    spouse_medical_power_of_attorney: string;
    spouse_medical_directive: string;
  }>;
};

export type GuardianshipInputMutationVariables = Exact<{
  input: Array<GuardianshipInput> | GuardianshipInput;
}>;

export type GuardianshipInputMutation = {
  __typename?: 'Mutation';
  UpdateGuardianship?: Maybe<string>;
};

export type SearchPlaceFragment = {
  __typename?: 'Place';
  id: string;
  formatted_phone_number: string;
  name: string;
  vicinity: string;
  location: { __typename?: 'Location'; lat: number; lng: number };
};

export type PlacesQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type PlacesQuery = {
  __typename?: 'Query';
  places?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Place';
        id: string;
        formatted_phone_number: string;
        name: string;
        vicinity: string;
        location: { __typename?: 'Location'; lat: number; lng: number };
      }>
    >
  >;
};

export type PersonUpsertMutationVariables = Exact<{
  input: PersonInput;
}>;

export type PersonUpsertMutation = {
  __typename?: 'Mutation';
  personUpsert?: Maybe<string>;
};

export type PersonalRepresentativeInputMutationVariables = Exact<{
  input: PersonalRepresentativeInput;
}>;

export type PersonalRepresentativeInputMutation = {
  __typename?: 'Mutation';
  UpsertPersonalRepresentative?: Maybe<string>;
};

export type PetsInputMutationVariables = Exact<{
  input: Array<PetsInput> | PetsInput;
}>;

export type PetsInputMutation = {
  __typename?: 'Mutation';
  UpsertPets?: Maybe<string>;
};

export type DeletePetMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type DeletePetMutation = {
  __typename?: 'Mutation';
  DeletePet?: Maybe<string>;
};

export type UpdateHasPetsMutationVariables = Exact<{
  hasPets: Scalars['Boolean'];
}>;

export type UpdateHasPetsMutation = {
  __typename?: 'Mutation';
  updateHasPets?: Maybe<string>;
};

export type PlanInfoFragmentFragment = {
  __typename?: 'PlanInfo';
  id: string;
  name?: Maybe<string>;
  description?: Maybe<string>;
  original_price?: Maybe<string>;
  offer_price?: Maybe<string>;
  additional_info?: Maybe<Array<Maybe<string>>>;
};

export type PlanInfoQueryVariables = Exact<{ [key: string]: never }>;

export type PlanInfoQuery = {
  __typename?: 'Query';
  planInfo: Array<{
    __typename?: 'PlanInfo';
    id: string;
    name?: Maybe<string>;
    description?: Maybe<string>;
    original_price?: Maybe<string>;
    offer_price?: Maybe<string>;
    additional_info?: Maybe<Array<Maybe<string>>>;
  }>;
};

export type PlanMutationVariables = Exact<{
  input: PlanInput;
}>;

export type PlanMutation = {
  __typename?: 'Mutation';
  updatePlan: { __typename?: 'UserInfo'; id: string };
};

export type AddressFragmentFragment = {
  __typename?: 'Address';
  id: string;
  type?: Maybe<AddressType>;
  name?: Maybe<string>;
  address?: Maybe<string>;
  apt_or_suit?: Maybe<string>;
  zip_code?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  county?: Maybe<string>;
  country?: Maybe<string>;
  person_id: string;
};

export type PersonFragmentFragment = {
  __typename?: 'Person';
  id: string;
  first_name?: Maybe<string>;
  middle_name?: Maybe<string>;
  last_name?: Maybe<string>;
  email?: Maybe<string>;
  date_of_birth?: Maybe<string>;
  phone?: Maybe<string>;
  type: PersonType;
  relation_type?: Maybe<RelationType>;
  user_id: string;
  address?: Maybe<{
    __typename?: 'Address';
    id: string;
    type?: Maybe<AddressType>;
    name?: Maybe<string>;
    address?: Maybe<string>;
    apt_or_suit?: Maybe<string>;
    zip_code?: Maybe<string>;
    city?: Maybe<string>;
    state?: Maybe<string>;
    county?: Maybe<string>;
    country?: Maybe<string>;
    person_id: string;
  }>;
};

export type ChildrenFragmentFragment = {
  __typename?: 'Children';
  id: string;
  full_name: string;
  date_of_birth: string;
  child_of?: Maybe<ChildOfType>;
  user_id: string;
  age?: Maybe<number>;
  primary_guardian_name?: Maybe<string>;
  secondary_guardian_name?: Maybe<string>;
};

export type PetsFragmentFragment = {
  __typename?: 'Pets';
  id: string;
  name: string;
  type: string;
  user_id: string;
  primary_guardian_name?: Maybe<string>;
  secondary_guardian_name?: Maybe<string>;
};

export type PersonalRepresentativeFragmentFragment = {
  __typename?: 'PersonalRepresentative';
  id: string;
  first_choice_owner_name: string;
  second_choice_owner_name: string;
  personal_representative_for: PersonType;
  user_id: string;
};

export type PersonalRepresentativesFragmentFragment = {
  __typename?: 'PersonalRepresentatives';
  self?: Maybe<{
    __typename?: 'PersonalRepresentative';
    id: string;
    first_choice_owner_name: string;
    second_choice_owner_name: string;
    personal_representative_for: PersonType;
    user_id: string;
  }>;
  spouse?: Maybe<{
    __typename?: 'PersonalRepresentative';
    id: string;
    first_choice_owner_name: string;
    second_choice_owner_name: string;
    personal_representative_for: PersonType;
    user_id: string;
  }>;
};

export type TrusteesFragmentFragment = {
  __typename?: 'Trustees';
  id?: Maybe<string>;
  user_id: string;
  primary_trustee_name: string;
  secondary_trustee_name: string;
};

export type RealEstatePropertyFragmentFragment = {
  __typename?: 'RealEstateProperty';
  ID: string;
  user_id?: Maybe<string>;
  type?: Maybe<RealEstatePropertyType>;
  description?: Maybe<string>;
  address?: Maybe<string>;
  zip_code?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  country?: Maybe<string>;
};

export type BusinessInterestsFragmentFragment = {
  __typename?: 'BusinessInterest';
  id: string;
  user_id: string;
  type?: Maybe<BusinessType>;
  name?: Maybe<string>;
  state_formed_in?: Maybe<string>;
  relationship?: Maybe<BusinessRelationship>;
};

export type FinancialAccountsFragmentFragment = {
  __typename?: 'FinancialAccountDesignation';
  id: string;
  user_id: string;
  account_type: FinancialAccountType;
  provider: string;
};

export type PersonalPropertiesFragmentFragment = {
  __typename?: 'PersonalProperty';
  id: string;
  user_id?: Maybe<string>;
  description?: Maybe<string>;
  beneficiary_owner_name?: Maybe<string>;
};

export type FinancialAssetsFragmentFragment = {
  __typename?: 'Assets';
  real_estate_properties: Array<{
    __typename?: 'RealEstateProperty';
    ID: string;
    user_id?: Maybe<string>;
    type?: Maybe<RealEstatePropertyType>;
    description?: Maybe<string>;
    address?: Maybe<string>;
    zip_code?: Maybe<string>;
    city?: Maybe<string>;
    state?: Maybe<string>;
    country?: Maybe<string>;
  }>;
  business_interests: Array<{
    __typename?: 'BusinessInterest';
    id: string;
    user_id: string;
    type?: Maybe<BusinessType>;
    name?: Maybe<string>;
    state_formed_in?: Maybe<string>;
    relationship?: Maybe<BusinessRelationship>;
  }>;
  financial_account_designations: Array<{
    __typename?: 'FinancialAccountDesignation';
    id: string;
    user_id: string;
    account_type: FinancialAccountType;
    provider: string;
  }>;
  personal_properties: Array<{
    __typename?: 'PersonalProperty';
    id: string;
    user_id?: Maybe<string>;
    description?: Maybe<string>;
    beneficiary_owner_name?: Maybe<string>;
  }>;
};

export type BeneficiariesFragmentFragment = {
  __typename?: 'Beneficiaries';
  id?: Maybe<string>;
  owner_id: string;
  user_id: string;
  owner_type: BeneficiaryType;
  full_name: string;
  inheritance_age: string;
  inheritance_percentage: string;
};

export type PowerOfAttorneyFragmentFragment = {
  __typename?: 'PowerOfAttorney';
  id: string;
  user_id: string;
  first_choice_owner_name: string;
  second_choice_owner_name?: Maybe<string>;
  power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
  power_of_attorney_for?: Maybe<PersonType>;
};

export type PowerOfAttorneysFragmentFragment = {
  __typename?: 'PowerOfAttorneys';
  general?: Maybe<{
    __typename?: 'PowerOfAttorney';
    id: string;
    user_id: string;
    first_choice_owner_name: string;
    second_choice_owner_name?: Maybe<string>;
    power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
    power_of_attorney_for?: Maybe<PersonType>;
  }>;
  medical?: Maybe<{
    __typename?: 'PowerOfAttorney';
    id: string;
    user_id: string;
    first_choice_owner_name: string;
    second_choice_owner_name?: Maybe<string>;
    power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
    power_of_attorney_for?: Maybe<PersonType>;
  }>;
  general_for_spouse?: Maybe<{
    __typename?: 'PowerOfAttorney';
    id: string;
    user_id: string;
    first_choice_owner_name: string;
    second_choice_owner_name?: Maybe<string>;
    power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
    power_of_attorney_for?: Maybe<PersonType>;
  }>;
  medical_for_spouse?: Maybe<{
    __typename?: 'PowerOfAttorney';
    id: string;
    user_id: string;
    first_choice_owner_name: string;
    second_choice_owner_name?: Maybe<string>;
    power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
    power_of_attorney_for?: Maybe<PersonType>;
  }>;
};

export type ProgressFragmentFragment = {
  __typename?: 'Progress';
  time_to_complete: string;
  total: number;
  filled: number;
  completed: boolean;
  disabled: boolean;
};

export type ProgressListFragmentFragment = {
  __typename?: 'ProgressList';
  personal_information?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  children?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  guardianship?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  pets?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  personal_representatives?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  financial_assets?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  trustees?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  beneficiaries?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
  power_of_attorneys?: Maybe<{
    __typename?: 'Progress';
    time_to_complete: string;
    total: number;
    filled: number;
    completed: boolean;
    disabled: boolean;
  }>;
};

export type PlanDocumentFragmentFragment = {
  __typename?: 'PlanDocument';
  progress_percentage?: Maybe<number>;
  is_document_complete?: Maybe<boolean>;
  is_document_editable?: Maybe<boolean>;
  manual_payment?: Maybe<boolean>;
  user_info: {
    __typename?: 'UserInfo';
    id: string;
    is_married?: Maybe<boolean>;
    has_real_estate?: Maybe<boolean>;
    has_children?: Maybe<boolean>;
    has_asset_above_threshold?: Maybe<boolean>;
    on_boarding_status?: Maybe<OnBoardingStatus>;
    plan_id?: Maybe<string>;
    has_pets?: Maybe<boolean>;
  };
  personal_information?: Maybe<{
    __typename?: 'PersonalInformation';
    self?: Maybe<{
      __typename?: 'Person';
      id: string;
      first_name?: Maybe<string>;
      middle_name?: Maybe<string>;
      last_name?: Maybe<string>;
      email?: Maybe<string>;
      date_of_birth?: Maybe<string>;
      phone?: Maybe<string>;
      type: PersonType;
      relation_type?: Maybe<RelationType>;
      user_id: string;
      address?: Maybe<{
        __typename?: 'Address';
        id: string;
        type?: Maybe<AddressType>;
        name?: Maybe<string>;
        address?: Maybe<string>;
        apt_or_suit?: Maybe<string>;
        zip_code?: Maybe<string>;
        city?: Maybe<string>;
        state?: Maybe<string>;
        county?: Maybe<string>;
        country?: Maybe<string>;
        person_id: string;
      }>;
    }>;
    spouse?: Maybe<{
      __typename?: 'Person';
      id: string;
      first_name?: Maybe<string>;
      middle_name?: Maybe<string>;
      last_name?: Maybe<string>;
      email?: Maybe<string>;
      date_of_birth?: Maybe<string>;
      phone?: Maybe<string>;
      type: PersonType;
      relation_type?: Maybe<RelationType>;
      user_id: string;
      address?: Maybe<{
        __typename?: 'Address';
        id: string;
        type?: Maybe<AddressType>;
        name?: Maybe<string>;
        address?: Maybe<string>;
        apt_or_suit?: Maybe<string>;
        zip_code?: Maybe<string>;
        city?: Maybe<string>;
        state?: Maybe<string>;
        county?: Maybe<string>;
        country?: Maybe<string>;
        person_id: string;
      }>;
    }>;
  }>;
  children?: Maybe<
    Array<{
      __typename?: 'Children';
      id: string;
      full_name: string;
      date_of_birth: string;
      child_of?: Maybe<ChildOfType>;
      user_id: string;
      age?: Maybe<number>;
      primary_guardian_name?: Maybe<string>;
      secondary_guardian_name?: Maybe<string>;
    }>
  >;
  pets?: Maybe<
    Array<{
      __typename?: 'Pets';
      id: string;
      name: string;
      type: string;
      user_id: string;
      primary_guardian_name?: Maybe<string>;
      secondary_guardian_name?: Maybe<string>;
    }>
  >;
  personal_representatives?: Maybe<{
    __typename?: 'PersonalRepresentatives';
    self?: Maybe<{
      __typename?: 'PersonalRepresentative';
      id: string;
      first_choice_owner_name: string;
      second_choice_owner_name: string;
      personal_representative_for: PersonType;
      user_id: string;
    }>;
    spouse?: Maybe<{
      __typename?: 'PersonalRepresentative';
      id: string;
      first_choice_owner_name: string;
      second_choice_owner_name: string;
      personal_representative_for: PersonType;
      user_id: string;
    }>;
  }>;
  assets: {
    __typename?: 'Assets';
    real_estate_properties: Array<{
      __typename?: 'RealEstateProperty';
      ID: string;
      user_id?: Maybe<string>;
      type?: Maybe<RealEstatePropertyType>;
      description?: Maybe<string>;
      address?: Maybe<string>;
      zip_code?: Maybe<string>;
      city?: Maybe<string>;
      state?: Maybe<string>;
      country?: Maybe<string>;
    }>;
    business_interests: Array<{
      __typename?: 'BusinessInterest';
      id: string;
      user_id: string;
      type?: Maybe<BusinessType>;
      name?: Maybe<string>;
      state_formed_in?: Maybe<string>;
      relationship?: Maybe<BusinessRelationship>;
    }>;
    financial_account_designations: Array<{
      __typename?: 'FinancialAccountDesignation';
      id: string;
      user_id: string;
      account_type: FinancialAccountType;
      provider: string;
    }>;
    personal_properties: Array<{
      __typename?: 'PersonalProperty';
      id: string;
      user_id?: Maybe<string>;
      description?: Maybe<string>;
      beneficiary_owner_name?: Maybe<string>;
    }>;
  };
  trustees?: Maybe<{
    __typename?: 'Trustees';
    id?: Maybe<string>;
    user_id: string;
    primary_trustee_name: string;
    secondary_trustee_name: string;
  }>;
  beneficiaries?: Maybe<
    Array<{
      __typename?: 'Beneficiaries';
      id?: Maybe<string>;
      owner_id: string;
      user_id: string;
      owner_type: BeneficiaryType;
      full_name: string;
      inheritance_age: string;
      inheritance_percentage: string;
    }>
  >;
  power_of_attorneys?: Maybe<{
    __typename?: 'PowerOfAttorneys';
    general?: Maybe<{
      __typename?: 'PowerOfAttorney';
      id: string;
      user_id: string;
      first_choice_owner_name: string;
      second_choice_owner_name?: Maybe<string>;
      power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
      power_of_attorney_for?: Maybe<PersonType>;
    }>;
    medical?: Maybe<{
      __typename?: 'PowerOfAttorney';
      id: string;
      user_id: string;
      first_choice_owner_name: string;
      second_choice_owner_name?: Maybe<string>;
      power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
      power_of_attorney_for?: Maybe<PersonType>;
    }>;
    general_for_spouse?: Maybe<{
      __typename?: 'PowerOfAttorney';
      id: string;
      user_id: string;
      first_choice_owner_name: string;
      second_choice_owner_name?: Maybe<string>;
      power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
      power_of_attorney_for?: Maybe<PersonType>;
    }>;
    medical_for_spouse?: Maybe<{
      __typename?: 'PowerOfAttorney';
      id: string;
      user_id: string;
      first_choice_owner_name: string;
      second_choice_owner_name?: Maybe<string>;
      power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
      power_of_attorney_for?: Maybe<PersonType>;
    }>;
  }>;
  progress?: Maybe<{
    __typename?: 'ProgressList';
    personal_information?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    children?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    guardianship?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    pets?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    personal_representatives?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    financial_assets?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    trustees?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    beneficiaries?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
    power_of_attorneys?: Maybe<{
      __typename?: 'Progress';
      time_to_complete: string;
      total: number;
      filled: number;
      completed: boolean;
      disabled: boolean;
    }>;
  }>;
};

export type PlanDocumentQueryVariables = Exact<{ [key: string]: never }>;

export type PlanDocumentQuery = {
  __typename?: 'Query';
  planDocuments: {
    __typename?: 'PlanDocument';
    progress_percentage?: Maybe<number>;
    is_document_complete?: Maybe<boolean>;
    is_document_editable?: Maybe<boolean>;
    manual_payment?: Maybe<boolean>;
    user_info: {
      __typename?: 'UserInfo';
      id: string;
      is_married?: Maybe<boolean>;
      has_real_estate?: Maybe<boolean>;
      has_children?: Maybe<boolean>;
      has_asset_above_threshold?: Maybe<boolean>;
      on_boarding_status?: Maybe<OnBoardingStatus>;
      plan_id?: Maybe<string>;
      has_pets?: Maybe<boolean>;
    };
    personal_information?: Maybe<{
      __typename?: 'PersonalInformation';
      self?: Maybe<{
        __typename?: 'Person';
        id: string;
        first_name?: Maybe<string>;
        middle_name?: Maybe<string>;
        last_name?: Maybe<string>;
        email?: Maybe<string>;
        date_of_birth?: Maybe<string>;
        phone?: Maybe<string>;
        type: PersonType;
        relation_type?: Maybe<RelationType>;
        user_id: string;
        address?: Maybe<{
          __typename?: 'Address';
          id: string;
          type?: Maybe<AddressType>;
          name?: Maybe<string>;
          address?: Maybe<string>;
          apt_or_suit?: Maybe<string>;
          zip_code?: Maybe<string>;
          city?: Maybe<string>;
          state?: Maybe<string>;
          county?: Maybe<string>;
          country?: Maybe<string>;
          person_id: string;
        }>;
      }>;
      spouse?: Maybe<{
        __typename?: 'Person';
        id: string;
        first_name?: Maybe<string>;
        middle_name?: Maybe<string>;
        last_name?: Maybe<string>;
        email?: Maybe<string>;
        date_of_birth?: Maybe<string>;
        phone?: Maybe<string>;
        type: PersonType;
        relation_type?: Maybe<RelationType>;
        user_id: string;
        address?: Maybe<{
          __typename?: 'Address';
          id: string;
          type?: Maybe<AddressType>;
          name?: Maybe<string>;
          address?: Maybe<string>;
          apt_or_suit?: Maybe<string>;
          zip_code?: Maybe<string>;
          city?: Maybe<string>;
          state?: Maybe<string>;
          county?: Maybe<string>;
          country?: Maybe<string>;
          person_id: string;
        }>;
      }>;
    }>;
    children?: Maybe<
      Array<{
        __typename?: 'Children';
        id: string;
        full_name: string;
        date_of_birth: string;
        child_of?: Maybe<ChildOfType>;
        user_id: string;
        age?: Maybe<number>;
        primary_guardian_name?: Maybe<string>;
        secondary_guardian_name?: Maybe<string>;
      }>
    >;
    pets?: Maybe<
      Array<{
        __typename?: 'Pets';
        id: string;
        name: string;
        type: string;
        user_id: string;
        primary_guardian_name?: Maybe<string>;
        secondary_guardian_name?: Maybe<string>;
      }>
    >;
    personal_representatives?: Maybe<{
      __typename?: 'PersonalRepresentatives';
      self?: Maybe<{
        __typename?: 'PersonalRepresentative';
        id: string;
        first_choice_owner_name: string;
        second_choice_owner_name: string;
        personal_representative_for: PersonType;
        user_id: string;
      }>;
      spouse?: Maybe<{
        __typename?: 'PersonalRepresentative';
        id: string;
        first_choice_owner_name: string;
        second_choice_owner_name: string;
        personal_representative_for: PersonType;
        user_id: string;
      }>;
    }>;
    assets: {
      __typename?: 'Assets';
      real_estate_properties: Array<{
        __typename?: 'RealEstateProperty';
        ID: string;
        user_id?: Maybe<string>;
        type?: Maybe<RealEstatePropertyType>;
        description?: Maybe<string>;
        address?: Maybe<string>;
        zip_code?: Maybe<string>;
        city?: Maybe<string>;
        state?: Maybe<string>;
        country?: Maybe<string>;
      }>;
      business_interests: Array<{
        __typename?: 'BusinessInterest';
        id: string;
        user_id: string;
        type?: Maybe<BusinessType>;
        name?: Maybe<string>;
        state_formed_in?: Maybe<string>;
        relationship?: Maybe<BusinessRelationship>;
      }>;
      financial_account_designations: Array<{
        __typename?: 'FinancialAccountDesignation';
        id: string;
        user_id: string;
        account_type: FinancialAccountType;
        provider: string;
      }>;
      personal_properties: Array<{
        __typename?: 'PersonalProperty';
        id: string;
        user_id?: Maybe<string>;
        description?: Maybe<string>;
        beneficiary_owner_name?: Maybe<string>;
      }>;
    };
    trustees?: Maybe<{
      __typename?: 'Trustees';
      id?: Maybe<string>;
      user_id: string;
      primary_trustee_name: string;
      secondary_trustee_name: string;
    }>;
    beneficiaries?: Maybe<
      Array<{
        __typename?: 'Beneficiaries';
        id?: Maybe<string>;
        owner_id: string;
        user_id: string;
        owner_type: BeneficiaryType;
        full_name: string;
        inheritance_age: string;
        inheritance_percentage: string;
      }>
    >;
    power_of_attorneys?: Maybe<{
      __typename?: 'PowerOfAttorneys';
      general?: Maybe<{
        __typename?: 'PowerOfAttorney';
        id: string;
        user_id: string;
        first_choice_owner_name: string;
        second_choice_owner_name?: Maybe<string>;
        power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
        power_of_attorney_for?: Maybe<PersonType>;
      }>;
      medical?: Maybe<{
        __typename?: 'PowerOfAttorney';
        id: string;
        user_id: string;
        first_choice_owner_name: string;
        second_choice_owner_name?: Maybe<string>;
        power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
        power_of_attorney_for?: Maybe<PersonType>;
      }>;
      general_for_spouse?: Maybe<{
        __typename?: 'PowerOfAttorney';
        id: string;
        user_id: string;
        first_choice_owner_name: string;
        second_choice_owner_name?: Maybe<string>;
        power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
        power_of_attorney_for?: Maybe<PersonType>;
      }>;
      medical_for_spouse?: Maybe<{
        __typename?: 'PowerOfAttorney';
        id: string;
        user_id: string;
        first_choice_owner_name: string;
        second_choice_owner_name?: Maybe<string>;
        power_of_attorney_type?: Maybe<PowerOfAttorneyType>;
        power_of_attorney_for?: Maybe<PersonType>;
      }>;
    }>;
    progress?: Maybe<{
      __typename?: 'ProgressList';
      personal_information?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      children?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      guardianship?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      pets?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      personal_representatives?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      financial_assets?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      trustees?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      beneficiaries?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
      power_of_attorneys?: Maybe<{
        __typename?: 'Progress';
        time_to_complete: string;
        total: number;
        filled: number;
        completed: boolean;
        disabled: boolean;
      }>;
    }>;
  };
};

export type PowerOfAttorneyInputMutationVariables = Exact<{
  input: PowerOfAttorneyInput;
}>;

export type PowerOfAttorneyInputMutation = {
  __typename?: 'Mutation';
  UpsertPowerOfAttorney?: Maybe<string>;
};

export type TrusteesInputMutationVariables = Exact<{
  input: TrusteesInput;
}>;

export type TrusteesInputMutation = {
  __typename?: 'Mutation';
  UpsertTrustees?: Maybe<string>;
};

export type BasicInfoMutationVariables = Exact<{
  input: BasicInfoInput;
}>;

export type BasicInfoMutation = {
  __typename?: 'Mutation';
  updateBasicInfo?: Maybe<string>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: Maybe<{
    __typename?: 'User';
    id: string;
    email: string;
    user_info?: Maybe<{
      __typename?: 'UserInfo';
      on_boarding_status?: Maybe<OnBoardingStatus>;
      plan_id?: Maybe<string>;
    }>;
  }>;
};

export type UpdateOnBoardingStatusMutationVariables = Exact<{
  input: OnBoardingStatusInput;
}>;

export type UpdateOnBoardingStatusMutation = {
  __typename?: 'Mutation';
  updateOnBoardingStatus?: Maybe<string>;
};

export const StatesFragmentFragmentDoc = gql`
  fragment StatesFragment on States {
    name
  }
`;
export const UserInfoFragmentFragmentDoc = gql`
  fragment UserInfoFragment on UserInfo {
    is_married
    has_children
    has_real_estate
    has_asset_above_threshold
  }
`;
export const PaymentFragmentFragmentDoc = gql`
  fragment PaymentFragment on Payment {
    id
    type
    brand
    last4
    payment_method_id
    status
  }
`;
export const UserAddressFragmentFragmentDoc = gql`
  fragment UserAddressFragment on Address {
    id
    name
    address
    apt_or_suit
    city
    state
    country
    zip_code
    type
  }
`;
export const UserPlanFragmentFragmentDoc = gql`
  fragment UserPlanFragment on UserPlanInfo {
    id
    offer_price
    original_price
    name
  }
`;
export const DocumentFragmentFragmentDoc = gql`
  fragment DocumentFragment on Document {
    family_trust
    self_will
    power_of_attorney
    medical_power_of_attorney
    medical_directive
    spouse_will
    spouse_power_of_attorney
    spouse_medical_power_of_attorney
    spouse_medical_directive
  }
`;
export const SearchPlaceFragmentDoc = gql`
  fragment SearchPlace on Place {
    id
    formatted_phone_number
    name
    vicinity
    location {
      lat
      lng
    }
  }
`;
export const PlanInfoFragmentFragmentDoc = gql`
  fragment PlanInfoFragment on PlanInfo {
    id
    name
    description
    original_price
    offer_price
    additional_info
  }
`;
export const AddressFragmentFragmentDoc = gql`
  fragment AddressFragment on Address {
    id
    type
    name
    address
    apt_or_suit
    zip_code
    city
    state
    county
    country
    person_id
  }
`;
export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    first_name
    middle_name
    last_name
    email
    date_of_birth
    phone
    type
    relation_type
    user_id
    address {
      ...AddressFragment
    }
  }
  ${AddressFragmentFragmentDoc}
`;
export const ChildrenFragmentFragmentDoc = gql`
  fragment ChildrenFragment on Children {
    id
    full_name
    date_of_birth
    child_of
    user_id
    age
    primary_guardian_name
    secondary_guardian_name
  }
`;
export const PetsFragmentFragmentDoc = gql`
  fragment PetsFragment on Pets {
    id
    name
    type
    user_id
    primary_guardian_name
    secondary_guardian_name
  }
`;
export const PersonalRepresentativeFragmentFragmentDoc = gql`
  fragment PersonalRepresentativeFragment on PersonalRepresentative {
    id
    first_choice_owner_name
    second_choice_owner_name
    personal_representative_for
    user_id
  }
`;
export const PersonalRepresentativesFragmentFragmentDoc = gql`
  fragment PersonalRepresentativesFragment on PersonalRepresentatives {
    self {
      ...PersonalRepresentativeFragment
    }
    spouse {
      ...PersonalRepresentativeFragment
    }
  }
  ${PersonalRepresentativeFragmentFragmentDoc}
`;
export const RealEstatePropertyFragmentFragmentDoc = gql`
  fragment RealEstatePropertyFragment on RealEstateProperty {
    ID
    user_id
    type
    description
    address
    zip_code
    city
    state
    country
  }
`;
export const BusinessInterestsFragmentFragmentDoc = gql`
  fragment BusinessInterestsFragment on BusinessInterest {
    id
    user_id
    type
    name
    state_formed_in
    relationship
  }
`;
export const FinancialAccountsFragmentFragmentDoc = gql`
  fragment FinancialAccountsFragment on FinancialAccountDesignation {
    id
    user_id
    account_type
    provider
  }
`;
export const PersonalPropertiesFragmentFragmentDoc = gql`
  fragment PersonalPropertiesFragment on PersonalProperty {
    id
    user_id
    description
    beneficiary_owner_name
  }
`;
export const FinancialAssetsFragmentFragmentDoc = gql`
  fragment FinancialAssetsFragment on Assets {
    real_estate_properties {
      ...RealEstatePropertyFragment
    }
    business_interests {
      ...BusinessInterestsFragment
    }
    financial_account_designations {
      ...FinancialAccountsFragment
    }
    personal_properties {
      ...PersonalPropertiesFragment
    }
  }
  ${RealEstatePropertyFragmentFragmentDoc}
  ${BusinessInterestsFragmentFragmentDoc}
  ${FinancialAccountsFragmentFragmentDoc}
  ${PersonalPropertiesFragmentFragmentDoc}
`;
export const TrusteesFragmentFragmentDoc = gql`
  fragment TrusteesFragment on Trustees {
    id
    user_id
    primary_trustee_name
    secondary_trustee_name
  }
`;
export const BeneficiariesFragmentFragmentDoc = gql`
  fragment BeneficiariesFragment on Beneficiaries {
    id
    owner_id
    user_id
    owner_type
    full_name
    inheritance_age
    inheritance_percentage
  }
`;
export const PowerOfAttorneyFragmentFragmentDoc = gql`
  fragment PowerOfAttorneyFragment on PowerOfAttorney {
    id
    user_id
    first_choice_owner_name
    second_choice_owner_name
    power_of_attorney_type
    power_of_attorney_for
  }
`;
export const PowerOfAttorneysFragmentFragmentDoc = gql`
  fragment PowerOfAttorneysFragment on PowerOfAttorneys {
    general {
      ...PowerOfAttorneyFragment
    }
    medical {
      ...PowerOfAttorneyFragment
    }
    general_for_spouse {
      ...PowerOfAttorneyFragment
    }
    medical_for_spouse {
      ...PowerOfAttorneyFragment
    }
  }
  ${PowerOfAttorneyFragmentFragmentDoc}
`;
export const ProgressFragmentFragmentDoc = gql`
  fragment ProgressFragment on Progress {
    time_to_complete
    total
    filled
    completed
    disabled
  }
`;
export const ProgressListFragmentFragmentDoc = gql`
  fragment ProgressListFragment on ProgressList {
    personal_information {
      ...ProgressFragment
    }
    children {
      ...ProgressFragment
    }
    guardianship {
      ...ProgressFragment
    }
    pets {
      ...ProgressFragment
    }
    personal_representatives {
      ...ProgressFragment
    }
    financial_assets {
      ...ProgressFragment
    }
    trustees {
      ...ProgressFragment
    }
    beneficiaries {
      ...ProgressFragment
    }
    power_of_attorneys {
      ...ProgressFragment
    }
  }
  ${ProgressFragmentFragmentDoc}
`;
export const PlanDocumentFragmentFragmentDoc = gql`
  fragment PlanDocumentFragment on PlanDocument {
    user_info {
      id
      is_married
      has_real_estate
      has_children
      has_asset_above_threshold
      on_boarding_status
      plan_id
      has_pets
    }
    personal_information {
      self {
        ...PersonFragment
      }
      spouse {
        ...PersonFragment
      }
    }
    children {
      ...ChildrenFragment
    }
    pets {
      ...PetsFragment
    }
    personal_representatives {
      ...PersonalRepresentativesFragment
    }
    assets {
      ...FinancialAssetsFragment
    }
    trustees {
      ...TrusteesFragment
    }
    beneficiaries {
      ...BeneficiariesFragment
    }
    power_of_attorneys {
      ...PowerOfAttorneysFragment
    }
    progress {
      ...ProgressListFragment
    }
    progress_percentage
    is_document_complete
    is_document_editable
    manual_payment
  }
  ${PersonFragmentFragmentDoc}
  ${ChildrenFragmentFragmentDoc}
  ${PetsFragmentFragmentDoc}
  ${PersonalRepresentativesFragmentFragmentDoc}
  ${FinancialAssetsFragmentFragmentDoc}
  ${TrusteesFragmentFragmentDoc}
  ${BeneficiariesFragmentFragmentDoc}
  ${PowerOfAttorneysFragmentFragmentDoc}
  ${ProgressListFragmentFragmentDoc}
`;
export const AllCountiesForStateDocument = gql`
  query AllCountiesForState($state: String!) {
    counties(state: $state) {
      name
    }
  }
`;

/**
 * __useAllCountiesForStateQuery__
 *
 * To run a query within a React component, call `useAllCountiesForStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCountiesForStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCountiesForStateQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAllCountiesForStateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AllCountiesForStateQuery,
    AllCountiesForStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AllCountiesForStateQuery,
    AllCountiesForStateQueryVariables
  >(AllCountiesForStateDocument, options);
}
export function useAllCountiesForStateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllCountiesForStateQuery,
    AllCountiesForStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AllCountiesForStateQuery,
    AllCountiesForStateQueryVariables
  >(AllCountiesForStateDocument, options);
}
export type AllCountiesForStateQueryHookResult = ReturnType<
  typeof useAllCountiesForStateQuery
>;
export type AllCountiesForStateLazyQueryHookResult = ReturnType<
  typeof useAllCountiesForStateLazyQuery
>;
export type AllCountiesForStateQueryResult = ApolloReactCommon.QueryResult<
  AllCountiesForStateQuery,
  AllCountiesForStateQueryVariables
>;
export const AllStatesDocument = gql`
  query AllStates {
    states {
      ...StatesFragment
    }
  }
  ${StatesFragmentFragmentDoc}
`;

/**
 * __useAllStatesQuery__
 *
 * To run a query within a React component, call `useAllStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllStatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllStatesQuery,
    AllStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllStatesQuery, AllStatesQueryVariables>(
    AllStatesDocument,
    options,
  );
}
export function useAllStatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllStatesQuery,
    AllStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllStatesQuery, AllStatesQueryVariables>(
    AllStatesDocument,
    options,
  );
}
export type AllStatesQueryHookResult = ReturnType<typeof useAllStatesQuery>;
export type AllStatesLazyQueryHookResult = ReturnType<
  typeof useAllStatesLazyQuery
>;
export type AllStatesQueryResult = ApolloReactCommon.QueryResult<
  AllStatesQuery,
  AllStatesQueryVariables
>;
export const AccountInputDocument = gql`
  mutation AccountInput($input: [FinancialAccountDesignationInput!]!) {
    UpsertFinancialAccountDesignations(input: $input)
  }
`;
export type AccountInputMutationFn = ApolloReactCommon.MutationFunction<
  AccountInputMutation,
  AccountInputMutationVariables
>;

/**
 * __useAccountInputMutation__
 *
 * To run a mutation, you first call `useAccountInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountInputMutation, { data, loading, error }] = useAccountInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountInputMutation,
    AccountInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AccountInputMutation,
    AccountInputMutationVariables
  >(AccountInputDocument, options);
}
export type AccountInputMutationHookResult = ReturnType<
  typeof useAccountInputMutation
>;
export type AccountInputMutationResult =
  ApolloReactCommon.MutationResult<AccountInputMutation>;
export type AccountInputMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountInputMutation,
  AccountInputMutationVariables
>;
export const DeleteFinancialAccountDesignationDocument = gql`
  mutation DeleteFinancialAccountDesignation($input: String!) {
    DeleteFinancialAccountDesignation(id: $input)
  }
`;
export type DeleteFinancialAccountDesignationMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteFinancialAccountDesignationMutation,
    DeleteFinancialAccountDesignationMutationVariables
  >;

/**
 * __useDeleteFinancialAccountDesignationMutation__
 *
 * To run a mutation, you first call `useDeleteFinancialAccountDesignationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFinancialAccountDesignationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFinancialAccountDesignationMutation, { data, loading, error }] = useDeleteFinancialAccountDesignationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFinancialAccountDesignationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFinancialAccountDesignationMutation,
    DeleteFinancialAccountDesignationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteFinancialAccountDesignationMutation,
    DeleteFinancialAccountDesignationMutationVariables
  >(DeleteFinancialAccountDesignationDocument, options);
}
export type DeleteFinancialAccountDesignationMutationHookResult = ReturnType<
  typeof useDeleteFinancialAccountDesignationMutation
>;
export type DeleteFinancialAccountDesignationMutationResult =
  ApolloReactCommon.MutationResult<DeleteFinancialAccountDesignationMutation>;
export type DeleteFinancialAccountDesignationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteFinancialAccountDesignationMutation,
    DeleteFinancialAccountDesignationMutationVariables
  >;
export const BusinessInterestInputDocument = gql`
  mutation BusinessInterestInput($input: [BusinessInterestInput!]!) {
    UpsertBusinessInterests(input: $input)
  }
`;
export type BusinessInterestInputMutationFn =
  ApolloReactCommon.MutationFunction<
    BusinessInterestInputMutation,
    BusinessInterestInputMutationVariables
  >;

/**
 * __useBusinessInterestInputMutation__
 *
 * To run a mutation, you first call `useBusinessInterestInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessInterestInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessInterestInputMutation, { data, loading, error }] = useBusinessInterestInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessInterestInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BusinessInterestInputMutation,
    BusinessInterestInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BusinessInterestInputMutation,
    BusinessInterestInputMutationVariables
  >(BusinessInterestInputDocument, options);
}
export type BusinessInterestInputMutationHookResult = ReturnType<
  typeof useBusinessInterestInputMutation
>;
export type BusinessInterestInputMutationResult =
  ApolloReactCommon.MutationResult<BusinessInterestInputMutation>;
export type BusinessInterestInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BusinessInterestInputMutation,
    BusinessInterestInputMutationVariables
  >;
export const DeleteBusinessInterestDocument = gql`
  mutation DeleteBusinessInterest($input: String!) {
    DeleteBusinessInterest(id: $input)
  }
`;
export type DeleteBusinessInterestMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteBusinessInterestMutation,
    DeleteBusinessInterestMutationVariables
  >;

/**
 * __useDeleteBusinessInterestMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessInterestMutation, { data, loading, error }] = useDeleteBusinessInterestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBusinessInterestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteBusinessInterestMutation,
    DeleteBusinessInterestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteBusinessInterestMutation,
    DeleteBusinessInterestMutationVariables
  >(DeleteBusinessInterestDocument, options);
}
export type DeleteBusinessInterestMutationHookResult = ReturnType<
  typeof useDeleteBusinessInterestMutation
>;
export type DeleteBusinessInterestMutationResult =
  ApolloReactCommon.MutationResult<DeleteBusinessInterestMutation>;
export type DeleteBusinessInterestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteBusinessInterestMutation,
    DeleteBusinessInterestMutationVariables
  >;
export const PersonalPropertyInputDocument = gql`
  mutation PersonalPropertyInput($input: [PersonalPropertyInput!]!) {
    UpsertPersonalProperties(input: $input)
  }
`;
export type PersonalPropertyInputMutationFn =
  ApolloReactCommon.MutationFunction<
    PersonalPropertyInputMutation,
    PersonalPropertyInputMutationVariables
  >;

/**
 * __usePersonalPropertyInputMutation__
 *
 * To run a mutation, you first call `usePersonalPropertyInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonalPropertyInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personalPropertyInputMutation, { data, loading, error }] = usePersonalPropertyInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonalPropertyInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PersonalPropertyInputMutation,
    PersonalPropertyInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PersonalPropertyInputMutation,
    PersonalPropertyInputMutationVariables
  >(PersonalPropertyInputDocument, options);
}
export type PersonalPropertyInputMutationHookResult = ReturnType<
  typeof usePersonalPropertyInputMutation
>;
export type PersonalPropertyInputMutationResult =
  ApolloReactCommon.MutationResult<PersonalPropertyInputMutation>;
export type PersonalPropertyInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PersonalPropertyInputMutation,
    PersonalPropertyInputMutationVariables
  >;
export const DeletePersonalPropertyDocument = gql`
  mutation DeletePersonalProperty($input: String!) {
    DeletePersonalProperty(id: $input)
  }
`;
export type DeletePersonalPropertyMutationFn =
  ApolloReactCommon.MutationFunction<
    DeletePersonalPropertyMutation,
    DeletePersonalPropertyMutationVariables
  >;

/**
 * __useDeletePersonalPropertyMutation__
 *
 * To run a mutation, you first call `useDeletePersonalPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonalPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonalPropertyMutation, { data, loading, error }] = useDeletePersonalPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePersonalPropertyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePersonalPropertyMutation,
    DeletePersonalPropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeletePersonalPropertyMutation,
    DeletePersonalPropertyMutationVariables
  >(DeletePersonalPropertyDocument, options);
}
export type DeletePersonalPropertyMutationHookResult = ReturnType<
  typeof useDeletePersonalPropertyMutation
>;
export type DeletePersonalPropertyMutationResult =
  ApolloReactCommon.MutationResult<DeletePersonalPropertyMutation>;
export type DeletePersonalPropertyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeletePersonalPropertyMutation,
    DeletePersonalPropertyMutationVariables
  >;
export const RealEstatePropertyInputDocument = gql`
  mutation RealEstatePropertyInput($input: [RealEstatePropertyInput!]!) {
    UpsertRealEstateProperties(input: $input)
  }
`;
export type RealEstatePropertyInputMutationFn =
  ApolloReactCommon.MutationFunction<
    RealEstatePropertyInputMutation,
    RealEstatePropertyInputMutationVariables
  >;

/**
 * __useRealEstatePropertyInputMutation__
 *
 * To run a mutation, you first call `useRealEstatePropertyInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRealEstatePropertyInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [realEstatePropertyInputMutation, { data, loading, error }] = useRealEstatePropertyInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRealEstatePropertyInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RealEstatePropertyInputMutation,
    RealEstatePropertyInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RealEstatePropertyInputMutation,
    RealEstatePropertyInputMutationVariables
  >(RealEstatePropertyInputDocument, options);
}
export type RealEstatePropertyInputMutationHookResult = ReturnType<
  typeof useRealEstatePropertyInputMutation
>;
export type RealEstatePropertyInputMutationResult =
  ApolloReactCommon.MutationResult<RealEstatePropertyInputMutation>;
export type RealEstatePropertyInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RealEstatePropertyInputMutation,
    RealEstatePropertyInputMutationVariables
  >;
export const DeleteRealEstatePropertyDocument = gql`
  mutation DeleteRealEstateProperty($input: String!) {
    DeleteRealEstateProperty(id: $input)
  }
`;
export type DeleteRealEstatePropertyMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteRealEstatePropertyMutation,
    DeleteRealEstatePropertyMutationVariables
  >;

/**
 * __useDeleteRealEstatePropertyMutation__
 *
 * To run a mutation, you first call `useDeleteRealEstatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRealEstatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRealEstatePropertyMutation, { data, loading, error }] = useDeleteRealEstatePropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRealEstatePropertyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteRealEstatePropertyMutation,
    DeleteRealEstatePropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteRealEstatePropertyMutation,
    DeleteRealEstatePropertyMutationVariables
  >(DeleteRealEstatePropertyDocument, options);
}
export type DeleteRealEstatePropertyMutationHookResult = ReturnType<
  typeof useDeleteRealEstatePropertyMutation
>;
export type DeleteRealEstatePropertyMutationResult =
  ApolloReactCommon.MutationResult<DeleteRealEstatePropertyMutation>;
export type DeleteRealEstatePropertyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteRealEstatePropertyMutation,
    DeleteRealEstatePropertyMutationVariables
  >;
export const DeleteAssetsDocument = gql`
  mutation DeleteAssets($input: AssetInput!) {
    DeleteAssets(input: $input)
  }
`;
export type DeleteAssetsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAssetsMutation,
  DeleteAssetsMutationVariables
>;

/**
 * __useDeleteAssetsMutation__
 *
 * To run a mutation, you first call `useDeleteAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetsMutation, { data, loading, error }] = useDeleteAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAssetsMutation,
    DeleteAssetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteAssetsMutation,
    DeleteAssetsMutationVariables
  >(DeleteAssetsDocument, options);
}
export type DeleteAssetsMutationHookResult = ReturnType<
  typeof useDeleteAssetsMutation
>;
export type DeleteAssetsMutationResult =
  ApolloReactCommon.MutationResult<DeleteAssetsMutation>;
export type DeleteAssetsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteAssetsMutation,
  DeleteAssetsMutationVariables
>;
export const RecommendationQuestionnaireDocument = gql`
  mutation RecommendationQuestionnaire(
    $input: RecommendationQuestionnaireInput!
  ) {
    updateRecommendationQuestionnaire(input: $input) {
      ...UserInfoFragment
    }
  }
  ${UserInfoFragmentFragmentDoc}
`;
export type RecommendationQuestionnaireMutationFn =
  ApolloReactCommon.MutationFunction<
    RecommendationQuestionnaireMutation,
    RecommendationQuestionnaireMutationVariables
  >;

/**
 * __useRecommendationQuestionnaireMutation__
 *
 * To run a mutation, you first call `useRecommendationQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecommendationQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recommendationQuestionnaireMutation, { data, loading, error }] = useRecommendationQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecommendationQuestionnaireMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecommendationQuestionnaireMutation,
    RecommendationQuestionnaireMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RecommendationQuestionnaireMutation,
    RecommendationQuestionnaireMutationVariables
  >(RecommendationQuestionnaireDocument, options);
}
export type RecommendationQuestionnaireMutationHookResult = ReturnType<
  typeof useRecommendationQuestionnaireMutation
>;
export type RecommendationQuestionnaireMutationResult =
  ApolloReactCommon.MutationResult<RecommendationQuestionnaireMutation>;
export type RecommendationQuestionnaireMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RecommendationQuestionnaireMutation,
    RecommendationQuestionnaireMutationVariables
  >;
export const UserInfoDocument = gql`
  query UserInfo {
    userInfo {
      ...UserInfoFragment
    }
  }
  ${UserInfoFragmentFragmentDoc}
`;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserInfoQuery,
    UserInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserInfoQuery, UserInfoQueryVariables>(
    UserInfoDocument,
    options,
  );
}
export function useUserInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserInfoQuery,
    UserInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(
    UserInfoDocument,
    options,
  );
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<
  typeof useUserInfoLazyQuery
>;
export type UserInfoQueryResult = ApolloReactCommon.QueryResult<
  UserInfoQuery,
  UserInfoQueryVariables
>;
export const BeneficiariesInputDocument = gql`
  mutation BeneficiariesInput($input: [BeneficiariesInput!]!) {
    UpsertBeneficiaries(input: $input)
  }
`;
export type BeneficiariesInputMutationFn = ApolloReactCommon.MutationFunction<
  BeneficiariesInputMutation,
  BeneficiariesInputMutationVariables
>;

/**
 * __useBeneficiariesInputMutation__
 *
 * To run a mutation, you first call `useBeneficiariesInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeneficiariesInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beneficiariesInputMutation, { data, loading, error }] = useBeneficiariesInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBeneficiariesInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BeneficiariesInputMutation,
    BeneficiariesInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BeneficiariesInputMutation,
    BeneficiariesInputMutationVariables
  >(BeneficiariesInputDocument, options);
}
export type BeneficiariesInputMutationHookResult = ReturnType<
  typeof useBeneficiariesInputMutation
>;
export type BeneficiariesInputMutationResult =
  ApolloReactCommon.MutationResult<BeneficiariesInputMutation>;
export type BeneficiariesInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BeneficiariesInputMutation,
    BeneficiariesInputMutationVariables
  >;
export const PaymentDetailsDocument = gql`
  query PaymentDetails {
    paymentDetails {
      ...PaymentFragment
    }
  }
  ${PaymentFragmentFragmentDoc}
`;

/**
 * __usePaymentDetailsQuery__
 *
 * To run a query within a React component, call `usePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentDetailsQuery,
    PaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PaymentDetailsQuery,
    PaymentDetailsQueryVariables
  >(PaymentDetailsDocument, options);
}
export function usePaymentDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentDetailsQuery,
    PaymentDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PaymentDetailsQuery,
    PaymentDetailsQueryVariables
  >(PaymentDetailsDocument, options);
}
export type PaymentDetailsQueryHookResult = ReturnType<
  typeof usePaymentDetailsQuery
>;
export type PaymentDetailsLazyQueryHookResult = ReturnType<
  typeof usePaymentDetailsLazyQuery
>;
export type PaymentDetailsQueryResult = ApolloReactCommon.QueryResult<
  PaymentDetailsQuery,
  PaymentDetailsQueryVariables
>;
export const ShippingAddressInputDocument = gql`
  mutation ShippingAddressInput($input: AddressInput!) {
    upsertShippingAddress(input: $input)
  }
`;
export type ShippingAddressInputMutationFn = ApolloReactCommon.MutationFunction<
  ShippingAddressInputMutation,
  ShippingAddressInputMutationVariables
>;

/**
 * __useShippingAddressInputMutation__
 *
 * To run a mutation, you first call `useShippingAddressInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShippingAddressInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shippingAddressInputMutation, { data, loading, error }] = useShippingAddressInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShippingAddressInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ShippingAddressInputMutation,
    ShippingAddressInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ShippingAddressInputMutation,
    ShippingAddressInputMutationVariables
  >(ShippingAddressInputDocument, options);
}
export type ShippingAddressInputMutationHookResult = ReturnType<
  typeof useShippingAddressInputMutation
>;
export type ShippingAddressInputMutationResult =
  ApolloReactCommon.MutationResult<ShippingAddressInputMutation>;
export type ShippingAddressInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ShippingAddressInputMutation,
    ShippingAddressInputMutationVariables
  >;
export const CreatePaymentDocument = gql`
  mutation CreatePayment($input: PaymentInput!) {
    paymentCreate(input: $input) {
      id
      create_payment_code
    }
  }
`;
export type CreatePaymentMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentMutation,
    CreatePaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePaymentMutation,
    CreatePaymentMutationVariables
  >(CreatePaymentDocument, options);
}
export type CreatePaymentMutationHookResult = ReturnType<
  typeof useCreatePaymentMutation
>;
export type CreatePaymentMutationResult =
  ApolloReactCommon.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreatePaymentMutation,
    CreatePaymentMutationVariables
  >;
export const UpdatePaymentStatusDocument = gql`
  mutation UpdatePaymentStatus($input: PaymentStatusInput!) {
    updatePaymentStatus(input: $input)
  }
`;
export type UpdatePaymentStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePaymentStatusMutation,
  UpdatePaymentStatusMutationVariables
>;

/**
 * __useUpdatePaymentStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentStatusMutation, { data, loading, error }] = useUpdatePaymentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentStatusMutation,
    UpdatePaymentStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePaymentStatusMutation,
    UpdatePaymentStatusMutationVariables
  >(UpdatePaymentStatusDocument, options);
}
export type UpdatePaymentStatusMutationHookResult = ReturnType<
  typeof useUpdatePaymentStatusMutation
>;
export type UpdatePaymentStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdatePaymentStatusMutation>;
export type UpdatePaymentStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdatePaymentStatusMutation,
    UpdatePaymentStatusMutationVariables
  >;
export const AddressDocument = gql`
  query Address {
    userAddresses {
      ...UserAddressFragment
    }
  }
  ${UserAddressFragmentFragmentDoc}
`;

/**
 * __useAddressQuery__
 *
 * To run a query within a React component, call `useAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AddressQuery,
    AddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AddressQuery, AddressQueryVariables>(
    AddressDocument,
    options,
  );
}
export function useAddressLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddressQuery,
    AddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AddressQuery, AddressQueryVariables>(
    AddressDocument,
    options,
  );
}
export type AddressQueryHookResult = ReturnType<typeof useAddressQuery>;
export type AddressLazyQueryHookResult = ReturnType<typeof useAddressLazyQuery>;
export type AddressQueryResult = ApolloReactCommon.QueryResult<
  AddressQuery,
  AddressQueryVariables
>;
export const UserPlanDocument = gql`
  query UserPlan($id: ID!) {
    userPlan(id: $id) {
      ...UserPlanFragment
    }
  }
  ${UserPlanFragmentFragmentDoc}
`;

/**
 * __useUserPlanQuery__
 *
 * To run a query within a React component, call `useUserPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPlanQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserPlanQuery,
    UserPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserPlanQuery, UserPlanQueryVariables>(
    UserPlanDocument,
    options,
  );
}
export function useUserPlanLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserPlanQuery,
    UserPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserPlanQuery, UserPlanQueryVariables>(
    UserPlanDocument,
    options,
  );
}
export type UserPlanQueryHookResult = ReturnType<typeof useUserPlanQuery>;
export type UserPlanLazyQueryHookResult = ReturnType<
  typeof useUserPlanLazyQuery
>;
export type UserPlanQueryResult = ApolloReactCommon.QueryResult<
  UserPlanQuery,
  UserPlanQueryVariables
>;
export const CreateCheckoutSessionDocument = gql`
  query CreateCheckoutSession($input: CheckoutSessionInput!) {
    createCheckoutSession(input: $input)
  }
`;

/**
 * __useCreateCheckoutSessionQuery__
 *
 * To run a query within a React component, call `useCreateCheckoutSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCheckoutSessionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutSessionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CreateCheckoutSessionQuery,
    CreateCheckoutSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CreateCheckoutSessionQuery,
    CreateCheckoutSessionQueryVariables
  >(CreateCheckoutSessionDocument, options);
}
export function useCreateCheckoutSessionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CreateCheckoutSessionQuery,
    CreateCheckoutSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CreateCheckoutSessionQuery,
    CreateCheckoutSessionQueryVariables
  >(CreateCheckoutSessionDocument, options);
}
export type CreateCheckoutSessionQueryHookResult = ReturnType<
  typeof useCreateCheckoutSessionQuery
>;
export type CreateCheckoutSessionLazyQueryHookResult = ReturnType<
  typeof useCreateCheckoutSessionLazyQuery
>;
export type CreateCheckoutSessionQueryResult = ApolloReactCommon.QueryResult<
  CreateCheckoutSessionQuery,
  CreateCheckoutSessionQueryVariables
>;
export const ChildrenInputDocument = gql`
  mutation ChildrenInput($input: [ChildrenInput!]!) {
    UpsertChildren(input: $input)
  }
`;
export type ChildrenInputMutationFn = ApolloReactCommon.MutationFunction<
  ChildrenInputMutation,
  ChildrenInputMutationVariables
>;

/**
 * __useChildrenInputMutation__
 *
 * To run a mutation, you first call `useChildrenInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChildrenInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [childrenInputMutation, { data, loading, error }] = useChildrenInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChildrenInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChildrenInputMutation,
    ChildrenInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChildrenInputMutation,
    ChildrenInputMutationVariables
  >(ChildrenInputDocument, options);
}
export type ChildrenInputMutationHookResult = ReturnType<
  typeof useChildrenInputMutation
>;
export type ChildrenInputMutationResult =
  ApolloReactCommon.MutationResult<ChildrenInputMutation>;
export type ChildrenInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ChildrenInputMutation,
    ChildrenInputMutationVariables
  >;
export const DeleteChildDocument = gql`
  mutation DeleteChild($input: String!) {
    DeleteChild(id: $input)
  }
`;
export type DeleteChildMutationFn = ApolloReactCommon.MutationFunction<
  DeleteChildMutation,
  DeleteChildMutationVariables
>;

/**
 * __useDeleteChildMutation__
 *
 * To run a mutation, you first call `useDeleteChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChildMutation, { data, loading, error }] = useDeleteChildMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChildMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteChildMutation,
    DeleteChildMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteChildMutation,
    DeleteChildMutationVariables
  >(DeleteChildDocument, options);
}
export type DeleteChildMutationHookResult = ReturnType<
  typeof useDeleteChildMutation
>;
export type DeleteChildMutationResult =
  ApolloReactCommon.MutationResult<DeleteChildMutation>;
export type DeleteChildMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteChildMutation,
  DeleteChildMutationVariables
>;
export const InfoInputDocument = gql`
  mutation InfoInput($input: InfoInput!) {
    UpdateUserInfo(input: $input)
  }
`;
export type InfoInputMutationFn = ApolloReactCommon.MutationFunction<
  InfoInputMutation,
  InfoInputMutationVariables
>;

/**
 * __useInfoInputMutation__
 *
 * To run a mutation, you first call `useInfoInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInfoInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [infoInputMutation, { data, loading, error }] = useInfoInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInfoInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InfoInputMutation,
    InfoInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    InfoInputMutation,
    InfoInputMutationVariables
  >(InfoInputDocument, options);
}
export type InfoInputMutationHookResult = ReturnType<
  typeof useInfoInputMutation
>;
export type InfoInputMutationResult =
  ApolloReactCommon.MutationResult<InfoInputMutation>;
export type InfoInputMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InfoInputMutation,
  InfoInputMutationVariables
>;
export const IsValidPromoCodeDocument = gql`
  query IsValidPromoCode($promoCode: String!) {
    isValidPromoCode(promoCode: $promoCode) {
      ok
      id
    }
  }
`;

/**
 * __useIsValidPromoCodeQuery__
 *
 * To run a query within a React component, call `useIsValidPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsValidPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsValidPromoCodeQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useIsValidPromoCodeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IsValidPromoCodeQuery,
    IsValidPromoCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IsValidPromoCodeQuery,
    IsValidPromoCodeQueryVariables
  >(IsValidPromoCodeDocument, options);
}
export function useIsValidPromoCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IsValidPromoCodeQuery,
    IsValidPromoCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IsValidPromoCodeQuery,
    IsValidPromoCodeQueryVariables
  >(IsValidPromoCodeDocument, options);
}
export type IsValidPromoCodeQueryHookResult = ReturnType<
  typeof useIsValidPromoCodeQuery
>;
export type IsValidPromoCodeLazyQueryHookResult = ReturnType<
  typeof useIsValidPromoCodeLazyQuery
>;
export type IsValidPromoCodeQueryResult = ApolloReactCommon.QueryResult<
  IsValidPromoCodeQuery,
  IsValidPromoCodeQueryVariables
>;
export const DocumentPdfDocument = gql`
  query DocumentPDF($regenerate: Boolean = false) {
    documentPDF(regenerate: $regenerate) {
      ...DocumentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
`;

/**
 * __useDocumentPdfQuery__
 *
 * To run a query within a React component, call `useDocumentPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentPdfQuery({
 *   variables: {
 *      regenerate: // value for 'regenerate'
 *   },
 * });
 */
export function useDocumentPdfQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DocumentPdfQuery,
    DocumentPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DocumentPdfQuery, DocumentPdfQueryVariables>(
    DocumentPdfDocument,
    options,
  );
}
export function useDocumentPdfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentPdfQuery,
    DocumentPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DocumentPdfQuery,
    DocumentPdfQueryVariables
  >(DocumentPdfDocument, options);
}
export type DocumentPdfQueryHookResult = ReturnType<typeof useDocumentPdfQuery>;
export type DocumentPdfLazyQueryHookResult = ReturnType<
  typeof useDocumentPdfLazyQuery
>;
export type DocumentPdfQueryResult = ApolloReactCommon.QueryResult<
  DocumentPdfQuery,
  DocumentPdfQueryVariables
>;
export const GuardianshipInputDocument = gql`
  mutation GuardianshipInput($input: [GuardianshipInput!]!) {
    UpdateGuardianship(input: $input)
  }
`;
export type GuardianshipInputMutationFn = ApolloReactCommon.MutationFunction<
  GuardianshipInputMutation,
  GuardianshipInputMutationVariables
>;

/**
 * __useGuardianshipInputMutation__
 *
 * To run a mutation, you first call `useGuardianshipInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuardianshipInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guardianshipInputMutation, { data, loading, error }] = useGuardianshipInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuardianshipInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuardianshipInputMutation,
    GuardianshipInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuardianshipInputMutation,
    GuardianshipInputMutationVariables
  >(GuardianshipInputDocument, options);
}
export type GuardianshipInputMutationHookResult = ReturnType<
  typeof useGuardianshipInputMutation
>;
export type GuardianshipInputMutationResult =
  ApolloReactCommon.MutationResult<GuardianshipInputMutation>;
export type GuardianshipInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GuardianshipInputMutation,
    GuardianshipInputMutationVariables
  >;
export const PlacesDocument = gql`
  query Places($ids: [ID!]!) {
    places(ids: $ids) {
      ...SearchPlace
    }
  }
  ${SearchPlaceFragmentDoc}
`;

/**
 * __usePlacesQuery__
 *
 * To run a query within a React component, call `usePlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePlacesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PlacesQuery,
    PlacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PlacesQuery, PlacesQueryVariables>(
    PlacesDocument,
    options,
  );
}
export function usePlacesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlacesQuery,
    PlacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PlacesQuery, PlacesQueryVariables>(
    PlacesDocument,
    options,
  );
}
export type PlacesQueryHookResult = ReturnType<typeof usePlacesQuery>;
export type PlacesLazyQueryHookResult = ReturnType<typeof usePlacesLazyQuery>;
export type PlacesQueryResult = ApolloReactCommon.QueryResult<
  PlacesQuery,
  PlacesQueryVariables
>;
export const PersonUpsertDocument = gql`
  mutation PersonUpsert($input: PersonInput!) {
    personUpsert(input: $input)
  }
`;
export type PersonUpsertMutationFn = ApolloReactCommon.MutationFunction<
  PersonUpsertMutation,
  PersonUpsertMutationVariables
>;

/**
 * __usePersonUpsertMutation__
 *
 * To run a mutation, you first call `usePersonUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personUpsertMutation, { data, loading, error }] = usePersonUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonUpsertMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PersonUpsertMutation,
    PersonUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PersonUpsertMutation,
    PersonUpsertMutationVariables
  >(PersonUpsertDocument, options);
}
export type PersonUpsertMutationHookResult = ReturnType<
  typeof usePersonUpsertMutation
>;
export type PersonUpsertMutationResult =
  ApolloReactCommon.MutationResult<PersonUpsertMutation>;
export type PersonUpsertMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PersonUpsertMutation,
  PersonUpsertMutationVariables
>;
export const PersonalRepresentativeInputDocument = gql`
  mutation PersonalRepresentativeInput($input: PersonalRepresentativeInput!) {
    UpsertPersonalRepresentative(input: $input)
  }
`;
export type PersonalRepresentativeInputMutationFn =
  ApolloReactCommon.MutationFunction<
    PersonalRepresentativeInputMutation,
    PersonalRepresentativeInputMutationVariables
  >;

/**
 * __usePersonalRepresentativeInputMutation__
 *
 * To run a mutation, you first call `usePersonalRepresentativeInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonalRepresentativeInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personalRepresentativeInputMutation, { data, loading, error }] = usePersonalRepresentativeInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonalRepresentativeInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PersonalRepresentativeInputMutation,
    PersonalRepresentativeInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PersonalRepresentativeInputMutation,
    PersonalRepresentativeInputMutationVariables
  >(PersonalRepresentativeInputDocument, options);
}
export type PersonalRepresentativeInputMutationHookResult = ReturnType<
  typeof usePersonalRepresentativeInputMutation
>;
export type PersonalRepresentativeInputMutationResult =
  ApolloReactCommon.MutationResult<PersonalRepresentativeInputMutation>;
export type PersonalRepresentativeInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PersonalRepresentativeInputMutation,
    PersonalRepresentativeInputMutationVariables
  >;
export const PetsInputDocument = gql`
  mutation PetsInput($input: [PetsInput!]!) {
    UpsertPets(input: $input)
  }
`;
export type PetsInputMutationFn = ApolloReactCommon.MutationFunction<
  PetsInputMutation,
  PetsInputMutationVariables
>;

/**
 * __usePetsInputMutation__
 *
 * To run a mutation, you first call `usePetsInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePetsInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [petsInputMutation, { data, loading, error }] = usePetsInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePetsInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PetsInputMutation,
    PetsInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PetsInputMutation,
    PetsInputMutationVariables
  >(PetsInputDocument, options);
}
export type PetsInputMutationHookResult = ReturnType<
  typeof usePetsInputMutation
>;
export type PetsInputMutationResult =
  ApolloReactCommon.MutationResult<PetsInputMutation>;
export type PetsInputMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PetsInputMutation,
  PetsInputMutationVariables
>;
export const DeletePetDocument = gql`
  mutation DeletePet($input: String!) {
    DeletePet(id: $input)
  }
`;
export type DeletePetMutationFn = ApolloReactCommon.MutationFunction<
  DeletePetMutation,
  DeletePetMutationVariables
>;

/**
 * __useDeletePetMutation__
 *
 * To run a mutation, you first call `useDeletePetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePetMutation, { data, loading, error }] = useDeletePetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePetMutation,
    DeletePetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeletePetMutation,
    DeletePetMutationVariables
  >(DeletePetDocument, options);
}
export type DeletePetMutationHookResult = ReturnType<
  typeof useDeletePetMutation
>;
export type DeletePetMutationResult =
  ApolloReactCommon.MutationResult<DeletePetMutation>;
export type DeletePetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePetMutation,
  DeletePetMutationVariables
>;
export const UpdateHasPetsDocument = gql`
  mutation UpdateHasPets($hasPets: Boolean!) {
    updateHasPets(input: $hasPets)
  }
`;
export type UpdateHasPetsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateHasPetsMutation,
  UpdateHasPetsMutationVariables
>;

/**
 * __useUpdateHasPetsMutation__
 *
 * To run a mutation, you first call `useUpdateHasPetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHasPetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHasPetsMutation, { data, loading, error }] = useUpdateHasPetsMutation({
 *   variables: {
 *      hasPets: // value for 'hasPets'
 *   },
 * });
 */
export function useUpdateHasPetsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHasPetsMutation,
    UpdateHasPetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateHasPetsMutation,
    UpdateHasPetsMutationVariables
  >(UpdateHasPetsDocument, options);
}
export type UpdateHasPetsMutationHookResult = ReturnType<
  typeof useUpdateHasPetsMutation
>;
export type UpdateHasPetsMutationResult =
  ApolloReactCommon.MutationResult<UpdateHasPetsMutation>;
export type UpdateHasPetsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateHasPetsMutation,
    UpdateHasPetsMutationVariables
  >;
export const PlanInfoDocument = gql`
  query PlanInfo {
    planInfo {
      ...PlanInfoFragment
    }
  }
  ${PlanInfoFragmentFragmentDoc}
`;

/**
 * __usePlanInfoQuery__
 *
 * To run a query within a React component, call `usePlanInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PlanInfoQuery,
    PlanInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PlanInfoQuery, PlanInfoQueryVariables>(
    PlanInfoDocument,
    options,
  );
}
export function usePlanInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlanInfoQuery,
    PlanInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PlanInfoQuery, PlanInfoQueryVariables>(
    PlanInfoDocument,
    options,
  );
}
export type PlanInfoQueryHookResult = ReturnType<typeof usePlanInfoQuery>;
export type PlanInfoLazyQueryHookResult = ReturnType<
  typeof usePlanInfoLazyQuery
>;
export type PlanInfoQueryResult = ApolloReactCommon.QueryResult<
  PlanInfoQuery,
  PlanInfoQueryVariables
>;
export const PlanDocument = gql`
  mutation Plan($input: PlanInput!) {
    updatePlan(input: $input) {
      id
    }
  }
`;
export type PlanMutationFn = ApolloReactCommon.MutationFunction<
  PlanMutation,
  PlanMutationVariables
>;

/**
 * __usePlanMutation__
 *
 * To run a mutation, you first call `usePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planMutation, { data, loading, error }] = usePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PlanMutation,
    PlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PlanMutation, PlanMutationVariables>(
    PlanDocument,
    options,
  );
}
export type PlanMutationHookResult = ReturnType<typeof usePlanMutation>;
export type PlanMutationResult = ApolloReactCommon.MutationResult<PlanMutation>;
export type PlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PlanMutation,
  PlanMutationVariables
>;
export const PlanDocumentDocument = gql`
  query PlanDocument {
    planDocuments {
      ...PlanDocumentFragment
    }
  }
  ${PlanDocumentFragmentFragmentDoc}
`;

/**
 * __usePlanDocumentQuery__
 *
 * To run a query within a React component, call `usePlanDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanDocumentQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanDocumentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PlanDocumentQuery,
    PlanDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PlanDocumentQuery,
    PlanDocumentQueryVariables
  >(PlanDocumentDocument, options);
}
export function usePlanDocumentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlanDocumentQuery,
    PlanDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PlanDocumentQuery,
    PlanDocumentQueryVariables
  >(PlanDocumentDocument, options);
}
export type PlanDocumentQueryHookResult = ReturnType<
  typeof usePlanDocumentQuery
>;
export type PlanDocumentLazyQueryHookResult = ReturnType<
  typeof usePlanDocumentLazyQuery
>;
export type PlanDocumentQueryResult = ApolloReactCommon.QueryResult<
  PlanDocumentQuery,
  PlanDocumentQueryVariables
>;
export const PowerOfAttorneyInputDocument = gql`
  mutation PowerOfAttorneyInput($input: PowerOfAttorneyInput!) {
    UpsertPowerOfAttorney(input: $input)
  }
`;
export type PowerOfAttorneyInputMutationFn = ApolloReactCommon.MutationFunction<
  PowerOfAttorneyInputMutation,
  PowerOfAttorneyInputMutationVariables
>;

/**
 * __usePowerOfAttorneyInputMutation__
 *
 * To run a mutation, you first call `usePowerOfAttorneyInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePowerOfAttorneyInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [powerOfAttorneyInputMutation, { data, loading, error }] = usePowerOfAttorneyInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePowerOfAttorneyInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PowerOfAttorneyInputMutation,
    PowerOfAttorneyInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PowerOfAttorneyInputMutation,
    PowerOfAttorneyInputMutationVariables
  >(PowerOfAttorneyInputDocument, options);
}
export type PowerOfAttorneyInputMutationHookResult = ReturnType<
  typeof usePowerOfAttorneyInputMutation
>;
export type PowerOfAttorneyInputMutationResult =
  ApolloReactCommon.MutationResult<PowerOfAttorneyInputMutation>;
export type PowerOfAttorneyInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PowerOfAttorneyInputMutation,
    PowerOfAttorneyInputMutationVariables
  >;
export const TrusteesInputDocument = gql`
  mutation TrusteesInput($input: TrusteesInput!) {
    UpsertTrustees(input: $input)
  }
`;
export type TrusteesInputMutationFn = ApolloReactCommon.MutationFunction<
  TrusteesInputMutation,
  TrusteesInputMutationVariables
>;

/**
 * __useTrusteesInputMutation__
 *
 * To run a mutation, you first call `useTrusteesInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrusteesInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trusteesInputMutation, { data, loading, error }] = useTrusteesInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrusteesInputMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TrusteesInputMutation,
    TrusteesInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TrusteesInputMutation,
    TrusteesInputMutationVariables
  >(TrusteesInputDocument, options);
}
export type TrusteesInputMutationHookResult = ReturnType<
  typeof useTrusteesInputMutation
>;
export type TrusteesInputMutationResult =
  ApolloReactCommon.MutationResult<TrusteesInputMutation>;
export type TrusteesInputMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    TrusteesInputMutation,
    TrusteesInputMutationVariables
  >;
export const BasicInfoDocument = gql`
  mutation BasicInfo($input: BasicInfoInput!) {
    updateBasicInfo(input: $input)
  }
`;
export type BasicInfoMutationFn = ApolloReactCommon.MutationFunction<
  BasicInfoMutation,
  BasicInfoMutationVariables
>;

/**
 * __useBasicInfoMutation__
 *
 * To run a mutation, you first call `useBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [basicInfoMutation, { data, loading, error }] = useBasicInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBasicInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BasicInfoMutation,
    BasicInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BasicInfoMutation,
    BasicInfoMutationVariables
  >(BasicInfoDocument, options);
}
export type BasicInfoMutationHookResult = ReturnType<
  typeof useBasicInfoMutation
>;
export type BasicInfoMutationResult =
  ApolloReactCommon.MutationResult<BasicInfoMutation>;
export type BasicInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BasicInfoMutation,
  BasicInfoMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      id
      email
      user_info {
        on_boarding_status
        plan_id
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export function useMeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MeQuery,
    MeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<
  MeQuery,
  MeQueryVariables
>;
export const UpdateOnBoardingStatusDocument = gql`
  mutation UpdateOnBoardingStatus($input: OnBoardingStatusInput!) {
    updateOnBoardingStatus(input: $input)
  }
`;
export type UpdateOnBoardingStatusMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateOnBoardingStatusMutation,
    UpdateOnBoardingStatusMutationVariables
  >;

/**
 * __useUpdateOnBoardingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnBoardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnBoardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnBoardingStatusMutation, { data, loading, error }] = useUpdateOnBoardingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnBoardingStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOnBoardingStatusMutation,
    UpdateOnBoardingStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOnBoardingStatusMutation,
    UpdateOnBoardingStatusMutationVariables
  >(UpdateOnBoardingStatusDocument, options);
}
export type UpdateOnBoardingStatusMutationHookResult = ReturnType<
  typeof useUpdateOnBoardingStatusMutation
>;
export type UpdateOnBoardingStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateOnBoardingStatusMutation>;
export type UpdateOnBoardingStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateOnBoardingStatusMutation,
    UpdateOnBoardingStatusMutationVariables
  >;
