import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { 
  ChildrenFragmentFragment, 
  PetsFragmentFragment, 
  useUpdateHasPetsMutation, 
  useInfoInputMutation,
  BasicInfoType,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';
import getErrorMessage from '../../utils/getErrorMessage';

const usePets = () => {
  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } =
    useContext(DocumentContext);

  const [hasPets, setHasPets] = useState(false);
  const [guardians, SetGuardiansFromChildren] = useState<Array<ChildrenFragmentFragment>>([]);
  const [showGuardianBanner, setShowGuardianBanner] = useState<boolean>(false);
  const [finalPets, setFinalPets] = useState<Array<PetsFragmentFragment>>([]);

  const [updateHasPets, { loading: loadingUpdateHasPets }] =
    useUpdateHasPetsMutation();
  const [UpdateUserInfo] = useInfoInputMutation();

  const history = useHistory();

  const onPetsQuestion = (petsFlag: boolean) => async () => {
    try {
      await updateHasPets({
        variables: {
          hasPets: petsFlag,
        },
      });
      refetchPlanDocuments?.();
      if (!petsFlag) {
        history.push(apiUrls.PERSONAL_REPRESENTATIVE);
      } else {
        setHasPets(petsFlag);
      }
    } catch (e: any) {
      openSnackbar({ message: e?.message }, NotifierType.Error);
    }
  };

  useEffect(() => {
    setHasPets(planDocuments?.user_info?.has_pets ? true : false);
    let children = planDocuments?.children;
    let guardiansData = children?.filter(
      (child) => Number(child?.age) < 18 && child.primary_guardian_name,
    );

    SetGuardiansFromChildren(guardiansData?.length ? guardiansData : []);
  }, [planDocuments]);

  const UserInfoSetOption = async (value: boolean) => {
    try {
      await UpdateUserInfo({
        variables: {
          input:{
            section: BasicInfoType.Pets,
            answer: value,
          },
        },
      });
      refetchPlanDocuments?.();
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    loading: loadingPlanDocuments || loadingUpdateHasPets,
    pets: planDocuments?.pets,
    finalPets,
    setFinalPets,
    hasPets,
    hasPetsFlag: planDocuments?.user_info?.has_pets ? true : false,
    setHasPets,
    onPetsQuestion,
    guardians,
    SetGuardiansFromChildren,
    showGuardianBanner,
    setShowGuardianBanner,
    UserInfoSetOption,
  };
};

export default usePets;
