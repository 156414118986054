import React from 'react';
import Loader from '../../components/Loader';
import usePets from './pets-hooks';
import PetsBox from './PetsBox';
import PetsBanner from './PetsBanner';
import GuardianBanner from './GuardianBanner';
interface Props {
  uiFlag?: string;
  setEditState?: Function;
}

const PetsMain = ({ uiFlag, setEditState }: Props) => {
  const {
    loading,
    pets,
    finalPets,
    setFinalPets,
    hasPets,
    hasPetsFlag,
    setHasPets,
    onPetsQuestion,
    guardians,
    showGuardianBanner,
    setShowGuardianBanner,
    UserInfoSetOption,
  } = usePets();
  return (
    <div>
      {hasPets && showGuardianBanner  ? (
        <GuardianBanner
          guardians={guardians ? guardians : []}
          petsList={finalPets}
          setShowGuardianBanner={setShowGuardianBanner}
          uiFlag={uiFlag}
          setEditState={setEditState}
        />
      ) : hasPets ? (
          <PetsBox
            petsList={pets || []}
            setShowGuardianBanner={setShowGuardianBanner}
            setFinalPets={setFinalPets}
            setHasPets={setHasPets}
            uiFlag={uiFlag}
            setEditState={setEditState}
          />
      ) : (
        <PetsBanner hasPets={hasPetsFlag} onPetsQuestion={onPetsQuestion} UserInfoSetOption={UserInfoSetOption} />
      )}
      <Loader open={loading} />
    </div>
  );
};

export default React.memo(PetsMain);
