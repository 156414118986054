import React from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import TrusteeIcon from '../../components/Icons/CompletionScreen/TrusteeIcon';

const CompletionScreen = () => {
  const history = useHistory();

  return (
    <CompletionBox
      header={'Trustees'}
      image={TrusteeIcon}
      title="Awesome! Don't Sweat It. Your Trustee has your back."
      quote={
        'TruWill ticked all the boxes. ' +
        'They made it so easy for my wife and I to create a trust.'
      }
      quoteBy="Izzy"
      previous={() => history.push(apiUrls.TRUSTEES)}
      continue={() => history.push(apiUrls.ASSETS)}
    />
  );
};

export default React.memo(CompletionScreen);
