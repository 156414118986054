import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';

const usePersonalProperties = () => {
  const {
    planDocuments,
    loadingPlanDocuments,
  } = useContext(DocumentContext);

  const [ hasProperties, setHasProperties ] = useState(false);

  useEffect(() => {
    if ((planDocuments?.assets?.personal_properties?.length || 0) > 0) {
      setHasProperties(true);
    }
  }, [planDocuments]);

  return {
    loading: loadingPlanDocuments,
    properties: planDocuments?.assets?.personal_properties,
    hasProperties,
    setHasProperties,
  };
};

export default usePersonalProperties;
