import React from 'react';
import { UnpackNestedValue } from 'react-hook-form/dist/types';
import InputMask from 'react-input-mask';

interface CustomProps {
  onChange: (...event: any[]) => void;
  value: UnpackNestedValue<any>;
}

const PhoneNumberCustomInput = React.forwardRef<HTMLElement, CustomProps>(
  (props, ref) => {
    const { ...other } = props;
    return (
      <InputMask
        {...other}
        mask="(+1) 999 999 9999"
        alwaysShowMask
        inputRef={ref as any}
      />
    );
  },
);

export default React.memo(PhoneNumberCustomInput);
