import { useContext, useEffect, useState } from 'react';
import {
  BeneficiariesFragmentFragment,
  BeneficiaryType,
  useBeneficiariesInputMutation,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import { SUMMARY } from '../Summary/constants';

export interface BeneficiaryProps {
  uiFlag?: string;
  setEditState?: Function;
}

const useBeneficiary = ({ uiFlag, setEditState }: BeneficiaryProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      age: 18,
    },
  });
  const history = useHistory();
  const { planDocuments, loadingPlanDocuments, refetchPlanDocuments } =
    useContext(DocumentContext);

  const [divideEqually, setDivideEqually] = useState(false);
  const [showPercentForm, setShowPercentForm] = useState(true);
  const [beneficiaries, setBeneficiaries] = useState(
    [] as BeneficiariesFragmentFragment[],
  );

  const [totalPercentage, setTotalPercentage] = useState(0);

  useEffect(() => {
    if (planDocuments?.beneficiaries?.length) {
      setDivideEqually(false);
      setValue(
        'age',
        Number(planDocuments?.beneficiaries[0]?.inheritance_age) || 18,
      );
      setBeneficiaries(planDocuments?.beneficiaries);
    } else if (planDocuments?.children?.length) {
      setDivideEqually(true);
      const inheritancePercentage = 100 / planDocuments?.children?.length;
      setBeneficiaries(
        planDocuments?.children.map((child) => {
          return {
            owner_id: child.id,
            owner_type: BeneficiaryType.Child,
            full_name: child.full_name,
            inheritance_percentage: inheritancePercentage,
          } as unknown as BeneficiariesFragmentFragment;
        }),
      );
    }
  }, [planDocuments]);

  useEffect(() => {
    calculateTotalPercentage();
  }, [beneficiaries]);

  const calculateTotalPercentage = () => {
    const total = beneficiaries.reduce((prev, beneficiary) => {
      const percentage = parseFloat(beneficiary.inheritance_percentage) || 0;
      return prev + percentage;
    }, 0);
    const rounded = Math.round((total + Number.EPSILON) * 100) / 100;
    setTotalPercentage(rounded);
  };

  const onBeneficiaryNameChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let beneficiaryValues = JSON.parse(JSON.stringify(beneficiaries));
      beneficiaryValues[index].full_name = event.target.value;
      setBeneficiaries(beneficiaryValues);
    };

  const onBeneficiaryPercentChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let beneficiaryValues = JSON.parse(JSON.stringify(beneficiaries));
      beneficiaryValues[index].inheritance_percentage = event.target.value;
      setBeneficiaries(beneficiaryValues);
    };

  const onBeneficiaryRemove = (index: number) => () => {
    setDivideEqually(false);
    let beneficiaryValues = [...beneficiaries];
    beneficiaryValues.splice(index, 1);
    setBeneficiaries(beneficiaryValues);
  };

  const onBeneficiaryAppend =
    (beneficiary: BeneficiariesFragmentFragment) => () => {
      setDivideEqually(false);
      const appendBeneficiaries = [...beneficiaries, beneficiary];
      setBeneficiaries(appendBeneficiaries);
    };

  const [UpsertBeneficiaries, { loading: loadingBeneficiaries }] =
    useBeneficiariesInputMutation();

  const onEquallyDivided = async () => {
    setDivideEqually(true);
    const inheritancePercentage = await splitEqually(beneficiaries.length);
    const localBeneficiaries = beneficiaries.map((beneficiary, index) => {
      return {
        id: beneficiary.id || null,
        full_name: beneficiary.full_name,
        inheritance_percentage: inheritancePercentage[index],
        owner_id: beneficiary.owner_id,
        owner_type: beneficiary.owner_type,
      } as unknown as BeneficiariesFragmentFragment;
    });
    setBeneficiaries(localBeneficiaries);
  };

  const splitEqually = async (numberOfPeople: number) => {
    if (numberOfPeople <= 0) {
      console.error('Number of people must be greater than zero.');
      return [];
    }

    const amountPerPerson = Math.floor((100 / numberOfPeople) * 100) / 100; // Truncate to two decimal places
    const remainingAmount =
      Math.round((100 - amountPerPerson * numberOfPeople) * 100) / 100; // Rounding remaining amount to two decimal places

    const splitAmounts = Array(numberOfPeople).fill(amountPerPerson);

    splitAmounts[splitAmounts.length - 1] += remainingAmount;
    splitAmounts[splitAmounts.length - 1] = parseFloat(
      splitAmounts[splitAmounts.length - 1].toFixed(2),
    );
    return splitAmounts;
  };

  const onNextClickPercentForm = async () => {
    let nameFlag = false;
    beneficiaries.forEach((beneficiary) => {
      if (beneficiary.owner_type === BeneficiaryType.Other) {
        if (!beneficiary.full_name) {
          nameFlag = true;
        }
      }
    });
    if (nameFlag || totalPercentage !== 100) {
      if (nameFlag) {
        openSnackbar(
          { message: 'Please provide names for all your Beneficiaries.' },
          NotifierType.Error,
        );
      } else if (totalPercentage !== 100) {
        openSnackbar(
          {
            message:
              'Divided assets percentage should equal 100 when added together.',
          },
          NotifierType.Error,
        );
      }
    } else {
      setShowPercentForm(false);
    }
  };

  const onNextClickAgeForm = async () => {
    try {
      await UpsertBeneficiaries({
        variables: {
          input: beneficiaries.map((beneficiary) => {
            return {
              id: beneficiary.id || null,
              full_name: beneficiary.full_name,
              percentage:
                beneficiary.inheritance_percentage.toString() === ''
                  ? '0'
                  : beneficiary.inheritance_percentage.toString(),
              owner_id: beneficiary.owner_id,
              owner_type: beneficiary.owner_type,
              age: !Number(getValues('age'))
                ? '18'
                : getValues('age').toString(),
            };
          }),
        },
      });
      openSnackbar(
        {
          message: 'Beneficiaries added successfully',
        },
        NotifierType.Success,
      );
      await refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc = setEditState || Function;
        editFunc('');
      } else {
        history.push(apiUrls.BENEFICIARIES_COMPLETION);
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  return {
    loading: loadingBeneficiaries || loadingPlanDocuments,
    onBeneficiaryNameChange,
    onBeneficiaryPercentChange,
    onBeneficiaryRemove,
    onBeneficiaryAppend,
    trusteeName: planDocuments?.trustees?.primary_trustee_name,
    totalPercentage,
    beneficiaries,
    divideEqually,
    setDivideEqually,
    onEquallyDivided,
    onNextClickPercentForm,
    onNextClickAgeForm,
    handleSubmit,
    showPercentForm,
    setShowPercentForm,
    register,
    errors,
    control,
  };
};

export default useBeneficiary;
