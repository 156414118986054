import React from 'react';
import useChildren from './children-hooks';
import ChildrenBox from './ChildrenBox';
import ChildrenBanner from './ChildrenBanner';
import CompletionScreen from './CompletionScreen';

interface Props {
  uiFlag: string;
  setEditState: Function;
}

const Children = ({ uiFlag, setEditState }: Props) => {
  const {
    loading,
    userChildren,
    hasChildren,
    hasChildrenFlag,
    showForm,
    setShowChildrenForm,
    showCompletionScreen,
    setShowCompletionScreen,
    UserInfoSetOption,
  } = useChildren();
  return (
    <>
      {showCompletionScreen ? (
        <CompletionScreen setShowCompletionScreen={setShowCompletionScreen} />
      ) : hasChildren && showForm ? (
        <ChildrenBox
          childrenList={userChildren || []}
          setShowChildrenForm={setShowChildrenForm}
          setShowCompletionScreen={setShowCompletionScreen}
          uiFlag={uiFlag}
          setEditState={setEditState}
        />
      ) : (
        <ChildrenBanner
          hasChildren={hasChildrenFlag}
          setHasChildren={setShowChildrenForm}
          loading={loading}
          UserInfoSetOption={UserInfoSetOption}
        />
      )}
    </>
  );
};

export default React.memo(Children);
