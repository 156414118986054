import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import useStates from './states-hook';
import Loader from '../Loader';

interface Props {
  value: string;
  onChange: Function;
  inputLabel?: string;
}

const StatesDropdown = ({ value, onChange, inputLabel }: Props) => {
  const { states, loading } = useStates();
  return (
    <>
      <Autocomplete
        disablePortal
        value={value}
        onChange={(event: any, newValue: string | null) => {
          onChange(newValue);
        }}
        options={states}
        renderInput={(params) => (
          <TextField {...params} label={inputLabel || 'State'} />
        )}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(StatesDropdown);
