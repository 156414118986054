import React, { MouseEventHandler, useEffect, useState } from 'react';
import { PersonFragmentFragment, PersonType } from '../../../generated/graphql';
import ContactInfoForm from './ContactInfoForm';
import SpouseRelationForm from '../SpouseRelationForm';
import usePersonalInformationForm from './personal-information-form-hooks';
import PersonalInfoForm from './PersonalInfoForm';
import HasPartnerConfirmation from '../HasPartnerConfirmation';
import AboutYouForm from './AboutYouForm';
import AddressForm from './AddressForm';

export interface PersonInfoProps {
  self?: PersonFragmentFragment | null;
  spouse?: PersonFragmentFragment | null;
  hasPartner: boolean;
  personType?: PersonType;
  prev?: MouseEventHandler;
  next?: Function;
  resetPage?: Function;
  currentPage?: number;
  uiFlag?: string;
  setEditState?: Function;
}

const PersonalInformationForm = ({
  self,
  spouse,
  hasPartner,
  next,
  prev,
  currentPage,
  resetPage,
  uiFlag,
  setEditState,
  personType,
}: PersonInfoProps) => {
  const [partner, setPartner] = useState(hasPartner);
  const [info, setInfo] = useState<PersonFragmentFragment | null | undefined>(
    null,
  );
  const [type, setType] = useState(personType ? personType : PersonType.Self);

  useEffect(() => {
    if (type === PersonType.Self) {
      setInfo(self);
    } else {
      setInfo(spouse);
    }
  }, [self, spouse, type]);

  useEffect(() => {
    setPartner(hasPartner);
  }, [hasPartner]);

  const setHasPartner = (value: boolean) => {
    setPartner(value);
    setType(PersonType.Spouse);
    if (next) {
      next();
    }
  };

  const previousAction = (e: React.MouseEvent<Element, MouseEvent>) => {
    setType(PersonType.Self);
    if (prev) {
      prev(e);
    }
  };

  const {
    errors,
    handleSubmit,
    create,
    control,
    nextPage,
    onAutocompleteAddress,
    currentState,
    loading,
    UserInfoSetOption,
  } = usePersonalInformationForm({
    info,
    type,
    resetPage,
    currentPage,
    next,
    uiFlag,
    setEditState,
  });

  switch (currentPage) {
    case 1:
      return (
        <PersonalInfoForm
          type={PersonType.Self}
          errors={errors}
          control={control}
          nextPage={nextPage}
          uiFlag={uiFlag}
          setEditState={setEditState}
          handleSubmit={handleSubmit(create)}
        />
      );
    case 2:
      return (
        <AboutYouForm
          type={PersonType.Self}
          errors={errors}
          control={control}
          previousPage={prev}
          nextPage={nextPage}
          uiFlag={uiFlag}
          setEditState={setEditState}
          handleSubmit={handleSubmit(create)}
        />
      );
    case 3:
      return (
        <AddressForm
          type={PersonType.Self}
          errors={errors}
          control={control}
          previousPage={prev}
          nextPage={nextPage}
          stateName={currentState}
          onAutocompleteAddress={onAutocompleteAddress}
          uiFlag={uiFlag}
          setEditState={setEditState}
          handleSubmit={handleSubmit(create)}
        />
      );
    case 4:
      return (
        <ContactInfoForm
          type={PersonType.Self}
          errors={errors}
          control={control}
          previousPage={prev}
          nextPage={nextPage}
          isSubmitting={loading}
          handleSubmit={handleSubmit(create)}
          uiFlag={uiFlag}
          setEditState={setEditState}
        />
      );
    case 5:
      return (
        <HasPartnerConfirmation
          hasPartner={partner}
          setHasPartner={setHasPartner}
          previousPage={previousAction}
          UserInfoSetOption={UserInfoSetOption}
        />
      );
    case 6:
      return (
        <SpouseRelationForm
          errors={errors}
          control={control}
          previousPage={prev}
          nextPage={nextPage}
          handleSubmit={handleSubmit(create)}
        />
      );
    case 7:
      return (
        <PersonalInfoForm
          type={PersonType.Spouse}
          errors={errors}
          control={control}
          nextPage={nextPage}
          previousPage={prev}
          uiFlag={uiFlag}
          setEditState={setEditState}
          handleSubmit={handleSubmit(create)}
        />
      );
    case 8:
      return (
        <AboutYouForm
          type={PersonType.Spouse}
          errors={errors}
          control={control}
          previousPage={prev}
          nextPage={nextPage}
          handleSubmit={handleSubmit(create)}
          uiFlag={uiFlag}
          setEditState={setEditState}
        />
      );
    case 9:
      return (
        <ContactInfoForm
          type={PersonType.Spouse}
          errors={errors}
          control={control}
          previousPage={prev}
          nextPage={nextPage}
          isSubmitting={loading}
          handleSubmit={handleSubmit(create)}
          uiFlag={uiFlag}
          setEditState={setEditState}
        />
      );
    default:
      return <></>;
  }
};

export default React.memo(PersonalInformationForm);
