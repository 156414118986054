import * as React from 'react';
import { MouseEventHandler } from 'react';
import { Box, Breakpoint, Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import FeatureCheckMarkIcon from '../../components/Icons/FeatureCheckMarkIcon';
import { BasicPlanDetails, TrustPlanDetails } from './constants';
import { PlanInfoFragmentFragment } from '../../generated/graphql';
import { BillingPlan, BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';

interface Props {
  plan: PlanInfoFragmentFragment;
  handleCompareModalClose: MouseEventHandler;
}

const PlanInfo = ({ handleCompareModalClose, plan }: Props) => {
  const theme = useTheme();
  const planDetails =
    plan.name === BillingPlan.TrustPlan ? TrustPlanDetails : BasicPlanDetails;
  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
          top: '68%',
        },
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 949,
        minWidth: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}
    >
      <Typography variant="mDisplay" component="p" sx={{ mb: 2 }}>
        What you will get with
        {plan.name === BillingPlan.TrustPlan ? ' Trust + Will' : ' a Will'}
      </Typography>

      <Grid container spacing={2}>
        {planDetails.map(({ key, name, description }) => {
          if (key === 'power_of_attorney') {
            return (
              <>
                <Grid item xs={1} sx={{ maxWidth: { md: '5%' } }}>
                  <Box component={FeatureCheckMarkIcon} />
                </Grid>
                <Grid item xs={11} flexDirection="column">
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="mTextInfo">
                      Power(s) of Attorney
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="smText">
                      <Typography
                        variant="smText"
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        General Power of Attorney:&nbsp;
                      </Typography>
                      A document that appoints someone to act on your behalf in
                      the event that you cannot act for yourself in order to pay
                      bills, banking transactions, insurance, real estate
                      transactions, etc.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="smText">
                      <Typography
                        variant="smText"
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        Medical Power of Attorney:&nbsp;
                      </Typography>
                      A document that appoints someone to act on your behalf in
                      the event that you cannot act for yourself in order to
                      access medical records, medical history, health insurance,
                      etc.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            );
          } else {
            return (
              <>
                <Grid item xs={1} sx={{ maxWidth: { md: '5%' } }}>
                  <Box component={FeatureCheckMarkIcon} />
                </Grid>
                <Grid item xs={11} flexDirection="column">
                  <Grid item>
                    <Typography variant="mTextInfo">{name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="smText">{description}</Typography>
                  </Grid>
                </Grid>
              </>
            );
          }
        })}
      </Grid>
      <Box textAlign="center" sx={{ mt: 5 }}>
        <Button
          variant="contained"
          onClick={handleCompareModalClose}
          sx={{
            minWidth: '14.75rem',
          }}
        >
          OK
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(PlanInfo);
