import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';

const useForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [stateLoading, setLoading] = useState(false);

  const onForgotPassword = useCallback(
    async (data: Record<string, string>) => {
      setLoading(true);
      try {
        await Auth.forgotPassword(data.email.trim());
        setLoading(false);
        openSnackbar(
          { message: 'Password reset link has been sent to your email.' },
          NotifierType.Success);
      } catch (err: any) {
        setLoading(false);
        openSnackbar({ message: err?.message }, NotifierType.Error);
      }
    },
    [setLoading],
  );

  return {
    loading: stateLoading,
    register,
    errors,
    handleSubmit,
    onForgotPassword,
    setLoading,
  };

};

export default useForgotPassword;
