import { useAllStatesLazyQuery } from '../../generated/graphql';
import { useEffect, useState } from 'react';

const useStates = () => {
  const [states, setStates] = useState([] as string[]);

  const [getStates, { data, loading: loadingStates }] = useAllStatesLazyQuery({
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (data?.states) {
      setStates(data?.states?.map((state) => state.name));
    }
  }, [data]);

  return {
    states,
    loading: loadingStates,
  };
};

export default useStates;
