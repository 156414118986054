import React, { FormEventHandler, MouseEventHandler } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import { PersonType } from '../../../generated/graphql';
import { DataForm } from './personal-information-form-hooks';
import TabLayout from '../../../components/TabLayout';
import PhoneNumberCustomInput from '../../../components/PhoneNumberCustomInput';
import getUnMaskedPhoneNumber from '../../../utils/getUnMaskedPhoneNumber';
import { VALIDATOR_REGEX } from '../../../variables/constant';
import Loader from '../../../components/Loader';

export interface FormProps {
  type?: PersonType;
  info?: PersonType;
  errors: FieldErrors;
  control: Control<DataForm>;
  previousPage?: MouseEventHandler;
  nextPage: Function;
  isSubmitting: boolean;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  uiFlag?: string;
  setEditState?: Function;
}

const ContactInfoForm = ({
  type,
  errors,
  control,
  previousPage,
  isSubmitting,
  handleSubmit,
  uiFlag,
  setEditState,
}: FormProps) => {
  const theme = useTheme();

  return (
    <form autoComplete="off" onSubmit={handleSubmit} noValidate>
      <TabLayout
        title="Personal Information"
        previous={previousPage}
        isSubmitting={isSubmitting}
        uiFlag={uiFlag}
        setEditState={setEditState}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="m" component="p">
                {type === PersonType.Self
                  ? 'What is your contact info?'
                  : "What is your spouse/partner's contact info?"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="phone"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Phone"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        inputComponent: PhoneNumberCustomInput as any,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: 'Phone is required' },
                    validate: (value: string) => {
                      const regex = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
                      const phone = getUnMaskedPhoneNumber(value);
                      if (regex.test(phone)) {
                        return true;
                      }
                      return 'Phone number is not valid';
                    },
                  }}
                  control={control}
                />

                {errors.phone && (
                  <FormHelperErrorText>
                    {errors?.phone?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: VALIDATOR_REGEX.email,
                      message: 'Please enter correct email address.',
                    },
                  }}
                  control={control}
                />
                {errors.email && (
                  <FormHelperErrorText>
                    {errors?.email?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <Loader open={isSubmitting} />
      </TabLayout>
    </form>
  );
};

export default React.memo(ContactInfoForm);
