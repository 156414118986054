import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { apiUrls } from '../../variables/urls';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Breakpoint, Collapse, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { OnBoardingStatus } from '../../generated/graphql';
import ListItemButton from '@mui/material/ListItemButton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/system';
import { openSnackbar } from '../Notifier';
import { NotifierType } from '../../variables/types';
import { SUMMARY } from '../../pages/Summary/constants';

interface ProgressInfo {
  total: number;
  filled: number;
  completed: boolean;
  disabled: boolean;
}

interface Links {
  text: string;
  link: string;
  data: ProgressInfo | undefined;
  disabled: boolean;
}

interface Props {
  open: boolean;
  toggleDrawer: Function;
  toggleDrawerMenu: Function;
  drawerMenuState: boolean;
  isDocumentComplete: boolean;
  isDocumentEditable: boolean;
  onBoardingStatus: string;
  personalInformationProgress?: ProgressInfo;
  childrenInfoProgress?: ProgressInfo;
  guardianshipProgress?: ProgressInfo;
  petsProgress?: ProgressInfo;
  personalRepresentativeProgress?: ProgressInfo;
  trusteeProgress?: ProgressInfo;
  financialAssetsProgress?: ProgressInfo;
  beneficiaryProgress?: ProgressInfo;
  powerOfAttorneyProgress?: ProgressInfo;
  isPaymentComplete: boolean;
}

const SideBarMenuItemList = ({
  open,
  toggleDrawer,
  toggleDrawerMenu,
  drawerMenuState,
  isDocumentComplete,
  isDocumentEditable,
  onBoardingStatus,
  personalInformationProgress,
  childrenInfoProgress,
  guardianshipProgress,
  petsProgress,
  personalRepresentativeProgress,
  trusteeProgress,
  financialAssetsProgress,
  beneficiaryProgress,
  powerOfAttorneyProgress,
  isPaymentComplete,
}: Props) => {
  const history = useHistory();
  const onTabClick = (tabURL: string) => () => {
    history.push(tabURL);
  };

  const onTabMenuClick = (tabURL: string, data?: ProgressInfo) => () => {
    if (
      data?.completed ||
      isDocumentEditable ||
      tabURL.endsWith(apiUrls.PERSONAL_INFORMATION)
    ) {
      history.push(tabURL);
      return;
    }
    openSnackbar(
      {
        message:
          'Please make sure you have completed all the steps before proceeding to this section',
      },
      NotifierType.Error,
    );
  };

  const theme = useTheme();
  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint),
  );

  const links: Links[] = [
    {
      text: 'Personal Information',
      link: apiUrls.PERSONAL_INFORMATION,
      data: personalInformationProgress,
      disabled: personalInformationProgress?.disabled || false,
    },
    {
      text: 'Children',
      link: apiUrls.CHILDREN,
      data: childrenInfoProgress,
      disabled: childrenInfoProgress?.disabled || false,
    },
    {
      text: 'Guardianship',
      link: apiUrls.GUARDIANSHIP,
      data: guardianshipProgress,
      disabled: guardianshipProgress?.disabled || false,
    },
    {
      text: 'Pets',
      link: apiUrls.PETS,
      data: petsProgress,
      disabled: petsProgress?.disabled || false,
    },
    {
      text: 'Personal Representative',
      link: apiUrls.PERSONAL_REPRESENTATIVE,
      data: personalRepresentativeProgress,
      disabled: personalRepresentativeProgress?.disabled || false,
    },
    {
      text: 'Trustees',
      link: apiUrls.TRUSTEES,
      data: trusteeProgress,
      disabled: trusteeProgress?.disabled || false,
    },
    {
      text: 'Assets',
      link: apiUrls.ASSETS,
      data: financialAssetsProgress,
      disabled: financialAssetsProgress?.disabled || false,
    },
    {
      text: 'Beneficiaries',
      link: apiUrls.BENEFICIARIES,
      data: beneficiaryProgress,
      disabled: beneficiaryProgress?.disabled || false,
    },
    {
      text: 'Power of Attorney',
      link: apiUrls.POWER_OF_ATTORNEY_GENERAL,
      data: powerOfAttorneyProgress,
      disabled: powerOfAttorneyProgress?.disabled || false,
    },
  ];

  return (
    <Box>
      <List>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <Tooltip title={open ? '' : 'Home'} placement="right">
            <div
              onClick={desktopView ? undefined : toggleDrawer(false)}
              onKeyDown={desktopView ? undefined : toggleDrawer(false)}
            >
              <ListItemButton
                onClick={onTabClick(apiUrls.DASHBOARD)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <HomeOutlinedIcon
                    fontSize={'medium'}
                    sx={{
                      color: 'primary.main',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="sideBar">Home</Typography>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </div>
          </Tooltip>
        </ListItem>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={toggleDrawerMenu(drawerMenuState, 'Documents')}
        >
          <Tooltip title={open ? '' : 'Documents'} placement="right">
            <div>
              <ListItemButton
                disabled={!isPaymentComplete}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <DescriptionOutlinedIcon
                    fontSize={'medium'}
                    sx={{
                      color: 'primary.main',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="sideBar">Documents</Typography>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {open && (drawerMenuState ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            </div>
          </Tooltip>
        </ListItem>

        <Collapse in={drawerMenuState} timeout="auto" unmountOnExit>
          <List
            role="presentation"
            onClick={desktopView ? undefined : toggleDrawer(false)}
            onKeyDown={desktopView ? undefined : toggleDrawer(false)}
          >
            {links.map(({ text, link, data, disabled }) => (
              <ListItemButton key={text} onClick={onTabMenuClick(link, data)}>
                <ListItemText
                  sx={{ ml: 1 }}
                  disableTypography
                  primary={
                    <Typography
                      variant="smText"
                      sx={{
                        color: disabled
                          ? theme.palette.grey[700]
                          : theme.palette.common.naturalBlack,
                      }}
                    >
                      {text}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <Tooltip title={open ? '' : SUMMARY} placement="right">
            <div
              onClick={desktopView ? undefined : toggleDrawer(false)}
              onKeyDown={desktopView ? undefined : toggleDrawer(false)}
            >
              <ListItemButton
                disabled={!isDocumentComplete}
                onClick={onTabClick(apiUrls.SUMMARY)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <VisibilityOutlinedIcon
                    fontSize={'medium'}
                    sx={{
                      color: 'primary.main',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="sideBar">Summary</Typography>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </div>
          </Tooltip>
        </ListItem>

        {/* <ListItem disablePadding sx={{ display: 'block' }}>
          <Tooltip title={open ? '' : 'Payment'} placement="right">
            <div
              onClick={desktopView ? undefined : toggleDrawer(false)}
              onKeyDown={desktopView ? undefined : toggleDrawer(false)}
            >
              <ListItemButton
                disabled={
                  !(
                    onBoardingStatus === OnBoardingStatus.Payment &&
                    isDocumentComplete
                  )
                }
                onClick={onTabClick(apiUrls.CHECKOUT)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PaymentOutlinedIcon
                    fontSize={'medium'}
                    sx={{
                      color: 'primary.main',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="sideBar">Payment</Typography>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </div>
          </Tooltip>
        </ListItem> */}

        <ListItem disablePadding sx={{ display: 'block' }}>
          <Tooltip title={open ? '' : 'Notarization'} placement="right">
            <div
              onClick={desktopView ? undefined : toggleDrawer(false)}
              onKeyDown={desktopView ? undefined : toggleDrawer(false)}
            >
              <ListItemButton
                disabled={
                  !(
                    onBoardingStatus === OnBoardingStatus.Notarization &&
                    isDocumentComplete
                  )
                }
                onClick={onTabClick(apiUrls.NEAR_BY_NOTARY)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ApprovalOutlinedIcon
                    fontSize={'medium'}
                    sx={{
                      color: 'primary.main',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="sideBar">Notarization</Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </div>
          </Tooltip>
        </ListItem>
      </List>
    </Box>
  );
};

export default SideBarMenuItemList;
