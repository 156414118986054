/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getToken } from './auth';
import { apiUrls } from '../variables/urls';
import { DocumentContext } from '../pages/PlanDocuments/DocumentContext';
import usePlanDocument from '../pages/PlanDocuments/plan-document-hook';

interface Props {
  children: React.ReactNode;
  path: string;
}

const PrivateRoute = ({ children, ...rest }: Props) => {
  const { loading, planDocuments, refetch } = usePlanDocument();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (getToken()) {
          return (
            <DocumentContext.Provider
              value={{
                planDocuments: planDocuments?.planDocuments || null,
                loadingPlanDocuments: loading,
                refetchPlanDocuments: refetch,
              }}
            >
              {children}
            </DocumentContext.Provider>
          );
        }
        return (
          <Redirect
            to={{
              pathname: apiUrls.HOME,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
