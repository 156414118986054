import React from 'react';
import { Box, Divider } from '@mui/material';
import ColorPalette from './ColorPalette';
import ButtonList from './ButtonList';
import TypographyList from './TypographyList';
import SelectorList from './SelectorList';

const StyleGuide = () => {
  return (
    <Box
      p={3}
      sx={{
        bgcolor: 'common.white',
        width: '100%',
        height: 'max-content',
      }}
    >
      <ColorPalette />
      <Divider
        sx={{
          my: 3,
        }}
      />
      <TypographyList />
      <Divider
        sx={{
          my: 3,
        }}
      />
      <ButtonList />
      <Divider
        sx={{
          my: 3,
        }}
      />
      <SelectorList />
    </Box>
  );
};

export default React.memo(StyleGuide);
