import { useContext, useEffect, useState } from 'react';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import useGeocoder from '../../hooks/maps/geocoder';
import useGoogleMap from '../../hooks/maps/google-map';
import usePlacesService from '../../hooks/maps/places';
import { SearchPlaceFragment, usePlacesQuery } from '../../generated/graphql';
import {
  MAP_NOTARY_RADIUS,
  MAP_NOTARY_SEARCH_KEYWORD,
  MAP_SEARCH_DEBOUNCE,
} from '../../variables/constant';
import { DocumentContext } from '../PlanDocuments/DocumentContext';

interface Props {
  mapOptions: google.maps.MapOptions;
  setZipCode?: (state: string) => void;
}

const useMapLocations = ({ mapOptions }: Props) => {
  const { geocode } = useGeocoder();
  const { map, ref } = useGoogleMap(mapOptions);
  const { nearbySearch } = usePlacesService(map!);
  const { planDocuments } = useContext(DocumentContext);
  const [nearbyPlaces, setNearbyPlaces] =
    useState<Array<SearchPlaceFragment> | null>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [postalCode, setPostalCode] = useState<string>(
    planDocuments?.personal_information?.self?.address?.zip_code!,
  );
  const [zipCode, setZipCode] = useState<string>(
    planDocuments?.personal_information?.self?.address?.zip_code!,
  );
  const { refetch: getPlaces, loading: placesSearching } = usePlacesQuery({
    fetchPolicy: 'network-only',
    skip: true,
  });

  // creating a debounce effect to avoid multiple google search calls
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPostalCode(zipCode!);
    }, MAP_SEARCH_DEBOUNCE);

    return () => clearTimeout(delayDebounceFn);
  }, [zipCode]);

  // TODO -- Do not remove this commented code, we need it later
  // const getNearByPlace = async (location: google.maps.LatLng) => {
  //   placeService?.nearbySearch({
  //     radius: 2000, // TODO -- this should come from constant
  //     keyword: 'notary',
  //     location,
  //   }, (result: google.maps.places.PlaceResult[] | null,
  //     status: google.maps.places.PlacesServiceStatus) => {
  //     if (status === google.maps.places.PlacesServiceStatus.OK) {
  //       resolve(result);
  //     } else {
  //       reject(status);
  //     }
  //   });
  // };

  const getData = async () => {
    if (!postalCode) {
      setNearbyPlaces([]);
      return;
    }
    setLoading(true);
    try {
      const locations = await geocode(postalCode);
      if (locations?.results?.length) {
        const location = locations?.results?.[0];
        const newLocation = new google.maps.LatLng({
          lat: location?.geometry?.location?.lat(),
          lng: location?.geometry?.location?.lng(),
        });
        const places = (await nearbySearch?.({
          radius: MAP_NOTARY_RADIUS,
          keyword: MAP_NOTARY_SEARCH_KEYWORD,
          location: newLocation,
        })) as google.maps.places.PlaceResult[];
        if (places?.length) {
          const ids = places.map((place) => place.place_id!) ?? [];
          const newPlaces = await getPlaces({ ids: ids });
          setNearbyPlaces(
            newPlaces?.data?.places as Array<SearchPlaceFragment>,
          );
        }
      }
    } catch (err: any) {
      // @ts-ignore
      if (err?.code === google.maps.GeocoderStatus.ZERO_RESULTS) {
        setNearbyPlaces([]);
      } else {
        // @ts-ignore
        openSnackbar(
          {
            message:
              err?.message || 'Unable to fetch notaries for this zip code',
          },
          NotifierType.Error,
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (ref && map && !loading) {
      getData();
    }
  }, [ref, map, postalCode]);

  useEffect(() => {
    setZipCode(planDocuments?.personal_information?.self?.address?.zip_code!);
  }, [planDocuments?.personal_information?.self?.address?.zip_code]);

  return {
    loading: loading || placesSearching,
    places: nearbyPlaces,
    ref,
    map,
    setZipCode,
    defaultZipCode: zipCode,
  };
};

export default useMapLocations;
