import React from 'react';
import Loader from '../../../components/Loader';
import useAccounts from './accounts-hooks';
import AccountBox from './AccountBox';

interface Props {
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const Accounts = ({ selectedForms, uiFlag, setEditState }: Props) => {
  const { loading, accounts } = useAccounts();
  return (
    <>
      <AccountBox 
        accountList={accounts || []} 
        selectedForms={selectedForms} 
        uiFlag={uiFlag}
        setEditState={setEditState}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(Accounts);
