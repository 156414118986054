import React, { FormEventHandler, MouseEventHandler } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import StatesDropdown from '../../../components/StatesDropdown';
import { PersonType } from '../../../generated/graphql';
import useAddressForm from './address-form-hook';
import { DataForm } from './personal-information-form-hooks';
import CountiesDropdown from '../../../components/CountiesDropdown';
import HtmlTooltip from '../../../components/HtmlTooltip';
import TabLayout from '../../../components/TabLayout';
import { SUMMARY } from '../../Summary/constants';

export interface FormProps {
  type?: PersonType;
  info?: PersonType;
  errors: FieldErrors;
  control: Control<DataForm>;
  previousPage?: MouseEventHandler;
  nextPage: Function;
  stateName: string;
  onAutocompleteAddress: Function;
  uiFlag?: string;
  setEditState?: Function;
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

const AddressForm = ({
  type,
  errors,
  control,
  previousPage,
  nextPage,
  stateName,
  onAutocompleteAddress,
  uiFlag,
  setEditState,
  handleSubmit,
}: FormProps) => {
  const theme = useTheme();
  const { googleAutoCompleteRef } = useAddressForm({
    onAutocompleteAddress,
  });

  const submitFormData = (e: any) => {
    e.preventDefault();
    nextPage(['address', 'apt_or_suit', 'city', 'zip_code', 'state']);
  };

  return (
    <form autoComplete="off" onSubmit={uiFlag === SUMMARY ? handleSubmit : submitFormData} noValidate>
      <TabLayout 
        title="Personal Information" 
        previous={previousPage}
        uiFlag={uiFlag}
        setEditState={setEditState}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="m" component="p">
                {type === PersonType.Self
                  ? 'What is your address?'
                  : 'What is your spouse/partner address?'}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      Why Do You Need My Address?
                      <br />
                      Estate planning laws differ from state-to-state so your
                      address is required in order to verify your state
                      residency and ensure your documents are legally-binding
                      and valid.
                    </React.Fragment>
                  }
                />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="address"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Address"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      inputRef={googleAutoCompleteRef}
                    />
                  )}
                  rules={{
                    required: 'Address is required',
                  }}
                  control={control}
                />
                {errors.address && (
                  <FormHelperErrorText>
                    {errors?.address?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="apt_or_suit"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="APT. or Suite"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                />
                {errors.apt_or_suit && (
                  <FormHelperErrorText>
                    {errors?.apt_or_suit?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>

              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="city"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="City"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'City is required',
                  }}
                  control={control}
                />
                {errors.city && (
                  <FormHelperErrorText>
                    {errors?.city?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="zip_code"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Zip Code"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Zip Code is required',
                  }}
                  control={control}
                />
                {errors.zip_code && (
                  <FormHelperErrorText>
                    {errors?.zip_code?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>

              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="state"
                  render={({ field: { onChange, value } }) => (
                    <StatesDropdown value={value} onChange={onChange} />
                  )}
                  rules={{
                    required: 'State is required',
                  }}
                  control={control}
                />
                {errors.state && (
                  <FormHelperErrorText>
                    {errors?.state?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="county"
                  render={({ field: { onChange, value } }) => (
                    <CountiesDropdown
                      state={stateName}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                  rules={{
                    required: 'County is required',
                  }}
                  control={control}
                />
                {errors.county && (
                  <FormHelperErrorText>
                    {errors?.county?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </TabLayout>
    </form>
  );
};

export default React.memo(AddressForm);
