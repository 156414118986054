import { Theme } from '@mui/system';

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize' as const,
          borderRadius: 6,
          fontSize: 17,
          fontWeight: 700,
          padding: theme.spacing(1, 1.5),
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        disabled: {
          color: theme.palette.primary.light,
        },
      },
    },
  };
};

export default Button;
