import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';

const useRealEstate = () => {
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const [hasRealEstate, setHasRealEstate] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const setShowRealEstateForm = (value: boolean) => {
    setShowForm(value);
    setHasRealEstate(value);
  };

  useEffect(() => {
    if (planDocuments?.user_info?.has_real_estate) {
      setHasRealEstate(true);
    }
    if ((planDocuments?.assets?.real_estate_properties?.length || 0) > 0) {
      setShowForm(true);
    }
  }, [planDocuments, setHasRealEstate]);

  return {
    loading: loadingPlanDocuments,
    realEstateProperties: planDocuments?.assets?.real_estate_properties,
    hasRealEstate,
    setHasRealEstate,
    showForm,
    setShowRealEstateForm,
  };
};

export default useRealEstate;
