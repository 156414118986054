import Amplify from 'aws-amplify';

const initAWSAmplify = () => {
  const {
    REACT_APP_REGION,
    REACT_APP_USER_POOL_ID,
    REACT_APP_USER_WEB_POOL_ID,
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_URL,
  } = process.env;

  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: REACT_APP_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: REACT_APP_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: REACT_APP_USER_WEB_POOL_ID,

      oauth: {
        domain: REACT_APP_COGNITO_DOMAIN,
        redirectSignIn: REACT_APP_URL,
        redirectSignOut: `${REACT_APP_URL}/login`,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        responseType: 'code',
      },
    },
  });
};

export default initAWSAmplify;
