import { useMemo } from 'react';

interface HooksResponse {
  nearbySearch: (request: google.maps.places.PlaceSearchRequest) => Promise<unknown>;
  placeService: google.maps.places.PlacesService | null;
}

const usePlacesService = (map: google.maps.Map): HooksResponse => {
  // Creates a Places Service instance
  const placeService = useMemo<google.maps.places.PlacesService | null>(() => {
    // Wait for map to be initialized
    if (!map) {
      return null;
    }

    if (!google.maps.places) {
      throw Error(
        "Places library missing. Add 'places' to the libraries array of GoogleMapProvider.",
      );
    }

    return new google.maps.places.PlacesService(map);
  }, [map]);

  const nearbySearch = (request: google.maps.places.PlaceSearchRequest) => {
    return new Promise((resolve, reject) => {
      placeService?.nearbySearch(request, (result: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          reject(status);
        }
      });
    });
  };

  return {
    nearbySearch,
    placeService,
  };
};

export default usePlacesService;
