import React, { useEffect, useState } from 'react';
import {
  Box,
  Breakpoint,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Loader from '../../components/Loader';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import useStripeCheckout from './stripe-payment-hooks';
import Layout from '../../components/Layout';
import MultiStepForm from '../PersonalInformation/MultiStepForm';
import Children from '../Children';
import GuardianshipForm from '../Guardianship/GuardianshipForm';
import PetsMain from '../Pets/PetsMain';
import PersonalRepresentativeSelf from '../PersonalRepresentative/PersonalRepresentativeSelf';
import PersonalRepresentativeSpouse from '../PersonalRepresentative/PersonalRepresentativeSpouse';
import TrusteesForm from '../Trustees/TrusteesForm';
import BeneficiariesMain from '../Beneficiaries/BeneficiariesMain';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  ChildOfType,
  PaymentStatus,
  PersonType,
} from '../../generated/graphql';
import GeneralPowerOfAttorney from '../PowerOfAttorney/GeneralPowerOfAttorney';
import GeneralPowerOfAttorneySpouse from '../PowerOfAttorney/GeneralPowerOfAttorneySpouse';
import MedicalPowerOfAttorney from '../PowerOfAttorney/MedicalPowerOfAttorney';
import MedicalPowerOfAttorneySpouse from '../PowerOfAttorney/MedicalPowerOfAttorneySpouse';
import useAssets from '../Assets/assets-hooks';
import RealEstates from '../Assets/RealEstateProperties';
import Accounts from '../Assets/Accounts';
import BusinessInterests from '../Assets/BusinessInterests';
import PersonalProperties from '../Assets/PersonalProperties';
import { EDIT_CASES, SUMMARY } from './constants';

const Summary = () => {
  const { loading, planDocuments, onContinueClick, paymentDetails } =
    useStripeCheckout();
  const { selectedForms } = useAssets();
  const theme = useTheme();

  const [editState, setEditState] = useState('');
  const [guardiansFlag, setGuardiansFlag] = useState(false);

  useEffect(() => {
    let arr = planDocuments?.children;
    let found: any = arr?.find(
      (obj) => obj.primary_guardian_name || obj.secondary_guardian_name,
    );
    if (found) {
      if (found.age >= 0 && found.age <= 18) setGuardiansFlag(true);
      else setGuardiansFlag(false);
    } else setGuardiansFlag(false);
  }, [planDocuments]);

  const subLineEdit = (subLine: string, editCase: string) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography component="div" variant="sTextInfoLight">
          {subLine}
        </Typography>
        {editState !== editCase ? (
          <Stack
            direction="row"
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() => setEditState(editCase)}
          >
            <EditIcon
              sx={{
                width: '16px',
              }}
            />{' '}
            <Typography
              sx={{
                ml: 1,
                fontSize: '14px',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            >
              Edit
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() => setEditState('')}
          >
            <CancelIcon
              sx={{
                width: '16px',
              }}
            />{' '}
            <Typography
              sx={{
                ml: 1,
                fontSize: '14px',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            >
              Cancel
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  };

  const headLineEdit = (headLine: string, editCase: string) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography
          variant="mLonger"
          component="p"
          sx={{
            py: 2,
          }}
        >
          {headLine}
        </Typography>
        {editState !== editCase ? (
          <Stack
            direction="row"
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() => setEditState(editCase)}
          >
            <EditIcon
              sx={{
                width: '16px',
              }}
            />{' '}
            <Typography
              sx={{
                ml: 1,
                fontSize: '14px',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            >
              Edit
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() => setEditState('')}
          >
            <CancelIcon
              sx={{
                width: '16px',
              }}
            />{' '}
            <Typography
              sx={{
                ml: 1,
                fontSize: '14px',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            >
              Cancel
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  };

  const summaryContent = (
    title: string,
    subtitle: string,
    editCase: string,
  ) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          py: 1.5,
        }}
      >
        <Typography
          variant="s"
          component="p"
          sx={{
            width: '20%',
            overflowWrap: 'anywhere',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="sideBar"
          component="p"
          sx={{
            width: '65%',
            overflowWrap: 'anywhere',
          }}
        >
          {subtitle}
        </Typography>
        <Stack
          direction="row"
          sx={{
            color: theme.palette.primary.main,
            cursor: 'pointer',
          }}
          onClick={() => setEditState(editCase)}
        >
          <EditIcon
            sx={{
              width: '16px',
            }}
          />{' '}
          <Typography
            sx={{
              ml: 1,
              fontSize: '14px',
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                display: 'none',
              },
            }}
          >
            Edit
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const childContent = (
    name: string,
    id: string,
    dob: string,
    childOf: string,
  ) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          py: 2,
        }}
      >
        <Typography
          variant={name === 'label' ? 's' : 'sideBar'}
          component="p"
          sx={{
            width: '30%',
            overflowWrap: 'anywhere',
          }}
        >
          {name === 'label' ? 'Name' : name}
        </Typography>
        <Typography
          variant={name === 'label' ? 's' : 'sideBar'}
          component="p"
          sx={{
            width: '30%',
            overflowWrap: 'anywhere',
          }}
        >
          {name === 'label'
            ? 'Date of Birth'
            : format(new Date(dob) as Date, 'MM/dd/yyyy')}
        </Typography>
        <Typography
          variant={name === 'label' ? 's' : 'sideBar'}
          component="p"
          sx={{
            width: '30%',
            overflowWrap: 'anywhere',
          }}
        >
          {name === 'label'
            ? 'Child Of'
            : childOf === ChildOfType.SpouseAndMe
              ? 'Spouse & Me'
              : childOf === ChildOfType.MeAndSomeoneElse
                ? 'Me and someone else'
                : childOf === ChildOfType.SpouseAndSomeoneElse
                  ? 'Spouse and someone else'
                  : ''}
        </Typography>
      </Stack>
    );
  };

  const guardianContent = (
    id: string,
    name: string,
    primaryg: string,
    secondaryg: string,
  ) => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            py: 2,
          }}
        >
          <Typography
            variant={name === 'label' ? 's' : 'sideBar'}
            component="p"
            sx={{
              width: '30%',
              overflowWrap: 'anywhere',
            }}
          >
            {name === 'label' ? 'Name' : name}
          </Typography>
          <Typography
            variant={name === 'label' ? 's' : 'sideBar'}
            component="p"
            sx={{
              width: '30%',
              overflowWrap: 'anywhere',
            }}
          >
            {name === 'label' ? 'Primary' : primaryg}
          </Typography>
          <Typography
            variant={name === 'label' ? 's' : 'sideBar'}
            component="p"
            sx={{
              width: '30%',
              overflowWrap: 'anywhere',
            }}
          >
            {name === 'label' ? 'Secondary' : secondaryg}
          </Typography>
        </Stack>
        {name !== 'label' && <Divider />}
      </>
    );
  };

  const listContent = (
    name: string,
    field1: string,
    field2: string = '',
    field3: string = '',
    type: string = '',
    editCase: string = '',
  ) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          py: 2,
        }}
      >
        <Typography
          variant={type === 'label' || (!field2 && !field3) ? 's' : 'sideBar'}
          component="p"
          sx={{
            width: '25%',
            overflowWrap: 'anywhere',
          }}
        >
          {name}
        </Typography>
        <Typography
          variant={type === 'label' ? 's' : 'sideBar'}
          component="p"
          sx={{
            width: !field2 && !field3 ? '60%' : '25%',
            overflowWrap: 'anywhere',
          }}
        >
          {field1}
        </Typography>
        {field2 && (
          <Typography
            variant={type === 'label' ? 's' : 'sideBar'}
            component="p"
            sx={{
              width: '25%',
              overflowWrap: 'anywhere',
            }}
          >
            {field2}
          </Typography>
        )}
        {field3 && (
          <Typography
            variant={type === 'label' ? 's' : 'sideBar'}
            component="p"
            sx={{
              width: '25%',
              overflowWrap: 'anywhere',
            }}
          >
            {field3}
          </Typography>
        )}
        {editCase === 'edit' ? (
          <Stack
            sx={{
              width: '47px',
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                width: '20px',
              },
            }}
          ></Stack>
        ) : editCase !== '' ? (
          <Stack
            direction="row"
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() => setEditState(editCase)}
          >
            <EditIcon
              sx={{
                width: '16px',
              }}
            />{' '}
            <Typography
              sx={{
                ml: 1,
                fontSize: '14px',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            >
              Edit
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    );
  };

  const personalInformation = () => {
    return (
      <>
        <Typography
          component="div"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Personal Information
        </Typography>
        <Typography component="div" variant="sTextInfoLight">
          Information about you & your spouse/partner.
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            px: 5,
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              px: 2,
              py: 2,
            },
          }}
        >
          <Typography
            variant="mLonger"
            component="p"
            sx={{
              py: 2,
            }}
          >
            Basic Info
          </Typography>
          {editState === EDIT_CASES.PERSONAL_INFORMATION.PERSONAL_INFO_FORM ? (
            <Box
              sx={{
                py: 2.5,
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  py: 2,
                },
              }}
            >
              <MultiStepForm
                uiFlag={SUMMARY}
                setEditState={setEditState}
                page={1}
              />
            </Box>
          ) : (
            summaryContent(
              'Full Legal Name',
              `${planDocuments?.personal_information?.self?.first_name || ''} ${
                planDocuments?.personal_information?.self?.middle_name || ''
              } ${planDocuments?.personal_information?.self?.last_name || ''}`,
              EDIT_CASES.PERSONAL_INFORMATION.PERSONAL_INFO_FORM,
            )
          )}
          <Divider />
          {
            editState === EDIT_CASES.PERSONAL_INFORMATION.ABOUT_YOU_FORM ? (
              <Box
                sx={{
                  py: 2.5,
                  [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                    py: 2,
                  },
                }}
              >
                <MultiStepForm
                  uiFlag={SUMMARY}
                  setEditState={setEditState}
                  page={2}
                />
              </Box>
            ) : (
              /*eslint-disable */
              summaryContent(
                'Date Of Birth',
                `${
                  planDocuments?.personal_information?.self?.date_of_birth
                    ? format(
                        new Date(
                          planDocuments?.personal_information?.self?.date_of_birth,
                        ) as Date,
                        'MM/dd/yyyy',
                      )
                    : ''
                }`,
                EDIT_CASES.PERSONAL_INFORMATION.ABOUT_YOU_FORM,
              )
            )
            /*eslint-enable */
          }
          <Divider />
          <Typography
            variant="mLonger"
            component="p"
            sx={{
              py: 2,
            }}
          >
            Contact Info
          </Typography>
          {editState === EDIT_CASES.PERSONAL_INFORMATION.ADDRESS_FORM ? (
            <Box
              sx={{
                py: 2.5,
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  py: 2,
                },
              }}
            >
              <MultiStepForm
                uiFlag={SUMMARY}
                setEditState={setEditState}
                page={3}
              />
            </Box>
          ) : (
            summaryContent(
              'Address',
              `${
                planDocuments?.personal_information?.self?.address?.address ||
                ''
              }
              ${
                planDocuments?.personal_information?.self?.address
                  ?.apt_or_suit || ''
              }
              ${planDocuments?.personal_information?.self?.address?.city || ''},
              ${
                planDocuments?.personal_information?.self?.address?.county || ''
              },
              ${planDocuments?.personal_information?.self?.address?.state || ''}
              ${
                planDocuments?.personal_information?.self?.address?.zip_code ||
                ''
              }`,
              EDIT_CASES.PERSONAL_INFORMATION.ADDRESS_FORM,
            )
          )}
          <Divider />
          {editState === EDIT_CASES.PERSONAL_INFORMATION.CONTACT_INFO_FORM ? (
            <Box
              sx={{
                py: 2.5,
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  py: 2,
                },
              }}
            >
              <MultiStepForm
                uiFlag={SUMMARY}
                setEditState={setEditState}
                page={4}
              />
            </Box>
          ) : (
            <>
              {summaryContent(
                'Email',
                `${planDocuments?.personal_information?.self?.email || ''}`,
                EDIT_CASES.PERSONAL_INFORMATION.CONTACT_INFO_FORM,
              )}
              <Divider />
              {summaryContent(
                'Phone Number',
                `${planDocuments?.personal_information?.self?.phone || ''}`,
                EDIT_CASES.PERSONAL_INFORMATION.CONTACT_INFO_FORM,
              )}
              <Divider />
            </>
          )}
          {planDocuments?.user_info?.is_married && (
            <>
              <Typography
                variant="mLonger"
                component="p"
                sx={{
                  py: 2,
                }}
              >
                Spouse/Partner Basic Info
              </Typography>
              {editState ===
              EDIT_CASES.PERSONAL_INFORMATION.SPOUSE_INFO_FORM ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <MultiStepForm
                    uiFlag={SUMMARY}
                    setEditState={setEditState}
                    page={7}
                    personType={PersonType.Spouse}
                  />
                </Box>
                ) : (
                  summaryContent(
                    'Full Legal Name',
                    `${
                      planDocuments?.personal_information?.spouse?.first_name ||
                    ''
                    }
                ${
                  planDocuments?.personal_information?.spouse?.last_name || ''
                }`,
                    EDIT_CASES.PERSONAL_INFORMATION.SPOUSE_INFO_FORM,
                  )
                )}
              <Divider />
              {
                editState ===
                EDIT_CASES.PERSONAL_INFORMATION.SPOUSE_ABOUT_YOU_FORM ? (
                  <Box
                    sx={{
                      py: 2.5,
                      [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                        py: 2,
                      },
                    }}
                  >
                    <MultiStepForm
                      uiFlag={SUMMARY}
                      setEditState={setEditState}
                      page={8}
                      personType={PersonType.Spouse}
                    />
                  </Box>
                  ) : (
                  /*eslint-disable */
                  summaryContent(
                    'Date Of Birth',
                    `${
                      planDocuments?.personal_information?.spouse?.date_of_birth
                        ? format(
                            new Date(
                              planDocuments?.personal_information?.spouse?.date_of_birth,
                            ) as Date,
                            'MM/dd/yyyy',
                          )
                        : ''
                    }`,
                    EDIT_CASES.PERSONAL_INFORMATION.SPOUSE_ABOUT_YOU_FORM,
                  )
                )
                /*eslint-enable */
              }
              <Divider />
              <Typography
                variant="mLonger"
                component="p"
                sx={{
                  py: 2,
                }}
              >
                Spouse/Partner Contact Info
              </Typography>
              {editState ===
              EDIT_CASES.PERSONAL_INFORMATION.SPOUSE_CONTACT_INFO_FORM ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <MultiStepForm
                    uiFlag={SUMMARY}
                    setEditState={setEditState}
                    page={9}
                    personType={PersonType.Spouse}
                  />
                </Box>
                ) : (
                  <>
                    {summaryContent(
                      'Email',
                      `${
                        planDocuments?.personal_information?.spouse?.email || ''
                      }`,
                      EDIT_CASES.PERSONAL_INFORMATION.SPOUSE_CONTACT_INFO_FORM,
                    )}
                  <Divider />
                  {summaryContent(
                    'Phone Number',
                    `${
                      planDocuments?.personal_information?.spouse?.phone || ''
                    }`,
                    EDIT_CASES.PERSONAL_INFORMATION.SPOUSE_CONTACT_INFO_FORM,
                  )}
                  <Divider />
                  </>
                )}
            </>
          )}
        </Box>
      </>
    );
  };

  const children = () => {
    return (
      <>
        <Typography
          component="div"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Children
        </Typography>
        {subLineEdit('Information about your children.', EDIT_CASES.CHILDREN)}
        {editState === EDIT_CASES.CHILDREN ? (
          <Box
            sx={{
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                py: 2,
              },
            }}
          >
            <Children uiFlag={SUMMARY} setEditState={setEditState} />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              px: 5,
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                px: 2,
                py: 2,
              },
            }}
          >
            {childContent('label', 'label', 'label', 'label')}
            {planDocuments?.children?.map((child) => {
              return (
                <>
                  {childContent(
                    `${child.full_name || ''}`,
                    child.id,
                    child.date_of_birth,
                    child.child_of || '',
                  )}
                  <Divider />
                </>
              );
            })}
          </Box>
        )}
      </>
    );
  };

  const guardianship = () => {
    return (
      <>
        <Typography
          component="p"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Guardianship
        </Typography>
        {subLineEdit(
          'Information about the Guardians for your children.',
          EDIT_CASES.GUARDIANSHIP,
        )}
        {editState === EDIT_CASES.GUARDIANSHIP ? (
          <Box
            sx={{
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                py: 2,
              },
            }}
          >
            <GuardianshipForm uiFlag={SUMMARY} setEditState={setEditState} />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              px: 5,
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                px: 2,
                py: 2,
              },
            }}
          >
            {guardianContent('label', 'label', 'label', 'label')}
            {planDocuments?.children?.reduce((prev, child) => {
              if (child.age != null && child.age >= 0 && child.age <= 18) {
                prev.push(
                  guardianContent(
                    '',
                    `${child.full_name || ''}`,
                    `${child?.primary_guardian_name}`,
                    `${child?.secondary_guardian_name}`,
                  ),
                );
              }
              return prev;
            }, [] as any)}
          </Box>
        )}
      </>
    );
  };

  const pets = () => {
    return (
      <>
        <Typography
          component="p"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Pets
        </Typography>
        {subLineEdit(
          'Information about your Pets and their Guardians.',
          EDIT_CASES.PETS,
        )}
        {editState === EDIT_CASES.PETS ? (
          <Box
            sx={{
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                py: 2,
              },
            }}
          >
            <PetsMain uiFlag={SUMMARY} setEditState={setEditState} />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              px: 5,
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                px: 2,
                py: 2,
              },
            }}
          >
            {guardianContent('label', 'label', 'label', 'label')}
            {planDocuments?.pets?.map((pet) => {
              return guardianContent(
                pet.id,
                `${pet.name || ''}(${pet.type || ''})`,
                pet.primary_guardian_name || '',
                pet.secondary_guardian_name || '',
              );
            })}
          </Box>
        )}
      </>
    );
  };

  const personalRepresentative = () => {
    return (
      <>
        <Typography
          component="p"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Personal Representative
        </Typography>
        <Typography component="div" variant="sTextInfoLight">
          Information about your Representatives.
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            px: 5,
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              px: 2,
              py: 2,
            },
          }}
        >
          {listContent(
            'Name',
            'First Choice',
            'Second Choice',
            '',
            'label',
            'edit',
          )}
          {editState ===
          EDIT_CASES.PERSONAL_REPRESENTATIVE.PERSONAL_REPRESENTATIVE_SELF ? (
            <Box
              sx={{
                py: 2.5,
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  py: 2,
                },
              }}
            >
              <PersonalRepresentativeSelf
                uiFlag={SUMMARY}
                setEditState={setEditState}
              />
            </Box>
            ) : (
              listContent(
                'Self',
                `${planDocuments?.personal_representatives?.self?.first_choice_owner_name}`,
                `${planDocuments?.personal_representatives?.self?.second_choice_owner_name}`,
                '',
                '',
                EDIT_CASES.PERSONAL_REPRESENTATIVE.PERSONAL_REPRESENTATIVE_SELF,
              )
            )}
          <Divider />
          {planDocuments?.user_info?.is_married && (
            <>
              {editState ===
              EDIT_CASES.PERSONAL_REPRESENTATIVE
                .PERSONAL_REPRESENTATIVE_SPOUSE ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <PersonalRepresentativeSpouse
                    uiFlag={SUMMARY}
                    setEditState={setEditState}
                  />
                </Box>
                ) : (
                  listContent(
                    'Spouse/Partner',
                    `${planDocuments?.personal_representatives?.spouse?.first_choice_owner_name}`,
                    `${planDocuments?.personal_representatives?.spouse?.second_choice_owner_name}`,
                    '',
                    '',
                    EDIT_CASES.PERSONAL_REPRESENTATIVE
                      .PERSONAL_REPRESENTATIVE_SPOUSE,
                  )
                )}
              <Divider />
            </>
          )}
        </Box>
      </>
    );
  };

  const trustees = () => {
    return (
      <>
        <Typography
          component="p"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Trustees
        </Typography>
        {subLineEdit('Information about your Trustees.', EDIT_CASES.TRUSTEES)}
        {editState === EDIT_CASES.TRUSTEES ? (
          <Box
            sx={{
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                py: 2,
              },
            }}
          >
            <TrusteesForm
              info={planDocuments?.trustees}
              uiFlag={SUMMARY}
              setEditState={setEditState}
            />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              px: 5,
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                px: 2,
                py: 2,
              },
            }}
          >
            {listContent(
              'Trustee',
              'Primary Choice',
              'Secondary Choice',
              '',
              'label',
            )}
            {listContent(
              'Trustee',
              `${planDocuments?.trustees?.primary_trustee_name}`,
              `${planDocuments?.trustees?.secondary_trustee_name}`,
              '',
              '',
            )}
            <Divider />
          </Box>
        )}
      </>
    );
  };

  const assets = () => {
    return (
      <>
        <Typography
          component="p"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Assets
        </Typography>
        <Typography component="div" variant="sTextInfoLight">
          Information about your Assets.
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            px: 5,
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              px: 2,
              py: 2,
            },
          }}
        >

              {headLineEdit(
                'Real Estate Properties',
                EDIT_CASES.ASSETS.REAL_ESTATE_PROPERTIES,
              )}
              {editState === EDIT_CASES.ASSETS.REAL_ESTATE_PROPERTIES ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <RealEstates
                    selectedForms={selectedForms}
                    uiFlag={SUMMARY}
                    setEditState={planDocuments?.assets?.real_estate_properties?.length !== 0 ? setEditState : undefined}
                  />
                </Box>
              ) : (
                planDocuments?.assets?.real_estate_properties?.map(
                  (property) => {
                    return (
                      <>
                        {listContent(
                          'Real Estate Property',
                          `Address: ${property.address} / type: ${property.type}`,
                        )}
                        <Divider />
                      </>
                    );
                  },
                )
              )}

              {headLineEdit(
                'Business Interests',
                EDIT_CASES.ASSETS.BUSINESS_INTERESTS,
              )}
              {editState === EDIT_CASES.ASSETS.BUSINESS_INTERESTS ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <BusinessInterests
                    selectedForms={selectedForms}
                    uiFlag={SUMMARY}
                    setEditState={planDocuments?.assets?.business_interests?.length !== 0 ? setEditState : undefined}
                  />
                </Box>
              ) : (
                planDocuments?.assets?.business_interests?.map((business) => {
                  return (
                    <>
                      {listContent(
                        'Business Interest',
                        `Name: ${business.name} / type: ${business.type}`,
                      )}
                      <Divider />
                    </>
                  );
                })
              )}

              {headLineEdit('Accounts', EDIT_CASES.ASSETS.ACCOUNTS)}
              {editState === EDIT_CASES.ASSETS.ACCOUNTS ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <Accounts
                    selectedForms={selectedForms}
                    uiFlag={SUMMARY}
                    setEditState={planDocuments?.assets?.financial_account_designations?.length !== 0 ? setEditState : undefined}
                  />
                </Box>
              ) : (
                planDocuments?.assets?.financial_account_designations?.map(
                  (account) => {
                    return (
                      <>
                        {listContent(
                          'Account',
                          `Provider: ${account.provider} / type: ${account.account_type}`,
                        )}
                        <Divider />
                      </>
                    );
                  },
                )
              )}


              {headLineEdit(
                'Personal Properties',
                EDIT_CASES.ASSETS.PERSONAL_PROPERTIES,
              )}
              {editState === EDIT_CASES.ASSETS.PERSONAL_PROPERTIES ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <PersonalProperties
                    selectedForms={selectedForms}
                    uiFlag={SUMMARY}
                    setEditState={planDocuments?.assets?.personal_properties?.length !== 0 ? setEditState : undefined}
                  />
                </Box>
              ) : (
                planDocuments?.assets?.personal_properties?.map((property) => {
                  return (
                    <>
                      {listContent(
                        'Personal Property',
                        property?.description || '',
                      )}
                      <Divider />
                    </>
                  );
                })
              )}
        </Box>
      </>
    );
  };

  const beneficiaries = () => {
    return (
      <>
        <Typography
          component="p"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Beneficiaries
        </Typography>
        {subLineEdit(
          'Information about your Beneficiaries.',
          EDIT_CASES.BENEFICIARIES,
        )}
        {editState === EDIT_CASES.BENEFICIARIES ? (
          <Box
            sx={{
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                py: 2,
              },
            }}
          >
            <BeneficiariesMain uiFlag={SUMMARY} setEditState={setEditState} />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              px: 5,
              py: 2.5,
              [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                px: 2,
                py: 2,
              },
            }}
          >
            {listContent(
              'Name',
              'Inheritance Age',
              'Inheritance Percentage',
              '',
              'label',
            )}
            {planDocuments?.beneficiaries?.reduce((prev, beneficiary) => {
              if (
                beneficiary.inheritance_age &&
                beneficiary.inheritance_percentage
              ) {
                prev.push(
                  <>
                    {listContent(
                      `${beneficiary?.full_name}`,
                      `${beneficiary?.inheritance_age}`,
                      `${Number(
                        Math.round(
                          (Number(beneficiary?.inheritance_percentage) +
                            Number.EPSILON) *
                            100,
                        ) / 100,
                      ).toString()}`,
                      '',
                      '',
                    )}
                    <Divider />
                  </>,
                );
              }
              return prev;
            }, [] as any)}
          </Box>
        )}
      </>
    );
  };

  const powerOfAttorney = () => {
    return (
      <>
        <Typography
          component="p"
          variant="m"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Power of Attorney
        </Typography>
        <Typography component="div" variant="sTextInfoLight">
          Information about your Power of Attorneys.
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            px: 5,
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              px: 2,
              py: 2,
            },
          }}
        >
          {listContent(
            'Type',
            'Primary Choice',
            'Second Choice',
            '',
            'label',
            'edit',
          )}
          {editState === EDIT_CASES.POWER_OF_ATTORNEY.GENERAL_POA ? (
            <Box
              sx={{
                py: 2.5,
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  py: 2,
                },
              }}
            >
              <GeneralPowerOfAttorney
                uiFlag={SUMMARY}
                setEditState={setEditState}
              />
            </Box>
          ) : (
            listContent(
              'General',
              `${planDocuments?.power_of_attorneys?.general?.first_choice_owner_name}`,
              `${planDocuments?.power_of_attorneys?.general?.second_choice_owner_name}`,
              '',
              '',
              EDIT_CASES.POWER_OF_ATTORNEY.GENERAL_POA,
            )
          )}
          <Divider />
          {editState === EDIT_CASES.POWER_OF_ATTORNEY.MEDICAL_POA ? (
            <Box
              sx={{
                py: 2.5,
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  py: 2,
                },
              }}
            >
              <MedicalPowerOfAttorney
                uiFlag={SUMMARY}
                setEditState={setEditState}
              />
            </Box>
          ) : (
            listContent(
              'Medical',
              `${planDocuments?.power_of_attorneys?.medical?.first_choice_owner_name}`,
              `${planDocuments?.power_of_attorneys?.medical?.second_choice_owner_name}`,
              '',
              '',
              EDIT_CASES.POWER_OF_ATTORNEY.MEDICAL_POA,
            )
          )}
          <Divider />
          {planDocuments?.user_info?.is_married && (
            <>
              {editState === EDIT_CASES.POWER_OF_ATTORNEY.GENERAL_POA_SPOUSE ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <GeneralPowerOfAttorneySpouse
                    uiFlag={SUMMARY}
                    setEditState={setEditState}
                  />
                </Box>
              ) : (
                listContent(
                  'General for spouse',
                  `${planDocuments?.power_of_attorneys?.general_for_spouse?.first_choice_owner_name}`,
                  `${planDocuments?.power_of_attorneys?.general_for_spouse?.second_choice_owner_name}`,
                  '',
                  '',
                  EDIT_CASES.POWER_OF_ATTORNEY.GENERAL_POA_SPOUSE,
                )
              )}
              <Divider />
            </>
          )}
          {planDocuments?.user_info?.is_married && (
            <>
              {editState === EDIT_CASES.POWER_OF_ATTORNEY.MEDICAL_POA_SPOUSE ? (
                <Box
                  sx={{
                    py: 2.5,
                    [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                      py: 2,
                    },
                  }}
                >
                  <MedicalPowerOfAttorneySpouse
                    uiFlag={SUMMARY}
                    setEditState={setEditState}
                  />
                </Box>
              ) : (
                listContent(
                  'Medical for spouse',
                  `${planDocuments?.power_of_attorneys?.medical_for_spouse?.first_choice_owner_name}`,
                  `${planDocuments?.power_of_attorneys?.medical_for_spouse?.second_choice_owner_name}`,
                  '',
                  '',
                  EDIT_CASES.POWER_OF_ATTORNEY.MEDICAL_POA_SPOUSE,
                )
              )}
              <Divider />
            </>
          )}
        </Box>
      </>
    );
  };

  return (
    <Layout isDocumentPage={false}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            width: '100vw',
          },
          [theme.breakpoints.up(BREAKPOINTS.SM as Breakpoint)]: {
            width: '90%',
            padding: '0% 5%',
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Typography
            variant="lDisplay"
            component="p"
            sx={{
              mb: 3,
            }}
          >
            Summary
          </Typography>
          {personalInformation()}
          {planDocuments?.children?.length !== 0 && children()}
          {guardiansFlag && guardianship()}
          {planDocuments?.pets?.length !== 0 && pets()}
          {personalRepresentative()}
          {planDocuments?.trustees && trustees()}
          {assets()}
          {planDocuments?.beneficiaries?.length !== 0 && beneficiaries()}
          {powerOfAttorney()}
          <Button variant="contained" color="primary" onClick={onContinueClick}>
            {paymentDetails?.paymentDetails?.status === PaymentStatus.Success || planDocuments?.manual_payment
              ? 'Continue'
              : 'Checkout'}
          </Button>
        </Stack>
        <Loader open={loading} />
      </Box>
    </Layout>
  );
};

export default React.memo(Summary);
