import React, { MouseEventHandler, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { apiUrls } from '../../variables/urls';
import HtmlTooltip from '../../components/HtmlTooltip';
import TabLayout from '../../components/TabLayout';
import StyledBannerButton from '../../components/StyledBannerButton';

interface Props {
  hasPartner: boolean;
  setHasPartner: Function;
  previousPage?: MouseEventHandler;
  UserInfoSetOption: Function;
}

const HasPartnerConfirmation = ({
  hasPartner,
  setHasPartner,
  previousPage,
  UserInfoSetOption,
}: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const [buttonState, setButtonState] = useState(hasPartner);

  const buttonStateSetter = (value: boolean) => {
    setButtonState(value);
    UserInfoSetOption(value);
  };

  return (
    <TabLayout
      title="Personal Information"
      previous={previousPage}
      next={
        buttonState
          ? () => setHasPartner(true)
          : () => history.push(apiUrls.PERSONAL_INFORMATION_COMPLETION)
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          py: 8,
          border: '0.063rem solid',
          borderColor: 'common.lightGreen',
          borderRadius: '0.5rem',
        }}
      >
        <Grid item xs={11}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{ mb: 1 }}
          >
            <Typography variant="m" component={'div'}>
              Do you have a spouse/partner?
              <HtmlTooltip
                title={
                  <React.Fragment>
                    Why Do You Need to Know If I Have a Spouse or Legal Partner?
                    <br />
                    Your relationship status is used in tailoring the documents
                    to your specific details.
                  </React.Fragment>
                }
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={5}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              width: '100%',
            }}
          >
            <StyledBannerButton
              onClick={() => buttonStateSetter(true)}
              value={buttonState}
              buttonText="Yes"
            />
            <StyledBannerButton
              onClick={() => buttonStateSetter(false)}
              value={!buttonState}
              buttonText="No"
            />
          </Stack>
        </Grid>
      </Grid>
    </TabLayout>
  );
};

export default React.memo(HasPartnerConfirmation);
