import { useContext, useEffect, useState } from 'react';
import { useAuthProvider } from '../../core/authContext';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import {
  OnBoardingStatus,
  PaymentStatus,
  usePaymentDetailsQuery,
} from '../../generated/graphql';

interface ProgressInfo {
  time_to_complete: string;
  total: number;
  filled: number;
  completed: boolean;
  disabled: boolean;
}

const useProgressBarStatus = () => {
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const { getUser } = useAuthProvider();
  const user = getUser();
  const userID = user?.id;
  const companyID = user?.user_info?.company_id;
  const [onBoardingStatus, setOnBoardingStatus] = useState(
    user?.user_info.on_boarding_status,
  );
  const { data: paymentDetails, loading: loadingPaymentDetails } =
    usePaymentDetailsQuery({
      fetchPolicy: 'network-only',
      skip: !userID || !!companyID,
    });
  const [userName, setUserName] = useState(user?.email);

  useEffect(() => {
    if (!loadingPlanDocuments) {
      if (planDocuments?.user_info?.on_boarding_status) {
        setOnBoardingStatus(planDocuments.user_info.on_boarding_status);
      }
      if (planDocuments?.personal_information?.self) {
        let firstName = planDocuments?.personal_information?.self?.first_name;
        let lastName = planDocuments?.personal_information?.self?.last_name;
        setUserName(`${firstName} ${lastName}`);
      }
    }
  }, [planDocuments, loadingPlanDocuments]);

  return {
    personalInformationProgress:
      planDocuments?.progress?.personal_information || ({} as ProgressInfo),
    childrenInfoProgress:
      planDocuments?.progress?.children || ({} as ProgressInfo),
    guardianshipProgress:
      planDocuments?.progress?.guardianship || ({} as ProgressInfo),
    petsProgress: planDocuments?.progress?.pets || ({} as ProgressInfo),
    personalRepresentativeProgress:
      planDocuments?.progress?.personal_representatives || ({} as ProgressInfo),
    trusteeProgress: planDocuments?.progress?.trustees || ({} as ProgressInfo),
    financialAssetsProgress:
      planDocuments?.progress?.financial_assets || ({} as ProgressInfo),
    beneficiaryProgress:
      planDocuments?.progress?.beneficiaries || ({} as ProgressInfo),
    powerOfAttorneyProgress:
      planDocuments?.progress?.power_of_attorneys || ({} as ProgressInfo),
    userName,
    userID,
    onBoardingStatus,
    isDocumentComplete: planDocuments?.is_document_complete || false,
    isDocumentEditable: planDocuments?.is_document_editable || false,
    isPaymentComplete:
      (!loadingPaymentDetails &&
        paymentDetails?.paymentDetails?.status === PaymentStatus.Success &&
        onBoardingStatus !== OnBoardingStatus.Payment) ||
      !!companyID,
  };
};

export default useProgressBarStatus;
