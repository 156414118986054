import React, { MouseEventHandler } from 'react';
import { Breakpoint, Link, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BREAKPOINTS } from '../../variables/constant';

interface Props {
  onPreviousClick: MouseEventHandler;
  buttonText?: string;
}

const PreviousButton = ({ onPreviousClick, buttonText }: Props) => {
  const theme = useTheme();
  return (
    <Paper
      component={Stack}
      direction="column"
      justifyContent="center"
      sx={{
        textAlign: 'center',
        backgroundColor: 'background.default',
        height: '3rem',
        [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
          backgroundColor: theme.palette.common.white,
        },
      }}
    >
      <Link onClick={onPreviousClick} sx={{ cursor: 'pointer' }}>
        {buttonText ? (
          <Typography variant="helper">{buttonText}</Typography>
        ) : (
          <Typography
            variant="sCaps"
            sx={{
              color: theme.palette.grey[700],
            }}
          >
            ^PREVIOUS
          </Typography>
        )}
      </Link>
    </Paper>
  );
};

export default React.memo(PreviousButton);
