import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { apiUrls } from '../../variables/urls';
import HtmlTooltip from '../../components/HtmlTooltip';
import TabLayout from '../../components/TabLayout';
import StyledBannerButton from '../../components/StyledBannerButton';
import Loader from '../../components/Loader';

interface Props {
  hasChildren: boolean;
  setHasChildren: Function;
  loading: boolean;
  UserInfoSetOption: Function;
}

const ChildrenBanner = ({ hasChildren, setHasChildren, loading, UserInfoSetOption }: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const [buttonState, setButtonState] = useState(hasChildren);

  const buttonStateSetter = (value: boolean) => {
    setButtonState(value);
    UserInfoSetOption(value);
  };

  return (
    <TabLayout
      title="Children"
      previous={() => history.push(apiUrls.PERSONAL_INFORMATION)}
      next={
        buttonState
          ? () => setHasChildren(true)
          : () => history.push(apiUrls.PETS)
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          py: 8,
          border: '0.063rem solid',
          borderColor: 'common.lightGreen',
          borderRadius: '0.5rem',
        }}
      >
        <Grid item xs={11}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{ mb: 1 }}
          >
            <Typography variant="m" component={'div'}>
              Do you have any children?
              <HtmlTooltip
                title={
                  <React.Fragment>
                    Why Do You Need to Know If I Have Any Children?
                    <br />
                    If you have children your estate plan will establish
                    guardianship for minor children and distribution standards
                    for them as beneficiaries of your estate.
                    <br /> <br />
                    What Does Full Legal Name Mean?
                    <br />
                    Use the full name of your child as listed on an
                    identification document, such as a birth certificate,
                    driver’s license, or passport. Include all parts of their
                    name (first, middle, and last) and any suffix, even if they
                    don't use all parts of their name in daily life.
                  </React.Fragment>
                }
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={5}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              width: '100%',
            }}
          >
            <StyledBannerButton
              onClick={() => buttonStateSetter(true)}
              value={buttonState}
              buttonText="Yes"
            />
            <StyledBannerButton
              onClick={() => buttonStateSetter(false)}
              value={!buttonState}
              buttonText="No"
            />
          </Stack>
        </Grid>
      </Grid>
      <Loader open={loading} />
    </TabLayout>
  );
};

export default React.memo(ChildrenBanner);
