import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClockIcon from '../Icons/ClockIcon';

interface Props {
  title: string;
  timeToComplete: string;
  total: number;
  filled: number;
  completed: boolean;
  selected: boolean;
  onClick: Function;
}

const ProgressBox = (props: Props) => {
  const theme = useTheme();
  const inProgress = !props.completed && (props.filled > 0 || props.selected);

  return (
    <Paper
      onClick={() => props.onClick()}
      sx={{
        flexGrow: 1,
        border: props.selected ? 3 : 1,
        borderRadius: '0.375rem',
        borderColor: props.selected
          ? theme.palette.primary.main
          : theme.palette.common.dustGreen,
        backgroundColor: theme.palette.background.paper,
        cursor: 'pointer',
        height: '5rem',
        width: '100%',
        p: 2,
        position: 'relative',
      }}
    >
      {props.completed && (
        <CheckCircleIcon
          fontSize="small"
          sx={{
            position: 'absolute',
            mt: 1.5,
            mr: 1.5,
            top: 0,
            right: 0,
            color: theme.palette.primary.main,
          }}
        />
      )}
      {inProgress && (
        <Box
          component="span"
          sx={{
            position: 'absolute',
            mt: 1.5,
            mr: 1.5,
            top: 0,
            right: 0,
            height: '8px',
            width: '8px',
            backgroundColor: theme.palette.common.tintGreen,
            borderRadius: '50%',
          }}
        />
      )}
      <Stack spacing={1}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography
            variant="sideLabel"
            component="p"
            noWrap={true}
            sx={{
              color: props.selected
                ? theme.palette.primary.main
                : theme.palette.common.lightBlack,
            }}
          >
            {props.title}
          </Typography>
        </Box>
        {props.filled || (props.completed && props.filled === 0) ? (
          <Typography
            variant="sideLabel"
            component="p"
            noWrap={true}
            sx={{
              color: props.completed
                ? theme.palette.primary.main
                : theme.palette.text.primary,
              fontSize: '0.688rem',
            }}
          >
            {props.completed ? 'COMPLETED' : 'IN PROGRESS'}
          </Typography>
        ) : (
          <Stack direction="row" spacing={0.5}>
            <Box component={ClockIcon} />
            <Box>
              <Typography
                variant="sLabel"
                component="p"
                noWrap={true}
                sx={{
                  fontWeight: 300,
                  color: theme.palette.grey['500'],
                }}
              >
                {props.timeToComplete}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default ProgressBox;
