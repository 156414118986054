import React, { useEffect, useState } from 'react';
import { Breakpoint } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BREAKPOINTS } from '../../../variables/constant';
import { useTheme } from '@mui/material/styles';
import CheckoutPaymentPage from './CheckoutPaymentPage';
import CheckoutMobileView from './CheckoutMobileView';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const Payment = () => {
  const theme = useTheme();
  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint),
  );

  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  useEffect(() => {
    if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
      const retrievePublishableKey = async () => {
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
        );
        setStripePromise(stripe);
      };
      retrievePublishableKey();
    }
  }, [process.env.STRIPE_PUBLISHABLE_KEY]);

  return stripePromise ? (
    <Elements stripe={stripePromise}>
      {desktopView ? <CheckoutPaymentPage /> : <CheckoutMobileView />}
    </Elements>
  ) : null;
};

export default React.memo(Payment);
