import React from 'react';
import { FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { FormProps } from './personal-information-form-hooks';
import { Controller } from 'react-hook-form';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import { useTheme } from '@mui/material/styles';
import TabLayout from '../../../components/TabLayout';
import { PersonType } from '../../../generated/graphql';
import HtmlTooltip from '../../../components/HtmlTooltip';
import { SUMMARY } from '../../Summary/constants';

const PersonalInfoForm = ({
  type,
  errors,
  control,
  previousPage,
  nextPage,
  uiFlag,
  setEditState,
  handleSubmit,
}: FormProps) => {
  const theme = useTheme();

  const submitFormData = (e: any) => {
    e.preventDefault();
    nextPage(['first_name', 'last_name', 'relation_type']);
  };

  return (
    <form autoComplete="off" onSubmit={uiFlag === SUMMARY ? handleSubmit : submitFormData} noValidate>
      <TabLayout 
        title="Personal Information" 
        previous={previousPage}
        uiFlag={uiFlag}
        setEditState={setEditState}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="m" component="div">
                {type === PersonType.Self
                  ? 'What is your legal name?'
                  : 'What is your spouse/partner’s name?'}
                <HtmlTooltip
                  title={
                    type === PersonType.Self ? (
                      <React.Fragment>
                        What Does Full Legal Name Mean?
                        <br />
                        Use your full name listed on an identification document,
                        such as a birth certificate, driver’s license, or
                        passport. Include all parts of your name (first, middle,
                        and last) and any suffix, even if you don't use all
                        parts of your name in daily life.
                        <br /> <br />
                        Why Do You Need My Full Legal Name?
                        <br />
                        In order for your documents to be legally-binding and
                        valid, your full legal name is required.
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        What Does Full Legal Name Mean?
                        <br />
                        Use the full name of your spouse or partner as listed on
                        an identification document, such as a birth certificate,
                        driver’s license, or passport. Include all parts of
                        their name (first, middle, and last) and any suffix,
                        even if they don't use all parts of their name in daily
                        life.
                        <br /> <br />
                        Why Do You Need My Full Legal Name?
                        <br />
                        In order for your documents to be legally-binding and
                        valid, your full legal name is required.
                      </React.Fragment>
                    )
                  }
                />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="first_name"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'First name is required',
                  }}
                  control={control}
                />
                {errors.first_name && (
                  <FormHelperErrorText>
                    {errors?.first_name?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="middle_name"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Middle Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                />
              </FormControl>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="last_name"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Last name is required',
                  }}
                  control={control}
                />
                {errors.last_name && (
                  <FormHelperErrorText>
                    {errors?.last_name?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </TabLayout>
    </form>
  );
};

export default React.memo(PersonalInfoForm);
