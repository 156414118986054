import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  PaymentStatus,
  usePaymentDetailsQuery,
  useRecommendationQuestionnaireMutation,
  useUserInfoQuery,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useAuthProvider } from '../../core/authContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import { apiUrls } from '../../variables/urls';

export interface DataForm {
  is_married?: boolean;
  has_children?: boolean;
  has_real_estate?: boolean;
  has_asset_above_threshold?: boolean;
  type_of_user?: string;
}

const useBasicInfo = () => {
  const [stateLoading, setLoadingState] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);

  const setLoading = (loading: boolean) => {
    setLoadingState(loading);
  };

  const handleNext = async (fields: any) => {
    const result = await trigger(fields);
    if (!result) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const history = useHistory();

  const { handleSubmit, setValue, control, trigger } = useForm<DataForm>({
    defaultValues: {
      is_married: undefined,
      has_children: undefined,
      has_real_estate: undefined,
      has_asset_above_threshold: undefined,
      type_of_user: undefined,
    },
  });

  const { getUser } = useAuthProvider();
  const user = getUser();
  const userID = user?.id;
  const companyID = user?.user_info?.company_id;

  const { data: userInfo, loading: userInfoLoading } = useUserInfoQuery({
    fetchPolicy: 'network-only',
    skip: !userID,
  });

  const { data: paymentData, loading: loadingPayment } = usePaymentDetailsQuery(
    {
      fetchPolicy: 'network-only',
      skip: !userID || !!companyID,
    },
  );
  useEffect(() => {
    if (paymentData?.paymentDetails.status === PaymentStatus.Success) {
      history.push(apiUrls.DASHBOARD);
    }
  }, [paymentData]);

  useEffect(() => {
    if (userInfo) {
      setValue('is_married', userInfo.userInfo.is_married || false);
      setValue('has_children', userInfo.userInfo.has_children || false);
      setValue('has_real_estate', userInfo.userInfo.has_real_estate || false);
      setValue(
        'has_asset_above_threshold',
        userInfo.userInfo.has_asset_above_threshold || false,
      );
    }
  }, [userInfo, setValue]);

  const [updateRecommendationQuestionnaire, { loading }] =
    useRecommendationQuestionnaireMutation();
  const [basicInfo, setBasicInfo] = useLocalStorage('basic-info', {});
  useEffect(() => {
    if (basicInfo && Object.keys(basicInfo).length !== 0) {
      setValue('is_married', basicInfo.is_married || false);
      setValue('has_children', basicInfo.has_children || false);
      setValue('has_real_estate', basicInfo.has_real_estate || false);
      setValue(
        'has_asset_above_threshold',
        basicInfo.has_asset_above_threshold || false,
      );
    }
  }, [basicInfo, setValue]);

  const onSeeMyRecommendations = async (data: DataForm) => {
    setLoading(true);
    try {
      if (userID) {
        await updateRecommendationQuestionnaire({
          variables: {
            input: {
              is_married: data.is_married || false,
              has_children: data.has_children || false,
              has_real_estate: data.has_real_estate || false,
              has_asset_above_threshold:
                data.has_asset_above_threshold || false,
              id: userID,
            },
          },
        });
      } else {
        setBasicInfo({ ...data });
      }
      history.push(apiUrls.PLAN);
    } catch (err: any) {
      setLoading(false);
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  return {
    loading: stateLoading || userInfoLoading || loading || loadingPayment,
    handleSubmit,
    onSeeMyRecommendations,
    control,
    Controller,
    handleBack,
    handleNext,
    activeStep,
  };
};

export default useBasicInfo;
