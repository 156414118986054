import React from 'react';
import { Box, Typography } from '@mui/material';
import ButtonRow from './ButtonRow';

const ButtonList = () => (
  <Box>
    <Typography variant="xlDisplay">Buttons</Typography>
    <Box mt={2} width="100%">
      <Box display="grid" gridTemplateColumns="800px">
        <ButtonRow mockupName="Primary" variant="contained" color="primary" />
        <ButtonRow mockupName="Disabled" variant="contained" disabled />
        <ButtonRow mockupName="Secondary" variant="outlined" color="primary" />
        <ButtonRow mockupName="Tertiary" variant="text" color="primary" />
      </Box>
    </Box>
  </Box>
);

export default React.memo(ButtonList);
