import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Box, Breakpoint } from '@mui/material';
import TickIcon from '../../components/Icons/TickIcon';
import { BREAKPOINTS } from '../../variables/constant';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: 'right',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.green1A,
  },
  'td, th': {
    borderRight: 0,
    borderTop: 0,
    borderBottom: 0,
  },
}));

const rows = [
  {
    name: 'Lorem Ipsum',
    attorney: <Box component={TickIcon} />,
    myEasyEstate: <Box component={TickIcon} />,
  },
  {
    name: 'Private settlement',
    attorney: <Box component={TickIcon} />,
    myEasyEstate: <Box component={TickIcon} />,
  },
  {
    name: 'Lorem Ipsum',
    attorney: (
      <Typography variant="mLight" sx={{ fontSize: 14 }}>
        1-2 Days
      </Typography>
    ),
    myEasyEstate: (
      <Typography variant="mLight" sx={{ fontSize: 14 }}>
        30 Mins
      </Typography>
    ),
  },
  {
    name: 'Name guardians for children',
    attorney: <Box component={TickIcon} />,
    myEasyEstate: <Box component={TickIcon} />,
  },
  {
    name: 'Name an executor',
    attorney: <Box component={TickIcon} />,
    myEasyEstate: <Box component={TickIcon} />,
  },
  {
    name: 'Lorem Ipsum',
    attorney: <Box component={TickIcon} />,
  },
  {
    name: 'Lorem Ipsum',
    attorney: <Box component={TickIcon} />,
  },
  {
    name: 'Price',
    attorney: (
      <Typography variant="mLight" sx={{ fontSize: 14 }}>
        $1,200+
      </Typography>
    ),
    myEasyEstate: (
      <Typography variant="mLight" sx={{ fontSize: 14 }}>
        $159 – $499
      </Typography>
    ),
  },
];

const ComparisonTable = () => {
  const theme = useTheme();
  return (
    <TableContainer>
      <Table
        width="556px"
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            width: 365,
          },
        }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right">
              In-Person With Attorney
            </StyledTableCell>
            <StyledTableCell align="right" sx={{ borderLeft: 1 }}>
              <Box
                sx={{
                  width: 167,
                  height: 70,
                  [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                    width: 110,
                    height: 46,
                  },
                }}
              >
                <img src="images/TruWillLogo.png" width="180px" alt="logo" />
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={`${row.name} ${index}`}>
              <StyledTableCell component="th" scope="row">
                <Typography variant="mLight" sx={{ fontSize: 14 }}>
                  {row.name}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">{row.attorney}</StyledTableCell>
              <StyledTableCell align="center" sx={{ borderLeft: 1 }}>
                {row.myEasyEstate || ''}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(ComparisonTable);
