import React from 'react';
import {
  Breakpoint,
  Button,
  Checkbox,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useAddressForm, { AddressProps } from './address-form-hooks';
import { Controller } from 'react-hook-form';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import Loader from '../../../components/Loader';
import { BREAKPOINTS } from '../../../variables/constant';
import { useTheme } from '@mui/material/styles';
import StatesDropdown from '../../../components/StatesDropdown';

const AddressForm = ({ onShipToAddress }: AddressProps) => {
  const theme = useTheme();
  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    loading,
    googleAutoCompleteRef,
  } = useAddressForm({
    onShipToAddress,
  });
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        sx={{
          width: '100%',
          [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
            width: '50%',
          },
        }}
      >
        <FormControl fullWidth>
          <Controller
            name="name"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="NAME"
                variant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: 'Please enter a name',
            }}
            control={control}
          />
          {errors.name && (
            <FormHelperErrorText>{errors?.name?.message}</FormHelperErrorText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="address"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="ADDRESS"
                variant="outlined"
                value={value}
                onChange={onChange}
                inputRef={googleAutoCompleteRef}
              />
            )}
            rules={{
              required: 'Please enter the address',
            }}
            control={control}
          />
          {errors.address && (
            <FormHelperErrorText>
              {errors?.address?.message}
            </FormHelperErrorText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="apt_or_suit"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="APT. or Suite"
                variant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
            control={control}
          />
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="zip_code"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="ZIP CODE"
                variant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: 'Please enter the zip code',
            }}
            control={control}
          />
          {errors.zip_code && (
            <FormHelperErrorText>
              {errors?.zip_code?.message}
            </FormHelperErrorText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="city"
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="CITY"
                variant="outlined"
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: 'Please enter the city',
            }}
            control={control}
          />
          {errors.city && (
            <FormHelperErrorText>{errors?.city?.message}</FormHelperErrorText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="state"
            render={({ field: { onChange, value } }) => (
              <StatesDropdown value={value || ''} onChange={onChange} />
            )}
            rules={{
              required: 'Please select the state',
            }}
            control={control}
          />
          {errors.state && (
            <FormHelperErrorText>{errors?.state?.message}</FormHelperErrorText>
          )}
        </FormControl>
        <FormControl>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Controller
              name="save_address"
              render={({ field: { onChange, value } }) => (
                <Checkbox value={value} onChange={onChange} />
              )}
              control={control}
            />
            <Typography>Save Address</Typography>
          </Stack>
        </FormControl>
        <Button fullWidth variant="contained" color="primary" type="submit">
          Ship to this address
        </Button>
      </Stack>
      <Loader open={loading} />
    </form>
  );
};

export default React.memo(AddressForm);
