import React from 'react';
import { Box, Grid } from '@mui/material';
import { SearchPlaceFragment } from '../../generated/graphql';

interface Props {
  place: SearchPlaceFragment
}

const InfoWindow = ({ place }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Grid item xs={9} spacing={1}>
        <Grid item>
          <p
            style={{
              fontFamily: 'Noto Sans Display',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '1.063rem',
              lineHeight: '120%',
              margin: '0.5rem 0rem 0.5rem 0rem',
            }}
          >
            { place.name }
          </p>
        </Grid>
        <Grid item>
          <p
            style={{
              fontFamily: 'Noto Sans Display',
              fontStyle: 'normal',
              fontWeight: 300,
              fontSize: '1rem',
              lineHeight: '1.375rem',
              margin: '0.5rem 0rem 0.5rem 0rem',
            }}
          >
            { place.vicinity }
          </p>
        </Grid>
        <Grid item>
          <p
            style={{
              fontFamily: 'Noto Sans Display',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '1.063rem',
              lineHeight: '120%',
              textDecorationLine: 'underline',
              color: '#578577',
              margin: '0.5rem 0rem 0.5rem 0rem',
            }}
          >
            { place.formatted_phone_number }
          </p>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <p
          style={{
            fontFamily: 'Noto Sans Display',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: '1.375rem',
            margin: '0.5rem 0rem 0.5rem 0rem',
          }}
        >
          0.5 miles
        </p>
      </Grid>
    </Box>
  );
};

export default React.memo(InfoWindow);
