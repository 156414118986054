import { createContext } from 'react';
import { PlanDocumentFragmentFragment } from '../../generated/graphql';

export interface DocumentContextValue {
  planDocuments: PlanDocumentFragmentFragment | null;
  loadingPlanDocuments: boolean;
  refetchPlanDocuments?: Function;
}

export const DocumentContext = createContext<DocumentContextValue>({
  planDocuments: null,
  loadingPlanDocuments: false,
});
