/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

const TickIcon = (props: any) => {
  const theme = useTheme();
  return (
    <SvgIcon
      width="38"
      height="42"
      viewBox="0 0 38 42"
      fill="none"
      {...props}
    >
      <path
        d="M18.9272 41.1679C15.0714 29.4168 9.28769 26.1527 0.933404 23.5413C6.71714 23.5413 13.7861 23.5413 16.9993
           29.4168C17.639 16.4197 23.566 8.4904 37.5637 0.691986C22.7061 12.4431 21.4978 24.847 18.9272 41.1679Z"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
};

export default TickIcon;
