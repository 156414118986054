import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import TypographyRow from './TypographyRow';

const TypographyList = () => {
  return (
    <Box>
      <Typography
        variant="xlDisplay"
        component="p"
        sx={{
          my: 2,
        }}
      >
        Typography
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        gridTemplateRows="repeat(5, 1fr)"
        gridAutoFlow="column"
      >
        <TypographyRow
          variant="xlDisplay"
          name="X Large Display"
          subText="Playfair Display, Black, 42 pt"
        />
        <TypographyRow
          variant="lDisplay"
          name="Large Display"
          subText="Playfair Display, Black, 32 pt"
        />
        <TypographyRow
          variant="lLight"
          name="Large Light"
          subText="Noto Sans, Light, 26 pt"
        />
        <TypographyRow
          variant="mDisplay"
          name="Medium Display"
          subText="Playfair Display, Bold, 22 pt"
        />
        <TypographyRow
          variant="m"
          name="Medium"
          subText="Noto Sans, Semibold, 22 pt"
        />
        <TypographyRow
          variant="mLonger"
          name="Medium Longer Text"
          subText="Noto Sans, Semibold, 17 pt"
        />
        <TypographyRow
          variant="mLight"
          name="Medium Light"
          subText="Noto Sans, Light, 21 pt"
        />
        <TypographyRow
          variant="s"
          name="Small"
          subText="Noto Sans, Light, 16 pt"
        />
        <TypographyRow
          variant="sCaps"
          name="Small Caps"
          subText="Noto Sans, Medium, 13 pt, 5% tracking"
        />
        <TypographyRow
          variant="sInfo"
          name="Small Info Label"
          subText="Playfair Display, Bold, 13 pt"
        />
        <TypographyRow
          variant="disclaimer"
          name="Disclaimer"
          subText="Noto Sans, Regular, 12 pt, Gray 2"
        />
        <TypographyRow
          variant="helper"
          name="Helper Text"
          subText="Shadows into Light, Regular, 18 pt"
        />
        <Link>This is a Link Text</Link>
      </Box>
      <Typography
        sx={{
          fontStyle: 'italic',
        }}
        variant="s"
      >
        Note: Click on Grid element to copy the typography variant except Link
      </Typography>
    </Box>
  );
};

export default React.memo(TypographyList);
