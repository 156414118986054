import React from 'react';
import { Box, Breakpoint, Grid, Typography, useTheme } from '@mui/material';
import HtmlTooltip from '../../components/HtmlTooltip';
import { useHistory } from 'react-router-dom';
import TabLayout from '../../components/TabLayout';
import { apiUrls } from '../../variables/urls';
import { BREAKPOINTS } from '../../variables/constant';
import BeneficiariesPercentForm from './BeneficiariesPercentForm';
import { BeneficiariesFragmentFragment } from '../../generated/graphql';

interface Props {
  onBeneficiaryNameChange: Function;
  onBeneficiaryPercentChange: Function;
  onBeneficiaryRemove: Function;
  onBeneficiaryAppend: Function;
  totalPercentage: Number;
  beneficiaries: BeneficiariesFragmentFragment[];
  divideEqually: boolean;
  setDivideEqually: Function;
  onEquallyDivided: Function;
  handleSubmit: Function;
  onNextClickPercentForm: Function;
  uiFlag?: string;
  setEditState?: Function;
}

const BeneficiariesBox = ({
  onBeneficiaryNameChange,
  onBeneficiaryPercentChange,
  onBeneficiaryRemove,
  onBeneficiaryAppend,
  totalPercentage,
  beneficiaries,
  divideEqually,
  setDivideEqually,
  onEquallyDivided,
  handleSubmit,
  onNextClickPercentForm,
  uiFlag,
  setEditState,
}: Props) => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onNextClickPercentForm)} noValidate>
      <TabLayout
        title="Beneficiaries"
        previous={() => history.push(apiUrls.ASSETS)}
        uiFlag={uiFlag}
        setEditState={setEditState}
        saveNextFlag={uiFlag ? true : false}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                p: 2,
              },
            }}
          >
            <Typography variant="m" component="div" align='center' sx={{ mx: 2 }}>
              Divide your assets between your beneficiaries based on percentage.
              <HtmlTooltip
                title={
                  <React.Fragment>
                    How Do I Determine If My Assets Should Be Divided Equally Among
                    My Beneficiaries
                    <br />
                    There is no obligation to divide your assets “equally” among
                    your beneficiaries but this is most often done to avoid
                    contention or bad feelings among beneficiaries. Things to
                    consider when choosing not to divide equally include resources
                    already available to certain beneficiaries, earning potential,
                    and special circumstances that may require a more specialized
                    division. If you have a child with “special needs” or a medical
                    condition, we encourage you to speak to an attorney licensed in
                    your state for pertinent legal advice regarding their situation.
                  </React.Fragment>
                }
              />
            </Typography>
            <Box>
              <BeneficiariesPercentForm
                onBeneficiaryNameChange={onBeneficiaryNameChange}
                onBeneficiaryPercentChange={onBeneficiaryPercentChange}
                onBeneficiaryRemove={onBeneficiaryRemove}
                onBeneficiaryAppend={onBeneficiaryAppend}
                totalPercentage={totalPercentage}
                beneficiaries={beneficiaries}
                divideEqually={divideEqually}
                setDivideEqually={setDivideEqually}
                onEquallyDivided={onEquallyDivided}
              />
            </Box>
          </Box>
        </Grid>
      </TabLayout>
    </form>
  );
};

export default React.memo(BeneficiariesBox);