import React, { useEffect } from 'react';
import { Box, Breakpoint, Button, Chip, Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TopBar from '../../components/TopBar';
import { BREAKPOINTS } from '../../variables/constant';
import Typography from '@mui/material/Typography';
import palette from '../../theme/palette';
import { questions } from './constants';
import QuestionRow from './QuestionRow';
import useBasicInfo from './basicinfo-hooks';
import Loader from '../../components/Loader';

const BasicInfo = () => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    onSeeMyRecommendations,
    loading,
    handleBack,
    handleNext,
    activeStep,
  } = useBasicInfo();

  // Need different background color for plan page
  useEffect(() => {
    document.body.classList.add('background-default');
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      width="100%"
      bgcolor="background.default"
      sx={{
        mt: 18,
        [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
          mx: 5,
        },
      }}
    >
      <TopBar />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="sHelper" component="p" sx={{ m: 2 }}>
          Find your plan
        </Typography>
        <Typography
          variant="sLabel"
          component="p"
          sx={{ m: 2, color: palette.grey[700] }}
        >
          Step {activeStep + 1} of 4
        </Typography>
        <Stack direction={'row'} spacing={0.5}>
          {questions.map((_, index) => {
            return (
              <Chip
                key={index}
                sx={{
                  height: 8,
                  width: 36,
                  backgroundColor:
                    index <= activeStep ? 'primary.main' : 'common.dustBlue',
                }}
              />
            );
          })}
        </Stack>
        <form autoComplete="off" noValidate>
          {questions.map(({ name, question, subText, options }, index) => {
            return (
              <QuestionRow
                control={control}
                question={question}
                subText={subText}
                name={name}
                key={name}
                options={options}
                displayQuestion={index === activeStep}
              />
            );
          })}
          <Stack
            direction="column"
            spacing={0}
            alignItems={'center'}
            sx={{ mt: 8 }}
          >
            <Stack direction="row" spacing={2} sx={{ maxWidth: '256px' }}>
              {activeStep !== 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    width: 256,
                    height: 50,
                  }}
                  onClick={handleBack}
                >
                  Previous
                </Button>
              )}
              {activeStep === questions.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: 256,
                    height: 50,
                  }}
                  onClick={handleSubmit(onSeeMyRecommendations)}
                >
                  Show Plans
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: 256,
                    height: 50,
                  }}
                  onClick={() => handleNext([questions[activeStep].name])}
                >
                  Next
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
      </Grid>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(BasicInfo);
