import React from 'react';
import Loader from '../../../components/Loader';
import usePersonalProperties from './personal-properties-hooks';
import PersonalProperties1 from './PersonalPropertiesBox';

interface Props {
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const PersonalProperties = ({ selectedForms, uiFlag, setEditState }: Props) => {
  const { loading, properties } = usePersonalProperties();
  return (
    <>
      <PersonalProperties1
        propertyList={properties || []}
        selectedForms={selectedForms}
        uiFlag={uiFlag}
        setEditState={setEditState}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(PersonalProperties);
