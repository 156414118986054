import React from 'react';

const GeneralPOWIcon = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.8011 8.34535C19.0155 7.96143 17.4027 4.81269 17.3515 4.68464C17.1212 4.17269 16.5835 3.86535 16.0203 3.83984C15.4571 3.83984 14.9451 4.1727 14.689 4.65914C14.6123 4.78717 13.0251 7.96144 7.21385 8.34558C6.77867 8.37112 6.4458 8.72951 6.4458 9.16487V17.4593C6.4458 24.6529 15.329 27.9553 15.713 28.0833C15.8153 28.1088 15.8923 28.1345 15.9946 28.1345C16.0969 28.1345 16.1739 28.109 16.2762 28.0833C16.6601 27.9552 25.5434 24.6529 25.5434 17.4593L25.5436 9.19002C25.5691 8.75484 25.2365 8.39646 24.8011 8.34522L24.8011 8.34535ZM23.9308 17.4846C23.9308 22.8606 17.454 25.8558 16.0204 26.4446C14.5868 25.8558 8.10998 22.8606 8.10998 17.4846V9.93255C13.3324 9.34362 15.4572 6.579 16.0204 5.68291C16.5836 6.60451 18.7084 9.34362 23.9308 9.93255V17.4846Z"
        fill="#578577"
      />
      <path
        d="M16.7627 10.8554V10.3947C16.7627 9.98501 16.4299 9.65234 16.0204 9.65234C15.6108 9.65234 15.2781 9.9852 15.2781 10.3947V10.8554C14.971 11.0346 14.7149 11.3161 14.6126 11.6747L12.1804 11.6489C12.0524 11.6489 11.9244 11.7513 11.8733 11.8538L10.0813 15.4122C10.0301 15.5145 10.0045 15.6425 10.0558 15.745C10.3629 16.8457 11.2078 17.5627 12.2319 17.5627C13.2558 17.5627 14.1006 16.8459 14.408 15.745C14.4335 15.6427 14.4335 15.5147 14.3824 15.4122L12.9228 12.6729H14.5867C14.7147 13.0057 14.9451 13.2873 15.2523 13.4664V18.2024C15.2523 19.0985 14.5867 19.8408 13.6907 19.9176L12.3083 20.0712C12.1548 20.0967 12.0523 20.2248 12.0523 20.3783V21.1976C12.0523 21.3512 12.1803 21.5048 12.3594 21.5048H19.6298C19.7834 21.5048 19.937 21.3767 19.937 21.1976V20.3783C19.937 20.2248 19.8346 20.0967 19.6809 20.0712L18.3245 19.9432C17.4284 19.8408 16.7629 19.0984 16.7629 18.228V13.4664C17.07 13.2871 17.3005 13.0057 17.4284 12.6728H19.0923L17.71 15.4376C17.6588 15.5399 17.6332 15.668 17.6845 15.7705C17.9916 16.8712 18.8364 17.5882 19.8606 17.5882C20.8845 17.5882 21.7293 16.8714 22.0367 15.7705C22.0622 15.6681 22.0622 15.5401 22.0111 15.4376L20.2192 11.8792C20.1167 11.7512 20.0142 11.6744 19.8606 11.6488L17.4286 11.6744C17.3261 11.316 17.0702 11.0344 16.7629 10.8553L16.7627 10.8554ZM13.3581 14.9257H11.0542L12.2061 12.6218L13.3581 14.9257ZM21.0125 14.9257H18.7086L19.8605 12.6218L21.0125 14.9257Z"
        fill="#578577"
      />
    </svg>
  );
};

export default React.memo(GeneralPOWIcon);
