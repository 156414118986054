import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  BusinessInterestsFragmentFragment,
  BusinessRelationship,
  BusinessType,
} from '../../../generated/graphql';
import useBusinessInterestForm from './business-interest-form-hooks';
import TabLayout from '../../../components/TabLayout';
import Loader from '../../../components/Loader';
import HtmlTooltip from '../../../components/HtmlTooltip';
import { Controller } from 'react-hook-form';
import FormHelperErrorText from '../../../components/FormHelperErrorText';
import StatesDropdown from '../../../components/StatesDropdown';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useAssetsCommon from '../assets-common-hooks';

interface Props {
  businessInterests: BusinessInterestsFragmentFragment[];
  selectedForms: string[];
  uiFlag?: string;
  setEditState?: Function;
}

const BusinessInterestBox = ({ businessInterests, selectedForms, uiFlag, setEditState }: Props) => {
  const theme = useTheme();
  const {
    loading,
    handleSubmit,
    create,
    onRemoveBusinessInterest,
    isSubmitting,
    control,
    fields,
    append,
    remove,
    errors,
    defaultValues,
    previousAction,
    onUpdate,
  } = useBusinessInterestForm({
    businessInterests,
    selectedForms,
    uiFlag,
    setEditState,
  });

  const { editItems, setEditItems, cancelEditMode } = useAssetsCommon({
    remove,
  });

  const resetAndRemove = (business: any, index: number) => {
    cancelEditMode(business.business_id, index);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout
        title="Assets"
        previous={previousAction}
        isSubmitting={isSubmitting}
        uiFlag={uiFlag}
        next={setEditState && !uiFlag ? () => setEditState('') : undefined}
        setEditState={setEditState}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography
                variant="sTextInfo"
                component="div"
                sx={{ color: theme.palette.primary.main, mb: 2 }}
              >
                BUSINESS INTEREST DETAILS
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="m" component="div">
                  List business interests to be held in trust
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Why Should I Include Business Ownership Interest in my
                        Trust or Will?
                        <br />
                        Including business ownership interests in your trust
                        will ensure that these interests will be distributed
                        pursuant to the terms of your trust and will avoid
                        probate for those assets similar to your real estate
                        assets.
                      </React.Fragment>
                    }
                  />
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={6}>
            <Stack direction="column" spacing={3}>
              {fields.map((business, index) => {
                return (
                  <Stack
                    key={business.id}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      width: '100%',
                      mt: 2,
                      border: '1px solid',
                      borderColor: theme.palette.common.lightGreen,
                      borderRadius: '0.5rem',
                      p: 2,
                    }}
                  >
                    {business.business_id && !editItems.includes(index) ? (
                      <Box sx={{ alignSelf: 'flex-start', width: '100%' }}>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'baseline'}
                        >
                          <Typography
                            variant="sTextInfo"
                            component="div"
                            sx={{
                              color: theme.palette.primary.main,
                              mb: 2,
                              fontWeight: 800,
                            }}
                          >
                            {business.name}
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() =>
                                setEditItems((prevArray: number[]) => [
                                  ...prevArray,
                                  index,
                                ])
                              }
                              size="small"
                              aria-label="delete"
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: '2rem',
                              }}
                            >
                              <EditOutlinedIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                onRemoveBusinessInterest(
                                  business.business_id,
                                  index,
                                )
                              }
                              size="small"
                              aria-label="delete"
                              sx={{ color: theme.palette.error.main }}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{ fontSize: 20 }}
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                        <Typography
                          variant="smText"
                          component="div"
                          sx={{ color: theme.palette.grey[700] }}
                        >
                          {business.business_type}
                        </Typography>
                        <Typography
                          variant="smText"
                          component="div"
                          sx={{ color: theme.palette.grey[700] }}
                        >
                          {business.state_formed_in}
                        </Typography>
                        <Typography
                          variant="smText"
                          component="div"
                          sx={{ color: theme.palette.grey[700] }}
                        >
                          {business.relationship}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.name`}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                label="BUSINESS NAME"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            rules={{
                              required: 'Please enter the business name',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.name && (
                            <FormHelperErrorText>
                              {errors?.fieldArray[index]?.name?.message}
                            </FormHelperErrorText>
                          )}
                        </FormControl>
                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.business_type`}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <InputLabel>BUSINESS TYPE</InputLabel>
                                <Select
                                  value={value ? value : ''}
                                  label="BUSINESS TYPE"
                                  onChange={onChange}
                                >
                                  <MenuItem disabled value={''}>
                                    <em>Select</em>
                                  </MenuItem>
                                  <MenuItem value={BusinessType.Llc}>
                                    LLC
                                  </MenuItem>
                                  <MenuItem value={BusinessType.SCorporation}>
                                    S Corporation
                                  </MenuItem>
                                  <MenuItem value={BusinessType.CCorporation}>
                                    C Corporation
                                  </MenuItem>
                                  <MenuItem value={BusinessType.NonProfit}>
                                    Non Profit
                                  </MenuItem>
                                  <MenuItem
                                    value={BusinessType.SoleProprietorship}
                                  >
                                    Sole Proprietorship
                                  </MenuItem>
                                </Select>
                              </>
                            )}
                            rules={{
                              required: 'Please select the business type',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.business_type && (
                            <FormHelperErrorText>
                              {
                                errors?.fieldArray[index]?.business_type
                                  ?.message
                              }
                            </FormHelperErrorText>
                          )}
                        </FormControl>

                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.state_formed_in`}
                            render={({ field: { onChange, value } }) => (
                              <StatesDropdown
                                value={value}
                                onChange={onChange}
                                inputLabel="STATE FORMED IN"
                              />
                            )}
                            rules={{
                              required: 'Please enter the state formed in',
                            }}
                            control={control}
                          />
                          {errors?.fieldArray?.[index]?.state_formed_in && (
                            <FormHelperErrorText>
                              {
                                errors?.fieldArray[index]?.state_formed_in
                                  ?.message
                              }
                            </FormHelperErrorText>
                          )}
                        </FormControl>

                        <FormControl
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Controller
                            name={`fieldArray.${index}.relationship`}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <InputLabel>
                                  RELATIONSHIP TO BUSINESS (OPTIONAL)
                                </InputLabel>
                                <Select
                                  value={value ? value : ''}
                                  label="RELATIONSHIP TO BUSINESS (OPTIONAL)"
                                  onChange={onChange}
                                >
                                  <MenuItem disabled value="">
                                    <em>Select</em>
                                  </MenuItem>
                                  <MenuItem value={BusinessRelationship.Owner}>
                                    Owner
                                  </MenuItem>
                                  <MenuItem
                                    value={BusinessRelationship.Founder}
                                  >
                                    Founder
                                  </MenuItem>
                                  <MenuItem
                                    value={BusinessRelationship.Investor}
                                  >
                                    Investor
                                  </MenuItem>
                                  <MenuItem
                                    value={BusinessRelationship.Employee}
                                  >
                                    Employee
                                  </MenuItem>
                                  <MenuItem
                                    value={BusinessRelationship.Partner}
                                  >
                                    Partner
                                  </MenuItem>
                                  <MenuItem value={BusinessRelationship.Member}>
                                    Member
                                  </MenuItem>
                                  <MenuItem value={BusinessRelationship.Other}>
                                    Other
                                  </MenuItem>
                                </Select>
                              </>
                            )}
                            control={control}
                          />
                        </FormControl>
                        <Stack
                          direction={'row'}
                          spacing={2}
                          alignSelf={'flex-start'}
                        >
                          {business.business_id && (
                            <Button
                              disabled={isSubmitting}
                              onClick={handleSubmit(onUpdate)}
                              variant="contained"
                              color="primary"
                              sx={{ width: '6rem' }}
                            >
                              Save
                            </Button>
                          )}
                          <Button
                            onClick={() =>
                              resetAndRemove(business, index)
                            }
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: '6rem',
                              color: theme.palette.common.naturalBlack,
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Stack>
                );
              })}

              <FormHelperErrorText>
                {errors?.fieldArray?.root?.message}
              </FormHelperErrorText>
              <Button
                onClick={() => append(defaultValues)}
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                sx={{
                  mt: 1,
                  width: '12rem',
                  color: theme.palette.text.primary,
                }}
              >
                Add Business
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </TabLayout>
      <Loader open={loading} />
    </form>
  );
};

export default React.memo(BusinessInterestBox);
