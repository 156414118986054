import react from 'react';
import { Box, Breakpoint, Button, Stack, Typography } from '@mui/material';
import YouDidItIcon from '../../components/Icons/YouDidItIcon';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import Layout from '../../components/Layout';

const YouDidItPage = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Layout isDocumentPage={false}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            width: '100vw',
          },
          [theme.breakpoints.up(BREAKPOINTS.SM as Breakpoint)]: {
            width: '90%',
            padding: '0% 5%',
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{
              p: 2,
            }}
          >
            <Box
              component={YouDidItIcon}
              sx={{
                width: '14.125rem',
                height: '22rem',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            />
            <Typography variant="lDisplay" component="p">
              Congratulations!
            </Typography>
            <Typography
              variant="s"
              component="p"
              sx={{
                maxWidth: '40rem',
                textAlign: 'center',
              }}
            >
              You have successfully completed your estate plan application. Our team is now preparing your documents, and a member of our team will be reaching out shortly to finalize the payment process. Please allow 5-8 business days for shipping and handling once your payment is confirmed. Once you receive your documents, kindly review them, sign, and have them notarized.
              Click the “Continue” button to find a notary near you.
            </Typography>
            <Box
              component={YouDidItIcon}
              sx={{
                width: '14.125rem',
                height: '22rem',
                [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
                  display: 'none',
                },
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                maxWidth: '24rem',
              }}
              onClick={() => history.push(apiUrls.NEAR_BY_NOTARY)}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Layout>
  );
};

export default react.memo(YouDidItPage);
