import * as React from 'react';
import { useState } from 'react';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Breakpoint, Button, Grid, Link, MenuItem, Modal, Stack } from '@mui/material';
import { apiUrls } from '../../variables/urls';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Logo from '../Icons/Logo';
import useProgressBarStatus from '../../pages/Dashboard/progress-bar-status-hook';
import Typography from '@mui/material/Typography';
import ChevronIcon from '../Icons/ChevronIcon';
import { StyledMenu } from '../StyledMenu/Menu.styled';
import { deleteToken } from '../../core/auth';
import { setUser } from '@sentry/react';
import { Auth, Cache } from 'aws-amplify';
import { openSnackbar } from '../Notifier';
import { NotifierType } from '../../variables/types';
import SideBarMenuItemList from '../SideBarMenuItemList';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BREAKPOINTS } from '../../variables/constant';

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const TopBar = () => {
  const theme = useTheme();
  const sidebar = Cache.getItem('sidebar');
  const [open, setSidebarState] = useState<boolean>(sidebar);
  const [logOutModal, setLogOutModal] = useState<boolean>(false);
  const setOpen = (value: boolean) => {
    Cache.setItem('sidebar', value);
    setSidebarState(value);
  };


  const history = useHistory();

  const {
    personalInformationProgress,
    childrenInfoProgress,
    guardianshipProgress,
    petsProgress,
    personalRepresentativeProgress,
    trusteeProgress,
    financialAssetsProgress,
    beneficiaryProgress,
    powerOfAttorneyProgress,
    userName,
    userID,
    onBoardingStatus,
    isDocumentComplete,
    isDocumentEditable,
    isPaymentComplete,
  } = useProgressBarStatus();

  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.MD as Breakpoint),
  );

  const toggleDrawer =
    (value: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(value);

      if (!value && desktopView) {
        Cache.setItem('drawer-menu', value);
        setDrawerMenuState(value);
      }
    };

  const drawerMenu = Cache.getItem('drawer-menu');
  const [drawerMenuState, setDrawerMenuState] = useState<boolean>(drawerMenu);
  const toggleDrawerMenu = (openMenu: boolean, option: string = '') => {
    return () => {
      if (!isPaymentComplete) {
        return;
      }
      Cache.setItem('drawer-menu', !openMenu);
      setDrawerMenuState(!openMenu);
      if (desktopView || option === 'Documents') {
        setOpen(true);
      }
    };
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    try {
      deleteToken();
      setUser(null);
      await Auth.signOut();
      history.push(apiUrls.LOGIN);
    } catch (error: any) {
      openSnackbar({ message: error.message }, NotifierType.Error);
    }
  };

  const handleOpenModal = () => setLogOutModal(true);
  const handleCloseModal = () => setLogOutModal(false);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'common.white',
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {userID && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(!open)}
              edge="start"
              sx={{
                color: theme.palette.grey['700'],
                mr: 5,
              }}
            >
              <MenuIcon
                fontSize="medium"
                sx={{
                  color: 'primary.main',
                }}
              />
            </IconButton>
          )}
          <Link
            to={apiUrls.DASHBOARD}
            component={RouterLink}
            textAlign={{ xs: userID ? 'center' : 'left', sm: 'left' }}
            sx={{
              flexGrow: 1,
            }}
          >
            <Box
              component={Logo}
              sx={{
                width: '100px',
                height: '60px',
              }}
            />
          </Link>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'end', md: 'center' }}
            justifyContent={{ xs: 'end', md: 'center' }}
            spacing={1}
          >
            {!userID && (
              <>
                <Typography
                  variant="smText"
                  sx={{
                    color: theme.palette.grey['700'],
                  }}
                  component="p"
                >
                  Already have an account?
                </Typography>
                <Link
                  sx={{
                    color: 'common.black',
                    textDecoration: 'none',
                  }}
                  to={apiUrls.LOGIN}
                  component={RouterLink}
                >
                  <Typography
                    variant="mLink"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                    }}
                    component="p"
                  >
                    Log In
                  </Typography>
                </Link>
              </>
            )}

            {userID && (
              <div>
                <Typography
                  variant="mLink"
                  sx={{
                    color: 'common.black',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  noWrap={userName.includes('@')}
                  component="p"
                  onClick={handleMenu}
                >
                  {userName} <Box component={ChevronIcon} sx={{ ml: 0.5 }} />
                </Typography>
                <StyledMenu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    width: 320,
                  }}
                >
                  <MenuItem onClick={() => history.push(apiUrls.DASHBOARD)}>
                    My Dashboard
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => history.push(apiUrls.PROFILE)}>
                    My Profile
                  </MenuItem>
                  <MenuItem onClick={() => history.push(apiUrls.CHANGE_PASSWORD)}>
                    Change Password
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleOpenModal}>Log Out</MenuItem>
                  <Modal
                    open={logOutModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 400,
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                    }}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Confirm Logout
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to logout from the system?
                      </Typography>
                      <Grid 
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 2 }}>
                        <Button
                          onClick={signOut}
                          variant="contained"
                          color="error">Logout</Button>
                        <Button 
                          sx={{ ml: 2 }} 
                          onClick={handleCloseModal}
                          variant="contained"
                          color="primary">Cancel</Button>
                      </Grid>
                    </Box>
                  </Modal>
                </StyledMenu>
              </div>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      {userID && (
        <Drawer
          variant="temporary"
          open={open}
          onClose={toggleDrawer(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Box
            sx={{
              // necessary for content to be below app bar
              ...theme.mixins.toolbar,
            }}
          />
          <SideBarMenuItemList
            open={open}
            toggleDrawer={toggleDrawer}
            toggleDrawerMenu={toggleDrawerMenu}
            drawerMenuState={drawerMenuState}
            onBoardingStatus={onBoardingStatus}
            isDocumentComplete={isDocumentComplete}
            isPaymentComplete={isPaymentComplete}
            isDocumentEditable={isDocumentEditable}
            personalInformationProgress={personalInformationProgress}
            childrenInfoProgress={childrenInfoProgress}
            guardianshipProgress={guardianshipProgress}
            petsProgress={petsProgress}
            personalRepresentativeProgress={personalRepresentativeProgress}
            trusteeProgress={trusteeProgress}
            financialAssetsProgress={financialAssetsProgress}
            beneficiaryProgress={beneficiaryProgress}
            powerOfAttorneyProgress={powerOfAttorneyProgress}
          />
        </Drawer>
      )}
      {userID && (
        <DesktopDrawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: 'background.default',
            },
          }}
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <DrawerHeader />
          <Divider />
          <SideBarMenuItemList
            open={open}
            toggleDrawer={toggleDrawer}
            toggleDrawerMenu={toggleDrawerMenu}
            drawerMenuState={drawerMenuState}
            onBoardingStatus={onBoardingStatus}
            isDocumentComplete={isDocumentComplete}
            isPaymentComplete={isPaymentComplete}
            isDocumentEditable={isDocumentEditable}
            personalInformationProgress={personalInformationProgress}
            childrenInfoProgress={childrenInfoProgress}
            guardianshipProgress={guardianshipProgress}
            petsProgress={petsProgress}
            personalRepresentativeProgress={personalRepresentativeProgress}
            trusteeProgress={trusteeProgress}
            financialAssetsProgress={financialAssetsProgress}
            beneficiaryProgress={beneficiaryProgress}
            powerOfAttorneyProgress={powerOfAttorneyProgress}
          />
        </DesktopDrawer>
      )}
    </>
  );
};

export default TopBar;
