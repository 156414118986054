const GREY = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EAE6E4',
  300: '#E0E0E0', // GRAY 3
  400: '#AFACAA',
  500: '#A6A6A6', // GRAY 1
  600: '#767676',
  700: '#626262', // GRAY 2
  800: '#585858',
  900: '#2E2C2C', // SOFT BLACK
};

const PRIMARY = {
  light: '#76A396',
  main: '#578577',
  dark: '#3D7060',
};

const SECONDARY = {
  light: '#E4BB3F',
  main: '#DEAA10',
  dark: '#9B760B',
};
const INFO = {
  light: '#7F9FAD',
  main: '#608899',
  dark: '#435F6B',
};
const SUCCESS = {
  light: '#AAF27F',
  main: '#378d1b',
  dark: '#229A16',
};

const ERROR = {
  light: '#E4713F',
  main: '#DE4E10',
  dark: '#9B360B',
};

const COMMON = {
  naturalBlack: '#000000',
  lightBlack: '#020202',
  richBlack: '#020202',
  black: '#272727',
  lightGrey: '#EFEFF4',
  white: '#FFFFFF',
  lightGreen: '#DDE7E4',
  dustGreen: '#B8CBC1',
  dustYellow: '#E7D8AE',
  dustBlue: '#CADCE3',
  dustOrange: '#9C6F43',
  darkGreen: '#366861',
  darkYellow: '#E7A800',
  green: '#679D84',
  green1A: '#e0ebe6',
  darkBlue: '#06303F',
  apricot: '#FFD3B5',
  dustApricot: '#F2C2A0',
  darkApricot: '#FFA982',
  darkApricot2: '#D89377',
  lightApricot: '#FFE2BD',
  chocolate: '#7F635D',
  metalBlue: '#2E2E47',
  metalGreen: '#515032',
  antiqueBrass: '#CC8B70',
  chestnutRose: '#C96B59',
  froly: '#F2836D',
  facebookBlue: '#176AE6',
  attorneyBlue: '#253a5b',
  curiousBlue: '#1D7CD5',
  calypso: '#82A7AF',
  gainsboro: '#DEDEDE',
  pewterBlue: '#82A7AF',
  pacificBlue: '#00B5D8',
  primaryLight2: '#57857733',
  lightBrown: '#FFC97D',
  visaBlue: '#00579F',
  visaYellow: '#FAA61A',
  tintGreen: '#34C759',
  brownBear: '#7A5534',
};

const palette = {
  common: { ...COMMON },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...ERROR },
  error: { ...ERROR },
  grey: GREY,
  text: { primary: '#2E2C2C', secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#FFFFFF', default: '#F8FAF3' },
  action: {
    disabledBackground: GREY[500],
    disabled: '#FFFFFF',
  },
};

export default palette;
