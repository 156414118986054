import React, { MouseEventHandler } from 'react';
import { Controller } from 'react-hook-form';
import {
  Breakpoint,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import Loader from '../../components/Loader';
import TabLayout from '../../components/TabLayout';
import HtmlTooltip from '../../components/HtmlTooltip';
import {
  PersonType,
  PowerOfAttorneyFragmentFragment,
  PowerOfAttorneyType,
} from '../../generated/graphql';
import usePowerOfAttorneyForm from './power-of-attorney-form-hooks';
import Box from '@mui/material/Box';
import GeneralPOWIcon from '../../components/Icons/GeneralPOWIcon';
import { BREAKPOINTS } from '../../variables/constant';

export interface Props {
  powerOfAttorney?: PowerOfAttorneyFragmentFragment | null;
  attorneyType: PowerOfAttorneyType;
  attorneyFor: PersonType;
  header: string;
  previous: MouseEventHandler;
  uiFlag?: string;
  setEditState?: Function;
}

const PowerOfAttorneyForm = ({
  powerOfAttorney,
  attorneyType,
  attorneyFor,
  header,
  previous,
  uiFlag, 
  setEditState,
}: Props) => {
  const { loading, handleSubmit, create, errors, control } =
    usePowerOfAttorneyForm({ powerOfAttorney, attorneyType, attorneyFor, uiFlag, setEditState });

  const theme = useTheme();

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout 
        title="Power of Attorney" 
        previous={previous}
        uiFlag={uiFlag}
        setEditState={setEditState}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Stack
                direction="row"
                justifyItems={'center'}
                alignItems={'center'}
                spacing={1}
              >
                <Box component={GeneralPOWIcon} />
                <Typography
                  variant="sTextInfo"
                  component="div"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {attorneyType === PowerOfAttorneyType.General
                    ? 'GENERAL'
                    : 'MEDICAL'}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{ my: 1 }}
              >
                <Typography variant="m" component="div" sx={{ width: '95%' }}>
                  {header}
                  {attorneyType === PowerOfAttorneyType.General ? (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          What is a General Power of Attorney?
                          <br />A document that authorizes someone to act on
                          your behalf in the event that you are unable to act
                          for yourself and covers all financial activity related
                          to your business, investments, paying bills, and
                          personal affairs.
                        </React.Fragment>
                      }
                    />
                  ) : (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          What is a Medical Power of Attorney?”
                          <br />A document that authorizes someone to act on
                          your behalf in the event that you are unable to act
                          for yourself and allows appointed persons to make
                          medical decisions on your behalf.
                        </React.Fragment>
                      }
                    />
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <Box
                display={'flex'}
                alignSelf={'flex-start'}
                sx={{
                  [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Typography variant="smText" noWrap>
                  Primary Choice&nbsp;
                </Typography>
                <Typography sx={{ color: theme.palette.grey[700] }} noWrap>
                  (Most people choose their spouse)
                </Typography>
              </Box>

              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="first_choice"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Full Legal Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Please enter the first choice name',
                  }}
                  control={control}
                />
                {errors.first_choice && (
                  <FormHelperErrorText>
                    {errors?.first_choice?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <Typography
                display={'flex'}
                alignSelf={'flex-start'}
                variant="smText"
              >
                Secondary Choice
                <Typography sx={{ color: theme.palette.grey[700] }}>
                  &nbsp;(Optional)
                </Typography>
              </Typography>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="second_choice"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Full Legal Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                />
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <Loader open={loading} />
      </TabLayout>
    </form>
  );
};

export default React.memo(PowerOfAttorneyForm);
