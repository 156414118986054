export const SUMMARY = 'Summary';

export const EDIT_CASES = {
  PERSONAL_INFORMATION: {
    PERSONAL_INFO_FORM: 'personalinfoform',
    ABOUT_YOU_FORM: 'aboutyouform',
    ADDRESS_FORM: 'addressform',
    CONTACT_INFO_FORM: 'contactinfoform',
    SPOUSE_INFO_FORM: 'spouseinfoform',
    SPOUSE_ABOUT_YOU_FORM: 'spouseaboutyouform',
    SPOUSE_ADDRESS_FORM: 'spouseaddressform',
    SPOUSE_CONTACT_INFO_FORM: 'spousecontactinfoform',
  },
  CHILDREN: 'children',
  GUARDIANSHIP: 'guardianship',
  PETS: 'pets',
  PERSONAL_REPRESENTATIVE: {
    PERSONAL_REPRESENTATIVE_SELF: 'personalrepresentativeself',
    PERSONAL_REPRESENTATIVE_SPOUSE: 'personalrepresentativespouse',
  },
  TRUSTEES: 'trustees',
  ASSETS: {
    REAL_ESTATE_PROPERTIES: 'real_estate_properties',
    BUSINESS_INTERESTS: 'business_interests',
    ACCOUNTS: 'financial_account_designations',
    PERSONAL_PROPERTIES: 'personal_properties',
  },
  BENEFICIARIES: 'beneficiaries',
  POWER_OF_ATTORNEY: {
    GENERAL_POA: 'generalpowerofattorney',
    MEDICAL_POA: 'medicalPowerOfattorney',
    GENERAL_POA_SPOUSE: 'generalpowerofattorneyspouse',
    MEDICAL_POA_SPOUSE: 'medicalpowerofattorneyspouse',
  },
};