/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import { useTheme } from '@mui/material/styles';

const Logo = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      width="793"
      height="513"
      viewBox="0 0 793 513"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        d="M326.325 64.4863C380.472 82.4819 409.283 131.112 424.251 178.11C438.778 223.729 438.325 267.141 438.183 280.714V280.729C438.172 281.781 438.163 282.654 438.163 283.333H426.223C411.345 252.522 401.255 240.844 394 232.446L394 232.446C391.394 229.429 389.153 226.836 387.188 223.931C364.997 191.138 366.676 177.135 366.676 177.135L385.226 183.862L386.34 182.828C386.34 182.828 367.956 167.279 362.007 156.682C350.051 135.381 345.994 118.569 345.994 118.569L326.325 64.4863Z"
        fill={theme.palette.primary.main}
      />
      <path 
        d="M515.327 287.848H552.091L552.107 287.859L553.541 289.293V297.187L552.107 298.621H546.366C542.778 298.621 540.268 300.593 539.191 303.999L502.246 411.957L500.812 413.391H491.484L490.05 411.957L459.565 332.336L428.717 411.957L427.284 413.391H417.955L416.521 411.957L379.581 303.999C377.966 299.159 375.274 298.621 372.407 298.621H366.666L365.232 297.187V289.293L366.666 287.859H425.343V302.368L413.686 325.033C412.284 327.762 412.284 330.998 413.686 333.727L430.103 372.016L430.535 372.048V335.742C428.009 335.118 426.132 332.837 426.132 330.113C426.132 326.915 428.728 324.319 431.926 324.319C435.125 324.319 437.721 326.915 437.721 330.113C437.721 332.832 435.85 335.113 433.323 335.737V372.032L433.76 372.01L450.284 333.722C451.665 330.993 451.644 327.661 450.237 324.948L438.499 302.358V287.848H486.094L487.528 289.282V297.176L486.094 298.61H480.716C477.848 298.61 475.876 300.94 475.876 303.632C475.876 304.351 475.876 305.065 476.233 305.785L503.493 373.929L526.803 305.785C527.16 304.527 527.341 303.45 527.341 302.555C527.341 300.044 525.908 298.61 522.859 298.61H515.327L513.887 297.176V289.282L515.327 287.848Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M126.071 411.914V404.025L127.505 402.591H133.24C136.827 402.591 140.41 399.004 140.41 395.422V298.701H131.806C112.265 298.701 107.606 311.249 106.348 322.725L104.914 324.159H97.025L95.9482 322.725L98.6348 289.378L100.069 287.944H206.198L207.632 289.378L210.318 322.725L209.242 324.159H201.353L199.919 322.725C198.666 311.254 194.002 298.701 174.46 298.701H165.857V395.422C165.857 399.009 169.444 402.591 173.027 402.591H178.762L180.196 404.025V411.914L178.762 413.348H127.488L126.055 411.914H126.071Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M199.05 403.316V411.205L200.489 412.644H242.797L244.231 411.211V403.322L242.797 401.888H240.643C237.061 401.888 233.474 398.306 233.474 394.718V362.805C233.474 350.257 237.951 340.038 244.945 335.737C245.302 342.906 250.68 348.466 258.388 348.466C266.096 348.466 271.832 342.906 271.832 335.198C271.832 327.491 265.739 321.574 256.954 321.574C245.302 321.574 236.336 329.106 231.677 339.143L233.468 325.518L232.035 324.084H226.299L200.127 334.66L199.05 336.094V340.934L200.484 342.368H202.637C206.219 342.368 209.807 345.95 209.807 349.537V394.713C209.807 398.295 206.225 401.882 202.637 401.882H200.484L199.05 403.316Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M379.661 397.565C376.074 397.565 372.492 393.977 372.492 390.395V342.709L374.283 325.859L372.849 324.426H367.113L339.145 334.287L338.068 335.721V340.561L339.502 341.995H341.655C345.243 341.995 348.825 345.582 348.825 349.164V378.924C348.825 393.625 340.04 402.41 328.745 402.41C319.065 402.41 311.895 397.032 311.895 385.198V342.709L313.686 325.859L312.253 324.426H306.517L278.548 334.287L277.471 335.721V340.561L278.905 341.995H281.059C284.646 341.995 288.228 345.582 288.228 349.164V385.918C288.228 406.536 301.133 416.573 319.779 416.573C331.431 416.573 342.545 411.195 348.819 403.13V414.425L350.253 415.859H355.989L381.804 405.283L383.238 403.849V399.009L381.804 397.575H379.651L379.661 397.565Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M543.823 404.025V411.914L545.262 413.353H587.57L589.004 411.919V404.03L587.57 402.596H585.417C581.835 402.596 578.247 399.014 578.247 395.427V365.731L580.038 349.239L578.247 347.448H572.869L544.9 358.023L543.823 359.457V364.297L545.257 365.731H547.411C550.993 365.731 554.58 369.313 554.58 372.901V395.422C554.58 399.004 550.998 402.591 547.411 402.591H545.257L543.823 404.025Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M566.59 305.492C558.343 305.492 551.712 312.123 551.712 320.369C551.712 328.615 558.343 335.246 566.59 335.246C574.836 335.246 581.467 328.615 581.467 320.369C581.467 312.123 574.836 305.492 566.59 305.492Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M596.888 411.914V404.025L598.322 402.591H600.475C604.063 402.591 607.645 399.004 607.645 395.422V309.73C607.645 306.142 604.057 302.56 600.475 302.56H598.322L596.888 301.126V296.286L597.965 294.852L625.933 284.277H631.669L633.103 285.711L631.312 302.56V395.422C631.312 399.009 634.899 402.591 638.481 402.591H640.635L642.069 404.025V411.914L640.635 413.348H598.327L596.893 411.914H596.888Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M651.744 404.025V411.914H651.749L653.183 413.348H695.49L696.924 411.914V404.025L695.49 402.591H693.337C689.755 402.591 686.168 399.009 686.168 395.422V302.56L687.959 285.711L686.525 284.277H680.789L652.82 294.852L651.744 296.286V301.126L653.177 302.56H655.331C658.913 302.56 662.5 306.142 662.5 309.73V395.422C662.5 399.004 658.918 402.591 655.331 402.591H653.177L651.744 404.025Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default Logo;


