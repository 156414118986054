import useGoogleAutocomplete from '../../../hooks/maps/google-address-autocomplete';

export interface Props {
  onAutocompleteAddress: Function;
}

const useAddressForm = ({ onAutocompleteAddress }: Props) => {
  const { googleAutoCompleteRef } = useGoogleAutocomplete({
    onPlaceSelect: onAutocompleteAddress,
  });

  return {
    googleAutoCompleteRef,
  };
};

export default useAddressForm;
