/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import { useTheme } from '@mui/material/styles';

const ClockIcon = (props: any) => {
  const theme = useTheme();

  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_7201_32052)">
        <path
          d="M5.49996 0.916748C2.96877 0.916748 0.916626 2.96889 0.916626 5.50008C0.916626 8.03127 2.96877 10.0834 5.49996 10.0834C8.03115 10.0834 10.0833 8.03127 10.0833 5.50008C10.0833 2.96889 8.03115 0.916748 5.49996 0.916748ZM7.61534 6.20521H5.49996C5.40645 6.20521 5.31678 6.16807 5.25066 6.10195C5.18454 6.03583 5.1474 5.94615 5.1474 5.85265V2.67957C5.1474 2.58606 5.18454 2.49639 5.25066 2.43027C5.31678 2.36415 5.40645 2.327 5.49996 2.327C5.59347 2.327 5.68314 2.36415 5.74926 2.43027C5.81538 2.49639 5.85252 2.58606 5.85252 2.67957V5.50008H7.61534C7.70885 5.50008 7.79853 5.53723 7.86465 5.60334C7.93076 5.66946 7.96791 5.75914 7.96791 5.85265C7.96791 5.94615 7.93076 6.03583 7.86465 6.10195C7.79853 6.16807 7.70885 6.20521 7.61534 6.20521Z"
          fill={theme.palette.grey['500']}
        />
      </g>
      <defs>
        <clipPath id="clip0_7201_32052">
          <rect width="11" height="11" fill={theme.palette.common.white} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockIcon;
