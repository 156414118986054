import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Breakpoint, Button, Link, Stack, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TopBar from '../../components/TopBar';
import { BREAKPOINTS, VALIDATOR_REGEX } from '../../variables/constant';
import useForgotPassword from './forgot-password-hooks';
import FormHelperErrorText from '../../components/FormHelperErrorText';

const ForgotPassword = () => {
  const { onForgotPassword, register, handleSubmit, errors } =
    useForgotPassword();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bgcolor="background.default"
    >
      <TopBar />
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onForgotPassword)}
        noValidate
      >
        <Stack spacing={2} sx={{ mt: 2, p: 4 }}>
          <Typography
            variant="xlDisplay"
            sx={{
              textAlign: 'center',
            }}
          >
            Forgot your password?
          </Typography>
          <Box display="flex" flexDirection="column">
            <Typography variant="sCaps">We’re here to help.</Typography>
            <Typography
              variant="sCaps"
              sx={{
                mt: 0,
              }}
            >
              Please Enter your email address below:
            </Typography>
          </Box>
          <TextField
            label="Email"
            variant="outlined"
            {...register('email', {
              required: {
                value: true,
                message: 'Email is required',
              },
              pattern: {
                value: VALIDATOR_REGEX.email,
                message: 'Please enter correct email address.',
              },
            })}
          />
          {errors.email && (
            <FormHelperErrorText>{errors.email.message}</FormHelperErrorText>
          )}
          <Button variant="contained" color="primary" type="submit">
            Email me a recovery link
          </Button>
          <Link
            to="/login"
            component={RouterLink}
            sx={{
              [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                textAlign: 'center',
              },
            }}
          >
            Go back to Log In
          </Link>
        </Stack>
      </form>
    </Box>
  );
};

export default React.memo(ForgotPassword);
