import { usePlacesWidget } from 'react-google-autocomplete';

interface Props {
  onPlaceSelect: Function;
}

export interface AddressProps {
  address: string;
  apt_or_suit: string;
  city: string;
  county: string;
  state: string;
  postalCode: string;
}

const useGoogleAutocomplete = ({ onPlaceSelect }: Props) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      let address = '';
      let city = '';
      let state = '';
      let county = '';
      let postalCode = '';
      let aptOrSuit = '';
      place?.address_components?.forEach((component) => {
        switch (component.types[0]) {
          case 'street_number':
          case 'street_address':
          case 'route':
          case 'intersection':
          case 'sublocality':
          case 'neighborhood':
          case 'premise':
            address = !address
              ? component.long_name
              : `${address} ${component.long_name}`;
            break;
          case 'floor':
          case 'room':
            aptOrSuit = !aptOrSuit
              ? component.long_name
              : `${aptOrSuit} ${component.long_name}`;
            break;
          case 'administrative_area_level_1':
            state = component.long_name;
            break;
          case 'administrative_area_level_2':
            county = component.long_name;
            break;
          case 'locality':
            city = component.long_name;
            break;
          case 'postal_code':
            postalCode = component.long_name;
            break;
          default: // do nothing
        }
      });
      onPlaceSelect({
        address,
        aptOrSuit,
        city,
        state,
        county,
        postalCode,
      });
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });

  return {
    googleAutoCompleteRef: ref,
  };
};

export default useGoogleAutocomplete;
