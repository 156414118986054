import React from 'react';
import PersonalRepresentativeForm from './PersonalRepresentativeForm';
import usePersonalRepresentative from './personal-representative-hooks';
import { PersonType } from '../../generated/graphql';
import { apiUrls } from '../../variables/urls';
import { useHistory } from 'react-router-dom';

interface Props {
  uiFlag?: string;
  setEditState?: Function;
}

const PersonalRepresentativeSpouse = ({ uiFlag, setEditState }: Props) => {
  const { personalRepresentatives } = usePersonalRepresentative();
  const history = useHistory();
  return (
    <PersonalRepresentativeForm
      personalRepresentative={personalRepresentatives?.spouse}
      personalRepresentativeFor={PersonType.Spouse}
      previous={() => history.push(apiUrls.PERSONAL_REPRESENTATIVE)}
      uiFlag={uiFlag}
      setEditState={setEditState}
    />
  );
};

export default React.memo(PersonalRepresentativeSpouse);
