import { useForm } from 'react-hook-form';
import { useShippingAddressInputMutation } from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import useGoogleAutocomplete, {
  AddressProps as GoogleAddressProps,
} from '../../../hooks/maps/google-address-autocomplete';

interface AddressFormProps {
  name?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  state?: string;
  save_address?: boolean;
  apt_or_suit?: string;
}

export interface AddressProps {
  onShipToAddress: Function;
}

const useAddressForm = ({ onShipToAddress }: AddressProps) => {
  const defaultValues = {
    name: '',
    address: '',
    zip_code: '',
    city: '',
    state: '',
    save_address: false,
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm<AddressFormProps>({
    defaultValues: {
      ...defaultValues,
    },
  });

  const [UpsertShippingAddress, { loading: shippingAddressLoading }] =
    useShippingAddressInputMutation();

  const onSubmit = async (data: AddressFormProps) => {
    try {
      await UpsertShippingAddress({
        variables: {
          input: {
            name: data.name,
            address: data.address,
            city: data.city,
            state: data.state,
            zip_code: data.zip_code,
            apt_or_suit: data.apt_or_suit,
          },
        },
      });
      openSnackbar(
        {
          message: 'Added shipping address successfully',
        },
        NotifierType.Success,
      );
      onShipToAddress();
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const onAutocompleteAddress = (place: GoogleAddressProps) => {
    const values = getValues();
    reset({
      ...values,
      address: place.address,
      city: place.city,
      state: place.state,
      zip_code: place.postalCode,
      apt_or_suit: place.apt_or_suit,
    });
  };

  const { googleAutoCompleteRef } = useGoogleAutocomplete({
    onPlaceSelect: onAutocompleteAddress,
  });

  return {
    loading: shippingAddressLoading,
    handleSubmit,
    errors,
    onSubmit,
    control,
    googleAutoCompleteRef,
  };
};

export default useAddressForm;
