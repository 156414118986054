import * as React from 'react';
import { ElementType, MouseEventHandler } from 'react';
import { useTheme } from '@mui/material/styles';
import { Breakpoint, Chip, Grid, Stack, Typography } from '@mui/material';
import TabLayout from '../TabLayout';
import Box from '@mui/material/Box';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { BREAKPOINTS } from '../../variables/constant';

interface Props {
  image: ElementType<SVGElement>;
  title: string;
  header: string;
  message?: string;
  quote?: string;
  quoteBy?: string;
  previous?: MouseEventHandler;
  continue?: MouseEventHandler;
}

const CompletionBox = (props: Props) => {
  const theme = useTheme();

  return (
    <TabLayout
      title={props.header}
      previous={props.previous}
      next={props.continue}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          border: '0.063rem solid',
          borderColor: 'common.lightGreen',
          borderRadius: '0.5rem',
        }}
      >
        <Grid item xs={11}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            sx={{
              py: 5,
            }}
          >
            <Box component={props.image} />
            <Typography
              variant="mText"
              component="p"
              sx={{
                textAlign: 'center',
                width: '60%',
                fontWeight: 600,
                color: theme.palette.primary.main,
                [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
                  width: '90%',
                },
              }}
            >
              {props.title}
            </Typography>
            <Chip
              sx={{
                mt: 3,
                height: 4,
                width: 25,
                backgroundColor: theme.palette.common.dustGreen,
              }}
            />
            {props.quote && (
              <Box display={'flex'} sx={{ width: '18rem', position: 'relative' }}>
                <Box
                  component={FormatQuoteIcon}
                  sx={{
                    color: theme.palette.common.dustGreen,
                    transform: 'rotate(180deg)',
                    mt: -2,
                    fontSize: '2rem',
                  }}
                />
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography
                    variant="disclaimer"
                    component="p"
                    sx={{ fontStyle: 'italic' }}
                  >
                    {props.quote}
                  </Typography>
                  <Typography
                    variant="sHelper"
                    component="p"
                    sx={{
                      fontStyle: 'italic',
                      color: theme.palette.grey[600],
                      textTransform: 'none' as const,
                      alignSelf: 'flex-end',
                      mt: 2,
                    }}
                  >
                    - {props.quoteBy}
                  </Typography>
                </Box>
                <Box
                  component={FormatQuoteIcon}
                  sx={{
                    color: theme.palette.common.dustGreen,
                    fontSize: '2rem',
                    position: 'absolute',
                    right: 0,
                    bottom: 16,
                  }}
                />
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
    </TabLayout>
  );
};

export default CompletionBox;
