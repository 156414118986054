import { styled } from '@mui/system';
import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    leaveTouchDelay={5000}
    enterTouchDelay={0}
  />
))(() => ({}));
