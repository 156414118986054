import React, { MouseEventHandler } from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  value?: boolean;
  onClick: MouseEventHandler;
  buttonText?: string;
}

const StyledBannerButton = ({ value, buttonText, onClick }: Props) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    normalButton: {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.lightGreen,
      },
    },
    selectedButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: theme.palette.common.lightGreen,
        color: theme.palette.common.black,
      },
    },
  });

  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={value ? classes.selectedButton : classes.normalButton}
      sx={{
        mb: 1,
        width: 200,
        height: 43,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: 256,
          alignItems: 'center',
          mx: 2,
        }}
      >
        {buttonText}
        <CheckCircleIcon
          fontSize="medium"
          sx={{
            position: 'absolute',
            mt: 1.2,
            mr: 2,
            top: 0,
            right: 0,
            color: theme.palette.primary.main,
            display: value ? 'block' : 'none',
          }}
        />
      </Box>
    </Button>
  );
};

export default React.memo(StyledBannerButton);
