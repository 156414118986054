import { useContext, useState } from 'react';
import { DocumentContext } from '../PlanDocuments/DocumentContext';

const useTrustees = () => {
  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const [haveTrustees, setHaveTrustees] = useState<boolean>(false);

  const onBannerNextClick = () => {
    setHaveTrustees(true);
  };

  return {
    loading: loadingPlanDocuments,
    trusteesInfo: planDocuments?.trustees,
    haveTrustees,
    setHaveTrustees,
    onBannerNextClick,
  };
};

export default useTrustees;
