import { useUserPlanQuery } from '../../../generated/graphql';
import { useAuthProvider } from '../../../core/authContext';

const useUserPlan = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const planID = user?.user_info.plan_id;

  const { data: userPlan, loading: loadingPlan } = useUserPlanQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: planID!,
    },
    skip: !planID,
  });

  return { userPlan, loadingPlan };
};

export default useUserPlan;
