import React from 'react';
import { Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PetsForm from './PetsForm';
import Loader from '../../components/Loader';
import TabLayout from '../../components/TabLayout';
import HtmlTooltip from '../../components/HtmlTooltip';
import { PetsFragmentFragment } from '../../generated/graphql';
import usePetsForm from './pets-form-hooks';

interface Props {
  petsList: PetsFragmentFragment[];
  setShowGuardianBanner: Function;
  setFinalPets: Function;
  setHasPets: Function;
  uiFlag?: string;
  setEditState?: Function;
}

const PetsBox = ({ 
  petsList, 
  setShowGuardianBanner, 
  setFinalPets,
  setHasPets,
  uiFlag, 
  setEditState,
}: Props) => {

  const {
    loading,
    errors,
    control,
    fields,
    append,
    create,
    handleSubmit,
    deleteAPet,
  } = usePetsForm({
    petsList,
    setShowGuardianBanner,
    setFinalPets,
  });

  const theme = useTheme();

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout
        title="Pets"
        previous={() => setHasPets(false)}
        uiFlag={uiFlag}
        setEditState={setEditState}
        saveNextFlag={uiFlag ? true : false}
      >
        <Container
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            px: 6,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Typography variant="m" component="div">
            Pet Details
            <HtmlTooltip
              title={
                <React.Fragment>
                  Why Do You Need to Know If I Have Any Pets?
                  <br />
                  If you have Pets, your estate plan will establish
                  guardianship for your Pets.
                  <br />
                  Add the Pet name of your pet and the type of animal the Pet is.
                </React.Fragment>
              }
            />
          </Typography>
          <PetsForm
            control={control}
            fields={fields}
            append={append}
            errors={errors}
            deleteAPet={deleteAPet}/>
        </Container>
      </TabLayout>
      <Loader open={loading} />
    </form>
  );
};

export default React.memo(PetsBox);
