import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const ErrorPage = () => (
  <Box
    height="100%"
    width="100%"
    justifyContent="center"
    alignItems="center"
    paddingX={4}
  >
    <Box
      component={Typography}
      sx={{
        fontWeight: 'bold',
        marginBottom: 1,
      }}
    >
      Something Went Wrong
    </Box>
    <Typography>
      We’r are not sure what went wrong, you can go back, or visit the homepage.
    </Typography>
    <Button variant="contained" color="primary" href="/">
      Homepage
    </Button>
  </Box>
);

export default React.memo(ErrorPage);
