import React, { MouseEventHandler } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import Loader from '../../components/Loader';
import usePersonalRepresentativeForm from './personal-representative-form-hooks';
import TabLayout from '../../components/TabLayout';
import HtmlTooltip from '../../components/HtmlTooltip';
import {
  PersonalRepresentativeFragmentFragment,
  PersonType,
} from '../../generated/graphql';

interface Props {
  personalRepresentative?: PersonalRepresentativeFragmentFragment | null;
  personalRepresentativeFor: PersonType;
  previous: MouseEventHandler;
  uiFlag?: string;
  setEditState?: Function;
}

const PersonalRepresentativeForm = ({
  personalRepresentative,
  personalRepresentativeFor,
  previous,
  uiFlag,
  setEditState,
}: Props) => {
  const { loading, handleSubmit, create, errors, control } =
    usePersonalRepresentativeForm({
      personalRepresentative,
      personalRepresentativeFor,
      uiFlag,
      setEditState,
    });

  const theme = useTheme();

  return (
    <form autoComplete="off" onSubmit={handleSubmit(create)} noValidate>
      <TabLayout
        title="Personal Representative"
        previous={previous}
        uiFlag={uiFlag}
        setEditState={setEditState}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="m" component="div" sx={{ width: '90%' }}>
                  {personalRepresentativeFor === PersonType.Self
                    ? 'Who would you like to appoint as your personal representative?'
                    : 'Who would your spouse/partner like to appoint as their personal representative?'}
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        Who Should I Choose as My Successor Trustee?
                        <br />
                        When choosing a successor trustee you should select
                        someone you trust to manage your assets for the benefit
                        of your beneficiaries. This can be an adult child or
                        other family member or a trusted friend. This person
                        does not need to have legal or business expertise but
                        should be able to exercise good judgment.
                      </React.Fragment>
                    }
                  />
                </Typography>
              </Stack>
              <Typography
                variant="smText"
                component="div"
                sx={{ width: '80%' }}
              >
                This is where you identify who will be responsible for managing and distributing your assets if you are gone. Most people will choose their Spouse first.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <Typography
                variant="smText"
                sx={{
                  alignSelf: 'flex-start',
                }}
              >
                Primary Representative
              </Typography>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="first_choice"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Full Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Please enter the first choice name',
                  }}
                  control={control}
                />
                {errors.first_choice && (
                  <FormHelperErrorText>
                    {errors?.first_choice?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
              <Typography
                variant="smText"
                sx={{
                  alignSelf: 'flex-start',
                }}
              >
                Secondary Representative
              </Typography>
              <FormControl
                sx={{
                  width: '100%',
                }}
              >
                <Controller
                  name="second_choice"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Full Name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Please enter the second choice name',
                  }}
                  control={control}
                />
                {errors.second_choice && (
                  <FormHelperErrorText>
                    {errors?.second_choice?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <Loader open={loading} />
      </TabLayout>
    </form>
  );
};

export default React.memo(PersonalRepresentativeForm);
