import { Button, Stack, TextField, Typography } from '@mui/material';
import { Box, Breakpoint } from '@mui/system';
import React from 'react';
import Layout from '../../components/Layout';
import { useTheme } from '@mui/material/styles';
import { BREAKPOINTS, VALIDATOR_REGEX } from '../../variables/constant';
import useChangePasswordForm from './change-password-form-hook';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import Loader from '../../components/Loader';

const ChangePassword = () => {
  const theme = useTheme();

  const {
    loading,
    register,
    errors,
    handleSubmit,
    onPasswordChange,
    showPassword,
    handleClickShowPassword,
  } = useChangePasswordForm();
  
  return (
    <Layout isDocumentPage={false}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            width: '100vw',
          },
          [theme.breakpoints.up(BREAKPOINTS.SM as Breakpoint)]: {
            width: '90%',
            padding: '0% 5%',
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Typography
            variant="lDisplay"
            component="p"
            sx={{
              mb: 3,
            }}
          >
            Change Password
          </Typography>
          <Box sx={{
            backgroundColor: theme.palette.common.white,
            px: 5,
            py: 2.5,
            [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
              px: 2,
              py: 2,
            },
          }}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(onPasswordChange)}
              noValidate
            >
              <Stack spacing={2} sx={{ mt: 2, mx: 4 }}>
              <Typography component="div" variant="sTextInfoLight">
                Please enter you current and new password to change your password.
              </Typography>
                <TextField
                  variant="outlined"
                  label="Current Password"
                  sx={{ mt: 2 }}
                  type={showPassword ? 'text' : 'password'}
                  {...register('current_password', { required: true })}
                />
                {errors.current_password && (
                  <FormHelperErrorText>Current password is required.</FormHelperErrorText>
                )}
                <TextField
                  variant="outlined"
                  label="New Password"
                  sx={{ mt: 2 }}
                  type={showPassword ? 'text' : 'password'}
                  {...register('new_password', { 
                    required: true,
                    pattern: {
                      value: VALIDATOR_REGEX.password,
                      message: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
                    }, 
                  })}
                />
                {errors.new_password && (
                  <FormHelperErrorText>
                    {errors?.new_password?.message}
                  </FormHelperErrorText>
                )}
                {errors.new_password && (
                  <FormHelperErrorText>Please enter a new password.</FormHelperErrorText>
                )}
                <TextField
                  variant="outlined"
                  label="Repeat New Password"
                  sx={{ mt: 2 }}
                  type={showPassword ? 'text' : 'password'}
                  {...register('repeat_new_password', { required: true })}
                />
                {errors.repeat_new_password && (
                  <FormHelperErrorText>Please repeat your new password.</FormHelperErrorText>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  sx={{ justifyContent: 'space-between', mt: 2 }}
                >
                  <Typography
                    variant="s"
                    sx={{ cursor: 'pointer' }}
                    color="primary"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? 'Hide password' : 'Show password'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                  <Button variant="contained" color="primary" type="submit">
                    Change Password
                  </Button>
                </Box>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Box>
      <Loader open={loading} />
    </Layout>
  );
};

export default React.memo(ChangePassword);