import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { NotifierType } from '../../variables/types';
import { Alert, AlertTitle, Button } from '@mui/material';
import useNotifier, { NotifierProps } from './use-notifier';

let openSnackbarFn: Function;

const Notifier = () => {
  const {
    open,
    message,
    variant,
    customIcon,
    actionButtonText,
    messageTitle,
    onActionButtonClick,
    handleSnackbarClose,
    openSnackbar,
  } = useNotifier();

  useEffect(() => {
    openSnackbarFn = openSnackbar.current;
  }, [openSnackbar]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      open={open}
      ContentProps={{
        'aria-describedby': 'snackbar-message',
      }}
    >
      {message ? (
        <Alert
          severity={variant}
          variant="filled"
          onClose={handleSnackbarClose}
          icon={customIcon}
          action={
            actionButtonText ? (
              <Button
                color="inherit"
                size="small"
                onClick={onActionButtonClick}
              >
                {actionButtonText}
              </Button>
            ) : undefined
          }
        >
          {messageTitle && <AlertTitle>{messageTitle}</AlertTitle>}
          {message}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};

export const openSnackbar = (
  {
    message,
    customIcon,
    actionButtonText,
    onActionButtonClick,
    messageTitle,
  }: NotifierProps,
  variant: NotifierType,
) => {
  openSnackbarFn(
    {
      message,
      customIcon,
      actionButtonText,
      onActionButtonClick,
      messageTitle,
    },
    variant,
  );
};

export default Notifier;
