import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Radio, RadioProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledRadioButton = (props: JSX.IntrinsicAttributes & RadioProps) => {
  const theme = useTheme();
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={
        <CheckCircleIcon
          fontSize="small"
          sx={{
            color: 'primary.main',
          }}
        />
      }
      icon={
        <CheckCircleIcon
          sx={{
            color: theme.palette.background.default,
          }}
        />
      }
      {...props}
    />
  );
};

export default React.memo(StyledRadioButton);
