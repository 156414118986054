import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TopBar from '../TopBar';
import { PlanDocumentFragmentFragment } from '../../generated/graphql';
import DocumentContentLayout from '../DocumentContentLayout';

type Props = {
  children?: React.ReactNode;
  planDocuments?: PlanDocumentFragmentFragment | null;
  isDocumentPage?: boolean;
};

const Layout = ({ children, isDocumentPage = true }: Props) => {
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      bgcolor="background.default"
    >
      <TopBar />
      <Box component="main" sx={{ flexGrow: 1, overflowY: 'scroll' }}>
        <DrawerHeader />
        {isDocumentPage ? (
          <DocumentContentLayout children={children} />
        ) : (
          <Box>{children}</Box>
        )}
      </Box>
    </Box>
  );
};

export default Layout;
