import react from 'react';
import React from 'react';
import { Box, Breakpoint, Button, Stack, Typography } from '@mui/material';
import YouDidItIcon from '../../components/Icons/YouDidItIcon';
import { BREAKPOINTS } from '../../variables/constant';
import { useTheme } from '@mui/material/styles';
import useStripeCheckout from './stripe-payment-hooks';
import Loader from '../../components/Loader';

const YouDidItPage = () => {
  const theme = useTheme();
  const { loading, redirectToStripeCheckout } = useStripeCheckout();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{
        p: 2,
      }}
    >
      <Box
        component={YouDidItIcon}
        sx={{
          width: '14.125rem',
          height: '22rem',
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            display: 'none',
          },
        }}
      />
      <Typography variant="lDisplay" component="p">
        You did it!
      </Typography>
      <Typography
        variant="s"
        component="p"
        sx={{
          maxWidth: '24rem',
          textAlign: 'center',
        }}
      >
        Your personalized will is ready to finalize. All we need now is your
        one-time payment, and we’ll send you your legally certified documents,
        along with detailed instructions on how to get them notarized.
      </Typography>
      <Box
        component={YouDidItIcon}
        sx={{
          width: '14.125rem',
          height: '22rem',
          [theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint)]: {
            display: 'none',
          },
        }}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{
          maxWidth: '24rem',
        }}
        onClick={() => redirectToStripeCheckout()}
      >
        Enter Payment
      </Button>
      <Loader open={loading} />
    </Stack>
  );
};

export default react.memo(YouDidItPage);
