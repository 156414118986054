import { useState } from 'react';

const useMobileView = () => {
  const [showPaymentType, setShowPaymentType] = useState(true);
  const [showCardForm, setShowCardForm] = useState(false);
  const [showShippingAddress, setShowShippingAddress] = useState(false);

  return {
    showPaymentType,
    setShowPaymentType,
    showCardForm,
    setShowCardForm,
    showShippingAddress,
    setShowShippingAddress,
  };
};

export default useMobileView;
