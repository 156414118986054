import React from 'react';
import {
  FormControl,
  Grid,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TabLayout from '../../components/TabLayout';
import { FormProps } from './PersonalInformationForm/personal-information-form-hooks';
import { Controller } from 'react-hook-form';
import { RelationType } from '../../generated/graphql';
import FormHelperErrorText from '../../components/FormHelperErrorText';
import StyledFormControlLabel from '../../components/StyledFormControlLabel';

const SpouseRelationForm = ({
  errors,
  control,
  previousPage,
  nextPage,
}: FormProps) => {
  const theme = useTheme();

  const submitFormData = (e: any) => {
    e.preventDefault();
    nextPage(['relation_type']);
  };

  return (
    <form autoComplete="off" onSubmit={submitFormData} noValidate>
      <TabLayout title="Personal Information" previous={previousPage}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            py: 8,
            border: '0.063rem solid',
            borderColor: 'common.lightGreen',
            borderRadius: '0.5rem',
          }}
        >
          <Grid item xs={11}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="m" component="p">
                How are they related to you?
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={11} sm={8} md={6} lg={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{
                width: '100%',
              }}
            >
              <FormControl>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      aria-label="relation_type"
                      value={value}
                      onChange={onChange}
                    >
                      <StyledFormControlLabel
                        value={RelationType.Wife}
                        name="type-group"
                        label="Wife"
                      />
                      <StyledFormControlLabel
                        value={RelationType.Husband}
                        name="type-group"
                        label="Husband"
                      />
                      <StyledFormControlLabel
                        value={RelationType.Partner}
                        name="type-group"
                        label="Partner"
                      />
                    </RadioGroup>
                  )}
                  name="relation_type"
                  control={control}
                  rules={{
                    required: 'Please specify the relation',
                  }}
                />
                {errors.relation_type && (
                  <FormHelperErrorText>
                    {errors?.relation_type?.message}
                  </FormHelperErrorText>
                )}
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </TabLayout>
    </form>
  );
};

export default React.memo(SpouseRelationForm);
