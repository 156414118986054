import React from 'react';
import { createPortal } from 'react-dom';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
} from '@mui/material';
import useDevtool from './dev-tool-hook';
import Flag from './Flag';
import noop from '../../utils/noop';

const DevTools = () => {
  const {
    showDevtools,
    showPanel,
    setShowPanel,
    featureFlags,
    featureFlagDispatch,
  } = useDevtool();

  if (!showDevtools) return null;

  const featureFlagKeys = Object.keys(featureFlags ?? {}) || [];

  const togglePanel = () => {
    setShowPanel((prev) => !prev);
  };

  return (
    <>
      {createPortal(
        <Button
          variant="contained"
          color="primary"
          onClick={togglePanel}
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            height: 40,
            minWidth: 180,
            zIndex: 'drawer',
          }}
        >
          Dev tool
        </Button>,
        document.body,
      )}
      {showPanel &&
        createPortal(
          <Box
            sx={{
              bgcolor: 'grey.300',
              width: '100%',
              bottom: 0,
              left: 0,
              position: 'fixed',
              zIndex: 'drawer',
              borderTop: (theme) => `1px solid ${theme.palette.grey[500]}`,
              height: '40vh',
            }}
          >
            <Box display="flex" justifyContent="space-between" p={2}>
              <Typography variant="h4">Devtool</Typography>
            </Box>
            <Box>
              <TableContainer
                sx={{
                  maxHeight: '30vh',
                  borderRadius: 0,
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Flag</TableCell>
                      <TableCell>Enabled</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {featureFlagKeys?.length > 0 ? (
                      featureFlagKeys.map((flag) => (
                        <Flag
                          key={flag}
                          flag={flag}
                          checked={Boolean(featureFlags?.[flag])}
                          featureFlagDispatch={featureFlagDispatch || noop}
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>
                          No feature flags available.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>,
          document.body,
        )}
    </>
  );
};

export default React.memo(DevTools);
