import React from 'react';

const NotorizationDoneImageIcon = (props: any) => {
  return (
    <svg
      width="494"
      height="435"
      viewBox="0 0 494 435"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M286.147 49.2864C299.613 49.2864 310.529 38.377 310.529 24.9195C310.529 11.4621 299.613 0.552734 286.147 0.552734C272.682 0.552734 261.766 11.4621 261.766 24.9195C261.766 38.377 272.682 49.2864 286.147 49.2864Z"
        fill="#FFEA8F"
      />
      <path
        d="M197.764 385.547C197.764 385.547 223.568 334.851 240.703 330.045C257.837 325.24 277.207 364.023 277.207 364.023C277.207 364.023 290.617 354.412 307.617 359.353C324.684 364.294 348.388 381.96 348.388 381.96L197.764 385.547Z"
        fill="#726F38"
      />
      <path
        d="M3.72461 411.54L7.51732 405.719C17.8795 389.948 35.4885 380.404 54.3843 380.404H380.76C402.501 380.404 423.022 390.625 436.161 407.952L440.36 413.503L3.72461 411.54Z"
        fill="#84CECA"
      />
      <path
        d="M1.35551 419.595C1.35551 419.595 -5.75582 411.066 13.0045 405.99C31.7649 400.913 361.595 403.756 384.622 404.23C407.649 404.636 446.118 405.854 444.56 415.669C443.003 425.483 172.027 424.4 150.693 424.129C129.427 423.859 21.4027 420.068 1.35551 419.595Z"
        fill="#FFC97D"
      />
      <path
        d="M426.747 419.122C426.747 419.122 429.321 351.978 421.667 297.559C410.086 215.321 389.768 180.26 382.725 158.871C375.681 137.482 362.068 113.657 369.518 113.454C376.968 113.318 385.027 132.135 396.541 157.449C408.935 184.659 430.946 226.963 444.559 285.037C453.364 322.535 459.188 418.919 459.188 418.919L435.145 418.378L426.747 419.122Z"
        fill="#9C6F43"
      />
      <path
        d="M417.334 100.119C423.497 100.728 432.302 102.691 441.174 105.805C441.851 106.008 441.919 107.226 442.596 107.497C443.883 107.971 445.779 107.497 447.066 108.038C456.616 111.896 465.556 116.838 470.5 122.591C475.715 128.615 482.826 156.704 482.826 156.704L470.906 152.711L462.982 142.558L463.388 150.207L445.238 144.115C445.238 144.115 440.835 134.842 438.6 133.827C437.652 133.353 439.684 141.543 438.397 140.934C433.656 138.7 426.816 135.519 419.298 131.931C418.079 131.322 416.521 122.929 415.235 122.32C414.151 121.779 413.27 129.089 412.187 128.547C392.072 118.868 369.79 114.401 371.009 106.346C373.379 90.9139 409.681 99.3746 417.334 100.119Z"
        fill="#515032"
      />
      <path
        d="M376.901 105.805C376.901 105.805 416.995 126.788 431.489 152.17C445.982 177.552 443.341 196.233 443.341 196.233C443.341 196.233 405.211 169.497 389.092 151.087C372.973 132.676 364.642 102.556 376.901 105.805Z"
        fill="#515032"
      />
      <path
        d="M345.407 67.4952C346.22 66.7507 364.303 70.0673 371.144 77.1066C377.984 84.1459 378.932 102.353 378.932 102.353C378.932 102.353 379.88 110.611 367.554 109.122C355.228 107.633 343.511 92.5389 342.021 88.6131C340.396 84.6873 344.595 68.3074 345.407 67.4952Z"
        fill="#726F38"
      />
      <path
        d="M376.9 107.633C375.614 103.91 372.024 101.609 368.096 101.947C365.387 102.15 362.136 103.098 358.885 105.399C351.097 111.017 314.795 153.456 310.528 168.008C306.261 182.628 308.022 213.222 308.022 213.222C308.022 213.222 318.791 206.995 330.033 197.925C331.388 196.842 331.117 186.96 332.472 185.809C333.352 185.065 335.79 193.119 336.671 192.307C340.26 189.058 343.714 185.539 346.694 181.884C347.575 180.801 348.523 179.244 349.607 177.281C350.148 176.266 348.117 165.639 348.726 164.421C349.132 163.609 352.18 172.34 352.586 171.46C354.28 167.94 356.108 163.812 358.005 159.344C358.411 158.397 357.26 148.515 357.666 147.499C358.411 145.672 360.714 152.711 361.459 150.884C369.518 130.781 377.171 108.512 376.9 107.633Z"
        fill="#515032"
      />
      <path
        d="M371.415 103.842C371.415 103.842 403.789 64.5164 433.927 56.9356C464.066 49.3548 479.237 53.619 479.237 53.619C479.237 53.619 471.922 63.8395 459.731 74.9399C458.377 76.226 453.839 73.3155 452.349 74.5338C451.604 75.143 453.907 79.9487 453.162 80.6255C449.03 83.9421 444.561 87.191 439.684 90.1692C438.533 90.846 436.23 87.1233 435.079 87.8002C433.995 88.4094 434.063 93.3504 432.979 93.9596C430.744 95.1102 428.441 96.1932 426.071 97.1408C394.849 109.933 365.726 115.754 371.415 103.842Z"
        fill="#726F38"
      />
      <path
        d="M376.697 101.134C376.697 101.134 327.121 89.2893 298.54 101.473C269.96 113.588 260.41 126.178 260.41 126.178C260.41 126.178 272.398 129.901 288.855 131.39C290.684 131.593 292.58 126.516 294.544 126.652C295.56 126.719 296.576 131.931 297.592 131.931C302.875 132.134 308.429 131.999 314.05 131.39C315.337 131.254 314.93 126.923 316.285 126.719C317.504 126.584 320.484 130.578 321.703 130.374C324.209 129.968 326.647 129.427 329.085 128.75C361.73 120.154 388.482 107.226 376.697 101.134Z"
        fill="#726F38"
      />
      <path
        d="M384.758 165.503C384.352 165.503 383.945 165.165 383.878 164.759C383.81 164.285 384.149 163.811 384.623 163.744L398.778 161.645C399.252 161.578 399.726 161.916 399.794 162.39C399.861 162.864 399.523 163.337 399.049 163.405L384.894 165.503C384.826 165.503 384.826 165.503 384.758 165.503Z"
        fill="#2E2E47"
      />
      <path
        d="M396 193.795C395.594 193.795 395.188 193.457 395.12 193.051C395.052 192.577 395.391 192.103 395.865 192.036L411.51 189.261C411.984 189.193 412.458 189.531 412.526 190.005C412.593 190.479 412.255 190.953 411.781 191.02L396.136 193.795C396.068 193.795 396 193.795 396 193.795Z"
        fill="#2E2E47"
      />
      <path
        d="M407.311 227.571C406.904 227.571 406.498 227.3 406.43 226.826C406.362 226.353 406.633 225.879 407.107 225.743L425.258 222.088C425.732 222.021 426.207 222.291 426.342 222.765C426.41 223.239 426.139 223.713 425.665 223.848L407.514 227.503C407.378 227.571 407.378 227.571 407.311 227.571Z"
        fill="#2E2E47"
      />
      <path
        d="M425.391 270.889C425.053 270.889 424.714 270.686 424.579 270.347L422.276 265.406H416.316C415.842 265.406 415.436 265 415.436 264.526C415.436 264.053 415.842 263.647 416.316 263.647H422.818C423.156 263.647 423.495 263.85 423.63 264.188L425.188 267.437L426.069 264.323C426.136 263.985 426.407 263.782 426.678 263.714L438.192 260.736C438.666 260.601 439.14 260.871 439.275 261.345C439.411 261.819 439.14 262.293 438.666 262.428L427.694 265.271L426.34 270.212C426.272 270.55 425.933 270.821 425.527 270.889C425.459 270.889 425.459 270.889 425.391 270.889Z"
        fill="#2E2E47"
      />
      <path
        d="M429.32 306.425C429.117 306.425 428.982 306.357 428.778 306.29L425.257 303.785L422.751 304.598C422.277 304.733 421.803 304.462 421.599 303.988C421.464 303.515 421.735 303.041 422.209 302.838L425.121 301.89C425.392 301.822 425.663 301.822 425.934 302.025L429.252 304.394L430.404 303.447C430.539 303.379 430.675 303.312 430.81 303.244L438.26 301.89C438.328 301.89 438.396 301.89 438.396 301.89H447.945C448.419 301.89 448.826 302.296 448.826 302.77C448.826 303.244 448.419 303.65 447.945 303.65H438.463L431.352 304.936L429.862 306.154C429.727 306.357 429.523 306.425 429.32 306.425Z"
        fill="#2E2E47"
      />
      <path
        d="M446.93 353.128C446.456 353.128 446.185 353.128 446.05 353.06C445.237 352.993 441.648 353.06 439.48 353.06C438.329 353.06 437.313 353.06 436.704 353.06C434.739 353.06 426.815 352.113 426.477 352.113C426.003 352.045 425.664 351.639 425.664 351.097C425.732 350.624 426.138 350.285 426.68 350.285C426.748 350.285 434.875 351.233 436.704 351.233C437.245 351.233 438.261 351.233 439.413 351.233C444.492 351.165 446.05 351.097 446.659 351.3C447.472 351.3 450.791 351.03 453.771 350.759C454.245 350.691 454.719 351.097 454.719 351.571C454.787 352.045 454.38 352.519 453.906 352.519C449.978 352.993 447.946 353.128 446.93 353.128Z"
        fill="#2E2E47"
      />
      <path
        d="M267.118 434.553C392.423 434.553 494.003 433.189 494.003 431.507C494.003 429.825 392.423 428.461 267.118 428.461C141.812 428.461 40.2324 429.825 40.2324 431.507C40.2324 433.189 141.812 434.553 267.118 434.553Z"
        fill="#EAE6E4"
      />
      <path
        d="M251.199 182.491C246.797 177.144 197.153 86.784 193.766 81.3691C178.46 56.9346 165.389 57.8145 165.389 57.8145C165.389 57.8145 165.253 69.3211 172.297 85.3626C177.58 97.4106 186.181 117.852 197.153 135.518C209.14 154.808 219.774 167.194 235.554 181.408C247.812 192.441 257.633 190.411 251.199 182.491Z"
        fill="#FFA982"
      />
      <path
        d="M145.817 12.2633C145.817 12.2633 142.566 12.0602 140.805 15.5799C137.961 21.1978 138.841 31.6891 144.462 36.3594C150.084 41.0297 152.116 42.8572 153.267 41.3681C154.418 39.879 153.402 10.4358 145.817 12.2633Z"
        fill="#774237"
      />
      <path
        d="M242.939 174.979C242.939 174.979 251.879 176.265 253.233 176.874C254.656 177.483 265.357 189.599 263.867 191.697C262.377 193.795 260.616 192.983 260.074 193.186C259.6 193.321 256.958 194.201 256.687 194.201C256.417 194.269 255.333 194.269 254.656 194.337C253.911 194.404 252.15 195.487 251.337 195.352C250.524 195.217 247.68 189.599 246.393 188.516C245.106 187.433 238.266 183.507 237.453 182.424C236.64 181.409 240.636 175.52 242.939 174.979Z"
        fill="#FFA982"
      />
      <path
        d="M285.878 196.503C283.982 187.365 268.878 183.507 261.429 182.086C253.979 180.665 251.54 185.673 251.54 185.673C251.54 185.673 249.644 189.328 250.254 193.457C250.931 197.586 258.313 202.053 258.313 202.053C258.313 202.053 260.209 210.379 270.03 212.612C279.85 214.71 287.842 205.573 285.878 196.503ZM260.209 198.263C260.209 198.263 254.724 196.571 253.369 193.863C252.014 191.088 252.421 185.809 255.875 184.929C259.329 184.049 266.914 186.553 266.914 186.553L260.209 198.263Z"
        fill="#2E2E47"
      />
      <path
        d="M394.918 353.871C394.918 353.871 395.528 351.638 399.659 351.299C403.79 350.961 410.699 351.909 412.866 352.585C415.033 353.262 417.133 354.954 417.404 355.496C417.674 356.105 419.029 358.812 420.451 359.422C421.806 360.098 426.479 361.655 427.969 362.806C429.459 363.957 430.814 364.701 430.949 365.581C431.084 366.461 427.901 369.574 426.885 370.048C425.802 370.522 416.659 371.47 415.44 371.47C414.22 371.47 409.141 371.537 409.005 371.943C408.802 372.35 408.328 376.411 408.531 379.389C408.735 382.367 407.38 386.699 407.448 391.369C407.515 396.04 408.125 400.101 407.583 402.537C407.041 404.974 394.647 415.871 387.807 417.36C380.966 418.85 357.533 413.096 352.995 411.743C348.458 410.389 337.418 405.312 330.171 406.869C322.925 408.358 316.355 413.232 312.63 414.315C308.837 415.465 301.32 423.858 301.591 425.618C301.861 427.31 303.961 427.919 304.164 430.492C304.367 433.064 299.152 433.537 297.121 431.371C295.089 429.205 297.188 422.708 297.121 421.151C297.053 419.594 300.168 411.133 300.642 410.592C301.116 410.05 304.435 408.561 305.383 407.005C306.331 405.448 307.821 392.994 312.495 389C317.168 385.007 321.096 384.262 330.645 384.804C340.195 385.345 354.553 385.819 362.816 385.278C371.078 384.736 377.377 383.45 381.982 381.758C386.52 380.133 389.568 373.636 389.906 372.62C390.177 371.605 393.699 355.428 394.918 353.871Z"
        fill="#2E2E47"
      />
      <path
        d="M395.866 408.02C395.866 408.02 388.484 414.382 387.4 417.022C386.317 419.73 385.03 422.302 384.962 424.265C384.895 426.227 385.64 429.003 388.687 430.086C391.735 431.168 392.345 431.439 391.464 432.793C390.584 434.147 386.791 434.553 383.608 434.485C380.425 434.417 377.783 432.59 377.309 431.168C376.835 429.815 378.596 416.819 378.867 413.57C379.138 410.321 380.221 408.02 382.727 406.125C385.233 404.297 396.205 405.313 395.866 408.02Z"
        fill="#2E2E47"
      />
      <path
        d="M401.147 406.666C401.147 406.666 404.601 412.351 408.258 415.33C411.848 418.375 418.553 422.707 421.465 423.858C424.377 424.941 425.664 425.482 422.887 427.039C420.111 428.596 415.641 428.799 411.577 427.784C407.446 426.836 406.904 423.926 404.398 421.963C401.892 420 392.343 413.028 391.936 410.795C391.53 408.561 399.928 405.718 401.147 406.666Z"
        fill="#2E2E47"
      />
      <path
        d="M334.234 403.552C334.234 403.552 332.947 408.358 330.577 410.185C328.206 412.08 324.684 413.908 325.904 417.021C327.123 420.202 328.951 424.061 330.712 425.482C332.473 426.836 334.369 428.663 334.776 430.423C335.182 432.183 324.955 432.792 323.669 431.1C322.382 429.408 320.079 418.713 318.521 416.886C316.964 414.991 312.494 411.674 315.27 408.29C317.98 404.906 331.254 399.152 334.234 403.552Z"
        fill="#2E2E47"
      />
      <path
        d="M318.318 385.684C318.318 385.684 311.139 388.662 304.637 392.046C298.136 395.43 278.495 390.286 281.001 394.077C283.507 397.867 298.813 397.935 305.179 396.446C311.546 394.957 314.864 394.077 317.844 391.505C320.756 388.933 322.924 384.871 318.318 385.684Z"
        fill="#2E2E47"
      />
      <path
        d="M430.949 365.65C430.814 364.77 429.527 364.025 427.97 362.874C427.225 362.333 425.802 361.656 424.312 361.047C420.79 362.468 415.101 365.379 413.34 365.582C408.6 366.191 396.409 361.724 396.612 364.093C396.815 366.123 408.938 372.08 409.006 372.012L409.074 371.944C409.141 371.944 409.209 371.877 409.345 371.877H409.412C409.548 371.877 409.683 371.809 409.819 371.809C409.886 371.809 410.022 371.809 410.09 371.809C410.293 371.809 410.496 371.741 410.699 371.741C410.767 371.741 410.902 371.741 410.97 371.741C411.105 371.741 411.309 371.741 411.444 371.741C412.934 371.674 414.695 371.674 415.305 371.674C416.524 371.674 425.667 370.658 426.75 370.252C427.902 369.643 431.085 366.462 430.949 365.65Z"
        fill="#82664B"
      />
      <path
        d="M421.465 423.858C420.652 423.519 419.569 422.978 418.282 422.301C416.656 422.436 415.912 423.79 413.947 423.993C411.645 424.264 408.123 423.519 406.227 423.858C407.513 425.482 408.665 427.174 411.577 427.851C415.708 428.799 420.178 428.663 422.887 427.107C425.596 425.55 424.377 425.008 421.465 423.858Z"
        fill="#82664B"
      />
      <path
        d="M388.687 430.153C388.281 430.017 387.874 429.814 387.603 429.611C385.91 430.085 386.113 431.303 385.842 431.303C385.504 431.303 381.711 430.965 379.883 429.95C378.934 429.408 378.054 428.19 377.444 427.242C377.241 429.273 377.173 430.83 377.377 431.303C377.851 432.657 380.492 434.552 383.675 434.62C386.858 434.688 390.651 434.281 391.532 432.928C392.344 431.439 391.735 431.168 388.687 430.153Z"
        fill="#82664B"
      />
      <path
        d="M430.947 365.649C430.812 364.769 429.525 364.025 427.967 362.874C427.628 362.603 427.087 362.333 426.477 361.994C425.597 362.536 424.377 363.483 424.716 364.092C425.122 364.905 427.561 367.612 429.322 368.018C430.27 367.071 431.015 366.055 430.947 365.649Z"
        fill="#2E2E47"
      />
      <path
        d="M334.709 430.424C334.303 428.664 332.406 426.837 330.645 425.483C328.884 424.129 327.056 420.204 325.837 417.022C324.618 413.841 328.139 412.014 330.51 410.186C331.526 409.374 332.339 408.088 332.948 406.87C332.135 406.531 330.984 406.599 329.291 407.411C324.55 409.78 320.757 410.998 322.586 415.804C324.347 420.677 324.279 424.536 322.45 428.191C322.924 429.612 323.331 430.695 323.669 431.169C324.956 432.861 335.115 432.252 334.709 430.424Z"
        fill="#82664B"
      />
      <path
        d="M312.56 414.45C315.608 413.57 320.484 410.254 326.038 408.155C324.886 407.208 322.719 406.328 318.588 408.155C311.883 411.134 304.229 413.638 302.333 416.278C300.504 418.917 300.234 424.738 297.795 426.769C297.118 427.31 296.576 427.784 296.17 428.055C296.17 429.409 296.373 430.695 297.05 431.439C299.082 433.605 304.297 433.131 304.094 430.559C303.891 427.987 301.791 427.378 301.52 425.686C301.317 423.994 308.835 415.533 312.56 414.45Z"
        fill="#82664B"
      />
      <path
        d="M389.567 373.433C389.567 373.433 408.192 378.374 408.733 378.509C409.208 378.645 408.869 383.315 408.733 383.586C408.598 383.856 387.874 376.479 387.67 376.14C387.535 375.87 388.077 373.298 389.567 373.433Z"
        fill="#FA634B"
      />
      <path
        d="M410.087 359.286C410.019 359.286 409.884 359.218 409.816 359.15C409.748 359.015 409.748 358.812 409.884 358.744C409.951 358.677 411.712 357.526 414.286 357.729C414.421 357.729 414.557 357.864 414.557 358.067C414.557 358.203 414.421 358.338 414.218 358.338C411.915 358.135 410.222 359.218 410.222 359.218C410.222 359.218 410.155 359.286 410.087 359.286Z"
        fill="white"
      />
      <path
        d="M411.779 358.203C411.779 358.203 411.373 359.354 412.186 360.03C412.998 360.707 414.421 358.474 413.676 358C412.931 357.662 411.779 358.203 411.779 358.203Z"
        fill="white"
      />
      <path
        d="M399.32 354.887C399.32 354.887 396.136 352.518 392.885 355.158C389.635 357.798 386.181 360.64 385.571 363.686C384.961 366.732 391.192 374.448 394.172 376.479C397.152 378.51 398.71 376.682 400.065 373.501C401.351 370.32 396.881 362.942 398.304 360.505C399.59 358.068 400.2 356.715 399.32 354.887Z"
        fill="#2E2E47"
      />
      <path
        d="M396.408 377.764C395.189 377.764 394.038 376.884 393.293 376.207C392.209 375.192 389.839 372.62 389.703 372.552C389.568 372.416 389.568 372.213 389.703 372.146C389.839 372.01 390.042 372.01 390.109 372.146C390.109 372.146 392.548 374.853 393.631 375.801C394.376 376.478 395.392 377.29 396.476 377.222C397.018 377.155 397.559 376.884 398.034 376.275C399.591 374.312 400.201 372.62 399.93 370.927C399.794 369.98 399.253 368.22 398.778 366.46C398.304 364.836 397.898 363.347 397.695 362.467C397.289 360.504 399.253 357.796 399.32 357.661C399.388 357.526 399.591 357.526 399.727 357.593C399.862 357.661 399.862 357.864 399.794 357.999C399.794 357.999 397.966 360.571 398.304 362.331C398.44 363.211 398.914 364.768 399.388 366.257C399.93 368.017 400.472 369.844 400.607 370.86C400.878 372.755 400.268 374.582 398.575 376.681C398.034 377.358 397.356 377.764 396.611 377.831C396.544 377.764 396.476 377.764 396.408 377.764Z"
        fill="white"
      />
      <path
        d="M253.232 176.941C253.232 176.941 257.499 177.821 259.734 179.581C261.969 181.409 263.662 183.71 265.017 184.793C266.371 185.876 261.427 186.959 258.921 184.387C256.415 181.815 249.914 176.941 253.232 176.941Z"
        fill="#FFA982"
      />
      <path
        d="M392.885 360.91C392.614 360.91 392.343 360.774 392.14 360.504L280.797 195.892C280.526 195.486 280.662 194.945 281.068 194.674C281.474 194.403 282.016 194.471 282.287 194.945L393.698 359.624C393.969 360.03 393.901 360.571 393.427 360.842C393.224 360.842 393.088 360.91 392.885 360.91Z"
        fill="#2E2E47"
      />
      <path
        d="M199.388 158.938C199.388 158.938 209.886 213.086 207.921 235.964C205.957 258.909 188.348 286.119 171.078 309.673C159.632 325.309 113.171 369.981 93.8693 375.937C90.3475 377.02 88.4512 366.935 88.4512 366.935C88.4512 366.935 126.581 298.031 135.521 286.796C145.748 274.003 155.23 177.416 169.994 169.09C184.759 160.697 199.388 158.938 199.388 158.938Z"
        fill="#FFA982"
      />
      <path
        d="M208.667 152.507C207.922 145.333 206.5 134.3 205.078 131.457C202.911 127.125 186.859 87.3262 186.859 87.3262L190.246 123.673C192.413 127.734 194.716 131.728 197.154 135.586C201.082 141.813 204.807 147.363 208.667 152.507Z"
        fill="#2E2E47"
      />
      <path
        d="M124.686 210.514C129.968 238.13 132.88 310.892 132.88 310.892L135.657 399.898C135.657 399.898 149.474 403.892 149.744 402.132C154.35 376.073 181.779 199.684 184.421 193.254C187.062 186.689 119.471 182.898 124.686 210.514Z"
        fill="#FFA982"
      />
      <path
        d="M213.677 215.049C208.192 175.52 202.435 162.592 195.73 128.344C190.853 103.503 190.447 87.7322 188.077 79.3392C185.3 69.4571 176.902 58.9658 171.89 56.123C165.794 52.6711 157.938 50.099 151.572 53.2802C142.632 57.7475 139.313 60.4549 137.552 67.2912C134.776 78.1885 140.736 89.492 137.214 120.763C136.604 125.975 130.847 140.595 129.561 146.957C127.8 155.824 122.314 174.844 122.314 189.937C122.314 206.52 122.111 226.826 121.84 231.903C121.433 239.889 105.721 313.125 105.721 313.125C105.721 313.125 112.967 314.817 125.294 311.636C131.796 309.944 136.401 260.669 137.62 260.601C139.991 260.398 136.74 310.824 139.246 310.621C155.094 309.47 172.025 308.658 178.392 307.846C209.614 303.852 214.355 299.994 214.355 299.994C214.355 299.994 213.813 265.339 214.084 260.398C214.761 248.756 215.371 227.571 213.677 215.049Z"
        fill="#EAE6E4"
      />
      <path
        d="M150.83 40.6233C150.83 40.6233 151.778 45.2259 151.439 48.1364C150.83 52.6036 148.934 55.0403 148.934 55.0403C148.934 55.0403 153.674 61.9442 161.192 58.4923C162.614 57.8154 163.292 56.9355 163.766 56.1909C164.443 55.1757 164.375 53.822 163.563 52.8744C162.479 51.656 160.583 48.8132 159.567 42.7892C159.16 40.5556 150.83 40.6233 150.83 40.6233Z"
        fill="#FFA982"
      />
      <path
        d="M159.565 42.8574L154.146 42.6543C154.146 42.6543 155.907 47.3246 161.122 48.6106C160.513 47.1215 159.971 45.2263 159.565 42.8574Z"
        fill="#2E2E47"
      />
      <path
        d="M146.969 34.735C146.969 34.735 146.156 35.3442 144.87 34.1935C143.854 33.2459 143.921 29.3202 144.87 28.4402C145.885 27.5603 146.495 28.0341 146.766 27.9664C147.037 27.8988 146.292 22.687 147.985 19.5734C149.678 16.4599 152.929 13.6171 158.009 14.0232C163.088 14.497 165.594 17.3398 167.219 19.5057C168.845 21.6717 168.913 26.1389 169.048 28.2372C169.184 30.3354 169.658 31.0123 170.064 32.1629C170.944 34.8027 171.215 36.6979 170.335 39.6084C169.454 42.5865 166.271 45.4293 161.327 45.2939C156.451 45.0909 148.527 42.5865 146.969 34.735Z"
        fill="#FFA982"
      />
      <path
        d="M163.153 34.328C162.95 34.328 162.747 34.328 162.544 34.2603C161.934 34.1927 161.596 33.9219 161.528 33.5835C161.46 33.4481 161.596 33.2451 161.731 33.2451C161.866 33.1774 162.07 33.3127 162.07 33.4481C162.07 33.5158 162.341 33.6512 162.95 33.7189C163.695 33.7189 164.643 33.5158 164.982 32.9066C165.321 32.3652 164.914 31.5529 164.508 31.0114C163.153 29.0486 162.476 28.101 162.476 27.4918C162.408 27.2887 162.205 25.732 163.018 24.8521C163.424 24.3783 163.966 24.1752 164.711 24.1752C165.185 24.1752 167.826 24.0398 168.436 24.5813C168.571 24.7167 168.571 24.8521 168.504 24.9874C168.368 25.1228 168.233 25.1228 168.097 25.0551C167.826 24.7844 166.133 24.7167 164.711 24.7844C164.101 24.7844 163.695 24.9197 163.424 25.2582C162.815 26.0027 163.018 27.4241 163.018 27.4241V27.4918C163.018 27.9656 164.169 29.59 164.982 30.673C165.862 31.959 165.727 32.7713 165.456 33.2451C165.05 34.0573 164.101 34.328 163.153 34.328Z"
        fill="#2E2E47"
      />
      <path
        d="M155.501 25.2593C155.365 25.2593 155.297 25.1916 155.23 25.1239C155.162 24.9885 155.23 24.7855 155.365 24.7178C155.433 24.6501 157.261 23.8379 159.632 23.8379C159.767 23.8379 159.903 23.9733 159.903 24.1763C159.903 24.3117 159.767 24.4471 159.564 24.4471C157.397 24.3794 155.636 25.2593 155.568 25.2593H155.501Z"
        fill="#2E2E47"
      />
      <path
        d="M158.211 28.3047C158.821 28.3047 159.295 28.6431 159.363 29.0492C159.43 29.4553 159.498 30.4706 158.888 30.606C156.586 31.2829 157.263 28.7108 157.398 28.5754C157.534 28.3724 157.805 28.3047 158.211 28.3047Z"
        fill="#2E2E47"
      />
      <path
        d="M155.705 29.7932H155.637C155.502 29.7255 155.366 29.5901 155.434 29.4548C155.434 29.3871 155.705 28.5072 157.127 28.0334C158.617 27.5596 159.769 28.7102 159.904 28.9133C159.972 29.0487 159.972 29.2517 159.836 29.3194C159.701 29.3871 159.498 29.3871 159.43 29.2517C159.294 29.1163 158.414 28.3041 157.33 28.6425C156.179 28.981 156.044 29.6578 155.976 29.6578C155.976 29.7255 155.84 29.7932 155.705 29.7932Z"
        fill="#2E2E47"
      />
      <path
        d="M167.082 27.8301C166.608 27.8301 166.202 28.1685 166.134 28.5746C166.066 28.9807 165.998 29.996 166.472 30.1314C168.301 30.8083 167.759 28.3039 167.692 28.1008C167.624 27.9655 167.488 27.8301 167.082 27.8301Z"
        fill="#2E2E47"
      />
      <path
        d="M164.646 29.049C164.646 29.049 164.578 29.049 164.646 29.049C164.443 28.9813 164.307 28.846 164.375 28.7106C164.443 28.3722 165.323 27.6953 166.542 27.6953C167.761 27.6953 168.235 28.1691 168.303 28.2368C168.439 28.3722 168.439 28.5075 168.303 28.6429C168.168 28.7783 168.032 28.7783 167.897 28.6429C167.897 28.6429 167.49 28.3045 166.542 28.3045C165.662 28.3045 165.12 28.7106 164.985 28.846C164.917 28.9813 164.781 29.049 164.646 29.049Z"
        fill="#2E2E47"
      />
      <path
        d="M158.211 31.9583C158.211 31.9583 154.824 31.0784 153.876 32.1614C152.928 33.2443 156.721 34.1919 158.617 33.9212C160.446 33.6504 159.091 32.0937 158.211 31.9583Z"
        fill="#F2836D"
      />
      <path
        d="M169.114 31.3498C169.114 31.3498 166.608 31.0114 166.134 32.2297C165.728 33.4481 168.504 33.8542 169.791 33.245C171.01 32.7035 169.723 31.4175 169.114 31.3498Z"
        fill="#F2836D"
      />
      <path
        d="M167.218 34.8027C167.218 34.8027 163.493 36.4949 159.361 35.6826C159.361 35.6826 161.258 38.8639 164.915 38.5931C168.572 38.3224 167.218 34.8027 167.218 34.8027Z"
        fill="white"
      />
      <path
        d="M187.13 154.403C192.277 151.764 188.755 146.078 191.058 142.964C193.293 139.851 196.747 142.964 196.747 142.964C197.559 143.235 198.304 143.506 198.982 143.709C198.711 142.355 198.372 141.002 198.101 139.648C197.966 139.648 197.763 139.648 197.559 139.648C194.986 139.512 192.141 137.55 193.902 137.143C194.309 137.076 194.715 137.076 195.054 137.211C195.595 137.347 196.544 137.55 197.559 137.211C197.018 134.639 196.476 131.864 195.866 129.021C194.444 130.51 193.225 131.39 192.615 130.781C191.735 129.901 193.225 127.667 195.189 125.298C194.647 122.253 194.105 119.342 193.699 116.567C191.938 120.357 190.516 126.923 188.62 125.84C185.843 124.351 193.022 115.619 192.751 112.777C192.48 109.866 189.365 110.611 186.723 110.408C184.082 110.137 183.811 124.892 181.711 124.012C179.544 123.133 184.217 111.694 182.456 109.934C180.695 108.174 175.006 107.294 172.162 106.55C169.25 105.805 168.234 111.829 167.895 114.333C167.489 116.838 181.373 126.043 177.715 127.397C174.058 128.75 167.15 118.598 164.983 118.192C162.816 117.785 163.357 122.117 163.357 124.757C163.357 127.397 183.879 134.91 181.237 135.654C178.596 136.399 165.66 128.886 163.425 130.51C161.258 132.135 162.341 135.181 163.425 136.67C164.509 138.159 185.369 139.58 182.456 141.543C179.544 143.574 166.608 140.189 164.441 141.543C162.274 142.897 163.831 146.078 165.186 147.093C166.541 148.109 168.234 146.619 173.246 146.349C178.257 146.078 181.779 148.244 179.138 149.733C176.496 151.222 169.182 150.478 167.963 150.884C166.676 151.29 166.473 153.05 167.963 155.012C169.385 157.043 181.982 157.043 187.13 154.403ZM181.508 131.458C181.17 131.999 176.361 131.187 175.616 129.36C174.871 127.532 180.357 130.172 180.357 130.172C180.357 130.172 181.779 130.916 181.508 131.458ZM183.879 126.111C184.827 125.095 185.504 126.991 185.504 126.991C185.504 126.991 187.807 129.63 186.385 130.443C184.962 131.322 182.93 127.126 183.879 126.111Z"
        fill="#FA634B"
      />
      <path
        d="M132.949 194.134C131.933 189.734 146.765 190.14 148.052 187.974C149.339 185.808 148.662 184.658 145.546 180.123C142.431 175.588 135.116 188.583 135.59 182.83C136.132 177.077 144.395 178.024 144.259 175.385C144.056 172.745 141.55 163.743 140.467 162.795C139.586 162.051 128.75 168.345 124.348 171.188C123.806 174.099 123.332 177.077 122.993 180.055C123.467 183.439 123.806 186.62 122.451 186.688C122.384 187.771 122.383 188.854 122.383 189.937C122.383 198.195 122.316 207.4 122.248 215.184C123.873 216.064 124.483 217.62 124.686 218.974C125.16 221.749 126.244 224.389 128.073 226.555C128.14 226.623 128.208 226.758 128.343 226.826C131.73 230.751 140.196 231.361 143.379 224.186C146.562 217.011 149.203 211.664 148.932 208.415C148.662 205.099 134.981 206.723 135.455 203.609C135.929 200.496 147.917 206.52 149.61 204.828C151.303 203.136 150.693 197.044 148.932 192.915C147.172 188.854 134.033 198.533 132.949 194.134ZM137.961 212.882C137.961 212.882 140.128 214.575 139.18 216.064C138.706 216.808 137.487 216.876 136.403 216.74C135.387 216.605 134.507 217.417 134.439 218.5C134.371 219.583 134.168 220.937 133.558 221.817C132.204 223.712 129.292 214.575 128.614 210.784C127.937 206.994 137.961 212.882 137.961 212.882Z"
        fill="#FA634B"
      />
      <path
        d="M152.116 69.7961C151.168 67.9009 136.674 70.0668 138.097 66.8179C139.519 63.569 145.479 66.8179 147.037 64.1105C147.782 62.7568 146.021 60.0494 143.854 57.748C142.634 58.628 141.619 59.5756 140.806 60.5908C140.738 60.9293 140.467 61.3354 140.061 61.6061C138.977 63.1629 138.232 64.9904 137.69 67.224C135.997 73.8572 137.555 80.6258 138.232 91.9293C138.909 91.6585 139.654 91.3201 140.535 90.914C145.547 88.6804 133.965 79.1367 139.587 80.6935C145.14 82.1825 142.77 90.2371 145.411 90.5079C148.053 90.7786 157.331 89.831 158.415 88.9511C159.431 88.0712 153.064 71.6913 152.116 69.7961Z"
        fill="#FA634B"
      />
      <path
        d="M213.815 215.996C213.476 215.658 213.408 215.184 213.611 214.507C213.07 210.649 212.528 207.062 211.986 203.678C210.97 204.896 209.886 206.25 209.683 205.844C209.277 205.234 211.105 199.955 211.105 198.398C211.105 198.263 211.038 198.128 211.038 197.992C209.141 195.623 207.11 193.931 205.281 194.676C203.859 195.285 201.285 199.481 198.711 205.167C198.17 206.317 204.671 210.379 203.452 212.748C202.098 215.455 196.815 209.702 196.612 210.176C195.528 213.018 194.512 215.996 193.632 218.975C193.429 219.787 200.269 222.02 199.66 224.593C198.779 228.112 192.751 222.562 192.548 223.171C190.787 231.023 190.72 237.927 194.445 240.16C204.062 245.913 210.699 232.986 210.699 232.986C210.699 232.986 212.325 234.001 214.763 235.152C214.763 228.248 214.492 221.411 213.815 215.996Z"
        fill="#FA634B"
      />
      <path
        d="M137.757 397.394C140.196 398.477 143.717 418.783 145.207 420.475C146.765 422.167 146.968 423.25 151.845 420.475C153.605 419.46 155.705 419.392 157.669 419.73C156.111 418.647 154.621 417.564 153.673 415.94C151.303 412.014 150.693 396.446 150.693 396.446C149.136 393.062 136.674 392.859 136.064 394.145C135.861 394.619 135.319 395.634 134.574 396.988C135.387 396.853 136.471 396.785 137.757 397.394Z"
        fill="#FFA982"
      />
      <path
        d="M157.737 429.95C156.112 430.762 144.259 430.288 139.315 427.852C134.304 425.347 135.387 408.426 132.136 406.463C131.324 405.989 130.646 405.854 130.172 405.989C129.969 406.395 129.834 406.734 129.698 407.14C127.531 412.284 128.14 430.694 129.495 431.777C130.782 432.793 158.211 434.282 161.259 434.35C165.526 434.417 165.864 431.439 164.713 427.919C161.801 426.566 159.295 429.205 157.737 429.95Z"
        fill="#FFD3B5"
      />
      <path
        d="M132.136 406.463C135.387 408.426 134.303 425.348 139.315 427.852C144.327 430.356 156.111 430.763 157.737 429.95C159.294 429.206 161.8 426.566 164.577 427.852C164.306 427.04 163.968 426.16 163.561 425.28C162.342 422.573 159.972 421.219 157.737 419.662C155.773 419.324 153.673 419.391 151.912 420.407C146.968 423.182 146.765 422.099 145.275 420.407C143.717 418.715 140.195 398.409 137.825 397.326C136.538 396.717 135.455 396.784 134.71 396.92C133.423 399.356 131.594 402.808 130.172 405.922C130.646 405.854 131.323 405.99 132.136 406.463Z"
        fill="#9C6F43"
      />
      <path
        d="M91.5666 364.363C92.8534 366.664 83.7103 385.142 83.9135 387.376C84.1844 389.677 83.7103 390.692 89.2639 391.301C91.2957 391.505 92.9889 392.655 94.4112 394.144C93.8016 392.384 93.2598 390.625 93.463 388.729C93.9371 384.127 102.606 371.267 102.606 371.267C103.351 367.612 93.463 360.098 92.1762 360.775C91.7698 361.046 90.6862 361.52 89.2639 362.197C90.0089 362.467 90.8894 363.077 91.5666 364.363Z"
        fill="#FFA982"
      />
      <path
        d="M88.3842 402.403C86.5555 402.064 77.3447 394.754 74.7711 389.813C72.1974 384.872 83.1015 371.809 81.6792 368.289C81.3406 367.409 80.8665 366.935 80.3247 366.732C79.986 366.935 79.5797 367.139 79.241 367.342C74.4324 370.184 64.0025 385.414 64.4766 387.038C64.9507 388.663 86.0814 406.058 88.5196 407.885C91.906 410.457 93.9378 408.291 95.0891 404.772C93.6669 402.064 90.0773 402.741 88.3842 402.403Z"
        fill="#FFD3B5"
      />
      <path
        d="M81.6784 368.288C83.1007 371.808 72.2644 384.804 74.7703 389.812C77.3439 394.753 86.5548 402.131 88.3834 402.402C90.0766 402.74 93.6661 402.063 95.1561 404.771C95.427 403.959 95.6302 403.011 95.8334 402.063C96.4429 399.153 95.3593 396.649 94.4111 394.077C92.9888 392.655 91.2957 391.504 89.2639 391.234C83.6425 390.557 84.1166 389.542 83.9134 387.308C83.6425 385.007 92.8534 366.596 91.5666 364.295C90.8893 363.077 90.0088 362.467 89.2639 362.129C86.8257 363.28 83.3039 365.039 80.2562 366.664C80.798 366.935 81.2721 367.408 81.6784 368.288Z"
        fill="#9C6F43"
      />
      <path
        d="M124.008 173.355C134.031 172.407 153.537 169.632 157.6 162.728C163.086 153.388 162.07 144.859 162.07 144.859C162.07 144.859 155.027 151.899 145.613 156.366C140.533 158.803 132.948 158.87 127.056 158.464C125.972 163.134 124.888 168.211 124.008 173.355Z"
        fill="#2E2E47"
      />
      <path
        d="M150.761 25.7324L145.749 27.6953C145.546 27.763 145.478 27.966 145.613 28.1691C145.681 28.2368 145.749 28.3045 145.884 28.3045L151.844 27.8984L150.761 25.7324Z"
        fill="#2E2E47"
      />
      <path
        d="M160.174 18.7613C160.174 18.7613 156.178 22.6871 153.875 24.5146C151.573 26.3421 146.9 27.6958 145.342 27.8312C143.784 27.8989 143.242 27.6958 142.836 26.2067C142.43 24.7176 143.039 18.9644 143.242 17.8814C143.445 16.7307 143.987 11.2482 147.78 10.436C151.573 9.62375 154.417 10.7067 155.839 11.6543C157.329 12.5342 160.174 18.7613 160.174 18.7613Z"
        fill="#774237"
      />
      <path
        d="M159.429 18.3553C159.429 18.3553 168.233 22.4841 168.843 23.0256C169.453 23.6347 168.843 19.5736 168.03 17.2723C167.15 14.971 166.269 11.2483 162.206 9.89456C158.142 8.54085 150.692 8.81159 152.25 11.519C153.808 14.2941 159.429 18.3553 159.429 18.3553Z"
        fill="#774237"
      />
      <path
        d="M172.435 25.2589C171.825 24.9882 170.471 25.2589 168.371 25.5974C166.272 25.9358 165.188 27.1541 164.646 27.3572C164.104 27.6279 163.698 27.6279 162.75 27.3572C161.734 27.0188 159.025 26.1389 157.602 25.8681C156.18 25.5297 152.32 25.3943 151.236 25.5974C150.627 25.7327 150.559 25.8681 150.627 26.0035C150.694 26.2065 150.762 26.4096 150.762 26.6127C150.762 28.1694 152.184 30.8769 153.945 32.1629C155.842 33.5166 158.754 33.2459 160.041 32.7044C161.327 32.0952 162.682 30.3354 162.953 29.8616C163.224 29.3878 163.495 29.1847 164.104 29.5231C164.714 29.9293 164.511 31.3507 166.61 32.2306C168.71 33.1105 169.455 32.8397 171.08 31.6891C172.706 30.5384 172.706 27.6956 172.841 27.0188C172.976 26.3419 173.044 25.5297 172.435 25.2589Z"
        fill="#2E2E47"
      />
      <path
        d="M154.215 30.8762H154.147C152.386 29.3871 152.318 26.9504 152.318 26.8151C152.318 26.7474 152.386 26.6797 152.454 26.6797C152.522 26.6797 152.589 26.7474 152.589 26.8151C152.589 26.8151 152.657 29.2517 154.35 30.6731C154.418 30.7408 154.418 30.8085 154.35 30.8762C154.282 30.8762 154.282 30.8762 154.215 30.8762Z"
        fill="white"
      />
      <path
        d="M144.463 13.4811C144.463 13.4811 138.977 9.69075 142.364 5.02045C145.75 0.282457 149.814 2.24534 151.507 3.93748C153.2 5.62962 151.845 12.872 149.001 13.6842C146.021 14.5641 144.463 13.4811 144.463 13.4811Z"
        fill="#774237"
      />
      <path
        d="M165.391 28.8456C165.324 28.8456 165.324 28.8456 165.391 28.8456C165.256 28.8456 165.256 28.7102 165.256 28.6425C165.256 28.5748 165.73 27.1534 167.423 26.4766C167.491 26.4766 167.559 26.4766 167.626 26.5442C167.626 26.6119 167.626 26.6796 167.559 26.7473C166.001 27.4242 165.527 28.7102 165.527 28.7779C165.459 28.8456 165.459 28.8456 165.391 28.8456Z"
        fill="white"
      />
      <path
        d="M146.427 42.8563C146.156 42.8563 145.885 42.7209 145.75 42.4502C145.546 42.1118 145.682 41.638 146.088 41.4349C148.797 40.0135 148.188 36.3585 147.511 33.7188C147.036 31.8236 146.562 26.4087 146.495 26.2057C146.427 25.7995 146.766 25.4611 147.172 25.3934C147.578 25.3257 147.917 25.6642 147.985 26.0703C147.985 26.138 148.459 31.5528 148.933 33.3803C149.407 35.1402 150.829 40.6227 146.766 42.7886C146.698 42.7886 146.562 42.8563 146.427 42.8563Z"
        fill="#774237"
      />
      <path
        d="M94.2089 105.466C94.2089 105.466 118.32 98.0205 140.195 98.9004C162.071 99.7803 180.019 106.075 181.441 108.444C182.863 110.813 162.681 149.732 157.872 158.667C152.996 167.601 113.782 164.623 110.937 160.156C108.093 155.689 85.4721 109.73 87.5039 108.106C89.468 106.481 94.2089 105.466 94.2089 105.466Z"
        fill="#FFC97D"
      />
      <path
        d="M105.994 150.814C126.447 145.873 148.933 149.528 161.123 152.303C163.968 146.889 167.693 139.578 171.282 132.404C162.207 128.952 143.379 123.063 125.702 124.891C109.651 126.583 100.305 128.478 95.6992 129.629C99.1533 137.006 103.014 144.858 105.994 150.814Z"
        fill="#2E2E47"
      />
      <path
        d="M108.5 207.197C110.6 200.564 123.061 98.2234 124.145 91.9963C129.225 63.636 141.686 59.6426 141.686 59.6426C141.686 59.6426 146.089 70.2692 145.547 87.7998C145.14 100.931 143.041 119.883 139.384 140.324C135.388 162.66 128.48 184.861 121.03 201.444C114.325 216.47 105.385 216.944 108.5 207.197Z"
        fill="#FFA982"
      />
      <path
        d="M110.329 199.819C110.329 199.819 109.245 205.166 108.094 205.843C106.942 206.52 102.54 209.024 100.982 210.919C99.4246 212.815 96.1737 221.208 96.6478 221.14C97.1219 221.072 97.596 220.395 97.596 220.395C97.596 220.395 96.106 223.509 96.4446 224.186L97.2573 224.457C97.2573 224.457 97.2573 225.472 98.0701 225.743C98.8828 226.013 102.879 219.854 103.082 219.922C103.285 219.989 99.4246 225.201 100.44 225.81C101.524 226.419 108.094 216.064 108.839 216.944C109.584 217.823 106.807 223.035 108.297 224.727C109.787 226.419 110.125 222.9 110.667 221.546C111.141 220.192 115.273 213.221 116.018 210.987C116.763 208.754 118.659 203.542 117.711 201.647C116.966 199.887 111.141 198.668 110.329 199.819Z"
        fill="#FFA982"
      />
      <path
        d="M155.705 106.345C155.637 106.345 155.637 106.345 155.569 106.345C154.486 106.277 153.673 105.397 153.741 104.314C154.215 95.3122 153.808 70.4038 146.562 63.2969C145.139 61.9431 143.649 61.334 141.821 61.4693C129.63 62.4846 122.789 92.334 121.164 103.773C121.029 104.856 120.013 105.6 118.997 105.397C117.913 105.262 117.168 104.247 117.371 103.231C117.439 102.758 118.929 92.2663 122.451 81.4366C127.395 66.275 133.829 58.2881 141.482 57.6113C144.394 57.3405 147.036 58.3558 149.271 60.5217C159.294 70.2685 157.737 103.096 157.601 104.517C157.601 105.533 156.721 106.345 155.705 106.345Z"
        fill="#2E2E47"
      />
      <path
        d="M193.7 305.545C196.409 305.071 198.78 304.597 200.879 304.191C201.624 301.484 201.76 297.761 201.218 294.715C200.337 289.977 184.151 296.949 184.151 292.075C184.151 287.27 199.525 291.196 201.421 289.233C203.25 287.27 202.843 285.848 200.744 280.366C198.644 274.883 187.74 286.728 189.704 280.84C191.601 274.951 200.066 277.929 200.541 275.154C201.015 272.311 200.473 262.294 199.592 261.075C198.712 259.857 180.764 265.746 178.665 266.693C176.565 267.641 178.055 283.276 174.669 281.584C171.282 279.892 175.075 273.665 172.298 271.837C169.522 270.078 160.243 278.674 160.988 280.704C161.733 282.735 160.649 292.414 159.295 289.909C157.873 287.405 156.315 283.209 156.315 283.209C156.315 283.209 153.944 282.87 151.439 285.848C148.933 288.894 146.562 299.182 150.626 304.936C153.335 308.794 156.247 308.929 159.837 309.268C166.135 308.861 171.689 308.523 175.549 308.185C175.752 307.034 176.836 307.169 178.258 307.914C178.326 307.914 178.462 307.914 178.597 307.846C182.796 307.305 186.453 306.763 189.772 306.289C186.724 305.206 184.015 304.056 184.625 302.702C185.302 300.672 189.84 303.514 193.7 305.545Z"
        fill="#FA634B"
      />
      <path
        d="M134.032 347.916C133.896 347.916 133.761 347.781 133.761 347.645L132 297.626C132 297.49 132.135 297.355 132.271 297.287C132.406 297.287 132.542 297.422 132.61 297.558L134.37 347.578C134.37 347.781 134.235 347.916 134.032 347.916Z"
        fill="#2E2E47"
      />
      <path
        d="M137.757 260.872C137.554 260.872 137.486 260.737 137.486 260.534C137.486 260.263 138.502 230.076 137.486 207.198C137.486 207.062 137.622 206.927 137.757 206.859C137.893 206.859 138.028 206.995 138.096 207.13C139.112 230.008 138.096 260.196 138.096 260.534C138.028 260.805 137.893 260.872 137.757 260.872Z"
        fill="#2E2E47"
      />
      <path
        d="M163.222 319.284C163.019 319.284 162.883 319.081 162.951 318.946L164.577 308.861C164.577 308.725 164.78 308.59 164.915 308.59C165.051 308.59 165.186 308.793 165.186 308.928L163.561 319.014C163.493 319.217 163.358 319.284 163.222 319.284Z"
        fill="#2E2E47"
      />
      <path
        d="M71.4509 296.069C71.4509 296.069 71.3832 296.069 71.4509 296.069C71.3155 296.069 71.18 296.001 71.18 295.866C71.1123 295.73 67.0487 282.599 60.3437 280.433C53.6388 278.335 49.7783 280.772 49.7106 280.772C49.5752 280.839 49.372 280.839 49.3043 280.704C49.2365 280.569 49.2365 280.366 49.372 280.298C49.5074 280.163 53.5033 277.658 60.4792 279.892C63.4592 280.84 66.236 283.75 68.8096 288.691C70.2319 291.398 71.18 294.038 71.5864 295.121C72.3991 294.106 74.7696 292.075 80.12 292.414C80.2554 292.414 80.3909 292.549 80.3909 292.752C80.3909 292.887 80.2554 293.023 80.0523 293.023C73.6182 292.617 71.7219 295.933 71.7219 295.933C71.6541 296.001 71.5187 296.069 71.4509 296.069Z"
        fill="#2E2E47"
      />
      <path
        d="M87.7724 304.463H58.4467C58.3112 304.463 58.1758 304.328 58.1758 304.192C58.1758 304.057 58.3112 303.921 58.4467 303.921H87.4338C87.5015 302.5 87.4338 295.799 83.0993 293.701C82.9638 293.633 82.8961 293.43 82.9638 293.295C83.0315 293.159 83.2347 293.092 83.3702 293.159C85.5374 294.242 87.0274 296.476 87.7047 299.657C88.1788 302.026 88.0433 304.124 88.0433 304.192C88.0433 304.328 87.9079 304.463 87.7724 304.463Z"
        fill="#2E2E47"
      />
      <path
        d="M19.7084 304.463C19.5053 304.463 19.3698 304.327 19.4375 304.124C19.4375 304.057 20.0471 296.814 26.2102 293.633C31.3575 290.993 37.182 292.821 38.8074 293.43C38.7397 292.618 38.7397 290.858 39.6879 288.557C41.1779 284.766 46.9347 281.517 47.1378 281.382C47.2733 281.314 47.4765 281.382 47.5442 281.517C47.6119 281.653 47.5442 281.856 47.4087 281.924C47.341 281.924 41.652 285.24 40.2297 288.827C39.0783 291.806 39.4847 293.836 39.4847 293.904C39.4847 294.039 39.4847 294.107 39.3492 294.175C39.2815 294.242 39.1461 294.242 39.0106 294.242C38.9429 294.242 32.2379 291.196 26.4134 294.242C20.5889 297.22 19.9794 304.192 19.9794 304.26C19.9794 304.395 19.8439 304.463 19.7084 304.463Z"
        fill="#2E2E47"
      />
      <path
        d="M110.396 287.811C110.193 287.811 110.057 287.608 110.125 287.473C110.125 287.405 110.802 283.073 107.213 280.907C103.691 278.741 101.321 280.907 101.185 280.975C101.117 281.043 100.982 281.043 100.914 281.043C100.779 281.043 100.711 280.907 100.711 280.84C100.711 280.84 100.034 277.591 97.6634 276.305C96.3089 275.628 94.6834 275.628 92.8548 276.372C89.4685 277.794 88.1139 282.261 88.1139 282.261C88.1139 282.329 88.0462 282.464 87.9107 282.464C87.843 282.464 87.7076 282.464 87.6398 282.396C87.6398 282.396 84.863 280.298 82.0185 281.313C79.174 282.329 78.429 285.375 78.429 285.375C78.3612 285.51 78.2258 285.645 78.0903 285.578C77.9549 285.51 77.8194 285.375 77.8872 285.239C77.8872 285.104 78.6999 281.923 81.8153 280.772C84.3889 279.824 86.7594 281.11 87.6398 281.652C88.0462 280.501 89.5362 276.982 92.5839 275.763C94.6157 274.951 96.4443 274.883 97.9343 275.696C100.034 276.778 100.914 279.147 101.185 280.163C102.066 279.554 104.436 278.403 107.552 280.298C111.48 282.735 110.735 287.473 110.735 287.473C110.667 287.676 110.532 287.811 110.396 287.811Z"
        fill="#2E2E47"
      />
      <path
        d="M403.11 31.553C402.907 31.553 402.771 31.35 402.839 31.2146C402.839 31.1469 403.99 23.7015 397.827 19.9111C391.732 16.1884 387.533 20.0465 387.533 20.1142C387.465 20.1819 387.329 20.1819 387.262 20.1819C387.126 20.1819 387.058 20.0465 387.058 19.9788C387.058 19.9111 385.839 14.4286 381.776 12.2626C379.473 11.0443 376.696 11.112 373.513 12.398C370.465 13.6164 368.434 16.3238 367.282 18.2867C365.995 20.4526 365.454 22.4155 365.454 22.4155C365.454 22.4832 365.386 22.6185 365.25 22.6185C365.183 22.6185 365.047 22.6185 364.979 22.5509C364.912 22.4832 360.171 18.9635 355.362 20.7233C350.554 22.4832 349.267 27.6273 349.267 27.695C349.199 27.8303 349.064 27.9657 348.928 27.898C348.793 27.8303 348.657 27.695 348.725 27.5596C348.793 27.3565 350.08 22.0771 355.227 20.1819C359.629 18.5574 363.828 20.9941 365.047 21.8063C365.589 20.1819 367.959 13.9548 373.378 11.7212C376.764 10.3674 379.744 10.2998 382.182 11.5858C385.839 13.5487 387.262 17.8129 387.668 19.2343C388.887 18.3543 392.951 15.9854 398.301 19.3019C404.735 23.2954 403.584 31.0792 403.584 31.1469C403.381 31.4177 403.313 31.553 403.11 31.553Z"
        fill="#2E2E47"
      />
      <path
        d="M324.276 306.424C324.209 306.424 324.209 306.424 324.276 306.424C324.073 306.424 323.938 306.221 324.005 306.086C324.005 306.018 325.021 299.249 321.635 294.647C319.942 292.413 317.436 290.992 314.185 290.586C314.05 290.586 313.914 290.383 313.914 290.247C313.914 290.112 314.117 289.977 314.253 289.977C317.639 290.45 320.28 291.872 322.109 294.308C325.699 299.114 324.683 305.883 324.615 306.153C324.547 306.289 324.412 306.424 324.276 306.424Z"
        fill="#2E2E47"
      />
      <path
        d="M260.544 305.342C260.409 305.342 260.341 305.274 260.273 305.206C260.206 305.139 256.345 297.084 261.967 291.872C266.978 287.338 273.819 290.722 275.444 291.602C275.58 291.06 275.918 290.18 276.46 289.165C277.611 287.27 279.169 286.322 280.998 286.322C284.791 286.322 287.026 292.888 287.635 295.054C288.38 293.903 290.344 291.737 293.866 292.752C297.252 293.768 298.878 296.069 299.487 297.287C300.097 295.73 301.858 292.075 306.463 290.519C306.599 290.451 306.802 290.519 306.869 290.722C306.937 290.857 306.869 291.06 306.666 291.128C301.113 293.023 299.894 298.235 299.826 298.235C299.826 298.37 299.69 298.438 299.555 298.438C299.42 298.438 299.284 298.37 299.284 298.235C299.284 298.167 297.93 294.58 293.73 293.362C289.599 292.143 287.906 295.798 287.838 296.001C287.771 296.137 287.703 296.204 287.567 296.204C287.432 296.204 287.364 296.137 287.296 296.001C287.296 295.934 284.994 286.999 280.998 286.999C279.372 286.999 278.018 287.879 276.934 289.571C276.121 290.857 275.851 292.211 275.851 292.211C275.851 292.279 275.783 292.414 275.647 292.414C275.58 292.482 275.444 292.414 275.377 292.414C275.309 292.346 267.588 287.541 262.237 292.414C256.887 297.287 260.612 304.936 260.612 305.003C260.68 305.139 260.612 305.342 260.477 305.409C260.68 305.342 260.612 305.342 260.544 305.342Z"
        fill="#2E2E47"
      />
      <path
        d="M56.9588 72.2326H25.8044C25.669 72.2326 25.5335 72.0972 25.5335 71.9619C25.4658 69.7959 26.2108 68.1038 27.6331 66.9531C29.2585 65.6671 31.6967 65.1256 34.8799 65.3287C36.573 65.3963 38.063 65.7348 38.7403 65.8702C38.7403 65.5994 38.808 65.261 38.9435 64.8549C39.4853 63.7042 40.8398 62.5536 42.9394 61.3352C44.8357 60.2522 46.4611 59.9815 47.8157 60.523C48.8993 60.9291 49.5766 61.809 49.9152 62.4859C50.7957 53.416 53.3693 47.2566 57.5006 44.2108C60.2097 42.1802 63.5283 41.571 67.4565 42.3833C71.6556 43.1955 74.9065 45.0907 77.0737 47.8658C77.1414 48.0012 77.1414 48.2042 77.006 48.2719C76.8705 48.3396 76.6673 48.3396 76.5996 48.2042C74.5678 45.4968 71.4524 43.737 67.3888 42.9247C63.6638 42.1802 60.4806 42.7894 57.907 44.6846C53.9111 47.6627 51.4052 53.7544 50.5248 62.7566C50.457 63.7719 50.3893 63.8396 50.1184 63.9073C49.9829 63.9073 49.8475 63.8396 49.7798 63.7042C49.712 63.5012 49.1702 61.809 47.5448 61.1322C46.3257 60.6584 44.9034 60.9291 43.1425 61.8767C38.5371 64.4488 39.2821 66.1409 39.2821 66.1409C39.3498 66.2763 39.3498 66.344 39.2144 66.4793C39.1466 66.547 39.0112 66.6147 38.9435 66.6147C38.8757 66.6147 31.4258 64.6518 27.8363 67.4946C26.6172 68.4422 26.0076 69.8636 25.9399 71.7588H56.7556C56.8911 71.7588 57.0265 71.8942 57.0265 72.0295C57.2297 72.0972 57.0943 72.2326 56.9588 72.2326Z"
        fill="#2E2E47"
      />
      <path
        d="M90.2134 72.2324H80.4607C80.3252 72.2324 80.1898 72.097 80.1898 71.9616C80.1898 71.8263 80.3252 71.6909 80.4607 71.6909H90.0779C90.3488 71.0817 91.297 68.7804 90.552 66.7498C90.0779 65.4638 89.062 64.5162 87.5043 63.907C81.9507 61.6734 79.3093 65.1254 79.1739 65.2608C79.1061 65.3961 78.9029 65.3961 78.7675 65.3284C78.632 65.2608 78.5643 65.1254 78.632 64.99C78.632 64.99 79.1739 63.2302 79.377 60.7258C79.377 60.5905 79.5125 60.4551 79.7157 60.4551C79.8511 60.4551 79.9866 60.5904 79.9866 60.7935C79.8511 62.2149 79.6479 63.3656 79.4448 64.1101C80.7316 63.0948 83.4407 61.6057 87.7075 63.2979C89.4684 63.9747 90.552 65.0577 91.0938 66.4791C92.042 69.1188 90.552 71.9616 90.4843 72.0293C90.4165 72.1647 90.3488 72.2324 90.2134 72.2324Z"
        fill="#2E2E47"
      />
    </svg>
  );
};

export default React.memo(NotorizationDoneImageIcon);
