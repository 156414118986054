import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ColorBox from './ColorBox';

const ColorPalette = () => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="xlDisplay">Color Palette</Typography>
      <Box display="grid" gridTemplateColumns="3fr 2fr" mt={3}>
        <Box>
          <Typography variant="mDisplay">Brand</Typography>
          <Box display="grid" gridTemplateColumns="repeat(4, 1fr)">
            <ColorBox
              color={theme.palette.grey[900]}
              name="Soft Black"
              themeText="grey.900"
            />
            <ColorBox
              color={theme.palette.grey[700]}
              name="Gray 2"
              themeText="grey.700"
            />
            <ColorBox
              color={theme.palette.grey[500]}
              name="Gray 1"
              themeText="grey.500"
            />
            <ColorBox
              color={theme.palette.grey[300]}
              name="Gray 3"
              themeText="grey.300"
            />
            <ColorBox
              color={theme.palette.background.default}
              name="Background"
              themeText="background.default"
            />
            <ColorBox
              color={theme.palette.common.lightGreen}
              name="Light Green"
              themeText="common.lightGreen"
            />
            <ColorBox
              color={theme.palette.secondary.main}
              name="Accent Color"
              themeText="secondary.main"
            />
            <ColorBox
              color={theme.palette.info.main}
              name="Accent Color 1"
              themeText="info.main"
            />
            <ColorBox
              color={theme.palette.common.dustGreen}
              name="Dust Green"
              themeText="common.dustGreen"
            />
            <ColorBox
              color={theme.palette.common.dustYellow}
              name="Dust Yellow"
              themeText="common.dustYellow"
            />
            <ColorBox
              color={theme.palette.common.dustBlue}
              name="Dust Blue"
              themeText="common.dustBlue"
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="mDisplay">Other</Typography>
          <Box display="grid" gridTemplateColumns="repeat(3, 1fr)">
            <ColorBox
              color={theme.palette.primary.main}
              name="Hit Color"
              themeText="primary.main"
            />
            <ColorBox
              color={theme.palette.error.main}
              name="Error/Warning"
              themeText="error.main"
            />
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          fontStyle: 'italic',
        }}
        variant="s"
      >
        Note: Click on Grid element to copy the theme color
      </Typography>
    </Box>
  );
};

export default React.memo(ColorPalette);
