import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import Loader from '../Loader';
import useCounties from './counties-hook';

interface Props {
  state: string;
  value?: string;
  onChange: any;
}

const CountiesDropdown = ({ state, value, onChange }: Props) => {
  const { counties, loading } = useCounties({ state });
  return (
    <>
      <Autocomplete
        disablePortal
        value={value}
        onChange={(event: any, newValue: string | null) => {
          onChange(newValue);
        }}
        options={counties}
        renderInput={(params) => <TextField {...params} label="County" />}
      />
      <Loader open={loading} />
    </>
  );
};

export default CountiesDropdown;
