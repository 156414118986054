import React from 'react';
import { useTheme } from '@mui/material/styles';

const CheckMarkIcon = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="9.36585"
        cy="14.6342"
        r="9.36585"
        fill={theme.palette.common.lightGreen}
      />
      <path
        d="M9.32966 21.3738C7.52339 15.955 4.81398 14.4498 0.900391 13.2456C3.6098 13.2456 6.9213 13.2456 8.42652 15.955C8.72619 9.96154 11.5027 6.3051 18.06 2.70898C11.0999 8.1278 10.5338 13.8477 9.32966 21.3738Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default React.memo(CheckMarkIcon);
