import React, { useContext } from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import PersonalRepresentativeIcon from '../../components/Icons/CompletionScreen/PersonalRepresentativeIcon';
import { DocumentContext } from '../PlanDocuments/DocumentContext';
import Loader from '../../components/Loader';

const CompletionScreen = () => {
  const history = useHistory();

  const { planDocuments, loadingPlanDocuments } = useContext(DocumentContext);

  const onPreviousClick = () => {
    const isMarried = planDocuments?.user_info?.is_married;
    if (isMarried) {
      return history.push(apiUrls.PERSONAL_REPRESENTATIVE_SPOUSE);
    }
    return history.push(apiUrls.PERSONAL_REPRESENTATIVE);
  };

  return (
    <>
      <CompletionBox
        header={'Personal Representative'}
        image={PersonalRepresentativeIcon}
        title="Great Job! We will help your personal representative manage & distribute your assets."
        quote={
          'This was super easy to do. ' +
          'Love having peace of mind that this wont burden my children.'
        }
        quoteBy="Kayla"
        previous={() => onPreviousClick()}
        continue={() => history.push(apiUrls.TRUSTEES)}
      />
      <Loader open={loadingPlanDocuments} />
    </>
  );
};

export default React.memo(CompletionScreen);
