import React from 'react';
import CompletionBox from '../../components/CompletionBox';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import BeneficiariesIcon from '../../components/Icons/CompletionScreen/BeneficiariesIcon';

const CompletionScreen = () => {
  const history = useHistory();

  return (
    <CompletionBox
      header={'Beneficiaries'}
      image={BeneficiariesIcon}
      title="Perfect! Your Beneficiaries will thank you for planning ahead."
      quote={'It feels good to be prepared & have this in place for my family’s well-being.'}
      quoteBy="Karen"
      previous={() => history.push(apiUrls.BENEFICIARIES)}
      continue={() => history.push(apiUrls.POWER_OF_ATTORNEY_GENERAL)}
    />
  );
};

export default React.memo(CompletionScreen);
