import React from 'react';
import {
  Box,
  Breakpoint,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import useMapLocations from './useLocation';
import {
  BREAKPOINTS,
  MAP_NOTARY_DEFAULT_LAT,
  MAP_NOTARY_DEFAULT_LNG,
  MAP_NOTARY_ZOOM,
} from '../../variables/constant';
import Loader from '../../components/Loader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MapRow from './MapRow';
import Map from './Map';
import { useHistory } from 'react-router-dom';
import { apiUrls } from '../../variables/urls';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const MapContainer = () => {
  const theme = useTheme();
  const history = useHistory();
  const { places, ref, map, setZipCode, loading, defaultZipCode } =
    useMapLocations({
      mapOptions: {
        center: {
          lat: MAP_NOTARY_DEFAULT_LAT,
          lng: MAP_NOTARY_DEFAULT_LNG,
        },
        zoom: MAP_NOTARY_ZOOM,
      },
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(event.target.value);
  };

  const desktopView = useMediaQuery(
    theme.breakpoints.up(BREAKPOINTS.LG as Breakpoint),
  );

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', lg: 'calc(100% - 20rem)' },
      }}
    >
      <Loader open={loading} />
      <Grid
        container
        columnSpacing={10}
        rowSpacing={2}
        justifyContent="center"
        sx={{
          py: 5,
          mt: 2,
          width: '85vw',
          px: 2,
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            flexDirection: 'column-reverse',
            py: 2,
          },
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          rowSpacing={8}
          sx={{
            flexDirection: 'column',
          }}
        >
          <Grid item alignSelf={'center'}>
            {desktopView ? (
              <Typography variant="xlDisplay">Find a notary near me</Typography>
            ) : (
              <Typography variant="lDisplay">Find a notary near me</Typography>
            )}
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              onChange={handleChange}
              value={defaultZipCode}
              placeholder={'Enter Zipcode'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.grey[500] }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <FormatListBulletedIcon
                      fontSize={'large'}
                      color={'primary'}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={10}
        rowSpacing={2}
        justifyContent="center"
        sx={{
          display: 'flex',
          width: '85vw',
          px: 20,
          [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
            px: 10,
          },
          [theme.breakpoints.down(BREAKPOINTS.MD as Breakpoint)]: {
            px: 5,
          },
          [theme.breakpoints.down(BREAKPOINTS.SM as Breakpoint)]: {
            flexDirection: 'column',
            px: 2,
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {places?.map((place) => (
            <MapRow place={place} key={place.id} />
          ))}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              style={{
                height: '80vh',
                [theme.breakpoints.down(BREAKPOINTS.LG as Breakpoint)]: {
                  height: '60vh',
                },
                width: '100%',
              }}
            >
              <Map places={places} map={map!} ref={ref} />
            </Box>
            <Grid
              item
              sx={{
                mt: 3,
              }}
            >
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => history.push(apiUrls.DASHBOARD)}
              >
                Go to my dashboard
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(MapContainer);
