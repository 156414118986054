import React from 'react';
import { useHistory } from 'react-router-dom';
import usePowerOfAttorneys from './power-of-attorneys-hooks';
import { apiUrls } from '../../variables/urls';
import { PersonType, PowerOfAttorneyType } from '../../generated/graphql';
import PowerOfAttorneyForm from './PowerOfAttorneyForm';
import Loader from '../../components/Loader';

interface Props {
  uiFlag?: string;
  setEditState?: Function;
}

const GeneralPowerOfAttorneySpouse = ({ uiFlag, setEditState }: Props) => {
  const { loading, powerOfAttorneys } = usePowerOfAttorneys();

  const history = useHistory();

  return (
    <>
      <PowerOfAttorneyForm
        powerOfAttorney={powerOfAttorneys?.general_for_spouse}
        attorneyType={PowerOfAttorneyType.General}
        attorneyFor={PersonType.Spouse}
        header={
          'Who would your spouse or partner like ' +
          'to manage their finances and make legal ' +
          'decisions on their behalf if ' +
          'they are incapacitated but still alive?'
        }
        previous={() => history.push(apiUrls.POWER_OF_ATTORNEY_MEDICAL)}
        uiFlag={uiFlag}
        setEditState={setEditState}
      />
      <Loader open={loading} />
    </>
  );
};

export default React.memo(GeneralPowerOfAttorneySpouse);
