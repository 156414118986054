import { Control, FieldErrors, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FormEventHandler, MouseEventHandler, useContext, useEffect } from 'react';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import {
  PersonFragmentFragment,
  PersonType,
  usePersonUpsertMutation,
  useInfoInputMutation,
  BasicInfoType,
} from '../../../generated/graphql';
import { useAuthProvider } from '../../../core/authContext';
import { apiUrls } from '../../../variables/urls';
import dateFormat from 'dateformat';
import { DocumentContext } from '../../PlanDocuments/DocumentContext';
import getUnMaskedPhoneNumber from '../../../utils/getUnMaskedPhoneNumber';
import { AddressProps } from '../../../hooks/maps/google-address-autocomplete';
import getErrorMessage from '../../../utils/getErrorMessage';
import { SUMMARY } from '../../Summary/constants';

export interface DataForm {
  first_name: string;
  middle_name?: string;
  last_name: string;
  date_of_birth: Date | null;
  phone: string;
  email: string;
  address: string;
  apt_or_suit: string;
  zip_code: string;
  city: string;
  state: string;
  county: string;
  relation_type: string;
}

export interface FormProps {
  type?: PersonType;
  errors: FieldErrors;
  control: Control<DataForm>;
  previousPage?: MouseEventHandler;
  nextPage: Function;
  uiFlag?: string;
  setEditState?: Function;
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

export interface PersonInfoProps {
  info?: PersonFragmentFragment | null;
  type?: PersonType;
  prev?: MouseEventHandler;
  next?: Function;
  resetPage?: Function;
  currentPage?: number;
  uiFlag?: string;
  setEditState?: Function;
}

const usePersonalInformationForm = ({ info, type, next, uiFlag, setEditState }: PersonInfoProps) => {
  const [upsertPersonInfo, { loading }] = usePersonUpsertMutation();
  const [UpdateUserInfo] = useInfoInputMutation();

  const { getUser } = useAuthProvider();
  const user = getUser();
  const userEmail = user?.email;
  const history = useHistory();

  const { refetchPlanDocuments } = useContext(DocumentContext);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
    watch,
    reset,
  } = useForm<DataForm>({
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: null,
      phone: '',
      email: type === PersonType.Self ? userEmail : '',
      address: '',
      zip_code: '',
      city: '',
      state: '',
      county: '',
      apt_or_suit: '',
      relation_type: '',
    },
  });

  const setFormValues = () => {
    setValue('first_name', info?.first_name || '');
    setValue('last_name', info?.last_name || '');
    setValue('middle_name', info?.middle_name || '');
    info?.date_of_birth
      ? setValue('date_of_birth', new Date(info?.date_of_birth) as Date)
      : setValue('date_of_birth', null);
    setValue('phone', info?.phone || '');
    setValue('email', info?.email || '');
    setValue('address', info?.address?.address || '');
    setValue('zip_code', info?.address?.zip_code || '');
    setValue('city', info?.address?.city || '');
    setValue('state', info?.address?.state || '');
    setValue('county', info?.address?.county || '');
    setValue('apt_or_suit', info?.address?.apt_or_suit || '');
    setValue('relation_type', info?.relation_type || '');
  };

  useEffect(() => {
    if (type === PersonType.Self) {
      if (info) {
        setFormValues();
      }
    } else {
      setFormValues();
    }
  }, [info, setValue, reset]);

  const nextPage = async (fields: any) => {
    const result = await trigger(fields);
    if (!result) return;

    if (next) {
      next();
    }
  };

  const onCreate = async (data: DataForm) => {
    try {
      await upsertPersonInfo({
        variables: {
          input: {
            id: info?.id,
            first_name: data.first_name ?? '',
            last_name: data.last_name || '',
            middle_name: data.middle_name || '',
            date_of_birth: dateFormat(
              new Date(data.date_of_birth || new Date()),
              'isoUtcDateTime',
            ),
            phone: getUnMaskedPhoneNumber(data.phone) || '',
            email: data.email || '',
            address: data.address || '',
            apt_or_suit: data.apt_or_suit || '',
            zip_code: data.zip_code || '',
            city: data.city || '',
            state: data.state || '',
            county: data.county || '',
            type: type || '',
            relation_type: data.relation_type || '',
          },
        },
      });

      refetchPlanDocuments?.();
      if (uiFlag === SUMMARY) {
        const editFunc =  setEditState || Function;
        editFunc('');
      } else {
        if (type === PersonType.Self) {
          if (next) {
            next();
          }
        } else {
          history.push(apiUrls.PERSONAL_INFORMATION_COMPLETION);
        }
      }
    } catch (err: any) {
      openSnackbar({ message: err?.message }, NotifierType.Error);
    }
  };

  const UserInfoSetOption = async (value: boolean) => {
    try {
      await UpdateUserInfo({
        variables: {
          input:{
            section: BasicInfoType.PersonalInformation,
            answer: value,
          },
        },
      });
      refetchPlanDocuments?.();
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const onAutocompleteAddress = (place: AddressProps) => {
    const values = getValues();
    reset({
      ...values,
      address: place.address,
      apt_or_suit: place.apt_or_suit,
      city: place.city,
      county: place.county,
      state: place.state,
      zip_code: place.postalCode,
    });
  };
  const currentState = watch('state', info?.address?.state || '');

  return {
    loading,
    register,
    errors,
    handleSubmit,
    create: onCreate,
    control,
    nextPage,
    onAutocompleteAddress,
    currentState,
    UserInfoSetOption,
  };
};

export default usePersonalInformationForm;
